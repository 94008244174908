import { ClientDetails } from "../../Generated/ExoDBAPI";

export function validateFreeText(text: string) {
  const re = /^\w+$/;
  const match = text.match(re);
  return match?.length === 1 && 2 <= text.length;
}

export function validateSiteNameInputs(locationText: string, freeTextName: string) {
  return validateFreeText(locationText) && validateFreeText(freeTextName);
}

export function createSiteName(clients: ClientDetails[], clientId: string, locationText: string, nameFreeText: string) {
  return `${clients.find((c) => c.id === clientId)?.abbreviation}_${locationText}_${nameFreeText}`;
}
