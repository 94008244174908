import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react'
import { SiteInfo } from '../Common/ChooseSite';
import PageCard from '../Templates/PageCard';
import Typography from '@mui/material/Typography';
import { Scan, useGetAllSitesInfoForChoosingLazyQuery, useSearchScansPsdLazyQuery } from '../../Generated/Graphql';
import { parseSites } from '../../Utils/parseCommonQueries';
import { reportAllPagesResults } from '../../Utils/graphqlUtils';
import { Checkbox, FormControlLabel } from '@mui/material';
import { comparator } from './ScanServices';

interface ScanQueryProps {
    setSelectedScan: (scan: Scan | null) => void;
    selectedScan: Scan | null;
    setSelectedScanSite?: (site: SiteInfo | null) => void;
    title?: string;
    format?: string | null;
    isValidDefault?: boolean;
}

function ScanQuery({ setSelectedScan, selectedScan, setSelectedScanSite, title="Scan Select", format = null, isValidDefault = false }: ScanQueryProps) {
    const [allSites, setAllSites] = useState<SiteInfo[]>([]);
    const [selectedSite, setSelectedSite] = useState<SiteInfo | null>(null);
    const [, getAllSitesQuery] = useGetAllSitesInfoForChoosingLazyQuery();
    const [isValid, setIsValid] = useState(isValidDefault);
    const [searchScansPSD, scansResults] = useSearchScansPsdLazyQuery();
    const [scanDates, setScanDates] = useState<string[]>([]);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [siteScans, setSiteScans] = useState<Scan[] | undefined>([]);
    const [scanOptions, setScanOptions] = useState<any[]>([]);

    useEffect(() => {
        async function load() {
          const sitesPromise = parseSites(getAllSitesQuery);
          const sites = (await sitesPromise).sort((a, b) => a.name.localeCompare(b.name));
          const sitesList = sites.filter((item) => item !== null && item.locationId !== null);
          setAllSites(
            sitesList.map((site) => {
              return {
                id: site.id,
                name: site.name,
                s3Ref: site.siteS3Ref,
                locationId: site.locationId ?? "",
                customerId: site.customerId ?? "",
              };
            })
          );
        }
        load();
    }, []);


    useEffect(() => {
        if (!siteScans || siteScans.length === 0) {
            return;
        }
        let uniqueDates = [...new Set(siteScans.map(item => {
            if (!item || !item.start_timestamp) {
                return "";
            }
            let date = new Date(item.start_timestamp * 1000);
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }))];
        uniqueDates.sort();
        setScanDates(uniqueDates);
        if (uniqueDates.length === 1) {
            setSelectedDate(uniqueDates[0]);
        }
    }, [siteScans])



    useEffect(() => {

        const siteId = selectedSite?.id;
        setScanDates([]);
        setSelectedDate(null);

        setSelectedScan(null);
        setSiteScans([]);
        async function loadScans(siteId: string) {

            let startScan = 0;
            reportAllPagesResults(
                scansResults,
                (res) => {
                    const scans = (res.data.searchScans?.items ?? []).filter(item => item !== null) as Scan[];
                    if(!scans || scans.length === 0) {
                        return;
                    }
                    setSiteScans((sScans) => {
                        return [...(startScan === 0 ? []: sScans || []), ...scans];
                    });
                    startScan += scans.length;
                },
                (res) => res.data.searchScans?.nextToken,
                { siteId: siteId, is_valid: isValid }
            );

        }
        if (siteId) {
            loadScans(siteId);
        }
    }, [scansResults, searchScansPSD, selectedSite, setSelectedScan, isValid])


    useEffect(() => {
        if (!selectedDate || !siteScans || siteScans.length === 0) {
            return;
        }
        let scansOnSelectedDate = siteScans.filter(scan => {
            let date = new Date(scan.start_timestamp * 1000);
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let day = String(date.getDate()).padStart(2, '0');
            let scanDate = `${year}-${month}-${day}`;

            return scanDate === selectedDate;
        });

        const filterScans = (scans: Scan[], format: string | null) => {
            if (!format) return scans;
            const regex = new RegExp(format);
            return scans.filter(scan => scan.name && regex.test(scan.name));
        };

        scansOnSelectedDate = filterScans(scansOnSelectedDate, format);
        scansOnSelectedDate = scansOnSelectedDate.sort(comparator);
        setScanOptions(scansOnSelectedDate);
    }, [selectedDate])

    return (
        <PageCard>
        {title.length>0 && <Typography variant="h4">{title}</Typography>}
        <Stack direction="row" gap={1}>
            <Autocomplete
                options={allSites}
                getOptionLabel={(o) => o.name}
                loading={true}
                renderInput={(params) => (
                <TextField
                    {...params}
                    key={`option-${params.id}`}
                    placeholder="Sites"
                    error={allSites.length === 0}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: allSites.length > 0  ? 'green' : 'default',
                            },
                            '&:hover fieldset': {
                                borderColor: allSites.length > 0 ? 'green' : 'default',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: allSites.length > 0 ? 'green' : 'default',
                            },
                        },
                    }}
                    />
                )}
                sx={{ width: 450 }}
                value={selectedSite}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, v) => {
                    setSelectedSite(v)
                    if (setSelectedScanSite) {
                        setSelectedScanSite(v);
                    }
                }}
            />
            <Autocomplete
                options={scanDates}
                renderInput={(params) => (
                <TextField {...params} key={`option-${params.id}`} placeholder="Dates"
                    error={scanDates.length === 0}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: allSites.length > 0  ? 'green' : 'default',
                            },
                            '&:hover fieldset': {
                                borderColor: allSites.length > 0 ? 'green' : 'default',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: allSites.length > 0 ? 'green' : 'default',
                            },
                        },
                    }}
                    />
                )}
                sx={{ width: 450 }}
                value={selectedDate}
                onChange={(e, v) => setSelectedDate(v)}
            />
            <Autocomplete
                options={scanOptions}
                getOptionLabel={(o) => o.name}
                renderInput={(params) => (
                <TextField {...params} key={`option-${params.id}`} placeholder="Scans" error={selectedSite === null} />
                )}
                sx={{ width: 800 }}
                value={selectedScan}
                onChange={(e, v) => setSelectedScan(v)}
            />
            <FormControlLabel
                    control={
                        <Checkbox
                            checked={isValid}
                            onChange={(e) => setIsValid(e.target.checked)}
                        />
                    }
                    label="IsValid"
                />
        </Stack>
        </PageCard>

    )
}

export default ScanQuery;
