import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Autocomplete, TextField } from '@mui/material';


const SELECT_ALL_VALUE = 'Select All';
const DESELECT_ALL_VALUE = 'Deselect All';
const NO_VALUES = '// No values';

function isChecked(option: string, strategy?: boolean | ((option: string) => boolean)): boolean {
  if (strategy === true || strategy === undefined) {
    return true;
  }

  if (strategy === false) {
    return false;
  }

  return strategy(option);
}

function updateOptions<T>({
  overrideOriginalValues,
  currentSelection,
  setChoices,
  setSelection,
  optionsMapping,
  strategy
}: UpdateOptionsProps<T>) {
  const allOptions = optionsMapping;
  setChoices((prevChoices) => {
    let chosen = overrideOriginalValues ? [] : prevChoices.chosen;
    let rejected = overrideOriginalValues ? [] : prevChoices.rejected;

    if (currentSelection === undefined) {
      const decidedOptions = [...chosen, ...rejected];
      const undecidedOptions = allOptions.filter((option) => !decidedOptions.includes(option));
      chosen = [...chosen, ...undecidedOptions.filter((option) => isChecked(option, strategy))];
      rejected = [...rejected, ...undecidedOptions.filter((option) => !chosen.includes(option))];
    } else {
      const undisplayedChosen = chosen.filter((option) => !allOptions.includes(option));
      const undisplayedRejected = rejected.filter((option) => !allOptions.includes(option));
      const currentRejection = allOptions.filter((option) => currentSelection.includes(option));
      chosen = [...undisplayedChosen, ...currentSelection];
      rejected = [...undisplayedRejected, ...currentRejection];
    }

    setSelection(
      optionsMapping.filter((option) => chosen.includes(option))
    );
    const result = { chosen: chosen, rejected: rejected };
    if (isEqual(prevChoices, result)) {
      return prevChoices;
    }
    return result;
  });
}

function SelectedValues<T>({
  filterName,
  optionsMapping,
  getDisplayByOption,
  enabled,
  setSelection,
  strategy,
  recalculateTrigger,
  resendOutputTrigger
}: CheckboxSelectionProps<T>) {
  const [allOptions, setAllOptions] = useState<string[]>(optionsMapping);
  const [choices, setChoices] = useState<OptionsChoice>({ chosen: [], rejected: [] });

  const allChosen = React.useMemo<boolean>(() => {
    return allOptions.every((option) => choices.chosen.includes(option));
  }, [allOptions, choices]);

  useEffect(() => {
    setAllOptions((prevOptions) => {
      const newOptions = optionsMapping;
      if (isEqual(prevOptions, newOptions)) {
        return prevOptions;
      }
      return newOptions;
    });
  }, [optionsMapping]);

  useEffect(() => {
    updateOptions<T>({
      overrideOriginalValues: true,
      setChoices: setChoices,
      setSelection: setSelection,
      optionsMapping: optionsMapping,
      strategy: strategy
    });
  }, [recalculateTrigger, strategy]);

  useEffect(() => {
    updateOptions<T>({
      overrideOriginalValues: false,
      setChoices: setChoices,
      setSelection: setSelection,
      optionsMapping: optionsMapping,
      strategy: strategy
    });
  }, [resendOutputTrigger]);

  function handleChange(currentlyCheckedOptions: string[]) {
    if (currentlyCheckedOptions.includes(SELECT_ALL_VALUE)) {
      currentlyCheckedOptions = allOptions;
    } else if (currentlyCheckedOptions.includes(DESELECT_ALL_VALUE)) {
      currentlyCheckedOptions = [];
    }
    updateOptions<T>({
      overrideOriginalValues: false,
      currentSelection: currentlyCheckedOptions,
      setChoices: setChoices,
      setSelection: setSelection,
      optionsMapping: optionsMapping,
      strategy: strategy
    });
  }

  return (
    <>
      <Autocomplete
        disabled={!enabled || allOptions.length === 0}
        size="small"
        options={[
          allChosen ? DESELECT_ALL_VALUE : SELECT_ALL_VALUE,
          ...(allOptions.length === 0 ? [NO_VALUES] : allOptions)
        ]}
        getOptionLabel={
          getDisplayByOption !== undefined
            ? (o) =>
                optionsMapping.includes(o) ? o : o
            : (o) => o
        }
        renderInput={(params) => (
          <TextField {...params} key={`option-${params.id}`} placeholder={filterName} />
        )}
        value={
          allOptions.length === 0
            ? [NO_VALUES]
            : allOptions.filter((option) => choices.chosen.includes(option))
        }
        onChange={(_, newVal) => handleChange(newVal)}
        sx={{
          width: 400,
          '& .MuiInputBase-root': {
            maxHeight: '100px',
            overflow: 'auto'
          },
          '& .MuiOutlinedInput-root': {
            border: '1px solid #e0e0e0'
          },
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }}
        limitTags={4}
        multiple
        disableCloseOnSelect
      />
    </>
  );
}
//MuiInputBase-root
interface UpdateOptionsProps<T> {
  overrideOriginalValues: boolean;
  currentSelection?: string[];
  setChoices: React.Dispatch<React.SetStateAction<OptionsChoice>>;
  setSelection: (selectedElements: string[]) => void;
  optionsMapping: string[];
  strategy?: boolean | ((option: string) => boolean);
}

interface OptionsChoice {
  chosen: string[];
  rejected: string[];
}

export interface CheckboxSelectionProps<T> {
  filterName: string;
  optionsMapping: string[];
  getDisplayByOption?: (element: T) => string;
  enabled: boolean;
  setSelection: (selectedElements: string[]) => void;
  recalculateTrigger: boolean;
  resendOutputTrigger: boolean;
  strategy?: boolean | ((option: string) => boolean);
}

export default SelectedValues;
