import { create } from 'zustand';
import { DISLPAY_PIPES_MODE, UNIT_MEASURES, UnitMeasuresProperties } from '../../../Utils/const';
import { DislpayPipeModeValues } from '../MapControls/DisplayPipeMode/type';
import LocalStorageService from '../../../Utils/storage';

type MapControlsStore = {
  unitMeasures: UnitMeasuresProperties;
  setUnitMeasures: (unit: UnitMeasuresProperties) => void;
  setDislpayPipesMode: (mode: DislpayPipeModeValues) => void;
  displayPipesMode: DislpayPipeModeValues;
};

export const useMapControlsStore = create<MapControlsStore>((set) => ({
  displayPipesMode:
    LocalStorageService.loadData(DISLPAY_PIPES_MODE) || DislpayPipeModeValues.Sensor,
  setDislpayPipesMode: (mode) =>
    set((state) => {
      LocalStorageService.saveData(DISLPAY_PIPES_MODE, mode);
      return { displayPipesMode: mode };
    }),
  unitMeasures: LocalStorageService.loadData(UNIT_MEASURES) || UnitMeasuresProperties.Feet,
  setUnitMeasures: (unit) =>
    set((state) => {
      LocalStorageService.saveData(UNIT_MEASURES, unit);
      return { unitMeasures: unit };
    })
}));
