import axios from 'axios';
import { getRequestParams } from '../Utils/azureAuth';
import { Api } from '../Generated/ExoDBAPI';
import { GeoJsonLayer } from '../Utils/layerUtils';
import { exovision_s3_bucket } from '../Constant/exoVision';

export interface returnStatus {
    message: string;
    status: number;
}

// TODO: generic copy file from - to s3
const copyS3JsonFile = async (layerId: string, targetLayerId: string, sourceFile: string, targetFile: string, overwrite: boolean): Promise<returnStatus> => {
    const api = new Api();
    if (!overwrite && await isFileExistInS3(layerId, targetFile)) {
      console.log('File already exists in S3. Skipping copy.');
      return ({
        message: 'File already exists in S3. Skipping copy.',
        status: 409,
      });
    }

    try {
      // Get pre-signed URL for reading JSON file
      const getPresignedData = await api.s3.getGetPresignedUrl({
        bucket: exovision_s3_bucket,
        file: `${layerId}/${sourceFile}`,
      }, await getRequestParams());

      // Fetch the JSON file from the pre-signed URL
      const jsonFile = await axios.get(getPresignedData.data.url);

      // Get pre-signed URL for writing JSON file
      const putPresignedData = await api.s3.getPutPresignedUrl({
        bucket: exovision_s3_bucket,
        file: `${targetLayerId}/${targetFile}`,
        // file: `${layerId}_edit/${targetFile}`,
      }, await getRequestParams());

      // Upload the JSON file to the new location
      console.log('Copying JSON file...', jsonFile.data);
      await axios.put(putPresignedData.data.url, jsonFile.data);
      console.log('JSON file copied successfully!');

      return ({
        message: 'JSON file copied successfully!',
        status: 200,
      });
    } catch (error) {
      console.error('Error copying JSON file:', error);
      return({
        message: 'Error copying JSON file',
        status: 500,
      });
    }
  };


  const updateLayersToS3 = async (layers: GeoJsonLayer[], targetLayerId: string, targetFile: string): Promise<returnStatus> => {
    const api = new Api();

    try {
        console.log("Layers updateLayerToS3", layers, targetLayerId, targetFile);
        const getPresignedData = await api.s3.getGetPresignedUrl({
            bucket: exovision_s3_bucket,
            file: `${targetLayerId}/${targetFile}`,
          }, await getRequestParams());

          // Fetch the JSON file from the pre-signed URL
        const jsonFile = await axios.get(getPresignedData.data.url);
        console.log('Before Copying JSON file...', jsonFile.data);

        const newArray = layers.map(layer => layer.subLayers).map(subLayers => subLayers?.map(subLayer => subLayer.data))
        console.log('newArray...', newArray);


        jsonFile.data.features = newArray?.length>0 ? newArray[0]:[];
        console.log('After Copying JSON file...', jsonFile.data);

        // Get pre-signed URL for writing JSON file
        const putPresignedData = await api.s3.getPutPresignedUrl({
        bucket: exovision_s3_bucket,
        file: `${targetLayerId}/${targetFile}`,
        // file: `${layerId}_edit/${targetFile}`,
        }, await getRequestParams());

        // Upload the JSON file to the new location
        await axios.put(putPresignedData.data.url, jsonFile.data);
        console.log('JSON file copied successfully!');

        return ({
        message: 'JSON file copied successfully!',
        status: 200,
        });
        } catch (error) {
        console.error('Error copying JSON file:', error);
        return({
        message: 'Error copying JSON file',
        status: 500,
        });
    }
  };




const isFileExistInS3 = async (layerId: string, fileName: string): Promise<boolean> => {
    const api = new Api();
    try {
      // Get pre-signed URL for checking if file exists
      const getPersignData = await api.s3.getGetPresignedUrl({
        bucket: exovision_s3_bucket,
        file: `${layerId}/${fileName}`,
      }, await getRequestParams());

      // Check if the pre-signed URL exists (i.e., file exists)
      return !!getPersignData.data.url;
    } catch (error) {
      console.error('Error checking file existence:', error);
      return false;
    }
  };

  export { copyS3JsonFile, isFileExistInS3, updateLayersToS3 };
