import { measurementUnits } from '../Components/Pages/ManHolesForm';

type KeyFuncType<T> = (arg: T) => number | string;
type Comparable = number | string;

function _getExtramumIndex<T>(
  arr: T[],
  keyFunc: KeyFuncType<T> | undefined = undefined,
  isMax: boolean = false
): number | undefined {
  if (arr.length === 0) {
    return undefined;
  }

  let mappedArr = arr as (number | string)[];
  if (keyFunc !== undefined) {
    mappedArr = arr.map((value: T) => keyFunc(value));
  }

  let currentIndex = 1;
  let extramumIndex = 0;
  while (currentIndex < arr.length) {
    if (
      (isMax && mappedArr[currentIndex] > mappedArr[extramumIndex]) ||
      (!isMax && mappedArr[currentIndex] < mappedArr[extramumIndex])
    ) {
      extramumIndex = currentIndex;
    }
    currentIndex++;
  }
  return extramumIndex;
}

export function convertMeasurement(value: number, unit: string): number {
  if (unit === measurementUnits.ISRAEL) {
    // Convert inches to centimeters
    return parseFloat((value * 2.54).toFixed(2));
  } else if (unit === measurementUnits.USA) {
    // Convert centimeters to inches
    return parseFloat((value / 2.54).toFixed(2));
  } else {
    throw new Error('Invalid measurement unit');
  }
}
export function getMaxIndex<T extends Comparable>(arr: T[]): number | undefined;
export function getMaxIndex<T extends Comparable>(arr: T[], keyFunc: undefined): number | undefined;
export function getMaxIndex<T>(arr: T[], keyFunc: KeyFuncType<T>): number | undefined;
export function getMaxIndex<T>(
  arr: T[],
  keyFunc: KeyFuncType<T> | undefined = undefined
): number | undefined {
  return _getExtramumIndex(arr, keyFunc, true);
}

export function getMinIndex<T extends Comparable>(arr: T[]): number | undefined;
export function getMinIndex<T extends Comparable>(arr: T[], keyFunc: undefined): number | undefined;
export function getMinIndex<T>(arr: T[], keyFunc: KeyFuncType<T>): number | undefined;
export function getMinIndex<T>(
  arr: T[],
  keyFunc: KeyFuncType<T> | undefined = undefined
): number | undefined {
  return _getExtramumIndex(arr, keyFunc, false);
}

export function getMaxValue<T extends Comparable>(arr: T[]): T | undefined;
export function getMaxValue<T extends Comparable>(arr: T[], keyFunc: undefined): T | undefined;
export function getMaxValue<T extends Comparable, K>(
  arr: T[],
  keyFunc: undefined,
  isEmpty: K
): T | K;
export function getMaxValue<T>(arr: T[], keyFunc: KeyFuncType<T>): T | undefined;
export function getMaxValue<T, K>(arr: T[], keyFunc: KeyFuncType<T>, ifEmpty: K): T | K;
export function getMaxValue(arr: any[], keyFunc: any = undefined, ifEmpty: any = undefined): any {
  const index = getMaxIndex(arr, keyFunc);
  return index === undefined ? ifEmpty : arr[index];
}

export function getMinValue<T extends Comparable>(arr: T[]): T | undefined;
export function getMinValue<T extends Comparable>(arr: T[], keyFunc: undefined): T | undefined;
export function getMinValue<T extends Comparable, K>(
  arr: T[],
  keyFunc: undefined,
  isEmpty: K
): T | K;
export function getMinValue<T>(arr: T[], keyFunc: KeyFuncType<T>): T | undefined;
export function getMinValue<T, K>(arr: T[], keyFunc: KeyFuncType<T>, ifEmpty: K): T | K;
export function getMinValue(arr: any[], keyFunc: any = undefined, ifEmpty: any = undefined): any {
  const index = getMinIndex(arr, keyFunc);
  return index === undefined ? ifEmpty : arr[index];
}
