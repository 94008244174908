import { Autocomplete, TextField, Typography } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import * as React from 'react';
import LoadTiffTileLayers, { CustomTileLayer } from './LoadTifTileLayers';
import { SiteDetails } from '../../../Generated/ExoDBAPI';
import ManageLoadedTiffs from './ManageLoadedTiffs';
import ManageGeoJsonLayers from './ManageGeoJsonLayers';
import { GeoJsonLayer } from '../../../Utils/layerUtils';
import UploadShapefile from './layerUploaders/UploadShapefile';
import UploadTiff from './layerUploaders/UploadTiff';
import UploadExoSpray from './layerUploaders/UploadExoSpray';
import { DrawnContext } from '../Drawns/DrawnFileData';
import UploadDWGFile from './layerUploaders/UploadDWGFile';

export enum SupportedFileTypes {
  NO_TYPE = '',
  TIFF = 'Tiff',
  SHAPEFILE = 'Shapefile',
  DWG = 'DWG',
  EXOSPARY = 'ExoSpray'
}

function ManageFileLayersTab({
  site,
  setExtraTileLayersPaths,
  loadedLayers,
  geoJsonLayer,
  setGeoJsonLayers,
  externallySelectedLayerId,
  drawnContext
}: UploadFilesTabProps) {
  const [selectedFileType, setSelectedFileType] = React.useState<SupportedFileTypes | null>(null);
  const [inputValue, setInputValue] = React.useState<string>('');

  return (
    <Stack direction="row">
      <span className="tabsBody">
        <Box sx={{ maxWidth: 600, overflowX: 'hidden' }}>
          <Typography variant="h5">Load local file</Typography>
          <Autocomplete
            options={[
              SupportedFileTypes.TIFF,
              SupportedFileTypes.SHAPEFILE,
              SupportedFileTypes.DWG,
              SupportedFileTypes.EXOSPARY
            ]}
            renderInput={(params) => <TextField {...params} label="Select File Type" />}
            value={selectedFileType}
            inputValue={inputValue}
            onChange={(e: any, newValue: SupportedFileTypes | null) =>
              setSelectedFileType(newValue)
            }
            onInputChange={(e: any, newInputValue: string) => {
              setInputValue(newInputValue);
            }}
          />
          {selectedFileType === SupportedFileTypes.TIFF && <UploadTiff plannedSite={site} />}
          {selectedFileType === SupportedFileTypes.SHAPEFILE && (
            <UploadShapefile plannedSite={site} />
          )}
          {selectedFileType === SupportedFileTypes.EXOSPARY && (
            <UploadExoSpray plannedSite={site} />
          )}
          {selectedFileType === SupportedFileTypes.DWG && <UploadDWGFile plannedSite={site} />}
        </Box>
      </span>
      <span className="tabsBody">
        <LoadTiffTileLayers
          site={site}
          setLoadedLayers={setExtraTileLayersPaths}
          loadedLayers={loadedLayers}
        />
        <ManageLoadedTiffs setLoadedLayers={setExtraTileLayersPaths} layers={loadedLayers} />
      </span>
      <Container sx={{ margin: 0, width: 600 }}>
        <ManageGeoJsonLayers
          layers={geoJsonLayer}
          setLayers={setGeoJsonLayers}
          site={site}
          externallySelectedLayerId={externallySelectedLayerId}
          drawnContext={drawnContext}
        />
      </Container>
    </Stack>
  );
}

export interface UploadFilesTabProps {
  site?: SiteDetails;
  setExtraTileLayersPaths: (layers: CustomTileLayer[]) => void;
  loadedLayers: CustomTileLayer[];
  geoJsonLayer: GeoJsonLayer[];
  setGeoJsonLayers: React.Dispatch<React.SetStateAction<GeoJsonLayer[]>>;
  externallySelectedLayerId?: string;
  drawnContext: DrawnContext;
}

export default ManageFileLayersTab;
