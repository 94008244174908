import {useMap, useMapEvents} from "react-leaflet";
import {useEffect, useState} from "react";
import * as React from "react";

interface Result {
    distance: number;
    setPixels: (val: number) => void;
}

function usePixelsToMeters(): Result {
    const map = useMap();
    const [distance, setDistance] = useState(0);
    const [pixels, setPixels] = useState(0);

    const [zoomLevel, setZoomLevel] = React.useState(map.getZoom()); // initial zoom level provided for MapContainer

    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });

    const pixelsToMeters = (pixelsDistance: number): number => {
        const containerMidHeight = map.getSize().y / 2;

        const point1 = map.containerPointToLatLng([0, containerMidHeight]);
        const point2 = map.containerPointToLatLng([pixelsDistance, containerMidHeight]);

        return point1.distanceTo(point2);
    }

    useEffect(() => {
        if ((map as any)._loaded) {
            setDistance(pixelsToMeters(pixels))
        }
    }, [pixels, zoomLevel]);

    return {
        distance,
        setPixels
    };
}

export default usePixelsToMeters;
