import axios from "axios";

import * as React from "react";
import { LayerGroup, LayersControl } from "react-leaflet";
import { EsriLayerGroup } from "./EsriLayer";
import { layerInfo } from "./EsriMapServer";

function EsriFeatureServer({ url, namePrefix = "" }: EsriFeatureServerProps) {
  const [layersInfo, setLayersInfo] = React.useState<layerInfo[]>([]);

  React.useEffect(() => {
    axios.get(`${url}/?f=pjson`).then((resp) => {
      if (resp.status !== 200) {
        console.log("Got an error while trying get Feature server", resp.data);
        return;
      }
      setLayersInfo(resp.data.layers.map((layer: any) => ({ id: layer.id, name: layer.name })));
    });
  }, []);
  return (
    <>
      {layersInfo.map((layer) => (
        <EsriLayerGroup
          name={`${namePrefix}-${layer.name}`}
          url={`${url}/${layer.id}`}
          key={`${namePrefix}-${layer.name}`}
        />
      ))}
    </>
  );
}

export interface EsriFeatureServerProps {
  url: string;
  namePrefix?: string;
}

export default EsriFeatureServer;
