import * as React from "react";
import { Dict } from "../../BasicTypes";

const ScanStatusContext = React.createContext<{
  registerNode: (data: NodeData) => string;
  updateNodeData: (id: string, data: NodeData) => void;
  reportClick: (id: string) => void;
  pickedNodeId: string | undefined;
  currentPickedNode: NodeData | undefined;
}>({
  registerNode: (data) => "dont",
  updateNodeData: (id, data) => {},
  reportClick: (id) => {},
  pickedNodeId: undefined,
  currentPickedNode: undefined,
});

export function ScanStatusProvider({ children }: any) {
  const [nodesData, setNodesData] = React.useState<Dict<NodeData>>({});
  const [pickedNodeId, setPickedNodeId] = React.useState<string>();

  function registerNode(data: NodeData) {
    const newId = crypto.randomUUID();
    setNodesData((oldVal) => {
      const newVal = { ...oldVal };
      newVal[newId] = data;
      return newVal;
    });
    return newId;
  }

  function updateNodeData(id: string, data: NodeData) {
    setNodesData((oldVal) => {
      const newVal = { ...oldVal };
      newVal[id] = data;
      return newVal;
    });
  }

  function reportClick(id: string) {
    if (pickedNodeId !== id) {
      setPickedNodeId(id);
    } else {
      setPickedNodeId(undefined);
    }
  }

  return (
    <ScanStatusContext.Provider
      value={{
        registerNode,
        updateNodeData,
        reportClick,
        pickedNodeId,
        currentPickedNode: pickedNodeId ? nodesData[pickedNodeId] : undefined,
      }}
    >
      {children}
    </ScanStatusContext.Provider>
  );
}

export interface NodeData {
  sections?: React.ReactNode | React.ReactNode[];
}

export default ScanStatusContext;
