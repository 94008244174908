import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ClientDetails, SiteType, StateDetails } from "../../Generated/ExoDBAPI";
import { standardCardCreator } from "../../Utils/siteCardsCreators";
import ClientAdminForm from "../AdminForms/ClientAdminForm";
import StateAdminForm from "../AdminForms/StateAdminForm";
import UserContext from "../Contexts/UserContext";
import RequestLogIn from "../RequestLogIn";
import TabForm from "../Templates/TabForm";
import SitesViewer from "./SitesViewer";

/**
 * The admin page for creating/editing/deleting entities in the db
 * @returns
 */
function AdminPage({ states, clients, siteTypes }: AdminPageProps) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <>
      {user === undefined ? (
        <RequestLogIn />
      ) : (
        <>
          <div className="pageWrapper">
            <TabForm
              tabs={[
                {
                  tabName: "Sites",
                  shouldReload: false,
                  tabElement: (
                    <SitesViewer
                      states={states}
                      siteTypes={siteTypes}
                      clients={clients}
                      siteCardCreator={standardCardCreator((site) => {
                        navigate(`/sites/${site.id}/admin`);
                      })}
                    />
                  ),
                },
                {
                  tabName: " States",
                  tabElement: <StateAdminForm states={states} />,
                  shouldReload: false,
                },
                {
                  tabName: "Clients",
                  tabElement: <ClientAdminForm clients={clients} />,
                  shouldReload: false,
                },
              ]}
            />
          </div>
        </>
      )}
    </>
  );
}

interface AdminPageProps {
  states: StateDetails[];
  clients: ClientDetails[];
  siteTypes: SiteType[];
}

export default AdminPage;
