import React from "react";
import { AnalysisProductsSite } from "../../../Generated/ExoDBAPI";
import { MapFeaturesStatus } from "../DataTypes/MapFeatures";
import { FeatureReporters } from "../Getters/ExoFuserEntitiesGetter";

import InvalidEntitiesView from "./InvalidEntitiesView";
import LogTransmitterView from "./LogTransmitterView";
import { Stack } from "@mui/material";
import MissingEntitiesForm from "./MissingEntitiesForm";

function RetryTab({ analysisSite, mapFeatureStatus, s3FeaturesIds, reporters, triggerRefresh, setMaxAllowedFrequency }: RetryTabProps) {
  function getAllFeaturesIds() {
    const ids: string[] = [];
    ids.push(...mapFeatureStatus.allFeatures.pipes.map((f) => f.header.s3Ref));
    ids.push(...mapFeatureStatus.allFeatures.transmitters.map((f) => f.header.s3Ref));
    ids.push(...mapFeatureStatus.allFeatures.travelCourses.map((f) => f.header.s3Ref));
    ids.push(...mapFeatureStatus.allFeatures.polygons.map((f) => f.header.s3Ref));
    return ids;
  }

  return (
    <span className="tabsContainer">
      <span className="tabsBody">
        <Stack spacing={2} sx={{ width: 1200 }}>
          <MissingEntitiesForm
            loading={!mapFeatureStatus.doneLoading}
            s3FeaturesIds={s3FeaturesIds}
            presentFeaturesIds={getAllFeaturesIds()}
            triggerRefresh={triggerRefresh}
          />
        </Stack>
      </span>
      <span className="tabsBody">
        <h3>Invalidated Entities</h3>
        <InvalidEntitiesView analysisSite={analysisSite} mapFeatureStatus={mapFeatureStatus} reporters={reporters} />
      </span>
      <span className="tabsBody">
        <h3>Log Transmitters</h3>
        <LogTransmitterView mapFeatureStatus={mapFeatureStatus} setMaxAllowedFrequency={setMaxAllowedFrequency} />
      </span>
    </span>
  );
}

export interface RetryTabProps {
  analysisSite?: AnalysisProductsSite;
  mapFeatureStatus: MapFeaturesStatus;
  s3FeaturesIds: string[];
  reporters: FeatureReporters;
  triggerRefresh: () => void;
  setMaxAllowedFrequency: (arg: number | undefined) => void;
}

export default RetryTab;
