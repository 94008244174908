import React, { RefObject, useMemo } from 'react';
import { LatLngLiteral } from 'leaflet';
import Control from 'react-leaflet-custom-control';
import { ButtonGroup, Button, Tooltip } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { paintSquare } from './PGE/ImageGenerate';
import Settings from './Settings';
import DrawLineOnMap from './MeasurementDrawLine';
import { v4 as uuidv4 } from 'uuid';
import {
  PictureAsPdf as PictureAsPdfIcon,
  LocalGasStation as LocalGasStationIcon,
  Settings as SettingsIcon,
  Straighten as StraightenIcon,
  CallMissed as CallMissedIcon,
  ElectricBolt as ElectricBoltIcon,
  LineAxis as LineAxisIcon,
  LineStyle as LineStyleIcon,
  Message as MessageIcon,
  OpenInFull as OpenInFullIcon,
  Bookmark as BookmarkIcon,
  Explore as ExploreIcon,
  LocalParking as LocalParkingIcon
} from '@mui/icons-material';
import ArrowLine from './ArrowLine/ArrowLine';
import { SelectedToolsMode, ToolObject, convertToSelectedToolsMode } from './MeasurementUtil';
import LineText from '../LineText/LineText';
import {
  MappingEntity,
  useCreateMappingEntityMutation,
  useDeleteMappingEntityMutation,
  useUpdateMappingEntityMutation
} from '../../../Generated/Graphql';
import { createEntityObject, updateEntityObject } from '../../../Services/mapEntity';
import { getPropertiesValue } from '../../../Services/exoTool';
import { useMap } from 'react-leaflet';
import ArrowDepth from './DepthLine/ArrowDepth';
import { EntityAction } from '../../../Types/MappingEntities';
import TextBox from './TextArea/TextBox';
import SvgStickers from '../../SVG/SvgStickers';
import CreateAlgoLineTool from './ExternalLine/CreateAlgoLineTool';
import { useMapControlsStore } from '../store';

type ToolsProps = {
  siteId: string | undefined;
  mappingEntities: MappingEntity[];
  mapContainerRef: RefObject<HTMLDivElement>;
  displayedEntitiesIds: string[];
};

export default function ExoTools({
  siteId,
  mappingEntities,
  mapContainerRef,
  displayedEntitiesIds
}: ToolsProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedTool, setSelectedTool] = React.useState<SelectedToolsMode>(SelectedToolsMode.NONE);
  const [tools, setTools] = React.useState<ToolObject[]>([]);
  const [points, setPoints] = React.useState<LatLngLiteral[]>([]);
  const [createMappingEntity] = useCreateMappingEntityMutation();
  const [updateMappingEntity] = useUpdateMappingEntityMutation();
  const [deleteMappingEntity] = useDeleteMappingEntityMutation();
  const [properties, setProperties] = React.useState<string>('');
  const [objectType, setObjectType] = React.useState<SelectedToolsMode>(SelectedToolsMode.NONE);
  const map = useMap();

  const { unitMeasures } = useMapControlsStore();

  const handleAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setSelectedTool(SelectedToolsMode.SETTINGS);
  };
  const handleCloseAnchor = () => {
    setAnchorEl(null);
    setSelectedTool(SelectedToolsMode.NONE);
    map?.scrollWheelZoom.enable();
  };

  const handleSelectedTool = useMemo(
    () => (tool: SelectedToolsMode) => {
      setObjectType(tool);
      setSelectedTool(tool !== selectedTool ? tool : SelectedToolsMode.NONE);
    },
    [selectedTool, setSelectedTool]
  );

  const styleTool = useMemo(
    () => (tool: SelectedToolsMode) => {
      return {
        backgroundColor: tool === selectedTool ? 'green' : 'white',
        color: tool === selectedTool ? 'white' : 'black',
        marginBottom: '4px',
        boxShadow: '0'
      };
    },
    [selectedTool]
  );

  async function createNewTool(tool: ToolObject) {
    const pointsCopy = [...tool.points];
    if (!siteId) return;
    const variables = createEntityObject(
      pointsCopy,
      tool.type,
      siteId,
      tool.properties ? JSON.stringify(tool.properties) : ''
    );
    const entity = await createMappingEntity({ variables });
    const id = entity?.data?.createMappingEntity?.id;

    setTools((previousTools: ToolObject[]) => [
      ...previousTools,
      {
        type: tool.type,
        points: pointsCopy,
        text: getPropertiesValue(tool.properties, 'text', '') || '',
        properties: tool.properties,
        id: id || uuidv4()
      }
    ]);
  }

  React.useEffect(() => {
    const mappingEntity = mappingEntities
      .map((entity: MappingEntity) => {
        const pointsArray = JSON.parse(entity.geo_data)?.geometry?.coordinates || [];
        const properties = JSON.parse(entity?.properties || '{}');
        const obj: ToolObject = {
          type: convertToSelectedToolsMode(entity.object_type) ?? SelectedToolsMode.NONE,
          points: pointsArray,
          text: properties?.text || '',
          id: entity.id,
          properties
        };
        if (!entity || !entity?.object_type) return null;
        // if (obj.points.length !== 2) return null;
        if (
          !obj.points[0]?.lat ||
          !obj.points[0]?.lng ||
          !obj.points[1]?.lat ||
          !obj.points[1]?.lng
        )
          return null;
        return obj;
      })
      .filter((tool: ToolObject | null) => tool !== null) as ToolObject[];
    setTools([...mappingEntity]);
  }, [mappingEntities]);

  React.useEffect(() => {
    const createEntityAsync = async () => {
      if (points.length !== 2) return;
      const copiedPoints = [...points];

      async function createEntity() {
        if (!objectType || !siteId) return;
        const variables = createEntityObject(
          [copiedPoints[0], copiedPoints[1]] as LatLngLiteral[],
          objectType,
          siteId,
          properties || ''
        );
        console.log('Creating entity', variables);
        return await createMappingEntity({ variables });
      }

      const entity = await createEntity();
      const id = entity?.data?.createMappingEntity?.id;

      setTools((previousTools: ToolObject[]) => [
        ...previousTools,
        {
          type: selectedTool,
          points: [copiedPoints[0], copiedPoints[1]],
          text: getPropertiesValue(properties, 'text', '') || '',
          properties: properties || {},
          id: id || uuidv4()
        }
      ]);
      setPoints([]);
      setProperties('');
      setSelectedTool(SelectedToolsMode.NONE);
    };

    createEntityAsync();
  }, [points, selectedTool, setTools, siteId, createMappingEntity, objectType, properties]);

  const updateData = (action: EntityAction, tool: ToolObject) => {
    if (action === EntityAction.DELETE && tool.id) {
      deleteMappingEntity({ variables: { id: tool.id } });
    }
    if (action === EntityAction.INSERT) {
      const variables = createEntityObject(
        [tool.points[0], tool.points[1]] as LatLngLiteral[],
        tool.type,
        siteId || '',
        tool.text,
        JSON.stringify(tool.properties) || ''
      );
      createMappingEntity({ variables });
    }
    if (action === EntityAction.UPDATE && tool.id) {
      const variables = updateEntityObject(
        tool.id,
        [tool.points[0], tool.points[1]] as LatLngLiteral[],
        tool.type,
        JSON.stringify(tool.properties) || ''
      );
      updateMappingEntity({ variables });
    }
  };

  return (
    <Control position="topleft">
      <ButtonGroup
        orientation="vertical"
        variant="contained"
        size="small"
        sx={{
          '& .MuiButton-root': {
            backgroundColor: 'white',
            width: '20px',
            fontSize: 6,
            minWidth: '32px',
            border: '1px solid #ccc 0.5px'
          }
        }}>
        <Tooltip placement="left" title="Settings">
          <>
            <Button
              color="inherit"
              onClick={handleAnchor}
              style={styleTool(SelectedToolsMode.SETTINGS)}>
              <SettingsIcon />
            </Button>
            <Popover
              open={selectedTool === SelectedToolsMode.SETTINGS}
              anchorEl={anchorEl}
              onClose={handleCloseAnchor}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}>
              <Typography>
                <Settings />
              </Typography>
            </Popover>
          </>
        </Tooltip>

        <Tooltip placement="left" title="Pdf Generate">
          <Button
            color="inherit"
            onClick={() => {
              paintSquare(mapContainerRef, map);
              handleSelectedTool(SelectedToolsMode.PDF);
            }}
            style={styleTool(SelectedToolsMode.PDF)}>
            <PictureAsPdfIcon />
          </Button>
        </Tooltip>

        <Tooltip placement="left" title="Measurement">
          <Button
            color="inherit"
            onClick={() => handleSelectedTool(SelectedToolsMode.MEASUREMENT)}
            style={styleTool(SelectedToolsMode.MEASUREMENT)}>
            <StraightenIcon />
          </Button>
        </Tooltip>
        {selectedTool === SelectedToolsMode.MEASUREMENT && (
          <DrawLineOnMap unitMeasures={unitMeasures} />
        )}

        <Tooltip placement="left" title="Straight Line">
          <Button
            color="inherit"
            onClick={() => handleSelectedTool(SelectedToolsMode.STRAIGHT_LINE)}
            style={styleTool(SelectedToolsMode.STRAIGHT_LINE)}>
            <LineAxisIcon />
          </Button>
        </Tooltip>
        {selectedTool === SelectedToolsMode.STRAIGHT_LINE && (
          <ArrowLine
            data={{
              type: SelectedToolsMode.STRAIGHT_LINE,
              id: '',
              points: [],
              text: '',
              properties: {}
            }}
            updateData={updateData}
            showStart={false}
            showEnd={false}
            showText={false}
            color="red"
            points={[]}
            setPoints={setPoints}
          />
        )}

        <Tooltip placement="left" title="Dash Line">
          <Button
            color="inherit"
            onClick={() => handleSelectedTool(SelectedToolsMode.DASH_LINE)}
            style={styleTool(SelectedToolsMode.DASH_LINE)}>
            <LineStyleIcon />
          </Button>
        </Tooltip>
        {selectedTool === SelectedToolsMode.DASH_LINE && (
          <ArrowLine
            data={{
              type: SelectedToolsMode.DASH_LINE,
              id: '',
              points: [],
              text: '',
              properties: {}
            }}
            updateData={updateData}
            showStart={false}
            showEnd={false}
            showText={false}
            lineType="dash"
            color="red"
            points={[]}
            setPoints={setPoints}
          />
        )}

        <Tooltip placement="left" title="Electric Line">
          <Button
            color="inherit"
            onClick={() => handleSelectedTool(SelectedToolsMode.ELECTRIC_LINE)}
            style={styleTool(SelectedToolsMode.ELECTRIC_LINE)}>
            <ElectricBoltIcon />
          </Button>
        </Tooltip>
        {selectedTool === SelectedToolsMode.ELECTRIC_LINE && (
          <LineText
            data={{
              type: SelectedToolsMode.ELECTRIC_LINE,
              id: '',
              points: [],
              text: '',
              properties: {}
            }}
            updateData={updateData}
            setPoints={setPoints}
            points={[]}
            text="E"
          />
        )}

        <Tooltip placement="left" title="Gas Line">
          <Button
            color="inherit"
            onClick={() => handleSelectedTool(SelectedToolsMode.GAS_LINE)}
            style={styleTool(SelectedToolsMode.GAS_LINE)}>
            <LocalGasStationIcon />
          </Button>
        </Tooltip>
        {selectedTool === SelectedToolsMode.GAS_LINE && (
          <LineText
            data={{
              type: SelectedToolsMode.GAS_LINE,
              id: '',
              points: [],
              text: '',
              properties: {}
            }}
            updateData={updateData}
            setPoints={setPoints}
            points={[]}
            text={'G'}
          />
        )}

        <Tooltip placement="left" title="Depth Line">
          <Button
            color="inherit"
            onClick={() => handleSelectedTool(SelectedToolsMode.DEPTH_LINE)}
            style={styleTool(SelectedToolsMode.DEPTH_LINE)}>
            <CallMissedIcon />
          </Button>
        </Tooltip>
        {selectedTool === SelectedToolsMode.DEPTH_LINE && (
          <ArrowDepth
            points={[]}
            setPoints={setPoints}
            updateData={updateData}
            data={{
              id: '',
              type: SelectedToolsMode.DEPTH_LINE,
              points: [],
              text: '',
              properties: { angle: 0 }
            }}
          />
        )}

        <Tooltip placement="left" title="Line Width Toggle">
          <Button
            color="inherit"
            onClick={() => handleSelectedTool(SelectedToolsMode.LINE_WIDTH)}
            style={styleTool(SelectedToolsMode.LINE_WIDTH)}>
            <OpenInFullIcon />
          </Button>
        </Tooltip>
        {selectedTool === SelectedToolsMode.LINE_WIDTH && (
          <ArrowLine
            data={{
              type: SelectedToolsMode.LINE_WIDTH,
              id: '',
              points: [],
              text: '',
              properties: {}
            }}
            updateData={updateData}
            points={points}
            setPoints={setPoints}
          />
        )}

        <Tooltip placement="left" title="Message">
          <Button
            color="inherit"
            onClick={() => handleSelectedTool(SelectedToolsMode.MESSAGE)}
            style={styleTool(SelectedToolsMode.MESSAGE)}>
            <MessageIcon />
          </Button>
        </Tooltip>
        {selectedTool === SelectedToolsMode.MESSAGE && (
          <TextBox
            points={[]}
            setPoints={setPoints}
            updateData={updateData}
            data={{
              id: '',
              type: SelectedToolsMode.MESSAGE,
              points: [],
              text: '',
              properties: { angle: 0 }
            }}
          />
        )}
        <Tooltip placement="right" title="Draw Images">
          <div>
            <Button
              color="inherit"
              onClick={() => handleSelectedTool(SelectedToolsMode.STICKERS)}
              style={styleTool(SelectedToolsMode.STICKERS)}>
              <BookmarkIcon />
            </Button>
          </div>
        </Tooltip>
        {[
          SelectedToolsMode.STICKERS,
          SelectedToolsMode.STICKER_NORTH,
          SelectedToolsMode.STICKER_P
        ].includes(selectedTool) && (
          <ButtonGroup variant="contained" color="primary">
            <Button
              color="inherit"
              onClick={() => handleSelectedTool(SelectedToolsMode.STICKER_NORTH)}
              style={styleTool(SelectedToolsMode.STICKER_NORTH)}>
              <ExploreIcon />
            </Button>
            <Button
              color="inherit"
              onClick={() => handleSelectedTool(SelectedToolsMode.STICKER_P)}
              style={styleTool(SelectedToolsMode.STICKER_P)}>
              <LocalParkingIcon />
            </Button>
          </ButtonGroup>
        )}
        {selectedTool === SelectedToolsMode.STICKER_NORTH && (
          <SvgStickers
            points={[]}
            setPoints={setPoints}
            name="north-arrow"
            data={{
              type: SelectedToolsMode.STICKER_NORTH,
              id: '',
              points: [],
              text: '',
              properties: {}
            }}
            updateData={updateData}
          />
        )}
        {selectedTool === SelectedToolsMode.STICKER_P && (
          <SvgStickers
            points={[]}
            setPoints={setPoints}
            name="letter-p"
            data={{
              type: SelectedToolsMode.STICKER_P,
              id: '',
              points: [],
              text: '',
              properties: {}
            }}
            updateData={updateData}
          />
        )}
        <CreateAlgoLineTool dispalyedPipesIds={displayedEntitiesIds} createTool={createNewTool} />

        <>
          {tools.map((cur) => (
            <div key={cur.id}>
              {cur.type === SelectedToolsMode.STRAIGHT_LINE && (
                <ArrowLine
                  key={cur.id}
                  data={cur}
                  updateData={updateData}
                  showStart={false}
                  showEnd={false}
                  showText={false}
                  color="red"
                  points={cur.points}
                  setPoints={setPoints}
                />
              )}
              {cur.type === SelectedToolsMode.DASH_LINE && (
                <ArrowLine
                  key={cur.id}
                  data={cur}
                  updateData={updateData}
                  showStart={false}
                  showEnd={false}
                  showText={false}
                  lineType="dash"
                  color="red"
                  points={cur.points}
                  setPoints={setPoints}
                />
              )}
              {cur.type === SelectedToolsMode.LINE_WIDTH && (
                <ArrowLine
                  key={cur.id}
                  data={cur}
                  updateData={updateData}
                  color="black"
                  points={cur.points}
                  setPoints={setPoints}
                />
              )}
              {cur.type === SelectedToolsMode.ELECTRIC_LINE && (
                <LineText
                  key={cur.id}
                  updateData={updateData}
                  data={cur}
                  setPoints={setPoints}
                  text="E"
                  points={cur.points}
                />
              )}
              {cur.type === SelectedToolsMode.GAS_LINE && (
                <LineText
                  key={cur.id}
                  updateData={updateData}
                  data={cur}
                  setPoints={setPoints}
                  text="G"
                  points={cur.points}
                />
              )}
              {cur.type === SelectedToolsMode.DEPTH_LINE && (
                <ArrowDepth
                  key={cur.id}
                  points={cur.points}
                  color="black"
                  updateData={updateData}
                  data={cur}
                  setPoints={setPoints}
                />
              )}
              {cur.type === SelectedToolsMode.MESSAGE && (
                <TextBox
                  key={cur.id}
                  points={cur.points}
                  color="blue"
                  updateData={updateData}
                  data={cur}
                  setPoints={setPoints}
                />
              )}
              {cur.type === SelectedToolsMode.STICKER_NORTH && (
                <SvgStickers
                  key={cur.id}
                  points={cur.points}
                  name="north-arrow"
                  updateData={updateData}
                  data={cur}
                  setPoints={setPoints}
                />
              )}
              {cur.type === SelectedToolsMode.STICKER_P && (
                <SvgStickers
                  key={cur.id}
                  points={cur.points}
                  name="letter-p"
                  updateData={updateData}
                  data={cur}
                  setPoints={setPoints}
                />
              )}
            </div>
          ))}
        </>
      </ButtonGroup>
    </Control>
  );
}
