import { Card, Divider, Pagination } from '@mui/material';
import * as React from 'react';
import { Layer, findLayerByPredicate } from '../../../Utils/layerUtils';
import LayerListItem from './LayerListItem';
import { Dict } from '../../../BasicTypes';
import { publish } from '../../../Utils/events';

function LayersList<V, T>({
  layers,
  setLayers,
  color,
  reportInfoClick,
  reportCloseClick,
  toggleGlobalSelect,
  closeEnabled,
  externallySelectedLayerId,
  closeEnabledOnChildren = false,
  maxPageSize = 5,
  showSave = false
}: GeojsonLayersListProps<V, T>) {
  const [pageNum, setPageNum] = React.useState(1);

  React.useEffect(() => {
    if (externallySelectedLayerId === undefined) {
      return;
    }
    let currentLayerIndex = 0;
    for (const layer of layers) {
      const foundLayer = findLayerByPredicate(
        layer.subLayers ?? [],
        (l) => l.id === externallySelectedLayerId
      );
      if (foundLayer !== undefined || layer.id === externallySelectedLayerId) {
        setPageNum(~~(currentLayerIndex / maxPageSize) + 1);
        break;
      }
      currentLayerIndex++;
    }
  }, [externallySelectedLayerId]);

  function changeColor(newColor: string, id: string, type?: string) {
    let newLayer = layers.find((l) => l.id === id);
    if (!newLayer) return;
    if (showSave) {
      publish('sendUpdate', {
        color: newColor,
        id,
        type
      });
    }
    newLayer.color = newColor;

    const setSubLayers = (layers?: Layer<V, T>[]) => {
      if (!layers?.length) return;
      layers.forEach((l) =>
        l.subLayers?.length
          ? setSubLayers(l.subLayers)
          : !l.overrideColor
          ? (l.color = newColor)
          : l.color
      );
    };

    setSubLayers(newLayer.subLayers);
    setLayers([...layers]);
  }

  function toggleVisibility(id: string) {
    const tempArr = [...layers];
    const layerIndex = tempArr.findIndex((l) => l.id === id);
    tempArr[layerIndex] = { ...tempArr[layerIndex], visible: !tempArr[layerIndex].visible };
    setLayers(tempArr);
  }

  function removeLayer(id: string) {
    const tempArr = [...layers];
    const layerIndex = tempArr.findIndex((l) => l.id === id);
    tempArr.splice(layerIndex, 1);
    setLayers(tempArr);
  }

  function setLayer(layer: Layer<V, T>, id: string) {
    const tempArr = [...layers];
    const layerIndex = tempArr.findIndex((l) => l.id === id);
    tempArr[layerIndex] = layer;
    setLayers(tempArr);
  }

  return (
    <>
      <span>
        {[...layers]
          .slice((pageNum - 1) * maxPageSize, pageNum * maxPageSize)
          .reverse()
          .map((l, i) => (
            <div>
              <Card
                sx={{
                  backgroundColor: color ? color : '#fafafa',
                  border: l.selected
                    ? '#777777 solid 2px'
                    : `${color ? color : '#fafafa'} solid 2px`
                }}
                key={l.id}>
                <LayerListItem
                  layer={l}
                  reporters={{
                    changeColor,
                    toggleVisibility,
                    removeLayer,
                    setLayer: (layer) => setLayer(layer, l.id),
                    reportInfoClick,
                    toggleGlobalSelect
                  }}
                  reportCloseClick={reportCloseClick}
                  closeEnabled={closeEnabled}
                  closeEnabledOnChildren={closeEnabledOnChildren}
                  externallySelectedLayerId={externallySelectedLayerId}
                  showSave={showSave}
                />
              </Card>
              <Divider />
            </div>
          ))}
        <Pagination
          count={Math.ceil(layers.length / maxPageSize)}
          page={pageNum}
          onChange={(_, num) => setPageNum(num)}
        />
      </span>
    </>
  );
}

export interface GeojsonLayersListProps<V, T> {
  layers: Layer<V, T>[];
  setLayers: (layers: Layer<V, T>[]) => void;
  reportInfoClick: (
    layerId: string,
    layerName: string,
    properties: Dict<any>,
    x: number,
    y: number
  ) => void;
  reportCloseClick?: (layerId: string) => void;
  toggleGlobalSelect: (layerId: string) => void;
  color?: string;
  closeEnabled?: boolean;
  closeEnabledOnChildren?: boolean;
  maxPageSize?: number;
  externallySelectedLayerId?: string;
  showSave?: boolean;
}

export default LayersList;
