import React from "react";

function InfoForm({ formFields }: FormFields) {
  function createSection(fieldName: string, fieldValue: string) {
    return (
      <span>
        <span className="siteInfoLabel">{fieldName}: </span> {fieldValue}
      </span>
    );
  }

  return <div className="detailedSiteView">{formFields.map((f) => createSection(f.name, f.value))}</div>;
}

export interface FormField {
  name: string;
  value: string;
}

export interface FormFields {
  formFields: FormField[];
}

export default InfoForm;
