import { useEffect } from 'react';
import { useGetTargetScanLazyQuery } from '../../../Generated/Graphql';
import SvgTarget from '../../SVG/SvgTargetType';
import { TargetType } from '../../../Constant/entity';
import { ParsedTargetObect } from '../../../Types/MappingEntities';
import { usePoiStore } from '../POI/store';
import { parseTargets } from '../POI/utils';

interface ScanTargetProps {
  siteId: string | undefined;
  onChangePOI: (poi: ParsedTargetObect) => void;
}

function ScanTarget({ siteId, onChangePOI }: ScanTargetProps) {
  const { setPois, setSelectedPoi, setFilteredPois, filteredPois } = usePoiStore();
  const [scanTargetQuery] = useGetTargetScanLazyQuery();

  useEffect(() => {
    (async () => {
      setSelectedPoi(undefined);
      if (!siteId) return;
      const res = await scanTargetQuery({ variables: { siteId } });
      const parsedData = parseTargets(res.data);
      setPois(parsedData);
      setFilteredPois(parsedData);
    })();
  }, [scanTargetQuery, siteId]);

  return (
    <>
      {filteredPois.map((targetObject) => {
        const mData = targetObject.metadata;
        const target_type =
          mData?.additional_information?.target?.target_type || TargetType.UNKNOWN;
        const scanTypeName =
          Object.keys(TargetType)
            .find((key) => TargetType[key as keyof typeof TargetType] === target_type)
            ?.toLowerCase() ?? 'default';
        return (
          <SvgTarget
            key={targetObject.id}
            name={`target-type-` + scanTypeName}
            data={targetObject}
            setSelectedManhole={(poi) => {
              setSelectedPoi(poi);
              onChangePOI(poi);
            }}
          />
        );
      })}
    </>
  );
}

export default ScanTarget;
