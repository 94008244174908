import React from "react";
import { Button } from "@mui/material";
import { AnalysisProductsPipe } from "../DataTypes/MapEntities";

export default function GoToPipesQASelectedpipe({ pipe }: GoToPipesQA) {
  return (
    <Button
      variant="outlined"
      value="Go To PipesQA"
      className="selectButton"
      disabled={pipe === undefined || process.env.REACT_APP_PIPES_QA_URL === undefined}
      onClick={() => {
        const pipesQAUrl = process.env.REACT_APP_PIPES_QA_URL;
        if (pipesQAUrl !== undefined) {
          window.open(
            new URL(`main?currentId=${pipe?.header.id}&interpolationType=y-amplitude-1_None`, pipesQAUrl).toString()
          );
        }
      }}
    >
      Go To Selected Pipe In PipesQA
    </Button>
  );
}

export interface GoToPipesQA {
  pipe?: AnalysisProductsPipe;
}
