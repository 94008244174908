import L, {LatLngExpression} from "leaflet";
import ScalableText from "./ScalableText";

const defaultTextClassName = 'text';
const rotationClassName = 'rotation-container';
const FONT_SIZE = 0.1;

function RotatableText(props: RotatableTextProps) {
    const {text, position, rotation, fontSize = FONT_SIZE, onAfterRender, interactive = true, textClassName = ''} = props;
    const usedTextClassName = `${defaultTextClassName} ${textClassName}`

    const updateHtml = (marker: L.Marker | null): void => {
        if (!marker) {
            return;
        }

        const markerElement = (marker as unknown as { _icon: HTMLDivElement })._icon;

        if (!markerElement) {
            return;
        }

        const rotationElement = markerElement.getElementsByClassName(rotationClassName)[0] as HTMLDivElement;

        if (!rotationElement) {
            return;
        }

        rotationElement.style.transform = `rotate(${rotation}deg)`;
        rotationElement.style.position = `relative`;
        rotationElement.style.whiteSpace = "nowrap";

        markerElement.style.marginTop = `${0}px`;
        markerElement.style.marginLeft = `${0}px`;

        const textElement = markerElement.getElementsByClassName(defaultTextClassName)[0] as HTMLDivElement;

        if (!textElement) {
            return;
        }

        textElement.style.position = `absolute`;
        onAfterRender?.(marker);
    }

    const html = `<div class="${rotationClassName}">
        <div class="${usedTextClassName}">${text}</div>
    </div>`;

    return (
        <ScalableText text={html} position={position} onAfterRender={updateHtml} interactive={interactive} fontSize={fontSize} />
    );
}

export interface RotatableTextProps {
    text: string;
    position: LatLngExpression;
    rotation: number;
    onAfterRender?: (marker: L.Marker | null) => void;
    fontSize?: number;
    interactive?: boolean;
    textClassName?: string;
}

export default RotatableText;
