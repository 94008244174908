import * as React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import LayersList from '../UploadFiles/LayersList';
import {
  PlannedPolygonLayer,
  findLayerByPredicate,
  globalSelectToggleRec
} from '../../../Utils/layerUtils';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { PropertiesModalConfig } from '../UploadFiles/ManageGeoJsonLayers';
import LayerPropertiesModal from '../UploadFiles/LayerPropertiesModal';
import { Dict } from '../../../BasicTypes';
import CreatePolygonModal from './CreatePolygonModal';
import CreateTransmitterModal from './CreateTransmitterModal';
import HelpModal from './HelpModal';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AreYouSureModal, { AreYouSureModalProps } from '../../Templates/AreYouSureModal';

export enum PlannedObjectType {
  POLYGON,
  TRANSMITTER
}
const emptyModalProps = {
  confirmationTitle: '',
  fields: [],
  open: false,
  acceptCallback: () => {},
  cancelCallback: () => {}
};
function ManagePolygonsForm({
  layers,
  setLayers,
  addPolygonClick,
  savePolygonClick,
  discardPolygonClick,
  deletePolygon,
  deleteTransmitter,
  updatePolygon,
  updateTransmitter,
  isDrawing,
  saveable,
  sensors
}: ManagePolygonsFormProps) {
  const [modalConfig, setModalConfig] = React.useState<PropertiesModalConfig>({
    layerId: '',
    visible: false,
    properties: {},
    coords: [0, 0],
    layerName: ''
  });
  const [showPolygonEditModal, setShowPolygonEditModal] = React.useState(false);
  const [showTransmitterEditModal, setShowTransmitterEditModal] = React.useState(false);
  const [areYouSureProps, setAreYouSureProps] =
    React.useState<AreYouSureModalProps>(emptyModalProps);
  const [selectedLayer, setSelectedLayer] = React.useState<PlannedPolygonLayer>();
  const [showHelpModal, setShowHelpModal] = React.useState(false);
  const cancelCallback = () => {
    setAreYouSureProps(emptyModalProps);
  };
  function globalSelectToggle(layerId: string) {
    const newLayers = globalSelectToggleRec(layerId, layers);
    setLayers(newLayers);
  }
  function handleEditLayerClicked() {
    if (selectedLayer?.data && selectedLayer?.data?.length > 2) {
      setShowPolygonEditModal(true);
    } else if (selectedLayer?.data && selectedLayer?.data?.length === 1) {
      setShowTransmitterEditModal(true);
    } else {
      console.error(
        `Unexpected planned object type ${selectedLayer?.metadata?.type} while trying to open metadata editing modal`
      );
      window.alert('Could not edit the layer');
    }
  }
  function handleShowAreYouSureModal({
    confirmationTitle,
    fields = [],
    open = true,
    acceptCallback,
    cancelCallback
  }: AreYouSureModalProps) {
    setAreYouSureProps({ confirmationTitle, fields, open, acceptCallback, cancelCallback });
  }
  function reportInfoClick(
    layerId: string,
    layerName: string,
    properties: Dict<any>,
    x: number,
    y: number
  ) {
    const selectedLayer = findLayerByPredicate(layers, (layer) => layer.id === layerId);
    setSelectedLayer(selectedLayer);
    if (modalConfig.layerId === layerId && modalConfig.visible === true) {
      const tempConfig = { ...modalConfig };
      setModalConfig({ ...tempConfig, visible: false });
      return;
    }
    const tempProperties = { ...properties };
    if (Object.keys(tempProperties).includes('sensorTypes')) {
      tempProperties['sensorTypes'] = tempProperties['sensorTypes'].join(', ');
    }
    setModalConfig({
      layerId: layerId,
      properties: tempProperties,
      coords: [x, y],
      layerName: layerName,
      visible: true
    });
  }
  function reportCloseClick(layerId: string) {
    const selectedLayer = findLayerByPredicate(layers, (layer) => layer.id === layerId);
    const layerType = selectedLayer?.data?.length === 1 ? 'transmitter' : 'polygon';
    if (selectedLayer !== undefined) {
      handleShowAreYouSureModal({
        confirmationTitle: `Are you sure you want to delete ${layerType} ${selectedLayer.name}?`,
        acceptCallback: async () => {
          layerType === 'polygon' ? await deletePolygon(layerId) : await deleteTransmitter(layerId);
          setAreYouSureProps(emptyModalProps);
        },
        open: true,
        cancelCallback: () => {
          setAreYouSureProps(emptyModalProps);
        }
      });
    }
  }

  function EditPolygon(
    name: string,
    sensors: string[],
    platforms: string[],
    comment: string,
    isRescan: boolean
  ) {
    setShowPolygonEditModal(false);
    setModalConfig((val) => ({ ...val, visible: false }));
    if (selectedLayer !== undefined) {
      selectedLayer.name = name;
      if (selectedLayer.metadata !== undefined) {
        selectedLayer.metadata.name = name;
        selectedLayer.metadata.comment = comment;
        selectedLayer.metadata.sensorTypes = sensors;
        selectedLayer.metadata.platforms = platforms;
        selectedLayer.metadata.isRescan = isRescan;
        const input = {
          id: selectedLayer.id,
          comment: comment,
          sensorModels: sensors,
          name: name,
          mountMethod: platforms,
          isRescan: isRescan
        };
        updatePolygon(input).then(
          () => setLayers([...layers]),
          (e) => console.error(e)
        );
      }
    }
  }

  function EditTransmitter(name: string, comment: string, chosenMethod: string) {
    setShowTransmitterEditModal(false);
    setModalConfig((val) => ({ ...val, visible: false }));
    if (selectedLayer !== undefined) {
      selectedLayer.name = name;
      if (selectedLayer.metadata !== undefined) {
        selectedLayer.metadata.name = name;
        selectedLayer.metadata.comment = comment;
        selectedLayer.metadata.method = chosenMethod;
      }
      const input = {
        id: selectedLayer.id,
        comment: comment,
        name: name,
        method: chosenMethod
      };
      updateTransmitter(input).then(
        () => setLayers([...layers]),
        (e) => console.error(e)
      );
      setLayers([...layers]);
    }
  }
  return (
    <>
      <AreYouSureModal {...areYouSureProps} cancelCallback={cancelCallback}></AreYouSureModal>
      <Stack direction={'row'} gap={1}>
        <Typography variant="h5">Polygons</Typography>
        <IconButton onClick={() => setShowHelpModal(true)}>
          <QuestionMarkIcon />
        </IconButton>
      </Stack>
      {isDrawing && saveable && (
        <IconButton onClick={savePolygonClick}>
          <CheckIcon />
        </IconButton>
      )}
      {isDrawing && !saveable && (
        <IconButton onClick={discardPolygonClick}>
          <CancelIcon />
        </IconButton>
      )}
      {!isDrawing && (
        <IconButton onClick={addPolygonClick}>
          <AddIcon />
        </IconButton>
      )}
      <Stack>
        <LayersList
          layers={layers}
          setLayers={setLayers}
          reportInfoClick={reportInfoClick}
          reportCloseClick={reportCloseClick}
          toggleGlobalSelect={globalSelectToggle}
          closeEnabled={true}
          closeEnabledOnChildren={true}
          showSave={true}
        />
      </Stack>
      {modalConfig.visible && (
        <LayerPropertiesModal
          properties={modalConfig.properties}
          coords={modalConfig.coords}
          onClose={() => setModalConfig((val) => ({ ...val, visible: false }))}
          key={`modal-${modalConfig.layerId}`}
          layerName={modalConfig.layerName}
          onEditClick={handleEditLayerClicked}
          editable={true}
        />
      )}
      {showPolygonEditModal && selectedLayer !== undefined && (
        <CreatePolygonModal
          reportSave={EditPolygon}
          reportCancel={() => setShowPolygonEditModal(false)}
          sensors={sensors}
          defaultName={selectedLayer.name}
          defaultComment={selectedLayer.metadata?.comment}
          defaultSensors={selectedLayer.metadata?.sensorTypes}
          defaultPlatformTypes={selectedLayer.metadata?.platforms}
          defaultIsRescan={selectedLayer.metadata?.isRescan}
        />
      )}
      {showTransmitterEditModal && selectedLayer !== undefined && (
        <CreateTransmitterModal
          reportSave={EditTransmitter}
          reportCancel={() => setShowTransmitterEditModal(false)}
          defaultName={selectedLayer.name}
          defaultComment={selectedLayer.metadata?.comment}
          defaultMethod={selectedLayer.metadata?.method}
        />
      )}
      {showHelpModal && <HelpModal reportClose={() => setShowHelpModal(false)} />}
    </>
  );
}

export interface ManagePolygonsFormProps {
  layers: PlannedPolygonLayer[];
  setLayers: (layers: PlannedPolygonLayer[]) => void;
  addPolygonClick: () => void;
  savePolygonClick: () => void;
  discardPolygonClick: () => void;
  sensors: string[];
  isDrawing: boolean;
  saveable: boolean;
  graphqlSiteId?: string;
  deletePolygon: (layerId: string) => void;
  deleteTransmitter: (layerId: string) => void;
  updatePolygon: (newData: Partial<PlannedPolygonLayer>) => Promise<void>;
  updateTransmitter: (newData: Partial<PlannedPolygonLayer>) => Promise<void>;
}

export default ManagePolygonsForm;
