import axios from "axios";
import { Dict } from "../BasicTypes";
import { Api, FileType } from "../Generated/ExoDBAPI";
import { runWithRetries } from "../Utils/promiseWithRetries";
import { FileUploader } from "./fileUploaderFactory";

class PreSignedUrlFileUploader implements FileUploader {
  fileData: File;
  fileName: string;
  fileType: FileType;
  siteId: string;
  backendClient: Api<null>;
  retries: number;
  timeBetweenRetries: number;
  requestParams: Dict<string>;
  date?: string;
  constructor(
    fileData: File,
    fileName: string,
    fileType: FileType,
    siteId: string,
    backendClient: Api<null>,
    requestParams: Dict<string>,
    date?: string,
    retries = 10,
    timeBetweenRetries = 5000
  ) {
    this.fileData = fileData;
    this.fileName = fileName;
    this.fileType = fileType;
    this.backendClient = backendClient;
    this.siteId = siteId;
    this.requestParams = requestParams;
    this.retries = retries;
    this.timeBetweenRetries = timeBetweenRetries;
    this.date = date;
  }

  async uploadFile(onProgress = (e: number) => {}) {
    const urlInfo = (
      await this.backendClient.sites.getUploadPreSignedUrl(
        this.siteId,
        {
          fileName: encodeURIComponent(this.fileName),
          fileType: this.fileType,
          date: this.date,
        },
        this.requestParams
      )
    ).data;

    let formData = new FormData();
    if (urlInfo.fields === undefined) {
      throw Error("Url info field was malformed");
    }
    for (const [k, v] of Object.entries(urlInfo.fields)) {
      formData.append(k, v);
    }
    formData.append("file", this.fileData);
    const postPromise = axios.post(urlInfo.url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (e) => {
        onProgress(Math.round((e.loaded * 100) / e.total));
      },
    });
    await runWithRetries(postPromise, {
      maxRetries: this.retries,
      waitBeforeRetryMilliseconds: this.timeBetweenRetries,
      failureError: new Error("Failed to write file to s3"),
    });
  }
}

export default PreSignedUrlFileUploader;
