import { Dict } from '../../../BasicTypes';
import { Confidence, GeoShape, TransmitterMethod } from '../../../Generated/ExoDBAPI';
import {
  GetAnalyzedEntitiesBySiteIdQuery,
  GetListMappingEntitiesQuery,
  GetSiteScansDetailsQuery,
  GetSiteTargetEntitiesQuery,
  GetSiteTransmittersQuery,
  GetSiteTravelCoursesQuery,
  MappingEntity,
  Maybe
} from '../../../Generated/Graphql';
import { lt as is_version_lower } from 'semver';
import { getDateStr } from '../../../Utils/dateUtils';
import { getDepths, getOriginalEpsgNum } from '../../../Utils/geoJsonUtils';
import {
  AnalysisProductsFeatureHeader,
  AnalysisProductsPipe,
  AnalysisProductsPolygon,
  AnalysisProductsTarget,
  AnalysisProductsTransmitter,
  AnalysisProductsTravelCourse,
  DepthPoint,
  PipesQAComment,
  ProcessedAlgoUtilityMetrics,
  ScanDetails
} from '../DataTypes/MapEntities';
import length from '@turf/length';

const CONFIDENCES: Dict<Confidence> = {
  0: Confidence.NotAnObject,
  1: Confidence.Lowest,
  2: Confidence.Low,
  3: Confidence.Medium,
  4: Confidence.High,
  5: Confidence.Highest
};

const FIRST_EXOANALYZER_WITH_DEPTH_SUPPORT = '2.22.0-alpha-0';

function isLegalSingleDepth(appName: string, appVersion: string, entityId: string): boolean {
  for (const [pythonValue, jsValue] of [
    ['a', 'alpha'], // <x>.<y>.<z>a<num>
    ['.dev', 'adev'], // <x>.<y>.<z>.dev<num> - using `adev` instead of `dev` because pre-release are compared lexically.
    ['dev', 'adev'] // <x>.<y>.<z>dev<num>
  ]) {
    const index = appVersion.indexOf(pythonValue);
    if (index > -1) {
      appVersion = `${appVersion.slice(0, index)}-${jsValue}-${appVersion.slice(
        index + pythonValue.length
      )}`;
      break;
    }
  }

  if (appName === 'exoanalyzer') {
    try {
      if (is_version_lower(appVersion, FIRST_EXOANALYZER_WITH_DEPTH_SUPPORT)) {
        return false;
      }
    } catch (err) {
      console.error(`Failed to extract version for: ${entityId}`);
    }
    return true;
  }

  return true;
}

function getSingleDepth(entity: any): number[] | undefined {
  try {
    const depth = JSON.parse(entity?.algo_utility_metrics?.metrics || '{}')['pipe_depth'];
    if (depth !== undefined) {
      return Array(JSON.parse(entity.geo_data).geometry.coordinates.length).fill(depth);
    }
  } catch (err: any) {
    console.log(err, entity);
  }
  return undefined;
}

function getEntityDepths(entity: any): number[] | undefined {
  const depths = getDepths(JSON.parse(entity.geo_data));
  const singleDepth = getSingleDepth(entity);
  if (depths === undefined || JSON.stringify(depths) === JSON.stringify(singleDepth)) {
    if (isLegalSingleDepth(entity.app_name, entity.app_version, entity.id)) {
      return singleDepth;
    }
    return undefined;
  }
  return depths;
}

function buildDepthPoints(depthPointResponseItems: any[] | undefined): DepthPoint[] {
  if (depthPointResponseItems === undefined) {
    return [];
  }
  const points: DepthPoint[] = [];
  for (const item of depthPointResponseItems) {
    const heights = item.hasEllipsoidHeight ? item.ellipsoidHeights : new Array(item.lons.length);
    for (let i = 0; i < item.lons.length; ++i) {
      points.push({
        index: i,
        lon: item.lons[i],
        lat: item.lats[i],
        depth: item.depthsFromGround[i],
        ellipsoidHeight: heights[i]
      });
    }
  }
  return points;
}
function sortAndAddPipesQAComments(comments: PipesQAComment[]) {
  return comments.sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  )[0];
}

export function convertToAnalyzedEntities(
  queryResult: GetAnalyzedEntitiesBySiteIdQuery
): [AnalysisProductsPipe[], AnalysisProductsPolygon[]] {
  const entities = queryResult.searchAnalyzedEntities?.items;
  if (!entities) {
    return [[], []];
  }

  const entitiesWithPartialDepths: string[] = [];
  entities.forEach((entity) => {
    const nextToken = entity?.depth_points?.nextToken;
    if (entity === null || nextToken === undefined) {
      console.log(`Bad entity: ${entity?.s3_ref}`);
      entitiesWithPartialDepths.push(
        entity?.name === undefined ? `Name: ${entity?.name}` : `ID: ${entity?.id}`
      );
    } else if (nextToken !== null) {
      console.log(`Partial crossing depths for entity at ${entity.s3_ref}`);
      entitiesWithPartialDepths.push(
        entity.name === undefined ? `Name: ${entity.name}` : `ID: ${entity.id}`
      );
    }
  });

  if (entitiesWithPartialDepths.length > 0) {
    const errors = entitiesWithPartialDepths.map((message) => `\n * ${message}`);
    window.alert(`Missing depths for pipes: ${errors}`);
  }

  const results = entities.map((entity) => {
    const algoUtilityMetrics = entity
      ? JSON.parse(entity.algo_utility_metrics?.metrics ?? '{}')
      : null;
    return entity
      ? {
          algoUtilityMetrics: algoUtilityMetrics,
          processedAlgoUtilityMetrics: createAlgoUtilityMetrics(
            algoUtilityMetrics,
            entity.geo_data !== undefined ? JSON.parse(entity.geo_data) : undefined
          ),
          confidence: numberToConfidence(entity.analysis_confidence),
          autoConfidence: numberToConfidence(entity.auto_confidence),
          frequency: entity.frequency ?? undefined,
          snr: entity.snr ?? undefined,
          classification: entity.classification,
          creation_source: entity.creation_source,
          depths: checkDepthsValid(entity.app_name, entity.app_version, entity.id)
            ? getEntityDepths(entity)
            : undefined,
          header: {
            id: entity.id,
            s3Ref: entity.s3_ref ?? '',
            name: entity.name ?? '',
            comment: entity.comment,
            shapeType: GeoShape.Polyline,
            geoJson: entity.geo_data,
            isValid: entity.is_valid,
            scan: {
              ...createScanObj(entity.scan, entity.sensor_model)
            },
            creationTimestamp: entity.creation_timestamp,
            originalEpsgCode: getOriginalEpsgNum(JSON.parse(entity.geo_data)),
            user: entity.user
          },
          depthPoints: buildDepthPoints(entity.depth_points?.items),
          pipesQAInfo: {
            flagged: entity.pipesqa_view?.flagged,
            status: entity.pipesqa_view?.status,
            comment:
              entity.pipesqa_view?.comments?.items && entity.pipesqa_view.comments.items.length > 0
                ? sortAndAddPipesQAComments(entity.pipesqa_view.comments.items as PipesQAComment[])
                : undefined
          }
        }
      : null;
  });
  return [
    results.filter(
      (e) => e !== null && e.classification.toLowerCase() === 'pipe'
    ) as unknown as AnalysisProductsPipe[],
    results.filter(
      (e) => e !== null && e.classification.toLowerCase() === 'polygon'
    ) as unknown as AnalysisProductsPolygon[]
  ];
}

export function convertEntitiesToTravelCourses(
  queryResult: GetSiteTravelCoursesQuery
): AnalysisProductsTravelCourse[] {
  let results: (AnalysisProductsTravelCourse | null)[] = [];
  const entities = queryResult.searchTravelCourses?.items;
  if (!entities) {
    return [];
  }
  results = entities.map((e) => {
    return e
      ? {
          header: createEntityHeader(e, e?.scan),
          tag: e.tag ?? undefined,
          gpsFixedModeRatio: e.gps_fixed_ratio ?? undefined
        }
      : null;
  });
  return results.filter((e) => e !== null) as AnalysisProductsTravelCourse[];
}

export function convertEntitiesToTransmitters(
  queryResult: GetSiteTransmittersQuery
): AnalysisProductsTransmitter[] {
  let results: (AnalysisProductsTransmitter | null)[] = [];

  const entities = queryResult.searchTransmitterSetups?.items;
  if (!entities) {
    return [];
  }
  results = entities.map((e) => {
    return e
      ? {
          frequency: e?.frequency ? e.frequency : -1,
          snr: e?.snr ?? undefined,
          method: e?.method ? (e?.method as TransmitterMethod) : undefined,
          header: createEntityHeader(e, e?.scan),
          source: e?.source ?? undefined,
          asset_id: e?.transmitter?.asset_id ?? undefined
        }
      : null;
  });

  return results.filter((e) => e !== null) as AnalysisProductsTransmitter[];
}

export function convertEntitiesToTargets(
  queryResult: GetSiteTargetEntitiesQuery
): AnalysisProductsTarget[] {
  let results: (AnalysisProductsTarget | null)[] = [];

  const entities = queryResult.searchTargetEntities?.items;
  if (!entities) {
    return [];
  }

  results = entities.map((e) => {
    return e
      ? {
          targetType: e.target_type,
          displayParams: JSON.parse(e.display_params ?? '{}'),
          header: createEntityHeader(e, e?.scan)
        }
      : null;
  });

  return results.filter((e) => e !== null) as AnalysisProductsTarget[];
}

export function convertEntitiesToScanDetails(queryResult: GetSiteScansDetailsQuery): ScanDetails[] {
  let results: (ScanDetails | null)[] = [];

  const entities = queryResult.getSite?.scans?.items;
  if (!entities) {
    return [];
  }

  results = entities.map((e) => {
    return e ? createScanObj(e) : null;
  });

  return results.filter((e) => e !== null) as ScanDetails[];
}

export function convertEntitiesToDrawDetails(
  queryResult: GetListMappingEntitiesQuery
): MappingEntity[] {
  const entities = queryResult?.listMappingEntities?.items;
  if (!entities) {
    return [];
  }
  return entities.filter((e) => e !== null) as MappingEntity[];
}

function createEntityHeader(entity: any, scan: any): AnalysisProductsFeatureHeader {
  return {
    id: entity.id,
    s3Ref: entity.s3_ref ?? '',
    name: entity.name ?? '',
    shapeType: GeoShape.Polyline,
    geoJson: entity.geo_data,
    scan: createScanObj(scan),
    originalEpsgCode: getOriginalEpsgNum(JSON.parse(entity.geo_data)),
    isValid: entity.is_valid
  };
}

function createScanObj(scan: any, sensorModel?: string | null): ScanDetails {
  if (!scan) {
    return {
      name: 'NO SCAN',
      sensor: sensorModel ?? 'Unknown sensor'
    } as ScanDetails;
  }

  return {
    id: scan.id,
    name: scan.name,
    date: getDateStr(scan.start_timestamp),
    timestamp: scan.start_timestamp,
    timeiso: scan.start_time_iso,
    sensor: sensorModel ?? scan.sensor.model,
    part: scan.part_index ?? undefined,
    polygon: scan.polygon_index ?? undefined,
    snake: scan.snake_index ?? undefined,
    mount_method: scan.mount_method ?? undefined,
    boundingPolygonS3Ref: scan.bounding_polygon_s3_ref ?? undefined,
    s3Ref: scan.s3_ref,
    isValid: scan?.is_valid ?? true,
    scanType: scan?.scan_type
  };
}

function numberToConfidence(num?: Maybe<number>) {
  if (!num) {
    return Confidence.None;
  }
  const confidence = CONFIDENCES[num];
  if (confidence === undefined) {
    throw new Error(`Unknown confidence ${num}`);
  }
  return confidence;
}

const NonValidApplicationVersions: Dict<string[]> = {
  exoanalyzer: ['2.13.0', '2.14.0a406', '2.14.0a407']
};

function checkDepthsValid(appName?: string | null, appVersion?: string | null, id?: string) {
  const isValid =
    ((NonValidApplicationVersions[appName as string] ?? {}) as any)[appVersion as string] ===
    undefined;
  if (!isValid) {
    console.log(
      `Entity with Id ${id} is from a faulty exoanalyzer version ${appVersion}. Invalidating depths`
    );
  }

  return isValid;
}

function createAlgoUtilityMetrics(mertics: any, geodata: any) {
  if (mertics === undefined) {
    return;
  }
  const processedMetrics: Partial<ProcessedAlgoUtilityMetrics> = {};
  const anglesOfCrossing: number[] | undefined = mertics.angles_of_pipe_crossings;
  if (anglesOfCrossing !== undefined && anglesOfCrossing !== null && anglesOfCrossing.length > 0) {
    processedMetrics.angleOfCrossingAverage =
      anglesOfCrossing.reduce((a, b) => a + b) / anglesOfCrossing.length;
  }

  if (geodata !== undefined) {
    processedMetrics.utilityLength = length(geodata, { units: 'meters' });
  }
  return processedMetrics;
}

export interface TransmittersAndTravelCourses {
  transmitters: AnalysisProductsTransmitter[];
  travelCourses: AnalysisProductsTravelCourse[];
}
