import { ApolloQueryResult } from "@apollo/client";
import { ScanFieldsFragment } from "../../Generated/Graphql";
import { ScanData } from "../Pages/ScansDashboard";
import { UNKNOWN_PLATFORM_TYPE } from "../ExoFuser/ScansTab/PlatformType";

export function parseScanResults<T>(
  result: ApolloQueryResult<T>,
  getItems: (res: ApolloQueryResult<T>) => (ScanFieldsFragment | null)[] | undefined
): ScanData[] {
  const scans: ScanData[] = [];
  const items = getItems(result);
  if (!items) {
    return [];
  }

  for (const scan of items) {
    if (!scan) {
      continue;
    }
    let fixedRatio = undefined;
    const travelCourses = scan.travel_courses?.items;
    if (travelCourses) {
      const fixedRatios = travelCourses
        .filter((tc) => tc?.gps_fixed_ratio !== undefined && tc.gps_fixed_ratio !== null)
        .map((tc) => tc?.gps_fixed_ratio);
      if (
        fixedRatios.length > 0 &&
        fixedRatios.every((r) => r === fixedRatios[0]) &&
        fixedRatios[0] !== null &&
        fixedRatios[0] !== undefined
      ) {
        fixedRatio = fixedRatios[0];
      }
    }

    scans.push({
      id: scan.id,
      name: scan.name ?? "NO NAME",
      startTimestamp: scan.start_timestamp * 1000,
      endTimestamp: scan.end_timestamp ? scan.end_timestamp * 1000 : undefined,
      siteName: scan.site.name,
      status: scan.status ?? "UPLOADING",
      sensor: scan.sensor.model,
      mount_method: scan?.mount_method ?? UNKNOWN_PLATFORM_TYPE,
      fixedModeRatio: fixedRatio,
    });
  }

  return scans;
}
