import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react'
import { ScanDetails } from '../DataTypes/MapEntities';

const SELECT_ALL_VAL = "Select All";
const DESELECT_ALL_VAL = "Deselect All";
export const UNKNOWN_PLATFORM_TYPE = "UNKNOWN";
const DEFAULT_PLATFORM_TYPES = ["CART","EXOPOKE","EXOROAD","DRONE","UNKNOWN","BACKPACK"];

interface PlatformTypeProps {
    scans: ScanDetails[];
    doneLoading: boolean;
}

function PlatformType({ doneLoading, scans }: PlatformTypeProps) {
    const [platforms, setPlatforms] = React.useState<string[]>([]);
    const [selectedPlatforms, setSelectedPlatforms] = React.useState<string[]>([]);

    useEffect(() => {
        const typeList = [...new Set<string>(scans.map((s) => s?.mount_method ?? UNKNOWN_PLATFORM_TYPE))];
        if (typeList.length !== selectedPlatforms.length) {
            typeList.splice(0, 0, SELECT_ALL_VAL);
        } else {
            typeList.splice(0, 0, DESELECT_ALL_VAL);
        }
        setPlatforms(typeList);
      }, [scans, selectedPlatforms]);

    useEffect(() => {
        if (doneLoading) {
            const relevantTypes = platforms.filter((s) => DEFAULT_PLATFORM_TYPES.includes(s as string));
            setSelectedPlatforms(relevantTypes);
        } else {
            setSelectedPlatforms([]);
        }
      }, [doneLoading]);

    return (
        <>
        <FormControl>
        <Typography variant="h5">Show Platform Type</Typography>
        <Stack gap="1rem">
            <Autocomplete
            options={platforms}
            getOptionLabel={(o) => o}
            placeholder="Platform Types"
            renderInput={(params) => <TextField {...params} key={`option-${params.id}`} placeholder="Platform type" />}
            sx={{ width: 500 }}
            value={selectedPlatforms}
            onChange={(e, newVal) => {
                if (newVal.includes(SELECT_ALL_VAL)) {
                    setSelectedPlatforms(platforms.slice(1));
                } else if (newVal.includes(DESELECT_ALL_VAL)) {
                    setSelectedPlatforms([]);
                } else {
                    setSelectedPlatforms(newVal);
                }
            }}
            size="small"
            disableCloseOnSelect={true}
            limitTags={5}
            disabled={!doneLoading}
            multiple
            />
            <Stack direction="row" gap="1rem">
            <Button onClick={()=>{}} variant="contained" key="showTypes" disabled={!doneLoading}>
                Show Platform Type
            </Button>
            <Button onClick={() => {}} variant="contained" key="hideTypes" disabled={!doneLoading}>
                Hide Platform Type
            </Button>
            </Stack>
        </Stack>
    </FormControl>
        </>
    )
}

export default PlatformType;
