import React from "react";

import { AnalysisProductsPipe, AnalysisProductsTravelCourse } from "../DataTypes/MapEntities";

const MAX_LINES = 3;

function getAverageDepthStr(pipe?: AnalysisProductsPipe): string | undefined {
  if (pipe === undefined) {
    return undefined;
  }
  if (pipe.depths !== undefined && pipe.depths.length > 0) {
    return (pipe.depths.reduce((a, b) => a + b, 0) / pipe.depths.length).toFixed(1);
  }
  return "Unknown";
}

function getFrequency(pipe?: AnalysisProductsPipe): string | undefined {
  if (pipe === undefined) {
    return undefined;
  }

  if (pipe.frequency === undefined) {
    return "N/A";
  }
  return `${pipe.frequency}Hz`;
}

function getComment(pipe?: AnalysisProductsPipe): string | undefined {
  if (pipe === undefined) {
    return undefined;
  }

  if (pipe.header.comment === undefined) {
    return "N/A";
  }
  return pipe.header.comment;
}

function DisplayPipeData({ pipe, preloaderTravelCourse }: DisplayPipeDataProps) {
  const scan = pipe?.header.scan;
  const pipeInfo = React.useMemo(() => {
    const data = {
      ID: pipe?.header.id,
      Name: pipe?.header.name,
      Sensor: scan?.sensor,
      Confidence: pipe?.confidence,
      "Avg. Depth": getAverageDepthStr(pipe),
      "Found By": pipe?.header.user,
      "Found At": scan === undefined ? undefined : `p${scan.polygon}s${scan.snake}_part${scan.part}`,
      "Scan From": scan === undefined ? undefined : scan.date,
      "Travel Course Fixed Mode": preloaderTravelCourse?.gpsFixedModeRatio
        ? `${(preloaderTravelCourse.gpsFixedModeRatio * 100).toFixed(2)}%`
        : "N/A",
      Frequency: getFrequency(pipe),
      Comment: getComment(pipe),
      "S3 Ref": pipe?.header.s3Ref,
    };
    return data;
  }, [pipe]);

  function createColumn(key: string, value?: string) {
    const displayValue = value === undefined ? "// pipe not selected" : value === null ? "null" : value;

    return (
      <span className="exofuserFormEntry" key={`exofuserSelectedPipe_${key}`}>
        <label htmlFor={`exofuser.selectedPipe.data.${key}`}>{key}</label>
        <textarea
          disabled={true}
          id={`exofuser.selectedPipe.data.${key}`}
          name={`exofuser.selectedPipe.data.${key}`}
          value={displayValue}
          style={{ display: "block" }}
          rows={Math.min(MAX_LINES, Math.floor(displayValue.length / 20) + 1)}
        />
      </span>
    );
  }

  return (
    <form className="selectedPipeGrid">
      {Object.entries(pipeInfo).map(([key, value]) => createColumn(key, value))}
      {Object.entries(pipe?.algoUtilityMetrics ?? {}).map(([key, value]) => {
        let displayValue: string = "";
        if (key === "pipe_coverage_area" && typeof value === "number") {
          displayValue = `${parseFloat((value as number).toFixed(2))}%`;
        } else if (key === "pipe_depth" && typeof value === "number") {
          displayValue = (value as number).toFixed(2);
        } else {
          displayValue = JSON.stringify(value);
        }
        return createColumn(
          key
            .toLowerCase()
            .replace(/_/g, () => " ")
            .replace(/\b\w/g, (txt: string) => txt.toUpperCase()),
          displayValue
        );
      })}
    </form>
  );
}

export interface DisplayPipeDataProps {
  pipe?: AnalysisProductsPipe;
  preloaderTravelCourse?: AnalysisProductsTravelCourse;
}

export default DisplayPipeData;
