import { SiteWideDepthData } from "./depthData";
import GeoidValueForm from "./GeoidValueForm";
import CalculateHeightsForm from "./CalculateHeightsForm";
import { AnalysisProductsPipe } from "../DataTypes/MapEntities";

function DepthTab({ siteWideDepthData, triggerRefresh, selectedPipe }: DepthTabProps) {
  return (
    <span className="tabsContainer">
      <span className="tabsBody">
        <GeoidValueForm siteWideDepthData={siteWideDepthData} />
      </span>
      <span className="tabsBody">
        <CalculateHeightsForm siteWideDepthData={siteWideDepthData} triggerRefresh={triggerRefresh} />
      </span>
    </span>
  );
}

export interface DepthTabProps {
  siteWideDepthData: SiteWideDepthData;
  triggerRefresh: () => void;
  selectedPipe: AnalysisProductsPipe | undefined;
}

export default DepthTab;
