import { useLocation, useNavigate } from 'react-router-dom';
import './ManHolesForm.css';
import AutoCompleteField from '../Templates/AutoCompleteField';

import { POIEntityTypes } from '../../BasicTypes';
import { useState } from 'react';
import { POIEntities } from '../../Constant/entity';

const options: POIEntityTypes[] = [];
Object.values(POIEntities).forEach((entity) => {
  if (!options.includes(entity)) {
    options.push(entity);
  }
});

export function POI() {
  const [entityType, setEntityType] = useState<POIEntityTypes | undefined>(POIEntities.Manhole);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const scanId = queryParams.get('scanId');

  const navigateToForm = () => {
    if (entityType === POIEntities.Manhole) {
      navigate(`/manholes?scanId=${scanId}`);
    } else {
      navigate(`/poientity?scanId=${scanId}&entity=${entityType}`);
    }
  };
  if (!scanId)
    return (
      <div className="manhole-404">
        <img src="./Assets/Manhole/manhole404.png" alt="404 Not Found" className="manhole_img" />
      </div>
    );
  return (
    <div className="formContainer entity">
      <AutoCompleteField
        placeholder="Point of interest"
        options={options}
        setSelectedOption={setEntityType}
        selectedOption={entityType}
        alwaysChoose={true}
        sx={{ minWidth: '100%' }}
        spanClassName="entityTypeContainer"
      />
      <span className="formSection" key="submitButton">
        <input type="button" value="Select" className="formButton" onClick={navigateToForm} />
      </span>
    </div>
  );
}
