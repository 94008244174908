import * as React from "react";
import {Marker, Polyline, useMap} from "react-leaflet";
import L, {LatLngExpression, LatLngLiteral, LatLngTuple} from "leaflet";
import useScalableValue from "../ArrowLine/useScalableValue";
import {useRef} from "react";
import {formatPoints, getAngle} from "../ArrowLine/helper";
import RotatableText from "../ArrowLine/RotatableText";
import { ToolObject,  getMidPoint } from "../MeasurementUtil";
import { EntityAction } from "../../../../Types/MappingEntities";



const textClassName = 'text';
const FONT_SIZE = 0.1;


interface Props {
    points: LatLngLiteral[],
    color?: string;
    setPoints: (points: LatLngLiteral[]) => void;
    updateData: (action: EntityAction, tool: ToolObject) => void;
    data: ToolObject;
}

function getArrowDiv(params: { arrowSize: number, color: string, angle: number }): string {
    const {arrowSize, color, angle} = params;
    return `<div style="
            height: ${arrowSize * 2}px;
            width: ${arrowSize * 2}px;
            transform: rotate(${angle}deg);
            position: relative;

        ">
        <div style="
            position: absolute;
            top: ${-arrowSize}px;
            left: ${-arrowSize}px;
            border-bottom: ${arrowSize * 2}px solid ${color};
            border-left: ${arrowSize * 2}px solid transparent;
        ">
        </div>
    </div>`;
}

function ArrowDepth({points, color = 'black', setPoints, updateData, data }: Props) {
    const arrowSize = useScalableValue(0.04);
    const markerRefStart = useRef<L.Marker>(null);
    const markerRefEnd = useRef<L.Marker>(null);
    const markerRefLine = useRef<L.Polyline>(null);
    const [formattedPoints, setFormattedPoints] = React.useState<LatLngTuple[]>([]);
    const [text, setText] = React.useState(data.properties?.text || "");
    const [midPoint, setMidPoint] = React.useState<LatLngExpression | null>(null);
    const [startPoint, setStartPoint] = React.useState<L.LatLngLiteral>();
    const [finishDraw, setFinishDraw] = React.useState(false);
    const [endPoint, setEndPoint] = React.useState<L.LatLngLiteral>();
    const [disabled, setDisabled] = React.useState(false);
    const arrowClassName = 'arrow-icon'
    const map = useMap();

    React.useEffect(() => {
        if (points.length > 1) {
            setStartPoint(points[0]);
            setEndPoint(points[points.length - 1]);
            const midLinePoint = getMidPoint(points[0] as LatLngLiteral, points[points.length - 1] as LatLngLiteral);
            setMidPoint(midLinePoint);
        }
    }, [points]);

    const handleStartDraw = React.useMemo(() => (e: L.LeafletMouseEvent) => {
        if (e?.latlng) {
            setStartPoint(e.latlng);
            setEndPoint(e.latlng);
            map.once('click', handleEndDraw);
            map.on('mousemove', handleMouseMove);
        }
    }, [setStartPoint]);

    const handleEndDraw = React.useMemo(() => (e: L.LeafletMouseEvent) => {
        if (e?.latlng) {
            setEndPoint(e.latlng);
            map.off('mousemove', handleMouseMove);
            setFinishDraw(true);
        }
    }, [setEndPoint]);

    const handleMouseMove = React.useMemo(() => (e: L.LeafletMouseEvent) => {
        setEndPoint(e.latlng);
    }, [setEndPoint]);

    React.useEffect(() => {
        if (points.length < 2) {
            map.once('click', handleStartDraw);
        }
      }, []);

    React.useEffect(() => {
        if (finishDraw && endPoint && startPoint) {
            setPoints([startPoint, endPoint]);
        }
    }, [finishDraw])

    React.useEffect(() => {
        if(endPoint && startPoint) {
            setFormattedPoints(formatPoints([startPoint, endPoint]));
            const midLinePoint = getMidPoint(startPoint as LatLngLiteral, endPoint as LatLngLiteral);
            setMidPoint(midLinePoint);
        }
      }, [endPoint]);



    React.useEffect(() => {
        if (markerRefStart && markerRefStart.current) {
            const markerElement = (markerRefStart.current as unknown as { _icon: HTMLDivElement })._icon;
            if (markerElement) {
                markerElement.style.marginTop = `${-arrowSize}px`;
                markerElement.style.marginLeft = `${-arrowSize}px`;
            }
        }

        if (markerRefEnd && markerRefEnd.current) {
            const markerElement = (markerRefEnd.current as unknown as { _icon: HTMLDivElement })._icon;
            if (markerElement) {
                markerElement.style.marginTop = `${-arrowSize}px`;
                markerElement.style.marginLeft = `${-arrowSize}px`;
            }
        }
    });


    const updateHtml = (marker: L.Marker | null): void => {
        if (!marker) {
            return;
        }

        const markerElement = (marker as unknown as { _icon: HTMLDivElement })._icon;

        if (!markerElement) {
            return;
        }

        const textElement = markerElement.getElementsByClassName(textClassName)[0] as HTMLDivElement;

        if (!textElement) {
            return;
        }

        textElement.style.top = `${-textElement.offsetHeight}px`;
        textElement.style.left = `${-textElement.offsetWidth / 2}px`;
    }

    if (formattedPoints.length < 2) {
        return null;
    }


    const angleStart = getAngle(formattedPoints[1], formattedPoints[0]) - 45;
    const angleText = getAngle(formattedPoints[1], formattedPoints[0]) + 180;
    const arrowDivStart = getArrowDiv({color, arrowSize, angle: angleStart});

    const arrowIconStart = L.divIcon({className: arrowClassName, html: arrowDivStart});
    let lineOptions: L.PolylineOptions  = {color: color};

    if (disabled) return null;

    return (
        <>

            <Marker key={'marker-start'} position={[startPoint?.lat as number, startPoint?.lng as number]} icon={arrowIconStart} ref={markerRefStart}  interactive={true} eventHandlers={{
                click: (e) => {
                    if (e.originalEvent.ctrlKey) {
                        map.removeLayer(e.target);
                        if (data.id) updateData(EntityAction.DELETE, data);
                        setDisabled(true);
                        return
                    }
                    if (e.originalEvent.altKey && !text) {
                        e.target.bindPopup(`<input type="text" id="markerTitle" placeholder="Enter title" value="${text}" >`);
                        e.target.on('popupopen', function() {
                            const inputElement = document.getElementById('markerTitle') as HTMLInputElement;
                            if (!inputElement) return;
                            inputElement.addEventListener('keypress', function(ev) {
                                if (ev.key === 'Enter' && startPoint && endPoint) {
                                    setText(inputElement.value);
                                    data.text = inputElement.value;
                                    data.properties.text = inputElement.value;
                                    data.points = [startPoint, endPoint];
                                    console.log('Insert:', data);
                                    e.target.setPopupContent(inputElement.value);
                                    e.target.closePopup();
                                    e.target.off('bindPopup');
                                    e.target.off('popupopen');
                                    updateData(EntityAction.UPDATE, data);
                                }
                            })
                        })
                        return
                    }
                },
                }}/>

            {startPoint && endPoint && <Polyline ref={markerRefLine} pathOptions={lineOptions} positions={[[startPoint?.lat, startPoint?.lng], [endPoint?.lat, endPoint?.lng]]} eventHandlers={{
                click: (e) => {
                    if (e.originalEvent.ctrlKey) {
                        map.removeLayer(e.target);
                        if (data.id) updateData(EntityAction.DELETE, data);
                        setDisabled(true);
                        return
                    }}}}
                    />}
            {midPoint &&<RotatableText
                text={text}
                position={midPoint}
                onAfterRender={updateHtml}
                interactive={false}
                fontSize={FONT_SIZE}
                rotation={angleText}
                textClassName={textClassName}
            />}
        </>
    );
}

export default ArrowDepth;
