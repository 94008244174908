import { Card, Divider, Stack } from '@mui/material';
import { measurementUnits, PipeExist, PipeItem } from '../../../../Pages/ManHolesForm';
import { MetaDataRow } from '../MetaData/MetaDataRow';
import { Tooltip } from '../../../../../shared';
import { convertMeasurement } from '../../../../../Utils/mathUtils';

export const Pipes = ({ data, measurement }: { data: PipeItem[]; measurement: string }) => {
  return (
    <Stack direction="row" spacing={2} mt={1}>
      {!data.length ? (
        <img src="./Assets/Manhole/no_pipe.svg" width={80} height={80} alt="noPipe" />
      ) : (
        data.map((pipe) => {
          const isPipe = pipe.isPipe !== PipeExist.NO;
          const imgUrl = isPipe
            ? `./Assets/Manhole/${pipe.picture}.png`
            : './Assets/Manhole/no_pipe.svg';

          const tooltipContent = (
            <Stack sx={{ padding: 1 }}>
              <img src={imgUrl} height={240} width={240} alt={imgUrl} />
              <Divider sx={{ my: 2 }} />
              <Stack direction="column" spacing={0.5}>
                <MetaDataRow title="Hive" content={pipe.hive} />
                <MetaDataRow
                  title="Azimuth"
                  content={
                    pipe.azimuth && (
                      <>
                        {measurement === measurementUnits.USA
                          ? convertMeasurement(pipe.azimuth, measurement)
                          : pipe.azimuth}
                        ({measurement})
                      </>
                    )
                  }
                />
                <MetaDataRow
                  title="Invert Level"
                  content={
                    pipe.invert_level && (
                      <>
                        {measurement === measurementUnits.USA
                          ? convertMeasurement(pipe.invert_level, measurement)
                          : pipe.invert_level}
                        ({measurement})
                      </>
                    )
                  }
                />
                <MetaDataRow
                  title="Top Level"
                  content={
                    pipe.top_level && (
                      <>
                        {measurement === measurementUnits.USA
                          ? convertMeasurement(pipe.top_level, measurement)
                          : pipe.top_level}
                        ({measurement})
                      </>
                    )
                  }
                />
                <MetaDataRow
                  title="Diameter/Oblique"
                  content={
                    pipe.diameter && (
                      <>
                        {measurement === measurementUnits.USA
                          ? convertMeasurement(pipe.diameter, measurement)
                          : pipe.diameter}
                        ({measurement})
                      </>
                    )
                  }
                />
                <MetaDataRow
                  title="Thickness"
                  content={
                    pipe.thickness && (
                      <>
                        {measurement === measurementUnits.USA
                          ? convertMeasurement(pipe.thickness, measurement)
                          : pipe.thickness}
                        ({measurement})
                      </>
                    )
                  }
                />
                <MetaDataRow title="Description" content={pipe.description} />
                {pipe.flowDirection && (
                  <MetaDataRow title="Flow direction" content={pipe.flowDirection} />
                )}
              </Stack>
            </Stack>
          );
          return (
            <Tooltip key={pipe.id} title={tooltipContent} disableHoverListener={!isPipe}>
              <Card
                elevation={isPipe ? 3 : 0}
                sx={{
                  width: 80,
                  height: 80
                }}>
                <img width="100%" height="100%" src={imgUrl} alt={imgUrl} />
              </Card>
            </Tooltip>
          );
        })
      )}
    </Stack>
  );
};
