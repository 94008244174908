import * as React from "react";
import { Api } from "../../Generated/ExoDBAPI";
import { getRequestParams } from "../../Utils/azureAuth";
import PolygonDisplayForm, { PolygonFileData } from "./PolygonDisplayForm";
import DownloadPolygonsForm from "./DownloadPolygonsForm";

function SitePolygonSection({ siteId }: PolygonTabProps) {
  const [polygonFilesData, setPolygonFilesData] = React.useState<PolygonFileData[]>([]);
  React.useEffect(() => {
    async function fetchUrls() {
      const api = new Api();
      const urls = (await api.sites.getPolygonUrls(siteId, await getRequestParams())).data.polygonsUrls;
      setPolygonFilesData(
        urls.map((u) => {
          const splittedPath = u.path.split(".");
          return { url: u.url, type: splittedPath[splittedPath.length - 1] };
        })
      );
    }
    fetchUrls();
  }, []);
  return (
    <>
      <h2 className="pageHeader">Site polygons</h2>
      <PolygonDisplayForm polygonFilesData={polygonFilesData} />

      <DownloadPolygonsForm siteId={siteId} />
    </>
  );
}

interface PolygonTabProps {
  siteId: string;
}

export default SitePolygonSection;
