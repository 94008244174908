import * as React from "react";

function ConfirmRTKOverride({ onConfirm, onDeny }: ConfirmRTKOverrideProps) {
  return (
    <div className="areYouSureModal">
      <h3 className="pageHeader">Update users</h3>
      <p>Are you sure you want to override the current rtk users?</p>
      <span className="areYouSureContainer">
        <input type="button" value="yes" className="areYouSureButton" name="yesOption" onClick={onConfirm} />
        <input type="button" value="no" className="areYouSureButton" name="noOption" onClick={onDeny} />
      </span>
    </div>
  );
}

interface ConfirmRTKOverrideProps {
  onConfirm: () => void;
  onDeny: () => void;
}

export default ConfirmRTKOverride;
