import {LatLng, LatLngExpression, LatLngTuple} from "leaflet";
import * as turf from "@turf/turf";
import {distance as distanceToPoint} from "@turf/turf";

export function isLatLngTuple(val: LatLngExpression): val is LatLngTuple {
    return (val as LatLng).lat === undefined;
}

export function findIntersectionPoint(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number): LatLngTuple | null {
    // Parametric equations for the first line segment
    const X1 = (t: number) => x1 + t * (x2 - x1);
    const Y1 = (t: number) => y1 + t * (y2 - y1);
    // Parametric equations for the second line segment
    const X2 = (u: number) => x3 + u * (x4 - x3);
    const Y2 = (u: number) => y3 + u * (y4 - y3);

    // Calculate the determinant of the matrix formed by the coefficients
    const determinant = (x2 - x1) * (y4 - y3) - (x4 - x3) * (y2 - y1);
    // Check if the lines are parallel or coincident
    if (determinant === 0) {
        // The lines are parallel or coincident
        return null;
    }

    // Calculate the parameters 't' and 'u'
    const t = ((x3 - x1) * (y4 - y3) - (x4 - x3) * (y3 - y1)) / determinant;
    const u = -((x2 - x1) * (y3 - y1) - (x3 - x1) * (y2 - y1)) / determinant;

    // Check if 't' and 'u' are within the range [0, 1]
    if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
        // Calculate the coordinates of the intersection point
        const intersectionX = X1(t);
        const intersectionY = Y1(t);
        // return { x: intersectionX, y: intersectionY };
        return [intersectionX, intersectionY] as LatLngTuple;
    } else {
        // The line segments do not intersect
        return null;
    }
}

export function addMetersToLat(latitude: number, metersToAdd: number): number {
    const latDegreeApproximation = 111000;
    const latDelta = metersToAdd / latDegreeApproximation;
    return latitude + (latDelta * 180 / Math.PI);
}

export function addMetersToLong(latitude: number, longitude: number, metersToAdd: number): number {
    const longDegreeApproximation = 111000 * Math.cos(latitude * Math.PI / 180);
    const longDelta = metersToAdd / longDegreeApproximation;
    return longitude + (longDelta * 180 / Math.PI);
}

interface SquarePointsReturn {
    rightTop: LatLngTuple;
    leftTop: LatLngTuple;
    leftBottom: LatLngTuple;
    rightBottom: LatLngTuple;
}
export function getSquareAroundPoint(point: LatLngTuple, distance: number): SquarePointsReturn {
    const dist = distance / 100;

    const rightTop: LatLngTuple = [addMetersToLat(point[0], dist), addMetersToLong(point[0], point[1], dist)]
    const leftTop: LatLngTuple = [addMetersToLat(point[0], dist), addMetersToLong(point[0], point[1], -dist)]
    const leftBottom: LatLngTuple = [addMetersToLat(point[0], -dist), addMetersToLong(point[0], point[1], -dist)]
    const rightBottom: LatLngTuple = [addMetersToLat(point[0], -dist), addMetersToLong(point[0], point[1], dist)]

    return {
        rightTop,
        rightBottom,
        leftBottom,
        leftTop
    }
}

export function getAllPossibleSquareIntersection(start: LatLngTuple, end: LatLngTuple, distance: number): LatLngTuple[] {
    const center: LatLngTuple = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];

    const {leftTop, leftBottom, rightTop, rightBottom} = getSquareAroundPoint(center, distance);

    const leftIntersectionPoint: LatLngTuple | null = findIntersectionPoint(start[0], start[1], end[0], end[1], leftTop[0], leftTop[1], leftBottom[0], leftBottom[1]);
    const rightIntersectionPoint: LatLngTuple | null = findIntersectionPoint(start[0], start[1], end[0], end[1], rightTop[0], rightTop[1], rightBottom[0], rightBottom[1]);
    const topIntersectionPoint: LatLngTuple | null = findIntersectionPoint(start[0], start[1], end[0], end[1], leftTop[0], leftTop[1], rightTop[0], rightTop[1]);
    const bottomIntersectionPoint: LatLngTuple | null = findIntersectionPoint(start[0], start[1], end[0], end[1], leftBottom[0], leftBottom[1], rightBottom[0], rightBottom[1]);

    const result: LatLngTuple[] = [];

    if (leftIntersectionPoint) {
        result.push(leftIntersectionPoint);
    }
    if (rightIntersectionPoint) {
        result.push(rightIntersectionPoint);
    }
    if (topIntersectionPoint) {
        result.push(topIntersectionPoint);
    }
    if (bottomIntersectionPoint) {
        result.push(bottomIntersectionPoint);
    }
    return result;
}

export function getAllPossibleSquareIntersectionPointsInOrder(start: LatLngTuple, end: LatLngTuple, distance: number): LatLngTuple[] {
    const result: LatLngTuple[] = [];
    const intersectionPoints = getAllPossibleSquareIntersection(start, end, distance);

    if (intersectionPoints.length === 2) {
        const distToStart1 = distanceToPoint(turf.point(start as number[]), turf.point(intersectionPoints[0] as number[]));
        const distToStart2 = distanceToPoint(turf.point(start as number[]), turf.point(intersectionPoints[1] as number[]));

        // add points in correct order
        if (distToStart1 < distToStart2) {
            result.push(intersectionPoints[0]);
            result.push(intersectionPoints[1]);
        } else {
            result.push(intersectionPoints[1]);
            result.push(intersectionPoints[0]);
        }
    }

    return result;
}
