import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { Dict, entityType } from '../../../BasicTypes';
import { IFilterCategoryFactory } from './BaseFilterCategory';

class EntityTypeFilter<T extends entityType> extends React.Component<
  EntityTypeFilterProps<T>,
  EntityTypeFilterState<T>
> {
  constructor(props: EntityTypeFilterProps<T>) {
    super(props);
    this.state = {
      filteredEntities: {},
      strategy: this.props.strategy,
      currentEntities: [],
      categoriesResetDisplayTrigger: false
    };
  }

  /**
   * Evaluate the categories again on done loading, to avoid missing options on the final display.
   * The categories should actually render themselves correctly now, but it can help avoiding
   * bugs in the critical section.
   */
  componentDidUpdate(prevProps: Readonly<EntityTypeFilterProps<T>>): void {
    if (prevProps.loadingStateChange !== this.props.loadingStateChange) {
      this.setState((previousState) => {
        return {
          filteredEntities: previousState.filteredEntities,
          strategy: previousState.strategy,
          categoriesResetDisplayTrigger: !previousState.categoriesResetDisplayTrigger,
          currentEntities: previousState.currentEntities
        };
      });
    }

    if (prevProps.strategy !== this.props.strategy) {
      this.setState((previousState) => {
        return {
          ...previousState,
          strategy: this.props.strategy
        };
      });
    }
  }

  #setFilteredEntities(filterName: string, entities: T[]) {
    this.setState((state) => {
      const newEntities: Dict<T[]> = { ...state.filteredEntities };
      newEntities[filterName] = entities;
      const currentEntities = this.#getEntitiesByRank(undefined, newEntities);

      const entitiesToDisplay = currentEntities.map((entity) => {
        let result = entity;
        for (const factory of this.props.filtersCategoriesFactories) {
          result = factory.createDisplayEntity(result);
        }
        return result;
      });

      this.props.setPickedEntities(entitiesToDisplay);
      return {
        filteredEntities: newEntities,
        currentEntities: entitiesToDisplay,
        strategy: state.strategy
      };
    });
  }

  /**
   *
   * @param rank the rank of the current filter, or undefined to indicate final check
   * @returns the entities that were not filtered by previous values
   */
  #getEntitiesByRank(rank: number | undefined, filteredEntitiesByCategoryName: Dict<T[]>): T[] {
    // Find all filters that should be performed before this one
    const filterByCategories = this.props.filtersCategoriesFactories
      .filter((factory) => {
        return rank === undefined || (factory.rank !== undefined && factory.rank < rank);
      })
      .map((factory) => factory.filterName);

    // Extract the entities not filtered by the given filter
    const allFilteredEntities: T[] = filterByCategories
      .map((name) =>
        name in filteredEntitiesByCategoryName ? filteredEntitiesByCategoryName[name] : []
      )
      .flat();

    return this.props.entities.filter((entity) => !allFilteredEntities.includes(entity));
  }

  #entitiesForCategory(filterName: string): T[] {
    const filterRank = this.props.filtersCategoriesFactories.find(
      (factory) => factory.filterName === filterName
    )?.rank;

    if (filterRank === undefined) {
      console.log('Warning: no rank for', filterName);
      return this.props.entities;
    }

    return this.#getEntitiesByRank(filterRank, this.state.filteredEntities);
  }

  #setStrategy(strategy?: boolean | ((option: string) => boolean)) {
    // Trigger the option on strategy display change
    this.setState((previousState) => {
      return {
        filteredEntities: previousState.filteredEntities,
        strategy: strategy,
        categoriesResetDisplayTrigger: !previousState.categoriesResetDisplayTrigger,
        currentEntities: previousState.currentEntities
      };
    });
  }

  render(): React.ReactNode {
    return (
      <form>
        <span>
          <>
            {this.props.filtersCategoriesFactories.map((factory) => {
              return factory.create(
                {
                  entities: this.#entitiesForCategory(factory.filterName),
                  setFilteredEntities: (entities) =>
                    this.#setFilteredEntities(factory.filterName, entities),
                  resetFilterTrigger: this.state.categoriesResetDisplayTrigger
                },
                this.state.strategy
              );
            })}
          </>
        </span>
        <span className="formSection">
          <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
            <Button variant="contained" onClick={() => this.#setStrategy(true)}>
              Show All
            </Button>
            <Button variant="contained" onClick={() => this.#setStrategy(false)}>
              Hide All
            </Button>
          </Stack>
        </span>
      </form>
    );
  }
}

export interface EntityTypeFilterProps<T> {
  // All the entities given to the entity type
  entities: T[];
  filtersCategoriesFactories: IFilterCategoryFactory<T>[];
  setPickedEntities: (e: T[]) => void;
  loadingStateChange: boolean;
  strategy?: boolean | ((option: string) => boolean);
}

export interface EntityTypeFilterState<T> {
  // For each category (given by name), the entities that were filtered OUT by it.
  // The filtered out entities are taken instead of the accepted ones, as not all the entities will always be given
  // to some of the categories.
  filteredEntities: Dict<T[]>;

  // All the entities that should actually be displayed. Used to avoid triggering the caller if no change occurred.
  currentEntities: T[];
  strategy?: boolean | ((option: string) => boolean);

  // This trigger is used to recreate the categories if a change that required it occurred.
  // We create a trigger by changing a boolean to the opposite value.
  categoriesResetDisplayTrigger: boolean;
}

export default EntityTypeFilter;
