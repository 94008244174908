import L from 'leaflet';
import { Dict } from '../../../../BasicTypes';
import {
  selectedDepthsPaneName,
  depthsPaneName,
  PanesConfigs
} from '../../../../config/paneConfig';
import { SCAN_SENSOR_GRADIOMETRY, TYPE_HUMAN } from '../../../../Constant/entity';
import { TransmitterMethod } from '../../../../Generated/ExoDBAPI';
import { darkenColor } from '../../../../Utils/cssUtils';
import { getAnalysisShapeColor } from '../../../../Utils/mapUtils';
import { AnalysisProductsPipe, AnalysisProductsTransmitter } from '../../DataTypes/MapEntities';
import { SiteWideDepthData } from '../../DepthSupport/depthData';
import { getTransmittersMethod } from '../../SelectedPipe/FindMatchingFeatures';
import { SELECTED_PIPE_CROSSING_COLOR, UNSELECTED_PIPE_CROSSING_COLOR } from './constants';
import {
  generatePipeInfoLines,
  generateDepthInfoLines,
  generatePipeTooltip
} from './generatePipeTooltip';
import { DislpayPipeModeValues } from '../../MapControls/DisplayPipeMode/type';
import { Theme } from '@mui/material';

export const getTextColor = (color: string, theme: Theme) => {
  try {
    return theme.palette.getContrastText(color);
  } catch (err) {
    return 'white';
  }
};

export const getPipeColor = ({
  pipe,
  isSelected,
  displayPipesMode,
  transmitterMapping
}: {
  pipe: AnalysisProductsPipe;
  isSelected: boolean;
  displayPipesMode: DislpayPipeModeValues;
  transmitterMapping: Dict<AnalysisProductsTransmitter[]> | undefined;
}) => {
  const transmitterMethod =
    pipe.header.scan.sensor === SCAN_SENSOR_GRADIOMETRY
      ? getTransmittersMethod(pipe, transmitterMapping!)
      : null;
  let color = getAnalysisShapeColor(
    pipe,
    isSelected,
    displayPipesMode,
    transmitterMethod as TransmitterMethod
  );
  color =
    pipe.header.scan.sensor === SCAN_SENSOR_GRADIOMETRY && pipe?.creation_source === TYPE_HUMAN
      ? darkenColor(color, 1.5)
      : color;
  return color;
};

export function generatePipe({
  pipe,
  isSelected,
  siteDepthData,
  setSelectedPipe,
  color,
  textColor
}: {
  pipe: AnalysisProductsPipe;
  isSelected: boolean;
  siteDepthData: SiteWideDepthData;
  transmitterMapping: Dict<AnalysisProductsTransmitter[]> | undefined;
  setSelectedPipe?: (pipe: AnalysisProductsPipe) => void;
  displayPipesMode: DislpayPipeModeValues;
  color: string;
  textColor: string;
}) {
  function onClick() {
    setSelectedPipe && setSelectedPipe(pipe);
  }

  const tooltip = generatePipeTooltip({
    content: generatePipeInfoLines(pipe),
    backgroundColor: color,
    textColor
  });

  const geojson = new L.GeoJSON(JSON.parse(pipe.header.geoJson), {
    interactive: true,
    style: { weight: 2, color },
    onEachFeature: (_, layer) => {
      layer.bindTooltip(tooltip, { sticky: true });
      layer.on({
        click: (e) => {
          if (e.originalEvent.altKey) {
            pipe.in_group = !pipe.in_group;
            onClick();
            return;
          }
          onClick();
        }
      });
    }
  });

  if (pipe.in_group) {
    const inGroupGeoJson = new L.GeoJSON(JSON.parse(pipe.header.geoJson), {
      interactive: true,
      pane: PanesConfigs.PIPE.name,
      style: { weight: 12, color: 'green', opacity: 0.6 },
      onEachFeature: (_, layer) => {
        layer.bindTooltip(tooltip, { sticky: true });
        layer.on({
          click: (e) => {
            if (e.originalEvent.altKey) {
              pipe.in_group = !pipe.in_group;
              onClick();
              return;
            }
            onClick();
          }
        });
      }
    });
    geojson.addLayer(inGroupGeoJson);
  }

  if (isSelected) {
    (pipe.depthPoints ?? []).forEach((point) => {
      const pathOptions = {
        color: isSelected ? SELECTED_PIPE_CROSSING_COLOR : UNSELECTED_PIPE_CROSSING_COLOR,
        interactive: true,
        fillOpacity: 1.0
      };

      const circleMarker = new L.CircleMarker([point.lat, point.lon], {
        ...pathOptions,
        pane: isSelected ? selectedDepthsPaneName : depthsPaneName,
        radius: isSelected ? 2 : 1
      });

      const tooltip = generatePipeTooltip({
        content: generateDepthInfoLines(pipe, point, siteDepthData),
        backgroundColor: color,
        textColor
      });

      circleMarker.on('click', onClick);
      circleMarker.bindTooltip(tooltip);

      geojson.addLayer(circleMarker);
    });
  }
  return geojson;
}
