import React from "react";
import { MAXIMUM_CHUNK_SIZE, MAX_UPLOAD_CONCURRENCY } from "../../config/fileUploadConfig";
import FileInput, { FileInputRef } from "../Inputs/FileInput";
import { Api, FileType } from "../../Generated/ExoDBAPI";
import { getPresignedUrlCallback, uploadFileUsingPresignedUrl } from "../../Utils/fileUploadUtils";
import CallbackPresignedURLUploader from "../../MultipartUpload/callBackPresingedURLUploader";

function UploadBackgroundImageForm({ siteId }: { siteId: string }) {
  const BackgroundImageRef = React.useRef<FileInputRef>(null);
  const submitRef = React.useRef<HTMLInputElement>(null);

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const submitButton = submitRef.current;

    if (submitButton === null) {
      return;
    }

    submitButton.className = "buttonClicked";
    submitButton.disabled = true;

    const api = new Api();

    try {
      await uploadFileUsingPresignedUrl(
        BackgroundImageRef,
        api,
        FileType.BackgroundImage,
        siteId,
        MAXIMUM_CHUNK_SIZE,
        MAX_UPLOAD_CONCURRENCY
      );
      // TODO: This is bad and stupid and i also hate. Remove this asap when our file database isn't a stupid csv in s3
      const fileName = `${Date.now()}.marker`;
      const uploader = new CallbackPresignedURLUploader(
        new File(["I hate this"], fileName),
        getPresignedUrlCallback(siteId, fileName, FileType.BackgroundImage, fileName.length, api, MAXIMUM_CHUNK_SIZE),
        async () => {}
      );
      const callbacks = await uploader.createCallbacks();
      await callbacks.uploadCallbacks[0]();
    } catch (e) {
      window.alert("Some files upload failed");
      console.log(e);
    } finally {
      submitButton.className = "formButton";
      submitButton.disabled = false;
    }
  }

  return (
    <form className="siteForm" onSubmit={onSubmit}>
      <div className="formSection">
        <span className="siteFormEntry">
          <label>Background file:</label>
          <FileInput multipleFiles={true} ref={BackgroundImageRef} key="BackgroundImageInput" accept=".tif" />
        </span>
        <span className="formSectionIsolated">
          <input type="submit" className="formButton" value="Upload background image" ref={submitRef} />
        </span>
      </div>
    </form>
  );
}

export default UploadBackgroundImageForm;
