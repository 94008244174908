import * as React from 'react';
import {
  Autocomplete,
  Button,
  Modal,
  TextField,
  Typography,
  Stack,
  Alert,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import ModalBox from '../../Templates/ModalBox';

const MODAL_WIDTH = 600;
const DEFAULT_PLATFORM_TYPES = ['CART', 'EXOROAD', 'DRONE', 'BACKPACK'];
function CreatePolygonModal({
  sensors,
  reportSave,
  reportCancel,
  defaultName,
  defaultComment,
  defaultSensors,
  defaultPlatformTypes,
  defaultIsRescan
}: CreatePolygonModalProps) {
  const [name, setName] = React.useState(defaultName ?? '');
  const [chosenSensors, setChosenSenors] = React.useState<string[]>(defaultSensors ?? []);
  const [chosenPlatformTypes, setChosenPlatformTypes] = React.useState<string[]>(
    defaultPlatformTypes ?? []
  );
  const [comment, setComment] = React.useState(defaultComment ?? '');
  const [isRescan, setIsRescan] = React.useState<boolean>(defaultIsRescan ?? false);
  const [error, setError] = React.useState<string>();

  function validateInput() {
    if (name === '') {
      setError('Enter a name');
      return;
    }
    reportSave(name, chosenSensors, chosenPlatformTypes, comment, isRescan);
  }

  React.useEffect(() => {
    setError(undefined);
  }, [name, comment, chosenSensors]);

  return (
    <Modal open={true}>
      <ModalBox>
        <Stack gap={2} sx={{ width: MODAL_WIDTH }}>
          <Typography variant="h4">Planned Polygon</Typography>
          <TextField label="Polygon Name" value={name} onChange={(e) => setName(e.target.value)} />
          <FormControlLabel
            label="Rescan"
            control={
              <Checkbox checked={isRescan} onChange={(e) => setIsRescan(e.target.checked)} />
            }
          />
          <Autocomplete
            options={sensors}
            placeholder="Sensors"
            renderInput={(params) => (
              <TextField {...params} key={`option-${params.id}`} placeholder="Sensors" />
            )}
            value={chosenSensors}
            onChange={(e, newVal) => {
              setChosenSenors(newVal);
            }}
            multiple
            disableCloseOnSelect
            limitTags={2}
            sx={{ maxWidth: MODAL_WIDTH }}
          />
          <Autocomplete
            options={DEFAULT_PLATFORM_TYPES}
            placeholder="Playform types"
            value={chosenPlatformTypes}
            renderInput={(params) => (
              <TextField {...params} key={`option-${params.id}`} placeholder="Playform types" />
            )}
            onChange={(e, newVal) => {
              setChosenPlatformTypes(newVal);
            }}
            multiple
            disableCloseOnSelect
            limitTags={2}
            sx={{ maxWidth: MODAL_WIDTH }}
          />
          <TextField
            label="Comment"
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            multiline
          />
          {error !== undefined && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Stack direction="row">
            <Button variant="outlined" onClick={validateInput}>
              Save
            </Button>
            <Button variant="outlined" sx={{ marginLeft: 'auto' }} onClick={reportCancel}>
              Discard
            </Button>
          </Stack>
        </Stack>
      </ModalBox>
    </Modal>
  );
}

export interface CreatePolygonModalProps {
  sensors: string[];
  reportSave: (
    name: string,
    sensors: string[],
    platforms: string[],
    comment: string,
    isRescan: boolean
  ) => void;
  reportCancel: () => void;
  defaultName?: string;
  defaultComment?: string;
  defaultSensors?: string[];
  defaultPlatformTypes?: string[];
  defaultIsRescan?: boolean;
}

export default CreatePolygonModal;
