import * as React from "react";
import { UserLayerAttributes, UserLayerShape } from "../../../Generated/ExoDBAPI";
import { Grid, MenuItem, TextField, Stack, FormControl, Typography, IconButton, FormLabel } from "@mui/material";
import { getMarkingOptions } from "./DrawnCommon";
import SaveIcon from "@mui/icons-material/Save";
import ReplayIcon from "@mui/icons-material/Replay";
import EditIcon from "@mui/icons-material/Edit";
import { DrawnContext } from "./DrawnFileData";
import { LatLngExpression } from "leaflet";

const selectedShapeInputDisplayConfig = {
  size: "small" as "small",
  sx: { width: "80%" },
};

function SelectedShapeView({ selectedShape, updateShapeAttributes, editable, drawnContext }: SelectedShapeViewProps) {
  const [shapeAttributes, setShapeAttributes] = React.useState({ ...(selectedShape ?? {}).layerAttributes });
  const [originalShapeAttributes, setOriginalShapeAttributes] = React.useState({
    ...(selectedShape ?? {}).layerAttributes,
  });

  const shapeLatLons = React.useMemo(() => {
    if (selectedShape === undefined) {
      return [];
    }
    return selectedShape.lats.map((lat, i) => [lat, selectedShape.lons[i]]);
  }, [JSON.stringify(selectedShape)]);

  React.useEffect(() => {
    setShapeAttributes({ ...(selectedShape ?? {}).layerAttributes });
    setOriginalShapeAttributes({ ...(selectedShape ?? {}).layerAttributes });
    drawnContext.setEditingShape(false);
  }, [JSON.stringify(selectedShape)]);

  React.useEffect(() => {
    if (selectedShape !== undefined) {
      drawnContext.setEditedShapePositions(selectedShape.lats.map((lat, i) => [lat, selectedShape.lons[i]]));
    } else {
      drawnContext.setEditedShapePositions([]);
    }
  }, [JSON.stringify(selectedShape), JSON.stringify(shapeLatLons)]);

  return (
    <Stack gap={1} m={1}>
      <Typography variant="h5">Selected Shape</Typography>
      <FormControl>
        <Stack gap={1}>
          {editable && (
            <Stack direction="row">
              <IconButton
                onClick={() => drawnContext.setEditingShape((val) => !val)}
                color={drawnContext.editingShape ? "secondary" : "default"}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  drawnContext.setEditingShape(false);
                  if (selectedShape !== undefined) {
                    updateShapeAttributes(selectedShape, shapeAttributes, drawnContext.editedShapePositions);
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  drawnContext.setEditingShape(false);
                  setShapeAttributes({ ...(selectedShape ?? {}).layerAttributes });
                  drawnContext.setEditedShapePositions([...shapeLatLons] as any);
                }}
              >
                <ReplayIcon />
              </IconButton>
              {(JSON.stringify(originalShapeAttributes) !== JSON.stringify(shapeAttributes) ||
                JSON.stringify(shapeLatLons) !== JSON.stringify(drawnContext.editedShapePositions)) && (
                <Typography color="red" sx={{ alignSelf: "center" }}>
                  Changes not saved
                </Typography>
              )}
            </Stack>
          )}
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                select
                label="QL"
                value={shapeAttributes.QL ?? ""}
                onChange={(e) => setShapeAttributes((attr) => ({ ...attr, QL: e.target.value }))}
                disabled={!drawnContext.editingShape}
                {...selectedShapeInputDisplayConfig}
              >
                {selectedShape === undefined
                  ? []
                  : getMarkingOptions(selectedShape.shapeType).map((c) => <MenuItem value={c}>{c}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Type"
                value={shapeAttributes.type ?? ""}
                onChange={(e) => setShapeAttributes((attr) => ({ ...attr, type: e.target.value }))}
                disabled={!drawnContext.editingShape}
                {...selectedShapeInputDisplayConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Properties"
                value={shapeAttributes.properties ?? ""}
                onChange={(e) => setShapeAttributes((attr) => ({ ...attr, properties: e.target.value }))}
                disabled={!drawnContext.editingShape}
                {...selectedShapeInputDisplayConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Depth"
                value={shapeAttributes.depth ?? ""}
                onChange={(e) => setShapeAttributes((attr) => ({ ...attr, depth: Number.parseFloat(e.target.value) }))}
                type="number"
                disabled={!drawnContext.editingShape}
                {...selectedShapeInputDisplayConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Notes"
                value={shapeAttributes.notes ?? ""}
                onChange={(e) => setShapeAttributes((attr) => ({ ...attr, notes: e.target.value }))}
                disabled={!drawnContext.editingShape}
                {...selectedShapeInputDisplayConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <FormLabel>Color</FormLabel>
                <input
                  type="color"
                  value={shapeAttributes.color ?? "#000000"}
                  onChange={(e) => setShapeAttributes((attr) => ({ ...attr, color: e.target.value }))}
                  disabled={!drawnContext.editingShape}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </FormControl>
    </Stack>
  );
}

export interface SelectedShapeViewProps {
  selectedShape?: UserLayerShape;
  updateShapeAttributes: (
    shape: UserLayerShape,
    newAttributes: UserLayerAttributes,
    newPositions: LatLngExpression[]
  ) => void;
  editable: boolean;
  drawnContext: DrawnContext;
}

export default SelectedShapeView;
