import * as React from "react";
import {useMapEvents, useMap} from "react-leaflet";

const scaleValue = (zoom: number, value: number): number => {
    return value * Math.pow(2, zoom - 15);
}

function useScalableValue(value: number) {
    const map = useMap();
    const [zoomLevel, setZoomLevel] = React.useState(map.getZoom()); // initial zoom level provided for MapContainer

    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });

    return scaleValue(zoomLevel, value)
}

export default useScalableValue;
