import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button } from '@mui/material';
import './ManHolesForm.css';
import { TargetObject } from '../../Types/MappingEntities';
import { SiteDetails } from '../../Generated/ExoDBAPI';
import UploadMedia from '../ExoFuser/UploadFiles/layerUploaders/UploadMedia';
import { POIEntityTypes } from '../../BasicTypes';
import { useCreatePoiMutation } from '../../Generated/Graphql';

export type FormData = {
  scanName: string;
  poiName: string;
  notes: string;
  fileNames: string[];
  folderName: string;
  entity: POIEntityTypes;
  siteId: string;
  scanId: string;
};

type POIProps = {
  targetObject: TargetObject;
  scanId: string;
  entity?: POIEntityTypes | undefined;
  scanName: string;
  siteName: string;
  site: SiteDetails;
  coordinates: [number, number] | null;
  updateTargetInfo: (targetInfo: FormData) => void;
};

const POIForm = ({
  targetObject,
  scanId,
  scanName,
  siteName,
  site,
  entity,
  coordinates,
  updateTargetInfo
}: POIProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();

  const [filesLoaded, setFilesLoaded] = useState<string[]>([]);
  const [filesFailed, setFilesFailed] = useState<string[]>([]);
  const [folderName, setFolderName] = useState<string>('');
  const [createPoiMutation] = useCreatePoiMutation();
  const [formData, setFormData] = useState<FormData>({
    scanName: scanName,
    poiName: targetObject?.name || '',
    notes: '',
    fileNames: [],
    folderName: '',
    siteId: site.id,
    scanId: scanId,
    entity: entity as POIEntityTypes
  });
  const [errMessage, setErrMessage] = useState<string>('');

  const updateMediaFiles = (folder: string, fileName: string, success: boolean) => {
    if (success) {
      setFilesLoaded([...filesLoaded, fileName]);
      setFormData((prev: FormData) => ({
        ...prev,
        fileNames: [...prev.fileNames, fileName]
      }));
    } else {
      setFilesFailed([...filesFailed, fileName]);
    }
    setFolderName(folder);
    setFormData((prev: FormData) => ({ ...prev, folderName: folder }));
  };

  const checkValidate = () => {
    const errorMessages: string[] = [];

    if (errorMessages.length > 0) {
      setErrMessage(errorMessages.join(', '));
      return false;
    }

    setErrMessage('');
    return true;
  };
  const geoData = {
    geometry: { coordinates, type: 'MultiPoint' },
    properties: {},
    type: 'Feature'
  };
  const onSubmit = async (data: FormData) => {
    const target_info = {
      ...data,
      fileNames: filesLoaded,
      siteId: site.id,
      folderName: folderName,
      entity: entity as POIEntityTypes
    };

    if (!checkValidate()) return;
    updateTargetInfo(target_info);
    const graphqlResp = await createPoiMutation({
      variables: {
        geoData: JSON.stringify(geoData),
        poiType: entity as POIEntityTypes,
        comment: data.notes,
        sitePoisId: site.id,
        scanPoisId: scanId
      }
    });
    if (graphqlResp.errors) {
      console.error('Got errors', graphqlResp.errors);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'formContainer'}>
      <div className='poiform_title'>
        {entity || ''}
      </div>
      <div className="control">
        <TextField
          label="Site Name"
          className={'control text-input-long'}
          value={siteName}
          variant="outlined"
          disabled
        />
      </div>
      <div className="control">
        <TextField
          label="Scan name"
          {...register('scanName', { required: true, valueAsNumber: false })}
          error={!!errors.scanName}
          helperText={errors.scanName && 'Scan Name is required'}
          className={'control text-input-long'}
          variant="outlined"
          value={scanName}
          InputLabelProps={{ shrink: scanName ? true : undefined }}
          onChange={(e) => {
            setFormData({ ...formData, scanName: e.target.value });
          }}
        />
      </div>
      <div className="control">
        <TextField
          label="General Notes and Description"
          {...register('notes', { required: false, valueAsNumber: false })}
          error={!!errors.notes}
          helperText={errors.notes && 'Notes'}
          className="control"
          inputProps={{ style: { padding: '10px', width: '300px' } }}
          onChange={(e) => {
            setFormData({ ...formData, notes: e.target.value });
          }}
        />
        <div className="comments">
          List all potential dangers or peculiar occurrences such
          <br /> as the scent of fuel, presence of gas, or asbestos.
        </div>
      </div>

      <p className="text">Allowed file types: Image, Video, Audio</p>
      {filesLoaded.map((file) => (
        <p key={file}>{file} uploaded successfully</p>
      ))}

      {filesFailed.map((file) => (
        <p key={file}>{file} failed to upload</p>
      ))}
      <hr />
      <div className="control">
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '250px' }}>
          <UploadMedia
            plannedSite={site}
            layerName={`${formData.poiName}`}
            folderName="poi"
            callBack={updateMediaFiles}
          />
        </div>
      </div>

      {errMessage.length > 0 && (
        <pre style={{ color: 'red', fontWeight: 'bolder' }}>{errMessage}</pre>
      )}
      <hr />

      <div className="submit">
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default POIForm;
