import { LatLngLiteral } from 'leaflet';
import { SelectedToolsMode } from '../Components/ExoFuser/MapControls/MeasurementUtil';
import { v4 as uuidv4 } from 'uuid';
import { Classification } from '../Constant/exoTools';

export const createEntityObject = (
  points: LatLngLiteral[],
  selectedTool: SelectedToolsMode,
  siteId: string,
  text = '',
  properties: string = ''
) => {
  const geoData = {
    geometry: {
      type: selectedTool.toString(),
      coordinates: points
    }
  };
  return {
    id: uuidv4(),
    siteId: siteId,
    geoData: JSON.stringify(geoData),
    classification: Classification,
    object_type: SelectedToolsMode[selectedTool],
    color: '#000000',
    depth: 0,
    properties: properties,
    text: text
  };
};

export const updateEntityObject = (
  id: string,
  points: LatLngLiteral[],
  selectedTool: SelectedToolsMode,
  properties: string = ''
) => {
  const geoData = {
    geometry: {
      type: selectedTool.toString(),
      coordinates: points
    }
  };
  return {
    id: id,
    geoData: JSON.stringify(geoData),
    properties: properties
  };
};
