import * as React from "react";
import ScansPolygons, { SensorPolygonsData } from "./ScansPolygons";
import { Dict } from "../../../BasicTypes";
import { ScanDetails } from "../DataTypes/MapEntities";
import PlatformType from "./PlatformType";

function ScansTab({ scans, reportRelevantScans, setScans, getScanGeoJson, doneLoading }: ScansTabProps) {
  return (
    <span className="tabsContainer">
      <span className="tabsBody">
        <ScansPolygons
          scans={scans}
          reportRelevantScans={reportRelevantScans}
          getScanGeoJson={getScanGeoJson}
          setScans={setScans}
          doneLoading={doneLoading}
        />
      </span>
      <span className="tabsBody">
        <PlatformType
          scans={scans}
          doneLoading={doneLoading}
        />
      </span>
    </span>
  );
}

export interface ScansTabProps {
  scans: ScanDetails[];
  reportRelevantScans: (scans: Dict<SensorPolygonsData>) => void;
  setScans: (scans: ScanDetails[]) => void;
  getScanGeoJson: (scan: ScanDetails) => Promise<string>;
  doneLoading: boolean;
}

export default ScansTab;
