import React from "react";
import PageCard from "../Templates/PageCard";
import { IconButton, Typography } from "@mui/material";
import { CopyBlock } from "react-code-blocks";
import axios from "axios";
import { getBearerToken } from "../../Utils/azureAuth";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Stack } from "@mui/system";

type RTKMonitorProps = {};

function RTKMonitor({}: RTKMonitorProps) {
  const [status, setStatus] = React.useState({});
  const [failed, setFailed] = React.useState(false);

  async function getStatus() {
    setFailed(false);
    const statusEndpoint = process.env.REACT_APP_NTRIP_STATUS_ENDPOINT;
    if (statusEndpoint === undefined) {
      window.alert("NTRIP endpoint is not configured");
      return;
    }
    try {
      const resp = await axios.get(statusEndpoint, {
        headers: { authorization: `${await getBearerToken()}` },
      });

      setStatus(resp.data);
    } catch (e) {
      window.alert("Got an error while trying to get ntrip db status");
      console.log("error while trying to fetch ntip db status", e);
      setFailed(true);
    }
  }
  React.useEffect(() => {
    getStatus();
  }, []);

  return (
    <PageCard>
      <Stack direction="row" gap={2}>
        <Typography variant="h3">RTK Monitor Status</Typography>
        <IconButton onClick={getStatus}>
          <RefreshIcon />
        </IconButton>
      </Stack>
      <hr />
      {!failed && <CopyBlock text={JSON.stringify(status, null, 4)} language={"json"} />}
      {failed && "Failed to get data from server"}
    </PageCard>
  );
}

export default RTKMonitor;
