import { ApolloQueryResult } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import * as React from "react";
import { StateDetails } from "../../Generated/ExoDBAPI";
import { GetSiteScansQuery, useGetSitesByNameLazyQuery, useGetSiteScansLazyQuery } from "../../Generated/Graphql";
import { getAllPagesResults } from "../../Utils/graphqlUtils";
import { ScanData } from "../Pages/ScansDashboard";
import { parseScanResults } from "../ScansDashboard/GraphqlParser";
import ScanCard from "../ScansDashboard/ScanCard";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { StringIterator } from "lodash";

const columns: GridColDef[] = [
  { field: "name", headerName: "name", flex: 1 },
  { field: "status", headerName: "Status" },
  { field: "sensor", headerName: "Sensor", flex: 1 },
  { field: "startTime", headerName: "Start time", flex: 1 },
  { field: "endTime", headerName: "End time", flex: 1 },
];

function SiteScans({ s3Prefix }: SiteScansProps) {
  const [graphqlSiteId, setGraphqlSiteId] = React.useState<string>("");
  const [scans, setScans] = React.useState<ScanData[]>([]);
  const [_getScans, queryResults] = useGetSiteScansLazyQuery({ variables: { siteId: graphqlSiteId } });
  const [_getSites, sitesQueryResult] = useGetSitesByNameLazyQuery();
  React.useEffect(() => {
    async function fetchData() {
      const results = await sitesQueryResult.fetchMore({ variables: { name: s3Prefix } });
      const site = results.data.searchSites?.items[0];
      if (site) {
        setGraphqlSiteId(site.id);
      }
    }
    fetchData();
  }, [s3Prefix]);

  React.useEffect(() => {
    async function fetchData() {
      const results = await getAllPagesResults(
        queryResults,
        (res) => parseScanResults(res, (res) => res.data.getSite?.scans?.items),
        (res) => res.data.getSite?.scans?.nextToken
      );
      setScans(results);
    }

    if (graphqlSiteId !== "") {
      fetchData();
    }
  }, [graphqlSiteId]);

  return (
    <>
      <Box sx={{ height: 600 }}>
        <Typography variant="h5">Scans</Typography>
        <hr />
        <DataGrid
          rows={scans.map((s) => {
            return {
              name: s.name,
              status: s.status,
              startTime: new Date(s.startTimestamp).toLocaleString(),
              endTime: s.endTimestamp ? new Date(s.endTimestamp).toLocaleString() : "Unknown",
              sensor: s.sensor,
              id: s.id,
            };
          })}
          columns={columns}
          getRowId={(e: any) => e.id}
          pageSize={10}
        ></DataGrid>
      </Box>
      <Box sx={{ height: 60 }}></Box>
    </>
  );
}

export interface SiteScansProps {
  s3Prefix: string;
}

export default SiteScans;
