import React from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../Contexts/UserContext";
import { FileData, FileInputRef } from "../Inputs/FileInput";
import { readFileArrayBuffer, readFileString, readFileUrl } from "../../Utils/fileUtils";
import { Api, ClientDetails, Contact, PolygonFileType, SiteType, StateDetails } from "../../Generated/ExoDBAPI";
import { getRequestParams, getRequestParamsOnlyHeaders } from "../../Utils/azureAuth";
import GeneralInfoFormSection from "./GeneralInfoFormSection";
import ClientInfoFormSection from "./ClientInfoFormSection";
import PolygonFormSection from "./PolygonFormSection";
import { useCreateSiteMutation, useGetSitesAmountWithS3RefLazyQuery } from "../../Generated/Graphql";
import { READERS } from "../../Utils/geoJsonParser";
import { Typography } from "@mui/material";
import { createSiteName, validateSiteNameInputs } from "./SiteUtils";
import CreateSiteConfirmationModal from "./CreateSiteConfirmationModal";
import axios from "axios";

/**
 * The form for creating a new site
 * @param {*} loadedStates States loaded previously while on the site to save having to wait for them to load
 * * @param {*} siteTypes site types loaded previously while on the site to save having to wait for them to load
 * @returns
 */
function CreateSiteForm({ loadedStates, siteTypes, loadedClients, customers, locations }: CreateSiteProps) {
  const [states, setStates] = React.useState(loadedStates);
  const [freeTextLocation, setFreeTextLocation] = React.useState("");
  const [freeTextName, setFreeTextName] = React.useState("");
  const [siteType, setSiteType] = React.useState<SiteType | "">("");
  const [stateId, setStateId] = React.useState<string>("");

  const [clients, setClients] = React.useState(loadedClients);
  const [clientId, setClientId] = React.useState<string>("");
  const [contactId, setContactId] = React.useState(1);
  const [contacts, setContacts] = React.useState<Contact[]>([]);

  const [clientContacts, setClientContacts] = React.useState<Contact[]>([]);
  const [evalErrors, setEvalErrors] = React.useState(false);

  const [openAreYouSure, setOpenAreYouSure] = React.useState(false);

  const name = React.useMemo(() => {
    if (freeTextName === "" || freeTextLocation === "" || clientId === undefined) {
      return "";
    }
    return createSiteName(clients, clientId, freeTextLocation, freeTextName);
  }, [clients, clientId, freeTextLocation, freeTextName]);

  const polygonFile = React.useRef<FileInputRef>(null);

  const { user } = React.useContext(UserContext);

  const submitRef = React.useRef<HTMLInputElement>(null);

  const [createSiteMutation, _] = useCreateSiteMutation();
  const [getSitesAmountWithS3Ref, _getSitesRes] = useGetSitesAmountWithS3RefLazyQuery();

  const navigate = useNavigate();

  React.useEffect(() => {
    async function fetchData() {
      if (user !== undefined) {
        if (states.length > 0) {
          setStateId(states[0].id);
        }
        if (clients.length > 0) {
          setClientId(clients[0].id);
        }
        const api = new Api();
        api.states.getStates(await getRequestParams()).then((res) => {
          setStates(res.data.states);
          if (stateId !== undefined) {
            setStateId(res.data.states[0].id);
          }
        });
        api.clients.getClients(await getRequestParams()).then((res) => {
          const clients = res.data.clients;
          setClients(clients);
          if (clients.length >= 1 && clientId !== undefined) {
            setClientId(clients[0].id);
          }
        });
      }
    }
    const defaultContacts = [{ name: "", jobTitle: "", phone: "", id: "0" }];
    setContacts(defaultContacts);
    fetchData();
  }, [user]);

  React.useEffect(() => {
    async function fetchContacts() {
      setClientContacts([]);
      if (user !== undefined && clientId !== undefined) {
        const api = new Api();

        api.contacts
          .getContacts({ clientId: clientId }, await getRequestParams())
          .then((res) => setClientContacts(res.data.contacts));
      }
    }
    fetchContacts();
  }, [clientId, user]);

  async function getSiteUUID(s3Ref: string) {
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(s3Ref));
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray.map((item) => item.toString(16).padStart(2, "0")).join("");
    return `${hash.slice(0, 8)}-${hash.slice(8, 12)}-${hash.slice(12, 16)}-${hash.slice(16, 20)}-${hash.slice(20, 32)}`;
  }

  /**
   * Gathers all the information from the form fields and sends a create site request to the backend
   */
  async function createSite() {
    setOpenAreYouSure(false);
    const submitButton = submitRef.current;
    if (submitButton === null || polygonFile.current === null) {
      return;
    }

    submitButton.className = "buttonClicked";
    submitButton.disabled = true;
    setEvalErrors(true);
    try {
      const polygonFileData = polygonFile.current.getUploadedFileData();

      if (polygonFileData.length < 1) {
        throw new Error("You need to upload a polygon file");
      }
      const stateInfo = states.find((state) => state.id === stateId);
      if (stateInfo === undefined) {
        throw new Error(`Picked state id ${stateId} doesn't match any existing state somehow`);
      }

      const res = await getSitesAmountWithS3Ref({ variables: { s3Ref: `${stateInfo.stateName}_${name}` } });
      const amountResult = res.data?.searchSites?.total;
      if (amountResult !== 0) {
        throw new Error(`There are already ${amountResult} of sites with the name ${name}`);
      }

      const polygonFiles = [];
      for (const file of polygonFileData) {
        const splits = file.fileName.split(".");
        const fileData = await readFileUrl(file.data);
        if (fileData === null) {
          throw Error("Polygon file data is null");
        }

        polygonFiles.push({
          content: fileData.split(";base64,")[1],
          type: splits[splits.length - 1] as PolygonFileType,
        });
      }

      const graphqlResp = await createGraphqlSite(polygonFileData);
      if (graphqlResp.errors) {
        window.alert(`Got ${graphqlResp.errors.length} while trying to create site in graphql`);
        console.log("Got errors", graphqlResp.errors);
        throw graphqlResp.errors[0];
      }

      const api = new Api();

      const addSiteRes = await api.sites.addSite(
        {
          name,
          stateId,
          clientId,
          polygonFiles: polygonFiles,
          siteType: siteType as SiteType,
          contacts,
        },
        await getRequestParams()
      );
      navigate(`../sites/${addSiteRes.data.id}`);
    } catch (e) {
      let errorMessage = "Unknown Error while creating a site";
      if (e instanceof Error) {
        errorMessage = `Failed to create site. ${e.message}`;
      }
      window.alert(errorMessage);
    } finally {
      submitButton.className = "formButton";
      submitButton.disabled = false;
    }
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!validateSiteNameInputs(freeTextLocation, freeTextName)) {
      throw new Error("Invalid values in name inputs");
    }

    if (siteType === "") {
      throw new Error("Pick a site type");
    }

    if (polygonFile.current === null) {
      return;
    }

    const polygonFileData = polygonFile.current.getUploadedFileData();

    if (polygonFileData.length < 1) {
      throw new Error("You need to upload a polygon file");
    }

    setOpenAreYouSure(true);
  }

  /**
   * Updates the contacts state when creating a new contact
   */
  function handelCreateNewContact() {
    const tempId = contactId + 1;
    const newContacts = [...contacts, { name: "", jobTitle: "", phone: "", id: `${tempId}` }];
    setContactId(tempId);
    setContacts(newContacts);
  }

  /**
   * Updates the contact state when deleting a contact
   * @param {int} index index of contact to delete
   */
  function handelDeleteContact(index: number) {
    const tempContacts = [...contacts];
    tempContacts.splice(index, 1);
    setContacts(tempContacts);
  }

  function handelUpdateContact(contact: Contact) {
    const tempContacts = [...contacts];
    const index = tempContacts.findIndex((c) => c.id === contact.id);
    tempContacts[index] = contact;
    setContacts(tempContacts);
  }

  async function createGraphqlSite(polygonFileData: FileData[]) {
    const stateInfo = states.find((state) => state.id === stateId);
    if (stateInfo === undefined) {
      throw new Error(`Picked state id ${stateId} doesn't match any existing state somehow`);
    }

    const graphqlLocationId = locations.find(
      (location) => location.state === stateInfo.stateName && location.country === stateInfo.countryName
    )?.id;

    if (graphqlLocationId === undefined) {
      throw new Error(`Location ${stateInfo.countryName}, ${stateInfo.stateName} doesn't exist in the new DB`);
    }

    const clientInfo = clients.find((client) => client.id === clientId);
    if (clientInfo === undefined) {
      throw new Error(`Picked client id ${clientId} doesn't match any existing client somehow`);
    }
    const graphqlCustomerId = customers.find((customer) => customer.name === clientInfo.name)?.id;
    if (graphqlCustomerId === undefined) {
      throw new Error(`Customer ${clientInfo.name} doesn't exist in the new DB`);
    }

    const geoJsons = [];
    for (const file of polygonFileData) {
      const fileType = file.fileName.split(".").pop();
      if (fileType === undefined) {
        throw new Error("Polygon mime type was undefined");
      }
      const fileData = fileType === "kml" ? await readFileString(file.data) : await readFileArrayBuffer(file.data);
      geoJsons.push(await READERS[fileType](fileData));
    }

    const features: any[] = [];
    geoJsons.flat().forEach((geoJson) => features.push(...geoJson.features));

    const s3Ref = `${stateInfo.stateName}_${name}`;
    const siteId = await getSiteUUID(s3Ref);
    const geoData = JSON.stringify({ type: "FeatureCollection", features: features });
    try {
      const prodBUrl = process.env.REACT_APP_PROD_B_GRAPHQL_HOST;
      if (prodBUrl !== undefined) {
        await axios.post(
          prodBUrl,
          {
            operationName: "CreateSite",
            query:
              "mutation CreateSite($id: ID!, $name: String!, $customerSitesId: ID!, $locationSitesId: ID!, $s3Ref: String!, $geoData: AWSJSON!) {\n  createSite(\n    input: {customerSitesId: $customerSitesId, locationSitesId: $locationSitesId, geo_data: $geoData, name: $name, s3_ref: $s3Ref, id: $id}\n  ) {\n    id\n    __typename\n  }\n}",
            variables: {
              id: siteId,
              name: name,
              s3Ref: s3Ref,
              customerSitesId: graphqlCustomerId,
              locationSitesId: graphqlLocationId,
              geoData: geoData,
            },
          },
          await getRequestParamsOnlyHeaders()
        );
      }
    } catch (e) {
      console.log("failed to create site in prodb");
      console.log(e);
      window.alert("Failed to create site in new DB. Please notify software team.");
    }

    return await createSiteMutation({
      variables: {
        name: name,
        locationSitesId: graphqlLocationId,
        customerSitesId: graphqlCustomerId,
        geoData: geoData,
        s3Ref: s3Ref,
        id: siteId,
      },
    });
  }

  return (
    <form className="siteForm" autoComplete="off" onSubmit={onSubmit} method="post">
      <div className="formSection">
        <Typography variant="h5">Site Information</Typography>
        <GeneralInfoFormSection
          states={states}
          clients={clients}
          siteTypes={siteTypes}
          siteType={siteType}
          stateId={stateId}
          clientId={clientId}
          freeTextLocation={freeTextLocation}
          freeTextName={freeTextName}
          onFreeTextLocationChange={setFreeTextLocation}
          onFreeTextNameChange={setFreeTextName}
          onStateIdChange={setStateId}
          onClientIdChange={setClientId}
          onSiteTypeChange={setSiteType}
          evalErrors={evalErrors}
        />
      </div>
      <hr />
      <ClientInfoFormSection
        clients={clients}
        clientId={clientId}
        contacts={contacts}
        autoCompleteContacts={clientContacts}
        onAddContact={handelCreateNewContact}
        onRemoveContact={handelDeleteContact}
        onClientIdChange={setClientId}
        onUpdateContact={handelUpdateContact}
      />
      <hr />
      <PolygonFormSection polyFileRef={polygonFile} />
      <div className="formSection" key="submitButton">
        <input type="submit" value="Create Site" className="formButton" ref={submitRef} />
      </div>
      <CreateSiteConfirmationModal
        open={openAreYouSure}
        acceptCallback={createSite}
        cancelCallback={() => setOpenAreYouSure(false)}
        siteName={name}
        client={clients.find((client) => client.id === clientId)}
        state={states.find((state) => state.id === stateId)}
        siteType={siteType as SiteType}
      />
    </form>
  );
}

export interface CreateSiteProps {
  loadedClients: ClientDetails[];
  loadedStates: StateDetails[];
  siteTypes: SiteType[];
  customers: Customer[];
  locations: Location[];
}

export interface Customer {
  name: string;
  id: string;
}

export interface Location {
  state?: string;
  country: string;
  region?: string;
  id: string;
}

export default CreateSiteForm;
