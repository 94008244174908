import { ApolloQueryResult, QueryResult } from "@apollo/client";

export async function getAllPagesResults<T, V>(
  queryResult: QueryResult<T, any>,
  transformer: (res: ApolloQueryResult<T>) => V[],
  getNextToken: (res: ApolloQueryResult<T>) => string | undefined | null
): Promise<V[]> {
  const results: V[] = [];
  await reportAllPagesResults(
    queryResult,
    (res) => {
      results.push(...transformer(res));
    },
    getNextToken
  );

  return results;
}

export async function reportAllPagesResults<T>(
  queryResult: QueryResult<T, any>,
  reporter: (res: ApolloQueryResult<T>) => void,
  getNextToken: (res: ApolloQueryResult<T>) => string | undefined | null,
  extraVars: any = {}
): Promise<void> {
  let nextToken = null;
  do {
    const newRes = await queryResult.fetchMore({ variables: { nextToken: nextToken, ...extraVars } });
    checkErrors(newRes);
    reporter(newRes);
    nextToken = getNextToken(newRes);
  } while (nextToken);
}

function checkErrors<T>(queryResult: ApolloQueryResult<T>) {
  if (queryResult.error) {
    throw queryResult.error;
  }
  if (queryResult.errors) {
    throw queryResult.errors[0];
  }
}
