import { kml } from "@tmcw/togeojson";
import { Dict } from "../BasicTypes";
import { readZipData } from "./fileUtils";

export const READERS: Dict<any> = {
  kml: kmlToGeoJsons,
  kmz: kmzToGeoJsons,
};

export function kmlToGeoJsons(kmlStr: string) {
  const kmlDoc = new DOMParser().parseFromString(kmlStr, "text/xml");
  return [kml(kmlDoc)];
}

export async function kmzToGeoJsons(kmzData: ArrayBuffer) {
  const geoJsons = [];
  const zip = await readZipData(kmzData);
  for (const fileName of Object.keys(zip.files)) {
    if (fileName.endsWith(".kml")) {
      const file = zip.files[fileName];
      const data = await file.async("string");
      geoJsons.push(...kmlToGeoJsons(data));
    }
  }

  return geoJsons;
}
