import * as React from "react";

function AddRTKCard({ onClick }: AddRTKCardProps) {
  return (
    <span className={`addRTKCard`} onClick={onClick}>
      Add a new RTK user
      <br />
      <b>+</b>
    </span>
  );
}

export interface AddRTKCardProps {
  onClick: () => void;
}

export default AddRTKCard;
