import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#fff'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#fff',
        backgroundColor: '#fff',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 14,
        fontStyle: 'bold',
        fontSize: 10,
        flexGrow: 1,
    },
    title: {
        width: '20%',
        textAlign: 'left',
        borderRightColor: '#fff',
        borderRightWidth: 0,
        paddingLeft: 8,
    },
    name: {
        width: '16%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
    },
    company: {
        width: '21%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
    },
    desc: {
        fontStyle: 'bold',
        fontSize: 10,
        width: '15%',
        color: 'blue',
        textAlign: 'left',
    },
    space: {
        width: '0%',
    },
  });

  const TableHeaderCrew = () => (
    <View style={styles.container}>
        <Text style={styles.desc}>CREW & CONDITIONS</Text>
        <Text style={styles.name}>Name</Text>
        <Text style={styles.name}>Phone</Text>
        <Text style={styles.name}>Time Arrived</Text>
        <Text style={styles.company}>Company</Text>
        <Text style={styles.name}>Equipment</Text>
    </View>
  );

  export default TableHeaderCrew;
