import * as React from "react";
import { Card, Container, Typography } from "@mui/material";
import { Dict } from "../../BasicTypes";
import ScanStatusContext from "./ScansStatusContext";

const STAGE_VISUAL_INFO_MAPPING: Dict<StageVisualConfig> = {
  Pending: { cardColor: "#f0f0f0", textColor: "#797979" }, // Very light grey
  Scheduled: { cardColor: "#fafafa", textColor: "#797979" }, // Almost white
  Running: { cardColor: "#e0e0f9", textColor: "#707079" }, // Blue
  Done: { cardColor: "#f0f9e9", textColor: "#707969" }, // Green
  Failed: { cardColor: "#ffe0e0", textColor: "#ff4444" }, // Red
  Skipped: { cardColor: "#fdd5bc", textColor: "#fd8e44" }, // Orange
  Unknown: { cardColor: "#333333", textColor: "#cccccc" }, // Black
};

function ScanStatusNode({ stageName, children, stageStatus }: ScanStatusNodeProps) {
  const [nodeId, setNodeId] = React.useState<string>("");
  const scanContext = React.useContext(ScanStatusContext);
  const canonicStatus = stageStatus[0].toUpperCase() + stageStatus.slice(1).toLowerCase();

  const visual = STAGE_VISUAL_INFO_MAPPING[canonicStatus] ?? STAGE_VISUAL_INFO_MAPPING["Unknown"];

  React.useEffect(() => {
    const newId = scanContext.registerNode({ sections: children });
    setNodeId(newId);
  }, []);

  React.useEffect(() => {
    if (nodeId !== undefined) {
      scanContext.updateNodeData(nodeId, { sections: children });
    }
  }, [children, nodeId]);

  return (
    <ScanStatusContext.Consumer>
      {(props) => (
        <Card
          sx={{
            width: 165,
            height: 75,
            cursor: "pointer",
            bgcolor: visual.cardColor,
            border: props.pickedNodeId === nodeId ? "#777777 solid 2px" : "",
          }}
          onClick={() => props.reportClick(nodeId)}
          onDoubleClick={() => props.reportClick(nodeId)}
        >
          <Container
            sx={{
              padding: 1,
              wordBreak: "break-word",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "100%",
              userSelect: "none",
            }}
          >
            <Typography variant="body1" fontWeight="bold" color={visual.textColor}>
              {stageName}
            </Typography>
          </Container>
        </Card>
      )}
    </ScanStatusContext.Consumer>
  );
}

export interface ScanStatusNodeProps {
  stageName: string;
  children?: React.ReactNode | React.ReactNode[];
  stageStatus: string;
}

interface StageVisualConfig {
  cardColor: string;
  textColor: string;
}

export default ScanStatusNode;
