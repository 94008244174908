import React from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useGetSiteScansRefsLazyQuery } from "../../Generated/Graphql";
import { getServicesRequestParams } from "../../Utils/azureAuth";
import { Api as ServicesApi, TriggerFrequenciesPostRequest } from "../../Generated/ExoServicesAPI";

import PageCard from "../Templates/PageCard";
import { ScanInfo, parseScans } from "../../Utils/parseCommonQueries";
import ChooseSite, { SiteInfo } from "../Common/ChooseSite";
import UserContext from "../Contexts/UserContext";
import LoadingWheel from "../LoadingWheel";

function getPolygonsHelperText(selectedPolygons: string[]): string | undefined {
  if (selectedPolygons.length === 0) {
    return "Give at least 1 polygon";
  }
  if (selectedPolygons.length > 1 && selectedPolygons.includes("All")) {
    return "`All` must be given alone";
  }

  return undefined;
}

function parsePolygons(polygons: string[]): (number | null)[] {
  return polygons.map((polygon) => {
    return polygon === "All" ? null : parseInt(polygon);
  });
}

const BUCKET = "exodigo-operations";

function UploadPreloaderSupportData() {
  const { user } = React.useContext(UserContext);
  const [selectedSites, setSelectedSites] = React.useState<SiteInfo[]>([]);

  const [allSiteScans, setAllSiteScans] = React.useState<ScanInfo[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<string>("");
  const [selectedPolygons, setSelectedPolygons] = React.useState<string[]>([]);

  const [comment, setComment] = React.useState<string>("");

  const [allFrequencies, setAllFrequencies] = React.useState<number[]>([]);
  const [selectedFrequencies, setSelectedFrequencies] = React.useState<number[]>([]);
  const [frequencyWindow, setFrequencyWindow] = React.useState<number>(0);
  const [frequencyWindowError, setFrequencyWindowError] = React.useState<string>("");
  const frequenciesRef = React.useRef<HTMLInputElement>();
  const [addFrequencyError, setAddFrequencyError] = React.useState<string>("");

  const [requestErrorText, setRequestErrorText] = React.useState<string | undefined>("No values are given");
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const selectedBucket = React.useMemo<string>(() => BUCKET, []);

  const selectedSite = React.useMemo<SiteInfo>(() => {
    if (selectedSites.length !== 1) {
      return { id: "", name: "", s3Ref: "", locationId: "", customerId: "" };
    }
    return selectedSites[0];
  }, [selectedSites]);

  const [, getSiteScansRefs] = useGetSiteScansRefsLazyQuery({ variables: { siteId: selectedSite.id } });

  React.useEffect(() => {
    async function load() {
      if (selectedSite.id === "") {
        return;
      }
      const scans = await parseScans(getSiteScansRefs);
      setAllSiteScans(scans);
    }
    load();
  }, [selectedSite, getSiteScansRefs]);

  const dateOptions = React.useMemo(() => {
    return [...new Set(allSiteScans.map((scan) => scan.date))].sort((a, b) => a.localeCompare(b));
  }, [allSiteScans]);

  const polygonOptions = React.useMemo(() => {
    const polygons = [
      ...new Set(
        allSiteScans
          .filter((scan) => scan.date === selectedDate && scan.bucket === selectedBucket)
          .map((scan) => scan.polygon)
      ),
    ].sort((a, b) => parseInt(a) - parseInt(b));
    return ["All", ...polygons];
  }, [allSiteScans, selectedDate, selectedBucket]);

  function addFrequencies() {
    const baseFrequencies = (frequenciesRef.current?.value ?? "")
      .split(/[,\s]/)
      .map((part) => part.trim())
      .filter((part) => part !== "")
      .map((part) => parseInt(part.trim()));

    const frequencies = baseFrequencies.filter((freq) => !Number.isNaN(freq));

    setAddFrequencyError(frequencies.length !== baseFrequencies.length ? "Illegal value" : "");
    setAllFrequencies((prevFrequencies) => [...new Set([...prevFrequencies, ...frequencies])]);
    setSelectedFrequencies((prevFrequencies) => [...new Set([...prevFrequencies, ...frequencies])]);
  }

  const request = React.useMemo<TriggerFrequenciesPostRequest>(() => {
    const missingElemenets = [];
    if (selectedBucket === "") {
      missingElemenets.push("Bucket");
    }
    if (selectedSite?.id === undefined || selectedSite.id === "") {
      missingElemenets.push("Site");
    }
    if (selectedDate === "") {
      missingElemenets.push("Date");
    }
    if (selectedPolygons.length === 0) {
      missingElemenets.push("Polygons");
    }
    if (selectedFrequencies.length === 0) {
      missingElemenets.push("Frequencies");
    }

    if (frequencyWindowError !== "") {
      missingElemenets.push("Frequency Search Window");
    }

    if (missingElemenets.length > 0) {
      setRequestErrorText(`Missing: ${missingElemenets.join(", ")}`);
    } else {
      setRequestErrorText(undefined);
    }
    const value: TriggerFrequenciesPostRequest = {
      scanBucket: selectedBucket,
      site: missingElemenets.includes("Site") ? "<missing>" : selectedSite.s3Ref,
      dates: missingElemenets.includes("Date") ? ["<missing>"] : [selectedDate],
      polygons: missingElemenets.includes("Polygons") ? [] : parsePolygons(selectedPolygons),
      frequencies: missingElemenets.includes("Frequencies") ? [] : selectedFrequencies,
      email: user?.account?.username ?? "unknown email",
      user: user?.account?.name ?? "unknown user",
      frequencyWindow: missingElemenets.includes("Frequency Search Window") ? 0 : frequencyWindow,
    };
    if (comment !== "") {
      value["comment"] = comment;
    }
    return value;
  }, [
    comment,
    frequencyWindow,
    frequencyWindowError,
    selectedBucket,
    selectedSite,
    selectedDate,
    selectedPolygons,
    selectedFrequencies,
    user,
  ]);

  function submit() {
    if (request === null || request === undefined) {
      return;
    }
    const localRequest = request;
    const api = new ServicesApi();
    setIsUploading(true);
    getServicesRequestParams()
      .then((requestParams) => api.frequencies.triggerFrequencies(localRequest, requestParams))
      .catch((e) => {
        console.log("Trigger action failed", e);
        window.alert("Trigger Failed. Inform R&D");
      })
      .finally(() => setIsUploading(false));
  }

  function handleToggle(value: number) {
    return () => {
      setSelectedFrequencies((oldFrequencies) => {
        const currentIndex = oldFrequencies.indexOf(value);
        const newChecked = [...oldFrequencies];

        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }

        return newChecked.sort((a, b) => a - b);
      });
    };
  }

  return (
    <>
      <Typography variant="h1">Trigger Frequencies</Typography>
      <PageCard>
        <Typography variant="subtitle1">Choose Targets</Typography>
        <Stack spacing={2}>
          <ChooseSite setSelectedSites={setSelectedSites} multiple={true} />
          <Stack spacing={2} direction="row">
            <Autocomplete
              options={dateOptions}
              getOptionLabel={(o) => o ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Date"
                  error={selectedDate === ""}
                  helperText={"Only a single date is allowed for now"}
                />
              )}
              sx={{ width: 200 }}
              value={selectedDate}
              onChange={(_e, date) => setSelectedDate(date ?? "")}
            />
            <Autocomplete
              options={polygonOptions}
              getOptionLabel={(o) => o ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Polygons"
                  error={getPolygonsHelperText(selectedPolygons) !== undefined}
                  helperText={getPolygonsHelperText(selectedPolygons)}
                />
              )}
              sx={{ width: 200 }}
              value={selectedPolygons}
              multiple
              onChange={(_e, polygons) => setSelectedPolygons(polygons ?? [])}
            />
          </Stack>
        </Stack>
      </PageCard>
      <PageCard>
        <Typography variant="subtitle1">Trigger Setting</Typography>
        <Stack spacing={2} direction="row">
          <TextField
            label="Frequency Search Window"
            helperText={frequencyWindowError === "" ? "Enter non-negative integer" : frequencyWindowError}
            sx={{ width: 300 }}
            value={frequencyWindow}
            error={frequencyWindowError !== ""}
            onChange={(ev) => {
              const value = parseInt(ev.target.value);
              if (isNaN(value) || value < 0) {
                setFrequencyWindowError(`Illegal: "${ev.target.value}" is not a non-negative int`);
              } else {
                setFrequencyWindow(value);
                setFrequencyWindowError("");
              }
            }}
          />
          <TextField
            label="Comment"
            helperText={"Add comment if wanted"}
            sx={{ width: 600 }}
            value={comment}
            onChange={(ev) => {
              setComment(ev.target.value);
            }}
          />
        </Stack>
      </PageCard>
      <PageCard>
        <Typography variant="subtitle1">Add Frequencies</Typography>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Frequencies"
              helperText={
                addFrequencyError === ""
                  ? "Enter frequencies separated by comma or spaces. Press `Enter` to add them to the list"
                  : addFrequencyError
              }
              sx={{ width: 600 }}
              inputRef={frequenciesRef}
              error={addFrequencyError !== ""}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  addFrequencies();
                }
              }}
            />
            <Button onClick={() => addFrequencies()}>Add</Button>
          </Stack>
        </Stack>
      </PageCard>
      <Stack direction="row" spacing={2}>
        <PageCard>
          <Stack spacing={2}>
            <Typography variant="subtitle1">Choose Frequencies</Typography>
            <List sx={{ width: "100%", maxWidth: 360, overflow: "auto", height: "100%", maxHeight: 400 }}>
              {allFrequencies
                .sort((a, b) => a - b)
                .map((value) => {
                  const labelId = `frequency-${value}`;
                  return (
                    <ListItem key={value} disablePadding>
                      <ListItemButton onClick={handleToggle(value)} dense>
                        <Checkbox
                          edge="start"
                          checked={selectedFrequencies.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                        <ListItemText id={labelId} primary={`${value} Hz`} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
            <Button
              onClick={() => {
                setSelectedFrequencies([]);
              }}
            >
              Clear Frequencies
            </Button>
            <Button
              onClick={() => {
                setAllFrequencies([]);
                setSelectedFrequencies([]);
              }}
            >
              Delete All Frequencies
            </Button>
          </Stack>
        </PageCard>

        <PageCard>
          <Stack spacing={2}>
            <Typography variant="subtitle1">Approve and Trigger</Typography>
            <TextField
              value={JSON.stringify(request, undefined, 2)}
              InputProps={{
                readOnly: true,
                style: { width: 600, overflow: "auto", height: "100%", maxHeight: 400 },
              }}
              label="Request"
              multiline={true}
              error={requestErrorText !== undefined}
              helperText={requestErrorText}
              rows={15}
            />
            <Button onClick={submit} disabled={requestErrorText !== undefined}>
              Trigger
            </Button>
            {isUploading ? <LoadingWheel /> : <></>}
          </Stack>
        </PageCard>
      </Stack>
    </>
  );
}

export default UploadPreloaderSupportData;
