import L, { TileLayerOptions } from "leaflet";
import { createLayerComponent } from "@react-leaflet/core";
import axios from "axios";

import { StatusCodes as HttpStatusCode } from "http-status-codes";

function transformUrl(originalUrl: string): string {

  const urlPattern = /\/tiles\/(\d+)\/(\d+)\/(\d+)\/png/;
  const match = originalUrl.match(urlPattern);

  if (match) {
    const [, z, x, y] = match;

    const tile_url = process.env.REACT_APP_BACKEND_TILE_HOST;
    if (!tile_url) {
      return originalUrl;
    }

    const transformedUrl = `${tile_url}/tiles_server_api_gateway_pipesqa-prod_resource?z=${z}&x=${x}&y=${y}`;

    return transformedUrl;
  } else {
    console.error("Original URL does not match the expected format.");
    return originalUrl;
  }
}


async function fetchTileImage(sourceUrl: string, authToken: string): Promise<string | undefined | null> {
  const cache = await caches.open('tile-cache');

  const url = transformUrl(sourceUrl);

  const cacheResponse = await cache.match(url);
  if (cacheResponse) {
    const blob = await cacheResponse.blob();
    return URL.createObjectURL(blob);
  }

  try {
    const response = await axios.get(url, { headers: { Authorization: authToken } });
    const tileResponse = await axios.get(response.data.url, { responseType: "blob" });
    const blobUrl = URL.createObjectURL(tileResponse.data);

    const blob = new Blob([tileResponse.data], { type: 'image/png' });
    await cache.put(url, new Response(blob));

    return blobUrl;

  } catch (error) {
    console.error("Error fetching tile:", error);
    throw error;
  }
}
class Authorized extends L.TileLayer {
  authToken: string;
  constructor(urlTemplate: string, authToken: string, options?: TileLayerOptions) {
    super(urlTemplate, options);
    this.authToken = authToken;
  }

  private setAuthToken(authToken: string) {
    this.authToken = authToken;
  }

  public static createAuthorizedLayer(props: any, context: any) {
    const instance = new Authorized(props.url, props.authToken, { ...props } as TileLayerOptions);
    return { instance, context };
  }

  public static updateAuthorizedLayer(instance: any, props: any, prevProps: any) {
    if (prevProps.authToken !== props.authToken) {
      if (instance.setAuthToken) {
        instance.setAuthToken(props.authToken);
      }
    }
    if (prevProps.opacity !== props.opacity) {
      if (instance.setOpacity) {
        instance.setOpacity(props.opacity);
      }
    }
    if (prevProps.zIndex !== props.zIndex) {
      if (instance.setZIndex) {
        instance.setZIndex(props.zIndex);
      }
    }
  }

  createTile(coords: L.Coords, done: (p1: any, p2: HTMLElement) => void) {
    const url = this.getTileUrl(coords);
    const img = document.createElement("img");
    fetchTileImage(url, this.authToken).then(blobUrl => {
      if (blobUrl === undefined || blobUrl === null) {
        return;
      }
      img.src = blobUrl;
      done(null, img);
      return img;
    }).catch(error => {
      if (error.response.status !== HttpStatusCode.NOT_FOUND) {
        Promise.reject(error);
      }
      Promise.resolve();
    });
    return img;
  }
}

export default createLayerComponent(Authorized.createAuthorizedLayer, Authorized.updateAuthorizedLayer);
