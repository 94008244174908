import { Button } from '@mui/material';
import * as React from 'react';
import Control from 'react-leaflet-custom-control';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import L, { ControlPosition, LeafletMouseEvent } from 'leaflet';
import { Polygon, Popup, Tooltip, useMap } from 'react-leaflet';
import { SelectedVisionToolsMode } from './MangeLayers/DrawTypeControl';

function MeasureControl({ setSelectedTool }: MeasureControlProps) {
  const [active, setActive] = React.useState(true);
  const [drawing, setDrawing] = React.useState(true);
  const [areaPolygon, setAreaPolygon] = React.useState<L.LatLng[]>([]);
  const [mouseOverPoint, setMouseOverPoint] = React.useState<L.LatLng>();
  const mapClickRef = React.useRef(mapClick);
  const mapMouseOverRef = React.useRef(mapMouseOver);

  const map = useMap();

  function mapClick(e: LeafletMouseEvent) {
    setAreaPolygon((currentPolygon) => [...currentPolygon, e.latlng]);
  }

  function mapMouseOver(e: LeafletMouseEvent) {
    setMouseOverPoint(e.latlng);
  }

  React.useEffect(() => {
    document.addEventListener('keydown', onEscPressed);
    // on component dismount
    return () => {
      document.removeEventListener('keydown', onEscPressed);
    };
  }, []);

  React.useEffect(() => {
    if (drawing) {
      map.addEventListener('click', mapClickRef.current);
      map.addEventListener('mousemove', mapMouseOverRef.current);
    } else {
      map.removeEventListener('click', mapClickRef.current);
      map.removeEventListener('mousemove', mapMouseOverRef.current);
    }
  }, [drawing]);

  React.useEffect(() => {
    if (!active) {
      setAreaPolygon([]);
    }
  }, [active]);

  function onEscPressed(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      setDrawing((prevState) => {
        if (prevState) {
          return !prevState;
        }
        setActive(false);
        return prevState;
      });

      setMouseOverPoint(undefined);
    }
  }

  function onControlClick() {
    if (!active) {
      setActive(true);
      setDrawing(true);
    } else if (!drawing) {
      setActive(false);
    } else {
      // If user clicked on the control while drawing remove the point added to the polygon
      setAreaPolygon((currentPolygon) => {
        if (currentPolygon.length === 0) {
          return currentPolygon;
        }
        const tempPolygon = [...currentPolygon];
        tempPolygon.pop();
        return tempPolygon;
      });
    }
  }

  function getPolygonPoints() {
    return mouseOverPoint ? [...areaPolygon, mouseOverPoint] : areaPolygon;
  }

  return (
    <>
      <Polygon positions={getPolygonPoints()} pathOptions={{ dashArray: [20, 20], color: 'red' }}>
        {areaPolygon.length > 0 && (
          <Tooltip position={mouseOverPoint} opacity={1} permanent>
            Polygon Area: <br />
            {L.GeometryUtil.geodesicArea(getPolygonPoints()).toLocaleString()} m²
            <br />
            {(L.GeometryUtil.geodesicArea(getPolygonPoints()) / 4_046.85642).toLocaleString()} acres
            <br />
            {(L.GeometryUtil.geodesicArea(getPolygonPoints()) / 1_000).toLocaleString()} dunams
          </Tooltip>
        )}
      </Polygon>
    </>
  );
}

export interface MeasureControlProps {
  // position: ControlPosition;
  setSelectedTool: (tool: SelectedVisionToolsMode) => void;
}

export default MeasureControl;
