import React from "react";
import {
  AddSite,
  Api,
  ClientDetails,
  Contact,
  PolygonFileType,
  SiteDetails,
  SiteType,
  StateDetails,
} from "../../Generated/ExoDBAPI";
import { getRequestParams } from "../../Utils/azureAuth";
import { readFileUrl } from "../../Utils/fileUtils";
import UserContext from "../Contexts/UserContext";
import { FileInputRef } from "../Inputs/FileInput";
import ClientInfoFormSection from "./ClientInfoFormSection";
import GeneralInfoFormSection from "./GeneralInfoFormSection";
import PolygonFormSection from "./PolygonFormSection";
import { validateSiteNameInputs } from "./SiteUtils";

function EditSiteForm({ site, loadedStates, loadedClients, siteTypes }: EditSiteFormProps) {
  const [states, setStates] = React.useState(loadedStates);
  const [freeTextLocation, setFreeTextLocation] = React.useState("");
  const [freeTextName, setFreeTextName] = React.useState("");
  const [siteType, setSiteType] = React.useState(site.siteType);
  const [stateId, setStateId] = React.useState<string>(site.stateId);

  const [clients, setClients] = React.useState(loadedClients);
  const [clientId, setClientId] = React.useState<string>(site.clientId);
  const [contactId, setContactId] = React.useState(site.contacts.length.toString());
  const [contacts, setContacts] = React.useState<Contact[]>(
    site.contacts.map((c, i) => {
      return { ...c, id: i.toString() };
    })
  );

  const name = React.useMemo(() => {
    if (freeTextName === "" || freeTextLocation === "" || clientId === undefined) {
      return "";
    }
    return `${clients.find((c) => c.id === clientId)?.abbreviation}_${freeTextLocation}_${freeTextName}`;
  }, [clientId, freeTextLocation, freeTextName]);

  const [clientContacts, setClientContacts] = React.useState<Contact[]>([]);

  const { user } = React.useContext(UserContext);

  const polygonFile = React.useRef<FileInputRef>(null);
  const submitRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    async function fetchData() {
      if (user !== undefined) {
        const api = new Api();
        api.states.getStates(await getRequestParams()).then((res) => {
          setStates(res.data.states);
        });
        api.clients.getClients(await getRequestParams()).then((res) => {
          const clients = res.data.clients;
          setClients(clients);
        });
      }
    }
    fetchData();
  }, [user]);

  React.useEffect(() => {
    async function fetchContacts() {
      setClientContacts([]);
      if (user !== undefined && clientId !== undefined) {
        const api = new Api();

        api.contacts
          .getContacts({ clientId: clientId }, await getRequestParams())
          .then((res) => setClientContacts(res.data.contacts));
      }
    }
    fetchContacts();
  }, [clientId]);

  /**
   * Gathers all the information from the form fields and sends a create site request to the backend
   * @param {*} e onSubmit event
   */
  async function editSite(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const submitButton = submitRef.current;

    if (submitButton === null) {
      return;
    }

    submitButton.classList.add("buttonClicked");
    submitButton.disabled = true;

    if (!validateSiteNameInputs(freeTextLocation, freeTextName)) {
      throw new Error("Invalid values in name inputs");
    }

    const requestBody: AddSite = {
      name,
      stateId,
      clientId,
      siteType,
      contacts,
    };
    const polygonFiles = [];
    if (polygonFile.current !== null) {
      const polygonFileData = polygonFile.current.getUploadedFileData();

      if (polygonFileData.length >= 1) {
        for (const file of polygonFileData) {
          const splits = file.fileName.split(".");
          const fileData = await readFileUrl(file.data);
          if (fileData === null) {
            throw Error("Polygon file data is null");
          }

          polygonFiles.push({
            content: fileData.split(";base64,")[1],
            type: splits[splits.length - 1] as PolygonFileType,
          });
        }

        requestBody.polygonFiles = polygonFiles;
      }
    }

    const api = new Api();
    api.sites
      .putSite(site.id, requestBody, await getRequestParams())
      .then(() => {
        window.alert("Edited site");
      })
      .catch((e) => {
        console.log("error", e);
        let errorMessage = "Unknown Error while editing site";
        if (e instanceof Error) {
          errorMessage = `Failed to edit sites. ${e.message}`;
        }
        window.alert(errorMessage);
      })
      .finally(() => {
        submitButton.classList.remove("buttonClicked");
        submitButton.disabled = false;
      });
  }

  /**
   * Updates the contacts state when creating a new contact
   */
  function handelCreateNewContact() {
    const tempId = contactId + 1;
    const newContacts = [...contacts, { name: "", jobTitle: "", phone: "", id: `${tempId}` }];
    setContactId(tempId);
    setContacts(newContacts);
  }

  /**
   * Updates the contact state when deleting a contact
   * @param {int} index index of contact to delete
   */
  function handelDeleteContact(index: number) {
    const tempContacts = [...contacts];
    tempContacts.splice(index, 1);
    setContacts(tempContacts);
  }

  function handelUpdateContact(contact: Contact) {
    const tempContacts = [...contacts];
    const index = tempContacts.findIndex((c) => c.id === contact.id);
    tempContacts[index] = contact;
    setContacts(tempContacts);
  }

  return (
    <form className="siteForm" autoComplete="off" onSubmit={editSite} method="post">
      <div className="formSection">
        <h3 className="fromSectionHeader">Site Information</h3>
        <GeneralInfoFormSection
          states={states}
          clients={clients}
          siteTypes={siteTypes}
          siteType={siteType}
          stateId={stateId}
          clientId={clientId}
          freeTextLocation={freeTextLocation}
          freeTextName={freeTextName}
          onFreeTextLocationChange={setFreeTextLocation}
          onFreeTextNameChange={setFreeTextName}
          onStateIdChange={setStateId}
          onSiteTypeChange={setSiteType}
          onClientIdChange={setClientId}
          evalErrors={false}
        />
      </div>
      <hr />
      <ClientInfoFormSection
        clients={clients}
        clientId={clientId}
        contacts={contacts}
        autoCompleteContacts={clientContacts}
        onAddContact={handelCreateNewContact}
        onRemoveContact={handelDeleteContact}
        onClientIdChange={setClientId}
        onUpdateContact={handelUpdateContact}
      />
      <hr />
      <PolygonFormSection polyFileRef={polygonFile} />
      <div className="formSection" key="submitButton">
        <input type="submit" value="Edit Site" className="formButton" ref={submitRef} />
      </div>
    </form>
  );
}

export interface EditSiteFormProps {
  site: SiteDetails;
  loadedStates: StateDetails[];
  loadedClients: ClientDetails[];
  siteTypes: SiteType[];
}

export default EditSiteForm;
