import React from "react";
import { useParams } from "react-router-dom";
import { MAXIMUM_CHUNK_SIZE, MAX_UPLOAD_CONCURRENCY } from "../../config/fileUploadConfig";
import { Api, FileType } from "../../Generated/ExoDBAPI";
import FileInput, { FileInputRef } from "../Inputs/FileInput";
import { uploadFileUsingPresignedUrl as uploadFileUsingPresignedUrl } from "../../Utils/fileUploadUtils";

function UploadScanDataPage() {
  const [fileType, setFileType] = React.useState(FileType.Seismic);
  const [date, setDate] = React.useState<string>("");
  const fileInputRef = React.useRef<FileInputRef>(null);
  const submitRef = React.useRef<HTMLInputElement>(null);
  const { siteId } = useParams();

  async function uploadFiles(e: React.FormEvent) {
    e.preventDefault();
    const submitButton = submitRef.current;

    if (submitButton === null || siteId === undefined) {
      return;
    }
    if (date === "") {
      window.alert("Date is empty");
      return;
    }
    try {
      submitButton.className = "buttonClicked";
      submitButton.disabled = true;
      const formattedDate = date.replaceAll("-", "_");

      const api = new Api();
      await uploadFileUsingPresignedUrl(
        fileInputRef,
        api,
        fileType,
        siteId,
        MAXIMUM_CHUNK_SIZE,
        MAX_UPLOAD_CONCURRENCY,
        formattedDate
      );
    } catch (e) {
      window.alert("Some files upload failed");
      console.log(e);
    } finally {
      submitButton.className = "formButton";
      submitButton.disabled = false;
    }
  }

  return (
    <div className="pageWrapper">
      <div className="infoContainer">
        <h2 className="pageHeader">Upload scan data</h2>
        <form onSubmit={(e) => uploadFiles(e)}>
          <div className="formSection">
            <span className="siteFormEntry">
              <label>Scan data type:</label>
              <select onChange={(e) => setFileType(e.target.value as FileType)}>
                <option value={FileType.Seismic}>Smart solo</option>
                <option value={FileType.GoPro}>GoPro</option>
                <option value={FileType.DronePhotos}>Drone pictures</option>
                <option value={FileType.ERT}>ERT</option>
                <option value={FileType.FDEM}>FDEM</option>
              </select>
            </span>
            <span className="siteFormEntry">
              <label>Scan date:</label>
              <input type="date" onChange={(e) => setDate(e.target.value)} />
            </span>
          </div>
          <div className="formSection">
            <span className="siteFormEntry">
              <label>Files:</label>
              <FileInput multipleFiles={true} ref={fileInputRef} key="BackgroundImageInput" directory={true} />
            </span>
            <span className="formSectionIsolated">
              <input type="submit" className="formButton" value="Upload" ref={submitRef} />
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UploadScanDataPage;
