import booleanDisjoint from "@turf/boolean-disjoint";
import { circle, point, polygon } from "@turf/turf";
import { getPointCoordinates } from "./geoJsonUtils";
import { getFeaturesGraphicConfig } from "../config/mapConfig";
import { entityType } from "../BasicTypes";
import { MapFeatures } from "../Components/ExoFuser/DataTypes/MapFeatures";

export function getTransmitterGeometry(entity: entityType) {
  const coords = getPointCoordinates(JSON.parse(entity.header.geoJson));
  return circle(point(coords), getFeaturesGraphicConfig().radius / 1000, undefined, "kilometers");
}

export function getGeoJsonGeometry(entity: entityType) {
  return JSON.parse(entity.header.geoJson);
}

export function doesIntersectFilter(geometry: any, filterPolygon: [number, number][]) {
  if (filterPolygon.length < 3) {
    return true;
  }
  const filteringPolygon = polygon([[...filterPolygon, filterPolygon[0]]]);
  return !booleanDisjoint(geometry, filteringPolygon);
}

export function filterMapFeatures(mapFeatures: MapFeatures, filterPolygon: [number, number][]): MapFeatures {
  return {
    pipes: mapFeatures.pipes.filter((e) => doesIntersectFilter(getGeoJsonGeometry(e), filterPolygon)),
    polygons: mapFeatures.polygons.filter((e) => doesIntersectFilter(getGeoJsonGeometry(e), filterPolygon)),
    transmitters: mapFeatures.transmitters.filter((e) => doesIntersectFilter(getTransmitterGeometry(e), filterPolygon)),
    travelCourses: mapFeatures.travelCourses.filter((e) => doesIntersectFilter(getGeoJsonGeometry(e), filterPolygon)),
    targets: mapFeatures.targets,
  };
}
