import * as React from "react";
import { Dict } from "../../../BasicTypes";
import Draggable from "react-draggable";
import { Box, Button, Table, TableCell, TableRow, Stack, Typography } from "@mui/material";

function LayerPropertiesModal({
  properties,
  coords,
  onClose,
  layerName,
  editable,
  onEditClick,
}: LayerPropertiesModalProps) {
  const boxRef = React.useRef<HTMLDivElement>(null);
  return (
    <Draggable>
      <Box
        sx={{
          backgroundColor: "white",
          zIndex: 1000,
          position: "fixed",
          cursor: "move",
          padding: 1,
          top: coords[1],
          left: coords[0] - 200,
          borderRadius: 2,
          border: "#e8e8e8 solid 1px",
          minWidth: "200px",
          maxHeight: 400,
          overflowY: "scroll",
        }}
        ref={boxRef}
      >
        <Stack gap="1rem">
          <div className="xButton" style={{ marginLeft: "auto", marginRight: "auto" }} onClick={onClose}>
            ✖
          </div>
          <Typography variant="h6" align="center" fontWeight="bold">
            {layerName}
          </Typography>
          {Object.keys(properties).length === 0 && (
            <Typography variant="body1" align="center">
              No Info about this layer
            </Typography>
          )}
          <Table sx={{ border: "#cccccc solid 1px" }}>
            {Object.keys(properties).map((prop) => (
              <TableRow>
                <TableCell>{prop}</TableCell>
                <TableCell>{new String(properties[prop])}</TableCell>
              </TableRow>
            ))}
          </Table>
          {editable && <Button onClick={onEditClick}>Edit</Button>}
        </Stack>
      </Box>
    </Draggable>
  );
}

export interface LayerPropertiesModalProps {
  properties: Dict<any>;
  coords: [number, number];
  layerName: string;
  onClose: () => void;
  editable?: boolean;
  onEditClick?: () => void;
}

export default LayerPropertiesModal;
