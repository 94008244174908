import axios from "axios";
import React from "react";
import { Dict } from "../../../BasicTypes";
import { AnalysisProductsSite, AnalysisProductsSource, Api, SiteDetails } from "../../../Generated/ExoDBAPI";

import {
  convertToAnalyzedEntities,
  convertEntitiesToTransmitters,
  convertEntitiesToTravelCourses,
  convertEntitiesToScanDetails,
  convertEntitiesToTargets,
  convertEntitiesToDrawDetails,
} from "./GraphqlConverters";
import { READERS } from "../../../Utils/geoJsonParser";
import {
  GetAnalyzedEntitiesBySiteIdQuery,
  MappingEntity,
  useGetAnalyzedEntitiesBySiteIdLazyQuery,
  useGetListMappingEntitiesLazyQuery,
  useGetSensorModelsLazyQuery,
  useGetSiteScansDetailsLazyQuery,
  useGetSiteTargetEntitiesLazyQuery,
  useGetSiteTransmittersLazyQuery,
  useGetSiteTravelCoursesLazyQuery,
} from "../../../Generated/Graphql";
import UserContext from "../../Contexts/UserContext";
import { LayerReporters, reportDrawnLayers } from "./getDrawnLayers";
import { getSourceNameForDrawnLayer } from "../Drawns/DrawnFileData";
import { getRequestParams } from "../../../Utils/azureAuth";
import { LOAD_SITE_LAYERS_QUERY_CONFIG } from "../ExoFuserConfig";
import { MapFeaturesStatus } from "../DataTypes/MapFeatures";
import { getAllPagesResults, reportAllPagesResults } from "../../../Utils/graphqlUtils";
import { DISPLAY_MAP_NAME } from "../SiteSelection/SitesSelectForm";
import { SensorInfo } from "../../Monitoring/MonitorTypes";
import { AnalysisProductsTravelCourse } from "../DataTypes/MapEntities";
import { ApolloError } from "@apollo/client/errors";

function getSuffix(path: string) {
  const index = path.lastIndexOf(".");
  if (index <= 0) {
    return "";
  }
  return path.slice(index + 1);
}

interface EntitiesResult {
  data: GetAnalyzedEntitiesBySiteIdQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

// TODO: Should split this component to one that get the map features and one that get the bounding polygons
function ExoFuserEntityGetter({
  graphqlSiteId,
  source,
  plannedSite,
  analysisSite,
  featureReporters,
  setExpectedGeoJsons,
  reportNewGeoJsons,
  reseters,
  layerReporters,
  refreshTrigger,
  setMappingEntity,
}: ExoFuserEntityGetterProps) {
  const sources = source?.map((s) => s.s3Source) ?? [];
  while (sources.length < 3) {
    sources.push(sources[0]);
  }
  const [_getEntities, entitiesResults] = useGetAnalyzedEntitiesBySiteIdLazyQuery({
    variables: {
      siteId: graphqlSiteId,
      dataSource1: sources[0],
      dataSource2: sources[1],
      dataSource3: sources[2],
      depthAlgorithm: "final",
      depthType: "crossing",
    },
  });

  const [_getTransmitters, transmittersResults] = useGetSiteTransmittersLazyQuery({
    variables: { siteId: graphqlSiteId },
  });
  const [_getTravelCourses, travelCoursesResults] = useGetSiteTravelCoursesLazyQuery({
    variables: { siteId: graphqlSiteId },
  });
  const [, scansResults] = useGetSiteScansDetailsLazyQuery({ variables: { siteId: graphqlSiteId ?? "" } });
  const [, sensorsResults] = useGetSensorModelsLazyQuery();
  const [_getTargets, targetEntitiesResults] = useGetSiteTargetEntitiesLazyQuery({
    variables: { siteId: graphqlSiteId },
  });
  const { user } = React.useContext(UserContext);
  let nextToken: string | null = null;
  const [, drawingResults] = useGetListMappingEntitiesLazyQuery({
    variables: { siteId: graphqlSiteId ?? "", nextToken },
  });

  React.useEffect(() => {
    reportAllPagesResults(
      sensorsResults,
      (res) => {
        const items = res.data.listSensors?.items;
        if (!items) {
          return;
        }
        const sensors = items.filter((item) => item !== null) as SensorInfo[];
        featureReporters.reportSensors(sensors.map((s) => s.model as string) as string[]);
      },
      (res) => res.data.listSensors?.nextToken
    );
  }, []);

  async function runFetchDataPromises(tasks: (() => Promise<void>)[]) {
    if (analysisSite === undefined || plannedSite === undefined) {
      return;
    }
    try {
      reseters.resetFeatures(analysisSite[0].name);
      await Promise.all(tasks.map((task) => task()));
      featureReporters.setDoneLoading(analysisSite[0].name, true);
    } catch (e) {
      if (e instanceof Error) {
        window.alert(`Got error ${e.message}`);
        throw e;
      } else {
        window.alert(`Got an unknown error while fetching features`);
      }
    }
  }

  React.useEffect(() => {
    async function loadDraws() {
      if (!graphqlSiteId) {
        return;
      }
      const drawings = await getAllPagesResults(
        drawingResults,
        (res) => convertEntitiesToDrawDetails(res.data),
        (res) => res.data.listMappingEntities?.nextToken
      );
      setMappingEntity(drawings);
    }

    loadDraws();
  }, [graphqlSiteId]);

  React.useEffect(() => {
    if (analysisSite === undefined) {
      return;
    }
    reseters.resetExpectedFeatures();
    // Fetch map features for site when the site changes
    if (analysisSite[0]?.name === DISPLAY_MAP_NAME) {
      runFetchDataPromises([loadTravelCourses, loadSitePolygons]);
    } else {
      runFetchDataPromises([
        loadUserLayers,
        loadAnalyzedEntities,
        loadTransmitters,
        loadTravelCourses,
        loadSitePolygons,
        loadScans,
        loadTargets,
      ]);
    }
  }, [graphqlSiteId, user, analysisSite]);

  React.useEffect(() => {
    reseters.resetExpectedFeatures();
    getExpectedFeaturesPaths();
  }, [analysisSite]);

  React.useEffect(() => {
    // Fetch map features for site when the site changes
    if (analysisSite && analysisSite[0]?.name === DISPLAY_MAP_NAME) {
      runFetchDataPromises([loadTravelCourses, loadSitePolygons, getExpectedFeaturesPaths]);
    } else {
      runFetchDataPromises([loadAnalyzedEntities, loadTransmitters, loadTravelCourses, getExpectedFeaturesPaths]);
    }
  }, [refreshTrigger]);

  async function loadUserLayers() {
    const api = new Api();
    if (!analysisSite || analysisSite[0]?.name === undefined || user === undefined) {
      return;
    }
    if (plannedSite === undefined) {
      window.alert(`Analysis site ${analysisSite[0].name} was asked without planned site`);
      return;
    }

    async function startLayersQueries(actualPlannedSite: SiteDetails) {
      if (user === undefined || source === undefined || source.length === 0) {
        return;
      }
      const sourceName = getSourceNameForDrawnLayer(source[0].name);
      const layersIds = await api.sites.getUserFeatureLayersIds(
        actualPlannedSite.id,
        { source: sourceName },
        await getRequestParams()
      );

      reseters.resetLayers(actualPlannedSite.id);
      layerReporters.setExpectedLayersCount(layersIds.data.ids.length);
      reportDrawnLayers({
        plannedSite: actualPlannedSite,
        ids: layersIds.data.ids,
        source: layersIds.data.source,
        reporters: layerReporters,
        queryConfig: LOAD_SITE_LAYERS_QUERY_CONFIG,
      });
    }

    startLayersQueries(plannedSite);
  }

  async function loadSitePolygons() {
    const api = new Api();
    if (plannedSite === undefined) {
      window.alert(
        `Analysis site ${
          analysisSite && analysisSite.length > 0 && analysisSite[0].name
        } was asked without planned site`
      );
      return;
    }
    const res = await api.sites.getPolygonUrls(plannedSite.id, await getRequestParams());

    setExpectedGeoJsons(-1);
    reseters.resetGeoJsons();
    const polygonFilesData = res.data.polygonsUrls.map(({ url, path }) => {
      return { type: getSuffix(path), url: url };
    });

    setExpectedGeoJsons(polygonFilesData.length);

    for (const polygonFileData of polygonFilesData) {
      let config = {};
      if (polygonFileData.type === "kmz") {
        config = { responseType: "arraybuffer" };
      }
      axios.get(polygonFileData.url, config).then(async (fileData) => {
        const parsedJsons = await READERS[polygonFileData.type](fileData.data);
        reportNewGeoJsons(parsedJsons);
      });
    }
  }

  async function getExpectedFeaturesPaths() {
    const api = new Api();
    if (analysisSite === undefined) {
      console.log("Can't get expected feature because analysis site was undefined");
      return;
    }

    const featuresIdsPromises = analysisSite.map(async (site) =>
      api.analysis.getAnalysisFeaturesIds(site.s3Prefix, { source: site.s3Source }, await getRequestParams())
    );

    const featuresIdsResponses = await Promise.all(featuresIdsPromises);
    const allFeaturesIds = featuresIdsResponses.flatMap((response) => response.data.ids);
    featureReporters.setExpectedFeatures(allFeaturesIds);
  }

  async function loadAnalyzedEntities() {
    if (!analysisSite || analysisSite[0]?.name === undefined) {
      return;
    }

    await reportAllPagesResults(
      entitiesResults,
      (queryRes) => {
        const [pipes, polygons] = convertToAnalyzedEntities(queryRes.data);
        featureReporters.reportNewFeatures(
          new MapFeaturesStatus({
            doneLoading: false,
            features: {
              pipes: pipes,
              transmitters: [],
              travelCourses: [],
              polygons: polygons,
              targets: [],
            },
            currentAnalysisSiteName: analysisSite[0].name,
            failedFeatures: new Set(),
            scans: [],
          })
        );
      },
      (queryRes) => queryRes.data.searchAnalyzedEntities?.nextToken
    );
  }

  async function loadTransmitters() {
    if (!analysisSite || analysisSite[0]?.name === undefined) {
      return;
    }
    const transmitters = await getAllPagesResults(
      transmittersResults,
      (res) => convertEntitiesToTransmitters(res.data),
      (res) => res.data.searchTransmitterSetups?.nextToken
    );

    featureReporters.reportNewFeatures(
      new MapFeaturesStatus({
        doneLoading: false,
        features: {
          pipes: [],
          transmitters: transmitters,
          travelCourses: [],
          polygons: [],
          targets: [],
        },
        currentAnalysisSiteName: analysisSite[0].name,
        failedFeatures: new Set(),
        scans: [],
      })
    );
  }

  async function loadTravelCourses() {
    if (!analysisSite || analysisSite[0]?.name === undefined) {
      return;
    }
    const travelCourses = await getAllPagesResults(
      travelCoursesResults,
      (res) => convertEntitiesToTravelCourses(res.data),
      (res) => res.data.searchTravelCourses?.nextToken
    );
    const travelCoursesByScan: Dict<AnalysisProductsTravelCourse[]> = {};
    for (const travelCourse of travelCourses) {
      const scanId = travelCourse.header.scan.id ?? "No_scan";
      if (travelCoursesByScan[scanId] === undefined) {
        travelCoursesByScan[scanId] = [];
      }
      travelCoursesByScan[scanId].push(travelCourse);
    }
    const filteredEntities = Object.keys(travelCoursesByScan).map((id) => {
      const courses = travelCoursesByScan[id];
      return courses.find((t) => t.tag === "raw") ?? courses[0];
    });

    featureReporters.reportNewFeatures(
      new MapFeaturesStatus({
        doneLoading: false,
        features: {
          pipes: [],
          transmitters: [],
          travelCourses: filteredEntities,
          polygons: [],
          targets: [],
        },
        currentAnalysisSiteName: analysisSite[0].name,
        failedFeatures: new Set(),
        scans: [],
      })
    );
  }

  async function loadTargets() {
    if (!analysisSite || analysisSite[0]?.name === undefined) {
      return;
    }
    const targets = await getAllPagesResults(
      targetEntitiesResults,
      (res) => convertEntitiesToTargets(res.data),
      (res) => res.data.searchTargetEntities?.nextToken
    );

    featureReporters.reportNewFeatures(
      new MapFeaturesStatus({
        doneLoading: false,
        features: {
          pipes: [],
          transmitters: [],
          travelCourses: [],
          polygons: [],
          targets: targets,
        },
        currentAnalysisSiteName: analysisSite[0].name,
        failedFeatures: new Set(),
        scans: [],
      })
    );
  }

  async function loadScans() {
    if (!analysisSite || analysisSite[0]?.name === undefined) {
      return;
    }
    const scans = await getAllPagesResults(
      scansResults,
      (res) => convertEntitiesToScanDetails(res.data),
      (res) => res.data.getSite?.scans?.nextToken
    );

    featureReporters.reportNewFeatures(
      new MapFeaturesStatus({
        doneLoading: false,
        features: {
          pipes: [],
          transmitters: [],
          travelCourses: [],
          polygons: [],
          targets: [],
        },
        currentAnalysisSiteName: analysisSite[0].name,
        failedFeatures: new Set(),
        scans: scans,
      })
    );
  }

  return <></>;
}

export interface Reseters {
  resetFeatures: (siteName: string) => void;
  resetLayers: (plannedSiteId: string) => void;
  resetGeoJsons: () => void;
  resetExpectedFeatures: () => void;
}

// Should split this interface to smaller interfaces
export interface ExoFuserEntityGetterProps {
  // Site Info
  graphqlSiteId?: string;
  source?: AnalysisProductsSource[];
  plannedSite?: SiteDetails;
  analysisSite?: AnalysisProductsSite[];
  // Report changes functions
  featureReporters: FeatureReporters;
  reportNewGeoJsons: (j: any[]) => void;
  // Reset metrics
  reseters: Reseters;
  // Set expected results
  setExpectedGeoJsons: (n: number) => void;
  layerReporters: LayerReporters;
  // Refresh
  refreshTrigger: boolean;
  setMappingEntity: (m: MappingEntity[]) => void;
}

export interface FeatureReporters {
  reportNewFeatures: (m: MapFeaturesStatus) => void;
  reportNewErrors: (featureIds: string[]) => void;
  setDoneLoading: (siteName: string, state: boolean) => void;
  setEntityValid: (site: AnalysisProductsSite, featureId: string, s3Ref: string, isValid: boolean) => void;
  setExpectedFeatures: (ids: string[]) => void;
  reportSensors: (sensors: string[]) => void;
}

export default ExoFuserEntityGetter;
