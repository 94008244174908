import React from "react";
import { useParams } from "react-router-dom";
import UserContext from "../Contexts/UserContext";
import LoadingWheel from "../LoadingWheel";
import UploadBackgroundImageForm from "../uploadBackgroundImageComponents/UploadBackgroundImageForm";
import { Api, SiteDetails } from "../../Generated/ExoDBAPI";
import { getBearerToken } from "../../Utils/azureAuth";

function UploadBackgroundImagePage() {
  const [site, setSite] = React.useState<SiteDetails>();
  const { user } = React.useContext(UserContext);
  let { siteId } = useParams();

  React.useEffect(() => {
    async function fetchData() {
      if (user !== undefined && siteId !== undefined) {
        const api = new Api();
        try {
          const site = (
            await api.sites.getSiteById(siteId, {
              baseUrl: process.env.REACT_APP_BACKEND_HOST,
              headers: { authorization: await getBearerToken() },
            })
          ).data;
          setSite(site);
        } catch (e) {
          let errorMessage = "Unknown Error in load site";
          if (e instanceof Error) {
            errorMessage = `Failed to load site. ${e.message}`;
          }
          window.alert(errorMessage);
          throw e;
        }
      }
    }
    fetchData();
  }, [user, siteId]);

  function getPageBody() {
    if (site === undefined) {
      return <LoadingWheel />;
    }
    if (siteId === undefined) {
      console.log("site id was undefined");
      return;
    }
    return (
      <div className="infoContainer">
        <h2 className="pageHeader">Upload background image - {site.name}</h2>
        <UploadBackgroundImageForm siteId={siteId} />
      </div>
    );
  }

  return <div className="pageWrapper">{getPageBody()}</div>;
}

export default UploadBackgroundImagePage;
