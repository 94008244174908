import { Api, FileType } from "../Generated/ExoDBAPI";
import PreSignedUrlFileUploader from "./fileUploader";
import PreSignedUrlMultipartFileUploader from "./multipartFileUploader";

class fileUploaderFactory {
  siteId: string;
  backendClient: Api<null>;
  maxChunkSize: number;
  requestParams: any;
  constructor(siteId: string, backendClient: Api<null>, maxChunkSize: number, requestParams: any) {
    this.siteId = siteId;
    this.backendClient = backendClient;
    this.maxChunkSize = maxChunkSize;
    this.requestParams = requestParams;
  }

  create(fileData: File, fileName: string, fileType: FileType, date?: string) {
    if (fileData.size < this.maxChunkSize) {
      return new PreSignedUrlFileUploader(
        fileData,
        fileName,
        fileType,
        this.siteId,
        this.backendClient,
        this.requestParams,
        date
      );
    }

    return new PreSignedUrlMultipartFileUploader(
      fileData,
      fileName,
      fileType,
      this.siteId,
      this.backendClient,
      this.requestParams,
      date
    );
  }
}

export interface FileUploader {
  uploadFile: (onProgress?: (e: number) => void) => Promise<void>;
}

export default fileUploaderFactory;
