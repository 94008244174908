import * as React from "react";
import { Api, SiteDetails } from "../../../../Generated/ExoDBAPI";
import { getRequestParams } from "../../../../Utils/azureAuth";
import { FileData, FileInputRef } from "../../../Inputs/FileInput";
import UploadFile from "./UploadFile";
import { readFileUrl } from "../../../../Utils/fileUtils";

function UploadExoSpray({ plannedSite }: UploadExoSprayProps) {
  async function uploadShapefileCallback(
    site: SiteDetails,
    layerName: string,
    fileData: FileData,
    fileInput: FileInputRef,
    multiSite?: boolean
  ) {
    const api = new Api();
    const fileBase64 = await readFileUrl(fileData.data);
    if (fileBase64 === null) {
      window.alert("Unable to read shapefile");
      return;
    }
    await api.analysis.postAnalysisExodaqLayers(
      {
        name: layerName,
        data: fileBase64.split("base64,")[1],
        site: site.name,
        layerType: "shapefile",
        // multiSite: multiSite,
      },
      await getRequestParams()
    );
  }

  return (
    <UploadFile
      plannedSite={plannedSite}
      accept=".zip"
      percentages={false}
      uploadCallback={uploadShapefileCallback}
      defaultName="ExoSpray"
      disableName={true}
      allowMultiSite={true}
    />
  );
}

export interface UploadExoSprayProps {
  plannedSite?: SiteDetails;
}

export default UploadExoSpray;
