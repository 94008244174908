import * as React from "react";
import { ClientDetails } from "../../Generated/ExoDBAPI";
import ListDisplay from "../Templates/ListDisplay";
import AddClientForm from "./AddClientForm";

function ClientAdminForm({ clients }: ClientAdminFormProps) {
  return (
    <div className="adminAddObjContainer">
      <AddClientForm />
      <span>
        <h3 className="pageHeader">Clients</h3>
        <ListDisplay items={clients.map((c) => c.name)} containerStyle={{ height: "200px" }} />
      </span>
    </div>
  );
}

interface ClientAdminFormProps {
  clients: ClientDetails[];
}

export default ClientAdminForm;
