import { ApolloQueryResult, QueryResult } from "@apollo/client";
import {
  Exact,
  GetPipelinesQuery,
  GetPreloadRunsQuery,
  InputMaybe,
  SearchScansMonitorInfoQuery,
} from "../../Generated/Graphql";
import { PipelineRunInfo, PreloadRunInfo, ScanMonitorInfo } from "./MonitorTypes";
import { reportAllPagesResults } from "../../Utils/graphqlUtils";
import { groupBy } from "../../Utils/groupBy";

interface GraphqlBaseStep {
  start_time?: number;
  end_time?: number;
  status: string;
  error_message?: string | null;
}

function buildBasicStep(graphqlStep?: GraphqlBaseStep) {
  return {
    status: graphqlStep?.status,
    startTime: graphqlStep?.start_time !== undefined ? graphqlStep.start_time * 1000 : undefined,
    endTime: graphqlStep?.end_time !== undefined ? graphqlStep.end_time * 1000 : undefined,
    errorMessage: graphqlStep?.error_message ?? undefined,
  };
}

export async function buildMonitorInfo(
  res: ApolloQueryResult<SearchScansMonitorInfoQuery>,
  getPreloadRunsQuery: QueryResult<
    GetPreloadRunsQuery,
    Exact<{
      nextToken?: InputMaybe<string> | undefined;
      stepId: string;
    }>
  >,
  getPipelinesQuery: QueryResult<
    GetPipelinesQuery,
    Exact<{
      nextToken?: InputMaybe<string> | undefined;
      stepId: string;
    }>
  >
): Promise<ScanMonitorInfo[]> {
  const items = res.data.searchScans?.items;
  if (items === null || items === undefined) {
    return [];
  }
  const monitorInfo = items
    .map(async (item) =>
      item === null
        ? null
        : !item.monitoring_steps
        ? createDefaultScanMonitorInfo(item.id, item.name ?? "")
        : ({
            id: item.id,
            name: item.name,
            sensor: item.sensor.model,
            scanType: item.scan_type,
            valid: item.is_valid,
            machineId: item.machine_id,
            monitoringInfo: {
              scanStep: {
                ...buildBasicStep(item.monitoring_steps?.scan_step),
                status: item.is_valid === false ? "FAILED" : "DONE",
              },
              uploadStep: {
                ...buildBasicStep(item.monitoring_steps?.upload_step),
                s3Ref: item.monitoring_steps.upload_step.s3_ref,
                updatedAt: new Date(item.monitoring_steps.upload_step.updatedAt).getTime(),
                uploader: item.monitoring_steps.upload_step.uploader,
                uploadSpeed: item.monitoring_steps.upload_step.upload_speed,
              },
              analysisStep: {
                status: item.monitoring_steps?.analysis_step.status,
                accessTime: item.monitoring_steps?.analysis_step.access_time * 1000,
                errorMessage: item.monitoring_steps?.analysis_step.error_message,
              },
              preloadStep: {
                ...buildBasicStep(item.monitoring_steps?.preloader_step),
                runs: await parsePreloaderRuns(item.monitoring_steps.preloader_step.id, getPreloadRunsQuery),
              },
              utilityFinderStep: {
                ...buildBasicStep(item.monitoring_steps?.utility_finder_step),
                pipelines: await parsePipelines(item.monitoring_steps.utility_finder_step.id, getPipelinesQuery),
              },
            },
          } as ScanMonitorInfo)
    )
    .filter((info) => info !== null);

  const parsedInfo = (await Promise.all(monitorInfo)) as ScanMonitorInfo[];

  for (const info of parsedInfo) {
    const preloadRuns = info.monitoringInfo.preloadStep.runs;
    const pipelines = info.monitoringInfo.utilityFinderStep.pipelines;
    const groupedPipelines = groupBy(pipelines, (pipeline) => `${pipeline.eplsS3Ref}#${pipeline.name}`);
    const updatedPipelines = Object.values(groupedPipelines).map((pipelines) =>
      pipelines
        .sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt))
        .reduce((prev, curr) => ({ ...prev, ...curr }))
    );

    const groupedPreloadRuns = groupBy(preloadRuns, (run) => run.targetName);
    const updatedPreloadRuns = Object.values(groupedPreloadRuns).map((runs) =>
      runs
        .sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt))
        .reduce((prev, curr) => ({ ...prev, ...curr }))
    );

    const aggPreloadInfo = calculateAggregatedFromRuns(updatedPreloadRuns);
    const aggPipelinesInfo = calculateAggregatedFromRuns(updatedPipelines);

    info.monitoringInfo.utilityFinderStep.pipelines = updatedPipelines;
    info.monitoringInfo.preloadStep.runs = updatedPreloadRuns;

    info.monitoringInfo.preloadStep.startTime = aggPreloadInfo.startTime;
    info.monitoringInfo.preloadStep.endTime = aggPreloadInfo.endTime;
    info.monitoringInfo.preloadStep.status = aggPreloadInfo.status;

    info.monitoringInfo.utilityFinderStep.startTime = aggPipelinesInfo.startTime;
    info.monitoringInfo.utilityFinderStep.endTime = aggPipelinesInfo.endTime;
    info.monitoringInfo.utilityFinderStep.status = aggPipelinesInfo.status;
  }

  return parsedInfo;
}

async function parsePreloaderRuns(
  stepId: string,
  getPreloadRunsQuery: QueryResult<
    GetPreloadRunsQuery,
    Exact<{
      nextToken?: InputMaybe<string> | undefined;
      stepId: string;
    }>
  >
) {
  const preloadRuns: PreloadRunInfo[] = [];
  await reportAllPagesResults(
    getPreloadRunsQuery,
    (res) => {
      const fetchedRuns = res.data.getPreloaderStep?.preload_runs?.items;
      if (!fetchedRuns) {
        return;
      }
      const parsedRuns = fetchedRuns
        .map((run) =>
          run === null
            ? null
            : {
                ...buildBasicStep(run),
                target: run.target_type,
                targetName: run.target_name,
                preloadedS3Refs: run.preloaded_scan_s3_refs,
                id: run.id,
                products: run.products,
                updatedAt: run.updatedAt,
              }
        )
        .filter((run) => run !== null) as PreloadRunInfo[];
      preloadRuns.push(...parsedRuns);
    },
    (res) => res.data.getPreloaderStep?.preload_runs?.nextToken,
    { stepId: stepId }
  );

  return preloadRuns;
}

function calculateAggregatedFromRuns(runs: RunsData[]) {
  let startTime = undefined;
  let endTime: number | undefined = Number.POSITIVE_INFINITY;
  let status = "RUNNING";
  const runsStatuses = new Set<string>();
  for (const run of runs) {
    if (startTime === undefined || (run.startTime !== undefined && run.startTime < startTime)) {
      startTime = run.startTime;
    }
    if (Number.isFinite(endTime) || run.endTime === undefined || (endTime !== undefined && run.endTime > endTime)) {
      endTime = run.endTime;
    }
    runsStatuses.add(run.status);
  }
  status = calculateAggregatedStatus(runsStatuses);

  return {
    startTime,
    endTime,
    status,
  };
}

export function calculateAggregatedStatus(runsStatuses: Set<string>) {
  let status = "RUNNING";
  if (runsStatuses.has("FAILED")) {
    status = "FAILED";
  } else if (runsStatuses.has("DONE") && runsStatuses.size === 1) {
    status = "DONE";
  } else if (
    (runsStatuses.has("SCHEDULED") && runsStatuses.size === 1) ||
    (runsStatuses.has("SCHEDULED") && runsStatuses.has("PENDING") && runsStatuses.size === 2)
  ) {
    status = "SCHEDULED";
  } else if (runsStatuses.size === 0) {
    status = "PENDING";
  } else if (
    (runsStatuses.has("DONE") && runsStatuses.has("SKIPPED") && runsStatuses.size === 2) ||
    (runsStatuses.has("SKIPPED") && runsStatuses.size === 1)
  ) {
    status = "SKIPPED";
  }
  return status;
}

async function parsePipelines(
  stepId: string,
  pipelinesQuery: QueryResult<
    GetPipelinesQuery,
    Exact<{
      nextToken?: InputMaybe<string> | undefined;
      stepId: string;
    }>
  >
) {
  const pipelines: PipelineRunInfo[] = [];
  await reportAllPagesResults(
    pipelinesQuery,
    (res) => {
      const fetchedRuns = res.data.getUtilityFinderStep?.pipelines?.items;
      if (!fetchedRuns) {
        return;
      }
      const parsedRuns = fetchedRuns
        .map((pipeline) =>
          pipeline === null
            ? null
            : {
                ...buildBasicStep(pipeline),
                name: pipeline.name,
                eplsS3Ref: pipeline.epls_s3_ref,
                updatedAt: pipeline.updatedAt,
              }
        )
        .filter((run) => run !== null) as PipelineRunInfo[];
      pipelines.push(...parsedRuns);
    },
    (res) => res.data.getUtilityFinderStep?.pipelines?.nextToken,
    { stepId: stepId }
  );
  return pipelines;
}

export function createDefaultScanMonitorInfo(id: string, name: string): ScanMonitorInfo {
  return {
    id: id,
    name: name,
    sensor: "",
    scanType: "TEST",
    machineId: undefined,
    monitoringInfo: {
      scanStep: {
        status: "SCHEDULED",
      },
      uploadStep: {
        status: "SCHEDULED",
        updatedAt: 0,
      },
      analysisStep: {
        status: "SCHEDULED",
      },
      preloadStep: {
        status: "SCHEDULED",
        runs: [],
      },
      utilityFinderStep: {
        status: "SCHEDULED",
        pipelines: [],
      },
    },
  };
}

interface RunsData {
  startTime?: number;
  endTime?: number;
  status: string;
}
