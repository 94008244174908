import { UNDEFINED_STR_VALUE } from "../../../Utils/filterUtils";
import { entityType } from "../../../BasicTypes";
import { getDateStr } from "../../../Utils/dateUtils";

import {
  FILTER_BOOL_CATEGORY_OPTIONS,
  SinglePropFilterCategoryFactory,
  BoolCategoryValues,
  createCompareOptions,
} from "./BaseFilterCategory";
import { ScanDetails } from "../DataTypes/MapEntities";
import { UNKNOWN_PLATFORM_TYPE } from "../ScansTab/PlatformType";

export function getFilterDateStr(value: number | string | undefined): string {
  return getDateStr(value, UNDEFINED_STR_VALUE);
}

export function getPolygonString(scan: ScanDetails) {
  return `P-${scan.polygon} S-${scan.snake} Part-${scan.part}`;
}

export class DateFilterCategoryFactory<T extends entityType> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return "Date";
  }

  getProp(entity: T): string {
    return getFilterDateStr(entity.header.scan.date);
  }
}

export class FrequencyFilterCategoryFactory<T extends entityType> extends SinglePropFilterCategoryFactory<T> {
  // Technically travel courses don't have frequency, but it shouldn't be to bad.

  get filterName(): string {
    return "Frequency";
  }

  getProp(entity: T): string {
    if (!("frequency" in entity) || entity.frequency === undefined) {
      return UNDEFINED_STR_VALUE;
    }

    return entity.frequency.toString();
  }
}

export class UserFilterCategoryFactory<T extends entityType> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return "User";
  }

  getProp(entity: T): string {
    if (entity.header.user === undefined) {
      return UNDEFINED_STR_VALUE;
    }
    return entity.header.user;
  }
}

export class SensorFilterCategoryFactory<T extends entityType> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return "Sensor";
  }

  getProp(entity: T): string {
    return entity.header.scan.sensor;
  }
}

export class PlatformTypeFilterCategoryFactory<T extends entityType> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return "Platform Type";
  }

  getProp(entity: T): string {
    return entity.header.scan.mount_method ?? UNKNOWN_PLATFORM_TYPE;
  }
}

export class ScanFilterCategoryFactory<T extends entityType> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return "Scan";
  }

  getProp(entity: T): string {
    return getPolygonString(entity.header.scan);
  }
}

export class CreationDateFilterCategoryFactory<T extends entityType> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return "Creation Date";
  }

  getProp(entity: T): string {
    if (entity.header.creationTimestamp === undefined) {
      return UNDEFINED_STR_VALUE;
    }
    return getFilterDateStr(entity.header.creationTimestamp);
  }
}

export class SnakeCategoryFactory<T extends entityType> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return "Snake";
  }

  getProp(entity: T): string {
    if (entity.header.scan.snake === undefined) {
      return UNDEFINED_STR_VALUE;
    }
    return entity.header.scan.snake.toString();
  }
}

export class InvalidScanFilterCategoryFactory<T extends entityType> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return "Valid Scan";
  }

  get compareOptions(): (a: string, b: string) => number {
    return createCompareOptions(FILTER_BOOL_CATEGORY_OPTIONS);
  }

  getProp(entity: T): string {
    if (entity.header.scan.isValid) {
      return BoolCategoryValues.YES;
    }
    return BoolCategoryValues.NO;
  }
}
