import { FileData, FileInputRef } from "../Components/Inputs/FileInput";
import { Api, FileType } from "../Generated/ExoDBAPI";
import CallbackPresignedURLUploader from "../MultipartUpload/callBackPresingedURLUploader";
import fileUploaderFactory from "../MultipartUpload/fileUploaderFactory";
import { UploadPart, UrlResponseData } from "../MultipartUpload/genericPresingedURLUploader";
import { getRequestParams } from "./azureAuth";

export function createUploadTasksData(
  inputRef: React.RefObject<FileInputRef>,
  fileType: FileType,
  date?: string
): TaskData[] {
  if (inputRef.current === null) {
    throw new Error("Got Malformed file input ref");
  }
  return inputRef.current.getUploadedFileData().map((f) => {
    return {
      file: f,
      fileType: fileType,
      date: date,
      onUploadFinished: (status: boolean) => {
        if (inputRef.current !== null) {
          inputRef.current.changeFileStatus(f.id, status);
        } else {
          throw new Error("Got a malformed input ref");
        }
      },
      onProgress: (prog: number) => {
        if (inputRef.current !== null) {
          inputRef.current.updateProgress(f.id, prog);
        } else {
          throw new Error("Got a malformed input ref");
        }
      },
    };
  });
}

export function createUploadFunctionAndCleanupCallbacksFromUploadTaskData(
  uploadTaskData: TaskData,
  getUrlsCallback: () => Promise<UrlResponseData>,
  endMultipartUploadCallBack: (uploadId: string, parts: UploadPart[]) => Promise<void>
) {
  const uploader = new CallbackPresignedURLUploader(
    uploadTaskData.file.data,
    getUrlsCallback,
    endMultipartUploadCallBack
  );

  return uploader.createCallbacks(uploadTaskData.onProgress, uploadTaskData.onUploadFinished);
}

export async function createUploadFunctionAndCleanupCallbacksFromFileInput(
  inputRef: React.RefObject<FileInputRef>,
  fileType: FileType,
  getUrlsCallback: (taskData: TaskData) => Promise<UrlResponseData>,
  endMultipartUploadCallBack: (uploadId: string, parts: UploadPart[], taskData: TaskData) => Promise<void>,
  date?: string
) {
  const tasksData = createUploadTasksData(inputRef, fileType, date);
  const uploadTasks = [];
  const cleanupTasks = [];
  for (const taskData of tasksData) {
    const { uploadCallbacks, cleanupCallback } = await createUploadFunctionAndCleanupCallbacksFromUploadTaskData(
      taskData,
      () => getUrlsCallback(taskData),
      (uploadId: string, parts: UploadPart[]) => endMultipartUploadCallBack(uploadId, parts, taskData)
    );
    uploadTasks.push(...uploadCallbacks);
    if (cleanupCallback !== undefined) {
      cleanupTasks.push(cleanupCallback);
    }
  }

  return {
    uploadTasks,
    cleanupTasks,
  };
}

export async function uploadAndCleanupFromFileInput(
  inputRef: React.RefObject<FileInputRef>,
  fileType: FileType,
  getUrlsCallback: (taskData: TaskData) => Promise<UrlResponseData>,
  endMultipartUploadCallBack: (uploadId: string, parts: UploadPart[], taskData: TaskData) => Promise<void>,
  concurrency: number,
  date?: string
) {
  const { uploadTasks, cleanupTasks } = await createUploadFunctionAndCleanupCallbacksFromFileInput(
    inputRef,
    fileType,
    getUrlsCallback,
    endMultipartUploadCallBack,
    date
  );

  await getFileUploadAndCleanupTasksPromise(uploadTasks, cleanupTasks, concurrency);
}

export function createUploadTasksFromUploadData(uploadTaskData: TaskData, fileUploadFactory: fileUploaderFactory) {
  return async () => {
    const uploader = fileUploadFactory.create(
      uploadTaskData.file.data,
      uploadTaskData.file.fileName,
      uploadTaskData.fileType,
      uploadTaskData.date
    );
    try {
      await uploader.uploadFile(uploadTaskData.onProgress);
      uploadTaskData.onUploadFinished(true);
    } catch (e) {
      uploadTaskData.onUploadFinished(false);
      throw e;
    }
  };
}

export function createUploadTasksForInput(
  inputRef: React.RefObject<FileInputRef>,
  fileType: FileType,
  fileUploadFactory: fileUploaderFactory,
  date?: string
) {
  const uploadTaskData = createUploadTasksData(inputRef, fileType, date);

  return uploadTaskData.map((d) => createUploadTasksFromUploadData(d, fileUploadFactory));
}

export async function preformTasksWithMaxConcurrency(tasks: (() => Promise<void>)[], concurrency: number) {
  const splittedTasks = [];

  for (let i = 0; i < concurrency; i++) {
    splittedTasks.push(async () => {
      let error = false;
      while (tasks.length > 0) {
        try {
          const task = tasks.pop();
          if (task !== undefined) {
            await task();
          }
        } catch (e) {
          error = true;
        }
      }
      if (error) {
        throw new Error(`Got exception while performing task ${error}`);
      }
    });
  }

  return Promise.all(
    splittedTasks.map(async (t) => {
      await t();
    })
  );
}

export async function uploadFileUsingPresignedUrl(
  fileInputRef: React.RefObject<FileInputRef>,
  api: Api<unknown>,
  fileType: FileType,
  siteId: string,
  maxChunkSize: number,
  concurrency: number,
  date?: string
) {
  await uploadAndCleanupFromFileInput(
    fileInputRef,
    fileType,
    async (taskData) => {
      return await getPresignedUrlCallback(
        siteId,
        taskData.file.data.name,
        taskData.fileType,
        taskData.file.data.size,
        api,
        maxChunkSize,
        date
      )();
    },
    async (uploadId, parts, taskData) => {
      api.sites.endMultipartFileUpload(
        siteId,
        {
          uploadId,
          parts,
          fileName: taskData.file.data.name,
          fileType: taskData.fileType,
          date: date,
        },
        await getRequestParams()
      );
    },
    concurrency
  );
}

export async function getFileUploadAndCleanupTasksPromise(
  uploadTasks: (() => Promise<void>)[],
  cleanupTasks: (() => Promise<void>)[],
  concurrency: number
) {
  await preformTasksWithMaxConcurrency(uploadTasks, concurrency);
  await preformTasksWithMaxConcurrency(cleanupTasks, concurrency);
}

export function getPresignedUrlCallback(
  siteId: string,
  fileName: string,
  fileType: FileType,
  fileSize: number,
  api: Api<unknown>,
  maxChunkSize: number,
  date?: string
) {
  let optionalParams = {};
  if (date !== undefined) {
    optionalParams = { date: date };
  }
  return async () => {
    if (fileSize < maxChunkSize) {
      const res = await api.sites.getUploadPreSignedUrl(
        siteId,
        { fileName: fileName, fileType: fileType, ...optionalParams },
        await getRequestParams()
      );
      return { urls: [res.data.url] };
    } else {
      return (
        await api.sites.startMultipartFileUpload(
          siteId,
          {
            fileName: fileName,
            fileType: fileType,
            parts: Math.ceil(fileSize / maxChunkSize),
            ...optionalParams,
          },
          await getRequestParams()
        )
      ).data;
    }
  };
}

export interface TaskData {
  file: FileData;
  fileType: FileType;
  date?: string;
  onUploadFinished: (status: boolean) => void;
  onProgress: (prog: number) => void;
}
