import * as React from 'react';
import { DateRangePicker } from 'react-date-range';
import { Collapse } from '@mui/material';

function DateRangeInput({ dateRange, setDateRange }: DateRangeInputProps) {
  const [showInput, setShowInput] = React.useState(false);
  const handleClickRef = React.useRef<(e: any) => void>(handleClick);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.addEventListener('click', handleClickRef.current);
    return () => {
      document.removeEventListener('click', handleClickRef.current);
    };
  }, []);

  function handleClick(e: any) {
    const container = containerRef.current;
    if (container === null) {
      return;
    }

    if (!container.contains(e.target)) {
      setShowInput(false);
    }
  }

  return (
    <div className="datesPreviewWrapper" ref={containerRef}>
      <div
        className="datesPreview"
        onClick={() => {
          setShowInput((val) => !val);
        }}>
        {dateRange.startDate.toDateString()} - {dateRange.endDate.toDateString()}
      </div>
      <div style={{ position: 'absolute', left: '0', top: '25px', zIndex: 1000 }}>
        <Collapse in={showInput}>
          <DateRangePicker
            ranges={[dateRange]}
            onChange={(item) => setDateRange(item.selection as any)}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
          />
        </Collapse>
      </div>
    </div>
  );
}

export interface DateRangeInputProps {
  dateRange: Range;
  setDateRange: (newVal: Range) => void;
}

export interface Range {
  startDate: Date;
  endDate: Date;
  key: string;
}

export default DateRangeInput;
