import { useContext } from "react";
import { ROLE_HOTFIX } from "../../config/authConfig";
import UserContext from "../Contexts/UserContext";
import "./SETTING.css"
import SettingChangeScanLocationInSite from "./Setting_changeScanLocationInSite";
import SettingChangeScanSite from "./Setting_changeScanSite";
import SettingScanValidation from "./Setting_scanValidation";

export default function SETTING() {
    const { user } = useContext(UserContext);


    if(user?.account?.idTokenClaims?.roles?.includes(ROLE_HOTFIX)){
        return (
            <div className="infoContainer">
                <SettingChangeScanSite />
                <SettingScanValidation />
                <SettingChangeScanLocationInSite />
            </div>
    );
    }

    return (
        <h3>UnAuthorize</h3>
    );
}
