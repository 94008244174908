/// <reference path="../../plugins/leaflet-ruler.d.ts"/>

import * as React from 'react';
import { LayerGroup, MapContainer, TileLayer, GeoJSON, LayersControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-ruler/src/leaflet-ruler.css';
import axios from 'axios';

import L from 'leaflet';
import { READERS } from '../../Utils/geoJsonParser';
import { Dict } from '../../BasicTypes';
import { getMapConfig } from '../../config/mapConfig';

const wasMapInitAttirubte = 'data-was-init';

function PolygonDisplayForm({
  polygonFilesData,
  reactOverlayLayers,
  displayPolygons
}: PolygonDisplayFormProps) {
  const [geoJsons, setgeoJsons] = React.useState<any[]>([]);
  const [doneLoading, setDoneLoading] = React.useState(false);

  const mapContainterRef = React.useRef<HTMLDivElement>(null);
  const mapRef = React.useRef<L.Map>(null);

  if (reactOverlayLayers === undefined) {
    reactOverlayLayers = [];
  }

  if (displayPolygons === undefined) {
    displayPolygons = true;
  }

  React.useEffect(() => {
    setgeoJsons([]);
    async function fetchData() {
      for (const polygonFileData of polygonFilesData) {
        let config = {};
        if (polygonFileData.type === 'kmz') {
          config = { responseType: 'arraybuffer' };
        }
        axios.get(polygonFileData.url, config).then(async (fileData) => {
          const parsedJsons = await READERS[polygonFileData.type](fileData.data);
          setgeoJsons((prevState) => {
            const tempState = [...prevState];
            tempState.push(...parsedJsons);
            return tempState;
          });
        });
      }
    }
    fetchData();
  }, [polygonFilesData]);

  React.useEffect(() => {
    setDoneLoading(geoJsons.length >= polygonFilesData.length && polygonFilesData.length > 0);
  }, [geoJsons]);

  React.useEffect(() => {
    if (mapRef === undefined || mapRef.current === null) {
      return;
    }

    const mapVal = mapRef?.current;
    const mapContainer = mapContainterRef?.current;

    if (mapContainer?.getAttribute(wasMapInitAttirubte) === '') {
      L.control
        .ruler({ lengthUnit: { display: 'meters', decimal: 2, factor: 1000 } })
        .addTo(mapVal);
      mapContainer.setAttribute(wasMapInitAttirubte, 'true');
    }

    if (doneLoading) {
      const group = L.featureGroup(geoJsons.map((j) => L.geoJSON(j)));
      mapVal.fitBounds(group.getBounds());
    }
  }, [doneLoading]);

  function getGeoJsons() {
    return geoJsons.map((g) => <GeoJSON data={g} />);
  }

  const mapConfig = getMapConfig();
  const tileLayers = mapConfig.tilesLayers.map((layer) => {
    return (
      <TileLayer
        url={layer.urlFormat}
        attribution={layer.attributions}
        minNativeZoom={layer.minNativeZoom}
        maxNativeZoom={layer.maxNativeZoom}
        maxZoom={mapConfig.maxZoom}
      />
    );
  });

  function getMap() {
    return (
      <div className="mapContainer" ref={mapContainterRef} data-was-init="">
        <MapContainer
          style={{ width: '100%', height: '100%', minHeight: '400px' }}
          maxZoom={mapConfig.maxZoom}
          ref={mapRef}>
          {tileLayers}
          <LayersControl position="topright">
            <LayersControl.Overlay checked={displayPolygons} name="Site Main Polygon[s]">
              <LayerGroup>{getGeoJsons()}</LayerGroup>
            </LayersControl.Overlay>
            {reactOverlayLayers}
          </LayersControl>
        </MapContainer>
      </div>
    );
  }

  return getMap();
}

export interface PolygonDisplayFormProps {
  polygonFilesData: PolygonFileData[];
  reactOverlayLayers?: React.ReactElement<typeof LayersControl.Overlay, any>[];
  displayPolygons?: boolean;
}

export interface PolygonFileData {
  type: string;
  url: string;
}

export default PolygonDisplayForm;
