/*
This module is used to change future data on the remote side
*/
import { AnalysisProductsSite, Api } from "../../Generated/ExoDBAPI";
import { getRequestParams } from "../../Utils/azureAuth";

export function changeValidity({ site, featureS3Ref, isValid }: changeValidityProps) {
  const api = new Api();
  async function setFeatureValidity() {
    try {
      await api.analysis.postFeatureValid(
        site.s3Prefix,
        encodeURIComponent(featureS3Ref),
        { source: site?.s3Source, isValid: isValid },
        await getRequestParams()
      );
    } catch (error) {
      console.log(error);
    }
  }

  setFeatureValidity();
}

export interface changeValidityProps {
  site: AnalysisProductsSite;
  featureS3Ref: string;
  isValid: boolean;
}
