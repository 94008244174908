import { Alert, Button, Collapse, FormControl, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { SECTION_WIDTH } from "./ArcGISStyleConsts";

function LoadResourcesForm({ reportNewResource }: LoadResourcesFormProps) {
  const [resourceType, setResourceType] = React.useState(ArcGISResourceType.FeatureLayer);
  const [resourceUrl, setResourceUrl] = React.useState("");
  const [resourceName, setResourceName] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (resourceUrl.startsWith("https://tiles.") || resourceUrl.startsWith("tiles.")) {
      setResourceType(ArcGISResourceType.TileServer);
    } else if (resourceUrl.endsWith("MapServer") || resourceUrl.endsWith("MapServer/")) {
      setResourceType(ArcGISResourceType.MapServer);
    } else if (resourceUrl.endsWith("FeatureServer") || resourceUrl.endsWith("FeatureServer/")) {
      setResourceType(ArcGISResourceType.FeatureServer);
    } else {
      setResourceType(ArcGISResourceType.FeatureLayer);
    }
  }, [resourceUrl]);

  function submit() {
    if (resourceUrl === "" || resourceName === "") {
      setError("Fill all the required fields");
      return;
    }
    try {
      reportNewResource({ url: resourceUrl, name: resourceName, type: resourceType });
      setResourceUrl("");
      setResourceName("");
    } catch (e) {
      let errorMessage = "Unknown error occurred while trying to load layer";
      if (e instanceof Error) {
        errorMessage = e.message;
      }
      setError(errorMessage);
    }
  }

  return (
    <FormControl sx={{ width: SECTION_WIDTH }}>
      <Collapse in={error !== ""}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      </Collapse>
      <Stack spacing={2}>
        <Typography variant="h5">Load ArcGIS Resource</Typography>
        <Stack spacing={2}>
          <TextField
            label="Type"
            defaultValue={resourceType}
            value={resourceType}
            size="small"
            onChange={(e) => setResourceType(e.target.value as ArcGISResourceType)}
            select
          >
            <MenuItem value={ArcGISResourceType.FeatureLayer}>Feature Layer</MenuItem>
            <MenuItem value={ArcGISResourceType.MapServer}>Map Server</MenuItem>
            <MenuItem value={ArcGISResourceType.FeatureServer}>Feature Server</MenuItem>
            <MenuItem value={ArcGISResourceType.TileServer}>Tile Server</MenuItem>
          </TextField>

          <TextField label="Url" value={resourceUrl} onChange={(e) => setResourceUrl(e.target.value)} size="small" />
          <TextField label="Name" value={resourceName} onChange={(e) => setResourceName(e.target.value)} size="small" />

          <Button variant="outlined" onClick={submit}>
            Load Resource
          </Button>
        </Stack>
      </Stack>
    </FormControl>
  );
}

export enum ArcGISResourceType {
  FeatureLayer = "FeatureLayer",
  MapServer = "MapServer",
  FeatureServer = "FeatureServer",
  TileServer = "TileServer",
}

export interface ResourceInfo {
  url: string;
  name: string;
  type: ArcGISResourceType;
}

export interface LoadResourcesFormProps {
  reportNewResource: (layer: ResourceInfo) => void;
}

export default LoadResourcesForm;
