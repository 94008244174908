import axios from "axios";
import { runWithRetries } from "../Utils/promiseWithRetries";
import { FileUploader } from "./fileUploaderFactory";
import partsUploader from "./partsUploader";

class GenericPreSignedUrlFileUploader implements FileUploader {
  fileData: File;
  getUrlsCallback: () => Promise<UrlResponseData>;
  endMultipartUploadCallBack: (uploadId: string, parts: UploadPart[]) => Promise<void>;
  retries: number;
  timeBetweenRetries: number;
  constructor(
    fileData: File,
    getUrlsCallback: () => Promise<UrlResponseData>,
    endMultipartUploadCallBack: (uploadId: string, parts: UploadPart[]) => Promise<void>,
    retries = 10,
    timeBetweenRetries = 5000
  ) {
    this.fileData = fileData;
    this.retries = retries;
    this.timeBetweenRetries = timeBetweenRetries;
    this.getUrlsCallback = getUrlsCallback;
    this.endMultipartUploadCallBack = endMultipartUploadCallBack;
  }

  async uploadFile(onProgress = (e: number) => {}) {
    const urlsData = await this.getUrlsCallback();
    if (urlsData.uploadId === undefined) {
      await this.handleSinglePartUpload(urlsData.urls, onProgress);
      return;
    }
    await this.handleMultipartUpload(urlsData.urls, urlsData.uploadId, onProgress);
  }

  async handleSinglePartUpload(urls: string[], onProgress: (e: number) => void) {
    if (urls.length !== 1) {
      throw new Error(`Single part upload should have one url, instead got ${urls.length}`);
    }
    const url = urls[0];
    const formData = new FormData();
    formData.append("file", this.fileData);
    const putPromise = axios.put(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (e) => {
        onProgress(Math.round((e.loaded * 100) / e.total));
      },
    });

    await runWithRetries(putPromise, {
      maxRetries: this.retries,
      waitBeforeRetryMilliseconds: this.timeBetweenRetries,
      failureError: new Error("Failed to write file to s3"),
    });
  }

  async handleMultipartUpload(urls: string[], uploadId: string, onProgress: (e: number) => void) {
    const fileParts = this.splitFile(urls.length);
    const filePartsUploader = new partsUploader(fileParts, urls, uploadId, this.retries, this.timeBetweenRetries);
    const res = await filePartsUploader.uploadFile(onProgress);
    await this.endMultipartUploadCallBack(uploadId, res);
  }

  splitFile(numberOfParts: number) {
    let currentPointer = 0;
    const partSize = Math.ceil(this.fileData.size / numberOfParts);
    const chunks = [];
    for (let index = 0; index < numberOfParts; index++) {
      const newStartPointer = currentPointer + partSize;
      chunks.push(this.fileData.slice(currentPointer, newStartPointer));
      currentPointer = newStartPointer;
    }
    return chunks;
  }
}

export interface UrlResponseData {
  urls: string[];
  uploadId?: string;
}

export interface UploadPart {
  ETag: string;
  PartNumber: number;
}

export default GenericPreSignedUrlFileUploader;
