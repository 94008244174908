import * as React from "react";
import {Marker} from "react-leaflet";
import L, {LatLngExpression, type LeafletEventHandlerFnMap} from "leaflet";
import {useRef} from "react";
import useScalableValue from "./useScalableValue";

const defaultClassName = 'text-class-name';

interface Props {
    text: string;
    className?: string;
    position: LatLngExpression,
    eventHandlers?: LeafletEventHandlerFnMap;
    onAfterRender?: (marker: L.Marker | null) => void;
    interactive?: boolean;
    fontSize?: number;
}

function ScalableText(props: Props) {
    const {text, position, className, eventHandlers, onAfterRender, interactive = true, fontSize = 10} = props;
    const markerRef = useRef<L.Marker>(null);
    const usedTextClassName = className ?? defaultClassName;
    const font = useScalableValue(fontSize);

    React.useEffect(() => {
        if (markerRef.current) {
            const textElement = (markerRef.current as unknown as { _icon: HTMLDivElement })._icon;
            if (textElement) {
                textElement.style.fontSize = `${font}px`;
                textElement.style.width = 'auto'; // needed to make correct size of the box;
                textElement.style.height = 'auto';

                textElement.style.marginTop = `${-textElement.offsetHeight / 2}px`;
                textElement.style.marginLeft = `${-textElement.offsetWidth / 2}px`;
            }
        }

        onAfterRender?.(markerRef.current);
    });

    const textIcon = L.divIcon({html: text, className: usedTextClassName});
    return <Marker interactive={interactive} position={position} icon={textIcon} ref={markerRef} eventHandlers={eventHandlers}/>;
}

export default ScalableText;
