import { Box, Button } from "@mui/material";
import * as React from "react";

function AreaFilter({
  isDrawing,
  filterExists,
  onFilterStart,
  onFilterStop,
  onFilterDelete,
  onFilterEnabled,
  onFilterDisabled,
  onClearSelected,
  onLoadSelected,
  onClearStorageSelections
}: AreaFilterProps) {
  const [filterEnabled, setFilterEnabled] = React.useState(true);

  React.useEffect(() => {
    if (!filterExists) {
      setFilterEnabled(true);
    }
  }, [filterExists]);

  return (
    <Box marginTop={3}>
      <span className="areaFilterButtonsContainer">
        <Button
          variant="contained"
          onClick={() => {
            isDrawing ? onFilterStop() : onFilterStart();
            setFilterEnabled(true);
          }}
        >
          {isDrawing ? "Stop drawing" : "Create filter"}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setFilterEnabled(true);
            onFilterDelete();
          }}
          disabled={isDrawing}
        >
          Delete filter
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            filterEnabled ? onFilterDisabled() : onFilterEnabled();
            setFilterEnabled((f) => !f);
          }}
        >
          {filterEnabled ? "Disable filter" : "Enable filter"}
        </Button>
        <div></div>
        <div><hr /></div>
        <div><hr /></div>

        <Button
          variant="contained"
          color="secondary"
          onClick={onClearSelected}
          disabled={isDrawing}
          > Clear Selected </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onLoadSelected}
          > Load Saved Selected </Button>
        <div></div>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClearStorageSelections}
          > Clear Saved Selected </Button>
      </span>
    </Box>
  );
}

export interface FilterEvents {
  onFilterStart: () => void;
  onFilterStop: () => void;
  onFilterDelete: () => void;
  onFilterEnabled: () => void;
  onFilterDisabled: () => void;
  onClearSelected: () => void;
  onLoadSelected: () => void;
  onClearStorageSelections: () => void;
}

export type AreaFilterProps = FilterEvents & {
  isDrawing: boolean;
  filterExists: boolean;
};

export default AreaFilter;
