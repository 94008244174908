import React from "react";
import { useEffect, useRef } from "react";

function RedirectToSharepoint({ sharepointLink }: RedirectToSharepointProps) {
  const redirectButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const redirectButton = redirectButtonRef.current;
    if (redirectButton === null) {
      console.log("Redirect button ref is null");
      return;
    }
    if (sharepointLink === undefined) {
      redirectButton.disabled = true;
    } else {
      redirectButton.disabled = false;
    }
  }, [sharepointLink]);

  function onRedirectClick() {
    if (sharepointLink === null) {
      console.log("cannot open new tab sharepoint link is null");
      return;
    }
    const sharepointTab = window.open(sharepointLink, "_blank");
    if (sharepointTab) {
      sharepointTab.focus();
    }
  }
  return (
    <span className="onedriveRedirectContainer">
      <button className="redirectToSharepoint" onClick={onRedirectClick} ref={redirectButtonRef}>
        Go to Onedrive directory
      </button>
    </span>
  );
}

interface RedirectToSharepointProps {
  sharepointLink: string;
}

export default RedirectToSharepoint;
