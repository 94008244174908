import { GeoShape } from "../../../Generated/ExoDBAPI";
import { MarkingLineTypeChoices, MarkingPolygonTypeChoices } from "./DrawnFileData";

export function getMarkingOptions(geoShape: GeoShape | undefined): string[] {
  if (geoShape === GeoShape.Polygon) {
    return MarkingPolygonTypeChoices;
  }

  if (geoShape === GeoShape.Polyline) {
    return MarkingLineTypeChoices;
  }

  return [];
}
