import * as React from "react";
import { Dict } from "../../BasicTypes";

function ListDisplay({ items, containerStyle = {}, itemStyle = {} }: ListDisplayProps) {
  return (
    <ul className="listDisplayContainer" style={containerStyle}>
      {items.map((item) => (
        <li className="listDisplayItem" style={itemStyle}>
          {item}
        </li>
      ))}
      <li className="listDisplayItem"></li>
    </ul>
  );
}

interface ListDisplayProps {
  items: string[];
  containerStyle?: Dict<string>;
  itemStyle?: Dict<string>;
}

export default ListDisplay;
