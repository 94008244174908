import React from "react";
import { AnalysisProductsSite, AnalysisProductsSource, SiteDetails } from "../../Generated/ExoDBAPI";
import { DisplayGroup } from "./DataTypes/DisplayModes";
import { MapFeatures } from "./DataTypes/MapFeatures";
import { DrawnContext, getDisplayMode } from "./Drawns/DrawnFileData";
import { ForceNoMissingPipes } from "./SiteSelection/AllowMissingPipesForm";

function displayErrorMessage(missingIds: string[], siteName?: string) {
  const missingPipes = missingIds.filter((id) => id.includes("__pipe__"));
  const message = [
    `When running on site: ${siteName}:`,
    `There are ${missingPipes.length} missing pipes.\n`,
    "You should:",
    "1) Check if it repeated in stable version.",
    "2) Inform software of your finding.",
    "\nNote: Upon approval of this message, you will be removed from the site",
  ].join("\n");
  alert(message);
}

function shouldForceLocationReplace(forceNoMissingPipes: ForceNoMissingPipes, isProduction: boolean) {
  if (forceNoMissingPipes === ForceNoMissingPipes.ABORT) {
    return true;
  }

  if (forceNoMissingPipes === ForceNoMissingPipes.IGNORE) {
    return false;
  }

  return isProduction;
}

function InfoDisplay({
  source,
  plannedSite,
  analysisSite,
  downloadedFeaturesCount,
  currentErrorsCount,
  invalidFeaturesCount,
  mapFeatures,
  currentlyDisplayedGroup,
  expectedLayersCount,
  currentUserLayersErrorsCount,
  expectedFeatures,
  doneLoading,
  drawnContext,
  displayedFeatures,
  forceNoMissingPipes,
}: InfoDisplayProps) {
  const missingFeaturesMessage = React.useMemo<React.ReactNode>(() => {
    if (doneLoading) {
      const allIds = new Set<string>([
        ...mapFeatures.pipes.map((e) => e.header.s3Ref),
        ...mapFeatures.transmitters.map((e) => e.header.s3Ref),
        ...mapFeatures.polygons.map((e) => e.header.s3Ref),
      ]);

      const missingIds = expectedFeatures.filter((id) => !allIds.has(id) && !id.includes("__gps__"));
      if (missingIds.some((id) => id.includes("__pipe__"))) {
        displayErrorMessage(missingIds, analysisSite?.name);
        if (shouldForceLocationReplace(forceNoMissingPipes, source?.name === "production")) {
          window.location.replace(window.location.href.split("?")[0]);
        }
        return <b className="infoDisplayCritical">S3 pipes are missing, check error tab for more info</b>;
      }
      if (missingIds.length > 0) {
        return <b className="infoDisplayWarning">S3 non pipe features are missing, check error tab for more info</b>;
      }
      return <b className="infoDisplayInfo">S3 as expected</b>;
    }
  }, [doneLoading, mapFeatures, expectedFeatures]);

  let displayGroupName = `Unknown Group (${currentlyDisplayedGroup})`;
  if (currentlyDisplayedGroup === DisplayGroup.FILTER) {
    displayGroupName = "Main View";
  } else if (currentlyDisplayedGroup === DisplayGroup.SELECTED_PIPE) {
    displayGroupName = "Pipe View";
  }

  const numberOfShapes = drawnContext.savedLayers
    .map((layer) => layer.shapes.length)
    .reduce((partialSum, a) => partialSum + a, 0);

  return (
    <div className="infoDisplay">
      Source: {source?.name}, Planned Site: {plannedSite?.name}, AnalysisSite: {analysisSite?.name} <br />
      Features: {downloadedFeaturesCount + invalidFeaturesCount + currentErrorsCount}, Successful:
      {
        " " /* need to explicitly add a space after line break because white spaces before line breaks are removed by formatting*/
      }
      {downloadedFeaturesCount}, Invalid: {invalidFeaturesCount}, Errors: {currentErrorsCount}. {missingFeaturesMessage}
      <br />
      Pipes: {mapFeatures.pipes.length}, Transmitters: {mapFeatures.transmitters.length}, Travel Courses:{" "}
      {mapFeatures.travelCourses.length}, Polygons: {mapFeatures.polygons.length}, Displayed Pipes:{" "}
      {displayedFeatures.pipes.length},{" "}
      <span style={{ color: "green", fontWeight: "bold" }}>{doneLoading ? "Done Loading" : ""}</span>
      <br />
      Layers [Shapes]: {numberOfShapes + currentUserLayersErrorsCount} / {expectedLayersCount}, Successful:{" "}
      {numberOfShapes}, Errors: {currentUserLayersErrorsCount}
      <br />
      Display Mode: {`"${displayGroupName}"`}, Drawn Display Mode: {`"${getDisplayMode(drawnContext)}"`}
    </div>
  );
}

export interface InfoDisplayProps {
  source?: AnalysisProductsSource;
  plannedSite?: SiteDetails;
  analysisSite?: AnalysisProductsSite;
  downloadedFeaturesCount: number;
  mapFeatures: MapFeatures;
  invalidFeaturesCount: number;
  currentErrorsCount: number;
  currentlyDisplayedGroup: DisplayGroup;
  expectedLayersCount: number;
  currentUserLayersErrorsCount: number;
  expectedFeatures: string[];
  doneLoading: boolean;
  drawnContext: DrawnContext;
  displayedFeatures: MapFeatures;
  forceNoMissingPipes: ForceNoMissingPipes;
}

export default InfoDisplay;
