import * as React from 'react';
import { Api, SiteDetails } from '../../../../Generated/ExoDBAPI';
import { getRequestParams } from '../../../../Utils/azureAuth';
import { FileData, FileInputRef } from '../../../Inputs/FileInput';
import UploadFile from './UploadFile';
import { readFileUrl } from '../../../../Utils/fileUtils';

function UploadDWGFile({ plannedSite }: UploadShapefileProps) {
  async function uploadShapefileCallback(
    site: SiteDetails,
    layerName: string,
    fileData: FileData,
    fileInput: FileInputRef,
    multiSite?: boolean
  ) {
    const api = new Api();
    const fileBase64 = await readFileUrl(fileData.data);
    if (fileBase64 === null) {
      window.alert('Unable to read shapefile');
      return;
    }
    await api.analysis.postAnalysisLayers(
      {
        name: layerName,
        data: fileBase64.split('base64,')[1],
        site: site.name,
        layerType: 'dwg',
        multiSite: multiSite
      },
      await getRequestParams()
    );
  }

  return (
    <UploadFile
      plannedSite={plannedSite}
      accept=".dwg"
      percentages={false}
      uploadCallback={uploadShapefileCallback}
      allowMultiSite={true}
    />
  );
}

export interface UploadShapefileProps {
  plannedSite?: SiteDetails;
}

export default UploadDWGFile;
