import React, { useEffect, useState, useContext } from "react";
import "./styles/App.css";
import SitesViewer from "./Components/Pages/SitesViewer";
import Navbar from "./Components/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import DetailedSiteView from "./Components/Pages/DetailedSiteView";
import CreateSitePage from "./Components/Pages/CreateSitePage";
import AddSiteData from "./Components/Pages/AddSiteData";
import AdminPage from "./Components/Pages/AdminPage";
import UserContext from "./Components/Contexts/UserContext";
import UploadBackgroundImagePage from "./Components/Pages/UploadBackgroundImagePage";
import {
  Api,
  ClientDetails,
  SiteType,
  StateDetails,
} from "./Generated/ExoDBAPI";
import { getRequestParamsOnlyHeaders } from "./Utils/azureAuth";
import SiteAdminForm from "./Components/AdminForms/SiteAdminForm";
import ExoFuserPage from "./Components/Pages/ExoFuserPage";
import PipelineDashboard from "./Components/Pages/PipelineDashboard";
import UploadScanDataPage from "./Components/Pages/UploadScanDataPage";
import ScansDashboard from "./Components/Pages/ScansDashboard";
import UploadOriginalImageTifPage from "./Components/Pages/UploadOriginalTifPage";
import MegaMonitor from "./Components/Pages/MegaMonitor";
import UploadPreloaderSupportData from "./Components/Pages/UploadPreloaderSupportData";
import LogRocket from "logrocket";
import { QueryParamProvider } from "use-query-params";
import * as QueryString from "query-string";
import { siteViewerCardsCreator } from "./Utils/siteCardsCreators";
import RequestLogIn from "./Components/RequestLogIn";
import RTKMonitor from "./Components/Pages/RTKMonitor";
import PSDView from "./Components/Pages/PSDView";
import SETTING from "./Components/Pages/SETTING";
import { LayerProvider } from "./Components/Contexts/LayerContext";
import { ManHole } from "./Components/Pages/ManHole";
import { POI } from "./Components/Pages/POI";
import { POIEntity } from "./Components/Pages/POIEntity";

if (process.env.REACT_APP_DEV_MODE !== "on") {
  LogRocket.init("0bo4qw/exoplanner");
}

function Exoplanner() {
  const [states, setStates] = useState<StateDetails[]>([]);
  const [clients, setClients] = useState<ClientDetails[]>([]);
  const [siteTypes, setSiteTypes] = useState<SiteType[]>([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    async function fetchData() {
      if (user !== undefined) {
        const api = new Api({ baseUrl: process.env.REACT_APP_BACKEND_HOST });

        api.clients
          .getClients(await getRequestParamsOnlyHeaders())
          .then((res) => setClients(res.data.clients));

        api.states
          .getStates(await getRequestParamsOnlyHeaders())
          .then((res) => setStates(res.data.states));
        api.siteTypes
          .getSiteTypes(await getRequestParamsOnlyHeaders())
          .then((res) => setSiteTypes(res.data.siteTypes));
      }
    }
    fetchData();
  }, [user]);

  function loadIfLoggedIn(page: React.ReactNode) {
    return user !== undefined ? page : <RequestLogIn />;
  }

  return (
    <>
      <Router>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: QueryString.default.parse,
            objectToSearchString: QueryString.default.stringify,
          }}
        >
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <div className="pageWrapper">
                  <SitesViewer
                    states={states}
                    siteTypes={siteTypes}
                    clients={clients}
                    siteCardCreator={siteViewerCardsCreator("sites/")}
                  />
                </div>
              }
            />
            <Route
              path="sites"
              element={
                <div className="pageWrapper">
                  <SitesViewer
                    states={states}
                    siteTypes={siteTypes}
                    clients={clients}
                  />
                </div>
              }
            />
            <Route
              path="sites/:siteId"
              element={
                <DetailedSiteView
                  loadedStates={states}
                  loadedClients={clients}
                />
              }
            />
            <Route
              path="createSite"
              element={
                <CreateSitePage
                  loadedStates={states}
                  siteTypes={siteTypes}
                  loadedClients={clients}
                />
              }
            />
            <Route path="addSiteData/:siteId" element={<AddSiteData />} />
            <Route
              path="admin"
              element={
                <AdminPage
                  states={states}
                  siteTypes={siteTypes}
                  clients={clients}
                />
              }
            />
            <Route
              path="uploadBackgroundImage/:siteId"
              element={<UploadBackgroundImagePage />}
            />
            <Route
              path="uploadTif/:siteId"
              element={<UploadOriginalImageTifPage />}
            />
            <Route
              path="uploadScanData/:siteId"
              element={<UploadScanDataPage />}
            />
            <Route
              path="sites/:siteId/admin"
              element={
                <SiteAdminForm
                  states={states}
                  clients={clients}
                  siteTypes={siteTypes}
                />
              }
            />
            <Route
              path="exofuser/"
              element={loadIfLoggedIn(
                <LayerProvider>
                  <ExoFuserPage locations={states} />
                </LayerProvider>
              )}
            />
            <Route path="pipelines/" element={<PipelineDashboard />} />
            <Route
              path="dataFlow/"
              element={loadIfLoggedIn(<ScansDashboard />)}
            />
            <Route
              path="monitoring/"
              element={loadIfLoggedIn(<MegaMonitor />)}
            />
            {/* Kept for backward support */}
            <Route
              path="uploadManualSupport/"
              element={loadIfLoggedIn(<UploadPreloaderSupportData />)}
            />
            <Route
              path="uploadPreloaderSupport/"
              element={loadIfLoggedIn(<UploadPreloaderSupportData />)}
            />
            <Route
              path="rtkMonitor/"
              element={loadIfLoggedIn(<RTKMonitor />)}
            />
            <Route path="psdView/" element={loadIfLoggedIn(<PSDView />)} />
            <Route path="manholes/" element={loadIfLoggedIn(<ManHole />)} />
            <Route path="poi/" element={loadIfLoggedIn(<POI />)} />
            <Route path="poientity/" element={loadIfLoggedIn(<POIEntity />)} />
            <Route path="hotfix/" element={loadIfLoggedIn(<SETTING />)} />
          </Routes>
        </QueryParamProvider>
      </Router>
    </>
  );
}

export default Exoplanner;
