import * as React from 'react';
import { Dict } from '../../../BasicTypes';
import { MapFeatures } from '../DataTypes/MapFeatures';
import EntityTypeFilter from './EntityTypeFilter';

import AreaFilter, { FilterEvents } from './AreaFilter';

import {
  DateFilterCategoryFactory,
  SensorFilterCategoryFactory,
  ScanFilterCategoryFactory,
  FrequencyFilterCategoryFactory,
  CreationDateFilterCategoryFactory,
  UserFilterCategoryFactory,
  SnakeCategoryFactory,
  PlatformTypeFilterCategoryFactory,
  InvalidScanFilterCategoryFactory
} from './GeneralFilterCategories';
import {
  AutoConfidenceFilterCategoryFactory,
  HasCommentFilterCategoryFactory,
  HasDepthFilterCategoryFactory,
  MainConfidenceFilterCategoryFactory,
  CrossingDepthCategoryFactory,
  PipeAtEdge,
  PipesQAFlagged,
  PipesQAStatus,
  PipeAboveGround,
  PipeAngleOfCrossing,
  PipeLength,
  PipeMisfitScore,
  PipeSegmentationScore,
  PipeTransmitterMethod,
  PipesQAComment
} from './FoundEntityFilterCategories';
import {
  MethodFilterCategoryFactory,
  SourceFilterCategoryFactory
} from './TransmitterFilterCategories';
import {
  AnalysisProductsPipe,
  AnalysisProductsPolygon,
  AnalysisProductsTarget,
  AnalysisProductsTransmitter,
  AnalysisProductsTravelCourse
} from '../DataTypes/MapEntities';
import { RangeFilterFactory } from './RangeFilter';
import { getMaxValue, getMinValue } from '../../../Utils/mathUtils';
import { declineRejected } from '../../../Utils/filterUtils';

function FilterTab({
  mapFeatures,
  setDisplayedFeatures,
  showTravelCoursesAtStart,
  isDrawingAreaFilter,
  areaFilterExists,
  areaFilterEvents,
  loadingStateChange,
  transmitterMapping,
  targets = []
}: EntityFilterTabProps) {
  const snrRange = React.useMemo<[number, number]>(() => {
    const snrs = mapFeatures.pipes.filter((p) => p.snr !== undefined).map((p) => p.snr) as number[];
    if (snrs.length === 0) {
      return [0, 0];
    }
    return [getMinValue(snrs), getMaxValue(snrs)] as [number, number];
  }, [mapFeatures.pipes]);

  return (
    <>
      <span className="tabsContainer filterContainer">
        <span className="tabsBody filterTab">
          <h3>Pipe filter</h3>
          <EntityTypeFilter<AnalysisProductsPipe>
            entities={mapFeatures.pipes}
            setPickedEntities={(pipes: AnalysisProductsPipe[]) => {
              setDisplayedFeatures((state) => {
                return {
                  transmitters: state.transmitters,
                  travelCourses: state.travelCourses,
                  polygons: state.polygons,
                  pipes: pipes,
                  targets: targets
                };
              });
            }}
            filtersCategoriesFactories={[
              new MainConfidenceFilterCategoryFactory({}),
              new DateFilterCategoryFactory({}),
              new FrequencyFilterCategoryFactory({ categoryRank: 2 }),
              new SensorFilterCategoryFactory({}),
              new ScanFilterCategoryFactory({}),
              new InvalidScanFilterCategoryFactory({}),
              new PlatformTypeFilterCategoryFactory({}),
              new CreationDateFilterCategoryFactory({}),
              new UserFilterCategoryFactory({}),
              new AutoConfidenceFilterCategoryFactory({}),
              new RangeFilterFactory(
                'SNR',
                snrRange[0],
                snrRange[1],
                (p: AnalysisProductsPipe) => p.snr
              ),
              new HasDepthFilterCategoryFactory({}),
              new HasCommentFilterCategoryFactory({}),
              new SnakeCategoryFactory({}),
              new CrossingDepthCategoryFactory({ categoryRank: 3 }),
              new PipeAtEdge({}),
              new PipesQAFlagged({}),
              new PipesQAStatus({ defaultCheckStrategy: declineRejected }),
              new PipesQAComment({}),
              new PipeAboveGround({}),
              new PipeAngleOfCrossing({}),
              new PipeLength({}),
              new PipeTransmitterMethod({ categoryRank: 4, transmitterMapping }),
              new PipeMisfitScore({}),
              new PipeSegmentationScore({})
            ]}
            loadingStateChange={loadingStateChange}
          />
        </span>
        <span className="tabsBody filterTab">
          <h3>Transmitter filter</h3>
          <EntityTypeFilter<AnalysisProductsTransmitter>
            entities={mapFeatures.transmitters}
            setPickedEntities={(transmitters) => {
              setDisplayedFeatures((state) => {
                return {
                  transmitters: transmitters,
                  travelCourses: state.travelCourses,
                  polygons: state.polygons,
                  pipes: state.pipes,
                  targets: targets
                };
              });
            }}
            filtersCategoriesFactories={[
              new DateFilterCategoryFactory({}),
              new FrequencyFilterCategoryFactory({ categoryRank: 2 }),
              new SensorFilterCategoryFactory({}),
              new ScanFilterCategoryFactory({}),
              new MethodFilterCategoryFactory({}),
              new SourceFilterCategoryFactory({}),
              new InvalidScanFilterCategoryFactory({}),
              new SnakeCategoryFactory({}),
              new PlatformTypeFilterCategoryFactory({})
            ]}
            strategy={false}
            loadingStateChange={loadingStateChange}
          />
        </span>
        <span className="tabsBody filterTab">
          <h3>Travel course filter</h3>
          <EntityTypeFilter<AnalysisProductsTravelCourse>
            entities={mapFeatures.travelCourses}
            setPickedEntities={(travelCourses) => {
              setDisplayedFeatures((state) => {
                return {
                  transmitters: state.transmitters,
                  travelCourses: travelCourses,
                  polygons: state.polygons,
                  pipes: state.pipes,
                  targets: targets
                };
              });
            }}
            filtersCategoriesFactories={[
              new DateFilterCategoryFactory({}),
              new SensorFilterCategoryFactory({}),
              new ScanFilterCategoryFactory({}),
              new InvalidScanFilterCategoryFactory({}),
              new SnakeCategoryFactory({}),
              new PlatformTypeFilterCategoryFactory({})
            ]}
            strategy={showTravelCoursesAtStart}
            loadingStateChange={loadingStateChange}
          />
        </span>
        <span className="tabsBody filterTab">
          <h3>Polygon filter</h3>
          <EntityTypeFilter<AnalysisProductsPolygon>
            entities={mapFeatures.polygons}
            setPickedEntities={(polygons) => {
              setDisplayedFeatures((state) => {
                return {
                  transmitters: state.transmitters,
                  travelCourses: state.travelCourses,
                  polygons: polygons,
                  pipes: state.pipes,
                  targets: targets
                };
              });
            }}
            filtersCategoriesFactories={[
              new MainConfidenceFilterCategoryFactory({}),
              new DateFilterCategoryFactory({}),
              new FrequencyFilterCategoryFactory({ categoryRank: 2 }),
              new SensorFilterCategoryFactory({}),
              new ScanFilterCategoryFactory({}),
              new InvalidScanFilterCategoryFactory({}),
              new CreationDateFilterCategoryFactory({}),
              new UserFilterCategoryFactory({}),
              new AutoConfidenceFilterCategoryFactory({}),
              new HasDepthFilterCategoryFactory({}),
              new SnakeCategoryFactory({})
            ]}
            loadingStateChange={loadingStateChange}
          />
        </span>
        <span className="tabsBody filterTab">
          <h3>Area filter</h3>
          <AreaFilter
            isDrawing={isDrawingAreaFilter}
            filterExists={areaFilterExists}
            {...areaFilterEvents}
          />
        </span>
      </span>
    </>
  );
}

export interface EntityFilterTabProps {
  mapFeatures: MapFeatures;
  setDisplayedFeatures: (f: (m: MapFeatures) => MapFeatures) => void;
  loadingStateChange: boolean;
  showTravelCoursesAtStart: boolean;
  isDrawingAreaFilter: boolean;
  areaFilterExists: boolean;
  areaFilterEvents: FilterEvents;
  transmitterMapping: Dict<AnalysisProductsTransmitter[]>;
  targets?: AnalysisProductsTarget[];
}

export default FilterTab;
