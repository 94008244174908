import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddSiteDataForm from "../AddSiteDataComponents/AddSiteDataForm";
import LoadingWheel from "../LoadingWheel";
import { useContext } from "react";
import UserContext from "../Contexts/UserContext";
import RequestLogIn from "../RequestLogIn";
import { Api, SiteDetails } from "../../Generated/ExoDBAPI";
import { getBearerToken } from "../../Utils/azureAuth";

/**
 * Add site data page. for adding relevant files to site that are saved to the S3
 * @returns
 */
function AddSiteData() {
  const [site, setSite] = useState<SiteDetails>();
  const { user } = useContext(UserContext);

  let { siteId } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (user !== undefined && siteId !== undefined) {
        const api = new Api();
        const site = (
          await api.sites.getSiteById(siteId, {
            baseUrl: process.env.REACT_APP_BACKEND_HOST,
            headers: { authorization: await getBearerToken() },
          })
        ).data;
        setSite(site);
      }
    }
    fetchData();
  }, [siteId, user]);

  function createPage() {
    if (user === undefined) {
      return <RequestLogIn />;
    }

    if (site === undefined) {
      return <LoadingWheel />;
    }
    if (siteId === undefined) {
      throw Error("Site id was undeifined in url");
    }

    return (
      <div className="pageWrapper">
        <div className="infoContainer">
          <h2 className="pageHeader">Add data to site - {site.name}</h2>
          <AddSiteDataForm siteId={siteId} />
        </div>
      </div>
    );
  }

  return createPage();
}

export default AddSiteData;
