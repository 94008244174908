import * as React from 'react';
import { Modal, Tabs, Tab, Box, Stack, Typography, IconButton } from '@mui/material';
import ModalBox from '../../Templates/ModalBox';
import CloseIcon from '@mui/icons-material/Close';

function HelpModal({ reportClose }: HelpModalProps) {
  const [tabIndex, setTabIndex] = React.useState(0);
  return (
    <Modal open={true}>
      <ModalBox>
        <IconButton sx={{ float: 'right' }} onClick={reportClose}>
          <CloseIcon />
        </IconButton>
        <Box sx={{ width: 600, height: 800, overflowY: 'auto' }}>
          <Tabs value={tabIndex} onChange={(_, newVal) => setTabIndex(newVal)}>
            <Tab label="Polygons"></Tab>
            <Tab label="Transmitters"></Tab>
          </Tabs>
          {tabIndex === 0 && (
            <Stack margin={0.5}>
              <p>
                <Typography variant="subtitle1" fontWeight="bold">
                  Drawing Polygons
                </Typography>
                Click the plus button to start drawing a new polygon. When drawing you can perform
                the following actions:
                <ul>
                  <li>Create a new vertex - Ctrl click on the map to create a new vertex.</li>
                  <li>
                    After drawing at least 3 vertices you can hit the check button to create the
                    polygon.
                  </li>
                </ul>
              </p>
              <p>
                <Typography variant="subtitle1" fontWeight="bold">
                  Editing polygons
                </Typography>
                To edit a polygon click on its corresponding item on the list. If the polygons
                vertices are highlighted then its editable. while editing a polygon you can perform
                the following actions:
                <ul>
                  <li>Delete an existing vertex - Alt click on a vertex to delete it</li>
                  <li>Move a vertex - Click and drag a vertex to move it</li>
                  <li>
                    Split an edge - Ctrl clicking on an edge will create a new vertex at that
                    location
                  </li>
                  <li>
                    Any color change (clicking on the color related icons) will be saved immediately
                  </li>
                  <li>Deleting a polygon - click on the x and approve delete</li>
                </ul>
                <b style={{ color: '#ff0000' }}>
                  ***Click on the save button to save all changes made on the vertex on the map
                  while the layer is selected***
                </b>
              </p>
              <p>
                <Typography variant="subtitle1" fontWeight="bold">
                  Editing a polygon's information
                </Typography>
                <ul>
                  <li>
                    Click on the info icon to open an attribute table. Click on the edit link on the
                    bottom to open a popup to edit the polygon's information.
                  </li>
                  <li>
                    Click on the save button to save all changes made on the polygon's information
                  </li>
                </ul>
              </p>
            </Stack>
          )}
          {tabIndex === 1 && (
            <Stack margin={0.5}>
              <p>
                <Typography variant="subtitle1" fontWeight="bold">
                  Editing a polygons transmitters
                </Typography>
                When a single polygon is editable you can perform the following transmitter related
                actions.
                <ul>
                  <li>
                    Create a new transmitter - Hold T and click on the map to create a new
                    transmitter
                  </li>
                  <li>
                    Move a transmitter - Click on the parent layer and drag the transmitter to move
                    it
                  </li>
                  <li>Deleting a transmitter - Click on the x and approve delete</li>
                  <li>
                    Any color change (clicking on the color related icons) will be saved immediately
                  </li>
                </ul>
                <b style={{ color: '#ff0000' }}>
                  ***Click on the save button to save all changes made on the transmitter on the map
                  while the parent layer is selected***
                </b>
              </p>
              <p>
                <Typography variant="subtitle1" fontWeight="bold">
                  Editing a polygon's information
                </Typography>
                <ul>
                  <li>
                    Click on the info icon to open an attribute table. Click on the edit link on the
                    bottom to open a popup to edit the transmitter's information.
                  </li>
                  <li>
                    Click on the save button to save all changes made on the transmitter's
                    information
                  </li>
                </ul>
              </p>
            </Stack>
          )}
        </Box>
      </ModalBox>
    </Modal>
  );
}

export interface HelpModalProps {
  reportClose: () => void;
}

export default HelpModal;
