import * as React from 'react';
import { Stack } from '@mui/material';
import { Box } from '@mui/material';
import { LatLng, LatLngExpression } from 'leaflet';
import { ScanDetails } from '../DataTypes/MapEntities';
import ManagePolygonsForm, { PlannedObjectType } from './ManagePolygonsForm';
import CreatePolygonModal from './CreatePolygonModal';
import CreateTransmitterModal from './CreateTransmitterModal';
import { PlannedPolygonLayer } from '../../../Utils/layerUtils';
import {
  useUpdateSiteWithPlannedEntityMutation,
  useCreatePlannedEntityMutation,
  useCreatePlannedPolygonMutation,
  useCreatePlannedTransmitterMutation,
  useDeletePlannedPolygonMutation,
  useDeletePlannedTransmitterMutation,
  useUpdatePlannedPolygonMutation,
  useUpdatePlannedTransmitterMutation
} from '../../../Generated/Graphql';
import { subscribe } from '../../../Utils/events';

export enum PlannedEntitiesTypes {
  POLYGON = 'Polygon',
  FEATURE = 'Feature',
  POINT = 'Point',
  TRANSMITTER = 'Transmitter'
}
const fieldsForData = ['color', 'overrideColor', 'geoData'];
function PlanningTab({
  plannedPolygons,
  setPlannedPolygons,
  beingDrawnPolygon,
  setBeingDrawnPolygon,
  isDrawing,
  sensors,
  newTransmitterData,
  clearTransmitterData,
  graphqlSiteId,
  reportTabOpened,
  scans,
  plannedEntityId
}: PlanningTabProps) {
  const [showCreatePolygonModal, setShowCreatePolygonModal] = React.useState(false);
  const [plannedPolygonId, setPlannedPolygonId] = React.useState('');
  const [updateSiteWithPlannedEntityMutation] = useUpdateSiteWithPlannedEntityMutation();
  const [createPlannedEntityMutation] = useCreatePlannedEntityMutation();
  const [createPlannedPolygonMutation] = useCreatePlannedPolygonMutation();
  const [createPlannedTransmitterMutation] = useCreatePlannedTransmitterMutation();
  const [updatePlannedPolygonMutation] = useUpdatePlannedPolygonMutation();
  const [updatePlannedTransmitterMutation] = useUpdatePlannedTransmitterMutation();
  const [deletePlannedPolygonMutation] = useDeletePlannedPolygonMutation();
  const [deletePlannedTransmitterMutation] = useDeletePlannedTransmitterMutation();

  React.useEffect(() => {
    reportTabOpened();
    subscribe('sendUpdate', (e: Event) => {
      const type = (e as CustomEvent<any>).detail.type;
      const input = {
        id: (e as CustomEvent<any>).detail.id
      } as Partial<PlannedPolygonLayer>;
      fieldsForData.forEach((field) => {
        if ((e as CustomEvent<any>).detail[field] !== undefined) {
          input[field as keyof Partial<PlannedPolygonLayer>] = (e as CustomEvent<any>).detail[
            field
          ];
        }
      });
      type === PlannedEntitiesTypes.POLYGON ? updatePolygon(input) : updateTransmitter(input);
    });
  }, []);

  function createNewPlannedPolygon() {
    setBeingDrawnPolygon([]);
  }

  function savePolygon() {
    setShowCreatePolygonModal(true);
  }

  function discardPolygon() {
    setBeingDrawnPolygon(undefined);
  }
  function convertToGeoJson(polygon: LatLng[] | LatLng) {
    let coordinates;
    let geoJson;
    if (!Array.isArray(polygon) || polygon.length === 1) {
      coordinates = !Array.isArray(polygon)
        ? [polygon.lng, polygon.lat]
        : [polygon[0].lng, polygon[0].lat];
      geoJson = {
        coordinates,
        type: PlannedEntitiesTypes.POINT
      };
    } else {
      coordinates = polygon.map((point: LatLng) => {
        return [point.lng, point.lat];
      });
      geoJson = {
        geometry: { coordinates: [coordinates], type: PlannedEntitiesTypes.POLYGON },
        type: PlannedEntitiesTypes.FEATURE,
        properties: {}
      };
    }

    return JSON.stringify(geoJson);
  }

  /**
   * createPlannedEntity
   * @param siteId
   * @returns plannedEntityId
   * function to create plannedEntity if no plannedEntityId.
   *  Uses two mutations: One to create the plannedEntity and one to update the site with the plannedEntityId
   */
  async function createPlannedEntity(siteId: string) {
    const graphqlResp = await createPlannedEntityMutation({
      variables: {
        plannedEntitiesSiteId: siteId
      }
    });
    if (graphqlResp.errors) {
      window.alert(`Got ${graphqlResp.errors.length} while trying to create poi in graphql`);
      console.error('Got errors', graphqlResp.errors);
    } else if (graphqlResp.data?.createPlannedEntities?.id) {
      updateSiteWithPlannedEntityMutation({
        variables: {
          id: siteId,
          plannedEntitiesId: graphqlResp.data?.createPlannedEntities?.id
        }
      });
      return graphqlResp.data?.createPlannedEntities?.id;
    }
  }

  /**
   * createPolygon
   * @param name
   * @param sensors
   * @param comment
   * function that uses createPlannedPolygonMutation to create a polygon and adds it to the plannedPolygons array and closes the modal
   */

  async function createPolygon(
    name: string,
    sensors: string[],
    platforms: string[],
    comment: string,
    isRescan: boolean
  ) {
    const plannedEntityIdToAdd =
      plannedEntityId && plannedEntityId !== ''
        ? plannedEntityId
        : await createPlannedEntity(graphqlSiteId ?? '');
    const geo_data = convertToGeoJson((beingDrawnPolygon as LatLng[]) ?? ([] as LatLng[]));

    if (plannedEntityIdToAdd && plannedEntityIdToAdd !== '') {
      const input = {
        color: '#ff0000',
        sensorModels: sensors,
        comment: comment ?? '',
        geoData: geo_data,
        mountMethod: platforms ?? [],
        name: name ?? '',
        plannedEntitiesPlanned_polygonsId: plannedEntityIdToAdd,
        overrideColor: false,
        isRescan: isRescan
      };
      try {
        const graphqlResp = await createPlannedPolygonMutation({
          variables: input
        });
        if (graphqlResp) {
          setPlannedPolygonId(graphqlResp.data?.createPlannedPolygon?.id ?? '');
          setPlannedPolygons((polygons) => [
            ...polygons,
            {
              id: graphqlResp.data?.createPlannedPolygon?.id ?? crypto.randomUUID(),
              name: name,
              data: beingDrawnPolygon,
              metadata: {
                name: name,
                comment: comment,
                sensorTypes: sensors,
                platforms: platforms,
                type: PlannedObjectType.POLYGON,
                isRescan: isRescan
              },
              selected: false,
              visible: true,
              color: '#ff0000'
            }
          ]);
        }
      } catch (e) {
        console.error(e);
        window.alert('Failed to create planned polygon');
      }
    } else {
      window.alert('Failed to create planned entity');
    }
    setBeingDrawnPolygon(undefined);
    setShowCreatePolygonModal(false);
  }

  //function that creates a transmitter and adds it to the plannedPolygons array and closes the modal

  async function createTransmitter(
    name: string,
    comment: string,
    polygon: PlannedPolygonLayer,
    coords: LatLngExpression,
    method?: string
  ) {
    const geo_data = convertToGeoJson((coords as unknown as LatLng[]) ?? ([] as LatLng[]));
    const selectedPolygonIndex = plannedPolygons.indexOf(polygon);
    const newTransmitterPolygonId =
      plannedPolygonId && plannedPolygonId !== ''
        ? plannedPolygonId
        : plannedPolygons[selectedPolygonIndex].id;
    if (newTransmitterPolygonId && newTransmitterPolygonId !== '') {
      const input = {
        color: '#ff0000',
        comment: comment ?? '',
        geoData: geo_data,
        name: name ?? '',
        method: method ?? '',
        plannedPolygonPlanned_transmittersId: newTransmitterPolygonId,
        overrideColor: false
      };
      try {
        const graphqlResp = await createPlannedTransmitterMutation({
          variables: input
        });
        if (graphqlResp) {
          const selectedPolygonIndex = plannedPolygons.indexOf(polygon);
          setPlannedPolygons((polygons) => {
            const newPolygons = [...polygons];
            const newSubLayers = [...(newPolygons[selectedPolygonIndex].subLayers ?? [])];
            newSubLayers.push({
              data: [coords],
              metadata: {
                name: name,
                comment: comment,
                method: method,
                type: PlannedObjectType.TRANSMITTER
              },
              name: name,
              id: graphqlResp.data?.createPlannedTransmitter?.id ?? crypto.randomUUID(),
              color: polygons[selectedPolygonIndex].color,
              visible: true,
              selected: false
            });
            newPolygons[selectedPolygonIndex].subLayers = newSubLayers;
            return newPolygons;
          });
        }
      } catch (e) {
        console.error(e);
        window.alert('Failed to create planned transmitter');
      }
    } else {
      window.alert('Failed to create planned polygon');
    }

    clearTransmitterData();
  }

  async function updatePolygon(newData: Partial<PlannedPolygonLayer>) {
    const input = {
      ...newData,
      id: newData.id || ''
    };
    const selectedPolygon = plannedPolygons.find((p) => p.id === newData.id);
    if (selectedPolygon?.subLayers && selectedPolygon?.subLayers?.length > 0) {
      if (newData.color) {
        selectedPolygon.subLayers.forEach((t) => {
          if (!t.overrideColor) {
            updateTransmitter({ id: t.id, color: newData.color });
          }
        });
      }
      if (newData.geoData) {
        selectedPolygon.subLayers.forEach((t) => {
          const geo_data = convertToGeoJson((t.data as unknown as LatLng[]) ?? ([] as LatLng[]));
          updateTransmitter({ id: t.id, geoData: geo_data });
        });
      }
    }
    if (newData.geoData && newData.geoData.length > 0) {
      const geo_data = convertToGeoJson(
        (newData.geoData as unknown as LatLng[]) ?? ([] as LatLng[])
      );
      input.geoData = geo_data;
    }

    if (newData.id && newData.id !== '') {
      input.id = newData.id;
      try {
        const graphqlresp = await updatePlannedPolygonMutation({
          variables: input
        });
        if (graphqlresp) {
          setPlannedPolygons((polygons) => {
            const newPolygons = polygons.map((p) => {
              if (p.id === plannedPolygonId) {
                p = { ...p, ...newData };
              }
              return p;
            });
            return newPolygons;
          });
        }
      } catch (e) {
        console.error(e);
        window.alert('Failed to update planned polygon');
      }
    }
  }

  async function updateTransmitter(newData: Partial<PlannedPolygonLayer>) {
    if (newData.id) {
      const input = {
        ...newData,
        id: newData.id
      };
      try {
        const graphqlResp = await updatePlannedTransmitterMutation({
          variables: input
        });
        if (graphqlResp) {
          setPlannedPolygons((polygons) => {
            const newPolygons = polygons.map((p) => {
              if (p.id === plannedPolygonId) {
                const newSubLayers = p.subLayers?.map((t) => {
                  if (t.id === newData.id) {
                    t = { ...t, ...newData };
                  }
                  return t;
                });
                p.subLayers = newSubLayers;
              }
              return p;
            });
            return newPolygons;
          });
        }
      } catch (e) {
        console.error(e);
        window.alert('Failed to update planned transmitter');
      }
    }
  }
  async function deletePolygon(polygonId: string) {
    if (polygonId && polygonId !== '') {
      const input = {
        id: polygonId
      };
      try {
        const graphqlResp = await deletePlannedPolygonMutation({
          variables: input
        });
        if (graphqlResp) {
          setPlannedPolygons((polygons) => {
            const newPolygons = polygons.filter((p) => p.id !== polygonId);
            return newPolygons;
          });
        }
      } catch (e) {
        console.error(e);
        window.alert('Failed to delete planned polygon');
      }
    } else {
      window.alert('Failed to delete planned polygon');
    }
  }

  async function deleteTransmitter(transmitterId: string) {
    if (transmitterId && transmitterId !== '') {
      const input = {
        id: transmitterId
      };

      try {
        const graphqlResp = await deletePlannedTransmitterMutation({
          variables: input
        });
        if (graphqlResp) {
          setPlannedPolygons((polygons) => {
            const newPolygons = polygons.map((p) => {
              const newSubLayers = p.subLayers?.filter((t) => t.id !== transmitterId);
              p.subLayers = newSubLayers;
              return p;
            });
            return newPolygons;
          });
        }
      } catch (e) {
        console.error(e);
        window.alert('Failed to delete planned transmitter');
      }
    }
  }
  return (
    <Stack direction="row">
      <Box sx={{ width: 750 }}>
        <ManagePolygonsForm
          layers={plannedPolygons}
          setLayers={setPlannedPolygons}
          addPolygonClick={createNewPlannedPolygon}
          savePolygonClick={savePolygon}
          discardPolygonClick={discardPolygon}
          isDrawing={isDrawing}
          saveable={isDrawing && beingDrawnPolygon !== undefined && beingDrawnPolygon.length > 2}
          sensors={sensors}
          graphqlSiteId={graphqlSiteId}
          deletePolygon={deletePolygon}
          deleteTransmitter={deleteTransmitter}
          updatePolygon={updatePolygon}
          updateTransmitter={updateTransmitter}
        />
      </Box>
      {showCreatePolygonModal && (
        <CreatePolygonModal
          sensors={sensors}
          reportCancel={() => {
            setBeingDrawnPolygon(undefined);
            setShowCreatePolygonModal(false);
          }}
          reportSave={createPolygon}
        />
      )}
      {newTransmitterData !== undefined && (
        <CreateTransmitterModal
          polygon={newTransmitterData.polygon.name}
          reportSave={(name, comment, chosenMethod) =>
            createTransmitter(
              name,
              comment,
              newTransmitterData.polygon,
              newTransmitterData.coords,
              chosenMethod
            )
          }
          reportCancel={clearTransmitterData}
        />
      )}
    </Stack>
  );
}

export interface PlanningTabProps {
  plannedPolygons: PlannedPolygonLayer[];
  setPlannedPolygons: (
    polygons: PlannedPolygonLayer[] | ((polygons: PlannedPolygonLayer[]) => PlannedPolygonLayer[])
  ) => void;
  beingDrawnPolygon?: LatLngExpression[];
  setBeingDrawnPolygon: (positions?: LatLngExpression[]) => void;
  isDrawing: boolean;
  sensors: string[];
  newTransmitterData?: PlannedTransmitterData;
  clearTransmitterData: () => void;
  graphqlSiteId?: string;
  reportTabOpened: () => void;
  scans: ScanDetails[];
  plannedEntityId?: string;
}

export interface PlannedTransmitterData {
  polygon: PlannedPolygonLayer;
  coords: LatLngExpression;
}

export default PlanningTab;
