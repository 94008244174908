import { createContext, useState } from "react";
import { AuthenticationResult } from "@azure/msal-browser";

const UserContext = createContext<{
  user: userData | undefined;
  setUser: React.Dispatch<React.SetStateAction<userData | undefined>>;
}>({
  user: undefined,
  setUser: () => {},
});

export function UserProvider({ children }: any) {
  const [user, setUser] = useState<userData>();

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
}

/* Actually used elements:
export interface userData {
  accessToken: string;
  expiresOn: Date | null;
  account: { homeAccountId: string; username: string; name?: string } | null;
}
*/
export type userData = AuthenticationResult;

export default UserContext;
