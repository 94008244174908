import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

export interface DrawCondition {
    id: number;
    condition: string;
    name: string;
    lanId: string;
    date: string;
    comments: string;
}

interface PropDrawCondition {
    items: DrawCondition[];
}


const borderColor = '#fff'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#fff',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 18,
        fontStyle: 'bold',
        fontSize: 10,
        marginLeft: 24,
    },
    title: {
        width: '15%',
        textAlign: 'left',
        fontSize: 10,
        borderRightColor: '#fff',
        borderRightWidth: 0,
        paddingLeft: 8,
    },
    name: {
        width: '16%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 10,
        textAlign: 'left',
        borderColor: '#000',
        borderWidth: 1,
        paddingTop: 2,
        backgroundColor: '#acafb5',
    },
    comments: {
        width: '37%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 10,
        textAlign: 'left',
        paddingRight: 8,

        borderColor: '#000',
        borderWidth: 1,
        paddingLeft: 4,
        paddingTop: 2,
        backgroundColor: '#acafb5',
    },
    footer : {
        fontSize: 6,
    },
    footer_row: {
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        height: 9,
        fontStyle: 'bold',
        width: '100%'
    },
  });


const TableRowDraw = ({items}: PropDrawCondition) => {
    if(items && items.length>0) {
    const rows = items.map( item =>
        <View style={styles.row} key={item.id.toString()}>
            <Text style={styles.title}>{item.condition}</Text>
            <Text style={styles.name}>{item.name}</Text>
            <Text style={styles.name}>{item.lanId}</Text>
            <Text style={styles.name}>{item.date}</Text>
            <Text style={styles.comments}>{item.comments}</Text>
        </View>)
        return (<>{rows}</> )
    }

    return (
        <View style={styles.row} key={'0'}>
            <Text style={styles.title}>{'Drawing Made by'}</Text>
            <Text style={styles.name}>{' '}</Text>
            <Text style={styles.name}>{' '}</Text>
            <Text style={styles.name}>{' '}</Text>
            <Text style={styles.comments}>{' '}</Text>
        </View>
        )
};

export default TableRowDraw

export const FooterDraw = () => {
    return (
        <View style={styles.footer_row} key={'0'}>
            <Text style={styles.footer}>{'I hereby certify that all dimensions, orientations and offsets shown above are accurate to locate and mark determinations'}</Text>
        </View>
    )
}
