import { SiteDetails, UserLayerShape } from "../../../Generated/ExoDBAPI";

function createCacheKeyBySite(plannedSite: SiteDetails): string {
  return `userLayersAllCache_for_${plannedSite.id}`;
}

export function readFromCache(plannedSite?: SiteDetails): LayersCacheEntry[] {
  if (plannedSite === undefined) {
    return [];
  }
  const cacheKey = createCacheKeyBySite(plannedSite);

  const storedData = window.localStorage.getItem(cacheKey);
  if (storedData === null || storedData === undefined) {
    return [];
  }

  let entries = [];
  try {
    entries = JSON.parse(storedData) as LayersCacheEntry[];
  } catch (error) {
    return [];
  }

  if (!Array.isArray(entries)) {
    return [];
  }

  const goodEntries = (entries as any[]).filter(
    (entry) => typeof entry.name === "string" && Array.isArray(entry.features)
  );

  if (goodEntries.length !== (entries as unknown[]).length) {
    const badEntries = (entries as any[]).filter(
      (entry) => typeof entry.name !== "string" || !Array.isArray(entry.features)
    );
  }

  return goodEntries;
}

export function saveToCache({ plannedSite, cachedLayers }: SaveToCacheParams): void {
  const cacheKey = createCacheKeyBySite(plannedSite);
  window.localStorage.setItem(cacheKey, JSON.stringify(cachedLayers));
}

export interface LayersCacheEntry {
  name: string;
  features: UserLayerShape[];
  timestamp?: number;
}

export interface SaveToCacheParams {
  plannedSite: SiteDetails;
  cachedLayers: LayersCacheEntry[];
}
