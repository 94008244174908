import React from 'react';
import {
  AnalysisProductsSite,
  AnalysisProductsSource,
  Api,
  SiteDetails,
  StateDetails
} from '../../../Generated/ExoDBAPI';
import UserContext from '../../Contexts/UserContext';
import { getRequestParams } from '../../../Utils/azureAuth';
import SiteSelectForm, { DISPLAY_MAP_NAME, createDisplayMapOption } from './SitesSelectForm';
import AllowMissingPipesForm, { ForceNoMissingPipes } from './AllowMissingPipesForm';
import { Stack } from '@mui/material';

function SiteSelectionHandler({
  setGraphqlSiteId,
  setParentAnalysisSite,
  setParentPlannedSite,
  setParentSource,
  setParentMapDisplayTravelCourses,
  setSourceQueryParam,
  setAnalysisSiteQueryParam,
  setPlannedSiteQueryParam,
  setForceNoMissingPipes,
  selectedAnalysisSiteName,
  selectedPlannedSiteId,
  selectedSourceS3Prefix,
  settransmittersAndTravelCoursesChecked,
  locations
}: SiteSelectionHandlerProps) {
  const [dataSources, setDataSources] = React.useState<AnalysisProductsSource[]>([]);
  const [plannedSites, setPlannedSites] = React.useState<SiteDetails[]>([]);
  const { user } = React.useContext(UserContext);

  React.useEffect(() => {
    // Fill the planned site after user connect
    async function fetchData() {
      if (user === undefined) {
        return;
      }
      const api = new Api();
      api.sites.getSites({}, await getRequestParams()).then((res) => {
        const sites = res.data.sites.sort((a: SiteDetails, b: SiteDetails) =>
          a.name < b.name ? -1 : 1
        );
        setPlannedSites(sites);
      });
    }
    fetchData();
  }, [user]);

  React.useEffect(() => {
    // Fill the sources after user connect
    async function fetchData() {
      if (user === undefined) {
        return;
      }
      const api = new Api();
      api.analysis.getProductsSources(await getRequestParams()).then((res) => {
        setDataSources(res.data.sources);
      });
    }
    fetchData();
  }, [user]);

  React.useEffect(() => {
    // Handle analysis site on source/planned site change
    async function fetchData() {
      if (
        user === undefined ||
        selectedAnalysisSiteName === undefined ||
        selectedPlannedSiteId === undefined ||
        selectedSourceS3Prefix?.length === 0
      ) {
        return;
      }
      // Fill the analysis site that matches the source and planned site
      const api = new Api();

      const plannedSite = plannedSites.find((s) => s.id === selectedPlannedSiteId);
      const sources =
        selectedSourceS3Prefix &&
        dataSources.filter((s) => selectedSourceS3Prefix.includes(s.s3Source));
      if (!plannedSite || !sources || sources.length === 0) {
        return;
      }

      const responses = await Promise.all(
        sources.map(async (source) =>
          api.analysis.getAnalysisSites(
            {
              plannedSiteId: plannedSite.id,
              source: source.s3Source
            },
            await getRequestParams()
          )
        )
      );

      const analysisSites: AnalysisProductsSite[] = [];
      responses.forEach((response) => {
        const analysisSitesData = response.data;
        analysisSites.push(...analysisSitesData.sites);
        analysisSites.push(
          createDisplayMapOption(analysisSitesData.coursesSource, analysisSitesData.coursesKey)
        );
      });

      const analysisSite = analysisSites.filter((s) => s.name === selectedAnalysisSiteName);
      if (analysisSite === undefined) {
        return;
      }

      setParentAnalysisSite(analysisSite);
      setParentPlannedSite(plannedSite);
      setParentSource(sources);
      setParentMapDisplayTravelCourses(analysisSite[0].name === DISPLAY_MAP_NAME);
    }
    fetchData();
  }, [
    user,
    plannedSites,
    dataSources,
    selectedPlannedSiteId,
    selectedAnalysisSiteName,
    selectedSourceS3Prefix,
    setParentAnalysisSite,
    setParentPlannedSite,
    setParentSource,
    setParentMapDisplayTravelCourses
  ]);

  return (
    <Stack direction="row" spacing={2}>
      <span className="tabsContainer">
        <span className="tabsBody">
          <h3>Select Site</h3>
          <SiteSelectForm
            plannedSites={plannedSites}
            dataSources={dataSources}
            setGraphqlSiteId={setGraphqlSiteId}
            setParentSource={setSourceQueryParam}
            setParentAnalysisSite={setAnalysisSiteQueryParam}
            setParentPlannedSite={setPlannedSiteQueryParam}
            setParentMapDisplayTravelCourses={setParentMapDisplayTravelCourses}
            locations={locations}
            settransmittersAndTravelCoursesChecked={settransmittersAndTravelCoursesChecked}
          />
        </span>
        <span className="tabsBody">
          <h3>On missing pipes</h3>
          <AllowMissingPipesForm setForceNoMissingPipes={setForceNoMissingPipes} />
        </span>
      </span>
    </Stack>
  );
}

export interface SiteSelectionHandlerProps {
  setSourceQueryParam: (s: string[] | undefined) => void;
  setPlannedSiteQueryParam: (s: string | undefined) => void;
  setAnalysisSiteQueryParam: (s: string | undefined) => void;
  setParentSource: (s: AnalysisProductsSource[]) => void;
  setParentPlannedSite: (s: SiteDetails | undefined) => void;
  setParentAnalysisSite: (s: AnalysisProductsSite[]) => void;
  setForceNoMissingPipes: (s: ForceNoMissingPipes) => void;
  setParentMapDisplayTravelCourses: React.Dispatch<React.SetStateAction<boolean>>;
  setGraphqlSiteId: (id: string) => void;
  selectedPlannedSiteId?: string;
  selectedSourceS3Prefix?: (string | null)[] | undefined | null;
  selectedAnalysisSiteName?: string;
  locations: StateDetails[];
  settransmittersAndTravelCoursesChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export default SiteSelectionHandler;
