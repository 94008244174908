interface PropertiesData {
    [key: string]: any;
  }

  export function getPropertiesValue(properties: string, key: string, defaultValue: any = null): any {
    try {
      const parsedProperties: PropertiesData = JSON.parse(properties);
      return parsedProperties[key] ?? defaultValue;
    } catch (error) {
      return defaultValue;
    }
  }

  export function setPropertiesValue(properties: string, key: string, value: any): string {
    let parsedProperties: PropertiesData = {};

    try {
      parsedProperties = JSON.parse(properties);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }

    parsedProperties[key] = value;
    return JSON.stringify(parsedProperties);
  }
