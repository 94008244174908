import { useCallback, useContext, useEffect, useState } from "react";
import { Table, Typography } from "../../../../shared";
import { ColumnDef } from "@tanstack/react-table";
import UserContext from "../../../Contexts/UserContext";
import { useDeleteUnifyTaskMutation, useSearchUnifyTasksLazyQuery } from "../../../../Generated/Graphql";
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { reportAllPagesResults } from "../../../../Utils/graphqlUtils";
import DeleteIcon from '@mui/icons-material/Delete';

type UnifyTaskType = {
    id: string;
    status: string;
}

const getStatusColor = (status: string) => {
    switch (status) {
      case 'IN_PROGRESS':
        return 'blue';
      case 'CANCELED':
        return 'red';
      case 'FAILED':
        return 'red';
      case 'STARTED':
        return 'black';
      default:
        return 'black';
    }
  }

type UnifyStatusProps = {
  siteId: string | undefined;
}

const UnifyStatus = ({ siteId }: UnifyStatusProps) => {
  const { user } = useContext(UserContext);
  const [unifyData, setUnifyData] = useState<UnifyTaskType[]>([]);
  const [, searchUnifyTasks] = useSearchUnifyTasksLazyQuery({ variables: { siteId: siteId ?? '' } });
  const [deleteUnifyTaskMutation] = useDeleteUnifyTaskMutation();
  const [loading, setLoading] = useState(false);

  const columns: ColumnDef<UnifyTaskType, any>[] = [
    {
      accessorKey: 'id',
      header: (p) => (
        <Typography fontWeight={400} textAlign="left">
          Task Id
        </Typography>
      )
    },
    {
      accessorKey: 'user_email',
      header: (p) => (
        <Typography fontWeight={400} textAlign="left">
          User
        </Typography>
      )
    },
    {
        accessorKey: 'status',
        header: (p) => (
          <Typography fontWeight={300} textAlign="left">
            Task Status
          </Typography>
        ),
        cell: (info) => (
            <Typography fontWeight="bold" textAlign="left" color={getStatusColor(info.getValue())}>
              {info.getValue()}
            </Typography>
          )
      },
    {
        accessorKey: 'createdAt',
        header: (p) => (
          <Typography fontWeight={300} textAlign="left">
            CreatedAt
          </Typography>
        )
      },
      {
        accessorKey: 'delete',
        header: (p) => (
          <Typography fontWeight={300} textAlign="left">

          </Typography>
        ),
        cell: (info) => {
          const status = info.row.original.status;
          if (status === 'IN_PROGRESS' || status === 'STARTED' || status === '') {
            return null;
          }
          return (
            <DeleteIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleDelete(info.row.original.id)}
            />
          );
        }
      }
  ];

  function handleDelete(id: string): void {
    deleteUnifyTaskMutation({ variables: { unifyTaskId: id } });
    setUnifyData((prev) => [...prev.filter((task) => task.id !== id)]);
  }


    const load = useCallback(async () => {
        setLoading(true);
        setUnifyData([]);
        await reportAllPagesResults(
          searchUnifyTasks,
          async (res) => {
            const searchTasks = (await (res).data.searchUnifyTasks?.items) || [];
            if (searchTasks.length === 0) {
              setLoading(false);
              return;
            }
            setUnifyData([
              ...searchTasks.map(task => ({ id: task?.id ?? '', status: task?.status ?? '', createdAt: task?.createdAt?.slice(0, 10) ?? '', user_email: task?.user_email?.replace('@exodigo.ai', '') ?? '' }))
            ]);
          },
          (res) => res.data.searchUnifyTasks?.nextToken
        );
        setLoading(false);
    }, [searchUnifyTasks]);

    useEffect(() => {
      if (!siteId) return;
      load();
    }, [load, siteId]);

  return (
    <>
    <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        {loading && <CircularProgress size={24} />}

        <IconButton onClick={load} disabled={loading}>
          <RefreshIcon />
          <Typography variant="body2" ml={1}>Refresh</Typography>
        </IconButton>
      </Box>
    <Table
          columns={columns}
          data={unifyData}
          onRowClick={() => {}}
        />
    </>
  )
}

export default UnifyStatus
