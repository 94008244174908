import React, { useState, useEffect, useRef } from 'react';
import { Marker, Polygon, useMap, useMapEvents } from 'react-leaflet';
import { LatLngExpression, LeafletMouseEvent, LeafletKeyboardEvent } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { AnalysisProductsTravelCourse, ScanDetails } from '../../DataTypes/MapEntities';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import L from 'leaflet';
import * as turf from '@turf/turf';
import { unifyAreaType, useUnifyStore } from './unifyStore';
import './PolygonDrawer.css';
import { findCenter, getGroupedScansFull } from './UnifyUtils';
import { dataModel } from './UnifyData';

const selectTravels = (travelCourses: AnalysisProductsTravelCourse[], polygonPoints:L.LatLngExpression[]): [AnalysisProductsTravelCourse[], ScanDetails[]] => {
    if (polygonPoints.length < 3) {
      return [[], [] as ScanDetails[]];
    }
    const turfPolygonPoints = polygonPoints.map(point => {
      if (Array.isArray(point)) {
          return [point[1], point[0]]; // Convert [lat, lng] to [lng, lat]
      } else if (point instanceof L.LatLng) {
          return [point.lng, point.lat]; // Convert L.LatLng to [lng, lat]
      } else {
          throw new Error('Invalid point format');
      }
    });

    const polygon = turf.polygon([turfPolygonPoints]);

    const idsInPolygonSet = new Set<string>();
    const filterTravelCourses: AnalysisProductsTravelCourse[] = []

    travelCourses.filter(travel => travel?.header?.scan?.polygon && travel?.header?.scan?.polygon > 0 && travel?.header?.scan?.snake && travel?.header?.scan?.snake > 0 && travel?.header?.scan?.part && travel?.header?.scan?.part > 0 ).forEach(course => {
      const geoJson = JSON.parse(course.header.geoJson);
      const coordinates: [number, number][] = geoJson.geometry.coordinates;

      for (const coord of coordinates) {
        const point = turf.point(coord);
        if (booleanPointInPolygon(point, polygon)) {
            idsInPolygonSet.add(course.header.id);
            filterTravelCourses.push(course)
            break;
        }
      }
    });

    const filteredTravelCourses = travelCourses.filter(course => idsInPolygonSet.has(course.header.id));
    const scans = filteredTravelCourses.map(course => course.header.scan);

    const orderedScans = scans.sort((a, b) => {
        if (a.polygon !== b.polygon) {
          return (a?.polygon || 0) - (b?.polygon || 0);
        } else if (a.snake !== b.snake) {
          return (a?.snake || 0) - (b?.snake || 0);
        } else {
          return (a?.part || 0) - (b?.part || 0);
        }
      });
    return [filterTravelCourses || [], orderedScans || [] as ScanDetails[]];
  }

interface UnifyDrawLineProps {
    travelCourses: AnalysisProductsTravelCourse[];
}

const PolygonDrawer = ({travelCourses}: UnifyDrawLineProps) => {
  const [points, setPoints] = useState<[number, number][]>([]);
  const polygonRef = useRef<L.Polygon | null>(null);
  const { setSelectedScans, setSelectedUnifyArea, selectedUnifyArea, polygons, setPolygons, getNewId } = useUnifyStore();
  const [currentZoomLevel, setCurrentZoomLevel] = useState<number>(20);
  const [currentMousePosition, setCurrentMousePosition] = useState([0, 0]);
  const MapEvents: React.FC = () => {
    const map = useMap();

    useEffect(() => {
          map.getContainer().style.cursor = 'crosshair';
        return () => {
          map.getContainer().style.cursor = '';
        }
      }, [map]);

    useMapEvents({
      mousemove: (event) => {
        const { lat, lng } = event.latlng;
        setCurrentMousePosition([lat, lng]);
      },
      click(e: LeafletMouseEvent) {
          setPoints((prevPoints) => [...prevPoints, [e.latlng.lat, e.latlng.lng]]);
      },
      keydown(e: LeafletKeyboardEvent) {
        if (e.originalEvent.key === 'Escape') {
          if(points.length<3) {
            setPoints([]);
            return
          }
            const closePolygon = [...points, points[0]];
            const [filterTravelCourses, orderedScans] = selectTravels(travelCourses, [...points, points[0]]);
            setSelectedScans(orderedScans);

            const areaId = getNewId();
            const groupedScansFull = getGroupedScansFull(orderedScans);
            const dataModels: dataModel[] = Object.keys(groupedScansFull).map(polygon => ({
                polygon: Number(polygon),
                scans_names: groupedScansFull[polygon].map(scan => scan.name),
                item: `${areaId}_${polygon}`,
                areaId: areaId,
                scans: groupedScansFull[polygon]
            }));
            setSelectedUnifyArea([...selectedUnifyArea.filter((value: unifyAreaType) => value.scans.length > 0), {id: areaId ,polygon: closePolygon, travelCourses: filterTravelCourses, scans: orderedScans, model: dataModels}]);
            setPolygons([...polygons, { points: points }]);
            setPoints([]);
        }
      },
      zoomend() {
        setCurrentZoomLevel(map.getZoom());
      }
    });
    return null;
  };

  useEffect(() => {
    if (polygonRef.current) {
      polygonRef.current.setLatLngs(points);
    }
  }, [points]);

  return (
    <>
        {selectedUnifyArea.map((area) => (
        <>
          <Polygon key={`area_${area.id}`} positions={area.polygon} />
          {currentZoomLevel > 17 && <Marker interactive={false} position={findCenter(area.polygon)} icon={L.divIcon({html: `${area.id}`, className: 'text_polygon_center'})} />}
        </>
        ))}
      <Polygon
          key='new_polygon'
          ref={polygonRef}
          positions={[...points, [currentMousePosition[0], currentMousePosition[1]]]}
          pathOptions={{ color: 'orange', fillColor: 'orange' }}
        />
        <MapEvents />
    </>
  );
};

export default PolygonDrawer;
