import * as React from "react";
import CustomGeoJsonLayersStorageManager, {
  CustomGeoJsonLayersStorageManagerProps,
} from "./CustomGeoJsonLayersStorageManager";
import PlanningLayerStorage, { PlanningLayerStorageProps } from "./PlanningLayersStorage";

function UserStorageManager({ customGeoJsonLayersStorageProps, planningLayerStorageProps }: UserStorageManagerProps) {
  return (
    <>
      {customGeoJsonLayersStorageProps && <CustomGeoJsonLayersStorageManager {...customGeoJsonLayersStorageProps} />}
      {planningLayerStorageProps && <PlanningLayerStorage {...planningLayerStorageProps} />}
    </>
  );
}

export interface UserStorageManagerProps {
  customGeoJsonLayersStorageProps?: CustomGeoJsonLayersStorageManagerProps;
  planningLayerStorageProps?: PlanningLayerStorageProps;
}

export default UserStorageManager;
