import React, { FormEvent, SyntheticEvent } from "react";
import { useRef } from "react";
import { ClientDetails, SiteType, StateDetails } from "../../Generated/ExoDBAPI";

const SCROLL_HEIGHT = "1000px";

function FilterBar({ states, siteTypes, clients, noFilterVal, onFilter, onResetClicked }: FilterBarProps) {
  const filterFromRef = useRef<HTMLFormElement>(null);

  function filterClicked() {
    const filterForm = filterFromRef.current;
    if (filterForm !== null) {
      if (filterForm.style.maxHeight !== SCROLL_HEIGHT) {
        filterForm.style.maxHeight = SCROLL_HEIGHT;
      } else {
        filterForm.style.maxHeight = "0px";
      }
    }
  }

  function filterSubmitClicked(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData: any = {};
    const fields = e.target;

    if (!(fields instanceof HTMLFormElement)) {
      return;
    }

    for (const field of fields) {
      if (field instanceof HTMLInputElement || field instanceof HTMLSelectElement) {
        if (field.type !== "submit" && field.type !== "button") {
          if (field.value !== noFilterVal) {
            formData[field.name] = field.value;
          } else {
            formData[field.name] = undefined;
          }
        }
      }
    }
    onFilter(formData);
  }

  return (
    <>
      <div className="filterContainer">
        <span className="filterTab" onClick={filterClicked}>
          filter
        </span>
        <form className="filterForm" ref={filterFromRef} onSubmit={filterSubmitClicked}>
          <span className="siteFormEntrySpan3">
            <input type="text" id="siteName" name="siteName" className="searchBar" placeholder="Site name" />
          </span>
          <span className="siteFormEntry">
            <label htmlFor="clientId">Client name: </label>
            <select id="clientId" name="clientId">
              <option value={noFilterVal} key="__AllKey__">
                All
              </option>
              {clients.map((c) => {
                return (
                  <option value={c.id} key={c.id + c.name}>
                    {c.name}
                  </option>
                );
              })}
            </select>
          </span>
          <span className="siteFormEntry" key="siteLocationEntry">
            <label>Site location: </label>
            <select id="location" name="location">
              <option value={noFilterVal}>All</option>
              {states.map((s, i) => {
                return (
                  <option value={i} key={s.countryName + s.stateName}>
                    {s.countryName}, {s.stateName}
                  </option>
                );
              })}
            </select>
          </span>
          <span className="siteFormEntry" key="siteTypeEntry">
            <label htmlFor="siteType">Site type: </label>
            <select id="siteType" name="siteType">
              <option value={noFilterVal}>All</option>
              {siteTypes.map((s) => {
                return (
                  <option value={s} key={s}>
                    {s}
                  </option>
                );
              })}
            </select>
          </span>
          <span className="siteFormEntry">
            <label htmlFor="createdAfter">Created After: </label>
            <input type="date" id="createdAfter" name="createdAfter" />
          </span>
          <span className="siteFormEntry">
            <label htmlFor="createdBefore">Created before: </label>
            <input type="date" id="createdBefore" name="createdBefore" />
          </span>
          <span className="filterSubmision" key="submitButtonFilter">
            <input type="submit" value="Filter" className="formButton" />
          </span>
          <span className="siteFormEntry" key="submitButtonResearch">
            <input type="button" value="Reset search" className="formButton" onClick={onResetClicked} />
          </span>
        </form>
      </div>
    </>
  );
}

export interface FilterBarProps {
  states: StateDetails[];
  siteTypes: SiteType[];
  clients: ClientDetails[];
  noFilterVal: string;
  onFilter: (queryPrams: any) => void;
  onResetClicked: () => void;
}

export default FilterBar;
