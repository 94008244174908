import React from "react";
import { FeatureLayer } from "react-esri-leaflet";
import { LayerGroup, LayersControl } from "react-leaflet";
import { mapService } from "esri-leaflet";
import EsriLayer, { EsriLayerGroup } from "./EsriLayer";

function EsriMapServer({ url, namePrefix = "" }: EsriMapServerProps) {
  const [layersInfo, setLayersInfo] = React.useState<layerInfo[]>([]);

  React.useEffect(() => {
    mapService({
      url,
    })
      .query()
      .run((error, featureCollection, response) => {
        if (error) {
          console.log("error while trying to fetch map server", error);
          return;
        }
        setLayersInfo(response.layers.map((layer: any) => ({ id: layer.id, name: layer.name })));
      });
  }, []);

  return (
    <>
      {layersInfo.map((layer) => (
        <EsriLayerGroup
          name={`${namePrefix}-${layer.name}`}
          url={`${url}/${layer.id}`}
          key={`${namePrefix}-${layer.name}`}
        />
      ))}
    </>
  );
}

export interface EsriMapServerProps {
  url: string;
  namePrefix?: string;
}

export interface layerInfo {
  id: string;
  name: string;
}

export default EsriMapServer;
