import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react'
import { SiteInfo } from '../Common/ChooseSite';
import PageCard from '../Templates/PageCard';
import Typography from '@mui/material/Typography';
import { useGetAllSitesInfoForChoosingLazyQuery } from '../../Generated/Graphql';
import { parseSites } from '../../Utils/parseCommonQueries';

interface SiteQueryProps {
    setSelectedSite: (site: SiteInfo | null) => void;
    selectedSite: SiteInfo | null;
    excludeSite?: SiteInfo | null;
    title?: string;
}

function SiteQuery({ setSelectedSite, selectedSite, excludeSite, title="Site Select" }: SiteQueryProps) {
    const [allSites, setAllSites] = useState<SiteInfo[]>([]);
    const [, getAllSitesQuery] = useGetAllSitesInfoForChoosingLazyQuery();

    useEffect(() => {
        async function load() {
          const sitesPromise = parseSites(getAllSitesQuery);
          const sites = (await sitesPromise).sort((a, b) => a.name.localeCompare(b.name));
          const sitesList = sites.filter((item) => item !== null && item.locationId !== null && item.id !== excludeSite?.id);
          setAllSites(
            sitesList.map((site) => {
              return {
                id: site.id,
                name: site.name,
                s3Ref: site.siteS3Ref,
                locationId: site.locationId ?? "",
                customerId: site.customerId ?? "",
              };
            })
          );
        }
        load();
    }, []);

    useEffect(() => {
        const siteId = selectedSite?.id;
        if (siteId) {
            setSelectedSite(selectedSite);
        }
    }, [selectedSite, setSelectedSite])


    return (
        <PageCard>
        {title.length>0 && <Typography variant="h4">{title}</Typography>}
        <Stack direction="row" gap={1}>
            <Autocomplete
                options={allSites}
                getOptionLabel={(o) => o.name}
                renderInput={(params) => (
                <TextField {...params} key={`option-${params.id}`} placeholder="Sites" error={selectedSite === null} />
                )}
                sx={{ width: 450 }}
                value={selectedSite}
                onChange={(e, v) => {setSelectedSite(v)}}
            />

        </Stack>
        </PageCard>
    )
}

export default SiteQuery;
