import React from "react";
import { useParams } from "react-router-dom";
import { Api, ClientDetails, SiteDetails, SiteType, StateDetails } from "../../Generated/ExoDBAPI";
import { getRequestParams } from "../../Utils/azureAuth";
import UserContext from "../Contexts/UserContext";
import EditSiteForm from "../CreateSiteComponents/EditSiteFrom";
import LoadingWheel from "../LoadingWheel";
import TabForm from "../Templates/TabForm";
import DeleteSiteForm from "./SiteAdminForms/DeleteSiteForm";
import EditRTK from "./SiteAdminForms/EditRTK";

function SiteAdminForm({ states, clients, siteTypes }: SiteAdminForm) {
  const { user } = React.useContext(UserContext);
  const [site, setSite] = React.useState<SiteDetails>();
  let { siteId } = useParams();

  React.useEffect(() => {
    async function fetchData() {
      if (user !== undefined && siteId !== undefined) {
        const api = new Api();
        try {
          api.sites.getSiteById(siteId, await getRequestParams()).then((res) => {
            setSite(res.data);
          });
        } catch (e) {
          let errorMessage = "Unknown Error when fetching site";
          if (e instanceof Error) {
            errorMessage = `Failed to load site. ${e.message}`;
          }
          window.alert(errorMessage);
          throw e;
        }
      }
    }
    fetchData();
  }, [siteId, user]);

  function getForm() {
    if (siteId === undefined || site === undefined) {
      return <LoadingWheel />;
    }
    return (
      <>
        <div className="pageWrapper">
          <h2 className="pageHeader">{site.name}</h2>

          <TabForm
            tabs={[
              {
                tabName: "RTK",
                shouldReload: true,
                tabElement: (
                  <EditRTK siteId={siteId} siteRTKs={site.rtkUsers} states={states} defaultStateId={site.stateId} />
                ),
              },
              {
                tabName: "Edit site",
                shouldReload: true,
                tabElement: (
                  <EditSiteForm loadedStates={states} loadedClients={clients} siteTypes={siteTypes} site={site} />
                ),
              },
              {
                tabName: "Delete Site",
                shouldReload: true,
                tabElement: <DeleteSiteForm siteId={siteId} siteName={site.name} />,
              },
            ]}
          ></TabForm>
        </div>
      </>
    );
  }

  return getForm();
}

export interface SiteAdminForm {
  states: StateDetails[];
  clients: ClientDetails[];
  siteTypes: SiteType[];
}

export default SiteAdminForm;
