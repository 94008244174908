import React from 'react';
import { Pane } from 'react-leaflet';

interface PaneConfig {
  name: string;
  zIndex: number;
}

export const TooltipPaneName = 'tooltipPane';
export const PopupPaneName = 'popupPane';
export const travelCoursePaneName = 'travel-course-pane';
export const flcTravelCoursePaneName = 'flc-travel-course-pane';
export const depthsPaneName = 'depth-pane';
export const selectedDepthsPaneName = 'selected-depth-pane';
export const exoVisionPaneName = 'exo-vision-pane';

export const BASE_TILE_LAYER_Z_INDEX = 150;

// 200 is the tiles layers, shouldn't put anything below it!
// 400 is the basic overlay z-index, while 500 is `shadow`. Most element should probably be in 301 - 499
// 650 is the tool tip pane, set by the default: tooltipPane.
//   Sadly, our code will override the tooltip value, so we will need to restore it manually
export const PanesConfigs = {
  ESRI_LAYERS: { name: 'esri-layers-pane', zIndex: 320 },
  SITE_POLYGON: { name: 'site-polygon-pane', zIndex: 350 },
  FLC_TRAVEL_COURSE: { name: flcTravelCoursePaneName, zIndex: 369 },
  TRAVEL_COURSE: { name: travelCoursePaneName, zIndex: 370 },
  POLYGON: { name: 'polygon-pane', zIndex: 400 },
  PIPE: { name: 'pipe-pane', zIndex: 420 },
  USER_LAYERS_LINES: { name: 'user-layer-lines-pane', zIndex: 430 },
  CURRENT_DRAWN_LINES: { name: 'current-drawn-lines-pane', zIndex: 450 },
  TRANSMITTER: { name: 'transmitter-pane', zIndex: 470 },
  SELECTED_PIPE: { name: 'selected-pipe-pane', zIndex: 490 },
  CREATED_ALGO_PIPE: { name: 'created-algo-line', zIndex: 490 },
  DEPTH: { name: depthsPaneName, zIndex: 495 },
  SELECTED_DEPTH: { name: selectedDepthsPaneName, zIndex: 496 },
  TARGET: { name: 'target-pane', zIndex: 501 },
  EXOVISION: { name: exoVisionPaneName, zIndex: 510 }
};

function createPane(children: undefined | React.ReactNode | React.ReactNode[], config: PaneConfig) {
  return (
    <Pane name={config.name} style={{ zIndex: config.zIndex }}>
      {children}
    </Pane>
  );
}

export function PipePane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.PIPE);
}

export function PolygonPane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.POLYGON);
}

export function TransmitterPane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.TRANSMITTER);
}

export function TravelCoursePane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.TRAVEL_COURSE);
}

export function SitePolygonPane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.SITE_POLYGON);
}

export function TargetPane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.TARGET);
}

export function UserLayerLinePane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.USER_LAYERS_LINES);
}

export function CurrentDrawnLinesPane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.CURRENT_DRAWN_LINES);
}

export function EsriLayersPane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.ESRI_LAYERS);
}

export function SelectedPipePane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.SELECTED_PIPE);
}

export function FLCTravelCoursePane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.FLC_TRAVEL_COURSE);
}

export function DepthsPane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.DEPTH);
}

export function SelectedDepthsPane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.SELECTED_DEPTH);
}

export function ExoVisionPane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.EXOVISION);
}

export function CreatedAlgoPipePane({ children }: PaneProps) {
  return createPane(children, PanesConfigs.CREATED_ALGO_PIPE);
}

export interface PaneProps {
  children?: React.ReactNode | React.ReactNode[];
}
