import * as React from "react";
import * as L from "esri-leaflet";
import { useMap } from "react-leaflet";
import { TiledMapLayer } from "react-esri-leaflet";
import axios from "axios";

function EsriTileLayer({ url, maxZoom, defaultMaxNativeZoom = 16, zIndex, opacity }: EsriTileLayerProps) {
  const [maxNativeZoom, setMaxNativeZoom] = React.useState<number>();
  React.useEffect(() => {
    setMaxNativeZoom(undefined);
    axios.get(`${url}?f=json`).then((res) => {
      setMaxNativeZoom(res.data["maxLOD"]);
    });
  }, [url]);
  return (
    <TiledMapLayer
      url={url}
      maxZoom={maxZoom}
      maxNativeZoom={maxNativeZoom ?? defaultMaxNativeZoom}
      zIndex={zIndex}
      opacity={opacity}
    />
  );
}

export interface EsriTileLayerProps {
  url: string;
  maxZoom?: number;
  defaultMaxNativeZoom?: number;
  zIndex?: number;
  opacity?: number;
}

export default EsriTileLayer;
