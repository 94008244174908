import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

function AutoCompleteTextInput({
  inputProps,
  values,
  getDisplayName,
  getSearchName,
  getActualValue,
  onValuePicked,
  updateOnValueChange,
}: AutoCompleteTextInputProps) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState<AutoCompleteOptions[]>([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", function (e) {
      setActiveIndex(-1);
      setOptions([]);
    });
  }, []);

  useEffect(() => {
    if (!showOptions || searchText === "") {
      setOptions([]);
      return;
    }

    setOptions(
      values
        .filter((v) => getSearchName(v).toLowerCase().startsWith(searchText.toLowerCase()))
        .map((v, i) => {
          return {
            displayText: getDisplayName(v),
            active: i === activeIndex,
            fieldText: getActualValue(v),
          };
        })
    );
  }, [showOptions, searchText, activeIndex]);

  useEffect(() => {
    setActiveIndex(-1);
  }, [values]);

  function onValueChange() {
    if (inputRef.current !== null) {
      setSearchText(inputRef.current.value);
      setShowOptions(true);
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "ArrowDown") {
      if (activeIndex < options.length - 1) {
        setActiveIndex((oldState) => {
          return oldState + 1;
        });
      }
    } else if (e.key === "ArrowUp") {
      if (activeIndex >= 0) {
        setActiveIndex((oldState) => {
          return oldState - 1;
        });
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      setShowOptions(false);
      if (activeIndex !== -1 && inputRef.current !== null) {
        inputRef.current.value = options[activeIndex].fieldText;
        setActiveIndex(-1);
        onValuePicked(true, options[activeIndex].fieldText);
      }
      onValuePicked(false, options[activeIndex].fieldText);
    } else if (e.key === "Tab") {
      setActiveIndex(-1);
      setOptions([]);
    }
  }

  function onItemClicked(index: number) {
    if (inputRef.current !== null) {
      inputRef.current.value = options[index].fieldText;
      onValuePicked(true, options[index].fieldText);
      setActiveIndex(-1);
    }
  }

  function createAutoCompleteList() {
    return (
      <span className="autoCompleteList">
        {options.map((v, i) => {
          let className = "autoCompleteItem";
          if (v.active) {
            className = "autoCompleteItemActive";
          }
          return (
            <span className={className} key={`item${v.displayText}`} onClick={() => onItemClicked(i)}>
              {v.displayText}
            </span>
          );
        })}
      </span>
    );
  }

  return (
    <>
      <span className="autoCompleteTextWrapper" ref={containerRef}>
        <input
          type="text"
          {...inputProps}
          onChange={(e) => {
            onValueChange();
            updateOnValueChange(e.target.value);
          }}
          onFocus={() => setShowOptions(true)}
          onKeyDown={onKeyDown}
          ref={inputRef}
        />
        {createAutoCompleteList()}
      </span>
    </>
  );
}

export interface AutoCompleteTextInputProps {
  inputProps: any;
  values: any[];
  getDisplayName: (value: any) => string;
  getSearchName: (value: any) => string;
  getActualValue: (value: any) => string;
  onValuePicked: (fromList: boolean, strVal: string) => void;
  updateOnValueChange: (value: string) => void;
}

interface AutoCompleteOptions {
  displayText: string;
  fieldText: string;
  active: boolean;
}

export default AutoCompleteTextInput;
