import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { Api, SiteDetails } from "../../../Generated/ExoDBAPI";
import { getRequestParams } from "../../../Utils/azureAuth";

function LoadTiffTileLayers({ site, setLoadedLayers, loadedLayers }: LoadTiffTileLayersProps) {
  const [tiffLayers, setTiffLayers] = React.useState<CustomTileLayer[]>([]);
  React.useEffect(() => {
    async function fetchData() {
      if (site === undefined) {
        return;
      }
      setTiffLayers([]);
      const api = new Api();
      const res = await api.analysis.getTiffLayers({ plannedSite: site.name }, await getRequestParams());
      setTiffLayers(
        res.data.tileLayers.map((l) => ({
          ...l,
          opacity: 0.5,
          path: `${process.env.REACT_APP_BACKEND_HOST}/${l.path}`,
        }))
      );
    }
    fetchData();
  }, [site]);

  return (
    <Stack gap="1rem">
      <Typography variant="h5">Load Tile Layer</Typography>
      <Autocomplete
        options={tiffLayers}
        getOptionLabel={(o) => o.name}
        placeholder="Layers"
        renderInput={(params) => <TextField {...params} key={`option-${params.id}`} placeholder="Layers" />}
        sx={{ width: 500 }}
        value={loadedLayers}
        onChange={(e, newVal) => setLoadedLayers(newVal)}
        size="small"
        multiple
      />
    </Stack>
  );
}

export interface LoadTiffTileLayersProps {
  site?: SiteDetails;
  loadedLayers: CustomTileLayer[];
  setLoadedLayers: (layers: CustomTileLayer[]) => void;
}

export interface CustomTileLayer {
  name: string;
  path: string;
  opacity: number;
  esriLayer?: boolean;
}

export default LoadTiffTileLayers;
