import * as React from 'react';
import L, { PathOptions, LatLngExpression, LeafletEvent, LatLng } from 'leaflet';
import { CircleMarker, Polygon, Polyline, useMap, Marker, useMapEvents } from 'react-leaflet';
import { calculateCentroid } from '../../../Utils/mapUtils';

/*
 A polygon on a leaflet map that can be edited.
 The polygon has two flags, drawable and editable.
 If any of them are on you can move existing edges by dragging them and alt clicking a vertex will delete it.
 If the polygon is drawable ctrl clicking on the map will add a new vertex to it.
 If the polygon is editable you ctrl clicking an edge will create a new vertex on the edge.
*/
function EditableShape({
  positions,
  setPositions,
  pathOptions,
  editable,
  drawable,
  polyline = false,
  polygonName
}: EditablePolygonProps) {
  const movePolygonVertexRef = React.useRef<(e: LeafletEvent) => void>(movePolygonVertex);
  const movingVertexIndex = React.useRef<{ val: number }>({ val: -1 });
  const addPointRef = React.useRef<(e: any) => void>(addPoint);
  const ctrlDownRef = React.useRef<(e: KeyboardEvent) => void>(ctrlPressed);
  const ctrlUpRef = React.useRef<(e: KeyboardEvent) => void>(ctrlReleased);
  const mouseUpRef = React.useRef<() => void>(mouseUp);
  const updateCursorPositionRef = React.useRef<(e: any) => void>(updateCursorPosition);
  const [currentCursorPosition, setCurrentCursorPosition] = React.useState<LatLngExpression>({
    lat: 0,
    lng: 0
  });
  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const [innerPositions, setInnerPositions] = React.useState<LatLngExpression[]>([...positions]);
  const innerPositionsRef = React.useRef<{ value: LatLngExpression[] }>({ value: [] });
  const lastMoveTimestamp = React.useRef<{ value: number }>({ value: -1 });
  const setPositionsRef = React.useRef({ value: setPositions });
  const map = useMap();
  const [zoomLevel, setZoomLevel] = React.useState(map.getZoom());
  const [fontSize, setFontSize] = React.useState('12px');

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
    }
  });
  const leafletClass = polyline ? Polyline : Polygon;
  React.useEffect(() => {
    zoomLevel <= 20
      ? setFontSize('12px')
      : zoomLevel === 21
      ? setFontSize('14px')
      : setFontSize('16px');
  }, [zoomLevel]);

  React.useEffect(() => {
    innerPositionsRef.current.value = innerPositions;
    lastMoveTimestamp.current.value = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - lastMoveTimestamp.current.value > 50) {
        setPositions(() => innerPositionsRef.current.value);
      }
    }, 100);
  }, [innerPositions]);

  React.useEffect(() => {
    setPositionsRef.current.value = setPositions;
  }, [setPositions]);

  function movePolygonVertex(e: any) {
    setInnerPositions((positions) => {
      const newPositions = [...positions];
      newPositions[movingVertexIndex.current.val] = e.latlng;
      return newPositions;
    });
  }

  function addPoint(e: any) {
    if (e.originalEvent.ctrlKey) {
      setInnerPositions((positions) => {
        const newPositions = [...positions, e.latlng];
        return newPositions;
      });
    }
  }

  function ctrlPressed(e: KeyboardEvent) {
    if (e.key === 'Control') {
      setShowPreview(true);
    }
  }

  function ctrlReleased(e: KeyboardEvent) {
    if (e.key === 'Control') {
      setShowPreview(false);
    }
  }

  function updateCursorPosition(e: any) {
    setCurrentCursorPosition(e.latlng);
  }

  const center = React.useMemo(
    () => calculateCentroid(innerPositions as LatLng[]),
    [innerPositions]
  );
  const textIcon = L.divIcon({
    className: 'text-label',
    html: `<div style="color: black; font-size:${fontSize}; font-weight: bold"><span style="background: rgba(255,255,255,0.7)">${polygonName}</span></div>`,
    iconSize: [50, 40],
    iconAnchor: [0, 0]
  });
  function mouseUp() {
    /*
     When the mouse is unpressed make the map draggable again, map is not draggable while moving the circle.
     This event can't be tied to the CircleMarker because the circle can lag behind the cursor and the mouse up event might not register
    */
    map.off('mousemove', movePolygonVertexRef.current);
    map.dragging.enable();
  }

  React.useEffect(() => {
    map.on('mouseup', mouseUpRef.current);
  }, []);

  React.useEffect(() => {
    if (drawable) {
      map.addEventListener('click', addPointRef.current);
    } else {
      map.removeEventListener('click', addPointRef.current);
    }
  }, [drawable]);

  React.useEffect(() => {
    map.on('mousemove', updateCursorPositionRef.current);
    window.addEventListener('keydown', ctrlDownRef.current);
    window.addEventListener('keyup', ctrlUpRef.current);
    return () => {
      map.off('mousemove', updateCursorPositionRef.current);
      window.removeEventListener('keydown', ctrlDownRef.current);
      window.removeEventListener('keyup', ctrlUpRef.current);
      map.removeEventListener('click', addPointRef.current);
      map.off('mouseup', mouseUpRef.current);
    };
  }, []);

  return (
    <>
      {polyline ? (
        <Polyline positions={innerPositions} pathOptions={pathOptions} />
      ) : (
        <>
          <Polygon positions={innerPositions} pathOptions={pathOptions} />
          {polygonName && zoomLevel >= 20 && <Marker position={center} icon={textIcon} />}
        </>
      )}
      {(editable || drawable) &&
        positions.slice(0, polyline ? positions.length - 1 : positions.length).map((pos, i) => (
          <Polyline
            positions={[pos, positions[(i + 1) % positions.length]]}
            pathOptions={{ opacity: 0, weight: 8 }}
            eventHandlers={{
              click(e) {
                if (e.originalEvent.ctrlKey && editable) {
                  setInnerPositions((positions) => {
                    const newPositions = [...positions];
                    newPositions.splice(i + 1, 0, e.latlng);
                    return newPositions;
                  });
                }
              }
            }}
          />
        ))}
      {(editable || drawable) &&
        innerPositions.map((pos, i) => (
          <CircleMarker
            center={pos}
            radius={12}
            pathOptions={{
              fillOpacity: 0.8,
              color: '#555555' /* Dark Grey */,
              fillColor: '#dddddd' /* Light Grey */
            }}
            eventHandlers={{
              mousedown() {
                map.dragging.disable();
                movingVertexIndex.current.val = i;
                map.on('mousemove', movePolygonVertexRef.current);
              },
              click(e) {
                if (e.originalEvent.altKey) {
                  setInnerPositions((positions) => {
                    const newPositions = [...positions];
                    newPositions.splice(i, 1);
                    return newPositions;
                  });
                }
              }
            }}
          />
        ))}
      {showPreview &&
        drawable &&
        React.createElement(leafletClass as any, {
          positions: [...innerPositions, currentCursorPosition],
          pathOptions: { ...pathOptions, opacity: 0.2, fillOpacity: 0 }
        })}
    </>
  );
}

export interface EditablePolygonProps {
  positions: LatLngExpression[];
  setPositions: (setFunc: (oldVla: LatLngExpression[]) => LatLngExpression[]) => void;
  pathOptions?: PathOptions;
  editable: boolean;
  drawable: boolean;
  polyline?: boolean;
  polygonName?: string;
}

export default EditableShape;
