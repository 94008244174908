import './MeasurementOptions.css';
import L from 'leaflet';
import { UnitMeasuresProperties } from '../../../Utils/const';
import { useMap } from 'react-leaflet';
import { DisplayPipeMode } from './DisplayPipeMode';
import { useMapControlsStore } from '../store';
import { ChangeEvent } from 'react';
import { RadioGroup, Divider, Stack, Grid } from '@mui/material';
import { RadioButton, Typography } from '../../../shared';

const Settings = () => {
  const { setDislpayPipesMode, setUnitMeasures, unitMeasures, displayPipesMode } =
    useMapControlsStore();

  const map = useMap();

  const handleUnitMeaserumentChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (map) {
      setUnitMeasures(value as UnitMeasuresProperties);
      // Invalidate the map size to trigger a redraw
      map.invalidateSize();
    }
  };

  const renderMeasurement = (
    <Stack direction="column" spacing={0.5}>
      <Typography fontWeight={500}>Measreument:</Typography>
      <RadioGroup onChange={handleUnitMeaserumentChange} value={unitMeasures}>
        <RadioButton
          id={UnitMeasuresProperties.Feet}
          value="feet"
          checked={unitMeasures === UnitMeasuresProperties.Feet}
          label="Feet 0' 0"
        />
        <RadioButton
          id={UnitMeasuresProperties.Meters}
          value="meters"
          checked={unitMeasures === UnitMeasuresProperties.Meters}
          label="Meters 0.00m"
        />
      </RadioGroup>
    </Stack>
  );

  return (
    <div className="measurement-options-container">
      <Typography>Settings</Typography>
      <Divider style={{ margin: '16px 0' }} />
      <Stack direction="row" spacing={3}>
        {renderMeasurement}
        <Divider component="span" orientation="vertical" flexItem />
        <DisplayPipeMode onChange={setDislpayPipesMode} value={displayPipesMode} />
      </Stack>
    </div>
  );
};

export default Settings;
