import { ParsedTargetObect } from '../../../../Types/MappingEntities';
import { FilterCategories, Filters } from '../types';

const getDepth = (p: ParsedTargetObect) => p.target_info?.depth || 0;

export const getDepthRange = (pois: ParsedTargetObect[]) => {
  const depths = pois.map((p) => {
    const depth = getDepth(p);
    if (depth === 999) return 0;
    return depth;
  });
  return [Math.min(...depths), Math.max(...depths)];
};

export const isMatchDepth = (
  p: ParsedTargetObect,
  range: Filters[FilterCategories.depth]
): boolean => {
  if (!range) return false;
  const [min, max] = range;
  const depth = getDepth(p);
  return !!(depth >= min && depth <= max);
};
