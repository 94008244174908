import * as React from "react";
import { AddContact, ClientDetails, Contact } from "../../Generated/ExoDBAPI";
import ContactInfo from "./ContactsInfo";

function ClientInfoFormSection({
  clients,
  clientId,
  contacts,
  onClientIdChange,
  onAddContact,
  onRemoveContact,
  onUpdateContact,
  autoCompleteContacts,
}: ClientInfoFormSectionProps) {
  return (
    <>
      <div className="formSection" key="siteContactInfoEntry">
        <h4 className="fromSectionHeader">Contact Info</h4>
        <ContactInfo
          createdContacts={contacts}
          autoCompleteContacts={autoCompleteContacts}
          onCreateNewContact={onAddContact}
          onDeleteContact={onRemoveContact}
          onUpdateContact={onUpdateContact}
        />
      </div>
    </>
  );
}

export interface ClientInfoFormSectionProps {
  clients: ClientDetails[];
  contacts: Contact[];
  clientId: string;
  onClientIdChange: (val: string) => void;
  onAddContact: () => void;
  onRemoveContact: (index: number) => void;
  onUpdateContact: (value: Contact) => void;
  autoCompleteContacts: Contact[];
}

export default ClientInfoFormSection;
