import React from "react";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import "react-phone-input-2/lib/style.css";
import AutoCompleteTextInput from "../Inputs/AutoCompleteTextInput";
import { Contact } from "../../Generated/ExoDBAPI";

/**
 * Input for creting a new contact
 * @param {*} contacts the state of the contacts
 * @param {*} handleCreateNewContact a callback when a new contact is created
 * @param {*} handleDeleteContact a callback when a contact is deleted
 * @returns
 */
function ContactInfo({
  createdContacts,
  onCreateNewContact,
  onDeleteContact,
  autoCompleteContacts = [],
  onUpdateContact,
}: ContactsInfoProps) {
  React.useEffect(() => {
    const elem = document.getElementById("addContactButton");
    if (createdContacts.length > 1 && elem !== null) {
      elem.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [createdContacts]);

  function handleContactNamePicked(selectedFromList: boolean, value: string, id: string, index: number) {
    if (selectedFromList) {
      const contact = autoCompleteContacts.find((c) => c.name === value);
      if (contact === undefined) {
        return;
      }
      let tempContact = { ...contact };
      tempContact.id = id;
      onUpdateContact(tempContact);
    }
  }

  /**
   * Creates a new contact info input with name and job title
   * @param {*} index the index of the contact created
   * @param {*} id the id of the created contact
   * @returns Contact info input DOM
   */
  function createContactInfo(index: number, id: string) {
    /**
     * Function that adds a delete button if the number of contacts is bigger than one
     * @returns the delete button DOM
     */
    function deleteButton() {
      if (createdContacts.length > 1) {
        return (
          <span className="siteFormEntry">
            <span onClick={() => onDeleteContact(index)} className="deleteContactButton">
              ×
            </span>
          </span>
        );
      }
    }
    const contact = createdContacts.find((c) => c.id == id);
    if (contact === undefined) {
      return;
    }
    return (
      <div key={`contactInfo${id}`} className="contactWrapper">
        <div>
          <span className="siteFormEntry">
            <label htmlFor={`contactInfoContactName-${id}`}>Contact Name: </label>
            <AutoCompleteTextInput
              inputProps={{
                name: `contactInfoContactName-${id}`,
                id: `contactInfoContactName-${id}`,
                required: "required",
                pattern: "(?=.{2,50}$)(^([a-zA-Z]+)([ ])?)(([a-zA-Z]+[ ])*)([a-zA-Z]+)",
                className: "createSiteInput",
                defaultValue: contact.name,
              }}
              values={autoCompleteContacts}
              getDisplayName={(v) => {
                return `${v.name}, ${v.jobTitle}`;
              }}
              getSearchName={(v) => {
                return v.name;
              }}
              getActualValue={(v) => {
                return v.name;
              }}
              onValuePicked={(fromList, val) => handleContactNamePicked(fromList, val, id, index)}
              updateOnValueChange={(value) => {
                if (contact !== undefined) {
                  let tempContact = { ...contact };
                  tempContact.name = value;
                  onUpdateContact(tempContact);
                }
              }}
            />
          </span>
          <span className="siteFormEntry">
            <label htmlFor={`contactInfoContactJobTitle-${id}`}>Job title: </label>
            <input
              type="text"
              className="createSiteInput"
              name={`contactInfoContactJobTitle-${id}`}
              id={`contactInfoContactJobTitle-${id}`}
              required={true}
              pattern="(?=.{2,50}$)(^([a-zA-Z0-9]+)([ ])?)(([a-zA-Z0-9]+[ ])*)([a-zA-Z0-9]+)"
              onChange={(e) => {
                if (contact !== undefined) {
                  let tempContact = { ...contact };
                  tempContact.jobTitle = e.target.value;
                  onUpdateContact(tempContact);
                }
              }}
              defaultValue={contact.jobTitle}
            />
          </span>
          <span className="siteFormEntry">
            <label htmlFor={`contactInfoContactJobTitle-${id}`}>Phone: </label>
            <PhoneInput
              country={"il"}
              preferredCountries={["il", "us"]}
              value={contact.phone}
              onChange={(phone: string) => {
                if (contact !== undefined) {
                  let tempContact = { ...contact };
                  tempContact.phone = phone;
                  onUpdateContact(tempContact);
                }
              }}
              isValid={(inputNumber, country, countries) => {
                return countries.some((country: any) => {
                  return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                });
              }}
              inputProps={{
                name: `contactInfoContactPhone-${id}`,
                id: `contactInfoContactPhone-${id}`,
                required: true,
              }}
            />
          </span>
        </div>
        {deleteButton()}
      </div>
    );
  }
  return (
    <>
      {createdContacts.map((c, i) => {
        return createContactInfo(i, c.id);
      })}
      <span className="siteFormEntry">
        <button
          type="button"
          id="addContactButton"
          onClick={() => {
            onCreateNewContact();
          }}
          className="addContactButton"
        >
          Add contact +
        </button>
      </span>
    </>
  );
}

export interface ContactsInfoProps {
  createdContacts: Contact[];
  onCreateNewContact: () => void;
  onDeleteContact: (index: number) => void;
  autoCompleteContacts: Contact[];
  onUpdateContact: (value: Contact) => void;
}

export default ContactInfo;
