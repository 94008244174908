import React, { useState } from 'react';
import PageCard from '../Templates/PageCard';
import {
  Autocomplete,
  Button,
  Box,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Pagination,
  Divider
} from '@mui/material';
import ScanStatusNode from '../Monitoring/ScanStatusNode';
import ScanStatusContainer from '../Monitoring/ScanStatusContainer';
import MonitorCollapseSection from '../Monitoring/MonitorCollapseSection';
import {
  useGetLocationsLazyQuery,
  useGetPipelinesLazyQuery,
  useGetPreloadRunsLazyQuery,
  useGetSitesWithScansInDateRangeLazyQuery,
  useSearchScansMonitorInfoLazyQuery
} from '../../Generated/Graphql';
import {
  AnalysisStep,
  LocationInfo,
  PreloadStep,
  ScanStages,
  ScanMonitorInfo,
  ScanStep,
  SensorInfo,
  SiteInfo,
  UploadStep,
  UtilityFinderStep
} from '../Monitoring/MonitorTypes';
import PreloadStepSection from '../Monitoring/PreloadStepSection';
import PipelineSection from '../Monitoring/PipelineSection';
import { reportAllPagesResults } from '../../Utils/graphqlUtils';
import { buildMonitorInfo } from '../Monitoring/monitoringParser';
import MonitorStatusFilters from '../Monitoring/MonitorStatusFilters';
import { Dict } from '../../BasicTypes';
import AggregatedDataDisplay from '../Monitoring/AggregatedDataDisplay';
import DateRangeInput from '../Inputs/DateRangeInput';
import DailySummary from '../Monitoring/DailySummary';
import { RUN_STATUS_ORDER } from '../Monitoring/MonitorTypes';
import { FilterFunctions, Filters, ScanFilterID } from '../Monitoring/Filters';

const SCANS_PER_PAGE = 25;

function checkIfStatusMatchesFilter(filterStatuses: string[], itemStatus: string) {
  return filterStatuses.includes(itemStatus);
}

function humanizeBytes(bytesPerSeconds: number) {
  if (bytesPerSeconds < 1000) {
    return `${bytesPerSeconds}B/sec`;
  }
  if (bytesPerSeconds < 1_000_000) {
    return `${bytesPerSeconds / 1000}KB/sec`;
  }
  return `${bytesPerSeconds / 1_000_000}MB/sec`;
}

function createGeneralInfoSection(
  step: { startTime?: number; endTime?: number },
  scanId: string,
  machineId: string | undefined = undefined
) {
  return {
    name: 'General Info',
    data: (
      <Stack>
        <Stack direction="row">
          <Box>Start Time: </Box>
          <Box>{!step.startTime ? 'N/A' : new Date(step.startTime).toLocaleString()}</Box>
        </Stack>
        <Stack direction="row">
          <Box>End Time: </Box>
          <Box>{!step.endTime ? 'N/A' : new Date(step.endTime).toLocaleString()}</Box>
        </Stack>
        <Stack direction="row">
          <Box>Scan Id: </Box>
          <Box>{scanId}</Box>
        </Stack>
        {machineId !== undefined ? (
          <Stack direction="row">
            <Box>Machine ID: </Box>
            <Box>{machineId}</Box>
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
    )
  };
}

function createScanStepSections(scanStep: ScanStep, scanId: string, machineId?: string) {
  return [createGeneralInfoSection(scanStep, scanId, machineId)];
}

function createUploadStepSections(uploadStep: UploadStep, scanId: string, machineId?: string) {
  return [
    createGeneralInfoSection(uploadStep, scanId, machineId),
    {
      name: 'Upload Info',
      data: (
        <Stack>
          <Stack direction="row">
            <Box>Uploader: </Box>
            <Box>{!uploadStep.uploader ? 'N/A' : uploadStep.uploader}</Box>
          </Stack>
          <Stack direction="row">
            <Box>Upload Speed: </Box>
            <Box>{!uploadStep.uploadSpeed ? 'N/A' : humanizeBytes(uploadStep.uploadSpeed)}</Box>
          </Stack>
          <Stack direction="row">
            <Box>Last Update: </Box>
            <Box>{new Date(uploadStep.updatedAt).toLocaleString()}</Box>
          </Stack>
        </Stack>
      )
    },
    {
      name: 'S3 Info',
      data: (
        <>
          S3 Ref:{' '}
          {uploadStep.s3Ref?.startsWith('s3://') ? uploadStep.s3Ref : `s3://${uploadStep.s3Ref}`}
        </>
      )
    }
  ];
}

function createPreloadStepSections(preloadStep: PreloadStep, scanId: string, machineId?: string) {
  return [
    createGeneralInfoSection(preloadStep, scanId, machineId),
    {
      name: 'Preload Runs',
      data: <PreloadStepSection preloadRuns={preloadStep.runs} />
    }
  ];
}

function createUtilityFinderStepSections(
  utilityFinderStep: UtilityFinderStep,
  scanId: string,
  machineId?: string
) {
  return [
    createGeneralInfoSection(utilityFinderStep, scanId, machineId),
    {
      name: 'Pipelines',
      data: (
        <>
          <PipelineSection pipelines={utilityFinderStep.pipelines} />
        </>
      )
    }
  ];
}

function createAnalysisStepSections(analysisStep: AnalysisStep, scanId: string) {
  return [
    {
      name: 'Access Info',
      data: (
        <Stack>
          <Stack direction="row">
            <Box>Access Time:</Box>
            <Box>
              {!analysisStep.accessTime
                ? 'N/A'
                : new Date(analysisStep.accessTime).toLocaleString()}
            </Box>
          </Stack>
          <Stack direction="row">
            <Box>Scan Id:</Box>
            <Box>{scanId}</Box>
          </Stack>
        </Stack>
      )
    }
  ];
}

function createDisplayInfo(monitorInfo: ScanMonitorInfo): ScanDisplayInfo {
  const scanSections = createScanStepSections(
    monitorInfo.monitoringInfo.scanStep,
    monitorInfo.id,
    monitorInfo.machineId
  );
  const uploadSections = createUploadStepSections(
    monitorInfo.monitoringInfo.uploadStep,
    monitorInfo.id,
    monitorInfo.machineId
  );
  const preloadSections = createPreloadStepSections(
    monitorInfo.monitoringInfo.preloadStep,
    monitorInfo.id,
    monitorInfo.machineId
  );
  const utilityFinderSections = createUtilityFinderStepSections(
    monitorInfo.monitoringInfo.utilityFinderStep,
    monitorInfo.id
  );
  const analysisSections = createAnalysisStepSections(
    monitorInfo.monitoringInfo.analysisStep,
    monitorInfo.id
  );

  return {
    name: monitorInfo.name,
    id: monitorInfo.id,
    sensor: monitorInfo.sensor,
    scanType: monitorInfo.scanType,
    valid: monitorInfo.valid ?? false,
    stages: [
      {
        name: ScanStages.SCAN,
        stageStatus: monitorInfo.monitoringInfo.scanStep.status,
        sections: scanSections
      },
      {
        name: ScanStages.UPLOAD,
        stageStatus: monitorInfo.monitoringInfo.uploadStep.status,
        sections: uploadSections
      },
      {
        name: ScanStages.PRELOAD,
        stageStatus: monitorInfo.monitoringInfo.preloadStep.status,
        sections: preloadSections
      },
      {
        name: ScanStages.UTILITY_FINDER,
        stageStatus: monitorInfo.monitoringInfo.utilityFinderStep.status,
        sections: utilityFinderSections
      },
      {
        name: ScanStages.ANALYSIS,
        stageStatus: monitorInfo.monitoringInfo.analysisStep.status,
        sections: analysisSections
      }
    ]
  };
}

function MegaMonitor() {
  const [allSites, setAllSites] = React.useState<SiteInfo[]>([]);
  const [sitesOptions, setSitesOptions] = React.useState<SiteInfo[]>([]);
  const [sensorOptionsBySite, setSensorOptionsBySite] = React.useState<Dict<SensorInfo[]>>({});
  const [locationOptions, setLocationOptions] = React.useState<LocationInfo[]>([]);
  const [scanMonitoringInfo, setScanMonitoringInfo] = React.useState<ScanMonitorInfo[]>([]);

  const [selectedSite, setSelectedSite] = React.useState<SiteInfo | null>(null);
  const [selectedSensors, setSelectedSensors] = React.useState<SensorInfo[]>([]);
  const [selectedLocation, setSelectedLocation] = React.useState<LocationInfo | null>(null);
  const [dateRange, setDateRange] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });

  const [filterParams, setFilterParams] = React.useState<FilterParams>();
  const [clientSideFilters, setClientSideFilters] = React.useState<ClientSideFilterParams>({
    scanStageStatuses: RUN_STATUS_ORDER,
    uploadStageStatuses: RUN_STATUS_ORDER,
    preloadStageStatuses: RUN_STATUS_ORDER,
    utilityFinderStageStatuses: RUN_STATUS_ORDER,
    analysisStageStatuses: RUN_STATUS_ORDER
  });

  const relevantSensors = React.useMemo(() => {
    if (selectedSite === null) {
      const sensors = Object.values(sensorOptionsBySite).flat();
      const uniqueSensors: SensorInfo[] = [];
      sensors.forEach((sensor) => {
        if (!uniqueSensors.some((s) => s.id === sensor.id)) {
          uniqueSensors.push(sensor);
        }
      });
      return uniqueSensors;
    }

    return sensorOptionsBySite[selectedSite.id];
  }, [sensorOptionsBySite, selectedSite]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [pageNum, setPageNum] = React.useState(1);

  const [siteInputError, setSiteInputError] = React.useState(false);

  const [, locationResults] = useGetLocationsLazyQuery();
  const [, monitorInfoResults] = useSearchScansMonitorInfoLazyQuery({
    variables: filterParams
  });
  const [, preloadRunsResults] = useGetPreloadRunsLazyQuery();
  const [, pipelinesResults] = useGetPipelinesLazyQuery();
  const [, sitesInScanRangeResults] = useGetSitesWithScansInDateRangeLazyQuery({
    variables: {
      startTimeStamp: ~~((dateRange.startDate.setHours(0, 0, 0, 1) - 1) / 1000),
      endTimeStamp: ~~(dateRange.endDate.setHours(23, 59, 59, 99) / 1000)
    }
  });

  // ######### NEW CODE START (FILTERS) ########### //
  const [checkedFilters, setCheckedFilters] = useState<Record<ScanFilterID, boolean>>({
    [ScanFilterID.Target]: false,
    [ScanFilterID.Survey]: false,
    [ScanFilterID.Transmitter]: false,
    [ScanFilterID.ValidScan]: false
  });

  const filterScans = (scans: ScanDisplayInfo[], checkedFilters: Record<ScanFilterID, boolean>) => {
    const activeFilters = Object.entries(checkedFilters)
      .filter(([_, isChecked]) => isChecked)
      .map(([id, _]) => id as ScanFilterID);

    if (!activeFilters.length) return scans;
    return scans.filter((scan) => activeFilters.every((id) => FilterFunctions[id](scan)));
  };
  // ######### NEW CODE END (FILTERS)  ########### //

  const scansDisplayInfo = React.useMemo<ScanDisplayInfo[]>(() => {
    setPageNum(1);
    const scans = scanMonitoringInfo
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
      )
      .map((info) => createDisplayInfo(info))
      .filter((scan) => {
        if (
          checkIfStatusMatchesFilter(
            clientSideFilters.scanStageStatuses,
            scan.stages[0].stageStatus
          ) &&
          checkIfStatusMatchesFilter(
            clientSideFilters.uploadStageStatuses,
            scan.stages[1].stageStatus
          ) &&
          checkIfStatusMatchesFilter(
            clientSideFilters.preloadStageStatuses,
            scan.stages[2].stageStatus
          ) &&
          checkIfStatusMatchesFilter(
            clientSideFilters.utilityFinderStageStatuses,
            scan.stages[3].stageStatus
          ) &&
          checkIfStatusMatchesFilter(
            clientSideFilters.analysisStageStatuses,
            scan.stages[4].stageStatus
          )
        ) {
          return true;
        }
        return false;
      });
    return filterScans(scans, checkedFilters);
  }, [scanMonitoringInfo, clientSideFilters, checkedFilters]);

  React.useEffect(() => {
    setAllSites([]);
    reportAllPagesResults(
      sitesInScanRangeResults,
      (res) => {
        const items = res.data.searchScans?.items;
        if (!items) {
          return;
        }
        const sites = items
          .map((scan) => scan?.site)
          .map((item) =>
            item === null || item === undefined
              ? null
              : { name: item.name, id: item.id, locationId: item.locationSitesId }
          )
          .filter((item) => item !== null) as SiteInfo[];

        const sensorsBySites: Dict<SensorInfo[]> = {};
        items
          .map((item) =>
            item === null || item === undefined
              ? null
              : { id: item.sensor.id, model: item.sensor.model, siteId: item.site.id }
          )
          .filter((item) => item !== null)
          .forEach((s) => {
            const key = s?.siteId ?? '';
            if (!Object.keys(sensorsBySites).includes(key)) {
              sensorsBySites[key] = [];
            }
            sensorsBySites[key].push(s as SensorInfo);
          });

        setAllSites((val) => {
          const siteNames = val.map((s) => s.name);
          const newSitesIds = new Set<string>(sites.map((s) => s.id));
          const newSites: SiteInfo[] = [];
          newSitesIds.forEach((id) => {
            newSites.push(sites.find((site) => site.id === id) as SiteInfo);
          });
          const relevantSites = newSites.filter((s) => !siteNames.includes(s.name));
          return [...val, ...relevantSites];
        });

        setSensorOptionsBySite((val) => {
          const newDict = { ...val };
          for (const siteId of Object.keys(sensorsBySites)) {
            const senorModels = (newDict[siteId] ?? []).map((s) => s.model);
            const newSensorIds = new Set<string>(sensorsBySites[siteId].map((s) => s.id));
            const newSensors: SensorInfo[] = [];
            newSensorIds.forEach((id) => {
              newSensors.push(
                sensorsBySites[siteId].find((sensor) => sensor.id === id) as SensorInfo
              );
            });
            const relevantSensors = newSensors.filter((s) => !senorModels.includes(s.model));
            newDict[siteId] = [...(newDict[siteId] ?? []), ...relevantSensors];
          }
          return newDict;
        });
      },
      (res) => res.data.searchScans?.nextToken
    );
  }, [dateRange]);

  React.useEffect(() => {
    setTimeout(() => {
      reportAllPagesResults(
        locationResults,
        (res) => {
          const items = res.data.searchLocations?.items;
          if (!items) {
            return;
          }
          const sites = items.filter((item) => item !== null) as LocationInfo[];
          setLocationOptions((val) => [...val, ...sites]);
        },
        (res) => res.data.searchLocations?.nextToken
      );
    }, 3000);
  }, [locationResults]);

  React.useEffect(() => {
    if (filterParams === undefined) {
      return;
    }
    const sensors = selectedSensors.map((s) => s.model);
    async function fetchData() {
      setIsLoading(true);
      await reportAllPagesResults(
        monitorInfoResults,
        async (res) => {
          const scansInfo = (
            await buildMonitorInfo(res, preloadRunsResults, pipelinesResults)
          ).filter((info) => selectedSensors.length === 0 || sensors.includes(info.sensor));
          setScanMonitoringInfo((val) => [...val, ...scansInfo]);
        },
        (res) => res.data.searchScans?.nextToken
      );
      setIsLoading(false);
    }

    fetchData();
  }, [filterParams]);

  React.useEffect(() => {
    if (!selectedLocation) {
      setSitesOptions(allSites);
      return;
    }
    setSitesOptions(allSites.filter((s) => s.locationId === selectedLocation.id));
  }, [allSites, selectedLocation]);

  function updateFilterParams() {
    setScanMonitoringInfo([]);
    setSiteInputError(false);
    if (selectedSite === undefined || selectedSite === null) {
      setSiteInputError(true);
      return;
    }
    setFilterParams({
      siteId: selectedSite?.id ?? undefined,
      startTimeStamp: ~~((dateRange.startDate.setHours(0, 0, 0, 1) - 1) / 1000),
      endTimeStamp: ~~(dateRange.endDate.setHours(23, 59, 59, 99) / 1000)
    });
  }

  function onStageFilterChange(stage: ScanStages, statuses: Dict<boolean>) {
    const enabledStatuses = Object.keys(statuses).filter(
      (status) => statuses[status as string] as boolean
    );
    let key = '';
    if (stage === ScanStages.SCAN) {
      key = 'scanStageStatuses';
    } else if (stage === ScanStages.UPLOAD) {
      key = 'uploadStageStatuses';
    } else if (stage === ScanStages.PRELOAD) {
      key = 'preloadStageStatuses';
    } else if (stage === ScanStages.UTILITY_FINDER) {
      key = 'utilityFinderStageStatuses';
    } else if (stage === ScanStages.ANALYSIS) {
      key = 'analysisStageStatuses';
    }

    setClientSideFilters((filters) => {
      const coolDict: Dict<string[]> = {};
      coolDict[key] = enabledStatuses;
      const newFilters = { ...filters, ...coolDict };
      return newFilters;
    });
  }

  return (
    <Stack overflow="hidden" height="100%" direction="column">
      <PageCard>
        <Typography variant="h5" marginBottom={1}>
          Mega Monitoring
        </Typography>
        <Stack gap="1rem">
          <Stack direction="row" spacing={2}>
            <DateRangeInput dateRange={dateRange} setDateRange={setDateRange} />
            <Autocomplete
              size="small"
              options={locationOptions}
              getOptionLabel={(o) => o.state}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  key={`option-${params.id}`}
                  placeholder="States"
                />
              )}
              sx={{ width: 250 }}
              value={selectedLocation}
              onChange={(e, v) => setSelectedLocation(v)}
            />
            <Autocomplete
              size="small"
              options={sitesOptions}
              getOptionLabel={(o) => o.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  key={`option-${params.id}`}
                  placeholder="Sites"
                  error={siteInputError}
                />
              )}
              sx={{ width: 500 }}
              value={selectedSite}
              onChange={(e, v) => setSelectedSite(v)}
            />
            <Autocomplete
              size="small"
              options={relevantSensors}
              getOptionLabel={(o) => o.model}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  key={`option-${params.id}`}
                  placeholder="Sensors"
                />
              )}
              sx={{ width: 250 }}
              value={selectedSensors}
              onChange={(e, v) => setSelectedSensors(v)}
              multiple
              limitTags={1}
              disableCloseOnSelect
            />
            <Stack>
              <Button variant="contained" onClick={updateFilterParams} disabled={isLoading}>
                Filter
              </Button>
            </Stack>
          </Stack>
          <Stack overflow="auto" direction="row" spacing={1}>
            <MonitorCollapseSection header="More filters">
              <Stack paddingLeft={4}>
                <MonitorStatusFilters reportFilterChanged={onStageFilterChange} />
                <Divider sx={{ marginY: 0.5 }} />
                <Filters
                  checkedFilters={checkedFilters}
                  onChange={(id, checked) =>
                    setCheckedFilters((prevState) => ({ ...prevState, [id]: checked }))
                  }
                />
              </Stack>
            </MonitorCollapseSection>
            <MonitorCollapseSection header="Daily Summary">
              <Stack paddingLeft={4}>
                <DailySummary />
              </Stack>
            </MonitorCollapseSection>
          </Stack>
        </Stack>
      </PageCard>
      <AggregatedDataDisplay monitorInfo={scanMonitoringInfo} />
      <Stack flex={1} overflow="auto">
        {isLoading && (
          <Stack flex={1} direction="row" alignItems="center" justifyContent="center">
            <CircularProgress sx={{ alignSelf: 'center' }} />
          </Stack>
        )}
        {!isLoading &&
          scansDisplayInfo
            .slice((pageNum - 1) * SCANS_PER_PAGE, pageNum * SCANS_PER_PAGE)
            .map((scan) => (
              <ScanStatusContainer
                color={scan.scanType === 'TEST' ? '#FEFFEF' : undefined}
                header={
                  <Stack gap="0.5rem">
                    <Typography
                      variant="subtitle1"
                      color={scan.valid ? 'black' : '#EE1D1C'}
                      fontWeight="bold"
                      fontSize="1.15rem">
                      {scan.name} {scan.valid ? '' : ' -  Invalid Scan'}
                    </Typography>
                    <Typography variant="subtitle2">{scan.sensor}</Typography>
                    <Typography variant="subtitle2">{scan.scanType}</Typography>
                  </Stack>
                }
                key={scan.id}>
                {scan.stages.map((stage) => (
                  <ScanStatusNode
                    stageName={stage.name}
                    stageStatus={stage.stageStatus}
                    key={`${scan.name}-${stage.name}`}>
                    {stage.sections.map((section) => (
                      <MonitorCollapseSection
                        header={section.name}
                        key={`${scan.name}-${stage.name}-${section.name}`}>
                        {section.data}
                      </MonitorCollapseSection>
                    ))}
                  </ScanStatusNode>
                ))}
              </ScanStatusContainer>
            ))}
      </Stack>
      <Pagination
        style={{ padding: 12, boxShadow: '0px -2px 2px 0px rgba(0,0,0,0.1)', zIndex: 999 }}
        count={Math.ceil(scansDisplayInfo.length / SCANS_PER_PAGE)}
        page={pageNum}
        onChange={(_, num) => setPageNum(num)}
      />
    </Stack>
  );
}

export interface SectionInfo {
  name: string;
  data: React.ReactNode | React.ReactNode[];
}

export interface StageInfo {
  name: string;
  stageStatus: string;
  sections: SectionInfo[];
}

export interface ScanDisplayInfo {
  name: string;
  id: string;
  sensor: string;
  scanType: string;
  valid: boolean;
  stages: StageInfo[];
}

export interface FilterParams {
  siteId?: string | null;
  startTimeStamp: number;
  endTimeStamp: number;
}

export interface ClientSideFilterParams {
  scanStageStatuses: string[];
  uploadStageStatuses: string[];
  preloadStageStatuses: string[];
  utilityFinderStageStatuses: string[];
  analysisStageStatuses: string[];
}

export default MegaMonitor;
