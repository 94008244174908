import * as React from "react";
import {useMapEvents, useMap} from "react-leaflet";


function useZoomValue() {
    const map = useMap();
    const [zoomLevel, setZoomLevel] = React.useState(map.getZoom());
    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });

    return zoomLevel
}

export default useZoomValue;
