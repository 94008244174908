import { QueryResult } from "@apollo/client";
import {
  Exact,
  GetLocationsQuery,
  GetAllSitesInfoForChoosingQuery,
  GetSiteScansRefsQuery,
  GetCustomersNamesQuery,
  InputMaybe,
} from "../Generated/Graphql";
import { reportAllPagesResults } from "./graphqlUtils";

export interface SiteBaseInfo {
  id: string;
  name: string;
  siteS3Ref: string;
  customerId?: string;
  locationId?: string;
}

export interface LocationInfo {
  id: string;
  name: string;
}

export interface CustomerInfo {
  id: string;
  name: string;
}

export interface ScanInfo {
  bucket: string;
  date: string;
  polygon: string;
}

export async function parseLocations(
  getAllLocationsQuery: QueryResult<GetLocationsQuery, Exact<{ nextToken?: InputMaybe<string> | undefined }>>
) {
  const locationsInfos: LocationInfo[] = [];
  await reportAllPagesResults(
    getAllLocationsQuery,
    (res) => {
      const fetchedLocations = res.data.searchLocations?.items;
      if (fetchedLocations === undefined || fetchedLocations === null) {
        return;
      }
      const parsedLocations = fetchedLocations
        .map((location) => {
          if (location === null || location?.id === undefined || location?.state === undefined) {
            return null;
          }
          return { id: location.id, name: location.state };
        })
        .filter((location) => location !== null) as LocationInfo[];
      locationsInfos.push(...parsedLocations);
    },
    (res) => res.data.searchLocations?.nextToken
  );

  return locationsInfos;
}

export async function parseCustomers(
  getCustomersQuery: QueryResult<GetCustomersNamesQuery, Exact<{ nextToken?: InputMaybe<string> | undefined }>>
) {
  const customersInfo: CustomerInfo[] = [];
  await reportAllPagesResults(
    getCustomersQuery,
    (res) => {
      const fetchedCustomers = res.data.searchCustomers?.items;
      if (fetchedCustomers === undefined || fetchedCustomers === null) {
        return;
      }
      const parsedCustomers = fetchedCustomers
        .map((customer) => {
          if (customer === null || customer?.id === undefined || customer?.name === undefined) {
            return null;
          }
          return { id: customer.id, name: customer.name };
        })
        .filter((customer) => customer !== null) as CustomerInfo[];
      customersInfo.push(...parsedCustomers);
    },
    (res) => res.data.searchCustomers?.nextToken
  );

  return customersInfo;
}

export async function parseSites(
  getAllSitesQuery: QueryResult<GetAllSitesInfoForChoosingQuery, Exact<{ nextToken?: InputMaybe<string> | undefined }>>
) {
  const siteInfos: SiteBaseInfo[] = [];
  await reportAllPagesResults(
    getAllSitesQuery,
    (res) => {
      const fetchedSites = res.data.searchSites?.items;
      if (fetchedSites === undefined || fetchedSites === null) {
        return;
      }
      const parsedSites = fetchedSites
        .map((site) => {
          if (site === null || site?.id === undefined || site?.name === undefined) {
            return null;
          }
          return {
            id: site.id,
            name: site.name,
            siteS3Ref: site.s3_ref,
            locationId: site.locationSitesId,
            customerId: site.customerSitesId,
          };
        })
        .filter((site) => site !== null) as SiteBaseInfo[];
      siteInfos.push(...parsedSites);
    },
    (res) => res.data.searchSites?.nextToken
  );

  return siteInfos;
}

function parseS3Ref(s3Ref: string): ScanInfo | undefined {
  const [bucket] = s3Ref.split("/", 1);
  const dateMatch = s3Ref.match(/(\d{4}_\d{2}_\d{2})/);
  if (dateMatch === null) {
    return undefined;
  }

  const parts = s3Ref.split("/");
  const scanName = parts[parts.length - 1];
  const polygonMatch = scanName.match(/^p(\d+)/);
  if (polygonMatch === null) {
    return undefined;
  }

  return { bucket: bucket, date: dateMatch[1], polygon: polygonMatch[1] };
}

export async function parseScans(
  getAllScansRefsQuery: QueryResult<
    GetSiteScansRefsQuery,
    Exact<{ nextToken?: InputMaybe<string> | undefined; siteId: string }>
  >
) {
  const scansInfos: ScanInfo[] = [];
  await reportAllPagesResults(
    getAllScansRefsQuery,
    (res) => {
      const fetchedScans = res.data.getSite?.scans?.items;
      if (fetchedScans === undefined || fetchedScans === null) {
        return;
      }
      const parsedScans = fetchedScans
        .map((scan) => {
          if (scan?.s3_ref === undefined || scan?.s3_ref === null) {
            return null;
          }
          return parseS3Ref(scan.s3_ref) ?? null;
        })
        .filter((scan) => scan !== null) as ScanInfo[];
      scansInfos.push(...parsedScans);
    },
    (res) => res.data.getSite?.scans?.nextToken
  );

  return scansInfos;
}
