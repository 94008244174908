import * as React from 'react';
import {
  FeatureGroup,
  LayerGroup,
  MapContainer,
  LayersControl,
  TileLayer,
  Polygon
} from 'react-leaflet';
import L, { LatLngExpression, LeafletEventHandlerFnMap, Map } from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw';

import { getFeaturesGraphicConfig, getMapConfig, TilesLayerConfig } from '../../config/mapConfig';
import { GeoShape, GetExoMapDataResponse, UserLayerShape } from '../../Generated/ExoDBAPI';
import { MapFeatures } from './DataTypes/MapFeatures';
import MapBoundsGeoJsons from './MapBoundsGeoJsons';
import MapResizer from './MapHandlers/MapResizer';
import PipeMapEntities from './EntitiesDisplay/PipeMapEntities';
import TransmitterMapEntities from './EntitiesDisplay/TransmitterMapEntities';
import TravelCourseMapEntities from './EntitiesDisplay/TravelCourseMapEntities';
import PolygonMapEntities from './EntitiesDisplay/PolygonMapEntities';
import { ResourceInfo } from './ArcGISResources/LoadResourcesForm';

import DrawnShapeEntities from './Drawns/DrawnShapeEntities';
import {
  DrawnContext,
  getDisplayMode,
  getGeoShapeType,
  ShapeDrawningState,
  UserLayerDisplayMode
} from './Drawns/DrawnFileData';
import ExomapImage from './Exomap/ExomapImage';
import MapEventsHandler from './MapHandlers/MapEventsHandler';
import { Dict } from '../../BasicTypes';
import EsriFeatures from './EntitiesDisplay/ArcGISFeatures/EsriFeatures';
import * as ExoFuserPanes from '../../config/paneConfig';
import AuthorizedTileLayer from './MapTiles/AuthorizedTileLayer';
import ScansPolygons from './EntitiesDisplay/ScansPolygons';
import { SensorPolygonsData } from './ScansTab/ScansPolygons';
import { GeoJsonLayer, PlannedPolygonLayer } from '../../Utils/layerUtils';
import GeoJsonsCustomLayers from './EntitiesDisplay/GeoJsonsCustomLayers';
import EsriTileLayer from './EntitiesDisplay/ArcGISFeatures/EsriTileLayer';
import PlanningEntities from './EntitiesDisplay/PlanningEntities';
import { AnalysisProductsPipe, AnalysisProductsTransmitter } from './DataTypes/MapEntities';
import { SiteWideDepthData } from './DepthSupport/depthData';
import { useRef } from 'react';
import ExoTools from './MapControls/ExoTools';
import { MappingEntity } from '../../Generated/Graphql';
import ExoVisionTool from './MapControls/ExoVisionTool';
import ScansTarget from './EntitiesDisplay/ScansTarget';
import DepthDisplay from './DepthSupport/DepthDisplay';
import { ParsedTargetObect } from '../../Types/MappingEntities';
import { useMapRef } from './store/mapRef';
import UnifyDrawLine from './Tab/Unify/UnifyDrawLines';
import PolygonDrawer from './Tab/Unify/PolygonDrawer';
import PolygonMultiDrawer from './Tab/Unify/PolygonMultiDrawer';
import { useUnifyStore } from './Tab/Unify/unifyStore';
import { DisplayGroup } from './DataTypes/DisplayModes';

const HIDE_USER_LAYERS_MODES = [UserLayerDisplayMode.Commit, UserLayerDisplayMode.CacheSet];
const HIDE_CURRENT_DRAWN_MODES = [UserLayerDisplayMode.CacheOnly];

function ExoFuserMap({
  displayedFeature,
  boundingGeoJsons,
  expectedGeoJsons,
  displayPolygons,
  useColoredTravelCourses,
  setSelectedTransmitter,
  setChosenDisplayGroup,
  setPreviousDisplayGroup,
  chosenDisplayGroup,
  exomap,
  drawnContext,
  onChangePOI,
  arcGISResources,
  authToken,
  scansByPolygon,
  filterPolygon = [],
  shouldFilterArea = false,
  transmittersAndTravelCoursesChecked,
  mapEvents = {},
  extraTileLayers,
  geojsonCustomLayers,
  onGeoJsonLayerClicked,
  maxAllowedFrequency,
  scanPlanningProps,
  selectedShape,
  onUserShapeClicked,
  setSelectedPipe,
  siteWideDepthData,
  mappingEntity,
  plannedPolygons,
  setPlannedPolygons,
  transmitterMapping
}: ExoFuserMapProps) {
  const mapConfig = getMapConfig();
  const groupRef = React.useRef<L.FeatureGroup>(null);
  const mapContainerRef = React.useRef<HTMLDivElement>(null);
  const { setMapRef } = useMapRef();
  const { showUnify } = useUnifyStore();
  function setState() {
    let state = ShapeDrawningState.Uninitialized;
    if (groupRef.current !== null) {
      state =
        groupRef.current.getLayers().length === 0
          ? ShapeDrawningState.Clean
          : ShapeDrawningState.ShapeDrawn;
    }

    drawnContext.setDrawingLayer(groupRef.current);
    drawnContext.setCurrentState((oldState) => {
      if (oldState === ShapeDrawningState.Uninitialized) {
        return oldState;
      }
      return state;
    });
  }

  function removeOtherTypes(allowedType: GeoShape | undefined): void {
    if (groupRef.current === null) {
      console.log('It is beyond me how an object can be created on a non-existing layer');
      return;
    }

    // Remove all previous drawings
    const group = groupRef.current;
    const layers = group.getLayers();

    layers.forEach((layer, i) => {
      let shouldRemove = false;
      if (allowedType === undefined) {
        shouldRemove = true;
      } else {
        const featureType = getGeoShapeType(layer);
        shouldRemove = featureType !== allowedType;
      }

      if (shouldRemove) {
        group.removeLayer(layer);
      }
    });
  }

  function onCreated(e: L.DrawEvents.Created): void {
    let newType = undefined;
    if (e.layerType === 'polyline') {
      newType = GeoShape.Polyline;
    } else if (e.layerType === 'polygon') {
      newType = GeoShape.Polygon;
    } else {
      console.log('Unknown layer type:', e.layerType);
    }

    removeOtherTypes(newType);
    drawnContext.setCurrentShapeType(newType);
    setState();
  }

  function onDeleted(e: L.DrawEvents.Deleted): void {
    let shapeType = undefined;
    if (groupRef.current !== null) {
      const features = groupRef.current.getLayers();
      if (features.length > 0) {
        shapeType = getGeoShapeType(features[0]);
      }
    }
    drawnContext.setCurrentShapeType(shapeType);
    setState();
  }

  function onDrawStart(e: L.DrawEvents.DrawStart): void {
    drawnContext.changeTabToDrawn();
  }

  React.useEffect(() => {
    let clearMessage: string | undefined = undefined;

    if (drawnContext.currentState === ShapeDrawningState.ShapeSubmitted) {
      clearMessage = 'Clear shapes after submit';
    }
    if (drawnContext.currentState === ShapeDrawningState.Uninitialized) {
      clearMessage = 'Clear shapes after state reset';
    }

    if (clearMessage !== undefined) {
      groupRef.current?.clearLayers();
      setState();
    }
  }, [drawnContext.currentState]);

  const userLayersDisplayMode = React.useMemo<UserLayerDisplayMode>(
    () => getDisplayMode(drawnContext),
    [drawnContext]
  );
  const mapRef = useRef<Map>(null);

  return (
    <div className="mapContainer" ref={mapContainerRef}>
      <MapContainer
        style={{ width: '100%', height: '100%', minHeight: '400px' }}
        maxZoom={mapConfig.maxZoom}
        ref={mapRef}
        inertia
        inertiaDeceleration={2000}
        inertiaMaxSpeed={Infinity}
        worldCopyJump={false}
        maxBoundsViscosity={0.5}
        wheelDebounceTime={100}
        whenReady={() => {
          setMapRef(mapRef);
        }}>
        {[...mapConfig.tilesLayers, ...extraTileLayers].map((layer, i) => {
          if (layer.esriLayer) {
            return (
              <EsriTileLayer
                zIndex={ExoFuserPanes.BASE_TILE_LAYER_Z_INDEX + i}
                key={`tileLayer${layer.urlFormat}`}
                url={layer.urlFormat}
                maxZoom={mapConfig.maxZoom}
                opacity={layer.opacity}
              />
            );
          }
          if (layer.authorizationRequired) {
            return (
              <AuthorizedTileLayer
                zIndex={ExoFuserPanes.BASE_TILE_LAYER_Z_INDEX + i}
                key={`tileLayer${layer.urlFormat}`}
                url={layer.urlFormat}
                attribution={layer.attributions}
                minNativeZoom={layer.minNativeZoom}
                maxNativeZoom={layer.maxNativeZoom}
                maxZoom={mapConfig.maxZoom}
                authToken={authToken}
                opacity={layer.opacity}
              />
            );
          } else {
            return (
              <TileLayer
                zIndex={ExoFuserPanes.BASE_TILE_LAYER_Z_INDEX + i}
                key={`fixedTileLayer${layer.urlFormat}`}
                url={layer.urlFormat}
                attribution={layer.attributions}
                minNativeZoom={layer.minNativeZoom}
                maxNativeZoom={layer.maxNativeZoom}
                maxZoom={mapConfig.maxZoom}
                opacity={layer.opacity}
              />
            );
          }
        })}

        <LayersControl position="topright">
          <MapResizer containerRef={mapContainerRef} />
          <MapEventsHandler events={mapEvents} />
          <Polygon
            positions={filterPolygon.map((c) => [c[1], c[0]])}
            {...getFeaturesGraphicConfig().filterPolygonDisplayParams}
          />
          <LayersControl.Overlay checked={displayPolygons} name="Site Main Polygon[s]">
            <LayerGroup>
              <ExoFuserPanes.SitePolygonPane>
                <MapBoundsGeoJsons
                  geoJsons={boundingGeoJsons}
                  expectedGeoJsons={expectedGeoJsons}
                />
              </ExoFuserPanes.SitePolygonPane>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={true} name="Pipes">
            <LayerGroup>
              <ExoFuserPanes.DepthsPane />
              <ExoFuserPanes.SelectedDepthsPane />
              <ExoFuserPanes.PipePane>
                <PipeMapEntities
                  pipes={displayedFeature.pipes}
                  transmitterMapping={transmitterMapping}
                  siteWideDepthData={siteWideDepthData}
                  setSelectedPipe={setSelectedPipe}
                />
              </ExoFuserPanes.PipePane>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={transmittersAndTravelCoursesChecked} name="Transmitters">
            <LayerGroup>
              <TransmitterMapEntities
                transmitters={displayedFeature.transmitters}
                maxAllowedFrequency={maxAllowedFrequency}
                setSelectedTransmitter={setSelectedTransmitter}
                chosenDisplayGroup={chosenDisplayGroup}
                setChosenDisplayGroup={setChosenDisplayGroup}
                setPreviousDisplayGroup={setPreviousDisplayGroup}
              />
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay
            checked={transmittersAndTravelCoursesChecked}
            name="Travel Courses">
            <LayerGroup>
              <ExoFuserPanes.FLCTravelCoursePane></ExoFuserPanes.FLCTravelCoursePane>
              <ExoFuserPanes.TravelCoursePane>
                <TravelCourseMapEntities
                  courses={displayedFeature.travelCourses}
                  colored={useColoredTravelCourses}
                />
              </ExoFuserPanes.TravelCoursePane>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={true} name="Polygons">
            <LayerGroup>
              <ExoFuserPanes.PolygonPane>
                <PolygonMapEntities polygons={displayedFeature.polygons} />
              </ExoFuserPanes.PolygonPane>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={true} name="Current Drawn">
            <FeatureGroup>
              <ExoFuserPanes.CurrentDrawnLinesPane>
                {!HIDE_CURRENT_DRAWN_MODES.includes(userLayersDisplayMode) && (
                  <DrawnShapeEntities
                    shapes={drawnContext.allDrawnShapes}
                    selectedShape={selectedShape}
                    handleShapeClicked={onUserShapeClicked}
                    drawnContext={drawnContext}
                  />
                )}
              </ExoFuserPanes.CurrentDrawnLinesPane>
            </FeatureGroup>
          </LayersControl.Overlay>
          <EsriFeatures arcGISResources={arcGISResources} />
          <ExoFuserPanes.UserLayerLinePane>
            <LayersControl.Overlay checked={false} name={'User Layers'}>
              <FeatureGroup>
                {!HIDE_USER_LAYERS_MODES.includes(userLayersDisplayMode) &&
                  drawnContext.displayedLayers.map((layer) => {
                    return (
                      <DrawnShapeEntities
                        shapes={layer.shapes}
                        selectedShape={selectedShape}
                        handleShapeClicked={onUserShapeClicked}
                        drawnContext={drawnContext}
                        key={layer.name}
                      />
                    );
                  })}
              </FeatureGroup>
            </LayersControl.Overlay>
          </ExoFuserPanes.UserLayerLinePane>
          <LayersControl.Overlay
            checked={scanPlanningProps.initialShowPlanningLayer}
            name="Planning">
            <LayerGroup>
              <PlanningEntities
                beingDrawnPlannedPolygon={scanPlanningProps.beingDrawnPlannedPolygon}
                setDrawnPolygonPositions={scanPlanningProps.setBeingDrawnPolygon}
                plannedPolygons={scanPlanningProps.plannedPolygons}
                setPlannedPolygonPositions={scanPlanningProps.setPlannedPolygonPositions}
                transmitterActions={{ ...scanPlanningProps.plannedTransmittersReporters }}
              />
            </LayerGroup>
          </LayersControl.Overlay>
          <>
            {exomap !== undefined && (
              <LayersControl.Overlay checked={true} name="exomap">
                <ExomapImage exomap={exomap} />
              </LayersControl.Overlay>
            )}
          </>
          <LayersControl.Overlay checked={true} name="Uploaded Layers">
            <LayerGroup>
              <GeoJsonsCustomLayers
                layers={geojsonCustomLayers}
                onLayerClicked={onGeoJsonLayerClicked}
              />
            </LayerGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay checked={true} name="GIS Tools">
            <LayerGroup>
              <ExoTools
                mappingEntities={mappingEntity}
                siteId={siteWideDepthData.graphqlSiteId}
                mapContainerRef={mapContainerRef}
                displayedEntitiesIds={displayedFeature.pipes.map((p) => p.header.id)}
              />
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={true} name="ExoVision Tools">
            <LayerGroup>
              <ExoVisionTool
                onGeoJsonLayerClicked={onGeoJsonLayerClicked}
                plannedPolygons={plannedPolygons}
                setPlannedPolygons={setPlannedPolygons}
              />
            </LayerGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay checked={true} name="Targets">
            <LayerGroup>
              <ExoFuserPanes.TargetPane>
                <ScansTarget onChangePOI={onChangePOI} siteId={siteWideDepthData.graphqlSiteId} />
              </ExoFuserPanes.TargetPane>
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>

        <ScansPolygons sensorScans={scansByPolygon} />

        <FeatureGroup ref={groupRef}>
          <EditControl
            draw={{
              polyline: {
                shapeOptions: {
                  color: 'rgb(0, 255, 255)'
                }
              },
              polygon: false,
              circle: false,
              circlemarker: false,
              marker: false,
              rectangle: false
            }}
            position="bottomright"
            onDrawStart={onDrawStart}
            onCreated={onCreated}
            onDeleted={onDeleted}
          />
        </FeatureGroup>
        <DepthDisplay siteId={siteWideDepthData.graphqlSiteId} />
        {showUnify && <PolygonDrawer travelCourses={displayedFeature.travelCourses} />}
      </MapContainer>
    </div>
  );
}

export interface ExoFuserMapProps {
  displayedFeature: MapFeatures;
  boundingGeoJsons: any[];
  expectedGeoJsons: number;
  displayPolygons: boolean;
  useColoredTravelCourses: boolean;
  exomap?: GetExoMapDataResponse;
  drawnContext: DrawnContext;
  selectedShape?: UserLayerShape;
  onUserShapeClicked: (shape: UserLayerShape) => void;
  authToken: string;
  setSelectedPipe: (pipe: AnalysisProductsPipe) => void;
  setSelectedTransmitter: (transmitter: AnalysisProductsTransmitter) => void;
  setChosenDisplayGroup: (displayGroup: DisplayGroup) => void;
  chosenDisplayGroup: DisplayGroup;
  setPreviousDisplayGroup: (displayGroup: DisplayGroup) => void;
  onChangePOI: (poi: ParsedTargetObect) => void;
  arcGISResources: ResourceInfo[];
  scansByPolygon: Dict<SensorPolygonsData>;
  filterPolygon?: [number, number][];
  shouldFilterArea?: boolean;
  mapEvents?: LeafletEventHandlerFnMap;
  extraTileLayers: TilesLayerConfig[];
  geojsonCustomLayers: GeoJsonLayer[];
  onGeoJsonLayerClicked: (layerId: string) => void;
  maxAllowedFrequency?: number;
  scanPlanningProps: ScanPlanningProps;
  siteWideDepthData: SiteWideDepthData;
  mappingEntity: MappingEntity[];
  plannedPolygons: GeoJsonLayer[];
  transmittersAndTravelCoursesChecked: boolean;
  transmitterMapping: Dict<AnalysisProductsTransmitter[]>;
  setPlannedPolygons: (
    polygons: GeoJsonLayer[] | ((polygons: GeoJsonLayer[]) => GeoJsonLayer[])
  ) => void;
}

export interface PlannedTransmittersReporters {
  addTransmitter: (coords: LatLngExpression) => void;
  removeTransmitter: (index: number) => void;
  changeTransmitter: (index: number, coords: LatLngExpression) => void;
}

export interface ScanPlanningProps {
  beingDrawnPlannedPolygon?: LatLngExpression[];
  setBeingDrawnPolygon: (setFunc: (oldVla: LatLngExpression[]) => LatLngExpression[]) => void;
  plannedPolygons: PlannedPolygonLayer[];
  setPlannedPolygonPositions: ((
    updateFunc: (positions: LatLngExpression[]) => LatLngExpression[]
  ) => void)[];
  plannedTransmittersReporters: PlannedTransmittersReporters;
  initialShowPlanningLayer: boolean;
}

export default ExoFuserMap;
