export function generateSquareLinesSVG(color: string): string {
  const svgContent = `
        <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <line x1="0" y1="0" x2="36" y2="0" stroke="${color}" stroke-width="8" />
            <line x1="36" y1="0" x2="36" y2="36" stroke="${color}" stroke-width="8" />
            <line x1="36" y1="36" x2="0" y2="36" stroke="${color}" stroke-width="8" />
            <line x1="0" y1="36" x2="0" y2="0" stroke="${color}" stroke-width="8" />
        </svg>
    `;
  return svgContent;
}
