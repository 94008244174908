import {LatLng, LatLngExpression, LatLngTuple} from "leaflet";

export function getAngle(latLng1: LatLngTuple, latlng2: LatLngTuple) {
    const dy = latlng2[0] - latLng1[0];
    const dx = Math.cos(Math.PI / 180 * latLng1[0]) * (latlng2[1] - latLng1[1]);
    const ang = -((Math.atan2(dy, dx) / Math.PI) * 180);
    return parseFloat((ang).toFixed(2));
}

export function isLatLngTuple(val: LatLngExpression): val is LatLngTuple {
    return (val as LatLng).lat === undefined;
}

export function formatPoints(points: LatLngExpression[]): LatLngTuple[] {
    return points.map((point) => {
        if (isLatLngTuple(point)) {
            return point;
        }

        return [point.lat, point.lng];
    })
}
