import * as React from "react";
import { Stack } from "@mui/material";
import { groupBy } from "../../Utils/groupBy";
import PageCard from "../Templates/PageCard";
import MonitorCollapseSection from "./MonitorCollapseSection";

function StatusSections<T extends StatusElement>({ name, items, createItemDisplay }: StatusSectionsProps<T>) {
  function createDisplay() {
    const itemsByStatus = groupBy(items, (p) => p.status);
    return (
      <>
        {createDisplayForStatusGroup(itemsByStatus["RUNNING"], "RUNNING")}
        {createDisplayForStatusGroup(itemsByStatus["DONE"], "DONE")}
        {createDisplayForStatusGroup(itemsByStatus["FAILED"], "FAILED")}
        {createDisplayForStatusGroup(itemsByStatus["SCHEDULED"], "SCHEDULED")}
      </>
    );
  }

  function createDisplayForStatusGroup(items: T[] | undefined, status: string) {
    if (items === undefined || items.length === 0) {
      return <></>;
    }
    return (
      <PageCard>
        <MonitorCollapseSection header={status} variant="subtitle1">
          <hr />
          <Stack>{items.map((item) => createItemDisplay(item))}</Stack>
        </MonitorCollapseSection>
      </PageCard>
    );
  }

  return (
    <>
      <Stack>
        <MonitorCollapseSection header={name} variant="subtitle1">
          {createDisplay()}
        </MonitorCollapseSection>
      </Stack>
    </>
  );
}

export interface StatusSectionsProps<T extends StatusElement> {
  name: string;
  items: T[];
  createItemDisplay: (item: T) => React.ReactNode;
}

export interface StatusElement {
  status: string;
}

export default StatusSections;
