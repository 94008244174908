import * as React from "react";
import { format, parse } from "date-fns";
import { Dict } from "../../BasicTypes";
import { Api, PipelineStatus } from "../../Generated/ExoDBAPI";
import { getRequestParams } from "../../Utils/azureAuth";
import { splitTasks } from "../../Utils/splitTasks";
import UserContext from "../Contexts/UserContext";
import Pipeline from "../PipelineDashboard/Pipeline";
import AggregatorCard from "../PipelineDashboard/AggregatorCards";
import { Button, FormControl } from "@mui/material";
import { Stack } from "@mui/system";
import AutoCompleteField from "../Templates/AutoCompleteField";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DateRangeInput from "../Inputs/DateRangeInput";

const REFRESH_RATE_MILLISECONDS = 1000;
const MAX_CONCURRENCY = 10;
const PIPELINE_DATE_FORMAT = "yyyy_MM_dd";

function PipelineDashboard() {
  const [pipelines, setPipelines] = React.useState<Dict<PipelineStatus>>({});
  const [refreshTrigger, setRefreshTrigger] = React.useState(true);

  const [tags, setTags] = React.useState<string[]>([]);
  const [sites, setSites] = React.useState<string[]>([]);

  const [pickedTag, setPickedTag] = React.useState<string>();
  const [pickedSite, setPickedSite] = React.useState<string>();
  const [showDateSelect, setShowDateSelect] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const pollingTag = React.useRef<string>();
  const pollingSite = React.useRef<string>();
  const pollingDateStart = React.useRef<string>();
  const pollingDateEnd = React.useRef<string>();

  const { user } = React.useContext(UserContext);

  React.useEffect(() => {
    async function fetchData() {
      if (user !== undefined) {
        const api = new Api();
        api.pipelines.getPipelinesTags(await getRequestParams()).then((res) => setTags(res.data.tags));
      }
    }
    fetchData();
  }, [user]);

  React.useEffect(() => {
    async function fetchData() {
      setSites([]);
      if (user !== undefined && pickedTag !== undefined) {
        const api = new Api();
        api.pipelines.getPipelinesSites(pickedTag, await getRequestParams()).then((res) => {
          const relevantSites = res.data.sites.filter((site) => {
            const dates = site.dates.map((date) => parse(date, PIPELINE_DATE_FORMAT, new Date()));
            for (const date of dates) {
              if (date >= dateRange.startDate && date <= dateRange.endDate) {
                return true;
              }
            }
            return false;
          });
          setPickedSite(relevantSites[0]?.name);
          setSites(relevantSites.map((s) => s.name));
        });
      }
    }

    fetchData();
  }, [pickedTag, dateRange]);

  React.useEffect(() => {
    async function fetchData() {
      if (user !== undefined) {
        try {
          if (pollingTag.current !== undefined && pollingSite.current !== undefined) {
            const tagName = pollingTag.current;
            const siteName = pollingSite.current;
            const scanDateStart = pollingDateStart.current;
            const scanDateEnd = pollingDateEnd.current;

            const api = new Api();

            const ids = (
              await api.pipelines.getPipelinesIds(
                {
                  siteName,
                  tagName,
                  scanDateStart: scanDateStart,
                  scanDateEnd: scanDateEnd,
                },
                await getRequestParams()
              )
            ).data.ids;
            const tasks = [];
            for (const id of ids) {
              tasks.push(async () => {
                const data = (await api.pipelines.getPipelineData(encodeURIComponent(id), await getRequestParams()))
                  .data;
                setPipelines((oldState) => {
                  if (
                    tagName === pollingTag.current &&
                    siteName === pollingSite.current &&
                    scanDateStart === pollingDateStart.current &&
                    scanDateEnd === pollingDateEnd.current
                  ) {
                    const newState = { ...oldState };
                    newState[id] = data;
                    return newState;
                  }
                  return oldState;
                });
              });
            }

            splitTasks(tasks, MAX_CONCURRENCY);
          }
        } finally {
          setTimeout(() => setRefreshTrigger((r) => !r), REFRESH_RATE_MILLISECONDS);
        }
      }
    }
    fetchData();
  }, [user, refreshTrigger]);

  function changedCompletedPipelineSteps(pipelines: PipelineStatus[]) {
    for (const pipeline of Object.values(pipelines)) {
      if (pipeline.state === "COMPLETED") {
        pipeline.currentStep = pipeline.steps.length + 1;
      }
    }
    return pipelines;
  }

  function aggregatePipelines(pipelines: PipelineStatus[], attrGetter: (p: PipelineStatus) => string) {
    const res: aggregatedPipelines[] = [];
    for (const pipeline of pipelines) {
      const entry = res.find((e) => e.groupName === attrGetter(pipeline));
      if (entry === undefined) {
        res.push({ groupName: attrGetter(pipeline), pipelines: [pipeline] });
      } else {
        entry.pipelines.push(pipeline);
      }
    }

    res.sort((a, b) => (a.groupName > b.groupName ? 1 : -1));
    return res;
  }

  function aggregatePipelinesToDomain(pipelines: PipelineStatus[]) {
    const res = aggregatePipelines(pipelines, (p: PipelineStatus) => p.domain);

    return res;
  }

  function aggregatePipelinesToScans(pipelines: PipelineStatus[]) {
    const res = aggregatePipelines(pipelines, (p: PipelineStatus) => p.scan);

    for (const entry of res) {
      entry.pipelines.sort((a, b) => a.creationTimestamp - b.creationTimestamp);
    }
    return res;
  }

  function startPolling(e: React.FormEvent) {
    e.preventDefault();
    pollingTag.current = pickedTag;
    pollingSite.current = pickedSite;
    pollingDateStart.current = format(dateRange.startDate, PIPELINE_DATE_FORMAT);
    pollingDateEnd.current = format(dateRange.endDate, PIPELINE_DATE_FORMAT);
    setPipelines({});
  }

  return (
    <div className="pageWrapper">
      <div className="siteHeader">
        <h2>Pipelines</h2>
      </div>
      <FormControl>
        <Stack direction="row" gap="1rem">
          <span>
            <AutoCompleteField<string>
              key="tags"
              placeholder="Tag"
              options={tags}
              setSelectedOption={setPickedTag}
              selectedOption={pickedTag}
              alwaysChoose={true}
              autoCompleteProps={{ sx: { width: "20rem" }, disableClearable: true }}
            />
          </span>
          <span>
            <AutoCompleteField<string>
              key="sites"
              placeholder="Site"
              options={sites}
              setSelectedOption={setPickedSite}
              selectedOption={pickedSite}
              alwaysChoose={true}
              autoCompleteProps={{ sx: { width: "20rem" }, disableClearable: true }}
            />
          </span>
          <DateRangeInput dateRange={dateRange} setDateRange={setDateRange} />
          <Button className="formButton" onClick={startPolling} variant="outlined">
            Show pipelines
          </Button>
        </Stack>
      </FormControl>

      {aggregatePipelinesToDomain(changedCompletedPipelineSteps(Object.values(pipelines))).map((e) => {
        return (
          <AggregatorCard header={<h2>{e.groupName}</h2>} key={`pipeline_dashboard_domain_${e.groupName}`}>
            {aggregatePipelinesToScans(e.pipelines).map((g, i) => (
              <AggregatorCard
                header={<h2>{g.groupName}</h2>}
                wrapperClass="sensorGroup"
                key={`pipeline_dashboard_scan_${g.groupName}`}
              >
                {g.pipelines.map((p) => (
                  <Pipeline
                    name={p.name}
                    activeStep={p.currentStep}
                    steps={p.steps}
                    sensor={p.sensor}
                    error={p.state === "ERROR"}
                    imageUrl={p.imageUrl}
                    idPrefix={`${p.scan}-${i}`}
                    key={`pipeline_${p.scan}-${p.name}-${p.domain}`}
                  />
                ))}
              </AggregatorCard>
            ))}
          </AggregatorCard>
        );
      })}
    </div>
  );
}

export interface aggregatedPipelines {
  groupName: string;
  pipelines: PipelineStatus[];
}

export default PipelineDashboard;
