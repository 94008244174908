import { Link } from "react-router-dom";
import InfoForm, { FormField } from "../Templates/InfoForm";

function S3Form({ formFields, siteId }: S3FormProps) {
  return (
    <>
      <InfoForm formFields={formFields} />
      <div className="addSiteDataContainer">
        <Link to={`/addSiteData/${siteId}`}>
          <button className="formButton">Add Site Data</button>
        </Link>

        <Link to={`/uploadBackgroundImage/${siteId}`}>
          <button className="formButton">Upload analyzer background image</button>
        </Link>

        <Link to={`/uploadTif/${siteId}`}>
          <button className="formButton">Upload fuser background image</button>
        </Link>

        <Link to={`/uploadScanData/${siteId}`}>
          <button className="formButton">Upload scan data</button>
        </Link>
      </div>
    </>
  );
}

export interface S3FormProps {
  formFields: FormField[];
  siteId: string;
}

export default S3Form;
