import { Stack, Tooltip } from "@mui/material";
import * as React from "react";
import { Box } from "@mui/material";
import { AnalysisProductsTravelCourse } from "../../DataTypes/MapEntities";
import UnifyData from "./UnifyData";
import { useEffect } from "react";
import CropIcon from '@mui/icons-material/Crop';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useUnifyStore } from "./unifyStore";
import UnifyStatus from "./UnifyStatus";

function UnifyTab({
  travelCourses,
  siteId,
}: UnifyTabProps) {
  const [travelFiltered, setTravelFiltered] = React.useState<AnalysisProductsTravelCourse[]>([]);
  const { showUnify, setShowUnify, clearAll } = useUnifyStore();
  useEffect(() => {
    setShowUnify(false);
    clearAll();
  }, [siteId]);

  useEffect(() => {
    setTravelFiltered(travelCourses.filter(course => course.header.scan?.polygon && course.header.scan?.polygon > 0 &&
      course.header.scan?.snake && course.header.scan?.snake > 0 &&
      course.header.scan?.part && course.header.scan?.part > 0 &&
      !course.header.scan.name.startsWith('unified')));
  }, [travelCourses]);


  const onClickUnify = () => {
    setShowUnify(true)
  }

  const onCancelUnify = () => {
    setShowUnify(false)
  }


  return (
    <Stack direction="row">
        {siteId &&
        <>
          <Box sx={{ width: 300, display: 'flex', flexDirection: 'column'}}>
            <Tooltip title="Start drawing Unify/Crop polygons">
            <CropIcon onClick={onClickUnify}
              sx={{ fontSize: 60, backgroundColor: showUnify ? 'gray': 'blue', color: 'white', padding: '8px', marginTop: '16px' }} />
              </Tooltip>
            <Tooltip title="Stop drawing">
            <CancelPresentationIcon onClick={onCancelUnify}
              sx={{ fontSize: 60, backgroundColor: showUnify ? 'blue': 'gray', color: 'white', padding: '8px', marginTop: '16px' }} />
              </Tooltip>
          </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '8px', marginTop: '16px', width: '100%'}}>
              <UnifyData travelFiltered={travelFiltered} siteId={siteId} />
        </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '8px', marginTop: '16px', width: '700px'}}>
        Task Statuses:
        <UnifyStatus siteId={siteId} />
      </Box>
      </>}
    </Stack>
  );
}

export interface UnifyTabProps {
  travelCourses: AnalysisProductsTravelCourse[];
  siteId: string | undefined;
}

export default UnifyTab;
