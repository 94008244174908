import * as React from "react";

function RTKCard({
  name,
  username,
  password,
  mountPoint,
  hostname,
  port,
  sendGGA,
  publish,
  index,
  handelDelete,
}: RTKCardProps) {
  function publishText() {
    if (publish) {
      return "User will be published";
    }
    return "User will not be published";
  }

  function ggeText() {
    if (sendGGA) {
      return "Sends gga";
    }
    return "Doesn't send gga";
  }

  return (
    <span className="rtkCard" key={`$rtkcard-${index}`}>
      <header className="flexHeader">
        <b>{name}</b>
        <img src="/delete.png" className="rtkDelete opacityHoverable" onClick={() => handelDelete(index)} />
      </header>
      <span className="cardEntry">
        Username:
        <br />
        {username}
      </span>
      <span className="cardEntry">
        Password:
        <br />
        {password}
      </span>
      <span className="cardEntry">
        Mount point:
        <br />
        {mountPoint}
      </span>
      <span className="cardEntry">
        Hostname:
        <br />
        {hostname}
      </span>
      <span className="cardEntry">
        Port:
        <br />
        {port}
      </span>
      <span className="cardEntry">{ggeText()}</span>
      <span className="cardEntry">{publishText()}</span>
    </span>
  );
}

export interface RTKCardProps {
  name: string;
  username: string;
  password: string;
  mountPoint: string;
  hostname: string;
  port: number;
  sendGGA: boolean;
  publish: boolean;
  index: number;
  handelDelete: (index: number) => void;
}

export default RTKCard;
