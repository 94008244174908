import * as React from "react";
import { Button } from "@mui/material";
import { DrawnContext, MarkingLineTypeChoices } from "../Drawns/DrawnFileData";
import { GeoShape, UserLayerAttributes } from "../../../Generated/ExoDBAPI";
import PropertiesModal from "../Drawns/PropertiesModal";
import { AnalysisProductsPipe } from "../DataTypes/MapEntities";

function getLatsAndLonsFromGeoJson(geojson: any) {
  const coordinates = geojson.geometry.coordinates;
  return {
    lats: coordinates.map((c: any) => c[1]),
    lons: coordinates.map((c: any) => c[0]),
  };
}

function CreateDrawnShape({ pipe, drawnContext }: CreateDrawnShapeProps) {
  const [showModal, setShowModal] = React.useState(false);
  const averageDepth = React.useMemo(() => {
    if (pipe === undefined) {
      return undefined;
    }
    const depths = pipe.depths;

    if (depths !== undefined && depths.length > 0) {
      return depths.reduce((a, b) => a + b, 0) / depths.length;
    }
    return undefined;
  }, [pipe]);
  function createShape(attrs: UserLayerAttributes, name: string) {
    if (pipe === undefined) {
      return;
    }
    const { lats, lons } = getLatsAndLonsFromGeoJson(JSON.parse(pipe.header.geoJson));
    drawnContext.setAllDrawnShapes((shapes) => [
      ...shapes,
      {
        name: name,
        lats: lats,
        lons: lons,
        timestamp: Math.floor(new Date().getTime() / 1000),
        markingType: attrs.QL ?? MarkingLineTypeChoices[0],
        shapeType: GeoShape.Polyline,
        layerAttributes: attrs,
      },
    ]);
    setShowModal(false);
  }

  return (
    <>
      <Button onClick={() => setShowModal(true)} variant="outlined" disabled={pipe === undefined}>
        Create Shape
      </Button>
      {showModal && (
        <PropertiesModal
          QLOptions={MarkingLineTypeChoices}
          defaultName={pipe?.header.name ?? ""}
          defaultQL={MarkingLineTypeChoices[0]}
          defaultDepth={averageDepth}
          onCancel={() => setShowModal(false)}
          onConfirm={createShape}
        />
      )}
    </>
  );
}

export interface CreateDrawnShapeProps {
  pipe?: AnalysisProductsPipe;
  drawnContext: DrawnContext;
}

export default CreateDrawnShape;
