import { Radio, RadioProps, FormControlLabel, FormControlLabelProps } from '@mui/material';

export const RadioButton = ({
  size = 'small',
  ...props
}: Omit<FormControlLabelProps, 'control'> & Pick<RadioProps, 'size'>) => {
  return (
    <FormControlLabel
      sx={{ whiteSpace: 'nowrap' }}
      componentsProps={{ typography: { fontSize: 14 } }}
      {...props}
      control={<Radio size={size} />}
    />
  );
};
