import { Divider, Stack, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { Typography } from '../../../../../shared';

export const TabSection = ({
  title,
  children,
  sx,
  dataInfo
}: {
  title: string;
  children: ReactNode;
  sx?: SxProps;
  dataInfo?: ReactNode;
}) => {
  return (
    <Stack sx={sx}>
      <Typography variant="body1">
        {title} {dataInfo}
      </Typography>
      <Divider flexItem component="span" sx={{ my: 0.6 }} />
      {children}
    </Stack>
  );
};
