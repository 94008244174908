export const TYPE_HUMAN = 'HUMAN';
export const SCAN_SENSOR_GRADIOMETRY = 'GRADIOMETRY_MINILEMON';
export const MAX_ITEMS_DATA_SOURCE = 3;
export const MIN_ITEMS_DATA_SOURCE = 1;

export enum TargetType {
  MANHOLE = 1,
  CONTROL_POINT = 2,
  MAP_TARGET = 3,
  GEOPHONE = 4,
  BEACON = 5,
  PIPE = 6,
  TERRA_PINPOINT = 7,
  ELECTRODE = 8,
  SURFACE_REFERENCE = 9,
  UNKNOWN = 0
}
export type TargetCompanyListType = {
  [key: string]: string[];
};

export const TargetCompanyList: TargetCompanyListType = {
  USA: ['BELL SYSTEM', 'conEdison'],
  ISRAEL: ['Partner', 'Cellcom', 'HOT', 'Bezeq', 'IEC', 'Mekorot']
};
export const TargetCompanyColors = [
  '#33FFFF',
  '#E933FF',
  '#FF3333',
  '##0066cc',
  '#FFF',
  '#FFF',
  '#FFF'
];
export const TargetOtherCompany = ['Unknown', 'Other'];
export const FlowDirection = ['Inflow', 'Outflow', 'No Flow'];
export const manholeCoverShapes = ['Circle', 'Rectangle', 'Square', 'Triangular'];
export const manholeCoverMaterials = ['Light steel', 'Heavy steel', 'Concrete', 'Clay', 'Other'];
export enum POIEntities {
  Manhole = 'manhole',
  Hydrant = 'hydrant',
  Valve = 'valve',
  Electricity = 'electricity',
  Cathodic = 'cathodic',
  ManholeLite = 'manhole_lite',
  ControlPoint = 'control_point',
  DroneTarget = 'drone_target',
  PTrap = 'P-trap',
  Clamp = 'clamp',
  Induction = 'induction',
  Injection = 'injection',
  Other = 'other'
}
export enum ManholeOpenedStatus {
  Blocked = 'Blocked',
  NotOpened = "Didn't open",
  Opened = 'Opened'
}

export enum TargetUtilityTypes {
  Electricity = 'Electricity',
  Lighting = 'Lighting',
  TrafficControl = 'Traffic Control',
  Communication = 'Communication',
  FiberOptic = 'Fiber Optic',
  Water = 'Water',
  Irrigation = 'Irrigation',
  Gas = 'Gas',
  Fuel = 'Fuel',
  Sewer = 'Sewer',
  Drain = 'Drain',
  Other = 'Other'
}

export const CART_HEIGHT = 108;
