import React from 'react';
import { Button } from '@mui/material';
import { AnalysisProductsPipe } from '../DataTypes/MapEntities';
import { getDateStr } from '../../../Utils/dateUtils';
import { Confidence } from '../../../Generated/ExoDBAPI';

const SECONDS_IN_A_DAY = 24 * 60 * 60;
export default function GroupOfPipesToPipesQA({ pipeGroup }: GoToPipesQA) {
  const [minScanDate, maxScanDate] = React.useMemo(() => {
    if (pipeGroup.length === 0) {
      return [undefined, undefined];
    }
    return ['2001-01-01', getDateStr(Date.now() / 1000 + SECONDS_IN_A_DAY)];
  }, [pipeGroup]);
  function redirectToPipesQA() {
    const pipesQAUrl = process.env.REACT_APP_PIPES_QA_URL;
    if (pipesQAUrl !== undefined) {
      const sortedPipeGroup = pipeGroup.sort((a, b) => {
        if (a?.autoConfidence === b?.autoConfidence) return 0;
        const confidenceOrder = [
          Confidence.Highest,
          Confidence.High,
          Confidence.Medium,
          Confidence.Low,
          Confidence.Lowest,
          Confidence.None
        ];
        const autoConfidenceA = confidenceOrder.indexOf(a?.autoConfidence || Confidence.None);
        const autoConfidenceB = confidenceOrder.indexOf(b?.autoConfidence || Confidence.None);
        return autoConfidenceA - autoConfidenceB;
      });
      const ids = sortedPipeGroup.map((p) => p.header.id).filter(Boolean);
      window.open(
        new URL(
          `main?analyzedEntitiesIds=${ids.join('%2C')}&currentId=${
            ids[0]
          }&startDate=${minScanDate}&endDate=${maxScanDate}&interpolationType=y-amplitude-1_None`,
          pipesQAUrl
        ).toString()
      );
    }
  }
  return (
    <Button
      variant="outlined"
      className="selectButton"
      disabled={pipeGroup.length === 0}
      onClick={redirectToPipesQA}>
      See group of pipes in pipesqa
    </Button>
  );
}

export interface GoToPipesQA {
  pipeGroup: AnalysisProductsPipe[];
}
