import { create } from 'zustand';
import {
  AnalysisProductsPipe,
  AnalysisProductsTransmitter,
  AnalysisProductsTravelCourse
} from '../DataTypes/MapEntities';

type MapEntitiesStore = {
  selectedPipe?: AnalysisProductsPipe;
  selectedPipeTravelCourse: AnalysisProductsTravelCourse[];
  invalidatedPipes: string[];
  showPipes: boolean;
  showPipeTransmitters: boolean;

  setInvalidatedPipe: any;
  setShowPipeTransmitters: (show: boolean) => void;
  setShowPipes: (show: boolean) => void;
  setSelectedPipe: any;
  selectedPipeScanTransmitters: AnalysisProductsTransmitter[];
  setSelectedPipeScanTransmitters: any;
  setSelectedPipeTravelCourse: any;
};

export const useMapPipeEntitiesStore = create<MapEntitiesStore>((set) => ({
  showPipes: true,
  showPipeTransmitters: false,
  selectedPipe: undefined,
  selectedPipeScanTransmitters: [],
  selectedPipeTravelCourse: [],
  invalidatedPipes: [],

  setShowPipeTransmitters: (showPipeTransmitters) => set((state) => ({ showPipeTransmitters })),
  setInvalidatedPipe: (pipe: any) =>
    set((state) => ({ invalidatedPipes: [...state.invalidatedPipes, pipe] })),
  setSelectedPipeTravelCourse: (selectedPipeTravelCourse: any) =>
    set((state) => ({ selectedPipeTravelCourse })),
  setSelectedPipeScanTransmitters: (selectedPipeScanTransmitters: any) =>
    set((state) => ({ selectedPipeScanTransmitters })),
  setShowPipes: (showPipes) => set((state) => ({ showPipes })),
  setSelectedPipe: (selectedPipe: any) => set((state) => ({ selectedPipe }))
}));
