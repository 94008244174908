/*
Split tasks between several workers, limiting max concurrency
*/
export async function splitTasks(tasks: (() => Promise<void>)[], maxConcurrency: number) {
  const actualConcurrency = Math.min(tasks.length, maxConcurrency);
  const splittedTasks: (() => Promise<void>)[] = [];

  for (let i = 0; i < actualConcurrency; i++) {
    splittedTasks.push(async () => {
      let error = false;
      let cause = undefined;
      while (tasks.length > 0) {
        try {
          const task = tasks.pop();
          if (task !== undefined) {
            await task();
          }
        } catch (e) {
          error = true;
          cause = e;
        }
      }
      if (error) {
        if (cause === undefined) {
          throw new Error("Got exception while performing tasks");
        }
        throw cause;
      }
    });
  }

  return Promise.all(
    splittedTasks.map(async (t) => {
      await t();
    })
  );
}
