export enum MapEntity_Object_Type {
  GASLINE = 'GASLINE',
  ELECTRICLINE = 'ELECTRICLINE',
  STRAIGHTLINE = 'STRAIGHTLINE',
  DASHLINE = 'DASHLINE',
  LINEWIDTH = 'LINEWIDTH',
  TEXT = 'TEXT',
  DEPTHLINE = 'DEPTHLINE',
  UNKNOWN = 'UNKNOWN'
}

export enum EntityAction {
  INSERT,
  DELETE,
  UPDATE
}

export type TargetObject = {
  __typename?: 'Scan';
  id: string;
  name?: string | null;
  scan_type: string;
  metadata?: any | null;
  site: { __typename?: 'Site'; id: string; location: { __typename?: 'Location'; country: string } };
  target_info: string | null;
};

export type ParsedTargetObect = Omit<TargetObject, 'target_info' | 'metadata'> & {
  target_info: any;
  metadata: any;
};
