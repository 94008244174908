import { Drawer, IconButton, Stack } from '@mui/material';
import { FIlterIcon } from '../../../../SVG';
import { useState } from 'react';
import { DrawerContent } from './DrawerContent';
import { Typography } from '../../../../../shared';

export const FilterBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <Stack direction="row" spacing={0.5} alignItems="center" my={1}>
        <IconButton onClick={() => setIsDrawerOpen(true)}>
          <FIlterIcon fontSize="inherit" />
        </IconButton>
        <Typography>Filters</Typography>
      </Stack>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Stack height="100%" sx={{ width: 400, padding: 4, boxSizing: 'border-box' }}>
          <DrawerContent onClose={() => setIsDrawerOpen(false)} />
        </Stack>
      </Drawer>
    </>
  );
};
