import { GeoJSON, Tooltip } from "react-leaflet";
import { getAnalysisShapeColor } from "../../../Utils/mapUtils";
import { transformLightnessToRange } from "../../../Utils/cssUtils";
import { TooltipPaneName } from "../../../config/paneConfig";
import { AnalysisProductsPolygon } from "../DataTypes/MapEntities";

function PolygonMapEntities({ polygons }: PolygonMapEntitiesProps) {
  function generatePolygonInfoLines(polygon: AnalysisProductsPolygon): string[] {
    const scan = polygon.header.scan;

    let averageDepth: string | number = "Unknown";
    if (polygon.depth !== undefined) {
      averageDepth = polygon.depth;
    }

    const lines = [
      `Name: ${polygon.header.name}`,
      `Sensor: ${scan.sensor}`,
      `Confidence: ${polygon.confidence}`,
      `Avg. Depth: ${averageDepth}`,
      `Found By: ${polygon.header.user}`,
      `Found At: p${scan.polygon}s${scan.snake}_part${scan.part}`,
      `Scan From: ${scan.date}`,
      `Auto Confidence: ${polygon.autoConfidence}`,
    ];

    if (polygon.frequency !== undefined) {
      lines.push(`Frequency: ${polygon.frequency}Hz`);
    }

    if (polygon.header.comment !== undefined) {
      lines.push(`Comment: ${polygon.header.comment}`);
    }

    return lines;
  }

  function createPolygon(polygon: AnalysisProductsPolygon) {
    const parsedPolygon = JSON.parse(polygon.header.geoJson);

    if (
      parsedPolygon?.geometry?.coordinates !== undefined &&
      parsedPolygon.geometry.coordinates.length > 0 &&
      parsedPolygon.geometry.coordinates[0].length > 0 &&
      typeof parsedPolygon.geometry.coordinates[0][0] === "number"
    ) {
      parsedPolygon.geometry.coordinates = [parsedPolygon.geometry.coordinates];
    }

    const color = getAnalysisShapeColor(polygon);
    const options = { color: color, interactive: true };
    const messageLines = generatePolygonInfoLines(polygon);
    const divStyle = {
      backgroundColor: transformLightnessToRange(color, 60, 80),
    };
    return (
      <GeoJSON data={parsedPolygon} pathOptions={options} key={`polygon${polygon.header.id}`}>
        <Tooltip pane={TooltipPaneName} sticky={true}>
          <div style={divStyle}>
            {messageLines.map((messageLine) => (
              <>
                {messageLine}
                <br />
              </>
            ))}
          </div>
        </Tooltip>
      </GeoJSON>
    );
  }
  return <>{polygons.map((p) => createPolygon(p))}</>;
}

export interface PolygonMapEntitiesProps {
  polygons: AnalysisProductsPolygon[];
}

export default PolygonMapEntities;
