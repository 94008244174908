import { TextField, TextFieldProps } from "@mui/material";
import * as React from "react";

function CreateSiteSelect(props: TextFieldProps) {
  return (
    <TextField sx={{ width: 150 }} inputProps={{ sx: { fontSize: 13 } }} size="small" {...props} select></TextField>
  );
}

export default CreateSiteSelect;
