import {
  Avatar,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import * as React from "react";
import { ArcGISResourceType, ResourceInfo } from "./LoadResourcesForm";
import DeleteIcon from "@mui/icons-material/Delete";
import MapIcon from "@mui/icons-material/Map";
import LayersIcon from "@mui/icons-material/Layers";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { SECTION_WIDTH } from "./ArcGISStyleConsts";

function ManageResourcesForm({ resources, deleteResource }: ManageResourcesFormProps) {
  function getIcon(resourceType: ArcGISResourceType) {
    if (resourceType === ArcGISResourceType.MapServer) {
      return <MapIcon />;
    }
    if (resourceType === ArcGISResourceType.FeatureLayer) {
      return <LayersIcon />;
    }
    if (resourceType === ArcGISResourceType.FeatureServer) {
      return <LocationOnIcon />;
    }
  }

  return (
    <>
      <Typography variant="h5">Manage Resources</Typography>
      <List
        sx={{ width: SECTION_WIDTH }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Loaded Resources
          </ListSubheader>
        }
      >
        {resources.map((resource) => (
          <ListItem
            sx={{ backgroundColor: "rgb(245,245,245)", border: "1px solid rgb(230, 230, 230)", borderRadius: 1 }}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  deleteResource(resource.name);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>{getIcon(resource.type)}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={resource.name} />
          </ListItem>
        ))}
      </List>
    </>
  );
}

export interface ManageResourcesFormProps {
  resources: ResourceInfo[];
  deleteResource: (resourceName: string) => void;
}

export default ManageResourcesForm;
