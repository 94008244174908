import React from "react";

function TabsBar({ tabs, activeTab, onTabClick }: TabsBarProps) {
  function createTab(tabName: string) {
    let className = "tab";
    if (tabName === activeTab) {
      className = "activeTab";
    }
    return (
      <div
        className={className}
        key={`tab_${tabName}`}
        onClick={() => {
          onTabClick(tabName);
        }}
      >
        {tabName}
      </div>
    );
  }

  return (
    <>
      <div className="tabsbar">
        {tabs.map((t) => createTab(t))}
        <div className="tabsbarPlaceHolder" />
      </div>
      <div className="tabsbarSeperator" />
    </>
  );
}

export interface TabsBarProps {
  tabs: string[];
  activeTab: string;
  onTabClick: (tabName: string) => void;
}

export default TabsBar;
