import * as React from "react";
import { ClientDetails, StateDetails } from "../../Generated/ExoDBAPI";
import ListDisplay from "../Templates/ListDisplay";
import AddStateForm from "./AddStateForm";

function StateAdminForm({ states }: StateAdminFormProps) {
  return (
    <div className="adminAddObjContainer">
      <AddStateForm />
      <span>
        <h3 className="pageHeader">States</h3>
        <ListDisplay
          items={states.map((s) => `${s.countryName}, ${s.stateName}`).sort()}
          containerStyle={{ height: "200px" }}
        />
      </span>
    </div>
  );
}

interface StateAdminFormProps {
  states: StateDetails[];
}

export default StateAdminForm;
