import * as React from 'react';

import CheckboxSelection from '../../Templates/CheckboxSelection';
import { Dict } from '../../../BasicTypes';

export enum BoolCategoryValues {
  YES = 'Yes',
  NO = 'No'
}

export const EMPTY_OPTION = 'Empty';
export const FILTER_BOOL_CATEGORY_OPTIONS = [BoolCategoryValues.YES, BoolCategoryValues.NO];
export const MINIMAL_RANK = 1;

export function createCompareOptions(
  highPriorityOptions: string[],
  lowPriorityOptions?: string[]
): (first: string, second: string) => number {
  function getPrioritySortKeyValue(option: string): number {
    const highPriorityIndex = highPriorityOptions.indexOf(option);
    if (highPriorityIndex !== -1) {
      return highPriorityIndex;
    }

    if (lowPriorityOptions === undefined) {
      return highPriorityOptions.length;
    }

    // Intentionally use that if option is not in array indexOf return -1,
    // so missing values will be before all low priority options (but after the high ones)
    return highPriorityOptions.length + 1 + lowPriorityOptions.indexOf(option);
  }

  function compareProps(a: string, b: string): number {
    const firstKey = getPrioritySortKeyValue(a);
    const secondKey = getPrioritySortKeyValue(b);

    const priorityKey = firstKey - secondKey;
    if (priorityKey !== 0) {
      return priorityKey;
    }
    return a.localeCompare(b);
  }

  return compareProps;
}

abstract class FilterCategory<EntityType, PropsType> extends React.Component<
  InternalFilterCategoryProps<EntityType, PropsType>,
  FilterCategoryState
> {
  boundUpdateOptions;
  constructor(props: InternalFilterCategoryProps<EntityType, PropsType>) {
    super(props);
    this.boundUpdateOptions = this.updateOptions.bind(this);
  }

  // Javascript doesn't have classmethods or inheritance of static methods :(
  get filterName(): string {
    return this.props.filterName;
  }

  /**
   * Inform the caller of the entities that were filtered out by this category
   * @param checkedOptions The option that were checked (and as such, are not filtered out by this category)
   */
  protected abstract updateEntities(checkedOptions: string[]): void;

  /**
   * Get all the options for the filter.
   */
  protected abstract getAllOptions(): string[];

  protected updateOptions(checkedOptions: string[]): void {
    this.props.setChecked(checkedOptions);
    this.updateEntities(checkedOptions);
  }

  render(): React.ReactNode {
    const optionsMapping: Dict<string> = Object.fromEntries(
      this.getAllOptions().map((option) => [option, option])
    );
    return (
      <>
        <CheckboxSelection<string>
          optionsMapping={optionsMapping}
          filterName={this.props.filterName}
          setSelection={this.boundUpdateOptions}
          strategy={this.props.strategy}
          recalculateTrigger={this.props.resetFilterTrigger}
          resendOutputTrigger={true}
          enabled={true}
        />
      </>
    );
  }
}

/* Single Element Filter - Named is not changed due to backwards compatibility reasons */
class SinglePropFilterCategory<EntityType> extends FilterCategory<EntityType, string> {
  protected updateEntities(checkedOptions: string[]): void {
    const filtered = this.props.entities.filter(
      (entity) => !checkedOptions.includes(this.props.getProp(entity))
    );
    this.props.setFilteredEntities(filtered);
  }

  protected getAllOptions() {
    const options = [...new Set(this.props.entities.map(this.props.getProp))];
    if (this.props.filterName !== 'Scan') return options.sort(this.props.compareOptions);

    options.sort((a: string, b: string) => {
      const aParts: number[] = a?.match(/\d+/g)?.map(Number) || [];
      const bParts: number[] = b?.match(/\d+/g)?.map(Number) || [];

      for (let i = 0; i < aParts.length; i++) {
        if (aParts[i] !== bParts[i]) {
          return aParts[i] - bParts[i];
        }
      }
      return 0;
    });
    return options;
  }
}

// This is actually how I define the FilterCategory class as type.
// Basically, it state the new with props will return FilterCategory, which is basically the class.
type createFilterCategory<
  EntityType,
  PropType,
  OutputType extends FilterCategory<EntityType, PropType>
> = new (props: InternalFilterCategoryProps<EntityType, PropType>) => OutputType;

export abstract class FilterCategoryFactory<
  EntityType,
  PropType,
  OutputType extends FilterCategory<EntityType, PropType>
> {
  #rank: number;
  #defaultCheckStrategy: boolean | ((option: string) => boolean);
  #index: number;
  #checkedOptions: Dict<string[]>;

  constructor({
    categoryRank = MINIMAL_RANK,
    defaultCheckStrategy = true
  }: FilterCategoryFactoryProps) {
    this.#rank = categoryRank;
    this.#defaultCheckStrategy = defaultCheckStrategy;
    this.#index = 0;
    this.#checkedOptions = {};
  }

  protected abstract get baseClass(): createFilterCategory<EntityType, PropType, OutputType>;
  protected abstract getProp(entity: EntityType): PropType;
  abstract get filterName(): string;

  get rank(): number {
    return this.#rank;
  }

  get compareOptions(): undefined | ((a: string, b: string) => number) {
    return undefined;
  }

  getCheckedOptions(filterId?: string): string[] {
    if (filterId === undefined) {
      if (Object.keys(this.#checkedOptions).length !== 1) {
        throw new Error(
          `Filter id is required if factory didn't created exactly one filter. Factory of: ${this.filterName}`
        );
      }
      return Object.values(this.#checkedOptions)[0];
    }
    const options = this.#checkedOptions[filterId];
    if (options === undefined) {
      throw new Error(`Filter id ${filterId} is missing in factory of: ${this.filterName}`);
    }
    return options;
  }

  /**
   * Filter elements of the entity, by either returning the entity as is or copy the entity and modify the result.
   * By default, the entity is returned as is.
   *
   * @param entity The original entity modify
   */
  createDisplayEntity(entity: EntityType): EntityType {
    return entity;
  }

  create(
    categoryProps: FilterCategoryProps<EntityType>,
    strategy?: boolean | ((options: string) => boolean),
    filterId?: string
  ): React.ReactElement {
    const usedStrategy = strategy === undefined ? this.#defaultCheckStrategy : strategy;
    const idToUse = filterId ?? this.#index.toString();

    const props: InternalFilterCategoryProps<EntityType, PropType> = {
      ...categoryProps,
      filterName: this.filterName,
      getProp: this.getProp,
      compareOptions: this.compareOptions,
      strategy: usedStrategy,
      key: this.filterName,
      setChecked: (options: string[]) => {
        // Note: this is not part of the state, so this change will not trigger rendering.
        // The code that uses the options to hide unchosen values in the entities is responsible for triggering
        // if the entities themselves changed.
        this.#checkedOptions[idToUse] = options;
      }
    };

    return React.createElement(this.baseClass, props);
  }
}

export abstract class SinglePropFilterCategoryFactory<EntityType> extends FilterCategoryFactory<
  EntityType,
  string,
  SinglePropFilterCategory<EntityType>
> {
  protected get baseClass(): createFilterCategory<
    EntityType,
    string,
    SinglePropFilterCategory<EntityType>
  > {
    return SinglePropFilterCategory<EntityType>;
  }
}

/* Multi Elements Filters */
class MultiPropsFilterCategory<EntityType> extends FilterCategory<EntityType, string[]> {
  protected fixOptions(options: string[], shouldAddEmptyEntitiesOption: boolean): string[] {
    return options.length === 0 && shouldAddEmptyEntitiesOption ? [EMPTY_OPTION] : options;
  }

  protected getAllOptions(): string[] {
    const props = this.props.entities
      .map(this.props.getProp)
      .flatMap((options) => this.fixOptions(options, this.shouldAddEmptyEntitiesOption));
    const options = [...new Set(props)];
    return options.sort(this.props.compareOptions);
  }

  /**
   * Tell if the entity should be returned.
   * By default, the entity is returned if any of its elements matches.
   *
   * @param matchedOptions The options that the entity matched on
   * @param checkedOptions The option that were checked (and as such, are not filtered out by this category)
   * @param entity The entity to decide whether to display
   */
  protected shouldMatch(
    matchedOptions: string[],
    checkedOptions: string[],
    entity: EntityType
  ): boolean {
    if (matchedOptions.length > 0) {
      return true;
    }

    if (
      this.shouldAddEmptyEntitiesOption &&
      checkedOptions.includes(EMPTY_OPTION) &&
      this.props.getProp(entity).length === 0
    ) {
      return true;
    }

    return false;
  }

  protected get shouldAddEmptyEntitiesOption(): boolean {
    return true;
  }

  protected updateEntities(checkedOptions: string[]): void {
    const filtered: EntityType[] = [];
    this.props.entities.forEach((entity) => {
      const entityProps: string[] = this.props.getProp(entity);
      const matchedOptions = entityProps.filter((prop) => checkedOptions.includes(prop));
      if (this.shouldMatch(matchedOptions, checkedOptions, entity)) {
        return;
      }
      filtered.push(entity);
    });

    this.props.setFilteredEntities(filtered);
  }
}

export abstract class MultiPropsFilterCategoryFactory<EntityType> extends FilterCategoryFactory<
  EntityType,
  string[],
  MultiPropsFilterCategory<EntityType>
> {
  protected get baseClass(): createFilterCategory<
    EntityType,
    string[],
    MultiPropsFilterCategory<EntityType>
  > {
    return MultiPropsFilterCategory<EntityType>;
  }
}

export interface FilterCategoryState {}

export interface FilterCategoryProps<EntityType> {
  entities: EntityType[];
  setFilteredEntities: (entities: EntityType[]) => void;
  resetFilterTrigger: boolean;
}

interface InternalFilterCategoryProps<EntityType, PropsType>
  extends FilterCategoryProps<EntityType> {
  filterName: string;
  getProp: (entity: EntityType) => PropsType;
  compareOptions?: (a: string, b: string) => number;
  strategy: boolean | ((option: string) => boolean);
  key: string;
  setChecked: (options: string[]) => void;
}

export interface FilterCategoryFactoryProps {
  categoryRank?: number;
  defaultCheckStrategy?: boolean | ((option: string) => boolean);
}

export interface IFilterCategoryFactory<T> {
  rank: number;
  filterName: string;
  createDisplayEntity(entity: T): T;
  create(
    categoryProps: FilterCategoryProps<T>,
    strategy?: boolean | ((options: string) => boolean),
    filterId?: string
  ): React.ReactElement;
}
