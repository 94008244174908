import React from 'react';
import { Column, ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { AnalysisProductsSite } from '../../../Generated/ExoDBAPI';
import { entityType } from '../../../BasicTypes';
import { MapFeaturesStatus } from '../DataTypes/MapFeatures';
import { FeatureReporters } from '../Getters/ExoFuserEntitiesGetter';
import '../../../styles/table.css';
import { Table } from '../../../shared';

function getInvalidEntitiesSection(
  entitiesTypeName: string,
  entities: entityType[]
): InvalidateEntityEntryData[] {
  return entities.map((entity) => {
    return {
      id: entity.header.id,
      s3Ref: entity.header.s3Ref,
      name: entity.header.fullName === undefined ? entity.header.name : entity.header.fullName,
      typeName: entitiesTypeName
    };
  });
}

function entryFromEntryData(
  entryData: InvalidateEntityEntryData,
  analysisSite: AnalysisProductsSite,
  reporters: FeatureReporters
): InvalidateEntityEntry {
  const button = (
    <button
      onClick={() => reporters.setEntityValid(analysisSite, entryData.id, entryData.s3Ref, true)}>
      Restore
    </button>
  );
  return {
    name: entryData.name,
    typeName: entryData.typeName,
    revalidateButton: button
  };
}

function InvalidEntitiesView({
  analysisSite,
  mapFeatureStatus,
  reporters
}: InvalidEntitiesViewProps) {
  const columns = React.useMemo<ColumnDef<InvalidateEntityEntry, any>[]>(
    () => [
      {
        accessorKey: 'typeName',
        header: 'Type'
      },
      {
        accessorKey: 'name',
        header: 'Name'
      },
      {
        accessorKey: 'revalidateButton',
        header: 'Re-Validate'
      }
    ],
    []
  );

  const sections = Object.entries(mapFeatureStatus.invalidFeatures).map(
    ([entitiesTypeName, entities]) => {
      return getInvalidEntitiesSection(entitiesTypeName, entities);
    }
  );
  const data: InvalidateEntityEntry[] = React.useMemo(() => {
    if (analysisSite === undefined) {
      return [];
    }
    return sections.flat(1).map((entry) => entryFromEntryData(entry, analysisSite, reporters));
  }, [mapFeatureStatus, analysisSite, reporters]);

  return (
    <span className="customTable">
      <Table columns={columns} data={data} />
    </span>
  );
}

interface InvalidateEntityEntry {
  name: string;
  typeName: string;
  revalidateButton: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;
}

interface InvalidateEntityEntryData {
  id: string;
  s3Ref: string;
  name: string;
  typeName: string;
}

export interface InvalidEntitiesViewProps {
  analysisSite?: AnalysisProductsSite;
  mapFeatureStatus: MapFeaturesStatus;
  reporters: FeatureReporters;
}

export default InvalidEntitiesView;
