import { AnalysisProductsTransmitter } from "../DataTypes/MapEntities";
import { SinglePropFilterCategoryFactory } from "./BaseFilterCategory";

const UNKNOWN_VALUE = "Unknown";

function getOptionalStringFilterValue<T>(val?: T): string {
  if (val === undefined) {
    return UNKNOWN_VALUE;
  }
  return `${val}`;
}

export class SNRFilterCategoryFactory extends SinglePropFilterCategoryFactory<AnalysisProductsTransmitter> {
  get filterName(): string {
    return "SNR";
  }

  get highPriorityOptions(): string[] {
    return [UNKNOWN_VALUE];
  }

  getProp(entity: AnalysisProductsTransmitter): string {
    return getOptionalStringFilterValue(entity.snr);
  }
}

export class MethodFilterCategoryFactory extends SinglePropFilterCategoryFactory<AnalysisProductsTransmitter> {
  get filterName(): string {
    return "Method";
  }

  get highPriorityOptions(): string[] {
    return [UNKNOWN_VALUE];
  }

  getProp(entity: AnalysisProductsTransmitter): string {
    return getOptionalStringFilterValue(entity.method);
  }
}

export class SourceFilterCategoryFactory extends SinglePropFilterCategoryFactory<AnalysisProductsTransmitter> {
  get filterName(): string {
    return "Source";
  }

  get highPriorityOptions(): string[] {
    return [UNKNOWN_VALUE];
  }

  getProp(entity: AnalysisProductsTransmitter): string {
    const source = getOptionalStringFilterValue(entity.source);
    if (source === "SmartTransmitter") {
      if (entity?.header?.scan?.scanType === "TRANSMITTER") {
        return "DeprecatedSmart";
      }
    }
    return source;
  }
}
