import L, { Icon, LatLngLiteral } from 'leaflet';
import React, { forwardRef, useEffect, useState } from 'react';
import useZoomValue from '../ExoFuser/useZoomValue';
import { Marker, useMap } from 'react-leaflet';
import { MarkerProps } from 'react-leaflet';
import { ParsedTargetObect } from '../../Types/MappingEntities';
import { sizeCalc } from '../../Utils/svgUtil';
import { TargetType } from '../../Constant/entity';
import { PipeItem } from '../Pages/ManHolesForm';
import AzimuthLine from '../ExoFuser/EntitiesDisplay/AzimuthLine';
import { getNewCoordinatesByAngle } from '../../Utils/mapUtils';
import { targetOwnerListMap, targetUtilityTypesMap } from '../../Constant/ownerAndUtilityMapping';
import { generateCircleDonutSVG } from './CircleWithColor';
import { generateSquareLinesSVG } from './SqaureWithColor';
interface SvgTargetTypeProps {
  name: string;
  data: ParsedTargetObect;
  setSelectedManhole: (target: ParsedTargetObect) => void;
}
export type TargetInfoType = {
  scanName: string;
  manholeName: string;
  utilityType: string[];
  company: string;
  notes: string;
  depth: number;
  gps_height: number;
  overallNumber: number;
  otherCompanyName: string;
  pipes: PipeItem[];
  fileNames: string[];
  folderName: string;
  siteId: string;
  scanId: string;
  Measurement: string;
};

const createMarker = (iconUrl: string, zoom: number): L.Icon | null => {
  if (!zoom) return null;
  const size = sizeCalc(zoom);
  return new L.Icon({
    iconUrl: iconUrl,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, -size / 2]
  });
};

const SvgTarget = ({ name, data, setSelectedManhole }: SvgTargetTypeProps) => {
  const zoomValue = useZoomValue();
  const [customIcon, setCustomIcon] = useState<Icon | null>(null);
  const [position, setPosition] = useState<LatLngLiteral>({ lat: 0, lng: 0 });
  const [metadata, setMetadata] = React.useState<any>();
  const [targetInfo, setTargetInfo] = React.useState<TargetInfoType | null>(null);
  const [azimuthColor, setAzimuthColor] = React.useState<string>(
    targetUtilityTypesMap['Unknown'].color
  );
  const map = useMap();

  const MarkerWrapper = forwardRef<HTMLDivElement, MarkerProps>((props, ref) => (
    <div ref={ref}>
      <Marker
        {...props}
        eventHandlers={{
          click: () => {
            if (metadata?.additional_information?.target?.target_type === TargetType.MANHOLE) {
              setSelectedManhole(data as ParsedTargetObect);
            }
          }
        }}
      />
    </div>
  ));

  useEffect(() => {
    if (data && data?.metadata) {
      const { metadata } = data;
      if (!metadata?.scan?.gps_position?.lat || !metadata?.scan?.gps_position?.lon) return;
      setMetadata(metadata);
      setPosition({
        lat: metadata?.scan?.gps_position?.lat,
        lng: metadata?.scan?.gps_position?.lon
      });
      if (Object.keys(data?.target_info).length > 0) {
        setTargetInfo(data?.target_info);
        if (data?.target_info.company) {
          setAzimuthColor(
            targetOwnerListMap[data?.target_info.company as keyof typeof targetOwnerListMap]
              ?.color || '#000000'
          );
        } else if (data?.target_info.utility_type && data?.target_info.utility_type.length > 0) {
          setAzimuthColor(
            targetUtilityTypesMap[
              data?.target_info.utility_type[0] as keyof typeof targetUtilityTypesMap
            ]?.color || '#000000'
          );
        } else {
          setAzimuthColor(targetUtilityTypesMap['Unknown']?.color || '#000000');
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (data?.target_info?.entity) {
    }
    const svgContent = data?.target_info?.entity
      ? generateSquareLinesSVG(azimuthColor)
      : generateCircleDonutSVG(azimuthColor);
    const dataUri = `data:image/svg+xml,${encodeURIComponent(svgContent)}`;
    if (!zoomValue) return;
    const cIcon = createMarker(dataUri, zoomValue);
    setCustomIcon(cIcon);
  }, [zoomValue, azimuthColor]);

  if (!customIcon || zoomValue !== map.getZoom()) return <></>;

  return (
    <>
      {targetInfo &&
        targetInfo.pipes &&
        targetInfo.pipes.length > 0 &&
        targetInfo.pipes.map(
          (pipe: PipeItem) =>
            pipe.azimuth &&
            pipe.azimuth !== 999 && (
              <>
                <AzimuthLine
                  positions={[
                    [metadata?.scan?.gps_position?.lat, metadata?.scan?.gps_position?.lon],
                    getNewCoordinatesByAngle(
                      metadata?.scan?.gps_position?.lat,
                      metadata?.scan?.gps_position?.lon,
                      pipe.azimuth
                    )
                  ]}
                  pathOptions={{
                    color: azimuthColor,
                    weight: 7
                  }}
                  azimuth={pipe.azimuth}
                />
              </>
            )
        )}
      {customIcon && (
        <MarkerWrapper
          key={data?.id || 'sticker-svg'}
          position={position}
          icon={customIcon}
          draggable={false}></MarkerWrapper>
      )}
    </>
  );
};

export default SvgTarget;
