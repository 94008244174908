export enum FilterCategories {
  type = 'type',
  owner = 'owner',
  depth = 'depth',
  utilities = 'utilities',
  media = 'media'
}

export type Filters = {
  [FilterCategories.media]?: boolean;
  [FilterCategories.owner]?: Array<{ label: string; value: string }>;
  [FilterCategories.utilities]?: boolean;
  [FilterCategories.depth]?: Array<number>;
};
