import * as msal from "@azure/msal-browser";
import { userData } from "../Components/Contexts/UserContext";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_SSO_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_SSO_AUTHORITY ?? "",
    redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI ?? "",
  },
};

export async function logIn(user?: userData) {
  if (user !== undefined && (user.expiresOn === null || user.expiresOn.getUTCMilliseconds() < Date.now())) {
    return user;
  }

  if (process.env.REACT_APP_SSO_SCOPES === undefined) {
    throw new Error("Env variables are not set for log in");
  }

  const loginRequest = {
    scopes: process.env.REACT_APP_SSO_SCOPES.split(","),
  };

  const msalInstance = new msal.PublicClientApplication(msalConfig);

  async function handleResponse(response: msal.AuthenticationResult | null) {
    if (response !== null) {
      return response;
    } else {
      await msalInstance.loginRedirect(loginRequest);
    }
  }
  const res = await msalInstance.handleRedirectPromise().then(handleResponse);
  return res;
}

export async function logOut(user: userData) {
  const msalInstance = new msal.PublicClientApplication(msalConfig);

  if (user.account === null || user.account.homeAccountId === undefined) {
    throw new Error("User account property was malformed");
  }

  const homeAccountId = user.account.homeAccountId;
  const logoutRequest = {
    account: msalInstance.getAccountByHomeId(homeAccountId),
    mainWindowRedirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
  };
  const logoutResponse = await msalInstance.logoutPopup(logoutRequest);
  if (logoutResponse !== null) {
    return true;
  }
  return false;
}

export async function getBearerToken() {
  if (process.env.REACT_APP_SSO_SCOPES === undefined) {
    throw new Error("Env variables are not set for log in");
  }

  const msalInstance = new msal.PublicClientApplication(msalConfig);
  const redirectResponse = await msalInstance.handleRedirectPromise();
  if (redirectResponse !== null) {
    return `bearer ${redirectResponse.accessToken}`;
  } else {
    const account = msalInstance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: process.env.REACT_APP_SSO_SCOPES.split(","),
      account: account,
    };

    let token = undefined;
    try {
      token = (await msalInstance.acquireTokenSilent(accessTokenRequest)).accessToken;
    } catch (error) {
      console.log("authentication error", error);
      if (error instanceof msal.InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(accessTokenRequest);
      }
    }
    return `bearer ${token ?? ""}`;
  }
}

export async function getRequestParamsOnlyHeaders() {
  const bearToken = await getBearerToken();

  return {
    headers: {
      authorization: bearToken,
    },
  };
}

export async function getRequestParams() {
  const bearToken = await getBearerToken();

  return {
    baseUrl: process.env.REACT_APP_BACKEND_HOST,
    headers: {
      authorization: bearToken,
    },
  };
}

export async function getServicesRequestParams() {
  const bearToken = await getBearerToken();

  return {
    baseUrl: process.env.REACT_APP_SERVICES_BACKEND_HOST,
    headers: {
      authorization: bearToken,
    },
  };
}
