import L from "leaflet";
import React from "react";
import { GeoShape, UserLayer, UserLayerShape } from "../../../Generated/ExoDBAPI";

export const SELECTED_DRAWN_SHAPE_WEIGHT = 10;

export enum ShapeDrawningState {
  Uninitialized = 0,
  Clean = 1,
  ShapeDrawn = 2,
  ShapeSubmitted = 3,
  LayerUploading = 4,
}

export enum UserLayerDisplayMode {
  Base = "Base",
  Commit = "Commit",
  CacheOnly = "Cache",
  CacheSet = "Cache Set",
  CacheUpdate = "Cache Update",
  LoadAll = "Load All",
  LoadCache = "Load Cached",
}

const CACHE_MODES = [UserLayerDisplayMode.CacheOnly, UserLayerDisplayMode.CacheSet, UserLayerDisplayMode.CacheUpdate];
export const MarkingLineTypeChoices = ["Identified", "Suspected", "Marking", "Anchor"];
export const MarkingPolygonTypeChoices = ["Identified", "Suspected", "Free"];

export interface lineDisplayOptions {
  color: string;
  dashArray?: number[];
  weight?: number;
}

export interface polygonDisplayOptions {
  fillColor: string;
  opacitiy: number;
}

export const drawnDisplayConfig = {
  [GeoShape.Polyline]: {
    Identified: { color: "#000000", dashArray: [1], weight: 4 },
    Suspected: { color: "#000000", dashArray: [10, 10], weight: 4 },
    Marking: { color: "#FFC0CB", dashArray: [1, 5], weight: 2 },
    Anchor: { color: "#ffff00", dashArray: [1], weight: 4 },
  },
  [GeoShape.Polygon]: {
    Identified: { fillColor: "red", opacitiy: 0.4 },
    Suspected: { fillColor: "yellow", opacitiy: 0.4 },
    Free: { fillColor: "green", opacitiy: 0.4 },
  },
};

export const defaultDrawnLineDisplayConfig = { color: "grey", dashArray: [1, 3], weight: 2 };
export const defaultDrawnPolygonDisplayConfig = { fillColor: "grey", opacitiy: 0.1 };

export function getSourceNameForDrawnLayer(baseSourceName?: string): string | undefined {
  if (baseSourceName === undefined) {
    return undefined;
  }
  return baseSourceName.replace(" ", "").toLowerCase();
}

export function getGeoShapeType(leafletFeatureLayer: L.Layer): GeoShape {
  if (leafletFeatureLayer instanceof L.Polygon) {
    return GeoShape.Polygon;
  }

  if (leafletFeatureLayer instanceof L.Polyline) {
    return GeoShape.Polyline;
  }

  throw Error(`Unknown layer: ${leafletFeatureLayer}`);
}

export interface UserDisplayModeState {
  cacheSelected: boolean;
  hoverMode: UserLayerDisplayMode;
}

/**
 * General context to drawn action.
 *
 * There are multiple modules that handle the drawning of object and giving each the data it requires
 * will cause the module to have big and constantly changing constructors, so we transfer this to most modules.
 */
export interface DrawnContext {
  // State machine
  currentState: ShapeDrawningState;
  setCurrentState: React.Dispatch<React.SetStateAction<ShapeDrawningState>>;

  // Layer containing all the unsaved shapes
  drawingLayer: L.FeatureGroup | null;
  setDrawingLayer: React.Dispatch<React.SetStateAction<L.FeatureGroup | null>>;

  // The shape of the currently drawn layer
  currentShapeType: GeoShape | undefined;
  setCurrentShapeType: React.Dispatch<React.SetStateAction<GeoShape | undefined>>;

  // Handle saved shapes that are not committed to layers
  allDrawnShapes: UserLayerShape[];
  setAllDrawnShapes: React.Dispatch<React.SetStateAction<UserLayerShape[]>>;

  // User drawn layers
  savedLayers: UserLayer[];
  setSavedLayers: React.Dispatch<React.SetStateAction<UserLayer[]>>;

  // Layers to displayed
  displayedLayers: UserLayer[];
  setDisplayedLayers: React.Dispatch<React.SetStateAction<UserLayer[]>>;

  // Control which of the layers should be presented by the map
  userDisplayModeState: UserDisplayModeState;
  setUserDisplayModeState: React.Dispatch<React.SetStateAction<UserDisplayModeState>>;

  // Control over the parent widget
  changeTabToDrawn: () => void; // Changing to the drawn tab is required for correct data flow

  // Is a shape being edited
  editingShape: boolean;
  setEditingShape: React.Dispatch<React.SetStateAction<boolean>>;

  // The edited shape new positions
  editedShapePositions: L.LatLngExpression[];
  setEditedShapePositions: React.Dispatch<React.SetStateAction<L.LatLngExpression[]>>;
}

export function getDisplayMode(context: DrawnContext) {
  if (context.userDisplayModeState.hoverMode !== UserLayerDisplayMode.Base) {
    if (CACHE_MODES.includes(context.userDisplayModeState.hoverMode) && !context.userDisplayModeState.cacheSelected) {
      return UserLayerDisplayMode.Base;
    }
    return context.userDisplayModeState.hoverMode;
  }

  if (context.userDisplayModeState.cacheSelected) {
    return UserLayerDisplayMode.CacheOnly;
  }

  return UserLayerDisplayMode.Base;
}

export function createHoverModeSet(enabled: boolean, mode: UserLayerDisplayMode, context: DrawnContext) {
  function updateHoverMode(newOverMode: UserLayerDisplayMode) {
    context.setUserDisplayModeState((prevState) => {
      prevState.hoverMode = newOverMode;
      return JSON.parse(JSON.stringify(prevState));
    });
  }

  return {
    onMouseEnter: () => enabled && updateHoverMode(mode),
    onMouseLeave: () => updateHoverMode(UserLayerDisplayMode.Base),
  };
}
