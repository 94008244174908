import L, { Icon, LatLngLiteral } from 'leaflet';
import React, { useEffect, useState } from 'react';
import useScalableValue from '../ExoFuser/useScalableValue';
import { EntityAction } from '../../Types/MappingEntities';
import { ToolObject } from '../ExoFuser/MapControls/MeasurementUtil';
import { Marker, useMap } from 'react-leaflet';
import { sizeCalc } from '../../Utils/svgUtil';

interface SvgStickersProps {
  name: string;
  color?: string;
  points: LatLngLiteral[];
  setPoints: (points: LatLngLiteral[]) => void;
  updateData: (action: EntityAction, tool: ToolObject) => void;
  data: ToolObject;
}

const createMarker = (iconUrl: string, zoom: number) => {
  if (!zoom) return null;
  const size = sizeCalc(zoom);
  return new L.Icon({
    iconUrl: iconUrl,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, -size / 2]
  });
};
const defaultZoom = 20;
const path = './Assets/SVG';

const SvgStickers = ({ name, points, setPoints, color, updateData, data }: SvgStickersProps) => {
  const [src, setSrc] = useState<string>(`${path}/${name}.svg`);
  const arrowSize = useScalableValue(1);
  const [zoom, setZoom] = useState<number>(0);
  const [disabled, setDisabled] = React.useState(false);
  const [customIcon, setCustomIcon] = useState<Icon | null>(null);
  const [position, setPosition] = useState<LatLngLiteral>({ lat: 0, lng: 0 });
  const [finishDraw, setFinishDraw] = React.useState(false);

  const [startPoint, setStartPoint] = React.useState<L.LatLngLiteral>();
  const map = useMap();

  useEffect(() => {
    if (points.length > 1) {
      setPosition({
        lat: (points[0].lat + points[1].lat) / 2,
        lng: (points[0].lng + points[1].lng) / 2
      });
      setStartPoint(points[0]);
    }
  }, [points]);

  useEffect(() => {
    const cIcon = createMarker(src, map.getZoom());
    setCustomIcon(cIcon);
    setZoom(map.getZoom());
  }, [src, map]);

  useEffect(() => {
    fetch(`${path}/${name}.svg`)
      .then((response) => response.text())
      .then((dataImg) => {
        const blob = new Blob([dataImg], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);
        setSrc(url);
        if (points.length < 2) {
          map.once('click', handleStartDraw);
        } else {
          const cIcon = createMarker(src, map.getZoom());
          setCustomIcon(cIcon);
          setZoom(map.getZoom());
          setPosition({
            lat: (points[0].lat + points[1].lat) / 2,
            lng: (points[0].lng + points[1].lng) / 2
          });
          setFinishDraw(true);
        }
      });
  }, []);

  useEffect(() => {
    if (finishDraw) {
      const zoom = map.getZoom();
      const cIcon = createMarker(src, zoom || defaultZoom);
      setCustomIcon(cIcon);
    }
  }, [finishDraw]);

  const calcNewPoints = (latlng: LatLngLiteral) => {
    if (!latlng) return;
    const zoom = map.getZoom();
    const size = sizeCalc(zoom || defaultZoom);
    const centerPoint = map.latLngToLayerPoint(latlng);

    const topLeftPoint = centerPoint.subtract(L.point([size, size]).divideBy(2));
    const bottomRightPoint = centerPoint.add(L.point([size, size]).divideBy(2));

    const topLeftLatLng = map.layerPointToLatLng(topLeftPoint);
    const bottomRightLatLng = map.layerPointToLatLng(bottomRightPoint);
    return [topLeftLatLng, bottomRightLatLng];
  };

  const addNewPoint = (latlng: LatLngLiteral) => {
    const newPoint = calcNewPoints(latlng);
    if (newPoint) setPoints(newPoint);
  };

  const handleStartDraw = (e: L.LeafletMouseEvent) => {
    addNewPoint(e.latlng);
  };

  useEffect(() => {
    if (!arrowSize || arrowSize === zoom || src.length === 0) return;
    const cIcon = createMarker(src, map.getZoom());
    setCustomIcon(cIcon);
    setZoom(arrowSize);
  }, [arrowSize]);

  if (disabled || !customIcon || !startPoint) return null;

  return (
    startPoint && (
      <Marker
        key={data?.id || 'sticker-svg'}
        position={position}
        icon={customIcon}
        draggable={false}
        eventHandlers={{
          dragend: (e) => {
            const newPoint = calcNewPoints(e.target.getLatLng());
            if (newPoint) data.points = newPoint;
            updateData(EntityAction.UPDATE, data);
          },
          click: (e) => {
            if (e.originalEvent.ctrlKey) {
              if (data.id) updateData(EntityAction.DELETE, data);
              setDisabled(true);
              return;
            }
          }
        }}
      />
    )
  );
};

export default SvgStickers;
