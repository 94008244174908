import L from 'leaflet';
import { ImageOverlay } from 'react-leaflet';
import { GetExoMapDataResponse } from '../../../Generated/ExoDBAPI';

function ExomapImage({ exomap, opacity = 0.6 }: ExomapImageProps) {
  function createExomapOverlay() {
    if (exomap !== undefined) {
      return (
        <ImageOverlay
          url={exomap.imageUrl}
          bounds={L.latLngBounds(
            [exomap.bbox.minLat, exomap.bbox.minLon],
            [exomap.bbox.maxLat, exomap.bbox.maxLon]
          )}
          opacity={opacity}
        />
      );
    }
  }
  return <>{createExomapOverlay()}</>;
}

export interface ExomapImageProps {
  exomap?: GetExoMapDataResponse;
  opacity?: number;
}

export default ExomapImage;
