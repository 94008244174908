import * as React from "react";
import * as turf from "@turf/turf";
import { GeoJSON } from "react-leaflet";
import { Dict } from "../../../BasicTypes";
import { SensorPolygonsData } from "../ScansTab/ScansPolygons";

function ScansPolygons({ sensorScans }: ScansPolygonsProps) {
  const [globalId, setGlobalId] = React.useState(0);
  const scanPolygon = React.useMemo(() => createIntersectionPolygon(sensorScans), [sensorScans]);

  React.useEffect(() => {
    setGlobalId((oldVal) => oldVal + 1);
  }, [sensorScans]);

  function createIntersectionPolygonForSensor(scanData: SensorPolygonsData) {
    const finalPolygons = [];

    for (const sensorPolygons of Object.values(scanData.sensorScans)) {
      if (
        !sensorPolygons.expectedSubDivisions
          .map((division) => Object.keys(sensorPolygons.scans).includes(division))
          .every((val) => val)
      ) {
        // If not all the snakes for the polygon the its considered empty and should be skipped
        continue;
      }

      // Unify all the scan that are the same snake
      const snakePolygons = [];
      for (const polygons of Object.values(sensorPolygons.scans)) {
        if (polygons[0].boundingPolygonGeoJson === undefined) {
          throw new Error("Got invalid geojson for bounding polygon");
        }
        let resultPolygon = JSON.parse(polygons[0].boundingPolygonGeoJson);
        for (let i = 1; i < polygons.length; i++) {
          const currentPolygon = polygons[i];
          if (currentPolygon.boundingPolygonGeoJson === undefined) {
            throw new Error("Got invalid geojson for bounding polygon");
          }

          resultPolygon = turf.union(resultPolygon, JSON.parse(currentPolygon.boundingPolygonGeoJson));
        }
        snakePolygons.push(resultPolygon);
      }

      // Intersect between the snakes polygons
      let subPolygon = snakePolygons[0];
      for (let i = 1; i < snakePolygons.length; i++) {
        subPolygon = turf.intersect(subPolygon, snakePolygons[i]);
        if (!subPolygon) {
          return;
        }
      }

      finalPolygons.push(subPolygon);
    }

    // Unify all the resulting polygons to get the scan polygon for the whole sensor
    let resultPolygon = finalPolygons[0];
    for (let i = 1; i < finalPolygons.length; i++) {
      resultPolygon = turf.union(resultPolygon, finalPolygons[i]);
      if (!resultPolygon) {
        return;
      }
    }

    return resultPolygon;
  }

  function createIntersectionPolygon(sensorsPolygons: Dict<SensorPolygonsData>) {
    const sensorPolygons = Object.values(sensorsPolygons).map((data) => createIntersectionPolygonForSensor(data));

    // Intersect between all the unified sensors polygons
    let resultPolygon = sensorPolygons[0];
    for (let i = 1; i < sensorPolygons.length; i++) {
      if (!resultPolygon || !sensorPolygons[i]) {
        return;
      }
      resultPolygon = turf.intersect(resultPolygon, sensorPolygons[i]);
    }

    return resultPolygon;
  }

  return (
    <>
      <GeoJSON data={scanPolygon} key={`scanPolygon${scanPolygon}`} />
    </>
  );
}

export interface ScansPolygonsProps {
  sensorScans: Dict<SensorPolygonsData>;
}

export default ScansPolygons;
