import { Box, Grid, Typography } from "@mui/material";
import * as React from "react";
import { Dict } from "../../BasicTypes";
import { ScanData } from "../Pages/ScansDashboard";

const SCAN_STATUS_TO_SX_MAPPING: Dict<any> = {
  STARTED: { backgroundColor: "white" },
  UPLOADING: { backgroundColor: "white" },
  UPLOADED: { backgroundColor: "rgb(240, 250, 240)" /* Light Green */ },
  ACCESSED: { backgroundColor: "rgb(230, 236, 255)" /* purple-blue */ },
  ANALYZED: { backgroundColor: "rgb(250, 246, 210)" /* light gold */ },
};

function ScanCard({ scan }: ScanCardProps) {
  return (
    <>
      <Box
        sx={{
          ...SCAN_STATUS_TO_SX_MAPPING[scan.status],
          borderRadius: 2,
          border: "rgb(220, 230, 220) solid 2px",
          padding: 1,
        }}
      >
        <Box>
          <Typography variant="h6">{scan.name}</Typography>
        </Box>
        <Grid container>
          <Grid xs={4} item>
            <Box>Site: {scan.siteName}</Box>
          </Grid>
          <Grid xs={4} item>
            <Box>Status: {scan.status}</Box>
          </Grid>
          <Grid xs={4} item>
            <Box>Sensor: {scan.sensor}</Box>
          </Grid>

          <Grid xs={4} item>
            <Box>Start time: {new Date(scan.startTimestamp).toLocaleString()}</Box>
          </Grid>
          {scan.endTimestamp && (
            <Grid xs={4} item>
              <Box>End time: {new Date(scan.endTimestamp).toLocaleString()}</Box>
            </Grid>
          )}
          <Grid xs={4} item>
            <Box>Fixed: {scan.fixedModeRatio ? `${(scan.fixedModeRatio * 100).toFixed(2)}%` : "N/A"}</Box>
          </Grid>
        </Grid>
      </Box>
      <hr />
    </>
  );
}

export interface ScanCardProps {
  scan: ScanData;
}

export default ScanCard;
