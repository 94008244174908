import { Button, ButtonGroup, Modal, Stack, Typography } from '@mui/material';
import * as React from 'react';
import ModalBox from '../Templates/ModalBox';

export interface confirmationField {
  label: string;
  value: string;
  key?: string;
}

function AreYouSureModal({
  confirmationTitle,
  fields = [],
  open,
  acceptCallback,
  cancelCallback
}: AreYouSureModalProps) {
  function createInputRow(label: string, value: string, key?: string) {
    return (
      <Stack direction="row" gap="0.5rem" key={key ?? label}>
        <Typography fontWeight="bold">{label}:</Typography>
        {value}
      </Stack>
    );
  }

  const rows = fields.map((field: confirmationField) =>
    createInputRow(field.label, field.value, field?.key)
  );
  return (
    <Modal open={open}>
      <ModalBox>
        <Stack gap="1rem">
          <Stack>
            <Typography variant="h4">Confirmation</Typography>
            <Typography variant="subtitle1">{confirmationTitle}</Typography>
          </Stack>
          <hr />
          <Stack>{rows}</Stack>
          <ButtonGroup>
            <Button onClick={acceptCallback}>Accept</Button>
            <Button onClick={cancelCallback}>Cancel</Button>
          </ButtonGroup>
        </Stack>
      </ModalBox>
    </Modal>
  );
}

export interface AreYouSureModalProps {
  confirmationTitle: string;
  fields?: confirmationField[];
  open: boolean;
  acceptCallback: () => void;
  cancelCallback: () => void;
}

export default AreYouSureModal;
