import React from "react";
import UserContext from "../Contexts/UserContext";
import LoadingWheel from "../LoadingWheel";
import RequestLogIn from "../RequestLogIn";
import FilterBar from "../SitesViewerComponents/FilterBar";
import { siteViewerCardsCreator } from "../../Utils/siteCardsCreators";
import { Api, ClientDetails, SiteDetails, SiteType, StateDetails } from "../../Generated/ExoDBAPI";
import { getBearerToken, getRequestParams } from "../../Utils/azureAuth";

const NO_FILTER_OPT = "NO_FILTER_OPT";

/**
 * A page for viewing the avliable sites
 * @returns
 */
function SitesViewer({ states, siteTypes, clients, siteCardCreator = siteViewerCardsCreator() }: SitesViewerProps) {
  const [sites, setSites] = React.useState<SiteDetails[]>([]);
  const [doneLoading, setDoneLoading] = React.useState(false);

  const { user } = React.useContext(UserContext);

  React.useEffect(() => {
    async function fetchData() {
      if (user !== undefined) {
        const api = new Api();

        try {
          const sites = (
            await api.sites.getSites(
              {},
              {
                baseUrl: process.env.REACT_APP_BACKEND_HOST,
                headers: { authorization: await getBearerToken() },
              }
            )
          ).data;
          setSites(sites.sites);
        } catch (e) {
          let errorMessage = "Unknown Error while fetching sites";
          if (e instanceof Error) {
            errorMessage = `Failed to load sites. ${e.message}`;
          }
          window.alert(errorMessage);
        } finally {
          setDoneLoading(true);
        }
      }
    }
    fetchData();
  }, [user]);

  function createPageMainBody() {
    if (user === undefined) {
      return <RequestLogIn />;
    }
    if (!doneLoading) {
      return <LoadingWheel />;
    }
    return (
      <ul className="sitesList">
        {sites.map((s, i) => {
          return createListItem(s, i);
        })}
      </ul>
    );
  }

  async function filterSites({ siteName, clientId, location, siteType, createdAfter, createdBefore }: filterParams) {
    try {
      setDoneLoading(false);
      const api = new Api();
      let state;
      let country;
      if (location !== undefined) {
        country = states[location].countryName;
        state = states[location].stateName;
      }
      const sites = (
        await api.sites.getSites(
          {
            siteNameFuzzy: siteName,
            clientId,
            siteType,
            createdAfter,
            createdBefore,
            country,
            state,
          },
          await getRequestParams()
        )
      ).data;

      setSites(sites.sites);
    } catch (e) {
      let errorMessage = "Unknown Error while fetching sites with filter";
      if (e instanceof Error) {
        errorMessage = `Failed to load sites with ilter. ${e.message}`;
      }
      window.alert(errorMessage);
    } finally {
      setDoneLoading(true);
    }
  }

  async function resetSearch() {
    if (user !== undefined) {
      setDoneLoading(false);
      const api = new Api();
      try {
        const sites = (
          await api.sites.getSites(
            {},
            {
              baseUrl: process.env.REACT_APP_BACKEND_HOST,
              headers: { authorization: await getBearerToken() },
            }
          )
        ).data;

        setSites(sites.sites);
      } catch (e) {
        let errorMessage = "Unknown Error while fetching sites";
        if (e instanceof Error) {
          errorMessage = `Failed to load sites. ${e.message}`;
        }
        window.alert(errorMessage);
      } finally {
        setDoneLoading(true);
      }
    }
  }

  /**
   * Creates a site list item DOM
   * @param {*} site the site info
   * @param {*} index the index in the list (for css)
   * @returns the site list item DOM
   */
  function createListItem(site: SiteDetails, index: number) {
    const className = `siteItem${index % 2}`;
    return siteCardCreator(site, index);
  }

  return (
    <>
      <h3 className="sitesHeader">Sites</h3>
      <FilterBar
        states={states}
        siteTypes={siteTypes}
        clients={clients}
        noFilterVal={NO_FILTER_OPT}
        onFilter={filterSites}
        onResetClicked={resetSearch}
      />
      <hr></hr>
      {createPageMainBody()}
    </>
  );
}

export interface SitesViewerProps {
  states: StateDetails[];
  siteTypes: SiteType[];
  clients: ClientDetails[];
  siteCardCreator?: (site: SiteDetails, index: number) => React.ReactNode;
}

export interface filterParams {
  siteName: string;
  clientId: string;
  location: number;
  siteType: string;
  createdAfter: string;
  createdBefore: string;
}

export default SitesViewer;
