import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

export interface CrewCondition {
    id: number;
    condition: string;
    name: string;
    phone: string;
    date: string;
    company: string;
    equipment: string;
}

interface PropCrewCondition {
    items: CrewCondition[];
}


const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 18,
        fontStyle: 'bold',
        fontSize: 10,
        marginLeft: 24,
    },
    title: {
        width: '15%',
        textAlign: 'left',
        fontSize: 10,
        borderRightColor: '#fff',
        borderRightWidth: 0,
        paddingLeft: 8,
    },
    name: {
        width: '16%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 10,
        textAlign: 'left',
        paddingRight: 8,

        borderColor: '#000',
        borderWidth: 1,
        // fontSize: 9,
        paddingLeft: 4,
        paddingTop: 2,
        backgroundColor: '#acafb5',
    },
    company: {
        width: '21%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 10,
        textAlign: 'left',
        paddingRight: 8,

        borderColor: '#000',
        borderWidth: 1,
        // fontSize: 9,
        paddingLeft: 4,
        paddingTop: 2,
        backgroundColor: '#acafb5',
    },
  });


const TableRowCrew = ({items}: PropCrewCondition) => {
    if(items && items.length>0) {
    const rows = items.map( item =>
        <View style={styles.row} key={item.id.toString()}>
            <Text style={styles.title}>{item.condition}</Text>
            <Text style={styles.name}>{item.name}</Text>
            <Text style={styles.name}>{item.phone}</Text>
            <Text style={styles.name}>{item.date}</Text>
            <Text style={styles.company}>{item.company}</Text>
            <Text style={styles.name}>{item.equipment}</Text>
        </View>)
        return (<>{rows}</> )
    }

    return (<>
        <View style={styles.row} key={'0'}>
            <Text style={styles.title}>{'Waste Water Locate'}</Text>
            <Text style={styles.name}>{' '}</Text>
            <Text style={styles.name}>{' '}</Text>
            <Text style={styles.name}>{' '}</Text>
            <Text style={styles.company}>{' '}</Text>
            <Text style={styles.name}>{' '}</Text>
        </View>
        <View style={styles.row} key={'1'}>
            <Text style={styles.title}>{'Gas Locate and Mark'}</Text>
            <Text style={styles.name}>{' '}</Text>
            <Text style={styles.name}>{' '}</Text>
            <Text style={styles.name}>{' '}</Text>
            <Text style={styles.company}>{' '}</Text>
            <Text style={styles.name}>{' '}</Text>
        </View>
        </>
        )
};

  export default TableRowCrew
