import * as React from 'react';
import { Tooltip, GeoJSON, useMap } from 'react-leaflet';
import { GeoJsonLayer } from '../../../Utils/layerUtils';
import { transformLightnessToRange } from '../../../Utils/cssUtils';
import { getColorFromTypeObject } from '../../../Constant/exoVision';
import { ExoVisionPane } from '../../../config/paneConfig';

interface Dictionary {
  [key: string]: any;
}

function dictionaryToString(dictionary: Dictionary): React.ReactNode[] {
  const elements: React.ReactNode[] = [];
  for (const key in dictionary) {
    if (dictionary.hasOwnProperty(key)) {
      elements.push(
        <>
          {`${key}: ${dictionary[key]}`}
          <br />
        </>
      );
    }
  }
  return elements;
}

function GeoJsonsCustomLayers({ layers, onLayerClicked }: GeoJsonsCustomLayersProps) {
  const [tooltipOpen, setTooltipOpen] = React.useState<string | null>(null);

  function createLayers(layers: GeoJsonLayer[], defaultColor?: string): React.ReactNode[] {
    const geojsonLayers = layers
      .filter((layer) => layer.visible)
      .map((layer) => {
        const reactLayers = [
          <GeoJSON
            data={layer.data}
            pathOptions={{
              color: getColorFromTypeObject(layer?.data?.properties?.type) || defaultColor,

              ...(layer.selected ? { weight: 5 } : { weight: 3 })
            }}
            key={layer.id}
            eventHandlers={{
              click: (e) => {
                onLayerClicked(layer.id, e.originalEvent.ctrlKey);
              },
              mouseover: () => {
                setTooltipOpen(layer.id);
              },
              mouseout: () => {
                setTooltipOpen(null);
              }
            }}>
            {tooltipOpen && layer.id === tooltipOpen && (
              <Tooltip direction="bottom" offset={[0, 60]} opacity={1} permanent>
                <div
                  style={{
                    backgroundColor: transformLightnessToRange(defaultColor || layer.color, 60, 80)
                  }}>
                  {dictionaryToString(layer?.data?.properties || {})}
                </div>
              </Tooltip>
            )}
          </GeoJSON>
        ];
        if (layer.subLayers === undefined) {
          return reactLayers;
        }
        return [...reactLayers, createLayers(layer.subLayers, layer.color)];
      })
      .flat(2);
    return geojsonLayers;
  }

  return <ExoVisionPane>{createLayers(layers)}</ExoVisionPane>;
}

export interface GeoJsonsCustomLayersProps {
  layers: GeoJsonLayer[];
  onLayerClicked: (layerId: string, ctrlKey?: boolean) => void;
}

export default GeoJsonsCustomLayers;
