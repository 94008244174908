import React from "react";
import ExportDataForm, { ExportDataFormProps } from "./ExportDataForm";
import LoadResourcesForm, { ArcGISResourceType, ResourceInfo } from "./LoadResourcesForm";
import ManageResourcesForm from "./ManageResourcesForm";

function ArcGISResourcesTab({ resources, exportSiteProps, setResources, reportNewTileLayer }: ArcGISResourcesTabProps) {
  function addNewResource(newResource: ResourceInfo) {
    if (resources.some((resource) => resource.name === newResource.name)) {
      throw Error(`Resource with name ${newResource.name} already exists`);
    }
    if (newResource.type === ArcGISResourceType.TileServer) {
      reportNewTileLayer(newResource);
    } else {
      setResources((resources) => {
        return [...resources, newResource];
      });
    }
  }

  function deleteResource(name: string) {
    setResources((resources) => {
      const index = resources.findIndex((resource) => resource.name === name);
      const newResources = [...resources];
      newResources.splice(index, 1);
      return newResources;
    });
  }

  return (
    <span className="tabsContainer">
      <span className="tabsBody">
        <LoadResourcesForm reportNewResource={addNewResource} />
      </span>
      <span className="tabsBody">
        <ManageResourcesForm resources={resources} deleteResource={deleteResource} />
      </span>
      <span className="tabsBody">
        <ExportDataForm {...exportSiteProps} />
      </span>
    </span>
  );
}

export interface ArcGISResourcesTabProps {
  resources: ResourceInfo[];
  exportSiteProps: ExportDataFormProps;
  setResources: (resources: ResourceInfo[] | ((resources: ResourceInfo[]) => ResourceInfo[])) => void;
  reportNewTileLayer: (info: ResourceInfo) => void;
}

export default ArcGISResourcesTab;
