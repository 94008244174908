import * as React from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';

import { PanesConfigs } from '../../../../config/paneConfig';
import { AnalysisProductsPipe, AnalysisProductsTransmitter } from '../../DataTypes/MapEntities';

import { SiteWideDepthData } from '../../DepthSupport/depthData';
import { useMapControlsStore, useMapPipeEntitiesStore } from '../../store';
import { Dict } from '../../../../BasicTypes';

import { useTheme } from '@mui/material';
import { generatePipe, getPipeColor, getTextColor } from './generatePipe';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

function groupPipes(pipes: AnalysisProductsPipe[], groupSize = 2000) {
  const groupedPipes = [];
  for (let i = 0; i < pipes.length; i += groupSize) {
    const group = pipes.slice(i, i + groupSize);
    groupedPipes.push(group);
  }
  return groupedPipes;
}

export function PipeMapEntities({
  pipes,
  setSelectedPipe: changeToPipesTab,
  siteWideDepthData,
  transmitterMapping
}: PipeMapEntitiesProps) {
  const { selectedPipe, setSelectedPipe, showPipes, invalidatedPipes } = useMapPipeEntitiesStore();

  const map = useMap();
  const theme = useTheme();

  const { displayPipesMode } = useMapControlsStore();

  React.useEffect(() => {
    if (!map || !pipes.length || !showPipes) return;
    const relevantPipes = pipes.filter((p) => !invalidatedPipes.includes(p.header.id));
    let pipeClusterGroups: L.MarkerClusterGroup[] = [];

    const groupedPipes = groupPipes(relevantPipes, 3000);

    groupedPipes.forEach((pipes) => {
      const pipesCluster = L.markerClusterGroup({
        pane: PanesConfigs.PIPE.name,
        animate: true,
        animateAddingMarkers: true,
        chunkedLoading: true,
        iconCreateFunction: () => {
          return L.divIcon({ html: '', className: '', iconSize: [0, 0] });
        }
      });
      pipes.forEach((pipe) => {
        const isSelected = pipe === selectedPipe;
        const color = getPipeColor({ pipe, displayPipesMode, transmitterMapping, isSelected });
        pipesCluster.addLayer(
          generatePipe({
            pipe,
            isSelected,
            transmitterMapping,
            setSelectedPipe: (pipe) => {
              setSelectedPipe(pipe);
              changeToPipesTab?.(pipe);
            },
            siteDepthData: siteWideDepthData,
            displayPipesMode,
            color,
            textColor: getTextColor(color, theme)
          })
        );
      });
      pipeClusterGroups.push(pipesCluster);
    });

    pipeClusterGroups.forEach((pc) => {
      map.addLayer(pc);
    });

    return () => {
      pipeClusterGroups.forEach((pc) => {
        map.removeLayer(pc);
      });
      pipeClusterGroups = [];
    };
  }, [JSON.stringify(pipes), siteWideDepthData, displayPipesMode, showPipes, invalidatedPipes]);

  React.useEffect(() => {
    if (!selectedPipe || !map) return;
    const color = getPipeColor({
      pipe: selectedPipe,
      displayPipesMode,
      transmitterMapping,
      isSelected: true
    });

    const pipe = generatePipe({
      pipe: selectedPipe,
      isSelected: true,
      transmitterMapping,
      setSelectedPipe: (pipe) => {
        // setSelectedPipe(undefined);
        // onChangePipe?.(pipe);
      },
      siteDepthData: siteWideDepthData,
      displayPipesMode,
      color,
      textColor: getTextColor(color, theme)
    });

    map.addLayer(pipe);

    return () => {
      map.removeLayer(pipe);
    };
  }, [selectedPipe, invalidatedPipes]);

  return null;
}

export interface PipeMapEntitiesProps {
  pipes: AnalysisProductsPipe[];
  setSelectedPipe?: (pipe: AnalysisProductsPipe) => void;
  siteWideDepthData: SiteWideDepthData;
  transmitterMapping?: Dict<AnalysisProductsTransmitter[]>;
}
