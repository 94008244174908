import React, { useContext } from 'react';
import { useQueryParam, StringParam, ArrayParam } from 'use-query-params';
import UserContext from '../Contexts/UserContext';
import RequestLogIn from '../RequestLogIn';

import TabForm from '../Templates/TabForm';
import {
  AnalysisProductsSite,
  AnalysisProductsSource,
  Api,
  GeoShape,
  SiteDetails,
  StateDetails,
  UserLayer,
  UserLayerShape
} from '../../Generated/ExoDBAPI';

import RetryTab from '../ExoFuser/Retries/RetryTab';
import ExoFuserMap, { PlannedTransmittersReporters } from '../ExoFuser/ExoFuserMap';
import ExoFuserEntityGetter, { FeatureReporters } from '../ExoFuser/Getters/ExoFuserEntitiesGetter';
import InfoDisplay from '../ExoFuser/InfoDisplay';

import {
  DrawnContext,
  UserLayerDisplayMode,
  ShapeDrawningState,
  UserDisplayModeState
} from '../ExoFuser/Drawns/DrawnFileData';

import DepthTab from '../ExoFuser/DepthSupport/DepthTab';
import { DepthValueIssue, SiteWideDepthData } from '../ExoFuser/DepthSupport/depthData';

import DrawingTab from '../ExoFuser/Drawns/DrawingTab';

import { changeValidity } from '../ExoFuser/updateData';
import { LayerReporters } from '../ExoFuser/Getters/getDrawnLayers';

import { DisplayGroup } from '../ExoFuser/DataTypes/DisplayModes';
import {
  createEmptyMapFeatures,
  getFeatureCount,
  MapFeatures,
  MapFeaturesStatus,
  concatFeatures,
  getFeaturesIds
} from '../ExoFuser/DataTypes/MapFeatures';
import FilterTab from '../ExoFuser/Filters/FilterTab';
import {
  findMatchingFeaturesToSelected,
  findMatchingFeaturesToSelectedAllScanTransmitters,
  getScanMatchingFeatures,
  entityToStringKey,
  findMatchingPipesToSelectedTransmitter
} from '../ExoFuser/SelectedPipe/FindMatchingFeatures';
import SelectedPipeTab from '../ExoFuser/SelectedPipe/SelectedPipeTab';
import { LatLngExpression, LeafletEventHandlerFnMap } from 'leaflet';
import ArcGISResourcesTab from '../ExoFuser/ArcGISResources/ArcGISResourcesTab';
import { ResourceInfo } from '../ExoFuser/ArcGISResources/LoadResourcesForm';
import { getRequestParams } from '../../Utils/azureAuth';
import { Dict } from '../../BasicTypes';
import ScansTab from '../ExoFuser/ScansTab/ScansTab';
import axios from 'axios';
import { SensorPolygonsData } from '../ExoFuser/ScansTab/ScansPolygons';
import ManageFileLayersTab from '../ExoFuser/UploadFiles/ManageFileLayersTab';
import { CustomTileLayer } from '../ExoFuser/UploadFiles/LoadTifTileLayers';
import ReportBugButton from '../ExoFuser/BugReport/ReportBugButton';
import { GeoJsonLayer, PlannedPolygonLayer, globalSelectToggleRec } from '../../Utils/layerUtils';
import UserStorageManager from '../ExoFuser/UserStorage/UserStorageManager';
import PlanningTab, { PlannedTransmitterData } from '../ExoFuser/Planning/PlanningTab';
import SiteSelectionHandler from '../ExoFuser/SiteSelection/SiteSelectionHandler';
import {
  AnalysisProductsPipe,
  AnalysisProductsTransmitter,
  AnalysisProductsTravelCourse,
  ScanDetails
} from '../ExoFuser/DataTypes/MapEntities';
import { exovision_edit } from '../../Constant/exoVision';
import { MappingEntity } from '../../Generated/Graphql';
import LayerContext from '../Contexts/LayerContext';
import { filterMapFeatures } from '../../Utils/areaFilterUtils';
import { groupBy } from '../../Utils/groupBy';
import { PoiTab } from '../ExoFuser/POI';
import LocalStorageService from '../../Utils/storage';
import { ForceNoMissingPipes } from '../ExoFuser/SiteSelection/AllowMissingPipesForm';
import { ParsedTargetObect } from '../../Types/MappingEntities';
import UnifyTab from '../ExoFuser/Tab/Unify/UnifyTab';
import { Stack, Button } from '@mui/material';
import { ROLE_UNIFY } from '../../config/authConfig';

enum TabName {
  FILTER = 'Filter',
  SELECT_SITE = 'Sites',
  DEPTH = 'Depth',
  DRAWN_LAYERS = 'Drawn',
  ERRORS_AND_RETRY = 'Errors/Retry',
  EXOMAP = 'Exomap',
  SELECTED_PIPE = 'Pipe Info',
  ARCGIS_RESOURCES = 'ArcGIS Resources',
  MANAGE_FILE_LAYERS = 'Manage File Layers',
  SCANS = 'Scans',
  PLANNING = 'Planning',
  MANHOLE = 'Manhole',
  POI = 'POI',
  UNIFY = 'Unify'
}

enum ExofuserMode {
  Basic,
  Filter
}

enum StorageData {
  selectedPipes = 'selectedPipe'
}
/**
 * The ExoFuser application page for displaying feature maps
 * @returns
 */
function ExoFuserPage({ locations }: ExoFuserPageProps) {
  const { user } = React.useContext(UserContext);

  const [maxAllowedFrequency, setMaxAllowedFrequency] = React.useState<number | undefined>();
  const [authToken, setAuthToken] = React.useState('');
  const [refreshTrigger, setRefreshTrigger] = React.useState(true);
  const [mappingEntity, setMappingEntity] = React.useState<MappingEntity[] | []>([]);
  const [exofuserMode, setExofuserMode] = React.useState(ExofuserMode.Basic);
  const { setGlobalLayerSelected } = React.useContext(LayerContext);

  const mapEvents = React.useMemo<LeafletEventHandlerFnMap>(() => {
    if (exofuserMode === ExofuserMode.Filter) {
      return {
        click(e) {
          setFilterPolygon((points) => {
            const newPoints = [...points, [e.latlng.lng, e.latlng.lat]] as [number, number][];
            return newPoints;
          });
        }
      };
    }

    return {};
  }, [exofuserMode]);

  const [graphqlSiteId, setGraphqlSiteId] = useQueryParam('siteId', StringParam);
  const [sourceS3Prefix, setSourceS3Prefix] = useQueryParam('source', ArrayParam);
  const [plannedSiteId, setPlannedSiteId] = useQueryParam('plannedSite', StringParam);
  const [analysisSiteName, setAnalysisSiteName] = useQueryParam('analysisSiteName', StringParam);

  const [sensors, setSensors] = React.useState<string[]>([]);

  // Site select data
  const [source, setSource] = React.useState<AnalysisProductsSource[]>([]);
  const [plannedSite, setPlannedSite] = React.useState<SiteDetails>();
  const [analysisSite, setAnalysisSite] = React.useState<AnalysisProductsSite[]>([]);
  const [forceNoMissingPipes, setForceNoMissingPipes] = React.useState<ForceNoMissingPipes>(
    ForceNoMissingPipes.IGNORE
  );

  // General site map features
  const [userLayersWithErrors, setUserLayersWithErrors] = React.useState<string[]>([]);
  const [mapFeatures, setMapFeatures] = React.useState<MapFeaturesStatus>(
    new MapFeaturesStatus({
      features: createEmptyMapFeatures(),
      currentAnalysisSiteName: '',
      doneLoading: false,
      failedFeatures: new Set<string>(),
      scans: []
    })
  );

  const [expectedLayersCount, setExpectedLayersCount] = React.useState(0);
  const [displayedFeatures, setDisplayedFeatures] = React.useState<MapFeatures>(
    createEmptyMapFeatures()
  );

  const [expectedFeaturesIds, setExpectedFeaturesIds] = React.useState<string[]>([]);

  const [boundingGeoJsonsData, setBoundingGeoJsonsData] = React.useState<any[]>([]);
  const [expectedGeoJsons, setExpectedGeoJsons] = React.useState(-1);

  const [scansBoundingPolygons, setScansBoundingPolygons] = React.useState<
    Dict<SensorPolygonsData>
  >({});

  const [mapDisplayTravelCourses, setMapDisplayTravelCourses] = React.useState(false);

  const [menuCollapsed, setMenuCollapsed] = React.useState(false);

  // Drawing user layers
  const [wasDrawnLayersDownloaded, setWasDrawnLayersDownloaded] = React.useState<boolean>(false);
  const [drawnState, setDrawnState] = React.useState<ShapeDrawningState>(
    ShapeDrawningState.Uninitialized
  );
  const [drawingLayer, setDrawingLayer] = React.useState<L.FeatureGroup | null>(null);
  const [currentShapeType, setCurrentShapeType] = React.useState<GeoShape>();
  const [allDrawnShapes, setAllDrawnShapes] = React.useState<UserLayerShape[]>([]);
  const [drawnSavedLayers, setDrawnSavedLayers] = React.useState<UserLayer[]>([]);
  const [displayedLayers, setDisplayedLayers] = React.useState<UserLayer[]>([]);
  const [drawnDisplayModeState, setDrawnDisplayModeState] = React.useState<UserDisplayModeState>({
    cacheSelected: false,
    hoverMode: UserLayerDisplayMode.Base
  });
  const [selectedUserShape, setSelectedUserShape] = React.useState<UserLayerShape>();
  const [editingShape, setEditingShape] = React.useState(false);
  const [editedShapePositions, setEditedShapePositions] = React.useState<LatLngExpression[]>([]);
  const { setGlobalChangeLayers, globalLayerSelected } = React.useContext(LayerContext);
  const [transmittersAndTravelCoursesChecked, settransmittersAndTravelCoursesChecked] =
    React.useState(false);

  // Exomap (LMAO)
  //const [exomap, setExomap] = React.useState<GetExoMapDataResponse>();
  /*
  const exomapContext: ExomapContext = {
    exomap: exomap,
    setExomapLayer: setExomap,
  };
  */
  const [selectedTransmitter, setSelectedTransmitter] =
    React.useState<AnalysisProductsTransmitter>();
  // Selected pipe
  const [selectedPipe, setSelectedPipe] = React.useState<AnalysisProductsPipe>();
  const [selectedPipeRawTravelCourses, setSelectedPipeRawTravelCourses] =
    React.useState<AnalysisProductsTravelCourse[]>();

  const selectedPipeDisplayedFeatures = React.useMemo<MapFeatures>(() => {
    if (selectedPipe === undefined || mapFeatures === undefined) {
      return createEmptyMapFeatures();
    }
    const features = findMatchingFeaturesToSelected(selectedPipe, mapFeatures.features);
    if (selectedPipeRawTravelCourses !== undefined) {
      features.travelCourses = selectedPipeRawTravelCourses;
    }

    return features;
  }, [selectedPipe, mapFeatures, selectedPipeRawTravelCourses]);

  const selectedPipeTransmittersDisplayedFeatures = React.useMemo<MapFeatures>(() => {
    if (selectedPipe === undefined || mapFeatures === undefined) {
      return createEmptyMapFeatures();
    }
    const features = findMatchingFeaturesToSelectedAllScanTransmitters(
      selectedPipe,
      mapFeatures.features
    );
    if (selectedPipeRawTravelCourses !== undefined) {
      features.travelCourses = selectedPipeRawTravelCourses;
    }

    return features;
  }, [selectedPipe, mapFeatures, selectedPipeRawTravelCourses]);

  const selectedTransmittersDisplayedFeatures = React.useMemo<MapFeatures>(() => {
    if (selectedTransmitter === undefined || mapFeatures === undefined) {
      return createEmptyMapFeatures();
    }
    const features = findMatchingPipesToSelectedTransmitter(
      selectedTransmitter,
      mapFeatures.features
    );
    return features;
  }, [selectedTransmitter, mapFeatures]);

  const transmitterMapping = React.useMemo<Dict<AnalysisProductsTransmitter[]>>(() => {
    if (!mapFeatures.doneLoading) {
      return {};
    }
    return groupBy(mapFeatures.allFeatures.transmitters, (t) => entityToStringKey(t));
  }, [mapFeatures.allFeatures.transmitters, mapFeatures.doneLoading]);

  const [chosenDisplayGroup, setChosenDisplayGroup] = React.useState<DisplayGroup>(
    DisplayGroup.FILTER
  );
  const [previousDisplayGroup, setPreviousDisplayGroup] =
    React.useState<DisplayGroup>(chosenDisplayGroup);

  // Area filter
  const [filterPolygon, setFilterPolygon] = React.useState<[number, number][]>([]);
  const [shouldFilterArea, setShouldFilterArea] = React.useState(false);

  // Extra tile layers
  const [extraTileLayers, setExtraTileLayer] = React.useState<CustomTileLayer[]>([]);

  // External Layers
  const [geoJsonLayers, setGeoJsonLayers] = React.useState<GeoJsonLayer[]>([]);
  const [externallySelectedLayerId, setExternallySelectedLayerId] = React.useState<string>();

  React.useEffect(() => {
    getRequestParams().then((res) => setAuthToken(res.headers.authorization));
  });

  // ArcGis external resources
  const [arcGISResources, setArcGISResources] = React.useState<ResourceInfo[]>([]);

  // Planning scan
  const [beingDrawnPlannedPolygon, setBeingDrawnPlannedPolygon] =
    React.useState<LatLngExpression[]>();
  const [plannedPolygons, setPlannedPolygons] = React.useState<PlannedPolygonLayer[]>([]);
  const [plannedEntityId, setPlannedEntityId] = React.useState<string>();
  const [newTransmitterData, setNewTransmitterData] = React.useState<PlannedTransmitterData>();
  const [planningTabOpened, setPlanningTabOpened] = React.useState(false);
  const [siteGeoidValue, setSiteGeoidValue] = React.useState<number | DepthValueIssue>(
    DepthValueIssue.NotQueried
  );

  const displayedFeaturesByGroups = React.useMemo(() => {
    return {
      [DisplayGroup.FILTER]: displayedFeatures,
      [DisplayGroup.SELECTED_PIPE]: selectedPipeDisplayedFeatures,
      [DisplayGroup.SELECTED_PIPE_SCAN_TRANSMITTERS]: selectedPipeTransmittersDisplayedFeatures,
      [DisplayGroup.SELECTED_TRANSMITTER_SCAN_PIPES]: selectedTransmittersDisplayedFeatures
    };
  }, [displayedFeatures, selectedPipeDisplayedFeatures, selectedTransmittersDisplayedFeatures]);

  function addRemovePipeFromStorage(pipe: AnalysisProductsPipe) {
    const data = JSON.parse(LocalStorageService.loadData(StorageData.selectedPipes, '[]'));
    if (pipe?.in_group) {
      data.push(pipe.header.id);
      const uniqueData = Array.from(new Set(data));
      LocalStorageService.saveData(StorageData.selectedPipes, JSON.stringify(uniqueData));
    } else {
      const index = data.indexOf(pipe.header.id);
      data.splice(index, 1);
      LocalStorageService.saveData(StorageData.selectedPipes, JSON.stringify(data));
    }
  }

  const featuresOnMap = React.useMemo(() => {
    const features = displayedFeaturesByGroups[chosenDisplayGroup];
    if (features === undefined) {
      return createEmptyMapFeatures();
    }
    if (!shouldFilterArea) {
      return features;
    }
    const pipesFilters = filterMapFeatures(features, filterPolygon);

    pipesFilters.pipes.forEach((pipe: AnalysisProductsPipe) => {
      pipe.in_group = true;
      addRemovePipeFromStorage(pipe);
    });
    return pipesFilters;
  }, [displayedFeatures, filterPolygon, chosenDisplayGroup, shouldFilterArea]);

  const { setLastLayer } = useContext(LayerContext);
  const tabControllerRef = React.useRef<TabForm>(null);
  const selectedPipeId = React.useRef<string>();

  React.useEffect(() => {
    async function fetchTravelCourse() {
      selectedPipeId.current = selectedPipe?.header.id;
      setSelectedPipeRawTravelCourses(undefined);
      const api = new Api();
      if (analysisSite === undefined || selectedPipe === undefined) {
        return;
      }

      const travelCourses = getScanMatchingFeatures(
        selectedPipe.header.scan,
        mapFeatures.features.travelCourses
      );
      const rawTravelCourses: AnalysisProductsTravelCourse[] = [];
      for (const travelCourse of travelCourses) {
        const fetchedTravelCourses = (
          await api.analysis.getAnalysisFeatureByS3Ref(
            { s3Ref: travelCourse.header.s3Ref },
            await getRequestParams()
          )
        ).data.travelCourses;
        rawTravelCourses.push(...fetchedTravelCourses);
      }
      setSelectedPipeRawTravelCourses((prevState) => {
        if (selectedPipe.header.id === selectedPipeId.current) {
          return rawTravelCourses;
        }

        return prevState;
      });
    }
    fetchTravelCourse();
  }, [selectedPipe]);

  const drawnContext: DrawnContext = {
    currentState: drawnState,
    setCurrentState: setDrawnState,
    drawingLayer: drawingLayer,
    setDrawingLayer: setDrawingLayer,
    currentShapeType: currentShapeType,
    setCurrentShapeType: setCurrentShapeType,
    allDrawnShapes: allDrawnShapes,
    setAllDrawnShapes: setAllDrawnShapes,
    savedLayers: drawnSavedLayers,
    setSavedLayers: setDrawnSavedLayers,
    displayedLayers: displayedLayers,
    setDisplayedLayers: setDisplayedLayers,
    userDisplayModeState: drawnDisplayModeState,
    setUserDisplayModeState: setDrawnDisplayModeState,
    changeTabToDrawn: () => {
      if (tabControllerRef.current !== null) {
        tabControllerRef.current.setActiveTab(TabName.DRAWN_LAYERS, true);
      }
    },
    editingShape: editingShape,
    setEditingShape: setEditingShape,
    editedShapePositions: editedShapePositions,
    setEditedShapePositions: setEditedShapePositions
  };

  const siteWideDepthData: SiteWideDepthData = {
    graphqlSiteId: graphqlSiteId ?? undefined,
    geoidValue: siteGeoidValue,
    setGeoidValue: setSiteGeoidValue,
    siteName: plannedSite?.name
  };

  function changeSelectedPipe(pipe: AnalysisProductsPipe) {
    if (tabControllerRef.current !== null) {
      // Can use `force=true` because this change will be from double click, so the user will not be mid-action
      tabControllerRef.current.setActiveTab(TabName.SELECTED_PIPE, true);
    }
    // setSelectedPipe(pipe);
    addRemovePipeFromStorage(pipe);
  }

  function changeSelectedManhole(poi: ParsedTargetObect) {
    if (tabControllerRef.current !== null) {
      tabControllerRef.current.setActiveTab(TabName.POI, true);
    }
  }

  function reportNewFeatures(featuresStatus: MapFeaturesStatus) {
    setMapFeatures((currentFeaturesStatus) => {
      if (
        currentFeaturesStatus.currentAnalysisSiteName !== featuresStatus.currentAnalysisSiteName
      ) {
        return currentFeaturesStatus;
      }
      // By default a feature is displayed
      setDisplayedFeatures((oldFeatures) => concatFeatures(oldFeatures, featuresStatus.features));
      return currentFeaturesStatus.merge(featuresStatus);
    });
  }

  function notifyDoneLoading(siteName: string, state: boolean) {
    setMapFeatures((oldFeatures) => {
      if (oldFeatures.currentAnalysisSiteName !== siteName) {
        return oldFeatures;
      }

      return oldFeatures.setDoneLoading(state);
    });
  }

  function reportNewErrors(featureIds: string[]): void {
    setMapFeatures((oldFeatures) => oldFeatures.extendErrors(featureIds));
  }

  function setEntityValid(
    site: AnalysisProductsSite,
    featureId: string,
    s3Ref: string,
    isValid: boolean
  ): void {
    setMapFeatures((oldFeatures) => {
      if (oldFeatures.currentAnalysisSiteName !== site.s3Prefix) {
        return oldFeatures;
      }

      const result = oldFeatures.setEntityValid(featureId, isValid);
      changeValidity({ site: site, featureS3Ref: s3Ref, isValid: isValid });
      return result;
    });
  }

  const reporters: FeatureReporters = {
    reportNewErrors: reportNewErrors,
    reportNewFeatures: reportNewFeatures,
    setDoneLoading: notifyDoneLoading,
    setEntityValid: setEntityValid,
    setExpectedFeatures: setExpectedFeaturesIds,
    reportSensors: (sensors) => setSensors((oldVal) => [...oldVal, ...sensors])
  };

  // The Layers should have a similar structure to the features (with errors, done loading, current state, etc.).
  // This is however outside of the scope of the current issue and will be added when retry is supported.
  function reportNewLayers(layers: UserLayer[]) {
    setDrawnSavedLayers((currentLayers) => {
      return currentLayers.concat(layers);
    });
  }

  function notifyLayersDoneLoading(plannedSiteId: string, state: boolean) {
    console.log('Set done loading user layers of', plannedSiteId, ' to state:', state);
    setWasDrawnLayersDownloaded(state);
  }

  function reportNewLayersErrors(layersIds: string[]): void {
    setUserLayersWithErrors((currentLayersErrors) => currentLayersErrors.concat(layersIds));
  }

  const layerReporter: LayerReporters = {
    setExpectedLayersCount: setExpectedLayersCount,
    reportNewErrors: reportNewLayersErrors,
    setDoneLoading: notifyLayersDoneLoading,
    reportNewLayers: reportNewLayers
  };

  const currentErrorsCount = React.useMemo(
    () => mapFeatures.failedFeatures.size,
    [mapFeatures.failedFeatures]
  );

  function resetFeatures(siteName: string) {
    setChosenDisplayGroup(DisplayGroup.FILTER);
    setMapFeatures(() => {
      return new MapFeaturesStatus({
        features: createEmptyMapFeatures(),
        currentAnalysisSiteName: siteName,
        doneLoading: false,
        failedFeatures: new Set<string>(),
        scans: []
      });
    });

    setDisplayedFeatures(createEmptyMapFeatures());
  }

  function resetUserLayers(plannedSiteId: string) {
    setUserLayersWithErrors([]);
    setWasDrawnLayersDownloaded(false);
    // Let the component clean up the state according to responsibilities:
    //   ExoFuser Map - currently drawn shape[s] (should have been DrawnLayersControlForm, but need to act on leaflet)
    //   DrawnLayersControlForm - saved layers
    //   CommitDrawnLayerForm - drawn shapes (to be committed as layers)
    setDrawnState(ShapeDrawningState.Uninitialized);
  }

  // Cancel selection if the pipe is not a valid pipe of the current site
  React.useEffect(() => {
    if (selectedPipe === undefined) {
      return;
    }

    if (
      mapFeatures === undefined ||
      !getFeaturesIds(mapFeatures.features).has(selectedPipe.header.id)
    ) {
      setSelectedPipe(undefined);
    }
  }, [mapFeatures, selectedPipe]);

  // Let the system to move the user from the sites tab after a site was selected.
  // Used to change into filter when loading is finished
  React.useEffect(() => {
    if (tabControllerRef.current !== null) {
      tabControllerRef.current.enableExit(TabName.SELECT_SITE);
    }
  }, [analysisSite]);

  React.useEffect(() => {
    if (mapFeatures.doneLoading) {
      if (tabControllerRef.current !== null) {
        let target = TabName.FILTER;
        let shouldForce = false;
        if (currentErrorsCount > 0) {
          target = TabName.ERRORS_AND_RETRY;
          window.alert(
            `Not all features was download successfully (${currentErrorsCount} errors)!\n` +
              `Switching to ${TabName.ERRORS_AND_RETRY} display`
          );
          shouldForce = true; // It is fine to force it here, since the user will be in the alert context
        }
        tabControllerRef.current.setActiveTab(target, shouldForce);
        if (target === TabName.FILTER) {
          setTimeout(() => {
            settransmittersAndTravelCoursesChecked(true);
          }, 2000);
        }
      }
    }
  }, [mapFeatures.doneLoading, tabControllerRef.current]);

  React.useEffect(() => {
    setFilterPolygon([]);
    setShouldFilterArea(false);
    setExofuserMode(ExofuserMode.Basic);
    setGlobalLayerSelected('');
  }, [source, analysisSite, plannedSite]);

  function reportNewGeoJsons(jsons: any[]) {
    setBoundingGeoJsonsData((prevState) => {
      const newState = [...prevState];
      newState.push(...jsons);
      return newState;
    });
  }

  React.useEffect(() => {
    // Sometimes downloading the layers come before the previous site was full restarted
    if (wasDrawnLayersDownloaded && drawnState === ShapeDrawningState.Uninitialized) {
      setDrawnState(ShapeDrawningState.Clean);
    }
  }, [wasDrawnLayersDownloaded, drawnState]);

  async function getScanBoundingPolygon(scan: ScanDetails) {
    const api = new Api();
    const url = (
      await api.analysis.getBoundingPolygonPresingedUrl(
        { s3Ref: scan.boundingPolygonS3Ref ?? '' },
        await getRequestParams()
      )
    ).data.url;
    const result = await axios.get(url);
    return result.data;
  }

  function onGeoJsonLayerClicked(
    layerId: string,
    ctrlClick: boolean = false,
    subLayer: any | null = null
  ) {
    const newLayers = globalSelectToggleRec(layerId, geoJsonLayers);
    if (!newLayers || newLayers.length === 0) {
      return;
    }

    let updatedLayers = [...newLayers];

    if (subLayer) {
      updatedLayers = newLayers.map((layer) => {
        const updatedSubLayers = layer.subLayers ? [...layer.subLayers, subLayer] : [subLayer];
        return { ...layer, subLayers: updatedSubLayers };
      });
    }

    if (ctrlClick && globalLayerSelected === exovision_edit) {
      const deleteLayers = newLayers.map((layer) => {
        if (layer.subLayers) {
          const subLayerArray = layer.subLayers.find((subLayer) => subLayer.id === layerId);
          return subLayerArray;
        }
        return undefined;
      });
      setLastLayer(deleteLayers?.[0]);

      updatedLayers = newLayers.map((layer) => {
        if (layer.subLayers) {
          const updatedSubLayers = layer.subLayers.filter((subLayer) => subLayer.id !== layerId);
          return { ...layer, subLayers: updatedSubLayers };
        }
        return layer;
      });

      setGlobalChangeLayers(true);
    }

    setGeoJsonLayers(updatedLayers);
    setExternallySelectedLayerId(layerId);
  }

  function updatePlannedPolygonPosition() {
    return plannedPolygons.map((_, i) => {
      return (updateFunc: (positions: LatLngExpression[]) => LatLngExpression[]) => {
        setPlannedPolygons((oldVal) => {
          const newVal = [...oldVal];
          if (newVal.length <= i) {
            return newVal;
          }
          const polygonPositions = updateFunc(newVal[i].data ?? []);
          newVal[i] = {
            ...oldVal[i],
            data: polygonPositions
          };

          return newVal;
        });
      };
    });
  }

  function clearSelectedPipes(pips: AnalysisProductsPipe[]) {
    pips.forEach((pipe) => {
      pipe.in_group = false;
    });
    setSelectedPipe({ ...pips[0] });
  }

  function loadSelectedPipes(pips: AnalysisProductsPipe[]) {
    const localSelectedPipes = JSON.parse(
      LocalStorageService.loadData(StorageData.selectedPipes) ?? '[]'
    );
    if (localSelectedPipes.length > 0) {
      pips.forEach((pipe) => {
        if (localSelectedPipes.includes(pipe.header.id)) {
          pipe.in_group = true;
        }
      });
      setSelectedPipe({ ...pips[0] });
    }
  }

  function clearStorageSelections() {
    LocalStorageService.saveData(StorageData.selectedPipes, '[]');
  }

  function getPlannedTransmittersSetters() {
    // TODO: define reporters to be a ref here so it can be passed to the map. Move the logic to populate the reporters to the PlanningTab
    const selectedPolygons = plannedPolygons.filter((p) => p.selected);
    if (selectedPolygons.length !== 1) {
      const reporters: PlannedTransmittersReporters = {
        addTransmitter: (c) => {},
        removeTransmitter: (i) => {},
        changeTransmitter: (i, c) => {}
      };
      return reporters;
    }

    const selectedPolygonIndex = plannedPolygons.indexOf(selectedPolygons[0]);
    const reporters: PlannedTransmittersReporters = {
      addTransmitter: (c) => setNewTransmitterData({ polygon: selectedPolygons[0], coords: c }),
      removeTransmitter: (i) =>
        setPlannedPolygons((polygons) => {
          const newPolygons = [...polygons];
          const newSubLayers = [...(newPolygons[selectedPolygonIndex].subLayers ?? [])];
          newSubLayers.splice(i, 1);
          newPolygons[selectedPolygonIndex].subLayers = newSubLayers;
          return newPolygons;
        }),
      changeTransmitter: (i, c) =>
        setPlannedPolygons((polygons) => {
          const newPolygons = [...polygons];
          const newSubLayers = [...(newPolygons[selectedPolygonIndex].subLayers ?? [])];
          newSubLayers[i].data = [c];
          newPolygons[selectedPolygonIndex].subLayers = newSubLayers;
          return newPolygons;
        })
    };
    return reporters;
  }

  return (
    <>
      {user === undefined ? (
        <RequestLogIn />
      ) : (
        <>
          <UserStorageManager
            customGeoJsonLayersStorageProps={{
              site: plannedSite,
              geojsonLayers: geoJsonLayers,
              setGeoJsonLayers: setGeoJsonLayers
            }}
            planningLayerStorageProps={{
              graphqlSiteId: graphqlSiteId ?? undefined,
              reloadTrigger: refreshTrigger,
              planningLayers: plannedPolygons,
              reportPlanningLayers: setPlannedPolygons,
              setPlannedEntityId: setPlannedEntityId
            }}
          />
          <ExoFuserEntityGetter
            graphqlSiteId={graphqlSiteId ?? undefined}
            source={source}
            plannedSite={plannedSite}
            analysisSite={analysisSite}
            reseters={{
              resetGeoJsons: () => setBoundingGeoJsonsData([]),
              resetFeatures: resetFeatures,
              resetLayers: resetUserLayers,
              resetExpectedFeatures: () => setExpectedFeaturesIds([])
            }}
            featureReporters={reporters}
            setExpectedGeoJsons={setExpectedGeoJsons}
            reportNewGeoJsons={reportNewGeoJsons}
            layerReporters={layerReporter}
            refreshTrigger={refreshTrigger}
            setMappingEntity={setMappingEntity}
          />

          <div className="pageWrapper">
            <span className="fuserHeader">
              <ReportBugButton
                plannedSite={plannedSite?.name}
                analysisSite={
                  analysisSite && analysisSite.length > 0 ? analysisSite[0].name : undefined
                }
                source={source[0]?.name}
                user={user.account?.name}
              />
              <span
                onClick={() => {
                  setMenuCollapsed((prevState) => !prevState);
                }}
                className="hideMenuOption">
                {menuCollapsed ? <img src="/expandMenu.png" /> : <img src="/collapseMenu.png" />}
              </span>
            </span>
            <div
              style={{
                maxHeight: 400,
                height: menuCollapsed ? 0 : 'unset',
                transition: 'all 0.2s'
              }}>
              <TabForm
                ref={tabControllerRef}
                tabClass="exofuserTab"
                tabs={[
                  {
                    tabName: TabName.SELECT_SITE,
                    shouldReload: false,
                    tabElement: (
                      <SiteSelectionHandler
                        setParentSource={setSource}
                        setParentPlannedSite={setPlannedSite}
                        setParentAnalysisSite={setAnalysisSite}
                        setAnalysisSiteQueryParam={setAnalysisSiteName}
                        setPlannedSiteQueryParam={setPlannedSiteId}
                        setForceNoMissingPipes={setForceNoMissingPipes}
                        setSourceQueryParam={setSourceS3Prefix}
                        setParentMapDisplayTravelCourses={setMapDisplayTravelCourses}
                        setGraphqlSiteId={setGraphqlSiteId}
                        locations={locations}
                        selectedAnalysisSiteName={analysisSiteName ?? undefined}
                        selectedPlannedSiteId={plannedSiteId ?? undefined}
                        selectedSourceS3Prefix={sourceS3Prefix ?? []}
                        settransmittersAndTravelCoursesChecked={
                          settransmittersAndTravelCoursesChecked
                        }
                      />
                    )
                  },
                  {
                    tabName: TabName.ERRORS_AND_RETRY,
                    shouldReload: false,
                    tabElement: (
                      <RetryTab
                        analysisSite={analysisSite[0]}
                        mapFeatureStatus={mapFeatures}
                        reporters={reporters}
                        s3FeaturesIds={expectedFeaturesIds}
                        triggerRefresh={() => setRefreshTrigger((state) => !state)}
                        setMaxAllowedFrequency={setMaxAllowedFrequency}></RetryTab>
                    )
                  },
                  {
                    tabName: TabName.FILTER,
                    shouldReload: false,
                    tabElement: (
                      <FilterTab
                        loadingStateChange={mapFeatures.doneLoading}
                        setDisplayedFeatures={setDisplayedFeatures}
                        mapFeatures={mapFeatures.features}
                        showTravelCoursesAtStart={mapDisplayTravelCourses}
                        isDrawingAreaFilter={exofuserMode === ExofuserMode.Filter}
                        areaFilterExists={
                          exofuserMode === ExofuserMode.Basic && filterPolygon.length !== 0
                        }
                        areaFilterEvents={{
                          onFilterStart: () => {
                            setFilterPolygon([]);
                            setExofuserMode(ExofuserMode.Filter);
                            setShouldFilterArea(false);
                          },
                          onFilterStop: () => {
                            setExofuserMode(ExofuserMode.Basic);
                            setShouldFilterArea(true);
                          },
                          onFilterDelete: () => {
                            setFilterPolygon([]);
                            setShouldFilterArea(false);
                          },
                          onFilterEnabled: () => {
                            setShouldFilterArea(true);
                          },
                          onFilterDisabled: () => {
                            setShouldFilterArea(false);
                          },
                          onClearSelected: () => {
                            clearSelectedPipes(mapFeatures?.features?.pipes || []);
                          },
                          onLoadSelected: () => {
                            loadSelectedPipes(mapFeatures?.features?.pipes || []);
                          },
                          onClearStorageSelections: () => {
                            clearStorageSelections();
                          }
                        }}
                        targets={mapFeatures.allFeatures.targets}
                        transmitterMapping={transmitterMapping}
                      />
                    )
                  },
                  {
                    tabName: TabName.DEPTH,
                    shouldReload: false,
                    tabElement: (
                      <DepthTab
                        siteWideDepthData={siteWideDepthData}
                        triggerRefresh={() => setRefreshTrigger((state) => !state)}
                        selectedPipe={selectedPipe}
                      />
                    )
                  },
                  {
                    tabName: TabName.DRAWN_LAYERS,
                    shouldReload: false,
                    tabElement: (
                      <DrawingTab
                        plannedSite={plannedSite}
                        source={source[0]}
                        drawnContext={drawnContext}
                        mapFeaturesStatus={mapFeatures}
                        selectedShape={selectedUserShape}
                        setSelectedShape={setSelectedUserShape}
                      />
                    )
                  },
                  {
                    tabName: TabName.SCANS,
                    shouldReload: false,
                    tabElement: (
                      <ScansTab
                        scans={mapFeatures.scans}
                        reportRelevantScans={setScansBoundingPolygons}
                        setScans={(scans) => setMapFeatures(mapFeatures.setScans(scans))}
                        getScanGeoJson={getScanBoundingPolygon}
                        doneLoading={mapFeatures.doneLoading}
                      />
                    )
                  },
                  {
                    tabName: TabName.SELECTED_PIPE,
                    shouldReload: false,
                    tabElement: (
                      <SelectedPipeTab
                        analysisSite={analysisSite[0]}
                        selectedPipe={selectedPipe}
                        setDisplayedFeatures={setDisplayedFeatures}
                        setEntityValid={setEntityValid}
                        transmitters={mapFeatures.allFeatures.transmitters}
                        travelCourses={mapFeatures.allFeatures.travelCourses}
                        drawnContext={drawnContext}
                        pipesQaGroup={displayedFeatures.pipes.filter(
                          (pipe: AnalysisProductsPipe) => pipe.in_group === true
                        )}
                      />
                    )
                  },
                  {
                    tabName: TabName.ARCGIS_RESOURCES,
                    shouldReload: false,
                    tabElement: (
                      <ArcGISResourcesTab
                        resources={arcGISResources}
                        setResources={setArcGISResources}
                        exportSiteProps={{
                          graphqlSiteId: graphqlSiteId ?? undefined,
                          analysisIds: [
                            ...featuresOnMap.pipes.map((pipe) => pipe.header.id),
                            ...featuresOnMap.polygons.map((polygon) => polygon.header.id)
                          ],
                          mappingIds: mappingEntity.map((feature) => feature.id)
                        }}
                        reportNewTileLayer={(info) =>
                          setExtraTileLayer((tiles) => [
                            ...tiles,
                            { name: info.name, path: info.url, opacity: 0.5, esriLayer: true }
                          ])
                        }
                      />
                    )
                  },
                  {
                    tabName: TabName.MANAGE_FILE_LAYERS,
                    shouldReload: false,
                    tabElement: (
                      <ManageFileLayersTab
                        site={plannedSite}
                        setExtraTileLayersPaths={setExtraTileLayer}
                        loadedLayers={extraTileLayers}
                        geoJsonLayer={geoJsonLayers}
                        setGeoJsonLayers={setGeoJsonLayers}
                        externallySelectedLayerId={externallySelectedLayerId}
                        drawnContext={drawnContext}
                      />
                    )
                  },
                  {
                    tabName: TabName.PLANNING,
                    shouldReload: true,
                    tabElement: (
                      <PlanningTab
                        plannedPolygons={plannedPolygons}
                        setPlannedPolygons={setPlannedPolygons}
                        isDrawing={beingDrawnPlannedPolygon !== undefined}
                        beingDrawnPolygon={beingDrawnPlannedPolygon}
                        setBeingDrawnPolygon={setBeingDrawnPlannedPolygon}
                        sensors={sensors}
                        newTransmitterData={newTransmitterData}
                        clearTransmitterData={() => setNewTransmitterData(undefined)}
                        graphqlSiteId={graphqlSiteId ?? undefined}
                        reportTabOpened={() => setPlanningTabOpened(true)}
                        scans={mapFeatures.scans}
                        plannedEntityId={plannedEntityId}
                      />
                    )
                  },
                  {
                    tabName: TabName.POI,
                    shouldReload: false,
                    tabElement: <PoiTab />
                  },
                  {
                    tabName: TabName.UNIFY,
                    shouldReload: false,
                    tabElement: (
                      user?.account?.idTokenClaims?.roles?.includes(ROLE_UNIFY) ? <UnifyTab
                        travelCourses={mapFeatures.allFeatures.travelCourses}
                        siteId={graphqlSiteId ?? undefined}
                      /> : <h1>You're not Authorized</h1>
                    )
                  }
                ]}></TabForm>
            </div>
            <div className="inforDisplayContainer">
              {!menuCollapsed && (
                <>
                  <InfoDisplay
                    source={source[0]}
                    plannedSite={plannedSite}
                    analysisSite={analysisSite[0]}
                    downloadedFeaturesCount={getFeatureCount(mapFeatures.features)}
                    invalidFeaturesCount={getFeatureCount(mapFeatures.invalidFeatures)}
                    currentErrorsCount={currentErrorsCount}
                    mapFeatures={mapFeatures.allFeatures}
                    currentlyDisplayedGroup={chosenDisplayGroup}
                    expectedLayersCount={expectedLayersCount}
                    currentUserLayersErrorsCount={userLayersWithErrors.length}
                    doneLoading={mapFeatures.doneLoading}
                    expectedFeatures={expectedFeaturesIds}
                    drawnContext={drawnContext}
                    displayedFeatures={displayedFeaturesByGroups[chosenDisplayGroup]}
                    forceNoMissingPipes={forceNoMissingPipes}
                  />
                  {selectedTransmitter && (
                    <div className="showAllEntitiesButtonContainer">
                      <Stack spacing={2}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setSelectedTransmitter(undefined);
                            setChosenDisplayGroup(previousDisplayGroup);
                          }}>
                          Show previous state
                        </Button>
                      </Stack>
                    </div>
                  )}
                </>
              )}
            </div>
            <ExoFuserMap
              boundingGeoJsons={boundingGeoJsonsData}
              expectedGeoJsons={expectedGeoJsons}
              displayedFeature={featuresOnMap}
              setSelectedTransmitter={setSelectedTransmitter}
              setChosenDisplayGroup={setChosenDisplayGroup}
              chosenDisplayGroup={chosenDisplayGroup}
              setPreviousDisplayGroup={setPreviousDisplayGroup}
              transmittersAndTravelCoursesChecked={transmittersAndTravelCoursesChecked}
              displayPolygons={false}
              useColoredTravelCourses={mapDisplayTravelCourses}
              drawnContext={drawnContext}
              setSelectedPipe={changeSelectedPipe}
              onChangePOI={changeSelectedManhole}
              filterPolygon={filterPolygon}
              shouldFilterArea={shouldFilterArea}
              mapEvents={mapEvents}
              arcGISResources={arcGISResources}
              authToken={authToken}
              scansByPolygon={scansBoundingPolygons}
              extraTileLayers={extraTileLayers.map((l) => ({
                ...l,
                authorizationRequired: true,
                urlFormat: l.path
              }))}
              transmitterMapping={transmitterMapping}
              geojsonCustomLayers={geoJsonLayers}
              onGeoJsonLayerClicked={onGeoJsonLayerClicked}
              maxAllowedFrequency={maxAllowedFrequency}
              scanPlanningProps={{
                beingDrawnPlannedPolygon: beingDrawnPlannedPolygon,
                setBeingDrawnPolygon: setBeingDrawnPlannedPolygon as (
                  setFunc: (oldVla: LatLngExpression[]) => LatLngExpression[]
                ) => void,
                plannedPolygons: plannedPolygons,
                setPlannedPolygonPositions: updatePlannedPolygonPosition(),
                plannedTransmittersReporters: getPlannedTransmittersSetters(),
                initialShowPlanningLayer: planningTabOpened
              }}
              selectedShape={selectedUserShape}
              onUserShapeClicked={(shape) =>
                setSelectedUserShape((selectedShape) =>
                  shape === selectedShape ? undefined : shape
                )
              }
              siteWideDepthData={siteWideDepthData}
              plannedPolygons={geoJsonLayers}
              setPlannedPolygons={setGeoJsonLayers}
              mappingEntity={mappingEntity}
            />
          </div>
        </>
      )}
    </>
  );
}

interface ExoFuserPageProps {
  locations: StateDetails[];
}

export default ExoFuserPage;
