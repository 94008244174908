import { createContext, useState } from "react";
import { Layer } from "../../Utils/layerUtils";

const LayerContext = createContext<{
  lastLayer: Layer<any, any> | undefined;
  setLastLayer: React.Dispatch<React.SetStateAction<Layer<any, any> | undefined>>;
  globalLayerSelected: string | undefined;
  setGlobalLayerSelected: React.Dispatch<React.SetStateAction<string | undefined>>;
  globalChangeLayers: boolean | undefined;
  setGlobalChangeLayers: React.Dispatch<React.SetStateAction<boolean | undefined>>
}>({
  lastLayer: undefined,
  setLastLayer: () => {},
  globalLayerSelected: undefined,
  setGlobalLayerSelected: () => {},
  globalChangeLayers: undefined,
  setGlobalChangeLayers: () => {}
});


export function LayerProvider({ children }: any) {
  const [lastLayer, setLastLayer] = useState<Layer<any, any>>();
  const [globalLayerSelected, setGlobalLayerSelected] = useState<string>();
  const [globalChangeLayers, setGlobalChangeLayers] = useState<boolean>();

  return  <LayerContext.Provider value={{ lastLayer, setLastLayer, globalLayerSelected, setGlobalLayerSelected, globalChangeLayers, setGlobalChangeLayers }}>
            {children}
          </LayerContext.Provider>;
}

export default LayerContext;
