import { useEffect, useMemo, useState } from "react";
import { Scan, useSearchScansLocationLazyQuery } from "../../Generated/Graphql";
import { SiteInfo } from "../Common/ChooseSite";
import { Box, Button, Card, CircularProgress, IconButton, Stack, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import "./SETTING.css"
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ChangeScanLocationRequest, LocationFixEntry, Api as ServicesApi } from "../../Generated/ExoServicesAPI";
import { getServicesRequestParams } from "../../Utils/azureAuth";
import ScanDateQuery from "./ScanDateQuery";
import { reportAllPagesResults } from "../../Utils/graphqlUtils";

const minRange = 1;
const maxRange = 998;

function getNumberAtPosition(str: string | undefined | null, position: number): number {
    if(str === undefined || !str) return 1;
    const matches = str.match(/\d+/g);
    if (matches && position > 0 && position <= matches.length) {
        return parseInt(matches[position - 1], 10);
    }
    return 1;
}

export default function SettingChangeScanLocationInSite() {
    const [selectedScan, setSelectedScan] = useState<Scan | null>(null);
    const [selectedScanSite, setSelectedScanSite] = useState<SiteInfo | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [polygon, setPolygon] = useState<number>(1);
    const [snake, setSnake] = useState<number>(1);
    const [part, setPart] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const [, scansResults] = useSearchScansLocationLazyQuery();
    const [siteScans, setSiteScans] = useState<Scan[] | undefined>([]);


    useEffect(() => {
        setPolygon(getNumberAtPosition(selectedScan?.name, 1));
        setSnake(getNumberAtPosition(selectedScan?.name, 2));
        setPart(getNumberAtPosition(selectedScan?.name, 3));
    },[selectedScan]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const loadScans = useMemo(() => async function(siteId: string) {
        let startScan = 0;
        reportAllPagesResults(
            scansResults,
            (res) => {
                const scans = (res?.data?.searchScans?.items ?? []).filter(item => item !== null) as Scan[];
                if(!scans || scans.length === 0) {
                    return;
                }
                setSiteScans((sScans) => {
                    return [...(startScan === 0 ? []: sScans || []), ...scans];
                });
                startScan += scans.length;
            },
            (res) => res.data.searchScans?.nextToken,
            { siteId: siteId }
        );
    }, []);

    const handleMoveScan = async () => {
        if (!selectedScan || !selectedScan.id || !selectedScanSite || !selectedScanSite.id) {
            return;
        }

        const api = new ServicesApi();
        try {

            if (!selectedScan.name) return;
            const data: ChangeScanLocationRequest = {
                polygon: {old: getNumberAtPosition(selectedScan.name, 1), new: polygon} as LocationFixEntry,
                snake: {old: getNumberAtPosition(selectedScan.name, 2), new: snake} as LocationFixEntry,
                part: {old: getNumberAtPosition(selectedScan.name, 3), new: part} as LocationFixEntry
            }

            const userConfirmed = window.confirm(`Are you sure you want to move the scan to the new location?\n
                old: ${getNumberAtPosition(selectedScan.name, 1)}_${getNumberAtPosition(selectedScan.name, 2)}_${getNumberAtPosition(selectedScan.name, 3)}\n
                new: ${polygon}_${snake}_${part}`);
                if (!userConfirmed) {
                    setSelectedScan(null);
                    setSelectedScanSite(null);
                    return;
            }

            setLoading(true);
            await api.fix.changeScanLocationInSite(
              selectedScan.id , data, await getServicesRequestParams()
            );

            setErrorMessage("Change Scan Location In Site - Success");
        } catch (e) {
            setErrorMessage(`Got an error while trying to change location, ${JSON.stringify(e)}`);
        } finally {
            setSelectedScan(null);
            setSelectedScanSite(null);
            setLoading(false);
        }

    }
    const getMessageStyle = () => {
        if (errorMessage.toLowerCase().includes("success")) {
            return { color: "green", fontWeight: "bold" };
        } else if (errorMessage) {
            return { color: "red", fontWeight: "bold" };
        }
        return {};
    };


    if (loading) {
        return (
          <Card
            component={Stack}
            flex={1}
            justifyContent="center"
            alignItems="center"
            direction="row"
            sx={{
              m: 1,
              p: 1
            }}>
            <CircularProgress />
          </Card>
        );
      }



    return (
        <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom>
                    Change Scan Location In Site
                </Typography>
                <IconButton onClick={toggleExpand}>
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </Box>
            {isExpanded && (
            <div className="one_line">
            <ScanDateQuery setSelectedScan={setSelectedScan} selectedScan={selectedScan} setSelectedScanSite={setSelectedScanSite} loadScans={loadScans} siteScans={siteScans} title="Source Scan" format={'^p\\d+_s\\d+_part\\d+_.+.'} />
            {selectedScanSite && selectedScan && selectedScan?.name?.length !== undefined && selectedScan.name.length > 0 &&  (
                <>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography>polygon</Typography>
                            <TextField
                                type="number"
                                inputProps={{ min: minRange, max: maxRange }}
                                defaultValue={getNumberAtPosition(selectedScan.name, 1) ?? 1}
                                variant="outlined"
                                onChange={(e) => setPolygon((e.target as HTMLInputElement).valueAsNumber)}
                            />
                        </TableCell>
                        <TableCell>
                            <Typography>snake</Typography>
                            <TextField
                                type="number"
                                inputProps={{ min: minRange, max: maxRange }}
                                defaultValue={getNumberAtPosition(selectedScan.name, 2) ?? 1}
                                onChange={(e) => setSnake((e.target as HTMLInputElement).valueAsNumber)}
                                variant="outlined"
                            />
                        </TableCell>
                        <TableCell>
                            <Typography>part</Typography>
                            <TextField
                                type="number"
                                inputProps={{ min: minRange, max: maxRange }}
                                defaultValue={getNumberAtPosition(selectedScan.name, 3) ?? 1}
                                onChange={(e) => setPart((e.target as HTMLInputElement).valueAsNumber)}
                                variant="outlined"
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', '& button': { m: 1, ml: 2 } }}>
                <Button
                        variant="contained"
                        size="large"
                        value="MoveScan"
                        className="selectButton"
                        disabled={!selectedScan || polygon < minRange || snake < minRange || part < minRange  || polygon > maxRange || snake > maxRange || part > maxRange}
                onClick={handleMoveScan}>Scan Location</Button>
                </Box>
                </>
                )}
            </div>)}
            {errorMessage && (
                <Typography sx={getMessageStyle()}>
                    {errorMessage}
                </Typography>
            )}
        </Box>
    );

}
