import { saveAs } from 'file-saver'
import { LocationAddress } from './TableRow';
import { CrewCondition } from './TableRowCrew';
import PdfGenerator from "./PdfGenerator";
import {pdf} from '@react-pdf/renderer';
import L from 'leaflet';
import { DrawCondition } from './TableRowDraw';

export interface MapImageOptions {
    width: number;
    height: number;
    left: number;
    top: number;
  }

export const createPictureMap = (existingSquare: HTMLElement, map: L.Map) => {
    if(!map) return;

    const imageOptions: MapImageOptions = {
      width: existingSquare.clientWidth,
      height: existingSquare.clientHeight,
      left: existingSquare.offsetLeft,
      top: existingSquare.offsetTop,
    };

    takeScreenshot(imageOptions)

  };


export const removeSquare = (mapContainerRef: React.RefObject<HTMLElement | null>) => {
  const mapContainer: HTMLElement | null = mapContainerRef?.current;
  if(!mapContainer) {
    return;
  }
  const existingSquare = document.getElementById('squareOverlay');

  if (existingSquare) {
    mapContainer.removeChild(existingSquare);
  }
}

export const paintSquare = (mapContainerRef: React.RefObject<HTMLElement | null>, map: L.Map )=> {
    const mapContainer: HTMLElement | null = mapContainerRef?.current;
    if(!mapContainer) {
      return;
    }

    const existingSquare = document.getElementById('squareOverlay');

    if (existingSquare) {
      createPictureMap(existingSquare, map);
      removeSquare(mapContainerRef);
      return
    }

    const offsetTop = mapContainer.offsetTop;
    const height = mapContainer.clientHeight;
    const offsetLeft = mapContainer.offsetLeft;
    const width = mapContainer.clientWidth;

    const ratio = 2.267;
    let squareHeight = height * 0.75;
    let squareWidth = squareHeight * ratio;

    if (width/height < ratio) {
      squareWidth = width * 0.75;
      squareHeight = squareWidth / ratio;
    }

    const squareLeft = offsetLeft + (width - squareWidth) / 2;
    const squareTop = offsetTop + (height - squareHeight) / 3;

    const squareOverlay = document.createElement('div');
    squareOverlay.id = 'squareOverlay';
    squareOverlay.style.position = 'absolute';
    squareOverlay.style.left = `${squareLeft}px`;
    squareOverlay.style.top = `${squareTop}px`;
    squareOverlay.style.width = `${squareWidth}px`;
    squareOverlay.style.height = `${squareHeight}px`;
    squareOverlay.style.border = '4px dashed black';
    squareOverlay.style.backgroundColor = 'pink';
    squareOverlay.style.opacity = '0.3';
    squareOverlay.style.zIndex = '9999';
    mapContainer.appendChild(squareOverlay);

    squareOverlay.addEventListener('dblclick', () => {
      squareOverlay.remove();
    });
  }

export const handleDownload = async (pdfImage: string) => {
    const location_address: LocationAddress[] = [
      {id: '0', street_number: ' ', name: ' ', city: ' '},
  ]

  const draw_condition: DrawCondition[] = []
  const crew_condition: CrewCondition[] = []

  const pdfBlob = await pdf(<PdfGenerator pdfImage={pdfImage} location_address={location_address} crew_condition={crew_condition} draw_condition={draw_condition} />).toBlob()
    saveAs(pdfBlob , 'map.pdf')
  }


  const takeScreenshot = (imageOptions: MapImageOptions ) => {

    const canvas = document.createElement("canvas");
    navigator.mediaDevices
      .getDisplayMedia({ preferCurrentTab: true } as any)
      .then((stream) => {
        const track = stream.getVideoTracks()[0];
        const capture = new ImageCapture(track);
        capture.grabFrame().then((bitmap) => {
          track.stop();
          canvas.width = imageOptions.width;
          canvas.height = imageOptions.height;
          const context = canvas.getContext("2d");
          if (context === null) {
            console.log("Canvas context was null. Aborting screenshot.");
            return;
          }
          context.drawImage(bitmap, imageOptions.left, imageOptions.top, imageOptions.width, imageOptions.height, 0, 0, imageOptions.width, imageOptions.height);
          handleDownload(canvas.toDataURL("image/png"));
        });
      })
      .catch((e) => console.log(e));
  }
