import { ParsedTargetObect } from '../../../../Types/MappingEntities';
import { FilterCategories, Filters } from '../types';

export const isImage = (fileName: string) => {
  if (!fileName) return;
  return fileName.toLowerCase().endsWith('.png') || fileName.toLowerCase().endsWith('.jpg');
};

export const isVideo = (fileName: string) => {
  if (!fileName) return;
  return fileName.toLowerCase().endsWith('.mov') || fileName.toLowerCase().endsWith('.mp4');
};

export const getHasMedia = (pois: ParsedTargetObect[]) => {
  return { hasMedia: pois.some(isMatchMedia) };
};

export const isMatchMedia = (p: ParsedTargetObect) => !!p?.target_info?.fileNames?.length;
export const getFileNames = (p?: ParsedTargetObect): string[] => p?.target_info?.fileNames || [];

export const isMatchVideo = (p: ParsedTargetObect) => {
  const fileNames = getFileNames(p);
  return !!fileNames.some((s) => s.endsWith('.MOV'));
};

export const isMatchImage = (p: ParsedTargetObect) => {
  const fileNames = getFileNames(p);
  return !!fileNames.some(
    (s) => s.toLowerCase().endsWith('.jpg') || s.toLowerCase().endsWith('.png')
  );
};
