import { Autocomplete, Button, FormControl, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { Dict } from "../../../BasicTypes";
import { ScanDetails } from "../DataTypes/MapEntities";

const SELECT_ALL_VAL = "Select All";
const DESELECT_ALL_VAL = "Deselect All";

const DEFAULT_SENSORS = ["COBRA_GPR", "FLC_370", "MINILEMON_GROUND", "NEL_MD"];
const EXPECTED_DIVISIONS = ["1", "2"];

function ScansPolygons({ scans, reportRelevantScans, setScans, getScanGeoJson, doneLoading }: ScansPolygonsProps) {
  const [sensors, setSensors] = React.useState<string[]>([]);
  const [selectedSensors, setSelectedSensors] = React.useState<string[]>([]);

  React.useEffect(() => {
    const sensorList = [...new Set<string>(scans.map((s) => s.sensor))];
    if (sensorList.length !== selectedSensors.length) {
      sensorList.splice(0, 0, SELECT_ALL_VAL);
    } else {
      sensorList.splice(0, 0, DESELECT_ALL_VAL);
    }
    setSensors(sensorList);
  }, [scans, selectedSensors]);

  React.useEffect(() => {
    if (doneLoading) {
      const relevantSensors = sensors.filter((s) => DEFAULT_SENSORS.includes(s as string));
      setSelectedSensors(relevantSensors);
    } else {
      setSelectedSensors([]);
      reportRelevantScans({});
    }
  }, [doneLoading]);

  async function showScans() {
    const senorToScansDict: Dict<SensorPolygonsData> = {};
    const scansWithoutS3Ref: ScanDetails[] = [];
    const newScans = [...scans];
    reportRelevantScans({});
    await Promise.all(
      scans.map(async (scan, i) => {
        if (!selectedSensors.includes(scan.sensor)) {
          return;
        }
        if (scan.boundingPolygonGeoJson === undefined) {
          if (scan.boundingPolygonS3Ref === undefined) {
            scansWithoutS3Ref.push(scan);
            return;
          }
          newScans[i] = { ...scan, boundingPolygonGeoJson: JSON.stringify(await getScanGeoJson(scan)) };
        }
        if (!Object.keys(senorToScansDict).includes(scan.sensor)) {
          senorToScansDict[scan.sensor] = {
            sensorScans: {},
          };
        }

        const sensorData = senorToScansDict[scan.sensor];
        const polygonKey = scan.polygon?.toString() ?? "";
        if (!Object.keys(sensorData.sensorScans).includes(polygonKey)) {
          sensorData.sensorScans[polygonKey] = {
            scans: {},
            expectedSubDivisions: EXPECTED_DIVISIONS,
          };
        }

        const scanDict = sensorData.sensorScans[polygonKey].scans;
        const snakeKey = scan.snake?.toString() ?? "";
        if (!Object.keys(scanDict).includes(snakeKey)) {
          scanDict[snakeKey] = [];
        }

        scanDict[snakeKey].push({ ...newScans[i] });
      })
    );
    if (scansWithoutS3Ref.length !== 0) {
      console.log("Scans without s3 ref ids", scansWithoutS3Ref.map((s) => s.id).join());
    }
    reportRelevantScans(senorToScansDict);
    setScans(newScans);
  }

  return (
    <FormControl>
      <Typography variant="h5">Show Scans Polygons</Typography>
      <Stack gap="1rem">
        <Autocomplete
          options={sensors}
          getOptionLabel={(o) => o}
          placeholder="Sensors"
          renderInput={(params) => <TextField {...params} key={`option-${params.id}`} placeholder="Sensors" />}
          sx={{ width: 500 }}
          value={selectedSensors}
          onChange={(e, newVal) => {
            if (newVal.includes(SELECT_ALL_VAL)) {
              setSelectedSensors(sensors.slice(1));
            } else if (newVal.includes(DESELECT_ALL_VAL)) {
              setSelectedSensors([]);
            } else {
              setSelectedSensors(newVal);
            }
          }}
          size="small"
          disableCloseOnSelect={true}
          limitTags={5}
          disabled={!doneLoading}
          multiple
        />
        <Stack direction="row" gap="1rem">
          <Button onClick={showScans} variant="contained" key="showScan" disabled={!doneLoading}>
            Show scan polygons
          </Button>
          <Button onClick={() => reportRelevantScans({})} variant="contained" key="hideScan" disabled={!doneLoading}>
            Hide scan polygons
          </Button>
        </Stack>
      </Stack>
    </FormControl>
  );
}

export interface ScansPolygonsProps {
  scans: ScanDetails[];
  reportRelevantScans: (scans: Dict<SensorPolygonsData>) => void;
  setScans: (scans: ScanDetails[]) => void;
  getScanGeoJson: (scan: ScanDetails) => Promise<string>;
  doneLoading: boolean;
}

export interface SensorPolygonsData {
  sensorScans: Dict<ScanPolygonData>;
}

export interface ScanPolygonData {
  scans: Dict<ScanDetails[]>;
  expectedSubDivisions: string[];
}

export default ScansPolygons;
