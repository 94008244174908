import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../Generated/ExoDBAPI";
import { getRequestParams } from "../../../Utils/azureAuth";

function DeleteSiteForm({ siteId, siteName }: { siteId: string; siteName: string }) {
  const [areYouSure, setAreYouSure] = React.useState(false);
  const deleteButtonRef = React.useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  async function deleteSite(e: React.FormEvent) {
    e.preventDefault();
    if (!areYouSure) {
      window.alert("If you mean business check the box to proceed down this path");
      return;
    }
    const api = new Api();
    const deleteButton = deleteButtonRef.current;
    if (deleteButton === null) {
      throw Error("Delete button ref is null");
    }
    try {
      deleteButton.disabled = true;
      deleteButton.classList.add("deleteButtonClicked");
      await api.sites.deleteSite(siteId, await getRequestParams());
      navigate("/");
    } catch (e) {
      let errorMessage = "Unknown Error while deleting site";
      if (e instanceof Error) {
        errorMessage = `Failed to delete site. ${e.message}`;
      }
      window.alert(errorMessage);
    } finally {
      deleteButton.disabled = false;
      deleteButton.classList.remove("deleteButtonClicked");
    }
  }

  function areYouSureChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setAreYouSure(e.target.checked);
  }
  return (
    <div>
      <h2 className="pageHeader">Delete Site {siteName}</h2>
      <span className="warningText">
        <img src="/warning.png" className="warningIcon" />{" "}
        <p>
          This action will delete the site <b>{siteName}</b>! <br />
          The sharepoint folders will not be modified this will cause problems when creating a site with the same name.
          <br />
          The S3 dirs will be moved to the deleted sites dir and will be needed to be removed manually.
        </p>
      </span>
      <span className="deleteSiteButtonContaner">
        <form onSubmit={deleteSite}>
          <div className="formSection">
            <span className="siteFormEntry">
              <span>
                <input type="checkbox" id="areYouSure" name="areYouSure" onChange={areYouSureChanged} /> I want to
                delete <b>{siteName}</b>
              </span>
            </span>
            <span className="siteFormEntryTopIsolated">
              <input
                type="submit"
                className="deleteButton"
                name="deleteSiteButton"
                id="deleteSiteButton"
                value="Delete Site"
                ref={deleteButtonRef}
              ></input>
            </span>
          </div>
        </form>
      </span>
    </div>
  );
}

export default DeleteSiteForm;
