import * as React from "react";
import { Api } from "../../Generated/ExoDBAPI";
import { getRequestParams } from "../../Utils/azureAuth";

function DownloadPolygonsForm({ siteId }: PolygonFormProps) {
  const submitRef = React.useRef<HTMLInputElement>(null);

  async function downloadPolygons(e: React.FormEvent) {
    e.preventDefault();
    const submitButton = submitRef.current;
    if (submitButton === null) {
      throw Error("Ref is null");
    }
    submitButton.disabled = true;
    submitButton.classList.add("buttonClicked");
    const api = new Api();
    try {
      const urls = (await api.sites.getPolygonUrls(siteId, await getRequestParams())).data.polygonsUrls;
      for (const url of urls) {
        window.open(url.url);
      }
    } catch (e) {
      let errorMessage = "Unknown Error while downloading polygons";
      if (e instanceof Error) {
        errorMessage = `Failed to download polygons. ${e.message}`;
      }
      window.alert(errorMessage);
    } finally {
      submitButton.disabled = false;
      submitButton.classList.remove("buttonClicked");
    }
  }
  return (
    <>
      <div>
        <form onSubmit={downloadPolygons}>
          <input
            type="submit"
            name="downloadPolygons"
            id="downloadPolygons"
            value="Download polygons"
            className="formButton"
            ref={submitRef}
          />
        </form>
      </div>
    </>
  );
}

interface PolygonFormProps {
  siteId: string;
}

export default DownloadPolygonsForm;
