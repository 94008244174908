import { Typography } from "@mui/material";
import * as React from "react";
import { useParams } from "react-router";
import { MAXIMUM_CHUNK_SIZE, MAX_UPLOAD_CONCURRENCY } from "../../config/fileUploadConfig";
import { Api, FileType } from "../../Generated/ExoDBAPI";
import { uploadFileUsingPresignedUrl } from "../../Utils/fileUploadUtils";
import FileInput, { FileInputRef } from "../Inputs/FileInput";
import PageCard from "../Templates/PageCard";

function UploadOriginalImageTifPage() {
  const BackgroundImageRef = React.useRef<FileInputRef>(null);
  const submitRef = React.useRef<HTMLInputElement>(null);
  const { siteId } = useParams();

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const submitButton = submitRef.current;

    if (submitButton === null || siteId === undefined) {
      return;
    }

    submitButton.className = "buttonClicked";
    submitButton.disabled = true;
    try {
      const api = new Api();
      await uploadFileUsingPresignedUrl(
        BackgroundImageRef,
        api,
        FileType.OriginalGeoTif,
        siteId,
        MAXIMUM_CHUNK_SIZE,
        MAX_UPLOAD_CONCURRENCY
      );
    } finally {
      submitButton.className = "formButton";
      submitButton.disabled = false;
    }
  }
  return (
    <PageCard>
      <Typography variant="h4">Upload TIF</Typography>
      <form className="siteForm" onSubmit={onSubmit}>
        <div className="formSection">
          <span className="siteFormEntry">
            <label>Upload large geotiff site image:</label>
            <FileInput multipleFiles={false} ref={BackgroundImageRef} key="BackgroundImageInput" accept=".tif,.tiff" />
          </span>
          <span className="formSectionIsolated">
            <input type="submit" className="formButton" value="Upload large geotiff site image" ref={submitRef} />
          </span>
        </div>
      </form>
    </PageCard>
  );
}

export default UploadOriginalImageTifPage;
