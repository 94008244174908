import { Card, ClickAwayListener, Stack } from '@mui/material';
import { PlayIcon } from '../../../../SVG';
import { useState } from 'react';
import { Tooltip } from '../../../../../shared';

export const VideoShow = ({ src }: { src: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const videoTooltipContent = (
    <Stack>
      <video
        preload="metadata"
        width="500"
        height="500"
        controls
        //   controls
      >
        <source src={src} type={`video/mp4`} />
        Your browser does not support the video tag.
      </video>
    </Stack>
  );

  const handleCloseTooltip = () => setIsOpen(false);
  const handleOpenTooltip = () => setIsOpen(true);

  return (
    <ClickAwayListener onClickAway={handleCloseTooltip}>
      <div>
        <Tooltip
          title={videoTooltipContent}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={isOpen}>
          <Card
            sx={{
              height: 80,
              width: 80,
              position: 'relative'
            }}
            key={src}>
            <Stack
              onClick={handleOpenTooltip}
              sx={{
                position: 'absolute',
                inset: 0,
                zIndex: 99999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
              <PlayIcon
                fontSize="large"
                sx={{
                  color: 'rgba(255,2555,255,0.6)'
                }}
              />
            </Stack>
            <video
              preload="metadata"
              width="100%"
              height="100%"
              //   controls
            >
              <source src={src} type={`video/mp4`} />
              Your browser does not support the video tag.
            </video>
          </Card>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
