import { Divider, Grid, RadioGroup, Stack } from '@mui/material';
import { DislpayPipeModeValues } from './type';
import { InfoIcon } from '../../../SVG/Info';
import { ChangeEvent, useMemo } from 'react';
import { getFeaturesGraphicConfig } from '../../../../config/mapConfig';
import { Legend, RadioButton, Tooltip, Typography } from '../../../../shared';
import { capitalizeString } from '../../../../Utils/generalUtils';

type DislpayPipeModeProps = {
  onChange: (displayMode: DislpayPipeModeValues) => void;
  value: DislpayPipeModeValues;
};

const colors = getFeaturesGraphicConfig().sensorColorbyConfidenceMatrix;

export const DisplayPipeMode = ({ onChange, value }: DislpayPipeModeProps) => {
  const { sensorColors, sensorsAndConfidenceColors } = useMemo(() => {
    return Object.entries(colors).reduce(
      (acc, [k, v]) => {
        const { default: d, ...rest } = v;
        acc.sensorColors = [...acc.sensorColors, { [k]: d }];
        acc.sensorsAndConfidenceColors = [...acc.sensorsAndConfidenceColors, { [k]: rest }];
        return acc;
      },
      { sensorColors: [{}], sensorsAndConfidenceColors: [{}] }
    );
  }, []);
  const { transmitterMethod } = getFeaturesGraphicConfig();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(value as DislpayPipeModeValues);
  };

  const renderSensorsLegend = (
    <Grid container padding={2}>
      {sensorColors.map((sc) =>
        Object.entries(sc).map(([k, v]) => {
          return (
            <Grid item xs={3}>
              <Legend color={v as string} label={k} />
            </Grid>
          );
        })
      )}
    </Grid>
  );
  const TransmittersLegend = (
    <Grid container padding={2}>
      {Object.entries(transmitterMethod).map(([k, v]) => {
        k = capitalizeString(k);
        return (
          <Grid item xs={6}>
            <Legend color={v as string} label={k} />
          </Grid>
        );
      })}
    </Grid>
  );

  const renderSensorAndConfidenceLegend = (
    <Grid container padding={2} spacing={5}>
      {sensorsAndConfidenceColors.map((sfc) =>
        Object.entries(sfc).map(([k, v]) => {
          return (
            <Grid key={k} item xs="auto">
              <Stack direction="column">
                <Typography fontWeight={600}>{k}</Typography>
                <Divider sx={{ my: 1 }} />
                {Object.entries(v as Record<string, string>).map(([ks, vs]) => (
                  <Legend color={vs} label={ks} />
                ))}
              </Stack>
            </Grid>
          );
        })
      )}
    </Grid>
  );

  return (
    <Stack direction="column" spacing={0.5}>
      <Typography fontWeight={500}>Pipes Colors Mode</Typography>
      <RadioGroup value={value} onChange={handleChange}>
        <Stack direction="row" alignItems="center">
          <RadioButton
            checked={value === DislpayPipeModeValues.SensorAndConfidence}
            label="Sensors And Confidence"
            value={DislpayPipeModeValues.SensorAndConfidence}
          />
          <Tooltip title={renderSensorAndConfidenceLegend}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        </Stack>
        <Stack direction="row" alignItems="center">
          <RadioButton
            checked={value === DislpayPipeModeValues.Sensor}
            label="Sensors"
            value={DislpayPipeModeValues.Sensor}
          />
          <Tooltip title={renderSensorsLegend}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        </Stack>
        <Stack direction="row" alignItems="center">
          <RadioButton
            checked={value === DislpayPipeModeValues.Transmitter}
            label="Transmitters"
            value={DislpayPipeModeValues.Transmitter}
          />
          <Tooltip title={TransmittersLegend}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        </Stack>
      </RadioGroup>
    </Stack>
  );
};
