import { FormControl, MenuItem, Stack, Typography } from "@mui/material";
import * as React from "react";
import { ClientDetails, SiteType, StateDetails } from "../../Generated/ExoDBAPI";
import "../../styles/createSite.css";
import CreateSiteSelect from "./CreateSiteSelect";
import CreateSiteTextField from "./CreateSiteTextField";
import { createSiteName, validateFreeText } from "./SiteUtils";

function GeneralInfoFormSection({
  states,
  clients,
  siteTypes,
  freeTextLocation,
  freeTextName,
  stateId,
  clientId,
  siteType,
  onFreeTextLocationChange,
  onFreeTextNameChange,
  onStateIdChange,
  onClientIdChange,
  onSiteTypeChange,
  evalErrors,
}: GeneralInfoFormSectionProps) {
  function validateNameInputs() {
    if (clientId === "") {
      return [false, "Pick a Client"];
    }
    if (!validateFreeText(freeTextLocation)) {
      return [false, "Enter a valid location name"];
    }
    if (!validateFreeText(freeTextName)) {
      return [false, "Enter a valid name"];
    }
    return [true, ""];
  }

  function getNamePreview() {
    const [valid, errorMessage] = validateNameInputs();
    if (!valid) {
      return errorMessage;
    }
    return createSiteName(clients, clientId, freeTextLocation, freeTextName);
  }

  function getS3DirPreview() {
    if (stateId === "") {
      return "Pick a State";
    }
    const [valid, errorMessage] = validateNameInputs();
    if (!valid) {
      return errorMessage;
    }
    return `${states.find((s) => s.id === stateId)?.stateName}_${createSiteName(
      clients,
      clientId,
      freeTextLocation,
      freeTextName
    )}`;
  }

  return (
    <FormControl>
      <span className="siteFormEntry" key="siteNameEntry">
        <Stack direction="row" spacing="1em">
          <CreateSiteSelect
            label="Site Type"
            onChange={(e) => onSiteTypeChange(e.target.value as SiteType)}
            value={siteType}
            key="site type"
            error={evalErrors && siteType === ""}
            select
          >
            {siteTypes.map((s) => {
              return (
                <MenuItem value={s} key={s}>
                  {s}
                </MenuItem>
              );
            })}
          </CreateSiteSelect>
          <span className="siteNameWrapper">
            <CreateSiteSelect
              label="State"
              onChange={(e) => onStateIdChange(e.target.value)}
              value={stateId}
              error={evalErrors && stateId === ""}
              key="state"
              select
            >
              {states.map((s) => {
                return (
                  <MenuItem value={s.id} key={s.id}>
                    {s.stateName}
                  </MenuItem>
                );
              })}
            </CreateSiteSelect>
            <span className="underscoreWrapper">__</span>
            <CreateSiteSelect
              label="Customer"
              onChange={(e) => onClientIdChange(e.target.value)}
              value={clientId}
              error={evalErrors && clientId === ""}
              key={"customer"}
              select
            >
              {clients.map((c) => {
                return (
                  <MenuItem value={c.id} key={c.id}>
                    {c.name}
                  </MenuItem>
                );
              })}
            </CreateSiteSelect>
            <span className="underscoreWrapper" key="firstUnderscore">
              __
            </span>
            <CreateSiteTextField
              label="Location"
              error={evalErrors && !validateFreeText(freeTextLocation)}
              onChange={(e) => onFreeTextLocationChange(e.target.value)}
              key="locationFreeText"
            ></CreateSiteTextField>
            <span className="underscoreWrapper" key="secondUnderscore">
              __
            </span>
            <CreateSiteTextField
              label="Name"
              onChange={(e) => onFreeTextNameChange(e.target.value)}
              defaultValue={freeTextName}
              error={evalErrors && !validateFreeText(freeTextName)}
              key="nameFreeText"
            ></CreateSiteTextField>
          </span>
        </Stack>
      </span>
      <span className="siteFormEntry siteNamePreview" key="siteNamePreviewEntry">
        <Typography key="siteNamePreviewEntry">{`Name: ${getNamePreview()}`}</Typography>
        <Typography className="siteFormEntry" key="s3RefNamePreviewEntry">
          {`S3 Dir Name: ${getS3DirPreview()}`}
        </Typography>
      </span>
    </FormControl>
  );
}

export interface GeneralInfoFormSectionProps {
  states: StateDetails[];
  clients: ClientDetails[];
  siteTypes: SiteType[];
  freeTextLocation: string;
  freeTextName: string;
  stateId: string;
  clientId: string;
  siteType: SiteType | "";
  onFreeTextLocationChange: (val: string) => void;
  onFreeTextNameChange: (val: string) => void;
  onStateIdChange: (val: string) => void;
  onClientIdChange: (val: string) => void;
  onSiteTypeChange: (val: SiteType) => void;
  evalErrors: boolean;
}

export default GeneralInfoFormSection;
