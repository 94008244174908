import React from "react";
import "./styles/App.css";
import "./styles/tabs.css";
import "./styles/selectedPipe.css";
import "./styles/drawnLayers.css";

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { UserProvider } from "./Components/Contexts/UserContext";
import AppProviders from './Components/Contexts/AppProviders';

import Exoplanner from "./Exoplanner.tsx";
import L from "leaflet";

import "./plugins/plugins";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getBearerToken } from "./Utils/azureAuth";

//delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: await getBearerToken(),
    },
    uri: process.env.REACT_APP_GRAPHQL_HOST,
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_HOST,
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

console.log("client", authLink.concat(httpLink), httpLink);

function App() {
  const UserContext = React.useContext({});

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ApolloProvider client={client}>
          <AppProviders>
            <Exoplanner />
          </AppProviders>
        </ApolloProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
