import * as React from "react";
import { ClientDetails, SiteType, StateDetails } from "../../Generated/ExoDBAPI";
import AreYouSureModal, { confirmationField } from "../Templates/AreYouSureModal";

function CreateSiteConfirmationModal({
  siteName,
  state,
  client,
  siteType,
  open,
  acceptCallback,
  cancelCallback,
}: CreateSiteConfirmationModal) {
  const fields: confirmationField[] = [
    { label: "Site Name", value: siteName },
    { label: "State", value: state === undefined ? "Invalid State" : state.stateName },
    { label: "Client", value: client === undefined ? "Invalid Client" : client.name },
    { label: "Site Type", value: siteType },
  ];
  return (
    <AreYouSureModal
      confirmationTitle="Confirm that these are the input listed below are correct"
      fields={fields}
      open={open}
      acceptCallback={acceptCallback}
      cancelCallback={cancelCallback}
    />
  );
}

export interface CreateSiteConfirmationModal {
  siteName: string;
  state?: StateDetails;
  client?: ClientDetails;
  siteType: SiteType;
  open: boolean;
  acceptCallback: () => void;
  cancelCallback: () => void;
}

export default CreateSiteConfirmationModal;
