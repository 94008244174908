export enum DepthValueIssue {
  NotQueried = "Not queried",
  QueryError = "Query Error",
  NotSet = "Value Not Set",
  SiteNotSet = "Site not set",
}

export interface SiteWideDepthData {
  geoidValue: number | DepthValueIssue;
  setGeoidValue: (geoidValue: number | DepthValueIssue) => void;
  graphqlSiteId: string | undefined;
  siteName?: string;
}

export function getGeoidValueNumber(geoidValue: number | DepthValueIssue): number | undefined {
  if (Object.values(DepthValueIssue).includes(geoidValue as any)) {
    return undefined;
  }

  return geoidValue as number;
}
