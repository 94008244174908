import { GeoJsonLayer } from '../Utils/layerUtils';

export const typeObject: {
  [key: string]: {
    label: string;
    color: string;
    backgroundColor: string;
  };
} = {
  MANHOLE: {
    label: 'MANHOLE',
    color: '#0000ff',
    backgroundColor: 'white'
  },
  DRAINAGE: {
    label: 'DRAINAGE',
    color: '#00ff00',
    backgroundColor: 'white'
  },
  TRENCH: {
    label: 'TRENCH',
    color: '#ff0000',
    backgroundColor: 'white'
  },
  UTILITY_MARK: {
    label: 'UTILITY_MARK',
    color: '#ffff32',
    backgroundColor: 'white'
  }
};

export const getColorFromTypeObject = (label: string | undefined): string | undefined => {
  if (!label) return undefined;
  const item = typeObject[label.toUpperCase()];
  return item?.color;
};

export const exovision_edit = 'exovision_edit';
export const exovision = 'exovision';
export const exovision_s3_bucket = 'exodigo-sites-user-layers';

export interface SubLayer {
  id: string;
  name: string;
  overrideColor: boolean;
  selected: boolean;
  subLayers: GeoJsonLayer[];
  visible: boolean;
  color: string;
  data: {
    type: string;
    geometry: {
      coordinates: [number, number][][];
      type: string;
    };
    properties: {
      type: string;
      confidence: number;
      create_by: string;
    };
  };
  metadata: {
    type: string;
  };
}
