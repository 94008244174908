import * as React from "react";
import { Checkbox, FormControl, FormControlLabel, Typography } from "@mui/material";
import { Dict } from "../../BasicTypes";
import { Stack } from "@mui/system";
import { RUN_STATUS_ORDER, RUN_STATUS_TO_DISPLAY_NAME } from "./MonitorTypes";

function StageStatusFilter({ stage, reportStatusChange, overrideCheckState }: StageStatusFilterProps) {
  const [filterStatus, setFilterStatus] = React.useState<Dict<boolean>>(overrideCheckState);

  React.useEffect(() => {
    reportStatusChange(filterStatus);
  }, [filterStatus]);

  React.useEffect(() => {
    setFilterStatus(overrideCheckState);
  }, [overrideCheckState]);

  return (
    <FormControl>
      <Typography variant="body1" fontWeight="bold">
        {stage}
      </Typography>

      <Stack sx={{ marginLeft: 1 }}>
        {RUN_STATUS_ORDER.map((status) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={filterStatus[status]}
                onChange={(_, checked) =>
                  setFilterStatus((currStatus) => {
                    const newStatus = { ...currStatus };
                    newStatus[status] = checked;
                    return newStatus;
                  })
                }
                size="small"
              />
            }
            label={RUN_STATUS_TO_DISPLAY_NAME[status]}
            key={status}
          />
        ))}
      </Stack>
    </FormControl>
  );
}

export interface StageStatusFilterProps {
  stage: string;
  reportStatusChange: (statuses: Dict<boolean>) => void;
  overrideCheckState: Dict<boolean>;
}

export default StageStatusFilter;
