import sleep from "./sleep";

export interface RetryQueryConfig {
  maxRetries: number;
  waitBeforeRetryMilliseconds: number;
  failureError?: Error;
  useErrorOnlyAsDefault?: boolean;
}

export async function runWithRetries<T>(corutine: Promise<T>, retryConfig: RetryQueryConfig): Promise<T> {
  let finalError = undefined;
  for (let i = 0; i < retryConfig.maxRetries; i += 1) {
    try {
      const result = await corutine;
      return result;
    } catch (error) {
      // Collect metric of how many misses we had
      finalError = error;
      await sleep(retryConfig.waitBeforeRetryMilliseconds);
    }
  }

  if (!retryConfig.useErrorOnlyAsDefault && retryConfig.failureError !== undefined) {
    throw retryConfig.failureError;
  }

  throw finalError ?? retryConfig.failureError ?? new Error(`Action failed after ${retryConfig.maxRetries} tries`);
}
