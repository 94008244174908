import React from "react";
import { Button, Checkbox, FormControlLabel, TextField, TextFieldProps, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { getServicesRequestParams } from "../../../Utils/azureAuth";
import { Api as ServicesApi, FixSitePostResponse } from "../../../Generated/ExoServicesAPI";
import LoadingWheel from "../../LoadingWheel";
import { DepthValueIssue, SiteWideDepthData } from "./depthData";

function LabeledTextField(textFieldProps: TextFieldProps) {
  const fixedProps = { ...textFieldProps };
  if (textFieldProps.size === undefined) {
    fixedProps.size = "small";
  }
  if (textFieldProps.style === undefined) {
    fixedProps.style = { marginLeft: "20px" };
  }
  return <TextField {...fixedProps} />;
}

function calculatePercentage(value: number, outOf: number, valueIfZeroMax: number = 0) {
  if (outOf === 0) {
    return valueIfZeroMax;
  }
  return 100 * (value / outOf);
}

function CalculateHeightsForm({ siteWideDepthData, triggerRefresh }: CalculateHeightsFormProps) {
  const [currentAction, setCurrentAction] = React.useState<string | undefined>(undefined);

  const [totalPipesWithDepthCount, setTotalPipesWithDepthCount] = React.useState<number | DepthValueIssue>(
    DepthValueIssue.NotQueried
  );
  const [needCalculationCount, setNeedCalculationCount] = React.useState<number>(0);
  const [handledSoFarCount, setHandledSoFarCount] = React.useState<number>(0);
  const [failureCount, setFailureCount] = React.useState<number | DepthValueIssue>(DepthValueIssue.NotQueried);
  const [isForceFixChecked, setIsForceFixChecked] = React.useState<boolean>(false);
  const [shouldRefershSite, setShouldRefershSite] = React.useState<boolean>(true);

  function createValueString(value: number | DepthValueIssue): string {
    if (value === DepthValueIssue.NotQueried) {
      return "Not Queried";
    }

    if (value === DepthValueIssue.SiteNotSet) {
      return "Site was not chosen";
    }

    if (value === DepthValueIssue.QueryError) {
      return "Query failed";
    }

    if (value === DepthValueIssue.NotSet) {
      return "Value was not returned from server";
    }

    if (Object.values(DepthValueIssue).includes(value as any)) {
      return `Unexpected Issue: ${value}`;
    }

    return value.toString();
  }

  function setCountersByResponse(response: FixSitePostResponse) {
    setTotalPipesWithDepthCount(response?.total ?? DepthValueIssue.NotSet);
    setFailureCount(
      response?.existing_errors === undefined ? DepthValueIssue.NotSet : response.existing_errors + response.failed
    );
    setNeedCalculationCount(response.left);
    setHandledSoFarCount((current) => current + response.handled);
  }

  async function performSingleCalculationIteration(
    api: ServicesApi<any>,
    siteId: string,
    forceFix: boolean = false
  ): Promise<number> {
    /* I only partially checked it in cases the status != 200. From this check,
    it seems that if the value is in [200, 300), the response will "succeed".
    If the value is in [100,200), it fails to create the response in client side testing. I assume it try to
    automate the handling, but to simplify I will just return 206 to indicate more job is required.
    I don't handle the 500 case, which might still send values. It will just yell on the user and we will check
    it manually. */
    const response = await api.depth.fixSiteHeights(siteId, { forceFix: forceFix }, await getServicesRequestParams());
    setCountersByResponse(response.data as FixSitePostResponse);
    return response.data.left;
  }

  async function calculateEntireSite(siteId: string, forceFix: boolean = false) {
    // While I started to prefer the then/catch/finally way to direct async, the need for repated
    // calls force the usage of direct async - unless recursion is used, which is not advised.
    const api = new ServicesApi();

    // Force fix must be given only on first call.
    let left = await performSingleCalculationIteration(api, siteId, forceFix);
    while (left > 0) {
      const newLeft = await performSingleCalculationIteration(api, siteId);
      if (newLeft >= left) {
        throw Error("Number of handled pipe was not decreased between calls, abort");
      }
      left = newLeft;
    }
  }

  function startHeightCalculation() {
    const siteId = siteWideDepthData.graphqlSiteId;
    if (siteId === undefined) {
      alert("Site id is not set, somehow.");
      return;
    }
    setNeedCalculationCount(0);
    setHandledSoFarCount(0);
    setFailureCount(DepthValueIssue.NotQueried);
    setTotalPipesWithDepthCount(DepthValueIssue.NotQueried);

    setCurrentAction("Calculating pipes heights");
    const shouldRefershOnEnd = shouldRefershSite;
    calculateEntireSite(siteId, isForceFixChecked)
      .catch((error) => {
        console.log("Height calculation error: ", error);
        alert("Calculating site heights failed unexpectedly");
      })
      .finally(() => {
        setCurrentAction(undefined);
        if (shouldRefershOnEnd && handledSoFarCount > 0) {
          // A partial query might be preferable, but search can't filter a collection of values well.
          // Full site refersh was safer and far easier to implement.
          // A workaround can be to do a reverse query - check crossing that were updated lately, and get the
          // pipes information from them. Of course, this might insert some timing issues between the local and
          // remote clock, but it should be fine if we give long enough buffer, or return a sample of update time
          // from the service.
          triggerRefresh();
        }
      });
  }

  function createSolveOutOfTotalString(
    total: number | DepthValueIssue,
    errors: number | DepthValueIssue,
    left: number
  ) {
    for (const value of [total, errors]) {
      if (Object.values(DepthValueIssue).includes(value as any)) {
        return createValueString(value);
      }
    }

    const solvedCount = (total as number) - (errors as number) - left;
    return `${solvedCount}/${total} (${calculatePercentage(solvedCount, total as number).toFixed(2)}%)`;
  }

  function createProgressString(handledCount: number, leftCount: number, finished: boolean) {
    /* It is surprisingly hard to not be misleading here. If the total is zero, it can be because
    we have nothing to do, or because we didn't start so we don't know the value. Therefore,
    we use the value of finished to decide it.

    However, what do we do if left > 0 but finished = true? This means that the actions stopped,
    so whatever left will never be processed. I decided in this case to effectively set the left to 0,
    displaying always: handled/handled (100%).
    This indicate that we did actually handled those missions, and that no new missions are pending. */
    const total = finished ? handledCount : handledCount + leftCount;
    const percentage = finished ? 100 : calculatePercentage(handledCount, total).toFixed(2);
    return `${handledCount}/${total} (${percentage}%)`;
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h5">Calculate Heights</Typography>
        <Typography variant="subtitle1">Trigger Altitude Calculation of Remote Pipes</Typography>
        <Typography variant="subtitle1">Note: This is beta - no support on failures for this version!</Typography>
        <Button
          variant="contained"
          key="Calculate Heights for site pipe"
          disabled={siteWideDepthData.graphqlSiteId === undefined || currentAction !== undefined}
          onClick={startHeightCalculation}
        >
          Start
        </Button>
        <FormControlLabel
          label="Run on solved pipes"
          control={<Checkbox checked={isForceFixChecked} onChange={(e) => setIsForceFixChecked(e.target.checked)} />}
        />
        <FormControlLabel
          label="Refresh site after calculation"
          control={<Checkbox checked={shouldRefershSite} onChange={(e) => setShouldRefershSite(e.target.checked)} />}
        />
        {currentAction !== undefined ? <LoadingWheel /> : <></>}
        <LabeledTextField
          label="Progress"
          disabled={true}
          value={createProgressString(handledSoFarCount, needCalculationCount, currentAction === undefined)}
        />
        <LabeledTextField
          label="Solved/Total"
          disabled={true}
          value={createSolveOutOfTotalString(totalPipesWithDepthCount, failureCount, needCalculationCount)}
        />
        <LabeledTextField label="Errors" disabled={true} value={createValueString(failureCount)} />

        <LabeledTextField
          label="Status"
          disabled={true}
          value={currentAction ?? (siteWideDepthData.graphqlSiteId === undefined ? "Site not set" : "Idle")}
          error={siteWideDepthData.graphqlSiteId === undefined}
          helperText={siteWideDepthData.graphqlSiteId === undefined ? "Please select site" : undefined}
        />
      </Stack>
    </>
  );
}

export interface CalculateHeightsFormProps {
  siteWideDepthData: SiteWideDepthData;
  triggerRefresh: () => void;
}

export default CalculateHeightsForm;
