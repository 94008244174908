/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum TransmitterMethod {
  Injection = 'Injection',
  Induction = 'Induction',
  Clamp = 'Clamp'
}

export enum PipelineState {
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR'
}

export enum GeoShape {
  Points = 'Points',
  Polyline = 'Polyline',
  Polygon = 'Polygon'
}

/** @format date-time */
export type DateTime = string;

export enum SiteType {
  Client = 'Client',
  Experiment = 'Experiment'
}

export interface AddSite {
  name: string;
  siteType: SiteType;
  /** @format uuid */
  stateId: string;
  /** @format uuid */
  clientId: string;
  contacts: AddContact[];
  polygonFiles?: PolygonFile[];
}

export interface SiteDetails {
  /** @format uuid */
  id: string;
  name: string;
  siteType: SiteType;
  creationDate: DateTime;
  /** @format uuid */
  stateId: string;
  clientName?: string;
  /** @format uuid */
  clientId: string;
  s3Prefix: string;
  polygonsDir: string;
  geoLocatedPictureDir: string;
  geoLocatedFilesDir: string;
  picturesDir: string;
  soilTypeDir: string;
  contacts: AddContact[];
  rtkUsers: RTKUser[];
}

export interface MultipartUploadRequest {
  fileName: string;
  fileType?: FileType;
  /** @format int32 */
  parts: number;
  date?: string;
}

export interface MultipartUploadResponse {
  urls: string[];
  uploadId: string;
}

export interface EndMultipartUploadRequest {
  uploadId: string;
  fileName: string;
  fileType?: FileType;
  date?: string;
  parts: {
    ETag: string;
    /** @format int32 */
    PartNumber: number;
  }[];
}

export interface S3PreSignedUrl {
  /** @format uri */
  url: string;
  fields: {
    key?: string;
    'x-amz-algorithm'?: string;
    'x-amz-credentials'?: string;
    'x-amz-date'?: string;
    'x-amz-signature'?: string;
    /** @format byte */
    policy?: string;
  };
}

export enum FileType {
  PolygonFile = 'PolygonFile',
  GeolocatedPicture = 'GeolocatedPicture',
  GeolocatedFile = 'GeolocatedFile',
  Picture = 'Picture',
  SoilType = 'SoilType',
  BackgroundImage = 'BackgroundImage',
  Seismic = 'Seismic',
  GoPro = 'GoPro',
  DronePhotos = 'DronePhotos',
  OriginalGeoTif = 'OriginalGeoTif',
  ERT = 'ERT',
  FDEM = 'FDEM'
}

export interface AddContact {
  name: string;
  jobTitle: string;
  phone: string;
}

export type Contact = AddContact & {
  /** @format uuid */
  id: string;
};

export interface AddState {
  stateName: string;
  countryName: string;
}

export interface StateDetails {
  /** @format uuid */
  id: string;
  stateName: string;
  countryName: string;
}

export interface AddClient {
  name: string;
  abbreviation?: string;
}

export interface ClientDetails {
  /** @format uuid */
  id: string;
  name: string;
  abbreviation: string;
}

export enum PolygonFileType {
  Kml = 'kml',
  Kmz = 'kmz'
}

export interface PolygonFile {
  /** @format base64 */
  content: string;
  type: PolygonFileType;
}

export interface IdResponse {
  /** @format uuid */
  id: string;
}

export interface GetClientsResponse {
  clients: ClientDetails[];
}

export interface GetStatesResponse {
  states: StateDetails[];
}

export interface GetContactsResponse {
  contacts: Contact[];
}

export interface GetSiteTypesResponse {
  siteTypes: SiteType[];
}

export interface AddDateDirBody {
  /** @format date-time */
  date: string;
}

export interface GetSharepointUrlResponse {
  /** @format uri */
  sharepointURL: string;
}

export interface GetSitesResponse {
  sites: SiteDetails[];
}

export interface PolygonUrls {
  polygonsUrls: {
    /** @format url */
    url: string;
    path: string;
  }[];
}

export interface PolygonsInfo {
  numberOfPolygons: number;
}

export interface RTKUser {
  name: string;
  username: string;
  password: string;
  mountPoint: string;
  hostname: string;
  /** @format port */
  port: number;
  /** @format uuid */
  id?: string;
  sendGGA: boolean;
}

export interface MultipleRTKUsers {
  rtkUsers: RTKUser[];
}

export interface AnalysisProductsSite {
  name: string;
  s3Prefix: string;
  s3Source?: string;
}

export interface GetAnalysisProductsSites {
  sites: AnalysisProductsSite[];
  coursesSource: string;
  coursesKey: string;
}

export interface AnalysisProductsSource {
  name: string;
  s3Source: string;
  group?: string;
}

export interface GetAnalysisProductsSources {
  sources: AnalysisProductsSource[];
}

export interface AnalysisFeaturesIds {
  ids: string[];
}

export interface IdList {
  ids: string[];
}

export enum Confidence {
  None = 'None',
  NotAnObject = 'Not An Object',
  Lowest = 'Lowest',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Highest = 'Highest'
}

export interface ScanDetailsOld {
  /** @format uuid */
  id?: string;
  name: string;
  /** @format date-time */
  date?: string;
  timestamp?: number;
  sensor: string;
  polygon?: number;
  snake?: number;
  part?: number;
  boundingPolygonS3Ref?: string;
  boundingPolygonGeoJson?: string;
  s3Ref?: string;
}

export interface AnalysisProductsFeatureHeaderOld {
  id: string;
  s3Ref: string;
  name: string;
  fullName?: string;
  geoJson: string;
  lats?: number[];
  lons?: number[];
  shapeType: GeoShape;
  scan: ScanDetailsOld;
  user?: string;
  applicationName?: string;
  applicationVersion?: string;
  creationTimestamp?: number;
  isValid?: boolean;
  originalEpsgCode: number;
  comment?: string;
}

export interface AnalysisProductsPipeOld {
  header: AnalysisProductsFeatureHeaderOld;
  depths?: number[];
  confidence: Confidence;
  autoConfidence: Confidence;
  radiusMeters?: number;
  frequency?: number;
}

export interface AnalysisProductsPolygonOld {
  header: AnalysisProductsFeatureHeaderOld;
  confidence: Confidence;
  autoConfidence: Confidence;
  radiusMeters?: number;
  frequency?: number;
  depth?: number;
}

export interface AnalysisProductsTransmitterOld {
  header: AnalysisProductsFeatureHeaderOld;
  frequency: number;
  method?: TransmitterMethod;
  snr?: number;
}

export interface AnalysisProductsTravelCourseOld {
  header: AnalysisProductsFeatureHeaderOld;
  fixMode?: number[];
  timestampUTC?: number[];
  gpsFixedModeRatio?: number;
  tag?: string;
}

export interface ReplacedProduct {
  original: string;
  replacement: string;
}

export interface GetAnalysisProductsFeatures {
  pipes: AnalysisProductsPipeOld[];
  transmitters: AnalysisProductsTransmitterOld[];
  travelCourses: AnalysisProductsTravelCourseOld[];
  polygons: AnalysisProductsPolygonOld[];
}

export interface GetMultipleFeaturesRequest {
  ids: string[];
  source?: string;
  /**
   * Id of the planned site
   * @format uuid
   */
  plannedSiteId?: string;
}

export interface GetMultipleFeaturesResponse {
  features: GetAnalysisProductsFeatures;
  failed_ids: string[];
  replaced?: ReplacedProduct[];
}

export interface CreateExoMapRequest {
  ids: string[];
  entireSite?: boolean;
  source?: string;
}

export interface GetExoMapDataResponse {
  name: string;
  imageUrl: string;
  bbox: {
    minLat: number;
    maxLat: number;
    minLon: number;
    maxLon: number;
  };
}

export interface GetAllSiteExoMaps {
  site: string;
  exomaps: GetExoMapDataResponse[];
}

export interface UserLayerShape {
  markingType: string;
  timestamp: number;
  shapeType: GeoShape;
  lats: number[];
  lons: number[];
  name?: string;
  layerAttributes?: UserLayerAttributes;
}

export interface UserLayerAttributes {
  QL?: string;
  type?: string;
  properties?: string;
  depth?: number;
  notes?: string;
  color?: string;
}

export interface UserLayer {
  name: string;
  shapes: UserLayerShape[];
  wantedEpsgCode: number;
}

export interface AddUserLayer {
  layer: UserLayer;
  source?: string;
}

export interface GetLayersIdsResponse {
  ids: string[];
  source?: string;
}

export interface GetLayersDataRequestBody {
  layersIds: string[];
  source?: string;
}

export interface GetLayersResponse {
  layers: UserLayer[];
  source?: string;
}

export interface PipelineStatus {
  steps: string[];
  name: string;
  currentStep: number;
  scan: string;
  sensor: string;
  domain: string;
  state: PipelineState;
  creationTimestamp: number;
  imageUrl?: string;
}

export interface PipelineSite {
  name: string;
  dates: string[];
}

export interface PipelineSiteList {
  sites: PipelineSite[];
}

export interface TagNameList {
  tags: string[];
}

export interface GetPipeCreationImageUrlResponse {
  url?: string;
  images: ImageData[];
}

export interface GetEntityProtobufUrlResponse {
  url: string;
}

export interface SetValidRequestBody {
  isValid: boolean;
  source?: string;
}

export enum EmailTarget {
  Exofuser = 'exofuser'
}

export interface EmailAttachment {
  id: string;
  data: string;
  type: string;
  subtype?: string;
}

export interface EmailRequest {
  subject: string;
  content: string;
  target: EmailTarget;
  attachments?: EmailAttachment[];
}

export interface GetTileResponse {
  /** @format uri */
  url?: string;
}

export interface MonitorMetadataError {
  /** @format date-time */
  mtime: string;
  /** @format uri */
  url: string;
}

export interface MonitorErrorsMetadataList {
  errors: MonitorMetadataError[];
}

export interface TiffTileLayer {
  name: string;
  path: string;
}

export interface AnalysisLayer {
  id: string;
  name: string;
  data?: Record<string, any>;
  metadata?: Record<string, any>;
  subLayers?: AnalysisSubLayer[];
}

export interface AnalysisSubLayer {
  id: string;
  name: string;
  data?: Record<string, any>;
  metadata?: Record<string, any>;
}

export interface AnalysisLayerNames {
  id: string;
  name: string;
}

export interface GetTiffLayersResponse {
  tileLayers: TiffTileLayer[];
}

export interface GetAnalysisLayersNamesResponse {
  layers: AnalysisLayerNames[];
}

export interface GetAnalysisLayerResponse {
  url: string;
}

export interface PostCustomLayer {
  name: string;
  data: string;
  layerType: string;
  site?: string;
  multiSite?: boolean;
}

export interface PresigedUrlsResponse {
  urls: string[];
  uploadId?: string;
}

export interface EndMultipartUpload {
  uploadId: string;
  parts: {
    ETag: string;
    /** @format int32 */
    PartNumber: number;
  }[];
}

export interface AWSResourcesPresignedUrlResponse {
  url: string;
}

export interface AWSResourcesGetArtifactAccessResponse {
  url: string;
  token: string;
}

export interface BoundingPolygonUrlResponse {
  url: string;
}

export interface PlannedObjectDisplayParams {
  color?: string;
  overrideColor?: boolean;
}

export interface PlannedTransmitter {
  /** @format double */
  x: number;
  /** @format double */
  y: number;
  name: string;
  comment?: string;
  displayParams?: PlannedObjectDisplayParams;
}

export interface PlannedPolygon {
  xs: number[];
  ys: number[];
  name: string;
  comment?: string;
  sensorTypes?: string[];
  displayParams?: PlannedObjectDisplayParams;
  transmitters: PlannedTransmitter[];
}

export interface PostSitePlan {
  siteId: string;
  plannedPolygons: PlannedPolygon[];
}

export interface ExodaqMapLayer {
  geoData?: any[];
}

export interface ImageData {
  url: string;
  name: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Swagger ExoDB
 * @version 1.0.0
 * @externalDocs http://swagger.io
 * @contact <exodigo-software@exodigo.ai>
 *
 * This is the ExoDB server.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  site = {
    /**
     * @description Old version need to be deleted
     *
     * @tags site
     * @name GetSitesOld
     * @summary Get all sites
     * @request GET:/site
     */
    getSitesOld: (
      query?: {
        /** Site name filter */
        siteName?: string;
        /** Country filter */
        country?: string;
        /** State filter */
        state?: string;
        /** Client filter */
        clientId?: string;
        /** Site type filter */
        siteType?: string;
        /**
         * Min date filter
         * @format date-time
         */
        createdAfter?: string;
        /**
         * Max date filter
         * @format date-time
         */
        createdBefore?: string;
        /** Longitude in site polygon */
        longitude?: string;
        /** Latitude in site polygon */
        latitude?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SiteDetails[], void>({
        path: `/site`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      })
  };
  state = {
    /**
     * @description Old version need to be deleted
     *
     * @tags state
     * @name GetStatesOld
     * @summary Get all states
     * @request GET:/state
     */
    getStatesOld: (params: RequestParams = {}) =>
      this.request<StateDetails[], void>({
        path: `/state`,
        method: 'GET',
        format: 'json',
        ...params
      })
  };
  sites = {
    /**
     * No description
     *
     * @tags sites
     * @name AddSite
     * @summary Add a new site
     * @request POST:/sites
     */
    addSite: (body: AddSite, params: RequestParams = {}) =>
      this.request<IdResponse, void>({
        path: `/sites`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name GetSites
     * @summary Get all sites
     * @request GET:/sites
     */
    getSites: (
      query?: {
        /** Site name filter */
        siteName?: string;
        /** Site name fuzzy filter */
        siteNameFuzzy?: string;
        /** Country filter */
        country?: string;
        /** State filter */
        state?: string;
        /**
         * Client filter
         * @format uuid
         */
        clientId?: string;
        /** Site type filter */
        siteType?: string;
        /**
         * Min date filter
         * @format date-time
         */
        createdAfter?: string;
        /**
         * Max date filter
         * @format date-time
         */
        createdBefore?: string;
        /** Longitude in site polygon */
        longitude?: string;
        /** Latitude in site polygon */
        latitude?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetSitesResponse, void>({
        path: `/sites`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single site
     *
     * @tags sites
     * @name GetSiteById
     * @summary Find site by ID
     * @request GET:/sites/{siteId}
     */
    getSiteById: (siteId: string, params: RequestParams = {}) =>
      this.request<SiteDetails, void>({
        path: `/sites/${siteId}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name PutSite
     * @summary Add a new site
     * @request PUT:/sites/{siteId}
     */
    putSite: (siteId: string, body: AddSite, params: RequestParams = {}) =>
      this.request<IdResponse, void>({
        path: `/sites/${siteId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single site
     *
     * @tags sites
     * @name DeleteSite
     * @summary Deletes the site from the db
     * @request DELETE:/sites/{siteId}
     */
    deleteSite: (siteId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/sites/${siteId}`,
        method: 'DELETE',
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name StartMultipartFileUpload
     * @summary Starts a multipart upload to s3
     * @request POST:/sites/{siteId}/start_multipart_file_upload
     */
    startMultipartFileUpload: (
      siteId: string,
      body: MultipartUploadRequest,
      params: RequestParams = {}
    ) =>
      this.request<MultipartUploadResponse, void>({
        path: `/sites/${siteId}/start_multipart_file_upload`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name EndMultipartFileUpload
     * @summary Signals that a multipart upload is done
     * @request POST:/sites/{siteId}/end_multipart_file_upload
     */
    endMultipartFileUpload: (
      siteId: string,
      body: EndMultipartUploadRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/sites/${siteId}/end_multipart_file_upload`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name GetUploadPreSignedUrl
     * @summary Get a pre signed url for uploading a file
     * @request GET:/sites/{siteId}/get_upload_pre_signed_url
     */
    getUploadPreSignedUrl: (
      siteId: string,
      query: {
        /** The file name */
        fileName: string;
        /** The type of file being uploaded */
        fileType: string;
        /** The relevant date for the upload */
        date?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<S3PreSignedUrl, void>({
        path: `/sites/${siteId}/get_upload_pre_signed_url`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name GetSharepointUrl
     * @summary Gets a link to the sites sharepoint folder
     * @request GET:/sites/{siteId}/sharepoint/get_sharepoint_url
     */
    getSharepointUrl: (siteId: string, params: RequestParams = {}) =>
      this.request<GetSharepointUrlResponse, any>({
        path: `/sites/${siteId}/sharepoint/get_sharepoint_url`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name PutRtkUsers
     * @summary Overrides site current rtk users
     * @request PUT:/sites/{siteId}/rtkUsers
     */
    putRtkUsers: (siteId: string, body: MultipleRTKUsers, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/sites/${siteId}/rtkUsers`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name AddSharepointDateDir
     * @summary Gets a link to the sites sharepoint folder
     * @request POST:/sites/{siteId}/sharepoint/add_date_dir
     */
    addSharepointDateDir: (siteId: string, body: AddDateDirBody, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/sites/${siteId}/sharepoint/add_date_dir`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name GetPolygonUrls
     * @summary Get presigned url for site polygons
     * @request GET:/sites/{siteId}/polygons/urls
     */
    getPolygonUrls: (siteId: string, params: RequestParams = {}) =>
      this.request<PolygonUrls, void>({
        path: `/sites/${siteId}/polygons/urls`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name GetPolygonsInfo
     * @summary Get info about site polygons
     * @request GET:/sites/{siteId}/polygons/info
     */
    getPolygonsInfo: (siteId: string, params: RequestParams = {}) =>
      this.request<PolygonsInfo, any>({
        path: `/sites/${siteId}/polygons/info`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name GetUserFeatureLayers
     * @summary Get all the user layers of the site
     * @request GET:/sites/{siteId}/layers
     */
    getUserFeatureLayers: (
      siteId: string,
      query?: {
        /** The source of the data (mostly used to allow training) */
        source?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetLayersResponse, void>({
        path: `/sites/${siteId}/layers`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Upload the features drawn by a users to the cloud
     *
     * @tags sites
     * @name PostUserFeatureLayer
     * @summary Upload a new layer by name
     * @request POST:/sites/{siteId}/layers
     */
    postUserFeatureLayer: (siteId: string, body: AddUserLayer, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/sites/${siteId}/layers`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags sites
     * @name GetUserFeatureLayersIds
     * @summary Get all the ids of the user layers of the site
     * @request GET:/sites/{siteId}/layers/ids
     */
    getUserFeatureLayersIds: (
      siteId: string,
      query?: {
        /** The source of the data (mostly used to allow training) */
        source?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetLayersIdsResponse, void>({
        path: `/sites/${siteId}/layers/ids`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Get the layers drawn by the user from the given site
     *
     * @tags sites
     * @name PostGetLayersData
     * @summary Get the user layers of the given ids from the site
     * @request POST:/sites/{siteId}/layers/data
     */
    postGetLayersData: (
      siteId: string,
      body: GetLayersDataRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<GetLayersResponse, void>({
        path: `/sites/${siteId}/layers/data`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
  states = {
    /**
     * No description
     *
     * @tags states
     * @name AddState
     * @summary Add a new state
     * @request POST:/states
     */
    addState: (body: AddState, params: RequestParams = {}) =>
      this.request<IdResponse, void>({
        path: `/states`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags states
     * @name GetStates
     * @summary Get all states
     * @request GET:/states
     */
    getStates: (params: RequestParams = {}) =>
      this.request<GetStatesResponse, void>({
        path: `/states`,
        method: 'GET',
        format: 'json',
        ...params
      })
  };
  siteTypes = {
    /**
     * No description
     *
     * @tags siteTypes
     * @name GetSiteTypes
     * @summary Get all site types
     * @request GET:/siteTypes
     */
    getSiteTypes: (params: RequestParams = {}) =>
      this.request<GetSiteTypesResponse, void>({
        path: `/siteTypes`,
        method: 'GET',
        format: 'json',
        ...params
      })
  };
  clients = {
    /**
     * No description
     *
     * @tags clients
     * @name GetClients
     * @summary Get all the clients
     * @request GET:/clients
     */
    getClients: (params: RequestParams = {}) =>
      this.request<GetClientsResponse, void>({
        path: `/clients`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags clients
     * @name AddClient
     * @summary Add a new client
     * @request POST:/clients
     */
    addClient: (body: AddClient, params: RequestParams = {}) =>
      this.request<IdResponse, void>({
        path: `/clients`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
  contacts = {
    /**
     * No description
     *
     * @tags contacts
     * @name GetContacts
     * @summary Get the contacts
     * @request GET:/contacts
     */
    getContacts: (
      query?: {
        /**
         * contact id
         * @format uuid
         */
        id?: string;
        /** contact name */
        name?: string;
        /** contact job title */
        jobTitle?: string;
        /** contact phone number */
        phone?: string;
        /**
         * filter contacts who are associated with a site
         * @format uuid
         */
        siteId?: string;
        /**
         * filter contacts who are associated with a client
         * @format uuid
         */
        clientId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetContactsResponse, void>({
        path: `/contacts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      })
  };
  rtkUsers = {
    /**
     * No description
     *
     * @tags rtkUsesrs
     * @name GetRtkUsers
     * @summary get rtk users
     * @request GET:/rtkUsers
     */
    getRtkUsers: (
      query?: {
        /**
         * state id filter, will take precedent over state name and country name
         * @format uuid
         */
        stateId?: string;
        /** Country filter */
        country?: string;
        /** State filter */
        state?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<MultipleRTKUsers, void>({
        path: `/rtkUsers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags rtkUsesrs
     * @name PublishRtkUsers
     * @summary Publishes new rtk users to all sites in state
     * @request POST:/rtkUsers/{stateId}/publish
     */
    publishRtkUsers: (stateId: string, body: MultipleRTKUsers, params: RequestParams = {}) =>
      this.request<MultipleRTKUsers, void>({
        path: `/rtkUsers/${stateId}/publish`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
  analysis = {
    /**
     * No description
     *
     * @tags analysis
     * @name GetProductsSources
     * @summary Get the sources from which products can be retrieved
     * @request GET:/analysis/products/sources
     */
    getProductsSources: (params: RequestParams = {}) =>
      this.request<GetAnalysisProductsSources, void>({
        path: `/analysis/products/sources`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name GetAnalysisSites
     * @summary Get the sites were analysis was performed
     * @request GET:/analysis/products/sites
     */
    getAnalysisSites: (
      query: {
        /** The id of the planned site to filter accordingly */
        plannedSiteId: string;
        /** Site name filter */
        siteName?: string;
        /** Change the ratio used with fuzzy score */
        fuzzyRatioThreshold?: number;
        /** From where the data should be taken (default: production) */
        source?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAnalysisProductsSites, void>({
        path: `/analysis/products/sites`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name GetAnalysisFeaturesIds
     * @summary Get the ids of the different feature maps of a given site
     * @request GET:/analysis/products/{analysisSite}/features/ids
     */
    getAnalysisFeaturesIds: (
      analysisSite: string,
      query?: {
        /** From where the data should be taken (default: production) */
        source?: string;
        /** Return paths in legacy format or new format */
        legacy?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AnalysisFeaturesIds, void>({
        path: `/analysis/products/${analysisSite}/features/ids`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Get a single one of pipes, travel courses, transmitters, etc. of a given site.
     *
     * @tags analysis
     * @name GetAnalysisFeature
     * @summary Get base data on the features discovered/marked in analysis
     * @request GET:/analysis/products/{analysisSite}/features/data
     */
    getAnalysisFeature: (
      analysisSite: string,
      query: {
        /** The name (future - id) of the feature */
        featureId: string;
        /** From where the data should be taken (default: production) */
        source?: string;
        /** Default value for epsg */
        defaultEpsgCode?: string;
        /**
         * Id of the planned site
         * @format uuid
         */
        plannedSiteId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAnalysisProductsFeatures, void>({
        path: `/analysis/products/${analysisSite}/features/data`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Get multiple pipes, travel courses, transmitters, etc. of a given site. Post since multiple ids in Query is ill-advised
     *
     * @tags analysis
     * @name PostGetMultipleAnalysisFeature
     * @summary Get base data on the features discovered/marked in analysis
     * @request POST:/analysis/products/{analysisSite}/features/data
     */
    postGetMultipleAnalysisFeature: (
      analysisSite: string,
      multipleFeaturesRequestBody: GetMultipleFeaturesRequest,
      params: RequestParams = {}
    ) =>
      this.request<GetMultipleFeaturesResponse, void>({
        path: `/analysis/products/${analysisSite}/features/data`,
        method: 'POST',
        body: multipleFeaturesRequestBody,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name GetPipeCreationImage
     * @summary Get image of the pipe at the moment of creation
     * @request GET:/analysis/products/{analysisSite}/{featureId}/image
     */
    getPipeCreationImage: (
      analysisSite: string,
      featureId: string,
      query?: {
        /** From where the data should be taken (default: production) */
        source?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetPipeCreationImageUrlResponse, void>({
        path: `/analysis/products/${analysisSite}/${featureId}/image`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Get a single one of pipes, travel courses, transmitters, etc..
     *
     * @tags analysis
     * @name GetAnalysisFeatureByS3Ref
     * @request GET:/analysis/products/features/data
     */
    getAnalysisFeatureByS3Ref: (
      query: {
        /** The s3 reference of the feature */
        s3Ref: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAnalysisProductsFeatures, void>({
        path: `/analysis/products/features/data`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name GetEntityProtobufUrl
     * @summary Get presigned for downloading entity protobuf
     * @request GET:/analysis/products/{analysisSite}/{featureId}/url
     */
    getEntityProtobufUrl: (
      analysisSite: string,
      featureId: string,
      query?: {
        /** From where the data should be taken (default: production) */
        source?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetEntityProtobufUrlResponse, void>({
        path: `/analysis/products/${analysisSite}/${featureId}/url`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name PostFeatureValid
     * @summary Set pipe as valid/invalid
     * @request POST:/analysis/products/{analysisSite}/{featureId}/valid
     */
    postFeatureValid: (
      analysisSite: string,
      featureId: string,
      setValidRequestBody: SetValidRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/analysis/products/${analysisSite}/${featureId}/valid`,
        method: 'POST',
        body: setValidRequestBody,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name GetTiffLayers
     * @summary Get all the tiff tile layers
     * @request GET:/analysis/tiffs/layers
     */
    getTiffLayers: (
      query?: {
        /** filter by planned site */
        plannedSite?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetTiffLayersResponse, void>({
        path: `/analysis/tiffs/layers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name GetTiffUploadPresignedUrl
     * @summary Get tiff upload presiged urls
     * @request GET:/analysis/tiffs/layers/get_upload_urls
     */
    getTiffUploadPresignedUrl: (
      query: {
        /** Planned site the tiff is assigned to */
        plannedSite: string;
        /** The new layer name */
        layerName: string;
        /** The size of the tiff */
        fileSize: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PresigedUrlsResponse, void>({
        path: `/analysis/tiffs/layers/get_upload_urls`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name EndTiffMultipartUpload
     * @summary Get tiff upload presiged urls
     * @request POST:/analysis/tiffs/layers/end_multipart_upload
     */
    endTiffMultipartUpload: (
      query: {
        /** filter by planned site */
        plannedSite: string;
        /** filter by planned site */
        layerName: string;
      },
      body: EndMultipartUpload,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/analysis/tiffs/layers/end_multipart_upload`,
        method: 'POST',
        query: query,
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name GetAnalysisGeojsonsLayers
     * @summary Get analysis custom layers
     * @request GET:/analysis/layers/geojsons
     */
    getAnalysisGeojsonsLayers: (
      query?: {
        /** filter by planned site */
        plannedSite?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAnalysisLayersNamesResponse, void>({
        path: `/analysis/layers/geojsons`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name PostAnalysisLayers
     * @summary Post a new analysis layer
     * @request POST:/analysis/layers/geojsons
     */
    postAnalysisLayers: (body: PostCustomLayer, params: RequestParams = {}) =>
      this.request<IdList, void>({
        path: `/analysis/layers/geojsons`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name GetAnalysisExodaqGeojsonsLayers
     * @summary Get analysis custom layers
     * @request GET:/analysis/layers/exodaq/geojsons
     */
    getAnalysisExodaqGeojsonsLayers: (
      query: {
        /** planned site name */
        siteName: string;
        /** layer name */
        layerName: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ExodaqMapLayer, void>({
        path: `/analysis/layers/exodaq/geojsons`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name PostAnalysisExodaqLayers
     * @summary Post a new analysis layer
     * @request POST:/analysis/layers/exodaq/geojsons
     */
    postAnalysisExodaqLayers: (body: PostCustomLayer, params: RequestParams = {}) =>
      this.request<IdList, void>({
        path: `/analysis/layers/exodaq/geojsons`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name GetAnalysisGeojsonLayer
     * @summary Get analysis custom layers
     * @request GET:/analysis/layers/geojsons/{id}
     */
    getAnalysisGeojsonLayer: (id: string, params: RequestParams = {}) =>
      this.request<GetAnalysisLayerResponse, void>({
        path: `/analysis/layers/geojsons/${id}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name GetBoundingPolygonPresingedUrl
     * @summary Get scan bounding polygon presinged url
     * @request GET:/analysis/scans/boundingPolygonPresingedUrl
     */
    getBoundingPolygonPresingedUrl: (
      query: {
        /** s3 ref to the bounding polygon geojson */
        s3Ref: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BoundingPolygonUrlResponse, void>({
        path: `/analysis/scans/boundingPolygonPresingedUrl`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags analysis
     * @name PostPlannedSite
     * @summary Post a new plan for scanning a site
     * @request POST:/analysis/sitePlans
     */
    postPlannedSite: (body: PostSitePlan, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/analysis/sitePlans`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params
      })
  };
  tiles = {
    /**
     * No description
     *
     * @tags tiles
     * @name GetTiles
     * @request GET:/tiles/{z}/{x}/{y}/png
     */
    getTiles: (z: number, x: number, y: number, params: RequestParams = {}) =>
      this.request<GetTileResponse, any>({
        path: `/tiles/${z}/${x}/${y}/png`,
        method: 'GET',
        ...params
      })
  };
  extraTiffLayers = {
    /**
     * No description
     *
     * @tags tiles
     * @name TilesPngDetail
     * @request GET:/extraTiffLayers/{site}/{layer}/tiles/{z}/{x}/{y}/png
     */
    tilesPngDetail: (
      site: string,
      layer: string,
      z: number,
      x: number,
      y: number,
      params: RequestParams = {}
    ) =>
      this.request<GetTileResponse, any>({
        path: `/extraTiffLayers/${site}/${layer}/tiles/${z}/${x}/${y}/png`,
        method: 'GET',
        ...params
      })
  };
  exomap = {
    /**
     * @description Run exomap, either on the entire site or the displayed data
     *
     * @tags exomap
     * @name PostExomapRequest
     * @summary Request ExoMap run
     * @request POST:/exomap/{analysisSite}/{exoMapName}
     */
    postExomapRequest: (
      analysisSite: string,
      exoMapName: string,
      exomapRequestBody: CreateExoMapRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/exomap/${analysisSite}/${exoMapName}`,
        method: 'POST',
        body: exomapRequestBody,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description Get all the exomap layers of a site
     *
     * @tags exomap
     * @name GetSiteExomaps
     * @summary Get all ExoMap of site
     * @request GET:/exomap/{analysisSite}
     */
    getSiteExomaps: (
      analysisSite: string,
      query?: {
        /** The source to get the query from */
        source?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAllSiteExoMaps, void>({
        path: `/exomap/${analysisSite}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      })
  };
  pipelines = {
    /**
     * @description Returns the ids of pipelines that pass the filters
     *
     * @tags pipelines
     * @name GetPipelinesIds
     * @request GET:/pipelines/ids
     */
    getPipelinesIds: (
      query?: {
        /** Site name filter */
        siteName?: string;
        /** Tag name filter */
        tagName?: string;
        /**
         * Scan date filter
         * @format datetime
         */
        scanDateStart?: string;
        /**
         * Scan date filter
         * @format datetime
         */
        scanDateEnd?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<IdList, any>({
        path: `/pipelines/ids`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags pipelines
     * @name GetPipelineData
     * @request GET:/pipelines/{id}
     */
    getPipelineData: (id: string, params: RequestParams = {}) =>
      this.request<PipelineStatus, any>({
        path: `/pipelines/${id}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a list of run tags
     *
     * @tags pipelines
     * @name GetPipelinesTags
     * @request GET:/pipelines/tags
     */
    getPipelinesTags: (params: RequestParams = {}) =>
      this.request<TagNameList, any>({
        path: `/pipelines/tags`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Returns the sites that have pipelines statuses
     *
     * @tags pipelines
     * @name GetPipelinesSites
     * @request GET:/pipelines/tags/{tag}/sites
     */
    getPipelinesSites: (tag: string, params: RequestParams = {}) =>
      this.request<PipelineSiteList, any>({
        path: `/pipelines/tags/${tag}/sites`,
        method: 'GET',
        format: 'json',
        ...params
      })
  };
  monitoring = {
    /**
     * @description Get the metadata of all errors
     *
     * @tags monitoring
     * @name GetMonitoringErrors
     * @request GET:/monitoring/errors
     */
    getMonitoringErrors: (params: RequestParams = {}) =>
      this.request<MonitorErrorsMetadataList, any>({
        path: `/monitoring/errors`,
        method: 'GET',
        format: 'json',
        ...params
      })
  };
  reportEmails = {
    /**
     * @description Sends a message via email to requested target
     *
     * @tags email
     * @name SendMail
     * @request POST:/reportEmails
     */
    sendMail: (body: EmailRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reportEmails`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params
      })
  };
  s3 = {
    /**
     * @description Get a presigned url for a get request
     *
     * @tags aws resources
     * @name GetGetPresignedUrl
     * @summary Get a get presinged url
     * @request GET:/s3
     */
    getGetPresignedUrl: (
      query: {
        /** S3 bucket */
        bucket: string;
        /** S3 file path */
        file: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AWSResourcesPresignedUrlResponse, void>({
        path: `/s3`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Get a presigned url for a put request
     *
     * @tags aws resources
     * @name GetPutPresignedUrl
     * @summary Get a put presigned url
     * @request PUT:/s3
     */
    getPutPresignedUrl: (
      query: {
        /** S3 bucket */
        bucket: string;
        /** S3 file path */
        file: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AWSResourcesPresignedUrlResponse, void>({
        path: `/s3`,
        method: 'PUT',
        query: query,
        format: 'json',
        ...params
      })
  };
  artifactAccess = {
    /**
     * @description Get url and token for code artifact
     *
     * @tags aws resources
     * @name GetCodeArtifactAccess
     * @summary Get url and token for code artifact
     * @request GET:/artifact_access
     */
    getCodeArtifactAccess: (
      query: {
        /** Repo type */
        type: string;
        /** Repo id */
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AWSResourcesGetArtifactAccessResponse, void>({
        path: `/artifact_access`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      })
  };
  exodaqConfig = {
    /**
     * No description
     *
     * @tags aws resources
     * @name GetExodaqConfig
     * @summary returns config for exodaq
     * @request GET:/exodaq_config
     */
    getExodaqConfig: (
      query?: {
        /** hostname */
        hostname?: string;
        /** config version */
        version?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<DateTime, void>({
        path: `/exodaq_config`,
        method: 'GET',
        query: query,
        ...params
      })
  };
}
