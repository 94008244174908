import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import LoadedFile from "../AddSiteDataComponents/LoadedFile";

const FileInput = forwardRef<FileInputRef, FileInputComponentProps>(
  ({ multipleFiles, accept = "*", percentages = true, directory = false, loadedFileXButtonAtStart = false }, ref) => {
    const [filesData, setFilesData] = useState<FileData[]>([]);
    const [fileIdCounter, setFileIdCounter] = useState(0);
    const fileInputRef = useRef<HTMLInputElement>(null);

    React.useEffect(() => {
      if (fileInputRef.current !== null && directory) {
        fileInputRef.current.setAttribute("directory", "");
        fileInputRef.current.setAttribute("webkitdirectory", "");
      }
    }, [fileInputRef]);

    /**
     * Exposes the info about the uploaded files
     * **/
    useImperativeHandle(ref, () => ({
      getFilesData: () => {
        return filesData;
      },
      getUploadedFileData: () => {
        return filesData.filter((f) => f.status !== true);
      },
      changeFileStatus: (id, status) => {
        setFilesData((prevState) => {
          const fileData = Object.assign(
            {},
            prevState.find((f) => f.id === id)
          );
          fileData.status = status;
          let tempFilesData = [...prevState];
          tempFilesData[tempFilesData.findIndex((f) => f.id === fileData.id)] = fileData;
          return tempFilesData;
        });
      },
      updateProgress: (id, prog) => {
        setFilesData((prevState) => {
          const fileData = Object.assign(
            {},
            prevState.find((f) => f.id === id)
          );
          fileData.progress = prog;
          let tempFilesData = [...prevState];
          tempFilesData[tempFilesData.findIndex((f) => f.id === fileData.id)] = fileData;

          return tempFilesData;
        });
      },
      clearFiles: () => {
        setFilesData([]);
        const fileInput = fileInputRef.current;
        if (fileInput !== null) {
          fileInput.value = "";
        }
      },
    }));

    /**
     * Update the filesData state with the new loaded files
     * @param {FileList string[]} files The new loaded files
     * @param {Array} tempFilesData the state of the files before adding the new files
     */
    function updateFilesData(files: Array<File>, tempFilesData: Array<FileData>) {
      const filesArr = [...files];
      let id = fileIdCounter;
      setFileIdCounter((oldState) => {
        return oldState + files.length;
      });
      const newFiles = filesArr.map((f) => {
        id += 1;
        const webkitRelativePath = f.webkitRelativePath;
        return {
          id: id,
          fileName: directory
            ? webkitRelativePath.slice(webkitRelativePath.indexOf("/") + 1, f.webkitRelativePath.length)
            : f.name,
          data: f,
          status: undefined,
          progress: 0,
        };
      });

      setFilesData(tempFilesData.concat(newFiles));
    }

    function checkIfFilesEndsWithSuffix(file: File) {
      const suffixes = accept.split(",").map((s) => s.trim());
      for (const suffix of suffixes) {
        if (file.name.endsWith(suffix)) {
          return true;
        }
      }
      return false;
    }

    /**
     * Add dropped files to the input
     * @param {*} e The file dropped event
     */
    function fileDropped(e: React.DragEvent<HTMLElement>) {
      e.preventDefault();
      let tempFilesData = [...filesData];
      if (!multipleFiles) {
        tempFilesData = [];
      }

      let files: Array<File> = [];
      if (e.dataTransfer.files !== null) {
        if (!multipleFiles) {
          for (const f of e.dataTransfer.files) {
            if (checkIfFilesEndsWithSuffix(f)) {
              files.push(f);
              break;
            }
          }
        } else {
          for (const f of e.dataTransfer.files) {
            if (checkIfFilesEndsWithSuffix(f)) {
              files.push(f);
            }
          }
        }
      }

      updateFilesData(files, tempFilesData);
    }

    function fileDroppedInDirMode(e: React.DragEvent<HTMLElement>) {
      e.preventDefault();
      window.alert("Can't drop file on directory mode. please click the input and select through there");
    }

    /**
     * when the input is clicked. calls the hidden file input to open file browser
     */
    function selectFileClicked() {
      if (fileInputRef.current !== null) {
        fileInputRef.current.click();
      }
    }

    /**
     * the event when the hidden file input values changes. this mean a new file was added
     * @param {*} e
     */
    function selectedImageChanged(e: React.ChangeEvent<HTMLInputElement>) {
      let tempFilesData = [...filesData];
      if (!multipleFiles) {
        tempFilesData = [];
      }

      let files: Array<File> = [];
      if (e.target.files !== null) {
        if (!multipleFiles) {
          files = [e.target.files[0]];
        } else {
          for (const f of e.target.files) {
            files.push(f);
          }
        }
      }
      updateFilesData(files, tempFilesData);
    }

    /**
     * When an upload file x button is click. removes the file from the state
     * @param {*} index the index if the file to delete
     */
    function deleteLoadedFile(index: number) {
      const fileInput = fileInputRef.current;
      if (fileInput !== null) {
        fileInput.value = "";
        const tempFilesData = [...filesData];
        tempFilesData.splice(index, 1);
        setFilesData(tempFilesData);
      }
    }

    /**
     * helper function for rendering files loaded to the input
     * @returns the dom of the loaded files
     */
    function renderLoadedFiles() {
      return (
        <span className="loadedFiles">
          {filesData.map((f, i) => {
            return (
              <LoadedFile
                fileName={f.fileName}
                status={f.status}
                progress={f.progress}
                onDeleteClicked={() => {
                  deleteLoadedFile(i);
                }}
                key={`file${i}`}
                percentages={percentages}
                xButtonAtStart={loadedFileXButtonAtStart}
              />
            );
          })}
        </span>
      );
    }

    function createFileInput() {
      if (!multipleFiles) {
        return (
          <input
            type="file"
            style={{ display: "none" }}
            onChange={(e) => selectedImageChanged(e)}
            ref={fileInputRef}
            accept={accept}
          />
        );
      }
      return (
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(e) => selectedImageChanged(e)}
          ref={fileInputRef}
          accept={accept}
          multiple
        />
      );
    }

    /**
     * helper function for rendering the input
     * @returns the dom of the input
     */
    function renderInput() {
      return (
        <span
          style={!multipleFiles && filesData.length >= 1 ? { display: "none" } : {}}
          className="fileInput"
          onDrop={(e) => (directory ? fileDroppedInDirMode(e) : fileDropped(e))}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onClick={selectFileClicked}
        >
          Drop file
          <img src="/UploadIcon.png" alt="upload file icon" />
          Or click here to select a file
          {createFileInput()}
        </span>
      );
    }

    /**
     * renders the whole component
     * @returns the dom of the whole component
     */
    function renderComponent() {
      return (
        <>
          {renderLoadedFiles()}
          {renderInput()}
        </>
      );
    }

    return renderComponent();
  }
);

export interface FileInputRef {
  getFilesData: () => FileData[];
  getUploadedFileData: () => FileData[];
  changeFileStatus: (id: number, status: boolean) => void;
  updateProgress: (id: number, prog: number) => void;
  clearFiles: () => void;
}

export interface FileInputComponentProps {
  multipleFiles: boolean;
  directory?: boolean;
  accept?: string;
  percentages?: boolean;
  loadedFileXButtonAtStart?: boolean;
}
export interface FileData {
  id: number;
  fileName: string;
  data: File;
  status?: boolean;
  progress: number;
}

export default FileInput;
