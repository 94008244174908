import * as React from 'react';
import { ScanMonitorInfo } from './MonitorTypes';
import { Box, Card, Stack } from '@mui/material';
import PageCard from '../Templates/PageCard';

function AggregatedDataDisplay({ monitorInfo }: AggregatedDataDisplayProps) {
  const preloadableScans = React.useMemo(() => {
    return monitorInfo.filter(
      (info) => info.monitoringInfo.scanStep.status !== 'FAILED' && info.scanType !== 'TEST'
    );
  }, [monitorInfo]);
  function buildStageDisplay(
    monitorInfo: ScanMonitorInfo[],
    getStageInfo: (info: ScanMonitorInfo) => StatusElement,
    stageName: string
  ) {
    return (
      <Stack gap="0.5rem">
        <Box>
          {stageName} succeeded:{' '}
          {monitorInfo.filter((info) => getStageInfo(info).status === 'DONE').length} /{' '}
          {monitorInfo.length}
        </Box>
        <Box>
          {stageName} failed:{' '}
          {monitorInfo.filter((info) => getStageInfo(info).status === 'FAILED').length} /{' '}
          {monitorInfo.length}
        </Box>
      </Stack>
    );
  }
  return (
    <PageCard>
      <Stack direction="row" gap="1rem" sx={{ marginRight: 2, marginLeft: 2 }}>
        {buildStageDisplay(monitorInfo, (info) => info.monitoringInfo.scanStep, 'Scan')}
        {buildStageDisplay(monitorInfo, (info) => info.monitoringInfo.uploadStep, 'Upload')}
        {buildStageDisplay(preloadableScans, (info) => info.monitoringInfo.preloadStep, 'Preload')}
        {buildStageDisplay(
          preloadableScans,
          (info) => info.monitoringInfo.utilityFinderStep,
          'Utility finder'
        )}
        {buildStageDisplay(
          preloadableScans,
          (info) => info.monitoringInfo.analysisStep,
          'Analysis'
        )}
      </Stack>
    </PageCard>
  );
}

export interface AggregatedDataDisplayProps {
  monitorInfo: ScanMonitorInfo[];
}

export interface StatusElement {
  status: string;
}

export default AggregatedDataDisplay;
