import * as React from 'react';
import { Autocomplete, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import {
  GeoJsonLayer,
  Layer,
  findAllLayersByPredicate,
  globalSelectToggleRec
} from '../../../Utils/layerUtils';
import LayersList from './LayersList';
import { Dict } from '../../../BasicTypes';
import LayerPropertiesModal from './LayerPropertiesModal';
import { Api, GeoShape, SiteDetails, UserLayerShape } from '../../../Generated/ExoDBAPI';
import { getRequestParams } from '../../../Utils/azureAuth';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UndoIcon from '@mui/icons-material/Undo';
import axios from 'axios';
import { DrawnContext } from '../Drawns/DrawnFileData';
import { copyS3JsonFile, returnStatus, updateLayersToS3 } from '../../../Services/s3services';
import SaveIcon from '@mui/icons-material/Save';
import { DialogConfirmation } from './DialogConfirmation';
import { exovision, exovision_edit, getColorFromTypeObject } from '../../../Constant/exoVision';
import LayerContext from '../../Contexts/LayerContext';
import { Md5 } from 'ts-md5';
import { AnySoaRecord } from 'dns';
const SUPPORTED_GEOJSON_GEOMETRIES = ['LineString', 'Polygon'];

function createLayerFromGeoJson(geojson: any, name: string, id: string): any {
  const layerColor = geojson?.properties?.exofuser_style?.color;

  const defaultColor =
    geojson?.type === 'FeatureCollection'
      ? '#' +
        (Number(BigInt('0x' + Md5.hashAsciiStr(name, false)) % BigInt(0xffffff)) << 0)
          .toString(16)
          .padStart(6, '0')
      : '#0000FF';
  return {
    id: id,
    name: name,
    metadata: geojson['properties'] ? geojson['properties'] : undefined,
    data: geojson?.type === 'FeatureCollection' ? undefined : geojson,
    visible: true,
    selected: false,
    color: geojson['properties']?.data?.properties?.type
      ? getColorFromTypeObject(geojson['properties']?.data?.properties?.type)
      : layerColor ?? defaultColor,
    overrideColor: layerColor !== undefined,
    subLayers: ((geojson['features'] as any[]) ?? []).map((f, i) =>
      createLayerFromGeoJson(f, `${name} - ${i}`, `${id} - ${i}`)
    )
  };
}

export async function getLayerObjFromId(layerId: string, layerName: string, api: Api<unknown>) {
  let res = await api.analysis.getAnalysisGeojsonLayer(
    encodeURIComponent(layerId),
    await getRequestParams()
  );

  const rawJson = (await axios.get(res.data.url)).data;
  if (rawJson['type'] === 'FeatureCollection') {
    const layerColor = rawJson?.properties?.style?.color;
    const layer = {
      id: layerId,
      name: layerName,
      metadata: rawJson['properties'] ? rawJson['properties'] : undefined,
      data: undefined,
      visible: true,
      selected: false,
      color: layerColor ?? '#0000ff',
      overrideColor: layerColor !== undefined,
      subLayers: ((rawJson['features'] as any[]) ?? []).map((f, i) =>
        createLayerFromGeoJson(
          f,
          f?.properties?.name ? f?.properties?.name : `${layerName} - ${i}`,
          `${layerName} - ${i}`
        )
      )
    };
    return layer;
  }

  return createLayerFromGeoJson(rawJson, layerName, layerId);
}

function ManageGeoJsonLayers({
  layers,
  setLayers,
  site,
  externallySelectedLayerId,
  drawnContext
}: ManageGeoJsonLayersProps) {
  const [layerOptions, setLayerOptions] = React.useState<AnalysisLayersSelectInfo[]>([]);
  const [modalConfig, setModalConfig] = React.useState<PropertiesModalConfig>({
    layerId: '',
    visible: false,
    properties: {},
    coords: [0, 0],
    layerName: ''
  });
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const [layerSelected, setLayerSelected] = React.useState<AnalysisLayersSelectInfo | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const {
    lastLayer,
    setLastLayer,
    setGlobalLayerSelected,
    globalChangeLayers,
    setGlobalChangeLayers
  } = React.useContext(LayerContext);

  function reportInfoClick(
    layerId: string,
    layerName: string,
    properties: Dict<any>,
    x: number,
    y: number
  ) {
    if (modalConfig.layerId === layerId && modalConfig.visible === true) {
      const tempConfig = { ...modalConfig };
      setModalConfig({ ...tempConfig, visible: false });
      return;
    }
    setModalConfig({
      layerId: layerId,
      properties: properties,
      coords: [x, y],
      layerName: layerName,
      visible: true
    });
  }

  React.useEffect(() => {
    async function fetchData() {
      if (site !== undefined) {
        const api = new Api();
        const res = await api.analysis.getAnalysisGeojsonsLayers(
          { plannedSite: site.name },
          await getRequestParams()
        );
        setLayerOptions(res.data.layers);
        const exovisionEditLayer = res.data.layers.find((layer) =>
          layer.name.includes(exovision_edit)
        );
        const exovisionLayer = res.data.layers.find((layer) => layer.name.includes(exovision));
        if (exovisionEditLayer) {
          setGlobalLayerSelected(exovision_edit);
          onLayerSelected(exovisionEditLayer);
        } else if (exovisionLayer) {
          setGlobalLayerSelected(exovision);
          onLayerSelected(exovisionLayer);
        }
      }
    }
    fetchData();
  }, [site, refreshFlag, setGlobalLayerSelected]);

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    const selectedLayer = layerOptions.find((layer) => layer.name === newInputValue);
    if (selectedLayer === undefined) {
      return;
    }

    setLayerSelected(selectedLayer);
    setGlobalLayerSelected(newInputValue);
    const layer = layers.find((layer) => layer.name === newInputValue);
    if (layer && newInputValue === exovision_edit) {
      setLayers([layer]);
    }
  };

  function globalSelectToggle(layerId: string) {
    const newLayers = globalSelectToggleRec(layerId, layers);
    setLayers(newLayers);
  }

  function addLayersToDrawing() {
    const selectedLayers = findAllLayersByPredicate(layers, (layer) => layer.selected);
    const newShapes: UserLayerShape[] = selectedLayers
      .filter(
        (l) => l.data !== undefined && SUPPORTED_GEOJSON_GEOMETRIES.includes(l.data?.geometry?.type)
      )
      .map((l) => {
        const geoData = l.data;
        const shapeType =
          geoData.geometry.type === 'LineString' ? GeoShape.Polyline : GeoShape.Polygon;
        let coords: [number, number][] = [];
        if (shapeType === GeoShape.Polyline) {
          coords = geoData.geometry.coordinates;
        } else {
          coords = geoData.geometry.coordinates[0];
        }

        return {
          markingType: 'Identified',
          timestamp: Date.now(),
          shapeType: shapeType,
          lats: coords.map((c) => c[1]),
          lons: coords.map((c) => c[0]),
          name: l.name,
          layerAttributes: { QL: 'Identified' }
        };
      });
    drawnContext.setAllDrawnShapes((shapes) => [...shapes, ...newShapes]);
  }

  async function onLayerSelected(selectedLayer: AnalysisLayersSelectInfo | null) {
    if (selectedLayer === null) {
      return;
    }
    if (layers.find((layer) => layer.id === selectedLayer.id) !== undefined) {
      return;
    }

    const api = new Api();
    const layer = await getLayerObjFromId(selectedLayer.id, selectedLayer.name, api);

    if (layer && selectedLayer.name === exovision_edit) {
      setLayers([layer]);
    } else {
      setLayers((currentLayers) => [...currentLayers, layer]);
    }
  }

  async function saveLayers(layers: GeoJsonLayer[], targetLayer: string) {
    await updateLayersToS3(layers, targetLayer, 'layer.json');
    setGlobalChangeLayers(false);
  }

  async function copyLayerOnS3(override: boolean) {
    if (layerSelected?.name !== exovision) {
      return;
    }
    const layerId = layerSelected?.id;
    const returnMessageStatus: returnStatus = await copyS3JsonFile(
      layerId,
      `${layerId}_edit`,
      'layer.json',
      'layer.json',
      override
    );
    if (returnMessageStatus.status === 409) {
      setOpenDialog(true);
      return;
    }
  }

  const handleAgreeClose = () => {
    setOpenDialog(false);
    copyLayerOnS3(true);
  };
  const handleDisagreeClose = () => {
    setOpenDialog(false);
  };

  const handleUndo = (lastLayer: Layer<any, any> | undefined) => {
    const firstLayer = layers?.[0];
    if (firstLayer?.subLayers && lastLayer) {
      firstLayer.subLayers.push(lastLayer);
    }

    setLastLayer(undefined);
    setGlobalChangeLayers(true);
  };

  return (
    <>
      <Stack gap="1rem">
        <Typography variant="h5">Load Layers</Typography>
        <Stack direction="row">
          <Autocomplete
            options={layerOptions}
            getOptionLabel={(o) => o.name}
            placeholder="Layers"
            renderInput={(params) => (
              <TextField {...params} key={`option-${params.id}`} placeholder="Layers" />
            )}
            sx={{ width: 500 }}
            onChange={(e, newVal) => onLayerSelected(newVal)}
            size="small"
            onInputChange={handleInputChange}
            value={
              layerOptions.find((option) => option.name === exovision_edit) ||
              layerOptions.find((option) => option.name === exovision) ||
              null
            }
          />
          <IconButton onClick={() => setRefreshFlag((val) => !val)}>
            <RefreshIcon />
          </IconButton>
          <IconButton onClick={addLayersToDrawing}>
            <EditIcon />
          </IconButton>
          <Tooltip title="Copy Layers">
            <div>
              <IconButton
                onClick={() => copyLayerOnS3(false)}
                disabled={layerSelected?.name !== exovision}>
                <ContentCopyIcon />
              </IconButton>
            </div>
          </Tooltip>
          {layerSelected && (
            <Tooltip title="Save Layer">
              <div>
                <IconButton
                  onClick={() => saveLayers(layers, layerSelected.id)}
                  disabled={layerSelected?.name !== exovision_edit || !globalChangeLayers}>
                  <SaveIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
          {lastLayer && layerSelected?.name === exovision_edit && (
            <Tooltip title="Undo">
              <IconButton
                onClick={() => {
                  handleUndo(lastLayer);
                }}>
                <UndoIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <LayersList
          layers={layers}
          setLayers={setLayers}
          reportInfoClick={reportInfoClick}
          toggleGlobalSelect={globalSelectToggle}
          closeEnabled={true}
          externallySelectedLayerId={externallySelectedLayerId}
        />
      </Stack>
      {modalConfig.visible && (
        <LayerPropertiesModal
          properties={modalConfig.properties}
          coords={modalConfig.coords}
          onClose={() => setModalConfig((val) => ({ ...val, visible: false }))}
          key={`modal-${modalConfig.layerId}`}
          layerName={modalConfig.layerName}
        />
      )}

      <DialogConfirmation
        openDialog={openDialog}
        handleAgreeClose={handleAgreeClose}
        handleDisagreeClose={handleDisagreeClose}
      />
    </>
  );
}

export interface ManageGeoJsonLayersProps {
  layers: GeoJsonLayer[];
  setLayers: React.Dispatch<React.SetStateAction<GeoJsonLayer[]>>;
  site?: SiteDetails;
  externallySelectedLayerId?: string;
  drawnContext: DrawnContext;
}

export interface AnalysisLayersSelectInfo {
  name: string;
  id: string;
}

export interface PropertiesModalConfig {
  properties: Dict<any>;
  layerId?: string;
  layerName: string;
  visible: boolean;
  coords: [number, number];
}

export default ManageGeoJsonLayers;
