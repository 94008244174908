import { Stack } from '@mui/material';
import { Typography } from '../../../../../shared';

export const MetaDataRow = ({
  title,
  content
}: {
  title: string;
  content: string | number | JSX.Element | null;
}) => {
  return (
    <Stack direction="row" spacing={1}>
      <Typography fontWeight={600}>{title}:</Typography>
      <Typography flex={1} minWidth={0} fontWeight={400}>
        {content}
      </Typography>
    </Stack>
  );
};
