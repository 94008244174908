import { GetTargetScanQuery } from '../../../../Generated/Graphql';
import { TargetObject, ParsedTargetObect } from '../../../../Types/MappingEntities';

export const parseTargets = (data: GetTargetScanQuery | undefined) => {
  try {
    if (!data?.searchScans?.items) return [];
    const items = Array.isArray(data.searchScans.items)
      ? data.searchScans.items
      : [data.searchScans.items];

    const filteredItems = items.filter((item) => item !== null) as TargetObject[];

    const parsedTargets: ParsedTargetObect[] = filteredItems.map((item) => ({
      ...item,
      metadata: JSON.parse(item.metadata || '{}'),
      target_info: JSON.parse(item.target_info || '{}')
    }));
    return parsedTargets;
  } catch (err) {
    return [];
  }
};
