import { useContext, useState } from "react";
import { AnalysisProductsTravelCourse, ScanDetails } from "../../DataTypes/MapEntities"
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import UnifyDataUnit from "./UnifyDataUnit";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CropIcon from '@mui/icons-material/Crop';
import { useUnifyStore } from "./unifyStore";
import { useCreateTasksScansConnectionMutation, useCreateUnifyTaskMutation } from "../../../../Generated/Graphql";
import UserContext from "../../../Contexts/UserContext";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import ClearIcon from '@mui/icons-material/Clear';

interface UnifyDataProps {
    travelFiltered: AnalysisProductsTravelCourse[];
    siteId: string;
}

export interface dataModel {
    areaId: number;
    item: string;
    polygon: number;
    scans: ScanDetails[];
}

const UnifyData = ({ travelFiltered , siteId}: UnifyDataProps) => {
    const { selectedUnifyArea, updateSelectedUnifyArea, updateUnifyAreaScans, clearAll } = useUnifyStore();
    const [createUnifyTaskMutation] = useCreateUnifyTaskMutation();
    const [createTasksScansConnectionMutation] = useCreateTasksScansConnectionMutation();
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const flipLatLng = (polygonData: [number, number][]): [number, number][] => {
      return polygonData.map((latLng) => [latLng[1], latLng[0]]);
    };

    const updateData = (areaId: number, polygon: number, scans_names: string[]) => {
        if (polygon === undefined || !areaId) return;
        const area = selectedUnifyArea.find(area => area.id === areaId);
        if (!area) return;
        updateUnifyAreaScans(areaId, polygon, scans_names);
    }

    const removeData = (areaId: number, item: string) => {
        const area = selectedUnifyArea.find(area => area.id === areaId);
        if (!area) return;
        updateSelectedUnifyArea(areaId, item);
    }

    const unifyAction = () => {
        if (selectedUnifyArea.length === 0) {
            return;
        }
        setLoading(true);

        selectedUnifyArea.forEach(async (area , index) => {
            const polygonData = area.polygon;

            const geo_data = JSON.stringify({ geometry: { coordinates: [flipLatLng(polygonData)], type: "Polygon" }, type: "Feature", properties: {} })
            const scanIds = area.model.flatMap(model => model.scans.map(scan => scan.id)).filter(scanId => scanId !== undefined && scanId !== null);
            const unifyTaskIdMutation = await createUnifyTaskMutation({variables:
                {
                    geoData: geo_data,
                    user_email: user?.account?.username ?? '',
                    siteId: siteId
                }})
            const unifyTaskId = unifyTaskIdMutation.data?.createUnifyTask?.id;
            if (!unifyTaskId || unifyTaskId === undefined) return;
            setProgress((index + 1) * 100 / selectedUnifyArea.length);
            scanIds.forEach(async (scanId) => {
                if (scanId === undefined || scanId === null) {
                    return;
                }
                const id = await createTasksScansConnectionMutation(
                    {variables:
                        {
                            scanId,
                            unifyTaskId
                        }}
                    )
            });
        });
        setLoading(false);
        clearAll();
    }

    if(loading) return (<CircularProgressWithLabel value={progress} size={200} fontSize="2rem" />)

    return (<>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1}}>
                {selectedUnifyArea.map((area) => {
                    const items = [];
                    for (const model of area.model) {
                        items.push(
                            <UnifyDataUnit
                                key={model.item}
                                item={model.item}
                                polygon={model.polygon?.toString() ?? ''}
                                areaId={model.areaId}
                                scansIds={model.scans.map(scan => scan.name)}
                                travelFiltered={travelFiltered}
                                updateData={updateData}
                                removeData={removeData}
                            />
                        );
                    }
                    return items;
                })}
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 8}}>
                    <Button variant="contained" startIcon={<CropIcon />} endIcon={<ContentCopyIcon />} onClick={unifyAction} disabled={selectedUnifyArea.length === 0 || selectedUnifyArea[0].model.length === 0} >
                            Crop / Unify
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={clearAll}
                        disabled={selectedUnifyArea.length === 0}
                        endIcon={<ClearIcon />}
                    >
                        Clear All
                    </Button>
                    </Box>
                </Box>
        </>
  )
}

export default UnifyData
