import * as React from "react";
import L from "leaflet";
import { useMap, GeoJSON } from "react-leaflet";

function MapBoundsGeoJsons({ geoJsons, expectedGeoJsons }: MapBoundsGeoJson) {
  const map = useMap();
  function getGeoJsons() {
    return geoJsons.map((g, i) => <GeoJSON data={g} key={`boundingGeoJson${i}`} />);
  }

  React.useEffect(() => {
    if (geoJsons.length === expectedGeoJsons) {
      const group = L.featureGroup(geoJsons.map((j) => L.geoJSON(j)));
      const bounds = group.getBounds();
      map.fitBounds(bounds);
    }
  }, [expectedGeoJsons, geoJsons, map]);

  return <>{getGeoJsons()}</>;
}

export interface MapBoundsGeoJson {
  geoJsons: any[];
  expectedGeoJsons: number;
}

export default MapBoundsGeoJsons;
