import { Box, Collapse } from "@mui/material";
import React from "react";
import PageCard from "../Templates/PageCard";

function AggregatedScanCard({ header, children, cardProps = {} }: AggregatedScanCardProps) {
  const [collapsed, setCollapsed] = React.useState(true);

  function createCollapseButton() {
    if (collapsed) {
      return <img src="doubleArrowsDown.png" className="scanCollapseImage" />;
    }
    return <img src="doubleArrowsUp.png" className="scanCollapseImage" />;
  }
  return (
    <PageCard {...cardProps}>
      <div className="headerWrapper" onClick={() => setCollapsed((val) => !val)}>
        {header}
        <div style={{ position: "relative" }}>
          <span className="ScanCardButtons">{createCollapseButton()}</span>
          <hr />
        </div>
      </div>

      <Collapse in={!collapsed}>{children}</Collapse>
    </PageCard>
  );
}

export interface AggregatedScanCardProps {
  header: React.ReactNode;
  children: React.ReactNode;
  cardProps?: any;
}

export default AggregatedScanCard;
