import JSZip from "jszip";

export function readFileUrl(file: File): Promise<string | null> {
  return readFileFromSource(
    file,
    (result) => typeof result === "string",
    "Data should be of type string for function `readAsDataURL`",
    (fr, file) => fr.readAsDataURL(file)
  );
}

export function readFileString(file: File): Promise<string | null> {
  return readFileFromSource(
    file,
    (result) => typeof result === "string",
    "Data should be of type string for function `readAsText`",
    (fr, file) => fr.readAsText(file)
  );
}

export function readFileArrayBuffer(file: File): Promise<ArrayBuffer | null> {
  return readFileFromSource(
    file,
    (result) => result instanceof ArrayBuffer,
    "Data should be of type ArrayBuffer for function `readAsArrayBuffer`",
    (fr, file) => fr.readAsArrayBuffer(file)
  );
}

function readFileFromSource(
  file: File,
  sourceTypePredicate: (result: any) => boolean,
  messageOnBadType: string,
  readAction: (fileReader: FileReader, blob: Blob) => void
): Promise<any> {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      if (!sourceTypePredicate(fr.result)) {
        console.log(messageOnBadType);
        return null;
      }
      resolve(fr.result);
    };
    fr.onerror = reject;
    readAction(fr, file);
  });
}

export function readZipData(file: ArrayBuffer): Promise<JSZip> {
  const zip = JSZip();
  return zip.loadAsync(file);
}

export function getFileName(filePath: string) {
  const fileName = filePath.split("/").pop()?.split(".")[0];
  if (fileName === undefined) {
    throw new Error(`Invalid file path ${filePath}`);
  }
  return fileName;
}
