/*
This code is a slightly adapted part from Paramanantham Harrison code at:
    https://blog.logrocket.com/complete-guide-building-smart-data-table-react/

As far as we could find, this code is not licensed
*/

import {
  ColumnDef,
  TableOptions,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import './Table.css';

export function Table<T>({
  columns,
  data,
  onRowClick,
  selectedRow,
  ...rest
}: Omit<TableOptions<T> & { onRowClick?: (args: T) => void; selectedRow?: T }, 'getCoreRowModel'>) {
  const { getRowModel, getHeaderGroups, getFooterGroups } = useReactTable({
    ...rest,
    columns,
    data,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className="table-container">
      <table>
        <thead className="table-header">
          {getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          style={{
            overflow: 'auto'
          }}>
          {getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  style={{
                    cursor: onRowClick && 'pointer',
                    background: row.original === selectedRow ? 'rgba(0,0,0,0.15)' : 'unset'
                  }}
                  className="table-row"
                  onClick={() => onRowClick && onRowClick(row.original)}
                  key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>

        <tfoot>
          {getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
}

export interface TableProps<T extends object> {
  columns: ColumnDef<T>[];
  data: T[];
}

export default Table;
