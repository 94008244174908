import { RetryQueryConfig } from "../../Utils/promiseWithRetries";

// Feature download configuration

// On first loading of the site, use higher concurrency and retry more
export const LOAD_SITE_FEAUTRES_QUERY_CONFIG: FeatureQueryConfig = {
  maxConcurrency: 6,
  retryConfig: {
    maxRetries: 3,
    waitBeforeRetryMilliseconds: 500,
  },
  featuresPerQuery: 1,
};

// On first loading of the site, use higher concurrency and retry more
export const LOAD_SITE_LAYERS_QUERY_CONFIG: FeatureQueryConfig = {
  maxConcurrency: 1,
  retryConfig: {
    maxRetries: 5,
    waitBeforeRetryMilliseconds: 1000,
  },
  featuresPerQuery: 64,
};

// On retry, there should be less, more complex features - reduce concurrency to reduce errors
export const REFERSH_SITE_FEAUTRES_QUERY_CONFIG: FeatureQueryConfig = {
  maxConcurrency: 3,
  retryConfig: {
    maxRetries: 2,
    waitBeforeRetryMilliseconds: 500,
  },
  featuresPerQuery: 1,
};

export const EXOMAP_QUERY_CONFIG: RetryQueryConfig = {
  maxRetries: 2,
  waitBeforeRetryMilliseconds: 500,
};

export const DRAWN_LAYER_QUERY_CONFIG: RetryQueryConfig = {
  maxRetries: 3,
  waitBeforeRetryMilliseconds: 500,
};

export interface FeatureQueryConfig {
  maxConcurrency: number;
  retryConfig: RetryQueryConfig;
  featuresPerQuery: number;
}
