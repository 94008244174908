import { Autocomplete, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import * as React from 'react';
import {
  useGetDailySummaryInfoLazyQuery,
  useGetSitesWithScansInDateRangeLazyQuery
} from '../../Generated/Graphql';
import { reportAllPagesResults } from '../../Utils/graphqlUtils';
import DownloadButton from '../Templates/DownloadButton';
import { SiteInfo } from './MonitorTypes';

function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function DailySummary() {
  const [allSites, setAllSites] = React.useState<SiteInfo[]>([]);
  const [summaryDate, setSummaryDate] = React.useState<Date>(new Date());
  const [selectedSite, setSelectedSite] = React.useState<SiteInfo | null>(null);
  const [dailySummaryFileContent, setDailySummaryFileContent] = React.useState<string>();
  const [_, dailySummaryResult] = useGetDailySummaryInfoLazyQuery({
    variables: {
      startTimeStamp: ~~(
        addDays(new Date(new Date(summaryDate).setHours(0, 0, 0, 0)), -1).getTime() / 1000
      ),
      endTimeStamp: ~~(
        addDays(new Date(new Date(summaryDate).setHours(24, 0, 0, 0)), 1).getTime() / 1000
      ),
      siteId: selectedSite?.id
    }
  });
  const [_getSitesInScansRange, sitesInScanRangeResults] = useGetSitesWithScansInDateRangeLazyQuery(
    {
      variables: {
        startTimeStamp: ~~(
          addDays(new Date(new Date(summaryDate).setHours(0, 0, 0, 0)), -1).getTime() / 1000
        ),
        endTimeStamp: ~~(
          addDays(new Date(new Date(summaryDate).setHours(24, 0, 0, 0)), 1).getTime() / 1000
        )
      }
    }
  );

  React.useEffect(() => {
    setAllSites([]);
    reportAllPagesResults(
      sitesInScanRangeResults,
      (res) => {
        const items = res.data.searchScans?.items;
        if (!items) {
          return;
        }
        const sites = items
          .map((scan) => scan?.site)
          .map((item) =>
            item === null || item === undefined
              ? null
              : { name: item.name, id: item.id, locationId: item.locationSitesId }
          )
          .filter((item) => item !== null) as SiteInfo[];
        setAllSites((val) => {
          const siteNames = val.map((s) => s.name);
          const newSitesIds = new Set<string>(sites.map((s) => s.id));
          const newSites: SiteInfo[] = [];
          newSitesIds.forEach((id) => {
            newSites.push(sites.find((site) => site.id === id) as SiteInfo);
          });
          const relevantSites = newSites.filter((s) => !siteNames.includes(s.name));
          return [...val, ...relevantSites];
        });
      },
      (res) => res.data.searchScans?.nextToken
    );
  }, [summaryDate]);

  React.useEffect(() => {
    buildDailySummary();
  }, [summaryDate, selectedSite]);

  async function buildDailySummary() {
    setDailySummaryFileContent(undefined);

    const dailySummaryRows: DailySummaryRow[] = [];
    await reportAllPagesResults(
      dailySummaryResult,
      (res) => {
        const scans = res.data.searchScans?.items;
        if (!scans) {
          return;
        }
        for (const scan of scans) {
          if (scan === null || !scan.name) {
            continue;
          }
          const scanStart = new Date(scan.start_timestamp * 1000);
          const scanEnd = new Date(scan.end_timestamp * 1000);
          const dateParts = scan.name.match('.*_(.*)_(.*)_(.*)_(.*)_(.*)_(.*)$');
          if (dateParts === null || Number.parseInt(dateParts[3]) !== summaryDate.getDate()) {
            continue;
          }
          let scanFrequencyData = [
            { frequency: scan.frequency ?? undefined, scanType: scan.scan_type }
          ];

          const transmitterSetups = scan.transmitter_setups;
          if (
            scan.scan_type === 'SURVEY' &&
            transmitterSetups &&
            transmitterSetups.items.length > 0
          ) {
            scanFrequencyData.push(
              ...transmitterSetups.items.map((t) => ({
                frequency: t?.frequency,
                scanType: 'TRANSMITTER'
              }))
            );
          }
          for (const scanFreqData of scanFrequencyData) {
            dailySummaryRows.push({
              scanName: scan.name ?? '',
              isValid: scan.is_valid ?? false,
              sensorType: scan.sensor.model,
              scanType: scanFreqData.scanType,
              date: scanStart,
              comment: scan.comment ?? '',
              frequency: scanFreqData.frequency ?? undefined,
              scanDuration: ~~((scanEnd.getTime() - scanStart.getTime()) / 1000),
              polygonIndex: scan.polygon_index ?? undefined,
              snakeIndex: scan.snake_index ?? undefined,
              partIndex: scan.part_index ?? undefined
            });
          }
        }
      },
      (res) => res.data.searchScans?.nextToken
    );
    const dailySummaryCsvRows = [
      'is valid, sensor type, scan type, scan name, date, scan duration, polygon, snake, part, comment, frequency'
    ];
    for (const row of dailySummaryRows) {
      dailySummaryCsvRows.push(
        `${row.isValid}, ${row.sensorType}, ${row.scanType}, ${row.scanName}, ${row.date}, ${
          row.scanDuration
        }, ${row.polygonIndex ?? ''}, ${row.snakeIndex ?? ''}, ${row.partIndex ?? ''}, ${
          row.comment
        }, ${row.frequency ?? ''}`
      );
    }

    setDailySummaryFileContent(dailySummaryCsvRows.join('\n'));
  }

  return (
    <Stack direction="row" gap={1}>
      <Autocomplete
        options={allSites}
        getOptionLabel={(o) => o.name}
        renderInput={(params) => (
          <TextField
            {...params}
            key={`option-${params.id}`}
            placeholder="Sites"
            error={selectedSite === null}
          />
        )}
        sx={{ width: 250 }}
        value={selectedSite}
        onChange={(e, v) => setSelectedSite(v)}
      />
      <DatePicker
        label="Date"
        renderInput={(params) => <TextField {...params} />}
        value={summaryDate}
        onChange={(newValue: any) => {
          newValue ? setSummaryDate(newValue.$d) : new Date();
        }}
      />
      <DownloadButton
        buttonName="Download Daily Summary"
        fileName={`DailySummary-${summaryDate.toDateString()}.csv`}
        data={dailySummaryFileContent}
        disabled={dailySummaryFileContent === undefined}
      />
    </Stack>
  );
}

export interface DailySummaryRow {
  isValid: boolean;
  sensorType: string;
  scanType: string;
  scanName: string;
  date: Date;
  scanDuration: number;
  comment: string;
  frequency: number | undefined;
  polygonIndex: number | undefined;
  snakeIndex: number | undefined;
  partIndex: number | undefined;
}

export default DailySummary;
