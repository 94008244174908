import { useMemo, useState } from 'react';
import { Scan, useSearchScansLocationValidLazyQuery } from '../../Generated/Graphql';
import { SiteInfo } from '../Common/ChooseSite';
import { Box, Button, IconButton, Typography } from '@mui/material';
import './SETTING.css';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Api as ServicesApi } from '../../Generated/ExoServicesAPI';
import { getServicesRequestParams } from '../../Utils/azureAuth';
import { reportAllPagesResults } from '../../Utils/graphqlUtils';
import ScanDateQuery from './ScanDateQuery';

export default function SettingScanValidation() {
  const [selectedScan, setSelectedScan] = useState<Scan | null>(null);
  const [selectedScanSite, setSelectedScanSite] = useState<SiteInfo | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [, scansResults] = useSearchScansLocationValidLazyQuery();
  const [siteScans, setSiteScans] = useState<Scan[] | undefined>([]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const loadScans = useMemo(
    () =>
      async function (siteId: string) {
        let startScan = 0;
        reportAllPagesResults(
          scansResults,
          (res) => {
            const scans = (res?.data?.searchScans?.items ?? []).filter(
              (item) => item !== null
            ) as Scan[];
            if (!scans || scans.length === 0) {
              return;
            }
            setSiteScans((sScans) => {
              return [...(startScan === 0 ? [] : sScans || []), ...scans];
            });
            startScan += scans.length;
          },
          (res) => res.data.searchScans?.nextToken,
          { siteId: siteId, is_valid: false }
        );
      },
    []
  );

  const handleMoveScan = async () => {
    console.log('forceScanValid');
    if (!selectedScan || !selectedScan.id || !selectedScanSite || !selectedScanSite.id) {
      return;
    }

    const api = new ServicesApi();
    try {
      await api.fix.forceScanValid(
        selectedScan.id,
        {},
        await getServicesRequestParams()
      );

      setErrorMessage('ScanValid moved successfully');
    } catch (e) {
      setErrorMessage(`Got an error while trying to valid scan, ${JSON.stringify(e)}`);
    } finally {
      setSelectedScan(null);
      setSelectedScanSite(null);
    }
  };
  const getMessageStyle = () => {
    if (errorMessage.toLowerCase().includes('success')) {
      return { color: 'green', fontWeight: 'bold' };
    } else if (errorMessage) {
      return { color: 'red', fontWeight: 'bold' };
    }
    return {};
  };
  return (
    <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Force Scan Valid
        </Typography>
        <IconButton onClick={toggleExpand}>
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      {isExpanded && (
        <div className="one_line">
          <ScanDateQuery
            setSelectedScan={setSelectedScan}
            selectedScan={selectedScan}
            setSelectedScanSite={setSelectedScanSite}
            loadScans={loadScans}
            siteScans={siteScans}
            title="Source Scan"
            format={'^p\\d+_s\\d+_part\\d+_.+.'}
          />
          {selectedScanSite && selectedScan && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '& button': { m: 1, ml: 2 }
                }}>
                <Button
                  variant="contained"
                  size="large"
                  value="MoveScan"
                  className="selectButton"
                  disabled={!selectedScan}
                  onClick={handleMoveScan}>
                  Scan Valid
                </Button>
              </Box>
            </>
          )}
        </div>
      )}
      {errorMessage && <Typography sx={getMessageStyle()}>{errorMessage}</Typography>}
    </Box>
  );
}
