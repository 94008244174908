import { Checkbox, FormControlLabel, FormLabel, Slider, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { FilterCategoryProps, IFilterCategoryFactory, MINIMAL_RANK } from './BaseFilterCategory';

type Props<T> = {
  name: string;
  rangeMin: number;
  rangeMax: number;
  getter: (item: T) => number | undefined;
  items: T[];
  reportFilteredEntities: (entities: T[]) => void;
  resetFiterTrigger: boolean;
};

const UPDATE_SLIDER_DELAY_MS = 200;

function RangeFilter<T>({
  name,
  rangeMin,
  rangeMax,
  getter,
  items,
  reportFilteredEntities,
  resetFiterTrigger
}: Props<T>) {
  const [range, setRange] = React.useState([rangeMin, rangeMax]);
  const [displayedRange, setDisplayedRange] = React.useState([rangeMin, rangeMax]);
  const [showFieldsWithoutProperty, setShowFieldsWithoutProperty] = React.useState(true);
  const [rangeMinTextFieldValue, setRangeMinTextFieldValue] = React.useState(range[0]);
  const [rangeMaxTextFieldValue, setRangeMaxTextFieldValue] = React.useState(range[1]);
  const lastSliderChange = React.useRef<{ value: number }>({ value: 0 });
  React.useEffect(() => {
    let filterdItems = [];
    filterdItems = items.filter((item) => {
      const prop = getter(item);
      return prop !== undefined && (range[0] > prop || prop > range[1]);
    });
    if (!showFieldsWithoutProperty) {
      filterdItems = [
        ...filterdItems,
        ...items.filter((item) => getter(item) === undefined || getter(item) === null)
      ];
    }
    reportFilteredEntities(filterdItems);
  }, [JSON.stringify(range), showFieldsWithoutProperty]);

  React.useEffect(() => {
    setRangeMinTextFieldValue(range[0]);
    setRangeMaxTextFieldValue(range[1]);
  }, [JSON.stringify(range)]);

  React.useEffect(() => {
    setRange([rangeMin, rangeMax]);
    setDisplayedRange([rangeMin, rangeMax]);
    setShowFieldsWithoutProperty(true);
  }, [resetFiterTrigger]);

  React.useEffect(() => {
    if (lastSliderChange.current === null) {
      return;
    }
    lastSliderChange.current.value = Date.now();
    setTimeout(() => {
      if (Date.now() - lastSliderChange.current.value > UPDATE_SLIDER_DELAY_MS * 0.95) {
        setRange([...displayedRange]);
      }
    }, UPDATE_SLIDER_DELAY_MS);
  }, [JSON.stringify(displayedRange)]);

  function handleOverrideRangeFromInputs() {
    const [lowRange, highRange] =
      rangeMinTextFieldValue < rangeMaxTextFieldValue
        ? [rangeMinTextFieldValue, rangeMaxTextFieldValue]
        : [rangeMaxTextFieldValue, rangeMinTextFieldValue];
    const newLowRange = lowRange >= rangeMin && lowRange <= rangeMax ? lowRange : range[0];
    const newHighRange = highRange >= rangeMin && highRange <= rangeMax ? highRange : range[1];
    setRange([newLowRange, newHighRange]);
    setDisplayedRange([newLowRange, newHighRange]);
  }

  return (
    <Stack>
      <FormLabel>{name}</FormLabel>
      <Stack direction={'row'} sx={{ alignItems: 'center' }} gap={2}>
        <Stack direction={'row'} sx={{ alignItems: 'center' }}>
          <TextField
            value={rangeMinTextFieldValue}
            onChange={(e) => setRangeMinTextFieldValue(Number(e.target.value))}
            size="small"
            sx={{ width: 100, padding: 1 }}
            inputProps={{ style: { fontSize: '0.85rem' } }}
            variant="standard"
            onBlur={handleOverrideRangeFromInputs}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleOverrideRangeFromInputs();
              }
            }}
            type="number"
          />
          -
          <TextField
            value={rangeMaxTextFieldValue}
            onChange={(e) => setRangeMaxTextFieldValue(Number(e.target.value))}
            size="small"
            sx={{ width: 100, padding: 1 }}
            inputProps={{ style: { fontSize: '0.85rem' } }}
            variant="standard"
            onBlur={handleOverrideRangeFromInputs}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleOverrideRangeFromInputs();
              }
            }}
            type="number"
          />
        </Stack>
        <FormControlLabel
          value={showFieldsWithoutProperty}
          onChange={(_, v) => {
            setShowFieldsWithoutProperty(v);
          }}
          control={
            <Checkbox value={showFieldsWithoutProperty} checked={showFieldsWithoutProperty} />
          }
          label="Show Nones"
          id="show_nones"
          name="show_nones"
        />
      </Stack>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={displayedRange}
        onChange={(_, v) => {
          setDisplayedRange(v as [number, number]);
        }}
        valueLabelDisplay="auto"
        getAriaValueText={() => '!23'}
        min={rangeMin}
        max={rangeMax}
      />
    </Stack>
  );
}

export class RangeFilterFactory<T> implements IFilterCategoryFactory<T> {
  readonly rank: number = MINIMAL_RANK;
  filterName: string;
  rangeMin: number;
  rangeMax: number;
  getter: (item: T) => number | undefined;

  constructor(
    filterName: string,
    rangeMin: number,
    rangeMax: number,
    getter: (item: T) => number | undefined
  ) {
    this.filterName = filterName;
    this.rangeMin = rangeMin;
    this.rangeMax = rangeMax;
    this.getter = getter;
  }
  createDisplayEntity(entity: T): T {
    return entity;
  }
  create(
    categoryProps: FilterCategoryProps<T>,
    strategy?: boolean | ((options: string) => boolean) | undefined,
    filterId?: string | undefined
  ): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
    return (
      <RangeFilter
        key={this.filterName}
        items={categoryProps.entities}
        getter={this.getter}
        name={this.filterName}
        rangeMin={this.rangeMin}
        rangeMax={this.rangeMax}
        reportFilteredEntities={categoryProps.setFilteredEntities}
        resetFiterTrigger={categoryProps.resetFilterTrigger}
      />
    );
  }
}

export default RangeFilter;
