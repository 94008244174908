import * as React from "react";
import {Marker, Polyline, useMap} from "react-leaflet";
import L, {LatLngExpression, LatLngLiteral, LatLngTuple, LeafletMouseEvent} from "leaflet";
import useScalableValue from "./useScalableValue";
import {useRef} from "react";
import {formatPoints, getAngle} from "./helper";
import RotatableText from "./RotatableText";
import { ToolObject,  getFormattedDistance, getMidPoint } from "../MeasurementUtil";
import { UNIT_MEASURES, UnitMeasuresProperties } from "../../../../Utils/const";
import { EntityAction } from "../../../../Types/MappingEntities";
import LocalStorageService from "../../../../Utils/storage";

const textClassName = 'text';
const FONT_SIZE = 0.1;


interface Props {
    points: LatLngLiteral[],
    showStart?: boolean;
    showEnd?: boolean;
    showText?: boolean;
    lineType?: 'dash' | 'solid';
    color?: string;
    setPoints: (points: LatLngLiteral[]) => void;
    data: ToolObject;
    updateData: (action: EntityAction, tool: ToolObject) => void;
}

function getArrowDiv(params: { arrowSize: number, color: string, angle: number }): string {
    const {arrowSize, color, angle} = params;
    return `<div style="
            height: ${arrowSize * 2}px;
            width: ${arrowSize * 2}px;
            transform: rotate(${angle}deg);
            position: relative;

        ">
        <div style="
            position: absolute;
            top: ${-arrowSize}px;
            left: ${-arrowSize}px;
            border-bottom: ${arrowSize * 2}px solid ${color};
            border-left: ${arrowSize * 2}px solid transparent;
        ">
        </div>
    </div>`;
}



function ArrowLine({points, showStart = true, showEnd = true, showText = true, color = 'black', lineType = 'solid', setPoints, data, updateData}: Props) {
    const arrowSize = useScalableValue(0.04);
    const markerRefStart = useRef<L.Marker>(null);
    const markerRefEnd = useRef<L.Marker>(null);
    const markerRefLine = useRef<L.Polyline>(null);
    const [formattedPoints, setFormattedPoints] = React.useState<LatLngTuple[]>([]);
    const [text, setText] = React.useState('');
    const [midPoint, setMidPoint] = React.useState<LatLngExpression | null>(null);
    const [startPoint, setStartPoint] = React.useState<L.LatLngLiteral>();
    const [finishDraw, setFinishDraw] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [endPoint, setEndPoint] = React.useState<L.LatLngLiteral>();
    const arrowClassName = 'arrow-icon'
    const map = useMap();
    const unitMeasures = LocalStorageService.loadData(UNIT_MEASURES) || UnitMeasuresProperties.Feet;
    React.useEffect(() => {
        if (points.length > 1) {
            setStartPoint(points[0]);
            setEndPoint(points[points.length - 1]);
        }
    }, [points]);

    const handleStartDraw = React.useMemo(() => (e: L.LeafletMouseEvent) => {
        if (e?.latlng) {
            setStartPoint(e.latlng);
            setEndPoint(e.latlng);
            map.once('click', handleEndDraw);
            map.on('mousemove', handleMouseMove);
        }
    }, [setStartPoint]);

    const handleEndDraw = React.useMemo(() => (e: L.LeafletMouseEvent) => {
        if (e?.latlng) {
            setEndPoint(e.latlng);
            map.off('mousemove', handleMouseMove);
            setFinishDraw(true);
        }
    }, [setEndPoint]);

    const handleMouseMove = React.useMemo(() => (e: L.LeafletMouseEvent) => {
        setEndPoint(e.latlng);
    }, [setEndPoint]);

    React.useEffect(() => {
        if (points.length < 2) {
            map.once('click', handleStartDraw);
        }
      }, []);

    React.useEffect(() => {
        if (finishDraw && endPoint && startPoint) {
            setPoints([startPoint, endPoint]);
        }
    }, [finishDraw])

    React.useEffect(() => {
        if(endPoint && startPoint) {
            setFormattedPoints(formatPoints([startPoint, endPoint]));
            const midLinePoint = getMidPoint(startPoint as LatLngLiteral, endPoint as LatLngLiteral);
            setMidPoint(midLinePoint);
            const textDistance = getFormattedDistance(startPoint as LatLngLiteral, endPoint as LatLngLiteral, map, unitMeasures) || '';
            setText(textDistance);
        }
      }, [endPoint, unitMeasures]);



    React.useEffect(() => {
        if (markerRefStart && markerRefStart.current) {
            const markerElement = (markerRefStart.current as unknown as { _icon: HTMLDivElement })._icon;
            if (markerElement) {
                markerElement.style.marginTop = `${-arrowSize}px`;
                markerElement.style.marginLeft = `${-arrowSize}px`;
            }
        }

        if (markerRefEnd && markerRefEnd.current) {
            const markerElement = (markerRefEnd.current as unknown as { _icon: HTMLDivElement })._icon;
            if (markerElement) {
                markerElement.style.marginTop = `${-arrowSize}px`;
                markerElement.style.marginLeft = `${-arrowSize}px`;
            }
        }
    });


    const handleEventClick = React.useCallback((e: LeafletMouseEvent) => {
        if (e.originalEvent.ctrlKey) {
          map.removeLayer(e.target);
          if (data?.id) {
            updateData(EntityAction.DELETE, data);
            setDisabled(true);
          }
        }
      }, [map, data, updateData, setDisabled]);


    const updateHtml = (marker: L.Marker | null): void => {
        if (!marker) {
            return;
        }

        const markerElement = (marker as unknown as { _icon: HTMLDivElement })._icon;

        if (!markerElement) {
            return;
        }

        const textElement = markerElement.getElementsByClassName(textClassName)[0] as HTMLDivElement;

        if (!textElement) {
            return;
        }

        textElement.style.top = `${-textElement.offsetHeight}px`;
        textElement.style.left = `${-textElement.offsetWidth / 2}px`;
    }

    if (formattedPoints.length < 2) {
        return null;
    }

    const angleStart = getAngle(formattedPoints[1], formattedPoints[0]) - 45;
    const angleText = getAngle(formattedPoints[1], formattedPoints[0]) + 180;
    const angleEnd = getAngle(formattedPoints[formattedPoints.length - 2], formattedPoints[formattedPoints.length - 1]) - 45;
    const arrowDivStart = getArrowDiv({color, arrowSize, angle: angleStart});
    const arrowDivEnd = getArrowDiv({color, arrowSize, angle: angleEnd});
    const arrowIconStart = L.divIcon({className: arrowClassName, html: arrowDivStart});
    const arrowIconEnd = L.divIcon({className: arrowClassName, html: arrowDivEnd});
    let lineOptions: L.PolylineOptions  = {color: color};
    if (lineType === 'dash')
        lineOptions = {color: color, dashArray: '10 10'}


    if (disabled) return null;

    return (
        <>
            {showStart &&
                <Marker key={'marker-start'} position={[startPoint?.lat as number, startPoint?.lng as number]} icon={arrowIconStart} ref={markerRefStart}  interactive={true}
                eventHandlers={{
                    click: handleEventClick
                  }}/>}
            {showEnd &&
                <Marker key={'marker-end'} position={formattedPoints[1]} icon={arrowIconEnd} ref={markerRefEnd}
                        interactive={false}/>}
            {startPoint && endPoint && <Polyline ref={markerRefLine} pathOptions={lineOptions} positions={[[startPoint?.lat, startPoint?.lng], [endPoint?.lat, endPoint?.lng]]} eventHandlers={{
                    click: handleEventClick
                  }}/>}
            {showText && midPoint &&<RotatableText
                text={text}
                position={midPoint}
                onAfterRender={updateHtml}
                interactive={false}
                fontSize={FONT_SIZE}
                rotation={angleText}
                textClassName={textClassName}
            />}
        </>
    );
}

export default ArrowLine;
