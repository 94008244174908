import React from "react";
import { useRef } from "react";
import { Api } from "../../Generated/ExoDBAPI";
import { useCreateCustomerMutation, useGetCustomersAmountWithNameLazyQuery } from "../../Generated/Graphql";
import { getRequestParams, getRequestParamsOnlyHeaders } from "../../Utils/azureAuth";
import axios from "axios";

/**
 * A form for adding a new client
 * @returns
 */
function AddClientForm() {
  const clientNameInput = useRef<HTMLInputElement>(null);
  const clientAbbrvInput = useRef<HTMLInputElement>(null);
  const submitRef = useRef<HTMLInputElement>(null);
  const [createCustomer, _] = useCreateCustomerMutation();
  const [customerAmountQuery] = useGetCustomersAmountWithNameLazyQuery();

  /**
   * Send a request to the backend to create new client
   * @param {*} e inSubmit event
   */
  async function addClient(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const submitButton = submitRef.current;
    if (submitButton === null || clientNameInput.current === null || clientAbbrvInput.current === null) {
      return;
    }
    submitButton.className = "buttonClicked";
    submitButton.disabled = true;

    const api = new Api();

    const newClientName = clientNameInput.current.value;
    const newClientAbbrv = clientAbbrvInput.current.value;
    try {
      const res = await customerAmountQuery({ variables: { name: newClientName } });
      const customerAmount = res.data?.searchCustomers?.total;
      if (customerAmount !== 0) {
        const errorMsg = `There are ${customerAmount} customers with this name in the DB`;
        window.alert(errorMsg);
        throw new Error(errorMsg);
      }
      api.clients
        .addClient(
          {
            name: newClientName,
            abbreviation: newClientAbbrv,
          },
          await getRequestParams()
        )
        .then(async () => {
          try {
            const prodBUrl = process.env.REACT_APP_PROD_B_GRAPHQL_HOST;
            if (prodBUrl !== undefined) {
              await axios.post(
                prodBUrl,
                {
                  operationName: "CreateCustomer",
                  query:
                    "mutation CreateCustomer($name: String!) {\n  createCustomer(input: {name: $name}) {\n    id\n    __typename\n  }\n}",
                  variables: {
                    name: newClientName,
                  },
                },
                await getRequestParamsOnlyHeaders()
              );
            }
          } catch (e) {
            console.log("failed to create customer in prodb");
            console.log(e);
            window.alert("Failed to create customer in new DB. Please notify software team.");
          }
          const resp = await createCustomer({ variables: { name: newClientName } });
          if (resp.errors) {
            console.log(resp.errors);
            window.alert("Failed to create customer in graphql");
            throw resp.errors[0];
          }
          if (clientNameInput.current !== null) {
            clientNameInput.current.value = "";
          }
          if (clientAbbrvInput.current !== null) {
            clientAbbrvInput.current.value = "";
          }
        })
        .catch((resp) => {
          const errorMsg = resp.response.data;
          window.alert(errorMsg);
        });
    } finally {
      submitButton.className = "formButton";
      submitButton.disabled = false;
    }
  }

  return (
    <form autoComplete="off" onSubmit={(e) => addClient(e)} id="AddClientForm">
      <h3 className="pageHeader">Create client</h3>

      <span>
        <span className="siteFormEntry">
          <label htmlFor="name">Client name:</label>
          <input
            type="text"
            name="name"
            id="clientName"
            required={true}
            pattern="(?=.{2,50}$)(^([a-zA-Z0-9]+)([ -_])?)(([a-zA-Z0-9]+[  -_])*)([a-zA-Z0-9]+)"
            ref={clientNameInput}
          />
        </span>
        <span className="siteFormEntry">
          <label htmlFor="abbrv">Client Abbreviation:</label>
          <input type="text" name="abbrv" id="clienAbbrv" required={true} pattern="\w+" ref={clientAbbrvInput} />
        </span>
      </span>
      <div className="formSectionIsolated">
        <input type="submit" className="formButton" value="Add Client" ref={submitRef} />
      </div>
    </form>
  );
}

export default AddClientForm;
