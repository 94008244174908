export function getDepths(geoJson: any): number[] | undefined {
  return geoJson.properties?.depths ?? geoJson.properties?.depth_values;
}

export function getOriginalEpsgNum(geoJson: any): number {
  const epsg: string = geoJson.properties?.original_crs;
  if (epsg) {
    const epsgParts = epsg.split("::");
    try {
      return parseInt(epsgParts[epsgParts.length - 1]);
    } catch {
      console.log(`Failed to parse ${epsgParts[epsgParts.length - 1]} as an int`);
    }
  }

  return 0;
}

export function getPointCoordinates(geoJson: any): [number, number] {
  if (geoJson.geometry.type === "Point") {
    // For backward (and possibly future) compatibility
    return geoJson.geometry.coordinates;
  }

  // For easier dimension handling, all coordinates are saved as [N, 2].
  // For transmitter, this means we use MultiPoint instead of points
  return geoJson.geometry.coordinates[0];
}
