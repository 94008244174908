import * as React from "react";
import BugReportIcon from "@mui/icons-material/BugReport";
import { IconButton } from "@mui/material";
import ReportBugModal, {
  Urgency,
  MIN_DESCRIPTION_CHARACTERS,
} from "./ReportBugModal";
import { Api, EmailTarget } from "../../../Generated/ExoDBAPI";
import { getRequestParams } from "../../../Utils/azureAuth";

const SCREENSHOT_ID = "image1";

function ReportBugButton({
  plannedSite,
  analysisSite,
  source,
  user,
}: ReportBugButtonProps) {
  const [screenShotDataUrl, setScreenShotDataUrl] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [urgency, setUrgency] = React.useState<Urgency | "" | undefined>("");
  const [openModal, setOpenModal] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  function showModal() {
    if (!openModal) {
      return <></>;
    }
    return (
      <ReportBugModal
        imageDataUrl={screenShotDataUrl}
        plannedSite={plannedSite}
        analysisSite={analysisSite}
        source={source}
        description={description}
        setDescription={setDescription}
        urgency={urgency}
        setUrgency={setUrgency}
        reportBug={reportBug}
        cancel={clearState}
        showErrors={showErrors}
      />
    );
  }

  function clearState() {
    setScreenShotDataUrl("");
    setDescription("");
    setUrgency(undefined);
    setOpenModal(false);
    setShowErrors(false);
  }

  function buildEmailHTML() {
    const mailHTML = `
    <html>
      <head></head>
      <body>
        <h1>Exofuser bug report</h1>
        <h2>General Info</h2>
        Urgency: ${urgency}
        <br/>
        Reporter: ${user}
        <br/>
        Planned site: ${plannedSite}
        <br/>
        Analysis site: ${analysisSite}
        <br/>
        Data source: ${source}
        <h2>Description</h2>
        <p>${description}</p>
        <h2>Screenshot</h2>
        <img src="cid:${SCREENSHOT_ID}"/>
      </body>
    </html>`;
    return mailHTML;
  }

  function validateInput() {
    if (description.length <= MIN_DESCRIPTION_CHARACTERS) {
      return false;
    }
    if (urgency === undefined || urgency === "") {
      return false;
    }
    return true;
  }

  async function reportBug() {
    if (!validateInput()) {
      setShowErrors(true);
      return;
    }
    const api = new Api();
    const emailContent = buildEmailHTML();
    await api.reportEmails.sendMail(
      {
        subject: "Exofuser Bug Report",
        content: emailContent,
        target: EmailTarget.Exofuser,
        attachments: [
          {
            id: SCREENSHOT_ID,
            type: "image",
            subtype: "png",
            data: screenShotDataUrl.slice(22),
          },
        ],
      },
      await getRequestParams()
    );
    clearState();
  }

  function takeScreenshot() {
    const canvas = document.createElement("canvas");
    navigator.mediaDevices
      .getDisplayMedia({ preferCurrentTab: true } as any)
      .then((stream) => {
        // Grab frame from stream
        const track = stream.getVideoTracks()[0];
        const capture = new ImageCapture(track);
        capture.grabFrame().then((bitmap) => {
          // Stop sharing
          track.stop();

          // Draw the bitmap to canvas
          canvas.width = bitmap.width;
          canvas.height = bitmap.height;
          const context = canvas.getContext("2d");
          if (context === null) {
            console.log("Canvas context was null. Aborting screenshot.");
            return;
          }
          context.drawImage(bitmap, 0, 0);

          // Grab blob from canvas
          setScreenShotDataUrl(canvas.toDataURL("image/png"));
          setOpenModal(true);
        });
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <IconButton
        onClick={async () => {
          takeScreenshot();
        }}
        color="error"
      >
        <BugReportIcon />
      </IconButton>
      {showModal()}
    </>
  );
}

export interface ReportBugButtonProps {
  plannedSite?: string;
  analysisSite?: string;
  source?: string;
  user?: string;
}

export default ReportBugButton;
