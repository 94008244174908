import { ApolloQueryResult } from "@apollo/client";
import React from "react";
import { useContext } from "react";
import { ClientDetails, SiteType, StateDetails } from "../../Generated/ExoDBAPI";
import {
  GetCustomersNamesQuery,
  GetLocationsQuery,
  useGetCustomersNamesLazyQuery,
  useGetLocationsLazyQuery,
} from "../../Generated/Graphql";
import { getAllPagesResults } from "../../Utils/graphqlUtils";
import UserContext from "../Contexts/UserContext";
import CreateSiteForm, { Customer, Location } from "../CreateSiteComponents/CreateSite";
import RequestLogIn from "../RequestLogIn";

/**
 * The page for creating a new site. The logic is in the form
 * * @param {*} loadedStates States loaded previously while on the site to save having to wait for them to load
 * * @param {*} siteTypes site types loaded previously while on the site to save having to wait for them to load
 * @returns
 */
function CreateSitePage({ loadedStates, siteTypes, loadedClients }: CreateSitePageProps) {
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [locations, setLocations] = React.useState<Location[]>([]);

  const { user } = useContext(UserContext);
  const [_customerQuery, customersResults] = useGetCustomersNamesLazyQuery();
  const [_locationQuery, locationResults] = useGetLocationsLazyQuery();

  React.useEffect(() => {
    // Fetches customer and locations data from GraphQL
    async function fetchData() {
      const fetchedCustomers = await getAllPagesResults(
        customersResults,
        (res) => parseGetCustomersResults(res),
        (res) => res.data.searchCustomers?.nextToken
      );
      setCustomers(fetchedCustomers);

      const fetchedLocations = await getAllPagesResults(
        locationResults,
        (res) => parseGetLocationsResults(res),
        (res) => res.data.searchLocations?.nextToken
      );
      setLocations(fetchedLocations);
    }

    fetchData();
  }, []);

  function parseGetCustomersResults(res: ApolloQueryResult<GetCustomersNamesQuery>) {
    const items = res.data.searchCustomers?.items;
    if (!items) {
      return [];
    }
    return items
      .map((item) =>
        item
          ? {
              name: item.name,
              id: item.id,
            }
          : null
      )
      .filter((item) => item !== null) as Customer[];
  }

  function parseGetLocationsResults(res: ApolloQueryResult<GetLocationsQuery>) {
    const items = res.data.searchLocations?.items;
    if (!items) {
      return [];
    }
    return items
      .map((item) =>
        item
          ? {
              state: item.state,
              country: item.country,
              region: item.region,
              id: item.id,
            }
          : null
      )
      .filter((item) => item !== null) as Location[];
  }

  return (
    <>
      {user === undefined ? (
        <RequestLogIn />
      ) : (
        <div className="pageWrapper">
          <div className="infoContainer">
            <h2 className="pageHeader">Create a new site</h2>
            <CreateSiteForm
              loadedStates={loadedStates}
              siteTypes={siteTypes}
              loadedClients={loadedClients}
              customers={customers}
              locations={locations}
            />
          </div>
        </div>
      )}
    </>
  );
}

interface CreateSitePageProps {
  loadedStates: StateDetails[];
  siteTypes: SiteType[];
  loadedClients: ClientDetails[];
}

export default CreateSitePage;
