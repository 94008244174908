import { createSvgIcon } from '@mui/material';

export const FIlterIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 28 28">
    <path
      fill="currentColor"
      d="M17 19a1 1 0 1 1 0 2h-6a1 1 0 1 1 0-2zm4-6a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2zm3-6a1 1 0 1 1 0 2H4a1 1 0 0 1 0-2z"
    />
  </svg>,
  'filterIcon'
);
