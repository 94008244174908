export function getTime(date?: Date) {
  return date !== null && date !== undefined ? date.getTime() : 0;
}

export function getDatetimeStr(value: number | string | undefined, defaultValue: string = "Unknown"): string {
  if (value === undefined || (typeof value == "number" && isNaN(value as number))) {
    return defaultValue;
  }

  const date = typeof value == "number" ? new Date(value * 1000) : new Date(value);

  // By default the date will be given in UTC time, we use the local time of the target instead.
  // Still no support for work days that crosses midnight, sadly.
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000);

  return localDate.toISOString();
}

export function getDateStr(value: number | string | undefined, defaultValue: string = "Unknown"): string {
  const datetimeValue = getDatetimeStr(value, defaultValue);
  if (value === undefined) {
    return datetimeValue;
  }
  return datetimeValue.split("T")[0];
}
