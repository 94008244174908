import { Button, Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import * as React from "react";
import { SiteDetails } from "../../../../Generated/ExoDBAPI";
import FileInput, { FileData, FileInputRef } from "../../../Inputs/FileInput";

function UploadFile({
  plannedSite,
  accept,
  percentages,
  uploadCallback,
  defaultName = "",
  disableName = false,
  allowMultiSite = false,
}: UploadFileProps) {
  const [layerName, setLayerName] = React.useState(defaultName);
  const [uploadDisabled, setUploadDisabled] = React.useState(false);
  const [uploadMultiSite, setUploadMultiSite] = React.useState(false);
  const fileInputRef = React.useRef<FileInputRef>(null);

  async function submit() {
    if (plannedSite == undefined) {
      window.alert("Pick a site");
      return;
    }
    if (layerName.length <= 3) {
      window.alert("Enter a layer name with more than 4 characters");
      return;
    }
    if (!/\w+/.test(layerName)) {
      window.alert("Layer name can only contain english letters, digits and underscores");
      return;
    }
    const fileInput = fileInputRef.current;
    if (fileInput === null) {
      window.alert("Internal error. This should be reported");
      console.log("Against all odds the file input ref was null");
      return;
    }

    const filesData = fileInput.getFilesData();
    if (filesData.length !== 1) {
      window.alert("You need to pick a single file to upload");
      return;
    }
    try {
      setUploadDisabled(true);
      await uploadCallback(plannedSite, layerName, filesData[0], fileInput, uploadMultiSite);
      fileInput.changeFileStatus(filesData[0].id, true);
      fileInput.clearFiles();
      setLayerName("");
    } catch (e: any) {
      if (e instanceof TypeError) {
        window.alert("Upload probably didn't fail. Check the layers in a few minutes.");
      }
      fileInput.changeFileStatus(filesData[0].id, false);
    } finally {
      setUploadDisabled(false);
    }
  }

  return (
    <Stack>
      <TextField
        size="small"
        placeholder="Layer Name"
        value={layerName}
        onChange={(e) => setLayerName(e.target.value)}
        disabled={disableName}
      />
      <FileInput
        multipleFiles={false}
        loadedFileXButtonAtStart={true}
        accept={accept}
        ref={fileInputRef}
        percentages={percentages}
      />
      {allowMultiSite && (
        <FormControlLabel
          control={<Checkbox value={uploadMultiSite} onChange={(_, c) => setUploadMultiSite(c)} />}
          label="Upload To Sites Based On Geolocation"
        />
      )}
      <Button variant="outlined" sx={{ marginTop: 1 }} onClick={submit} disabled={uploadDisabled}>
        Load file
      </Button>
    </Stack>
  );
}

export interface UploadFileProps {
  plannedSite?: SiteDetails;
  accept: string;
  percentages: boolean;
  uploadCallback: (
    site: SiteDetails,
    layerName: string,
    fileData: FileData,
    fileInput: FileInputRef,
    multiSite?: boolean
  ) => Promise<void>;
  defaultName?: string;
  disableName?: boolean;
  allowMultiSite?: boolean;
}

export default UploadFile;
