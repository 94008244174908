import React from "react";
import FileInput, { FileInputRef } from "../Inputs/FileInput";
import { MAXIMUM_CHUNK_SIZE, MAX_UPLOAD_CONCURRENCY } from "../../config/fileUploadConfig";
import { createUploadTasksForInput, preformTasksWithMaxConcurrency } from "../../Utils/fileUploadUtils";
import fileUploaderFactory from "../../MultipartUpload/fileUploaderFactory";
import { Api, FileType } from "../../Generated/ExoDBAPI";
import { getRequestParamsOnlyHeaders } from "../../Utils/azureAuth";

/**
 * A form to upload files to s3 associated with a site
 * @param {int} siteId the id of the site to upload data to
 * @returns
 */
function AddSiteDataForm({ siteId }: { siteId: string }) {
  const geoLocatedPicture = React.useRef<FileInputRef>(null);
  const geolocatedFiles = React.useRef<FileInputRef>(null);
  const pictures = React.useRef<FileInputRef>(null);
  const soilTypeFiles = React.useRef<FileInputRef>(null);

  const submitRef = React.useRef<HTMLInputElement>(null);

  /**
   * Gets pre signed urls from the backend and uploads the files to s3 with it
   * @param {*} e The form submit event
   */
  async function addSiteData(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const submitButton = submitRef.current;
    if (submitButton === null) {
      return;
    }

    submitButton.className = "buttonClicked";
    submitButton.disabled = true;

    const uploaderFactory = new fileUploaderFactory(
      siteId,
      new Api({ baseUrl: process.env.REACT_APP_BACKEND_HOST }),
      MAXIMUM_CHUNK_SIZE,
      await getRequestParamsOnlyHeaders()
    );

    let uploadTasks: (() => Promise<void>)[] = [];

    uploadTasks = uploadTasks.concat(
      createUploadTasksForInput(geoLocatedPicture, FileType.GeolocatedPicture, uploaderFactory),
      createUploadTasksForInput(geolocatedFiles, FileType.GeolocatedFile, uploaderFactory),
      createUploadTasksForInput(pictures, FileType.Picture, uploaderFactory),
      createUploadTasksForInput(soilTypeFiles, FileType.SoilType, uploaderFactory)
    );

    try {
      await preformTasksWithMaxConcurrency(uploadTasks, MAX_UPLOAD_CONCURRENCY);
    } catch (e) {
      window.alert("Some files upload failed");
      console.log(e);
    } finally {
      submitButton.className = "formButton";
      submitButton.disabled = false;
    }
  }

  return (
    <form className="siteForm" onSubmit={(e) => addSiteData(e)}>
      <div className="formSection">
        <span className="siteFormEntry">
          <label>Site geo-located picture:</label>
          <FileInput multipleFiles={false} ref={geoLocatedPicture} key="geoLocatedPicutreInput" />
        </span>
        <span className="siteFormEntry">
          <label>Site geo-located files:</label>
          <FileInput multipleFiles={true} ref={geolocatedFiles} key="geoLocatedFilesInput" />
        </span>
        <span className="siteFormEntry">
          <label>Picutres:</label>
          <FileInput multipleFiles={true} ref={pictures} key="sitePicturesInput" />
        </span>
        <span className="siteFormEntry">
          <label>Soil type:</label>
          <FileInput multipleFiles={false} ref={soilTypeFiles} key="soilTypeInput" />
        </span>
        <div className="formSectionIsolated">
          <input type="submit" className="formButton" value="Add Data To Site" ref={submitRef} />
        </div>
      </div>
    </form>
  );
}

export default AddSiteDataForm;
