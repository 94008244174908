import {
  AnalysisProductsPipe,
  AnalysisProductsPolygon,
  AnalysisProductsTransmitter,
  DepthPoint
} from '../DataTypes/MapEntities';

import { getDepth } from '../DepthSupport/depthUtils';
import {
  FILTER_BOOL_CATEGORY_OPTIONS,
  BoolCategoryValues,
  createCompareOptions,
  SinglePropFilterCategoryFactory,
  MultiPropsFilterCategoryFactory,
  FilterCategoryFactoryProps
} from './BaseFilterCategory';
import { UNDEFINED_STR_VALUE } from '../../../Utils/filterUtils';
import {
  DEFAULT_MIN_SAME_DATE_VALUE,
  DEFAULT_MIN_TRANSMITTERS_FREQUENCY_DELTA,
  entityToStringKey
} from '../SelectedPipe/FindMatchingFeatures';
import { getMinValue } from '../../../Utils/mathUtils';
import { Confidence } from '../../../Generated/ExoDBAPI';
import { Dict } from '../../../BasicTypes';

type FoundAnalysisEntity = AnalysisProductsPipe | AnalysisProductsPolygon;
const CONFIDENCES = ['NONE', 'LOWEST', 'LOW', 'MEDIUM', 'HIGH', 'HIGHEST', 'NOT AN OBJECT'];

const compareConfidences = createCompareOptions(CONFIDENCES);

export class MainConfidenceFilterCategoryFactory<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return 'Confidence';
  }

  get compareOptions(): (a: string, b: string) => number {
    return compareConfidences;
  }

  getProp(entity: T): string {
    return entity.confidence.toString().toUpperCase();
  }
}

export class AutoConfidenceFilterCategoryFactory<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return 'Auto Confidence';
  }

  get compareOptions(): (a: string, b: string) => number {
    return compareConfidences;
  }

  getProp(entity: T): string {
    return entity.autoConfidence.toString().toUpperCase();
  }
}

export class HasDepthFilterCategoryFactory<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return 'Has Depth';
  }

  get compareOptions(): (a: string, b: string) => number {
    return createCompareOptions(FILTER_BOOL_CATEGORY_OPTIONS);
  }

  getProp(entity: T): string {
    for (const key of ['depth', 'depths']) {
      if (key in entity && (entity as any)[key] !== undefined) {
        return BoolCategoryValues.YES;
      }
    }

    return BoolCategoryValues.NO;
  }
}

export class HasCommentFilterCategoryFactory<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return 'Has Comment';
  }

  get compareOptions(): (a: string, b: string) => number {
    return createCompareOptions(FILTER_BOOL_CATEGORY_OPTIONS);
  }

  getProp(entity: T): string {
    if (entity.header.comment !== undefined && entity.header.comment !== '') {
      return BoolCategoryValues.YES;
    }

    return BoolCategoryValues.NO;
  }
}

export class CrossingDepthCategoryFactory<
  T extends AnalysisProductsPipe
> extends MultiPropsFilterCategoryFactory<T> {
  get filterName(): string {
    return 'Crossing Depth';
  }

  get compareOptions(): (a: string, b: string) => number {
    return (a, b) => parseFloat(a) - parseFloat(b);
  }

  protected getProp(entity: T): string[] {
    return entity.depthPoints === undefined
      ? []
      : entity.depthPoints.map((point) => getDepth(point.depth));
  }

  createDisplayEntity(entity: T): T {
    // This will fail if more then one factory was created.
    const allEntitiesProps = this.getProp(entity);
    let matchedOptions: string[] = [];
    try {
      matchedOptions = allEntitiesProps.filter((prop) => this.getCheckedOptions().includes(prop));
    } catch (e) {
      console.log(e);
      return entity;
    }

    if (allEntitiesProps.length === matchedOptions.length) {
      // This also solve the empty case
      return entity;
    }

    const copiedEntity = { ...entity };
    copiedEntity.depthPoints = (entity.depthPoints as DepthPoint[]).filter((point) => {
      const depthString = getDepth(point.depth);
      return matchedOptions.includes(depthString);
    });
    return copiedEntity;
  }
}

export class PipeAtEdge<T extends FoundAnalysisEntity> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return 'Pipe At Edge';
  }

  getProp(entity: T): string {
    const PipeAtEdge = entity.algoUtilityMetrics?.pipe_at_scan_edge;
    if (PipeAtEdge === undefined || PipeAtEdge === null) {
      return UNDEFINED_STR_VALUE;
    }
    return PipeAtEdge ? BoolCategoryValues.YES : BoolCategoryValues.NO;
  }
}

export class PipesQAFlagged<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return 'PipesQA Flagged';
  }

  getProp(entity: T): string {
    const pipeFlagged = entity.pipesQAInfo?.flagged;
    if (pipeFlagged === undefined || pipeFlagged === null) {
      return UNDEFINED_STR_VALUE;
    }
    return pipeFlagged ? BoolCategoryValues.YES : BoolCategoryValues.NO;
  }
}

export class PipesQAStatus<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return 'PipesQA Status';
  }

  getProp(entity: T): string {
    const pipeStatus = entity.pipesQAInfo?.status;
    if (pipeStatus === undefined || pipeStatus === null) {
      return UNDEFINED_STR_VALUE;
    }
    return pipeStatus;
  }
}
export class PipesQAComment<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return 'PipesQA Comment';
  }
  get compareOptions(): (a: string, b: string) => number {
    return createCompareOptions(FILTER_BOOL_CATEGORY_OPTIONS);
  }

  getProp(entity: T): string {
    if (
      entity.pipesQAInfo?.comment?.text !== undefined &&
      entity.pipesQAInfo?.comment?.text !== ''
    ) {
      return BoolCategoryValues.YES;
    }

    return BoolCategoryValues.NO;
  }
}

export class PipeAboveGround<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  get filterName(): string {
    return 'Pipe Above Ground';
  }

  getProp(entity: T): string {
    const pipeAboveGround = entity.algoUtilityMetrics?.pipe_above_ground;
    if (pipeAboveGround === undefined || pipeAboveGround === null) {
      return UNDEFINED_STR_VALUE;
    }
    return pipeAboveGround ? BoolCategoryValues.YES : BoolCategoryValues.NO;
  }
}

export class PipeAngleOfCrossing<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  static readonly THRESHOLD_ANGLE: number = 30;
  get filterName(): string {
    return 'Pipe Angle of Crossing';
  }

  getProp(entity: T): string {
    if (entity.processedAlgoUtilityMetrics?.angleOfCrossingAverage === undefined) {
      return UNDEFINED_STR_VALUE;
    }

    return entity.processedAlgoUtilityMetrics.angleOfCrossingAverage >
      PipeAngleOfCrossing.THRESHOLD_ANGLE
      ? 'Large'
      : 'Small';
  }
}

export class PipeLength<T extends FoundAnalysisEntity> extends SinglePropFilterCategoryFactory<T> {
  static readonly THRESHOLD_LENGTH: number = 5;
  get filterName(): string {
    return 'Pipe Length';
  }

  getProp(entity: T): string {
    if (entity.processedAlgoUtilityMetrics?.utilityLength === undefined) {
      return UNDEFINED_STR_VALUE;
    }

    return entity.processedAlgoUtilityMetrics.utilityLength > PipeLength.THRESHOLD_LENGTH
      ? 'Long'
      : 'Short';
  }
}

export type PipeTransmitterMethodProps = FilterCategoryFactoryProps & {
  transmitterMapping: Dict<AnalysisProductsTransmitter[]>;
  maxDelta?: number;
  maxTimestampDelta?: number;
};

export class PipeTransmitterMethod<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  transmitterMapping: Dict<AnalysisProductsTransmitter[]>;
  maxDelta: number;
  maxTimestampDelta: number;
  constructor({
    categoryRank,
    defaultCheckStrategy,
    transmitterMapping,
    maxDelta = DEFAULT_MIN_TRANSMITTERS_FREQUENCY_DELTA,
    maxTimestampDelta = DEFAULT_MIN_SAME_DATE_VALUE
  }: PipeTransmitterMethodProps) {
    super({ categoryRank, defaultCheckStrategy });
    this.transmitterMapping = transmitterMapping;
    this.maxDelta = maxDelta;
    this.maxTimestampDelta = maxTimestampDelta;
  }
  get filterName(): string {
    return 'Pipe Transmitter Method';
  }

  getProp = (entity: T) => {
    const transmitters = this.transmitterMapping[entityToStringKey(entity)];
    if (!transmitters || entity.frequency === undefined) {
      return UNDEFINED_STR_VALUE;
    }
    const pipeFreq: number = entity.frequency;
    const comapareToPipeFreq = (freq: number) => Math.abs(freq - pipeFreq);
    const minDiffTransmitter = getMinValue(
      transmitters.filter(
        (t) =>
          t.header.scan.timestamp !== undefined &&
          entity.header.scan.timestamp !== undefined &&
          Math.abs(t.header.scan.timestamp - entity.header.scan.timestamp) <= this.maxTimestampDelta
      ),
      (t) => comapareToPipeFreq(t.frequency)
    );
    if (
      minDiffTransmitter === undefined ||
      comapareToPipeFreq(minDiffTransmitter.frequency) > this.maxDelta
    ) {
      return UNDEFINED_STR_VALUE;
    }

    return minDiffTransmitter.method ?? UNDEFINED_STR_VALUE;
  };
}

export abstract class AlgoMetricsConfidentFilter<
  T extends FoundAnalysisEntity
> extends SinglePropFilterCategoryFactory<T> {
  static readonly CONFIDENCE_DICT: Dict<Confidence> = {
    0: Confidence.Lowest,
    1: Confidence.Low,
    2: Confidence.Medium,
    3: Confidence.High,
    4: Confidence.Highest,
    5: Confidence.Highest
  };

  abstract getEntitiyScore(entity: T): number | undefined;

  getProp = (entity: T) => {
    const score = this.getEntitiyScore(entity);
    if (score === undefined) {
      return Confidence.None.toUpperCase();
    }
    const scoreIndex = Math.floor((10 * score) / 2);
    return AlgoMetricsConfidentFilter.CONFIDENCE_DICT[scoreIndex].toUpperCase();
  };

  get compareOptions(): (a: string, b: string) => number {
    return compareConfidences;
  }
}

export class PipeMisfitScore<T extends FoundAnalysisEntity> extends AlgoMetricsConfidentFilter<T> {
  get filterName(): string {
    return 'Pipe Misfit Score';
  }

  getEntitiyScore = (entity: T) => {
    return entity.algoUtilityMetrics?.simulation_fit_pipe_metric ?? undefined;
  };
}

export class PipeSegmentationScore<
  T extends FoundAnalysisEntity
> extends AlgoMetricsConfidentFilter<T> {
  get filterName(): string {
    return 'Pipe Segmentation Score';
  }

  getEntitiyScore = (entity: T) => {
    return entity.algoUtilityMetrics?.segmentation_mean_pipe_score ?? undefined;
  };
}
