import React from 'react';
import { Link } from 'react-router-dom';
import UserContext from './Contexts/UserContext';
import { logIn, logOut } from '../Utils/azureAuth';
import LogRocket from 'logrocket';
import { APP_NAME, APP_VERSION } from '../config/appConfig';
import { ROLE_HOTFIX } from '../config/authConfig';

/**
 * Navbar for navigating between the sites pages
 * @returns
 */
function Navbar() {
  const { user, setUser } = React.useContext(UserContext);

  React.useEffect(() => {
    // Perform log in if you can
    async function doLogIn() {
      const resp = await logIn();
      if (resp?.account?.homeAccountId !== undefined && resp?.account?.name !== undefined) {
        if (process.env.REACT_APP_DEV_MODE !== 'on') {
          LogRocket.identify(resp.account.homeAccountId, {
            name: resp.account.name,
            email: (resp.account as any).username
          });
        }
      }
      setUser(resp);
    }
    doLogIn();
  }, []);

  function navbarLogOut() {
    if (user !== undefined) {
      logOut(user);
    }
    setUser(undefined);
  }

  function handleClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    logIn();
  }

  /**
   * Creates the DOM for the log in and out navbar items
   * @returns
   */
  function createLogInLink() {
    if (user === undefined) {
      return (
        <span className="navbarLink navbarItem navbarLinkText" onClick={handleClick} key="logIn">
          Log In
        </span>
      );
    } else {
      return (
        <span className="navbarLink navbarItem navbarLinkText" onClick={navbarLogOut} key="logOut">
          Log Out
        </span>
      );
    }
  }

  return (
    <header className="navbar">
      <Link to="/" className="logoLink" key="home">
        <span className="logoContainer">
          <img className="logo" src="/exoplanner.png" alt="navbar logo" />
          <span className="logoText">{APP_NAME}</span>
        </span>
      </Link>
      <Link to="sites" className="navbarLink" key="sites">
        <span className="navbarItem navbarLinkText">Sites</span>
      </Link>
      <Link to="createSite" className="navbarLink" key="createSite">
        <span className="navbarItem navbarLinkText">Create Site</span>
      </Link>
      <Link to="exofuser" className="navbarLink" key="exofuser">
        <span className="navbarItem navbarLinkText">ExoFuser</span>
      </Link>
      <Link to="pipelines" className="navbarLink" key="pipelines">
        <span className="navbarItem navbarLinkText">Pipelines</span>
      </Link>
      <Link to="dataFlow" className="navbarLink" key="dataflow">
        <span className="navbarItem navbarLinkText">Data Flow</span>
      </Link>
      <Link to="monitoring" className="navbarLink" key="monitoring">
        <span className="navbarItem navbarLinkText">Monitoring</span>
      </Link>
      <Link to="UploadManualSupport" className="navbarLink" key="uploadmanualsupport">
        <span className="navbarItem navbarLinkText">Preloader</span>
      </Link>
      <Link to="RTKMonitor" className="navbarLink" key="RRTMonitor">
        <span className="navbarItem navbarLinkText">RTK Monitor</span>
      </Link>
      <Link to="PSDView" className="navbarLink" key="PSDView">
        <span className="navbarItem navbarLinkText">PSD View</span>
      </Link>
      {user?.account?.idTokenClaims?.roles?.includes(ROLE_HOTFIX) && <Link to="hotfix" className="navbarLink" key="Setting">
        <span className="navbarItem navbarLinkText">Hotfix</span>
      </Link>}

      <span className="endAlignedNavbarLink">
        <span className="navbarItem navbarPlainText">Version {APP_VERSION}</span>
        <Link to="admin" className="navbarLink" key="admin">
          <span className="navbarItem navbarLinkText">Admin</span>
        </Link>
        {createLogInLink()}
      </span>
    </header>
  );
}

export default Navbar;
