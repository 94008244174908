import { Stack } from '@mui/material';
import { Typography } from '../Typography';

type LegendProps = {
  color: string;
  label: string;
};

export const Legend = ({ label, color }: LegendProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography sx={{ bgcolor: color, borderRadius: 1, width: 14, height: 14 }} />
      <Typography>{label}</Typography>
    </Stack>
  );
};
