import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

export interface LocationAddress {
    id: string;
    street_number: string;
    name: string;
    city: string;
}

interface PropLocationAddress {
    item: LocationAddress;
}

const borderColor = '#fff'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 18,
        fontStyle: 'bold',
        fontSize: 10,
        marginLeft: 24,
    },
    title: {
        width: '15%',
        textAlign: 'left',
        borderRightColor: '#fff',
        borderRightWidth: 0,
        paddingLeft: 8,
    },
    street_number: {
        width: '16%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,

        borderColor: '#000',
        borderWidth: 1,
        fontSize: 9,
        paddingTop: 2,
        backgroundColor: '#acafb5',
    },
    name: {
        width: '39%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingRight: 8,

        borderColor: '#000',
        borderWidth: 1,
        fontSize: 9,
        paddingLeft: 4,
        paddingTop: 2,
        backgroundColor: '#acafb5',
    },
    city: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingRight: 8,

        borderColor: '#000',
        borderWidth: 1,
        fontSize: 9,
        paddingLeft: 4,
        paddingTop: 2,
        backgroundColor: '#acafb5',
    }
  });


const TableRow = ({item}: PropLocationAddress) => {
    return (
        <View style={styles.row} key={item.id.toString()}>
            <Text style={styles.title}>{'Address:'}</Text>
            <Text style={styles.street_number}>{item.street_number}</Text>
            <Text style={styles.name}>{item.name}</Text>
            <Text style={styles.city}>{item.city}</Text>
        </View>)
};

  export default TableRow
