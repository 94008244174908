import { stringify } from "querystring";
import * as React from "react";
import ImageModal from "./ImageModal";

import "./Pipeline.css";

const LOOK_BEHIND_STEPS = 3;

function Pipeline({ name, steps, activeStep, sensor, error, imageUrl, idPrefix = "" }: PipelineProps) {
  const [showPipeImage, setShowPipeImage] = React.useState(false);
  const [prevActiveStep, setPrevActiveStep] = React.useState(LOOK_BEHIND_STEPS);

  function scrollPipeline(prevLastShownStepId: string, lastShownStepId: string) {
    const element = document.getElementById(lastShownStepId);
    const element2 = document.getElementById(prevLastShownStepId);
    const container = document.getElementById(`${idPrefix}-${name}-container`);

    if (element !== null && container !== null && element2 !== null) {
      container?.scrollBy({ left: element.offsetLeft - element2.offsetLeft, behavior: "smooth" });
    }
  }

  React.useEffect(() => {
    const lastShownStepId = `${idPrefix}-${name}-${activeStep - LOOK_BEHIND_STEPS}`;
    const prevLastShownStepId = `${idPrefix}-${name}-${prevActiveStep - LOOK_BEHIND_STEPS}`;

    scrollPipeline(prevLastShownStepId, lastShownStepId);
    setPrevActiveStep(activeStep);
  }, [activeStep]);

  function createStep(stepName: string, index: number, key?: string) {
    let className = "";
    if (index < activeStep) {
      className = "done";
    } else if (index == activeStep) {
      if (error) {
        className = "error";
      } else {
        className = "active";
      }
    }

    return (
      <li className={className} id={`${idPrefix}-${name}-${index}`} key={key ?? `${key ?? index}`}>
        {stepName.replaceAll("_", " ")}
      </li>
    );
  }

  function getPipelineImage() {
    if (imageUrl !== undefined) {
      return (
        <span className="siteButtonWrapper">
          <img src={imageUrl} className="miniPipelineImage" onClick={() => setShowPipeImage(true)} />
        </span>
      );
    }
    return <></>;
  }

  function getModal() {
    if (showPipeImage && imageUrl !== undefined) {
      return <ImageModal url={imageUrl} onExit={() => setShowPipeImage(false)} />;
    }
    return <></>;
  }

  return (
    <>
      <span className="pipelineCard">
        <span className="siteHeader">
          <h3>{name}</h3>
          {getPipelineImage()}
        </span>
        <span className="scanInfo">Sensor: {sensor}</span>
        <span className="steplistWrapper" id={`${idPrefix}-${name}-container`}>
          <ol className="steplist">{steps.map((s, i) => createStep(s, i))}</ol>
        </span>
      </span>
      {getModal()}
    </>
  );
}

export interface PipelineProps {
  name: string;
  sensor: string;
  steps: string[];
  activeStep: number;
  error: boolean;
  imageUrl?: string;
  idPrefix?: string;
}

export default Pipeline;
