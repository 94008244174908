import React from "react";
import { Button, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { getServicesRequestParams } from "../../../Utils/azureAuth";
import { Api as ServicesApi } from "../../../Generated/ExoServicesAPI";
import LoadingWheel from "../../LoadingWheel";
import { DepthValueIssue, SiteWideDepthData } from "./depthData";
import AreYouSureModal from "../../Templates/AreYouSureModal";
import sleep from "../../../Utils/sleep";

const ErrorIssues = new Set<any>([DepthValueIssue.NotQueried, DepthValueIssue.QueryError]);

class ValueNotMatchedError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = "ValueNotMatchedError";
  }
}

class ValueNotChangedError extends ValueNotMatchedError {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = "ValueNotChangedError";
  }
}

function GeoidValueForm({ siteWideDepthData }: GeoidValueFormProps) {
  const [currentAction, setCurrentAction] = React.useState<string | undefined>(undefined);
  const [newGeoidValue, setNewGeoidValue] = React.useState<string>("");
  const [displayConfirmationModal, setDisplayConfirmationModal] = React.useState<boolean>(false);

  function getGeoidString(geoidValue: number | DepthValueIssue): string {
    if (geoidValue === DepthValueIssue.NotQueried) {
      return "Not Queried";
    }

    if (geoidValue === DepthValueIssue.NotSet) {
      return "Value not set for this site";
    }

    if (geoidValue === DepthValueIssue.QueryError) {
      return "Query failed";
    }

    if (geoidValue === DepthValueIssue.SiteNotSet) {
      return "Site was not chosen";
    }

    if (typeof geoidValue !== "number") {
      return `Unknown Depth issue: ${geoidValue}`;
    }

    return (geoidValue as number).toString();
  }

  function getGeoidHelperText(geoidValue: number | DepthValueIssue): string | undefined {
    if (geoidValue === DepthValueIssue.NotQueried) {
      return "If persist for 20 secs, report to Software team";
    }

    if (geoidValue === DepthValueIssue.QueryError) {
      return "Report to software Team";
    }

    if (geoidValue === DepthValueIssue.SiteNotSet) {
      return "Please set site";
    }

    return undefined;
  }

  async function queryGeoidValue(siteId: string): Promise<number | DepthValueIssue> {
    const api = new ServicesApi();
    const response = await api.depth.getGeoidValue(siteId, await getServicesRequestParams());

    if (response.data.geoidValue === null) {
      return DepthValueIssue.NotSet;
    }
    return response.data.geoidValue;
  }

  /* It seems that graphql doesn't update the DB the moment the mutation return a success, but a little bit later.
  to avoid this issue, we wait a little before the first query, and than a longer wait between query attempts. */
  async function performGeoidValueQuery(
    expected?: number | DepthValueIssue,
    max_attempts: number = 2,
    initialSleepMilli: number = 200,
    sleepIntervalMilli: number = 1000
  ) {
    const initialGeoidValue = siteWideDepthData.geoidValue;
    siteWideDepthData.setGeoidValue(DepthValueIssue.NotQueried);
    const graphqlSiteId = siteWideDepthData.graphqlSiteId;
    if (graphqlSiteId === undefined) {
      siteWideDepthData.setGeoidValue(DepthValueIssue.SiteNotSet);
      return;
    }

    try {
      // The action will be set in the loop, but we want to avoid the 0.2 seconds of wrong action.
      setCurrentAction(`Remote geoid value read, attempt: 1`);
      sleep(initialSleepMilli);
      for (let i = 0; i < max_attempts; i += 1) {
        setCurrentAction(`Remote geoid value read, attempt: ${i + 1}`);
        const geoidValue = await queryGeoidValue(graphqlSiteId);
        if (expected !== undefined && expected !== geoidValue) {
          if (geoidValue === initialGeoidValue) {
            sleep(sleepIntervalMilli);
            continue;
          } else {
            const baseMessage = "Geoid value not as expected";
            const status = `expected: ${getGeoidString(expected)}, got: ${getGeoidString(geoidValue)}`;
            const tip = "Does someone else set it as well?";
            alert(`${baseMessage}! ${status}\n${tip}`);
            return;
          }
        }
        siteWideDepthData.setGeoidValue(geoidValue);
        return;
      }
      alert(`Geoid value was not changed after ${max_attempts} queries! Wait 30 seconds and retry updating it`);
    } catch (error) {
      console.log("Query error", error);
      alert("Geoid value query failed! Retry, and if it repeat ask for software support");
    } finally {
      setCurrentAction(undefined);
    }
  }

  React.useEffect(() => {
    performGeoidValueQuery();
  }, [siteWideDepthData.graphqlSiteId, siteWideDepthData.setGeoidValue]);

  function getGeoidValueErrorHelperText(value: string) {
    if (siteWideDepthData.graphqlSiteId === undefined) {
      return "You must choose site";
    }
    if (value === "") {
      return "";
    }

    return "";
  }

  function getGeoidValueHelperText(value: string) {
    const errorHelperText = getGeoidValueErrorHelperText(value);
    if (errorHelperText !== "") {
      return errorHelperText;
    }
    if (value !== "") {
      return "Clear to unset value";
    }

    return "Fill to set value";
  }

  function startGeoidValueUpdate(siteId: string | undefined, value: string) {
    if (siteId === undefined) {
      alert("Site id is somehow not set. Try to refersh the page.");
      return;
    }
    setCurrentAction("Updating geoid value");
    const api = new ServicesApi();
    getServicesRequestParams()
      .then((params) => {
        api.depth
          .setGeoidValue(siteId, { geoidValue: value === "" ? null : parseFloat(value) }, params)
          .then(() => {
            // performGeoidValueQuery will update the current action on success
            performGeoidValueQuery(value === "" ? DepthValueIssue.NotSet : parseFloat(value));
          })
          .catch(() => alert("Setting geoid value failed (remote error)"));
      })
      .catch(() => {
        setCurrentAction(undefined);
        alert("Setting geoid value failed (failed to get authorization info)");
      });
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h5">Geoid Value</Typography>
        <TextField
          disabled={true}
          label="Current Geoid Value"
          value={getGeoidString(siteWideDepthData.geoidValue)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewGeoidValue(e.target.value)}
          error={ErrorIssues.has(siteWideDepthData.geoidValue)}
          helperText={getGeoidHelperText(siteWideDepthData.geoidValue)}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            type="number"
            InputProps={{ inputProps: { step: 0.1 } }}
            label="Set Geoid Value"
            value={newGeoidValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewGeoidValue(e.target.value)}
            error={getGeoidValueErrorHelperText(newGeoidValue) !== ""}
            helperText={getGeoidValueHelperText(newGeoidValue)}
          />
          <Button
            variant="contained"
            key="UpdateGeoidValue"
            disabled={
              getGeoidValueErrorHelperText(newGeoidValue) !== "" ||
              currentAction !== undefined ||
              siteWideDepthData.graphqlSiteId === undefined
            }
            onClick={() => setDisplayConfirmationModal(true)}
          >
            {newGeoidValue === "" ? "Unset" : "Update"}
          </Button>
        </Stack>
        <TextField label="Status" size="small" disabled={true} value={currentAction ?? "Idle"} />
        {currentAction !== undefined ? <LoadingWheel /> : <></>}
      </Stack>
      <AreYouSureModal
        confirmationTitle="Are you sure you want to update geoid value?"
        fields={[
          { label: "Current Value", value: getGeoidString(siteWideDepthData.geoidValue) },
          { label: "New Value", value: newGeoidValue === "" ? "Unset" : newGeoidValue },
          { label: "Site Name", value: siteWideDepthData.siteName ?? "unknown site name" },
          { label: "Site ID", value: siteWideDepthData.graphqlSiteId ?? "unknown site id" },
        ]}
        open={displayConfirmationModal}
        cancelCallback={() => setDisplayConfirmationModal(false)}
        acceptCallback={() => {
          startGeoidValueUpdate(siteWideDepthData.graphqlSiteId, newGeoidValue);
          setDisplayConfirmationModal(false);
        }}
      />
    </>
  );
}

export interface GeoidValueFormProps {
  siteWideDepthData: SiteWideDepthData;
}

export default GeoidValueForm;
