import { Link } from "react-router-dom";
import { SiteDetails } from "../Generated/ExoDBAPI";

export function siteViewerCardsCreator(prefix?: string) {
  function createCard(site: SiteDetails, index: number) {
    const className = `siteItem${index % 2}`;
    return (
      <Link to={`${prefix ?? ""}${site.id}`} style={{ textDecoration: "none" }} key={`site${index}`}>
        <li className={className}>
          <span className="siteData">
            <span className="siteField">Site Name:</span> {site.name}
          </span>
          <span className="siteData">
            <span className="siteField">Creation Date:</span> {site.creationDate}
          </span>
          <span className="siteData">
            <span className="siteField">Site Type:</span> {site.siteType}
          </span>
        </li>
      </Link>
    );
  }

  return createCard;
}

export function standardCardCreator(onSiteClicked: (site: SiteDetails) => void) {
  function createCard(site: SiteDetails, index: number) {
    const className = `siteItem${index % 2}`;
    return (
      <li className={className} onClick={() => onSiteClicked(site)}>
        <span className="siteData">
          <span className="siteField">Site Name:</span> {site.name}
        </span>
        <span className="siteData">
          <span className="siteField">Creation Date:</span> {site.creationDate}
        </span>
        <span className="siteData">
          <span className="siteField">Site Type:</span> {site.siteType}
        </span>
      </li>
    );
  }

  return createCard;
}
