import React from "react";

import { DrawnContext } from "./DrawnFileData";

import { AnalysisProductsSource, SiteDetails, UserLayerShape } from "../../../Generated/ExoDBAPI";
import { MapFeaturesStatus } from "../DataTypes/MapFeatures";

import DrawnControlForm from "./DrawnControlForm";
import CommitDrawnLayerForm from "./CommitDrawnLayerForm";
import LayersControlForm, { UpdateCacheParams } from "./LayersControlForm";
import SelectedShapeView from "./SelectedShapeView";

function DrawingTab({
  plannedSite,
  source,
  drawnContext,
  mapFeaturesStatus,
  selectedShape,
  setSelectedShape,
}: DrawingTabProps) {
  const [failureSavedCacheParams, setFailureSavedCacheParams] = React.useState<UpdateCacheParams>();

  function reportUploadFailure(plannedSite: SiteDetails, features: UserLayerShape[]) {
    console.log("Upload layer failed! Save to failure cache");
    setFailureSavedCacheParams({ plannedSite: plannedSite, cacheName: "failure", features: features });
  }

  return (
    <span className="tabsContainer drawnContainer">
      <span className="tabsBody drawnTab">
        <h3>Current Shape</h3>
        <DrawnControlForm drawnContext={drawnContext} />
      </span>
      <span className="tabsBody drawnTab">
        <h3>Commit Layer</h3>
        <CommitDrawnLayerForm
          site={plannedSite}
          source={source}
          drawnContext={drawnContext}
          mapFeatures={mapFeaturesStatus}
          reportUploadFailure={reportUploadFailure}
        />
      </span>
      <span className="tabsBody drawnTab">
        <h3>Layers Control</h3>
        <LayersControlForm
          plannedSite={plannedSite}
          drawnContext={drawnContext}
          failureCache={failureSavedCacheParams}
        />
      </span>

      <SelectedShapeView
        selectedShape={selectedShape}
        updateShapeAttributes={(shape, attributes, newPositions) => {
          drawnContext.setAllDrawnShapes((shapes) => {
            const newShapes = [...shapes];
            const updateShapeIndex = newShapes.findIndex((s) => s === shape);
            if (updateShapeIndex !== -1) {
              const lats = newPositions.map((p: any) => {
                if (Object.keys(p).includes("lat")) {
                  return p.lat;
                }
                return p[0];
              });
              const lons = newPositions.map((p: any) => {
                if (Object.keys(p).includes("lng")) {
                  return p.lng;
                }
                return p[1];
              });

              newShapes[updateShapeIndex] = { ...shape, layerAttributes: attributes, lats, lons };
              setSelectedShape(newShapes[updateShapeIndex]);
            }
            return newShapes;
          });
        }}
        editable={selectedShape !== undefined ? drawnContext.allDrawnShapes.includes(selectedShape) : false}
        drawnContext={drawnContext}
      />
    </span>
  );
}

export interface DrawingTabProps {
  plannedSite?: SiteDetails;
  source?: AnalysisProductsSource;
  drawnContext: DrawnContext;
  mapFeaturesStatus: MapFeaturesStatus;
  selectedShape?: UserLayerShape;
  setSelectedShape: (shape?: UserLayerShape) => void;
}

export default DrawingTab;
