import { Dict } from '../BasicTypes';
import { LatLng, LatLngExpression } from 'leaflet';

export interface Layer<DataType, MetaDataType> {
  id: string;
  name: string;
  data?: DataType;
  color: string;
  overrideColor?: boolean;
  visible: boolean;
  selected: boolean;
  subLayers?: Layer<DataType, MetaDataType>[];
  metadata?: MetaDataType;
  geoData?: string;
}

export enum PlannedObjectType {
  POLYGON,
  TRANSMITTER
}

export interface PlannedPolygonMetadata {
  platforms?: string[];
  name: string;
  comment?: string;
  sensorTypes?: string[];
  method?: string;
  type: PlannedObjectType;
  isRescan?: boolean;
  geoData?: LatLng[];
}

export type PlannedPolygonLayer = Layer<LatLngExpression[], PlannedPolygonMetadata>;

export type GeoJsonLayer = Layer<any, Dict<any>>;

export function globalSelectToggleRec(layerId: string, layers: Layer<any, any>[]) {
  const layerIndex = layers.findIndex((l) => l.id === layerId);
  const selected = !layers[layerIndex]?.selected;
  const newLayers = layers.map((l) => ({ ...l, selected: false }));
  if (layerIndex !== -1) {
    newLayers[layerIndex].selected = selected;
  }

  newLayers.forEach((l) => {
    l.subLayers = globalSelectToggleRec(layerId, l.subLayers ?? []);
  });
  return newLayers;
}

export function findLayerByPredicate<T, V>(
  layers: Layer<T, V>[],
  predicate: (layer: Layer<T, V>) => boolean
): Layer<T, V> | undefined {
  for (const layer of layers) {
    if (predicate(layer)) {
      return layer;
    }
    const foundLayer = findLayerByPredicate(layer.subLayers ?? [], predicate);
    if (foundLayer !== undefined) {
      return foundLayer;
    }
  }

  // No layer the fulfils the predicated found
  return undefined;
}

export function findAllLayersByPredicate<T, V>(
  layers: Layer<T, V>[],
  predicate: (layer: Layer<T, V>) => boolean
): Layer<T, V>[] {
  const matchingLayers: Layer<T, V>[] = [];
  for (const layer of layers) {
    if (predicate(layer)) {
      matchingLayers.push(layer);
    }
    const foundLayers = findAllLayersByPredicate(layer.subLayers ?? [], predicate);
    matchingLayers.push(...foundLayers);
  }
  return matchingLayers;
}
