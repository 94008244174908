import { create } from 'zustand';

type MapRefStore = {
  mapRef: any;
  setMapRef: (ref: any) => void;
};

export const useMapRef = create<MapRefStore>((set) => ({
  mapRef: {},
  setMapRef: (mapRef) => set(() => ({ mapRef }))
}));
