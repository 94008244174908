import React, { useContext, useMemo } from 'react';
import { ButtonGroup, Button, Tooltip } from '@mui/material';
import Control from 'react-leaflet-custom-control';
import DrawTypeControl from './MangeLayers/DrawTypeControl';

import {
  SquareFoot as SquareFootIcon,
  DriveFileRenameOutline as DriveFileRenameOutlineIcon
} from '@mui/icons-material';
import { SelectedVisionToolsMode } from './MangeLayers/DrawTypeControl';
import MeasureControl from './MeasureControl';
import { GeoJsonLayer } from '../../../Utils/layerUtils';
import LayerContext from '../../Contexts/LayerContext';
import { exovision_edit } from '../../../Constant/exoVision';

interface Props {
  onGeoJsonLayerClicked: (layerId: string, ctrlClick: boolean, subLayer: any) => void;
  plannedPolygons: GeoJsonLayer[];
  setPlannedPolygons: (
    polygons: GeoJsonLayer[] | ((polygons: GeoJsonLayer[]) => GeoJsonLayer[])
  ) => void;
}

export default function ExoVisionTool({
  onGeoJsonLayerClicked,
  plannedPolygons,
  setPlannedPolygons
}: Props) {
  const [selectedTool, setSelectedTool] = React.useState<SelectedVisionToolsMode>(
    SelectedVisionToolsMode.NONE
  );
  const { globalLayerSelected } = useContext(LayerContext);
  const handleSelectedTool = useMemo(
    () => (tool: SelectedVisionToolsMode) => {
      setSelectedTool(tool !== selectedTool ? tool : SelectedVisionToolsMode.NONE);
    },
    [selectedTool, setSelectedTool]
  );
  const styleTool = useMemo(
    () => (tool: SelectedVisionToolsMode) => {
      return {
        backgroundColor: tool === selectedTool ? 'green' : 'white',
        color: tool === selectedTool ? 'white' : 'black',
        marginBottom: '4px'
      };
    },
    [selectedTool]
  );

  return (
    <Control position="topright">
      <ButtonGroup
        orientation="vertical"
        variant="contained"
        size="small"
        sx={{
          '& .MuiButton-root': {
            backgroundColor: 'white',
            width: '20px',
            fontSize: 6,
            minWidth: '32px',
            border: '1px solid #ccc 0.5px',
            marginBottom: '4px'
          }
        }}>
        <Tooltip placement="right" title="Measure Polygon">
          <Button
            color="inherit"
            onClick={() => handleSelectedTool(SelectedVisionToolsMode.MEASURE)}
            style={styleTool(SelectedVisionToolsMode.MEASURE)}>
            <SquareFootIcon />
          </Button>
        </Tooltip>
        {selectedTool === SelectedVisionToolsMode.MEASURE && (
          <MeasureControl setSelectedTool={setSelectedTool} />
        )}

        <Tooltip placement="right" title="Draw Polygon">
          <div>
            <Button
              color="inherit"
              disabled={globalLayerSelected !== exovision_edit}
              onClick={() => handleSelectedTool(SelectedVisionToolsMode.DRAW)}
              style={styleTool(SelectedVisionToolsMode.DRAW)}>
              <DriveFileRenameOutlineIcon
                style={{ color: globalLayerSelected !== exovision_edit ? 'gray' : 'black' }}
              />
            </Button>
          </div>
        </Tooltip>
        {selectedTool === SelectedVisionToolsMode.DRAW && (
          <DrawTypeControl
            setSelectedTool={setSelectedTool}
            onGeoJsonLayerClicked={onGeoJsonLayerClicked}
            setPlannedPolygons={setPlannedPolygons}
            plannedPolygons={plannedPolygons}
          />
        )}
      </ButtonGroup>
    </Control>
  );
}
