import React from 'react';
import { Autocomplete, FormControl, Stack, TextField } from '@mui/material';
import { ApolloQueryResult } from '@apollo/client';

import UserContext from '../../Contexts/UserContext';
import AutoCompleteField from '../../Templates/AutoCompleteField';
import { getRequestParams } from '../../../Utils/azureAuth';
import {
  Api,
  SiteDetails,
  AnalysisProductsSite,
  AnalysisProductsSource,
  StateDetails
} from '../../../Generated/ExoDBAPI';
import { GetAllSitesS3RefsQuery, useGetAllSitesS3RefsLazyQuery } from '../../../Generated/Graphql';
import { MAX_ITEMS_DATA_SOURCE, MIN_ITEMS_DATA_SOURCE } from '../../../Constant/entity';

export const DISPLAY_MAP_NAME = 'Display Map';

interface SelectionAnalysisSite extends AnalysisProductsSite {
  useColorTravelCourses?: boolean;
}

function changeButtonState(button: HTMLInputElement | null, isEnabled: boolean) {
  if (button === null || button === undefined) {
    return;
  }

  if (isEnabled) {
    button.className = 'formButton';
    button.disabled = false;
  } else {
    button.className = 'buttonClicked';
    button.disabled = true;
  }
}

function changeChosen<T>(
  chosen: T | undefined,
  setSiteAction: React.Dispatch<React.SetStateAction<T | undefined>>,
  which_choosing?: string | undefined
) {
  setSiteAction(chosen);
}

export function createDisplayMapOption(
  travelCourseSource: string,
  travelCourseKey: string
): SelectionAnalysisSite {
  return {
    name: DISPLAY_MAP_NAME,
    s3Prefix: encodeURIComponent(travelCourseKey),
    s3Source: travelCourseSource,
    useColorTravelCourses: true
  };
}

interface LocationSelection {
  id: string | undefined;
  name: string;
}

const allLocationOption = { id: undefined, name: '  All  ' };

/**
 * The page for selecting a site. The logic is in the form
 * * @param {*} loadedSites sites loaded previously to save having to wait for them to load
 * @returns
 */
function SiteSelectForm({
  dataSources,
  plannedSites,
  setParentSource,
  setParentPlannedSite,
  setParentAnalysisSite,
  setParentMapDisplayTravelCourses,
  setGraphqlSiteId,
  settransmittersAndTravelCoursesChecked,
  locations
}: SiteSelectFormProps) {
  // The second value of *LazyQuery is easier for pagination, so the first value is not used.
  const [, result] = useGetAllSitesS3RefsLazyQuery();

  const [analysisSites, setAnalysisSites] = React.useState<SelectionAnalysisSite[]>([]);
  const locationsOptions = React.useMemo<LocationSelection[]>(() => {
    const baseLocations = locations.map((location) => {
      return { id: location.id, name: location.stateName };
    });
    return [allLocationOption, ...baseLocations];
  }, [locations]);
  const [graphqlSites, setGraphqlSites] = React.useState<graphqlSite[]>([]);

  const [inputSelectedSourceGroups, setInputSelectedSourceGroups] = React.useState<string[]>([]);
  const [inputSelectedSource, setInputSelectedSource] = React.useState<
    AnalysisProductsSource[] | undefined
  >([]);
  const [inputSelectedSource1, setInputSelectedSource1] = React.useState<
    AnalysisProductsSource | undefined
  >();
  const [inputSelectedPlannedSite, setInputSelectedPlannedSite] = React.useState<
    SiteDetails | undefined
  >();
  const [inputSelectedAnalysisSite, setInputSelectedAnalysisSite] = React.useState<
    SelectionAnalysisSite | undefined
  >();
  const [selectedLocation, setSelectedLocation] = React.useState<LocationSelection | undefined>(
    allLocationOption
  );
  const submitRef = React.useRef<HTMLInputElement>(null);
  const { user } = React.useContext(UserContext);

  const sourceGroups = React.useMemo(() => {
    const groups = Array.from(
      new Set<string>(dataSources.map((source) => source.group ?? source.name))
    ).sort();
    const productionIndex = groups.indexOf('Production');
    if (productionIndex !== -1) {
      const element = groups.splice(productionIndex, 1);
      groups.splice(0, 0, element[0]);
    }
    if (groups.length > 0) {
      setInputSelectedSourceGroups([groups[0]]);
    }
    if (groups.length > 0) {
      setInputSelectedSourceGroups([groups[0]]);
    }
    return groups;
  }, [dataSources]);

  const sourceOptions = React.useMemo(() => {
    return dataSources.filter((source) => inputSelectedSourceGroups.includes(source.group ?? ''));
  }, [inputSelectedSourceGroups, dataSources]);

  React.useEffect(() => {
    const sources = dataSources.filter((source) =>
      inputSelectedSourceGroups.includes(source.group ?? source.name)
    );
    setInputSelectedSource(sources);
  }, [inputSelectedSourceGroups, dataSources]);

  /**
   * Gathers all the information from the form fields and display a site to the user
   * @param {*} e onSubmit event
   */
  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    settransmittersAndTravelCoursesChecked(false);
    const submitButton = submitRef.current;
    if (submitButton === null || submitButton === undefined) {
      console.log("Submit button doesn't exist. How did you get here?");
      return;
    }
    if (inputSelectedAnalysisSite?.useColorTravelCourses === undefined) {
      setParentMapDisplayTravelCourses(false);
    } else {
      setParentMapDisplayTravelCourses(inputSelectedAnalysisSite.useColorTravelCourses);
    }

    let siteName = inputSelectedAnalysisSite?.name;

    if (siteName === DISPLAY_MAP_NAME) {
      siteName = inputSelectedAnalysisSite?.s3Prefix.split('%2F').pop();
    }
    const graphqlSite = graphqlSites.find((s) => siteName === s.s3_ref);
    if (graphqlSite === undefined) {
      throw new Error(`Couldn't find site ${siteName} in the graphql`);
    }
    console.log('graphql site id', graphqlSite.id, graphqlSite.s3_ref);
    setGraphqlSiteId(graphqlSite.id);

    let s3Sources = [];
    if (inputSelectedSource && inputSelectedSource?.length <= MAX_ITEMS_DATA_SOURCE) {
      s3Sources = inputSelectedSource ? inputSelectedSource.map((item) => item.s3Source) : [];
    } else if (inputSelectedSource1) {
      s3Sources.push(inputSelectedSource1.s3Source);
    } else {
      return;
    }

    setParentSource(s3Sources);
    setParentPlannedSite(inputSelectedPlannedSite?.id);
    setParentAnalysisSite(inputSelectedAnalysisSite?.name);
  }

  React.useEffect(() => {
    // Fetch site data from graphql on load
    console.log(`Triggered with user`, user);
    async function fetchData() {
      if (user === undefined) {
        return;
      }
      let nextToken = null;
      do {
        const res: ApolloQueryResult<GetAllSitesS3RefsQuery> = await result.fetchMore({
          variables: { nextToken }
        });
        const entries = res.data.listSites?.items ? res.data.listSites?.items : [];
        const fetchedSites: graphqlSite[] = [];
        for (const entry of entries) {
          if (entry !== null) {
            fetchedSites.push(entry);
          }
        }
        setGraphqlSites((sites) => [...sites, ...fetchedSites]);
        nextToken = res.data.listSites?.nextToken;
      } while (nextToken);
    }
    fetchData();
  }, [user]);

  React.useEffect(() => {
    if (sourceOptions.length === 1) {
      setInputSelectedSource1(sourceOptions[0]);
    }
  }, [sourceOptions]);

  React.useEffect(() => {
    // Handle analysis site on source/planned site change
    async function fetchData() {
      if (user === undefined || inputSelectedPlannedSite === undefined) {
        // Clean analysis site if planned site is not set
        setAnalysisSites([]);
        changeChosen(undefined, setInputSelectedAnalysisSite, 'analysis site');
        changeButtonState(submitRef.current, false);
      } else {
        // Fill the analysis site that matches the source and planned site
        const api = new Api();
        setAnalysisSites([]);
        changeButtonState(submitRef.current, true);
        if (!inputSelectedPlannedSite) return;
        if (inputSelectedSource1) {
          const response = await api.analysis.getAnalysisSites(
            {
              plannedSiteId: inputSelectedPlannedSite.id,
              source: inputSelectedSource1.s3Source
            },
            await getRequestParams()
          );
          if (!response) return;
          const sites: AnalysisProductsSite[] = [];
          sites.push(...response.data.sites);
          sites.push(createDisplayMapOption(response.data.coursesSource, response.data.coursesKey));
          setAnalysisSites(sites);
          changeButtonState(submitRef.current, true);
        } else if (inputSelectedSource && inputSelectedSource?.length > 0) {
          for (const item of inputSelectedSource) {
            const response = await api.analysis.getAnalysisSites(
              {
                plannedSiteId: inputSelectedPlannedSite?.id,
                source: item.s3Source
              },
              await getRequestParams()
            );
            if (!response) return;
            const sites: AnalysisProductsSite[] = [];
            sites.push(...response.data.sites);
            sites.push(
              createDisplayMapOption(response.data.coursesSource, response.data.coursesKey)
            );
            setAnalysisSites(sites);
            changeButtonState(submitRef.current, true);
          }
        }
      }
    }
    fetchData();
  }, [user, inputSelectedPlannedSite, inputSelectedSource, inputSelectedSource1]);

  return (
    <form className="siteForm" autoComplete="off" onSubmit={submit}>
      <FormControl key="siteSelectForm">
        {sourceOptions.length > MAX_ITEMS_DATA_SOURCE && (
          <AutoCompleteField<AnalysisProductsSource>
            key="exofuser.siteSelection.dataSource"
            placeholder="Data Source"
            options={sourceOptions}
            setSelectedOption={setInputSelectedSource1}
            selectedOption={inputSelectedSource1}
            alwaysChoose={true}
          />
        )}

        <Autocomplete
          style={{ width: 500, marginTop: 10, marginLeft: 5, marginBottom: 2 }}
          options={sourceGroups}
          getOptionLabel={(option) => option}
          placeholder="Source"
          value={sourceGroups.length > 0 ? inputSelectedSourceGroups : []}
          renderInput={(params) => (
            <TextField
              {...params}
              key={`option-${params.id}`}
              placeholder="Group"
              label="Data Source Groups"
            />
          )}
          onChange={
            (event, value) => {
              if (value.length >= MIN_ITEMS_DATA_SOURCE && value.length <= MAX_ITEMS_DATA_SOURCE)
                setInputSelectedSourceGroups(value);
            } // limit to 3 sources TODO: unlimited sources
          }
          size="small"
          disableCloseOnSelect={true}
          autoComplete
          multiple
        />

        <Stack direction="row">
          <AutoCompleteField<LocationSelection>
            key="exofuser.siteSelection.location"
            placeholder="Location"
            options={locationsOptions}
            setSelectedOption={setSelectedLocation}
            selectedOption={selectedLocation}
            alwaysChoose={true}
            autoCompleteProps={{ sx: { width: '9em' }, disableClearable: true }}
          />
          <AutoCompleteField<SiteDetails>
            key="exofuser.siteSelection.plannedSite"
            placeholder="Planned Site"
            options={plannedSites.filter(
              (site) => selectedLocation?.id === undefined || selectedLocation.id === site.stateId
            )}
            setSelectedOption={setInputSelectedPlannedSite}
            selectedOption={inputSelectedPlannedSite}
            alwaysChoose={true}
            autoCompleteProps={{ sx: { minWidth: '40em' } }}
          />
        </Stack>
        <AutoCompleteField<SelectionAnalysisSite>
          key="exofuser.siteSelection.analysisSite"
          placeholder="Analysis Site"
          options={analysisSites}
          setSelectedOption={setInputSelectedAnalysisSite}
          selectedOption={inputSelectedAnalysisSite}
          alwaysChoose={true}
        />

        <span style={{ marginTop: 12 }} className="formSection" key="submitButton">
          <input
            type="submit"
            value="Display"
            className="formButton"
            disabled={true}
            ref={submitRef}
          />
        </span>
      </FormControl>
    </form>
  );
}

export interface graphqlSite {
  s3_ref?: string | null;
  id: string;
}

export interface SiteSelectFormProps {
  dataSources: AnalysisProductsSource[];
  plannedSites: SiteDetails[];
  setParentSource: (s: string[] | undefined) => void;
  settransmittersAndTravelCoursesChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setParentPlannedSite: (s: string | undefined) => void;
  setParentAnalysisSite: (s: string | undefined) => void;
  setParentMapDisplayTravelCourses: React.Dispatch<React.SetStateAction<boolean>>;
  setGraphqlSiteId: (id: string) => void;
  locations: StateDetails[];
}

export default SiteSelectForm;
