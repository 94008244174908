import React from "react";
import { EntityAction } from "../../../../Types/MappingEntities";
import { SelectedToolsMode, ToolObject } from "../MeasurementUtil";
import { GeoJSON, Popup, useMap } from "react-leaflet";
import { Button, ButtonGroup, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { Dict } from "../../../../BasicTypes";

type Props = {
  createTool: (tool: ToolObject) => void;
  handleCancelEntity: () => void;
  externalLineGeojson: any;
};

const streetLegalTools: Dict<SelectedToolsMode> = {
  "Regular Line": SelectedToolsMode.STRAIGHT_LINE,
  "Dashed Line": SelectedToolsMode.DASH_LINE,
  "Gas Line": SelectedToolsMode.GAS_LINE,
  "Electric Line": SelectedToolsMode.ELECTRIC_LINE,
};

function ExternalLine({ externalLineGeojson, createTool, handleCancelEntity }: Props) {
  const [tool, setTool] = React.useState<SelectedToolsMode>();
  const map = useMap();

  React.useEffect(() => {
    const coord = externalLineGeojson.geometry.coordinates[0];
    map.flyTo([coord[1], coord[0]]);
  }, []);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (tool === undefined) {
      return;
    }
    createTool({
      type: tool,
      text: "",
      id: "",
      properties: externalLineGeojson["properties"] ?? {},
      points: externalLineGeojson["geometry"]["coordinates"].map((c: [number, number]) => ({ lng: c[0], lat: c[1] })),
    });
  }
  return (
    <>
      <GeoJSON data={externalLineGeojson} pathOptions={{ color: "purple", weight: 6 }}>
        <Popup closeButton={false} autoClose={false}>
          <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography variant="h5" pb={1}>
              Assign Type
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="type-label"
                name="type"
                value={tool}
                onChange={(_, v) => {
                  setTool(Number(v) as SelectedToolsMode);
                }}
              >
                {Object.keys(streetLegalTools).map((optionKey: string) => (
                  <FormControlLabel
                    key={optionKey}
                    value={streetLegalTools[optionKey]}
                    control={<Radio />}
                    label={optionKey}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <ButtonGroup>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
                disabled={tool === undefined}
              >
                Create Entity
              </Button>
              <Button variant="contained" color="primary" style={{ marginTop: "20px" }} onClick={handleCancelEntity}>
                Removed Entity
              </Button>
            </ButtonGroup>
          </form>
        </Popup>
      </GeoJSON>
    </>
  );
}

export default ExternalLine;
