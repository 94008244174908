import { Box, Button, FormControl, MenuItem, Modal, FormLabel, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import ModalBox from "../../Templates/ModalBox";
import { GeoShape, UserLayerAttributes } from "../../../Generated/ExoDBAPI";
import { drawnDisplayConfig } from "./DrawnFileData";

function PropertiesModal({
  onConfirm,
  onCancel,
  QLOptions,
  defaultQL,
  defaultName,
  defaultDepth,
}: PropertiesModalProps) {
  const [QL, setQL] = React.useState(defaultQL);
  const [name, setName] = React.useState(defaultName);
  const [color, setColor] = React.useState<string>((drawnDisplayConfig[GeoShape.Polyline] as any)[defaultQL].color);
  const [type, setType] = React.useState("");
  const [properties, setProperties] = React.useState("");
  const [depth, setDepth] = React.useState(defaultDepth ? defaultDepth.toString() : "");
  const [notes, setNotes] = React.useState("");

  return (
    <Modal open={true}>
      <ModalBox>
        <FormControl>
          <Box sx={{ width: 400 }}>
            <Stack gap={2}>
              <Typography variant="h4">Shape Attributes</Typography>
              <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} />
              <Stack gap={1}>
                <TextField label="QL" value={QL} onChange={(e) => setQL(e.target.value)} select>
                  {QLOptions.map((o) => (
                    <MenuItem value={o}>{o}</MenuItem>
                  ))}
                </TextField>
                <FormLabel>Color</FormLabel>
                <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
                <TextField label="Type" value={type} onChange={(e) => setType(e.target.value)} />
                <TextField label="Properties" value={properties} onChange={(e) => setProperties(e.target.value)} />
                <TextField
                  label="Depth (Meters)"
                  value={depth}
                  onChange={(e) => setDepth(e.target.value)}
                  type="number"
                />
                <TextField label="Notes" value={notes} onChange={(e) => setNotes(e.target.value)} />
              </Stack>
              <Stack direction="row">
                <Button
                  variant="outlined"
                  onClick={() =>
                    onConfirm(
                      {
                        QL,
                        type,
                        properties,
                        depth: depth === undefined || depth === "" ? undefined : Number.parseFloat(depth),
                        notes,
                        color,
                      },
                      name
                    )
                  }
                >
                  Confirm
                </Button>
                <Button variant="outlined" sx={{ marginLeft: "auto" }} onClick={onCancel}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Box>
        </FormControl>
      </ModalBox>
    </Modal>
  );
}

export interface PropertiesModalProps {
  onConfirm: (attrs: UserLayerAttributes, name: string) => void;
  onCancel: () => void;
  QLOptions: string[];
  defaultQL: string;
  defaultName: string;
  defaultDepth?: number;
}

export default PropertiesModal;
