import { Box } from "@mui/material";
const boxShadow =
  "0px 2px 5px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);";
const PageCard = (props: any) => (
  <Box
    {...props}
    sx={{
      padding: 1.5,
      margin: 1,
      position: "relative",
      background: "white",
      boxShadow: boxShadow,
      borderRadius: "5px",
      ...props.sx,
    }}
  >
    {props.children}
  </Box>
);
export default PageCard;
