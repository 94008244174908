import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useGetTargetScanManHoleLazyQuery,
  useUpdateScanEntityMutation
} from '../../Generated/Graphql';
import { TargetObject } from '../../Types/MappingEntities';
import ManHoleForm from './ManHolesForm';
import { FormData } from './ManHolesForm';
import { SiteDetails } from '../../Generated/ExoDBAPI';
import './ManHolesForm.css';

export function ManHole() {
  const [scanTargetQuery] = useGetTargetScanManHoleLazyQuery();
  const [targetObject, setTargetObject] = useState<TargetObject>();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [country, setCountry] = useState<string>('');
  const [scanName, setScanName] = useState<string>('');
  const [siteName, setSiteName] = useState<string>('');
  const [site, setSite] = useState<SiteDetails | undefined>();
  const [sourceTargetInfo, setSourceTargetInfo] = useState<any>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [finishScan, setFinishScan] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<[number, number] | null>(null);
  const [updateScanEntity, { loading, error }] = useUpdateScanEntityMutation();
  const queryParams = new URLSearchParams(location.search);
  const scanId = queryParams.get('scanId');

  const updateTargetInfo = (formData: FormData) => {
    const newTargetInfo = {
      ...(sourceTargetInfo || {}),
      ...formData
    };
    if (!targetObject?.id) return;
    updateScanEntity({
      variables: { id: targetObject?.id, target_info: JSON.stringify(newTargetInfo) }
    });
    setFinishScan(true);
  };
  useEffect(() => {
    let nextToken: string | null = null;
    async function fetchData() {
      if (!scanId) {
        setIsLoading(false);
        setNotFound(true);
        return;
      }
      const res = await scanTargetQuery({ variables: { scanId, nextToken } });
      if (!res?.data?.searchScans?.items || res?.data?.searchScans?.total === 0) {
        setIsLoading(false);
        setNotFound(true);
        return;
      }
      const items = Array.isArray(res.data.searchScans.items)
        ? res.data.searchScans.items
        : [res.data.searchScans.items];
      const filteredItems = items.filter((item) => item !== null);
      const target_info = JSON.parse(filteredItems[0]?.target_info);
      const metadata = JSON.parse(filteredItems[0]?.metadata);
      const coordinates = metadata?.scan?.gps_position
        ? [metadata?.scan?.gps_position?.lon, metadata?.scan?.gps_position?.lat]
        : null;
      setCountry(items[0]?.site?.location?.country || '');
      setSourceTargetInfo(target_info);
      setSite(filteredItems[0]?.site as SiteDetails);
      setScanName(metadata?.scan?.name || '');
      setSiteName(metadata?.scan?.site || '');
      setTargetObject(filteredItems[0] as TargetObject);
      setCoordinates(coordinates as [number, number]);
      setIsLoading(false);
    }
    fetchData();
  }, [scanTargetQuery, scanId]);

  if (isLoading) return <div>Loading...</div>;

  if (notFound || !targetObject || !scanId || !site)
    return (
      <div className="manhole-404">
        <img src="./Assets/Manhole/manhole404.png" alt="404 Not Found" className="manhole_img" />
      </div>
    );
  if (error) return <div>Error uploading data: {error.message}</div>;
  if (loading) return <div>UpLoading...</div>;

  if (finishScan)
    return (
      <>
        <div className="manhole-finish">
          <img src="./Assets/Manhole/scan_success.jpg" alt="Success" className="manhole_img" />
        </div>
      </>
    );
  return (
    <ManHoleForm
      updateTargetInfo={updateTargetInfo}
      targetObject={targetObject}
      scanId={scanId}
      scanName={scanName}
      country={country}
      siteName={siteName}
      site={site}
      coordinates={coordinates}
    />
  );
}
