import * as React from 'react';
import { ButtonGroup, Box, IconButton, Stack, Typography, Collapse } from '@mui/material';
import { Layer } from '../../../Utils/layerUtils';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LayersList from './LayersList';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InfoIcon from '@mui/icons-material/Info';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { Dict } from '../../../BasicTypes';
import { PlannedEntitiesTypes } from '../Planning/PlanningTab';
import { publish } from '../../../Utils/events';

function LayerListItem<V, T>({
  layer,
  reporters,
  externallySelectedLayerId,
  reportCloseClick,
  closeEnabled = false,
  closeEnabledOnChildren = false,
  showSave
}: GeoJsonLayerListItemProps<V, T>) {
  const [collapseIn, setCollapseIn] = React.useState(false);
  const [pickedColor, setPickedColor] = React.useState(layer.color);

  function setSubLayers(layers: Layer<V, T>[]) {
    const tempLayer = { ...layer, subLayers: layers };
    reporters.setLayer(tempLayer);
  }
  const icons = React.useMemo(() => {
    return (
      <>
        {showSave && layer.selected && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              publish('sendUpdate', {
                id: layer.id,
                type:
                  (layer.data as any[])?.length > 1
                    ? PlannedEntitiesTypes.POLYGON
                    : PlannedEntitiesTypes.TRANSMITTER,
                geoData: layer.data,
                subLayers: layer.subLayers
              });
            }}
            sx={{ height: 30, width: 30, alignSelf: 'center' }}>
            <SaveIcon sx={{ height: 20 }} />
          </IconButton>
        )}
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            publish('sendUpdate', {
              id: layer.id,
              type:
                (layer.data as any[])?.length > 1
                  ? PlannedEntitiesTypes.POLYGON
                  : PlannedEntitiesTypes.TRANSMITTER,
              overrideColor: !layer.overrideColor
            });

            reporters.setLayer({ ...layer, overrideColor: !layer.overrideColor });
          }}
          sx={{ height: 30, width: 30, alignSelf: 'center' }}>
          <ColorLensIcon sx={{ height: 20 }} color={layer.overrideColor ? 'primary' : 'inherit'} />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            reporters.reportInfoClick(layer.id, layer.name, layer.metadata ?? {}, e.pageX, e.pageY);
          }}
          sx={{ height: 30, width: 30, alignSelf: 'center' }}>
          <InfoIcon sx={{ height: 20 }} />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            reporters.toggleVisibility(layer.id);
          }}
          sx={{ height: 30, width: 30, alignSelf: 'center' }}
          key="visibilityToggle">
          {layer.visible && <VisibilityIcon sx={{ height: 20 }} />}
          {!layer.visible && <VisibilityOffIcon sx={{ height: 20 }} />}
        </IconButton>

        {closeEnabled && (
          <IconButton
            key="closeButton"
            onClick={(e) => {
              e.stopPropagation();
              if (reportCloseClick) {
                reportCloseClick(layer.id);
              } else {
                reporters.removeLayer(layer.id);
              }
            }}
            sx={{ height: 30, width: 30, alignSelf: 'center' }}>
            <CloseIcon sx={{ height: 20 }} />
          </IconButton>
        )}
      </>
    );
  }, [reporters, layer.id, layer.visible]);

  const subLayers = React.useMemo(() => {
    return (
      <>
        {layer.subLayers && layer.subLayers.length > 0 && (
          <>
            <Box
              sx={{ justifyContent: 'center', display: 'flex', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                setCollapseIn((val) => !val);
              }}>
              <MoreHorizIcon />
            </Box>
            <Collapse in={collapseIn}>
              <LayersList
                layers={layer.subLayers}
                setLayers={(layers) => setSubLayers(layers)}
                reportInfoClick={reporters.reportInfoClick}
                reportCloseClick={reportCloseClick}
                color="white"
                toggleGlobalSelect={reporters.toggleGlobalSelect}
                closeEnabled={closeEnabledOnChildren}
                closeEnabledOnChildren={closeEnabledOnChildren}
                externallySelectedLayerId={externallySelectedLayerId}
              />
            </Collapse>
          </>
        )}
      </>
    );
  }, [JSON.stringify(layer), reporters, collapseIn]);

  return (
    <Stack
      sx={{ padding: 1, cursor: 'pointer' }}
      onClick={(e) => {
        e.stopPropagation();
        if (e.ctrlKey) {
          reporters.setLayer({ ...layer, selected: !layer.selected });
        } else {
          reporters.toggleGlobalSelect(layer.id);
        }
      }}>
      <Stack direction="row" gap="0.5rem">
        <Typography variant="subtitle1" fontWeight="bold" fontSize="0.9rem">
          {layer.name}
        </Typography>
        <ButtonGroup sx={{ marginLeft: 'auto' }}>
          {true ? (
            <input
              type="color"
              value={layer.overrideColor ? pickedColor : layer.color}
              onChange={(e) => setPickedColor(e.target.value)}
              onBlur={() => {
                reporters.changeColor(
                  pickedColor,
                  layer.id,
                  (layer.data as any[])?.length > 1
                    ? PlannedEntitiesTypes.POLYGON
                    : PlannedEntitiesTypes.TRANSMITTER
                );
              }}
              style={{ alignSelf: 'center', height: '20px' }}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <></>
          )}
          {icons}
        </ButtonGroup>
      </Stack>
      {subLayers}
    </Stack>
  );
}

export interface GeoJsonLayerListItemProps<V, T> {
  layer: Layer<V, T>;
  reporters: ReportFunctions<V, T>;
  closeEnabled?: boolean;
  closeEnabledOnChildren?: boolean;
  externallySelectedLayerId?: string;
  reportCloseClick?: (layerId: string) => void;
  showSave?: boolean;
}

export interface ReportFunctions<V, T> {
  changeColor: (color: string, id: string, type?: string) => void;
  toggleVisibility: (id: string) => void;
  removeLayer: (id: string) => void;
  setLayer: (layer: Layer<V, T>) => void;
  reportInfoClick: (
    layerId: string,
    layerName: string,
    properties: Dict<any>,
    x: number,
    y: number
  ) => void;
  toggleGlobalSelect: (layerId: string) => void;
}

export default LayerListItem;
