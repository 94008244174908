import Color from "colorjs.io";
import chroma from 'chroma-js';
const maxHSLLightness = 100;

/**
 * Will return the first found stylesheet for the given selector
 *
 * @param selector: The selector of the css to search
 * @return: The values of the selector css
 */
export function getCssStyle(selector: string): CSSStyleDeclaration {
  for (let sheet of document.styleSheets) {
    for (let rule_index in sheet.cssRules) {
      const rule: CSSStyleRule | null = sheet.cssRules.item(parseInt(rule_index)) as CSSStyleRule | null;
      if (rule?.selectorText === ".exofuserMenu") {
        return rule.style;
      }
    }
  }

  throw Error(`Couldn't find stylesheet for selector ${selector}`);
}

export function colorToRBGA(color: Color): string {
  const names = ["r", "g", "b"];
  const values = names.map((attr) => Math.min(255, Math.floor(255 * color.p3[attr])));
  values.push(color.alpha);
  return `rgba(${values.join(",")})`;
}

/**
 * Transform the color to a given range of lightness
 *
 * @param colorDescription Color, given as a string by one of the common formats
 * @param lightnessMin The minimal value for lightness, in [0, lightnessMax]
 * @param lightnessMax The maximal value for lightness, in [lightnessMin, 100]
 * @returns A string representing the color, in p3 format.
 */
export function transformLightnessToRange(
  colorDescription: string,
  lightnessMin: number = 0,
  lightnessMax: number = 100
): string {
  if (lightnessMin < 0 || lightnessMax > maxHSLLightness || lightnessMin > lightnessMax) {
    throw Error(`Expected lightness range: 0<=lmin<=lmax<=100, Got min: ${lightnessMin}, max: ${lightnessMax}`);
  }

  const color = new Color(colorDescription);
  const lightnessDelta = lightnessMax - lightnessMin;
  color.set("hsl.l", (lightness) => lightness * (lightnessDelta / maxHSLLightness) + lightnessMin);
  return colorToRBGA(color);
}

export function brightenColor(color: string, factor: number) {
  return chroma(color).brighten(factor).css();
}

export function darkenColor(color: string, factor: number) {
  return chroma(color).darken(factor).css();
}
