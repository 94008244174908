import { ParsedTargetObect } from '../../../../Types/MappingEntities';
import { FilterCategories, Filters } from '../types';

export const getOwner = (p?: ParsedTargetObect) => {
  const company = p?.target_info?.company || p?.target_info?.otherCompanyName;
  return company?.trim() || '';
};

export const isMatchOwner = (p: ParsedTargetObect, owner: Filters[FilterCategories.owner]) => {
  return !!owner?.find((o) => o.value === getOwner(p));
};

export const getOwners = (pois: ParsedTargetObect[]) => {
  const ignoreValues = ['?', 'N/a'];
  return Object.values(
    pois.reduce<Record<string, { label: string; value: string }>>((acc, p) => {
      const owner = getOwner(p);

      if (!owner || ignoreValues.includes(owner)) return acc;

      return {
        ...acc,
        [owner]: {
          value: owner,
          label: owner
        }
      };
    }, {})
  );
};
