import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { groupBy } from "../../Utils/groupBy";
import { getFileName } from "../../Utils/fileUtils";
import StatusSections from "./StatusSections";
import { PipelineRunInfo } from "./MonitorTypes";
import { calculateAggregatedStatus } from "./monitoringParser";

function PipelineSection({ pipelines }: PipelineSectionProps) {
  const groupedPipelines = React.useMemo<eplsPipelineRuns[]>(() => {
    const groupedPipelines = groupBy(pipelines, (p) => getFileName(p.eplsS3Ref));
    const sortedNames = Object.keys(groupedPipelines).sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
    );
    return sortedNames.map((name) => ({
      eplsName: name,
      pipelines: groupedPipelines[name],
      status: calculateAggregatedStatus(new Set<string>(groupedPipelines[name].map((p) => p.status))),
    }));
  }, [pipelines]);

  function createPipelineInfoSection(runInfo: PipelineRunInfo) {
    return (
      <Stack gap="0.1rem">
        <Typography variant="subtitle1" fontWeight="bold">
          {runInfo.name}
        </Typography>
        <Stack sx={{ marginLeft: "0.1rem" }} gap="0.1rem">
          <Box>
            Start Time: {runInfo.startTime === undefined ? "N/A" : new Date(runInfo.startTime).toLocaleString()}
          </Box>
          <Box>End Time: {runInfo.endTime === undefined ? "N/A" : new Date(runInfo.endTime).toLocaleString()}</Box>
          <Box>Status: {runInfo.status}</Box>
          {runInfo.errorMessage && runInfo.status === "FAILED" ? (
            <Box>Error message: {runInfo.errorMessage}</Box>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    );
  }
  return (
    <>
      {groupedPipelines.map((p) => (
        <>
          <StatusSections
            name={p.eplsName}
            items={p.pipelines}
            key={p.eplsName}
            createItemDisplay={(pipeline) => createPipelineInfoSection(pipeline)}
          />
          <hr />
        </>
      ))}
    </>
  );
}

export interface PipelineSectionProps {
  pipelines: PipelineRunInfo[];
}

export interface eplsPipelineRuns {
  eplsName: string;
  pipelines: PipelineRunInfo[];
  status: string;
}

export default PipelineSection;
