import React from "react";
import { ToolObject } from "../MeasurementUtil";
import { Button, Tooltip } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ExternalLine from "./ExternalLine";
import { CreatedAlgoPipePane } from "../../../../config/paneConfig";
import { Api as ServicesApi } from "../../../../Generated/ExoServicesAPI";
import { getServicesRequestParams } from "../../../../Utils/azureAuth";


type Props = {
  dispalyedPipesIds: string[];
  createTool: (tool: ToolObject) => void;
};

function CreateAlgoLineTool({ dispalyedPipesIds, createTool }: Props) {
  const [createdLine, setCreatedLine] = React.useState<any[]>([]);
  const [isLoading, setIsloading] = React.useState(false);

  async function createLine() {
    if (createdLine.length > 0) {
      return;
    }
    setIsloading(true);
    const api = new ServicesApi();
    try {
      const res = await api.analysis.createNewAlgoPipe(
        { entities_ids: dispalyedPipesIds },
        await getServicesRequestParams()
      );
      if (res.data.algo_line?.features.length === 0) {
        window.alert("No pipe was created from the group");
        return;
      }
      res.data.algo_line?.features.forEach((feature: any) => {
        setCreatedLine(prev => [...prev, feature]);
      });

    } catch (e) {
      console.error("Error while calling create pipe endpoint", e);
      window.alert("Got an error while trying to create a new pipe");
    } finally {
      setIsloading(false);
    }
  }
  return (
    <>
      <Tooltip placement="left" title="Create Pipe Automatically">
        <Button color="inherit" onClick={createLine} disabled={createdLine.length > 0 || isLoading}>
          <EngineeringIcon />
        </Button>
      </Tooltip>
      {createdLine.map( (line, index) => (
        <CreatedAlgoPipePane>
          <ExternalLine
            createTool={(tool) => {
              createTool(tool);
              // setCreatedLine(undefined);
            }}
            externalLineGeojson={line}
            handleCancelEntity={() => setCreatedLine([])}
          />
        </CreatedAlgoPipePane>
      ))}
    </>
  );
}

export default CreateAlgoLineTool;
