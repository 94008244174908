import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface CircularProgressWithLabelProps extends CircularProgressProps {
  value: number;
  fontSize: string;
  size: number;
  thickness?: number;
}

const CircularProgressWithLabel = ({ value, fontSize, size, thickness=8 }: CircularProgressWithLabelProps) => {
  return (
    <Box sx= {{ width: '300px'  }}>
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={value} size={size} thickness={thickness}  />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary', fontSize  }}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
    </Box>
  );
}

// return <CircularProgressWithLabel value={progress} size={200} fontSize="2rem" />;

export default CircularProgressWithLabel;
