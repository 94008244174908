import { Box, Stack } from "@mui/material";
import React from "react";
import PageCard from "../Templates/PageCard";
import ScanStatusContext from "./ScansStatusContext";

function ScanStatus({ header, color, children }: ScanStatusProps) {
  return (
    <PageCard sx={{ padding: 1, backgroundColor: color }}>
      <Stack>
        <Stack direction="row">
          <Box width="50%" overflow="hidden" sx={{ wordWrap: "break-word" }}>
            {header}
          </Box>
          <Box width="65%" overflow="hidden">
            <Stack direction="row" sx={{ alignItems: "center" }} gap="0.2rem">
              {Array.isArray(children) &&
                children.map((node, i) => (
                  <>
                    {node}
                    {children.length - 1 !== i && <hr style={{ width: "20px", margin: "0px" }} />}
                  </>
                ))}
              {!Array.isArray(children) && children}
            </Stack>
          </Box>
        </Stack>
        <ScanStatusContext.Consumer>
          {(props) =>
            Array.isArray(props.currentPickedNode?.sections)
              ? props.currentPickedNode?.sections.map((section, i) => (
                  <>
                    <hr />
                    {section}
                  </>
                ))
              : props.currentPickedNode?.sections
          }
        </ScanStatusContext.Consumer>
      </Stack>
    </PageCard>
  );
}

export interface ScanStatusProps {
  color?: string;
  header: React.ReactNode;
  children: React.ReactElement[] | React.ReactElement | undefined;
}

export default ScanStatus;
