import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { ChangeEvent } from 'react';
import { ScanDisplayInfo } from '../Pages/MegaMonitor';

export enum ScanFilterID {
  Survey = 'survey',
  ValidScan = 'validScan',
  Target = 'target',
  Transmitter = 'transmitter'
}

export const SCAN_FILTER = [
  { id: ScanFilterID.Survey, label: 'Survey' },
  { id: ScanFilterID.Target, label: 'Target' },
  { id: ScanFilterID.Transmitter, label: 'Transmitter' },
  { id: ScanFilterID.ValidScan, label: 'Valid Scan' }
];

export const FilterFunctions: Record<ScanFilterID, (v: ScanDisplayInfo) => boolean> = {
  [ScanFilterID.Survey]: (v) => v.scanType.toLowerCase() === 'survey',
  [ScanFilterID.Transmitter]: (v) => v.scanType.toLowerCase() === 'transmitter',
  [ScanFilterID.ValidScan]: (v) => v.valid,
  [ScanFilterID.Target]: (v) => v.scanType.toLowerCase() === 'target'
};

export const Filters = ({
  onChange,
  checkedFilters
}: {
  onChange: (id: string, checked: boolean) => void;
  checkedFilters: Record<ScanFilterID, boolean>;
}) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { id } = e.target;
    onChange(id, checked);
  };

  return (
    <Stack direction="row" paddingLeft={1}>
      {SCAN_FILTER.map(({ id, label }) => {
        return (
          <FormControlLabel
            key={id}
            label={label}
            control={
              <Checkbox
                size="small"
                onChange={handleOnChange}
                id={id}
                checked={checkedFilters[id]}
              />
            }
          />
        );
      })}
    </Stack>
  );
};
