import React from "react";

import { Md5 } from "ts-md5";
import { Polygon, Polyline, Tooltip } from "react-leaflet";
import { TooltipPaneName } from "../../../config/paneConfig";
import { GeoShape, UserLayerShape } from "../../../Generated/ExoDBAPI";
import {
  DrawnContext,
  SELECTED_DRAWN_SHAPE_WEIGHT,
  defaultDrawnLineDisplayConfig,
  defaultDrawnPolygonDisplayConfig,
  drawnDisplayConfig,
  lineDisplayOptions,
  polygonDisplayOptions,
} from "./DrawnFileData";
import EditableShape from "../EditableMapEntites/EditableShape";

function getDisplayOptions<T>(marking: string, markingMapping: { [key: string]: T }, defaultValue: T): T {
  if (marking in markingMapping) {
    return markingMapping[marking];
  }

  return defaultValue;
}

function DrawnShapeEntities({ shapes, selectedShape, handleShapeClicked, drawnContext }: DrawnLinesEntitiesProps) {
  function createTooltip(lines: string[]) {
    return (
      <Tooltip pane={TooltipPaneName} sticky={true}>
        {lines.map((messageLine) => (
          <div key={messageLine}>
            {messageLine}
            <br />
          </div>
        ))}
      </Tooltip>
    );
  }

  function createShape(shape: UserLayerShape, index: number) {
    try {
      const baseKey = Md5.hashAsciiStr(
        `${JSON.stringify(shape.lats)}-${JSON.stringify(shape.lons)}-${JSON.stringify(shape.name)}`
      );
      if (selectedShape === shape && drawnContext.editingShape) {
        return (
          <EditableShape
            positions={drawnContext.editedShapePositions}
            setPositions={drawnContext.setEditedShapePositions}
            editable={true}
            drawable={false}
            key={`editedShape=${baseKey}`}
            polyline
          />
        );
      }
      const latLngs: [number, number][] = shape.lats.map((lat, index) => [lat, shape.lons[index]]);
      const name = shape.name === undefined ? `unnamed (${index})` : shape.name;
      const tooltipLines = [
        `Name: ${name}`,
        `Marked as: ${shape.markingType}`,
        `QL: ${shape.layerAttributes?.QL}`,
        `Type: ${shape.layerAttributes?.type}`,
        `Properties: ${shape.layerAttributes?.properties}`,
        `Depth: ${shape.layerAttributes?.depth}`,
        `Notes: ${shape.layerAttributes?.notes}`,
      ];
      const tooltip = createTooltip(tooltipLines);

      if (shape.shapeType == GeoShape.Polyline) {
        const markingDisplayData: lineDisplayOptions = getDisplayOptions(
          shape.layerAttributes?.QL ? shape.layerAttributes.QL : shape.markingType,
          drawnDisplayConfig[GeoShape.Polyline],
          defaultDrawnLineDisplayConfig
        );

        return (
          <Polyline
            positions={selectedShape === shape ? drawnContext.editedShapePositions : latLngs}
            key={`drawnLine_${baseKey}`}
            pathOptions={{
              ...markingDisplayData,
              weight: shape === selectedShape ? SELECTED_DRAWN_SHAPE_WEIGHT : markingDisplayData.weight,
              color: shape.layerAttributes?.color ? shape.layerAttributes?.color : markingDisplayData.color,
            }}
            eventHandlers={{
              click() {
                handleShapeClicked(shape);
              },
            }}
          >
            {tooltip}
          </Polyline>
        );
      }

      if (shape.shapeType == GeoShape.Polygon) {
        const markingDisplayData: polygonDisplayOptions = getDisplayOptions(
          shape.markingType,
          drawnDisplayConfig[GeoShape.Polygon],
          defaultDrawnPolygonDisplayConfig
        );
        return (
          <Polygon
            positions={latLngs}
            key={`drawnPolygon_${baseKey}`}
            fillColor={markingDisplayData.fillColor}
            color={markingDisplayData.fillColor}
            fillOpacity={markingDisplayData.opacitiy}
          >
            {tooltip}
          </Polygon>
        );
      }
      console.log("Unsupported shape", shape);
    } catch (error) {
      console.log("Unexpected error while parsing", shape, " Error:", error);
    }

    return <></>;
  }

  return <>{shapes.map((shape, i) => createShape(shape, i))}</>;
}

export interface DrawnLinesEntitiesProps {
  shapes: UserLayerShape[];
  selectedShape?: UserLayerShape;
  handleShapeClicked: (shape: UserLayerShape) => void;
  drawnContext: DrawnContext;
}

export default DrawnShapeEntities;
