import { Button, Divider, InputLabel, Stack, TextField } from "@mui/material";
import { isEqual } from "lodash";
import React from "react";
import { Dict } from "../../../BasicTypes";
import { SiteDetails, UserLayer, UserLayerShape } from "../../../Generated/ExoDBAPI";
import { getDatetimeStr } from "../../../Utils/dateUtils";
import AutoCompleteField from "../../Templates/AutoCompleteField";

import CheckboxSelection from "../../Templates/CheckboxSelection";
import { createHoverModeSet, DrawnContext, UserLayerDisplayMode } from "./DrawnFileData";
import { LayersCacheEntry, readFromCache, saveToCache } from "./DrawnLayersCache";

// Cache simulate UserLayers to make the code easier, but the actual epsg will be set when committing the layer to
// the remote server.
const _DEFAULT_EPSG_CODE = 0;

enum StatusMessage {
  Ready = "Ready",
  NoName = "Missing a name",
  NotSelected = "No cache is selected",
  NoDrawnShape = "No shapes are drawn",
  CacheExist = "Cache name is already used",
  NoSuchCache = "Unknown cache (system error)",
  NoDisplayedLayers = "No layers are displayed",
  NoDisplayedCachedLayers = "No cached layers",
  DrawnShapesAreFound = "Layers are Drawn",
}

const ALLOWED_STATES = [StatusMessage.Ready, StatusMessage.NotSelected, StatusMessage.NoName];
const MAX_SAVED_AUTO = 5;
const USER_CACHE_PREFIX = "User: ";
const AUTO_CACHE_PREFIX = "Auto: ";
const FAILURE_CACHE_PREFIX = "Failure: ";

function compareLayers(a: UserLayer, b: UserLayer) {
  return a.name.localeCompare(b.name);
}

function getCategoryValue(cacheName: string) {
  const orderedOptions = [FAILURE_CACHE_PREFIX, USER_CACHE_PREFIX, AUTO_CACHE_PREFIX];
  for (const [index, prefix] of orderedOptions.entries()) {
    if (cacheName.startsWith(prefix)) {
      return index;
    }
  }
  return orderedOptions.length;
}

function getIndexCompareOption(firstCacheName: string, secondCacheName: string) {
  const numberedCachesPrefixes = [FAILURE_CACHE_PREFIX, AUTO_CACHE_PREFIX];
  for (const prefix of numberedCachesPrefixes) {
    if (firstCacheName.startsWith(prefix) && secondCacheName.startsWith(prefix)) {
      return parseInt(secondCacheName.slice(prefix.length)) - parseInt(firstCacheName.slice(prefix.length));
    }
  }
  return 0;
}

function compareCacheEntries(a: LayersCacheEntry, b: LayersCacheEntry) {
  const categoryCompare = getCategoryValue(a.name) - getCategoryValue(b.name);
  if (categoryCompare !== 0) {
    return categoryCompare;
  }

  const indexCompareOption = getIndexCompareOption(a.name, b.name);
  if (indexCompareOption !== 0) {
    return indexCompareOption;
  }
  return a.name.localeCompare(b.name);
}

function LayersControlForm({ plannedSite, drawnContext, failureCache }: LayersControlFormProps) {
  const [cachedData, setCachedData] = React.useState<LayersCacheEntry[]>([]);
  const [displayedSavedLayers, setDisplayedSavedLayers] = React.useState<UserLayer[]>([]);
  const [displayedCachedLayers, setDisplayedCachedLayers] = React.useState<UserLayer[]>([]);

  const [displayedLayersOverride, setDisplayedLayersOverride] = React.useState<UserLayer[]>();
  const [resendTrigger, setResendTrigger] = React.useState<boolean>(true);

  const [displayedCacheToEdit, setDisplayedCacheToEdit] = React.useState<LayersCacheEntry>();

  const displayMapping = React.useMemo<Dict<string>>(() => {
    return Object.fromEntries(
      Object.values(cachedData).map((entry) => [
        entry.name,
        `${entry.name} [${entry.features.length}, ${
          entry.timestamp === undefined ? "Unknown time" : getDatetimeStr(entry.timestamp / 1000)
        }]`,
      ])
    );
  }, [cachedData]);
  const [newCacheName, setNewCacheName] = React.useState<string>("");

  function updateCache(updateCacheParams?: UpdateCacheParams) {
    if (updateCacheParams === undefined) {
      return;
    }

    if (updateCacheParams.plannedSite === undefined) {
      console.log("Missing plannedSite when updating cache :(");
      return;
    }

    setCachedData((prevCacheData) => {
      const baseCacheData = prevCacheData.filter((entry) => entry.name !== updateCacheParams.cacheName);
      const newCacheEntries = [];
      if (updateCacheParams.features.length > 0) {
        newCacheEntries.push({
          name: updateCacheParams.cacheName,
          features: updateCacheParams.features,
          timestamp: Date.now(),
        });
      }

      const newCacheData = [...baseCacheData, ...newCacheEntries].sort((firstLayer, secondLayer) =>
        firstLayer.name.localeCompare(secondLayer.name)
      );

      if (updateCacheParams.plannedSite === undefined) {
        console.log("Error: No planned site for saving, how?");
        return newCacheData;
      }

      saveToCache({ plannedSite: updateCacheParams.plannedSite, cachedLayers: newCacheData });
      return readFromCache(plannedSite);
    });

    // Use to trigger the display change after new data was added
    setResendTrigger((prevTrigger) => !prevTrigger);
  }

  // Refresh caches on site change
  React.useEffect(() => {
    setCachedData(readFromCache(plannedSite));
  }, [plannedSite?.id]);

  // Update parents on display changing
  React.useEffect(() => {
    if (displayedLayersOverride !== undefined && displayedLayersOverride !== null) {
      drawnContext.setDisplayedLayers(displayedLayersOverride);
    } else if (displayedCacheToEdit !== undefined && displayedCacheToEdit !== null) {
      drawnContext.setDisplayedLayers([
        { name: displayedCacheToEdit.name, shapes: displayedCacheToEdit.features, wantedEpsgCode: _DEFAULT_EPSG_CODE },
      ]);
    } else {
      drawnContext.setDisplayedLayers([...displayedSavedLayers, ...displayedCachedLayers]);
    }
  }, [displayedSavedLayers, displayedCachedLayers, displayedCacheToEdit, displayedLayersOverride]);

  const newCacheReadyStatus = React.useMemo<StatusMessage>(() => {
    if (newCacheName.length <= USER_CACHE_PREFIX.length) {
      return StatusMessage.NoName;
    }
    if (cachedData.map((entry) => entry.name).includes(newCacheName)) {
      return StatusMessage.CacheExist;
    }
    if (drawnContext.allDrawnShapes.length === 0) {
      return StatusMessage.NoDrawnShape;
    }
    return StatusMessage.Ready;
  }, [newCacheName, cachedData, drawnContext.allDrawnShapes]);

  const editCacheReadyStatus = React.useMemo<StatusMessage>(() => {
    if (displayedCacheToEdit === undefined) {
      return StatusMessage.NotSelected;
    }
    if (!cachedData.includes(displayedCacheToEdit)) {
      return StatusMessage.NoSuchCache;
    }
    return StatusMessage.Ready;
  }, [displayedCacheToEdit, cachedData]);

  const loadAllReadyStatus = React.useMemo<StatusMessage>(() => {
    if (displayedSavedLayers.length === 0 && displayedCachedLayers.length === 0) {
      return StatusMessage.NoDisplayedLayers;
    }
    if (drawnContext.allDrawnShapes.length !== 0) {
      return StatusMessage.DrawnShapesAreFound;
    }
    return StatusMessage.Ready;
  }, [displayedSavedLayers, displayedCachedLayers, drawnContext.allDrawnShapes]);

  const loadCachesReadyStatus = React.useMemo<StatusMessage>(() => {
    if (displayedCachedLayers.length === 0) {
      return StatusMessage.NoDisplayedCachedLayers;
    }
    if (drawnContext.allDrawnShapes.length !== 0) {
      return StatusMessage.DrawnShapesAreFound;
    }
    return StatusMessage.Ready;
  }, [displayedCachedLayers, drawnContext.allDrawnShapes]);

  React.useEffect(() => {
    // Set/Clear the cache selection flag in the display mode state, according to if a cache is selected or not
    drawnContext.setUserDisplayModeState((prevState) => {
      prevState.cacheSelected = editCacheReadyStatus === StatusMessage.Ready;
      return JSON.parse(JSON.stringify(prevState));
    });
  }, [editCacheReadyStatus]);

  function changeDisplayHover(enabled: boolean, mode: UserLayerDisplayMode, userLayersToDisplay?: UserLayer[]) {
    if (userLayersToDisplay !== undefined && !enabled) {
      return;
    }
    setDisplayedLayersOverride(userLayersToDisplay);
    createHoverModeSet(enabled, mode, drawnContext)[
      userLayersToDisplay === undefined ? "onMouseLeave" : "onMouseEnter"
    ]();
  }

  function updateLimitedDisplayCache(prefix: string, features?: UserLayerShape[], maxCount?: number) {
    if (plannedSite === undefined || features?.length === undefined || features.length === 0) {
      return;
    }
    const realFeatures = features;

    setCachedData((prevState) => {
      if (plannedSite === undefined) {
        return prevState;
      }
      const categoryCaches = prevState.filter((entry) => entry.name.startsWith(prefix)).sort(compareCacheEntries);
      const otherCaches = prevState.filter((entry) => !entry.name.startsWith(prefix));
      const lastCacheEntry = categoryCaches[0]; // The newest cache is first, and array[0] for an empty array return `undefined`

      // No need to perform an ordered check, but it should be enough to avoid double caching
      if (lastCacheEntry !== undefined && isEqual(lastCacheEntry.features, realFeatures)) {
        return prevState;
      }
      const newIndex = lastCacheEntry === undefined ? 1 : parseInt(lastCacheEntry.name.slice(prefix.length)) + 1;
      const newEntry = {
        name: `${prefix}${newIndex}`,
        features: realFeatures,
        timestamp: Date.now(),
      };

      const end = maxCount === undefined ? undefined : maxCount - 1;
      saveToCache({
        plannedSite: plannedSite,
        // slice(0) return the entire list
        cachedLayers: [...otherCaches, ...categoryCaches.slice(0, end), newEntry],
      });
      return readFromCache(plannedSite);
    });
  }

  React.useEffect(() => {
    updateLimitedDisplayCache(FAILURE_CACHE_PREFIX, failureCache?.features);
  }, [failureCache?.features]);

  React.useEffect(() => {
    updateLimitedDisplayCache(AUTO_CACHE_PREFIX, drawnContext.allDrawnShapes, MAX_SAVED_AUTO);
  }, [drawnContext.allDrawnShapes]);

  return (
    <Stack direction="row" spacing={"2em"} divider={<Divider orientation="vertical" flexItem sx={{ width: 0 }} />}>
      <Stack direction="column" spacing="0.5em">
        <CheckboxSelection<UserLayer>
          filterName={"User Layers"}
          optionsMapping={Object.fromEntries(
            drawnContext.savedLayers.sort(compareLayers).map((layer) => [layer.name, layer])
          )}
          getDisplayByOption={(layer) => `${layer.name} [features: ${layer.shapes.length}]`}
          enabled={true}
          setSelection={setDisplayedSavedLayers}
          recalculateTrigger={true}
          resendOutputTrigger={true}
          strategy={true}
        />
        <CheckboxSelection<UserLayer>
          filterName={"Cached Layers"}
          optionsMapping={Object.fromEntries(
            Object.values(cachedData)
              .filter((entry) => entry.features.length > 0)
              .map((entry) => [
                `${entry.name}`,
                { name: entry.name, shapes: entry.features, wantedEpsgCode: _DEFAULT_EPSG_CODE },
              ])
          )}
          getDisplayByOption={(entry) =>
            displayMapping[entry.name] ?? `${entry.name} [${entry.shapes.length}, Unknown creation time]`
          }
          enabled={true}
          setSelection={setDisplayedCachedLayers}
          recalculateTrigger={true}
          resendOutputTrigger={resendTrigger}
          strategy={false}
        />
        <Stack direction="row" spacing="0.2em" style={{ marginTop: "1em", display: "none" }}>
          <TextField disabled={true} value={loadAllReadyStatus} label={"Load All Status"} />
          <div
            style={{ width: "fit-content", margin: "0.5em" }}
            onMouseEnter={() =>
              changeDisplayHover(loadAllReadyStatus === StatusMessage.Ready, UserLayerDisplayMode.LoadAll, [
                ...displayedSavedLayers,
                ...displayedCachedLayers,
              ])
            }
            onMouseLeave={() => changeDisplayHover(true, UserLayerDisplayMode.LoadAll)}
          >
            <Button
              variant="contained"
              disabled={loadAllReadyStatus !== StatusMessage.Ready}
              onClick={() => {
                drawnContext.setAllDrawnShapes((prevShapes) =>
                  prevShapes.concat([
                    ...displayedSavedLayers.flatMap((layer) => layer.shapes),
                    ...displayedCachedLayers.flatMap((layer) => layer.shapes),
                  ])
                );
              }}
            >
              Load All
            </Button>
          </div>
        </Stack>
        <Stack direction="row" spacing="0.2em" style={{ marginTop: "1em" }}>
          <TextField disabled={true} value={loadCachesReadyStatus} label={"Load Cache Status"} />
          <div
            style={{ width: "fit-content", margin: "0.5em" }}
            onMouseEnter={() =>
              changeDisplayHover(loadCachesReadyStatus === StatusMessage.Ready, UserLayerDisplayMode.LoadCache, [
                ...displayedCachedLayers,
              ])
            }
            onMouseLeave={() => changeDisplayHover(true, UserLayerDisplayMode.LoadCache)}
          >
            <Button
              variant="contained"
              disabled={loadCachesReadyStatus !== StatusMessage.Ready}
              onClick={() => {
                drawnContext.setAllDrawnShapes((prevShapes) =>
                  prevShapes.concat([...displayedCachedLayers.flatMap((layer) => layer.shapes)])
                );
              }}
            >
              Load Caches
            </Button>
          </div>
        </Stack>
      </Stack>
      <Stack direction="column" spacing="0.5em">
        <InputLabel>New Cache</InputLabel>
        <span>
          <TextField
            label={"Cache Name"}
            onChange={(e) => setNewCacheName(`${USER_CACHE_PREFIX}${e.target.value}`)}
            error={!ALLOWED_STATES.includes(newCacheReadyStatus)}
            helperText={newCacheReadyStatus}
          />
          <div
            style={{ width: "fit-content" }}
            {...createHoverModeSet(
              newCacheReadyStatus === StatusMessage.Ready,
              UserLayerDisplayMode.CacheSet,
              drawnContext
            )}
          >
            <Button
              variant="contained"
              disabled={newCacheReadyStatus !== StatusMessage.Ready}
              onClick={() => {
                updateCache({
                  plannedSite: plannedSite,
                  cacheName: newCacheName,
                  features: drawnContext.allDrawnShapes,
                });
                setNewCacheName("");
              }}
            >
              Save
            </Button>
          </div>
        </span>
      </Stack>
      <Stack direction="column" spacing="0.5em">
        <InputLabel>Edit Cache</InputLabel>
        <span>
          <AutoCompleteField<LayersCacheEntry>
            key="exofuser.drawn.cache.layers"
            placeholder={"Cache Name"}
            options={cachedData.sort(compareCacheEntries)}
            setSelectedOption={setDisplayedCacheToEdit}
            selectedOption={displayedCacheToEdit}
            autoCompleteProps={{ sx: { width: "15em" } }}
            textFieldProps={{ error: !ALLOWED_STATES.includes(editCacheReadyStatus), helperText: editCacheReadyStatus }}
          />
          <Stack direction="row" spacing="0.4em">
            <div
              {...createHoverModeSet(
                editCacheReadyStatus === StatusMessage.Ready,
                UserLayerDisplayMode.CacheUpdate,
                drawnContext
              )}
              style={{ width: "fit-content" }}
            >
              <Button
                variant="contained"
                disabled={editCacheReadyStatus !== StatusMessage.Ready}
                onClick={() => {
                  displayedCacheToEdit?.name !== undefined &&
                    updateCache({
                      plannedSite: plannedSite,
                      cacheName: displayedCacheToEdit.name,
                      features: drawnContext.allDrawnShapes.concat(displayedCacheToEdit.features),
                    });
                  setDisplayedCacheToEdit(undefined);
                }}
              >
                Update
              </Button>
            </div>
            <div
              {...createHoverModeSet(
                editCacheReadyStatus === StatusMessage.Ready,
                UserLayerDisplayMode.CacheSet,
                drawnContext
              )}
              style={{ width: "fit-content" }}
            >
              <Button
                variant="contained"
                disabled={editCacheReadyStatus !== StatusMessage.Ready}
                onClick={() => {
                  displayedCacheToEdit?.name !== undefined &&
                    updateCache({
                      plannedSite: plannedSite,
                      cacheName: displayedCacheToEdit.name,
                      features: drawnContext.allDrawnShapes,
                    });
                  setDisplayedCacheToEdit(undefined);
                }}
              >
                Overwrite
              </Button>
            </div>
            <div style={{ width: "fit-content" }}>
              <Button
                variant="contained"
                disabled={editCacheReadyStatus !== StatusMessage.Ready}
                onClick={() => {
                  displayedCacheToEdit?.name !== undefined &&
                    updateCache({
                      plannedSite: plannedSite,
                      cacheName: displayedCacheToEdit.name,
                      features: [],
                    });
                  setDisplayedCacheToEdit(undefined);
                }}
              >
                Delete
              </Button>
            </div>
          </Stack>
        </span>
      </Stack>
    </Stack>
  );
}

export interface UpdateCacheParams {
  plannedSite?: SiteDetails;
  cacheName: string;
  features: UserLayerShape[];
}

export interface LayersControlFormProps {
  plannedSite?: SiteDetails;
  drawnContext: DrawnContext;
  failureCache?: UpdateCacheParams;
}

export default LayersControlForm;
