import * as React from "react";
import { useMap } from "react-leaflet";

function MapResizer({ containerRef }: MapResizerProps) {
  const map = useMap();
  React.useEffect(() => {
    if (containerRef.current !== null) {
      const obv = new ResizeObserver(() => {
        map.invalidateSize();
      }).observe(containerRef.current);
    }
  }, []);
  return <></>;
}

export interface MapResizerProps {
  containerRef: React.RefObject<HTMLDivElement>;
}

export default MapResizer;
