import axios from "axios";
import { Dict } from "../BasicTypes";
import { runWithRetries } from "../Utils/promiseWithRetries";

class CallbackPresignedURLUploader {
  fileData: File;
  getUrlsCallback: () => Promise<UrlResponseData>;
  endMultipartUploadCallBack: (uploadId: string, parts: UploadPart[]) => Promise<void>;
  retries: number;
  timeBetweenRetries: number;
  constructor(
    fileData: File,
    getUrlsCallback: () => Promise<UrlResponseData>,
    endMultipartUploadCallBack: (uploadId: string, parts: UploadPart[]) => Promise<void>,
    retries = 10,
    timeBetweenRetries = 5000
  ) {
    this.fileData = fileData;
    this.retries = retries;
    this.timeBetweenRetries = timeBetweenRetries;
    this.getUrlsCallback = getUrlsCallback;
    this.endMultipartUploadCallBack = endMultipartUploadCallBack;
  }

  async createCallbacks(
    onProgress = (e: number) => {},
    onFinished = (status: boolean) => {}
  ): Promise<UploadCallbacks> {
    const urlsData = await this.getUrlsCallback();
    if (urlsData.uploadId === undefined) {
      return this.handleSinglePartUpload(urlsData.urls, onProgress, onFinished);
    }
    return this.handleMultipartUpload(urlsData.urls, urlsData.uploadId, onProgress, onFinished);
  }

  handleSinglePartUpload(
    urls: string[],
    onProgress: (e: number) => void,
    onFinished: (status: boolean) => void
  ): UploadCallbacks {
    if (urls.length !== 1) {
      throw new Error(`Single part upload should have one url, instead got ${urls.length}`);
    }

    const uploadStatus = { successful: true };
    const uploadCallback = async () => {
      const url = urls[0];
      const formData = new FormData();
      formData.append("file", this.fileData);
      const putPromise = axios.put(url, this.fileData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (e) => {
          onProgress(Math.round((e.loaded * 100) / e.total));
        },
      });

      runWithRetries(putPromise, {
        maxRetries: this.retries,
        waitBeforeRetryMilliseconds: this.timeBetweenRetries,
        failureError: new Error("Failed to write file to s3"),
      }).catch((error) => {
        uploadStatus.successful = false;
        throw error;
      });
    };

    return {
      uploadCallbacks: [uploadCallback],
      cleanupCallback: async () => {
        onFinished(uploadStatus.successful);
      },
    };
  }

  handleMultipartUpload(
    urls: string[],
    uploadId: string,
    onProgress: (e: number) => void,
    onFinished: (status: boolean) => void
  ) {
    const fileParts = this.splitFile(urls.length);
    const uploadSync = new MultipartFileUploadStateSync(this.fileData.size);
    const uploadCallbacks = [];
    for (let i = 0; i < fileParts.length; i++) {
      const filePart = fileParts[i];
      const url = urls[i];
      uploadCallbacks.push(async () => {
        const putPromise = axios.put(url, filePart, {
          onUploadProgress: (e) => {
            uploadSync.uploadedSizeDict[i] = e.loaded;
            onProgress(Math.round((uploadSync.uploadSize * 100) / uploadSync.fileSize));
          },
        });

        // I don't find the promise mechanism intuitive, but with js more strict scope checking I don't
        // want to try... catch.. the error and put it after all the handling, or use `let` for the response.
        const resp = await runWithRetries(putPromise, {
          maxRetries: this.retries,
          waitBeforeRetryMilliseconds: this.timeBetweenRetries,
          failureError: new Error("Failed to write file to s3"),
        }).catch((error) => {
          uploadSync.success = false;
          throw error;
        });

        uploadSync.uploadParts.push({ ETag: resp.headers["etag"], PartNumber: i + 1 });
      });
    }
    const cleanupCallback = async () => {
      if (uploadSync.success) {
        try {
          await this.endMultipartUploadCallBack(
            uploadId,
            uploadSync.uploadParts.sort((a, b) => a.PartNumber - b.PartNumber)
          );
          onFinished(true);
        } catch (e) {
          onFinished(false);
          console.log(`Got error while trying to end multipart upload ${e}`);
        }
      } else {
        onFinished(false);
      }
    };

    return {
      uploadCallbacks,
      cleanupCallback,
    };
  }

  splitFile(numberOfParts: number) {
    let currentPointer = 0;
    const partSize = Math.ceil(this.fileData.size / numberOfParts);
    let chunks = [];
    for (let index = 0; index < numberOfParts; index++) {
      const newStartPointer = currentPointer + partSize;
      chunks.push(this.fileData.slice(currentPointer, newStartPointer));
      currentPointer = newStartPointer;
    }
    return chunks;
  }
}

class MultipartFileUploadStateSync {
  fileSize: number;
  uploadedSizeDict: Dict<number>;
  success: boolean;
  private _uploadParts: UploadPart[];
  constructor(fileSize: number) {
    this.fileSize = fileSize;
    this.uploadedSizeDict = {};
    this.success = true;
    this._uploadParts = [];
  }

  get uploadParts() {
    return this._uploadParts;
  }

  get uploadSize() {
    return Object.values(this.uploadedSizeDict).reduce((prev, current) => prev + current);
  }
}

export interface UrlResponseData {
  urls: string[];
  uploadId?: string;
}

export interface UploadPart {
  ETag: string;
  PartNumber: number;
}

export interface UploadCallbacks {
  uploadCallbacks: (() => Promise<void>)[];
  cleanupCallback?: () => Promise<void>;
}

export default CallbackPresignedURLUploader;
