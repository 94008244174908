import { Stack } from '@mui/material';
import { VideoShow } from './VideoShow';
import { ImageShow } from './ImageShow';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../../Generated/ExoDBAPI';
import { getRequestParams } from '../../../../../Utils/azureAuth';
import { MediaSpinner } from './Loader';
import { Typography } from '../../../../../shared';
import { poiBucketName, poiBucketPath } from '../../../UploadFiles/layerUploaders/mediaUtils';

type MediaProps = {
  fileNames: string[];
  siteId: string;
  folderName: string;
};

async function getMedia(siteId: string, layerName: string, fileName: string) {
  try {
    const api = new Api();
    const getPresignedData = await api.s3.getGetPresignedUrl(
      {
        bucket: poiBucketName,
        file: poiBucketPath(siteId, layerName, fileName)
      },
      await getRequestParams()
    );

    return axios.get(getPresignedData.data.url, { responseType: 'blob' });
  } catch (error) {
    console.error('Error getting media file:', error);
  }
}

export const Media = ({ fileNames, siteId, folderName }: MediaProps) => {
  const [fileShow, setFileShow] = useState<string[]>([]);
  const [fileVideoShow, setFileVideoShow] = useState<string[]>([]);
  const [imagesLoading, setIsImagesLoading] = useState(0);
  const [videosLoading, setIsVideoLoading] = useState(0);

  const [isError, setIsError] = useState(false);

  const isLoading = +(imagesLoading + videosLoading);

  const reset = () => {
    setFileShow([]);
    setFileVideoShow([]);
  };

  useEffect(() => {
    reset();
    if (fileNames && fileNames.length > 0) {
      const promises = fileNames.map(async (fileName: string) => {
        if (!fileName || fileName === undefined) return;
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        if (fileExtension !== 'jpg' && fileExtension !== 'png' && fileExtension !== 'jpeg') return;
        const mediaFile = (await getMedia(siteId, folderName, fileName)) as AxiosResponse<any, any>;
        return URL.createObjectURL(mediaFile.data);
      });

      setIsImagesLoading(promises.length);
      Promise.all(promises)
        .then((imageUrls) => {
          const filteredImageUrls = imageUrls.filter(Boolean) as string[];
          setFileShow(filteredImageUrls);
          setIsImagesLoading(0);
        })
        .catch((err) => {
          setIsImagesLoading(0);
          setIsError(err);
        });
    } else {
      setFileShow([]);
    }

    if (fileNames && fileNames.length > 0) {
      const promises = fileNames.map(async (fileName: string) => {
        if (!fileName || fileName === undefined) return;
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        if (fileExtension !== 'mp4' && fileExtension !== 'mov') return;
        const mediaFile = (await getMedia(siteId, folderName, fileName)) as AxiosResponse<any, any>;
        return URL.createObjectURL(mediaFile.data);
      });

      setIsVideoLoading(promises.length);
      Promise.all(promises)
        .then((imageUrls) => {
          const filteredImageUrls = imageUrls.filter(Boolean) as string[];
          setFileVideoShow(filteredImageUrls);
          setIsVideoLoading(0);
        })
        .catch((err) => {
          setIsVideoLoading(0);
          setIsError(err);
        });
    } else {
      setFileVideoShow([]);
    }
  }, [fileNames]);

  const renderLoaders = () =>
    Array(videosLoading + imagesLoading)
      .fill(null)
      .map(() => <MediaSpinner />);

  if (!fileNames?.length) return <Typography>No Data</Typography>;
  if (isError) return <>Sorry, Something went wrong</>;
  if (isLoading)
    return (
      <Stack direction="row" spacing={2} mt={1}>
        {renderLoaders()}
      </Stack>
    );

  return (
    <Stack direction="row" spacing={2} mt={1}>
      {fileShow.map((src) => (
        <ImageShow src={src} />
      ))}
      {fileVideoShow.map((src) => (
        <VideoShow src={src} />
      ))}
    </Stack>
  );
};
