import { useState } from 'react';
import { Api, SiteDetails } from '../../../../Generated/ExoDBAPI';
import { getRequestParams } from '../../../../Utils/azureAuth';
import { FileData, FileInputRef } from '../../../Inputs/FileInput';
import UploadMediaFile from './UploadMediaFile';
import axios from 'axios';
import { poiBucketName, poiBucketPath } from './mediaUtils';

function UploadMedia({ plannedSite, layerName, callBack }: UploadMediaProps) {
  const [progress, setProgress] = useState<number>(0);

  function onProgress(progress: number) {
    setProgress(progress);
  }

  async function uploadMediaCallback(
    site: SiteDetails,
    layerName: string,
    fileData: FileData,
    fileInput: FileInputRef
  ) {
    const api = new Api();
    const uploadfile = await api.s3.getPutPresignedUrl(
      {
        bucket: poiBucketName,
        file: poiBucketPath(site.id, layerName, fileData.fileName.replace(/ /g, '_'))
      },
      await getRequestParams()
    );

    try {
      await axios.put(uploadfile.data.url, fileData.data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (e) => {
          onProgress(Math.round((e.loaded * 100) / e.total));
        }
      });
      callBack(layerName, fileData.fileName.replace(/ /g, '_'), true);
    } catch (error) {
      callBack(layerName, fileData.fileName.replace(/ /g, '_'), false);
    }
  }

  return (
    <UploadMediaFile
      plannedSite={plannedSite}
      accept=".jpg, .png, .mp4, .mov"
      percentages={true}
      uploadCallback={uploadMediaCallback}
      layerName={layerName}
      progress={progress}
    />
  );
}

export interface UploadMediaProps {
  plannedSite?: SiteDetails;
  layerName: string;
  folderName?: string;
  callBack: (folder: string, fileName: string, success: boolean) => void;
}

export default UploadMedia;
