import React from "react";
import { useRef } from "react";
import { useEffect } from "react";

function LoadedFile({
  fileName,
  onDeleteClicked,
  status,
  progress,
  percentages = true,
  xButtonAtStart = false,
}: LoadedFileProps) {
  const displayRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (status === undefined) {
      return;
    }
    if (displayRef.current !== null) {
      if (status === true) {
        displayRef.current.classList.add("fileSuccess");
        displayRef.current.classList.remove("fileFailure");
      } else {
        displayRef.current.classList.add("fileFailure");
        displayRef.current.classList.remove("fileSuccess");
      }
    }
  }, [status]);

  const xButton = <img src="/xIcon.png" onClick={onDeleteClicked} className="xButton" alt="Delete button" />

  return (
    <span className="loadedFile" ref={displayRef}>
      {xButtonAtStart && xButton}
      {fileName} {percentages ? `${progress}%` : ""}
      {!xButtonAtStart && xButton}
    </span>
  );
}

export interface LoadedFileProps {
  fileName: string;
  onDeleteClicked: () => void;
  status?: boolean;
  progress: number;
  percentages?: boolean;
  xButtonAtStart?: boolean;
}

export default LoadedFile;
