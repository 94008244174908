import { Button } from '@mui/material';
import { AnalysisProductsSite } from '../../../Generated/ExoDBAPI';
import { AnalysisProductsPipe } from '../DataTypes/MapEntities';
import { MapFeatures } from '../DataTypes/MapFeatures';
import AreYouSureModal from '../../Templates/AreYouSureModal';
import { useState } from 'react';
import { useMapPipeEntitiesStore } from '../store';

function InvalidatePipe({
  analysisSite,
  pipe,
  setEntityValid,
  setDisplayedFeatures
}: InvalidatePipeProps) {
  const { setInvalidatedPipe, setSelectedPipe } = useMapPipeEntitiesStore();
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState<boolean>(false);

  return (
    <>
      <Button
        variant="outlined"
        value="Invalidate"
        className="selectButton"
        disabled={pipe === undefined || analysisSite === undefined}
        onClick={() => {
          if (analysisSite === undefined || pipe === undefined) {
            console.log(
              'Missing needed data, either site or pipe is undefined',
              analysisSite,
              pipe
            );
          } else {
            setDisplayConfirmationModal(true);
          }
        }}>
        Invalidate Pipe
      </Button>
      {analysisSite && pipe?.header.id && (
        <AreYouSureModal
          confirmationTitle="Are you sure you want to Invalidate this pipe?"
          fields={[]}
          open={displayConfirmationModal}
          cancelCallback={() => setDisplayConfirmationModal(false)}
          acceptCallback={() => {
            setSelectedPipe(undefined);
            setInvalidatedPipe(pipe.header.id);
            setEntityValid(analysisSite, pipe?.header.id, pipe?.header.s3Ref, false);

            setDisplayConfirmationModal(false);
          }}
        />
      )}
    </>
  );
}

export interface InvalidatePipeProps {
  analysisSite?: AnalysisProductsSite;
  pipe?: AnalysisProductsPipe;
  setEntityValid: (
    site: AnalysisProductsSite,
    featureId: string,
    s3Ref: string,
    isValid: boolean
  ) => void;
  setDisplayedFeatures: (f: (m: MapFeatures) => MapFeatures) => void;
}

export default InvalidatePipe;
