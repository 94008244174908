import * as React from 'react';
import { useEffect } from 'react';
import { Polyline, useMap } from 'react-leaflet';
import { LatLngLiteral, LatLngTuple, LeafletMouseEvent } from 'leaflet';
import ScalableText from '../ScalableText';
import usePixelsToMeters from '../usePixelsToMeters';
import './LineText.css';
import { getAllPossibleSquareIntersectionPointsInOrder } from './lineHelpers';
import * as turf from '@turf/turf';
import { distance as distanceToPoint } from '@turf/turf';
import { EntityAction } from '../../../Types/MappingEntities';
import { ToolObject } from '../MapControls/MeasurementUtil';

const pathOptions = {
  color: 'black'
};

interface Props {
  text: string;
  textClassName?: string;
  points: LatLngLiteral[];
  setPoints: (points: LatLngLiteral[]) => void;
  updateData: (action: EntityAction, tool: ToolObject) => void;
  data: ToolObject;
}

const FONT_SIZE = 0.1;
const INTERVAL = 1;

function getLines(
  points: LatLngTuple[],
  distance: number,
  text: string,
  textClassName: string,
  handleEventClick: (e: LeafletMouseEvent) => void
): JSX.Element[] {
  const result: JSX.Element[] = [];
  const INTERVAL_distance = INTERVAL / 1000;
  for (let i = 0; i < points.length - 1; i++) {
    const from = points[i];
    const to = points[i + 1];
    const line = turf.lineString([[from[1], from[0]] as number[], [to[1], to[0]] as number[]]);
    let iteration = 1;
    let nextBreakPoint = turf.along(line, INTERVAL_distance);
    let dist = distanceToPoint(nextBreakPoint, turf.point(to as number[]));
    let prevFrom = from;
    while (dist > 0) {
      const newTo = nextBreakPoint.geometry.coordinates.reverse() as LatLngTuple;
      const intersectionPointsInOrder = getAllPossibleSquareIntersectionPointsInOrder(
        prevFrom,
        newTo,
        distance
      );
      if (intersectionPointsInOrder.length === 2) {
        const center: LatLngTuple = [(prevFrom[0] + newTo[0]) / 2, (prevFrom[1] + newTo[1]) / 2];
        result.push(
          <Polyline
            pathOptions={pathOptions}
            positions={[prevFrom, intersectionPointsInOrder[0]]}
            eventHandlers={{
              click: handleEventClick
            }}
          />
        );
        result.push(
          <ScalableText
            text={text}
            position={center}
            className={textClassName}
            interactive={false}
            fontSize={FONT_SIZE}
          />
        );
        result.push(
          <Polyline
            pathOptions={pathOptions}
            positions={[intersectionPointsInOrder[1], newTo]}
            eventHandlers={{
              click: handleEventClick
            }}
          />
        );
      } else {
        result.push(
          <Polyline
            pathOptions={pathOptions}
            positions={[prevFrom, newTo]}
            eventHandlers={{
              click: handleEventClick
            }}
          />
        );
      }
      nextBreakPoint = turf.along(line, INTERVAL_distance * iteration);

      dist = distanceToPoint(nextBreakPoint, turf.point([to[1], to[0]] as number[]));

      iteration++;
      if (iteration > 10) {
        dist = 0;
      }
      prevFrom = newTo;
    }
    result.push(
      <Polyline
        pathOptions={pathOptions}
        positions={[prevFrom, to]}
        eventHandlers={{
          click: handleEventClick
        }}
      />
    );
  }
  return result;
}
const lineTextClassName = 'line-text';

function LineText(props: Props) {
  const { text, points, textClassName, setPoints, data, updateData } = props;
  const map = useMap();
  const { setPixels, distance } = usePixelsToMeters();
  const [startPoint, setStartPoint] = React.useState<L.LatLngLiteral>();
  const [endPoint, setEndPoint] = React.useState<L.LatLngLiteral>();
  const [finishDraw, setFinishDraw] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (points.length > 1) {
      setStartPoint(points[0]);
      setEndPoint(points[points.length - 1]);
    } else {
      map.once('click', handleStartDraw);
    }
  }, []);

  const handleStartDraw = React.useMemo(
    () => (e: L.LeafletMouseEvent) => {
      if (e?.latlng) {
        if (points.length === 0) {
          points.push(e.latlng);
        }
        setStartPoint(e.latlng);
        map.once('click', handleEndDraw);
        map.on('mousemove', handleMouseMove);
      }
    },
    [setStartPoint]
  );

  const handleEndDraw = (e: L.LeafletMouseEvent) => {
    if (e?.latlng) {
      points.push(e.latlng);
      setEndPoint(e.latlng);
      setFinishDraw(true);
      map.off('mousemove', handleMouseMove);
    }
  };

  const handleMouseMove = React.useMemo(
    () => (e: L.LeafletMouseEvent) => {
      setEndPoint(e.latlng);
    },
    [setEndPoint]
  );

  const handleEventClick = React.useCallback(
    (e: LeafletMouseEvent) => {
      if (e.originalEvent.ctrlKey) {
        map.removeLayer(e.target);
        if (data?.id) {
          updateData(EntityAction.DELETE, data);
          setDisabled(true);
        }
      }
    },
    [map, data, updateData, setDisabled]
  );

  const className = `${textClassName} ${lineTextClassName}`;
  const hiddenClassName = `${className} hidden`;

  useEffect(() => {
    const elements = document.getElementsByClassName(
      hiddenClassName
    ) as unknown as HTMLDivElement[];
    const el = elements[0];
    if (el) {
      setPixels(el.offsetWidth);
    }
  }, [map.getZoom()]);

  React.useEffect(() => {
    if (finishDraw && endPoint && startPoint) {
      setPoints([startPoint, endPoint]);
    }
  }, [finishDraw]);

  if (!startPoint || !endPoint) {
    return null;
  }

  if (disabled) return null;

  return (
    <>
      <ScalableText
        text={text}
        position={[startPoint.lat, startPoint.lng]}
        className={hiddenClassName}
        interactive={false}
        fontSize={FONT_SIZE}
      />
      {getLines(
        [
          [startPoint.lat, startPoint.lng],
          [endPoint.lat, endPoint.lng]
        ],
        distance,
        text,
        className,
        handleEventClick
      )}
    </>
  );
}

export default LineText;
