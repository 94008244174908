import { Stack } from '@mui/material';
import { MetaDataRow } from './MetaDataRow';

import { Typography } from '../../../../../shared';
import { POIEntities } from '../../../../../Constant/entity';
import { convertMeasurement } from '../../../../../Utils/mathUtils';
import { measurementUnits } from '../../../../Pages/ManHolesForm';

type MetaDataProps = {
  data: {
    name: string;
    entity: string;
    notes: string;
    owner: string;
    depth: number;
    measurement: string;
    utilities: Array<string>;
  };
};

export const MetaData = ({
  data: { name, notes, owner, depth, measurement, utilities, entity }
}: MetaDataProps) => {
  return (
    <Stack direction="column" spacing={0.5}>
      <MetaDataRow
        title="Name"
        content={<Typography sx={{ wordBreak: 'break-all' }}>{name}</Typography>}
      />
      <MetaDataRow
        title="Entity Type"
        content={
          <Typography sx={{ wordBreak: 'break-all' }}>
            {entity ? entity : POIEntities.Manhole}
          </Typography>
        }
      />
      <MetaDataRow title="Notes" content={notes} />
      <MetaDataRow title="Owner" content={owner} />
      <MetaDataRow title="Utilities" content={<>{utilities && utilities.toString()}</>} />
      <MetaDataRow
        title="Depth"
        content={
          depth && depth !== 999 ? (
            <>
              {measurement === measurementUnits.USA
                ? convertMeasurement(depth, measurement)
                : measurement}
              ({measurement})
            </>
          ) : (
            ''
          )
        }
      />
    </Stack>
  );
};
