import { LatLngExpression, PathOptions } from 'leaflet';
import * as React from 'react';
import { Circle, useMap } from 'react-leaflet';
import EditableCircle from './EditableCircle';

function EditableCircleGroup({
  pathOptions,
  defaultRadius,
  centers,
  addCircle,
  removeCircle,
  changeCircle,
  editable,
  pathOptionsOverrides
}: EditableCircleGroupProps) {
  /*
   The states are used to force rendering of the preview circle.
   However, states current value are not easily accessible from events.
   Since those value are needed there, references to the same value are provided.
  */
  const [active, setActive] = React.useState(false);
  const [previewCenter, setPreviewCenter] = React.useState<LatLngExpression>();
  const keyDownRef = React.useRef<(e: KeyboardEvent) => void>(keyDown);
  const keyUpRef = React.useRef<(e: KeyboardEvent) => void>(keyUp);
  const mouseMoveRef = React.useRef<(e: any) => void>(mouseMove);
  const clickMapRef = React.useRef<() => void>(clickMap);
  const previewCenterRef = React.useRef<{ val: LatLngExpression | undefined }>({ val: undefined });
  const activeRef = React.useRef<{ val: boolean }>({ val: false });
  const addCircleRef = React.useRef<{ val: (coords: LatLngExpression) => void }>({
    val: addCircle
  });
  const removeCircleRef = React.useRef<{ val: (index: number) => void }>({ val: removeCircle });
  const changeCircleRef = React.useRef<{ val: (index: number, coords: LatLngExpression) => void }>({
    val: changeCircle
  });

  const map = useMap();

  React.useEffect(() => {
    activeRef.current.val = active;
  }, [active]);

  React.useEffect(() => {
    previewCenterRef.current.val = previewCenter;
  }, [previewCenter]);

  React.useEffect(() => {
    addCircleRef.current.val = addCircle;
    removeCircleRef.current.val = removeCircle;
    changeCircleRef.current.val = changeCircle;
  }, [addCircle, removeCircle, changeCircle]);

  function keyDown(e: KeyboardEvent) {
    if (e.code === 'KeyT') {
      setActive(true);
    }
  }

  function keyUp(e: KeyboardEvent) {
    if (e.code === 'KeyT') {
      setActive(false);
    }
  }

  function mouseMove(e: any) {
    setPreviewCenter(e.latlng);
  }

  function clickMap() {
    if (activeRef.current.val && previewCenterRef.current.val !== undefined) {
      addCircleRef.current.val(previewCenterRef.current.val);
    }
  }

  React.useEffect(() => {
    if (editable) {
      window.addEventListener('keydown', keyDownRef.current);
      window.addEventListener('keyup', keyUpRef.current);
      map.on('mousemove', mouseMoveRef.current);
      map.on('click', clickMapRef.current);
    } else {
      window.removeEventListener('keydown', keyDownRef.current);
      window.removeEventListener('keyup', keyUpRef.current);
      map.off('mousemove', mouseMoveRef.current);
      map.off('click', clickMapRef.current);
    }
  }, [editable]);

  React.useEffect(() => {
    return () => {
      window.removeEventListener('keydown', keyDownRef.current);
      window.removeEventListener('keyup', keyUpRef.current);
      map.off('mousemove', mouseMoveRef.current);
      map.removeEventListener('click', clickMapRef.current);
    };
  }, []);

  return (
    <>
      {editable &&
        centers.map((c, i) => (
          <EditableCircle
            center={c}
            radius={defaultRadius}
            pathOptions={{ ...pathOptions, ...pathOptionsOverrides?.at(i) }}
            setCenter={(c) => changeCircleRef.current.val(i, c)}
            editable={true}
          />
        ))}
      {!editable &&
        centers.map((c, i) => (
          <Circle
            center={c}
            radius={defaultRadius}
            pathOptions={{ ...pathOptions, ...pathOptionsOverrides?.at(i) }}
          />
        ))}
      {active && previewCenter !== undefined && (
        <Circle
          center={previewCenter}
          radius={defaultRadius}
          pathOptions={{ ...pathOptions, opacity: 0.2 }}
        />
      )}
    </>
  );
}

export interface EditableCircleGroupProps {
  defaultRadius: number;
  pathOptions?: PathOptions;
  pathOptionsOverrides?: (PathOptions | undefined)[];
  centers: LatLngExpression[];
  addCircle: (coords: LatLngExpression) => void;
  removeCircle: (index: number) => void;
  changeCircle: (index: number, coords: LatLngExpression) => void;
  editable: boolean;
}

export default EditableCircleGroup;
