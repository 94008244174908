import { LatLngExpression } from 'leaflet';
import { ScanDetails } from '../../DataTypes/MapEntities';

export const findCenter = (latlngs: LatLngExpression[]): LatLngExpression => {
  if (latlngs.length === 0) {
    throw new Error('No latlngs provided');
  }

  let sumLat = 0;
  let sumLng = 0;

  latlngs.forEach((latlng) => {
    if (Array.isArray(latlng)) {
      sumLat += latlng[0];
      sumLng += latlng[1];
    } else {
      sumLat += latlng.lat;
      sumLng += latlng.lng;
    }
  });

  const centerLat = sumLat / latlngs.length;
  const centerLng = sumLng / latlngs.length;

  return [centerLat, centerLng];
};

export type GroupedScansFull = {
  [key: string]: ScanDetails[];
};

export const getGroupedScansFull = (selectedScans: ScanDetails[]) =>
  selectedScans.reduce((acc: GroupedScansFull, scan) => {
    if (scan.polygon !== undefined && scan.id !== undefined) {
      if (!acc[scan.polygon]) {
        acc[scan.polygon] = [];
      }
      acc[scan.polygon].push(scan);
    }
    return acc;
  }, {} as GroupedScansFull);
