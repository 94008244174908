import React, { useEffect, useRef, useState } from 'react';
import { Card, CircularProgress, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { getServicesRequestParams } from '../../Utils/azureAuth';
import { Api } from '../../Generated/ExoServicesAPI';
import Plot from 'react-plotly.js';
import { useGetScanTransimtterSetupsLazyQuery } from '../../Generated/Graphql';
import { Data } from 'plotly.js';

export type DataPoint = { x: number; y: number };
export type DataPoints = DataPoint[];

const channelColors = ['#1E77B4', 'purple', '##6e304b', 'yellowgreen', , 'red', 'green', 'orange'];

async function fetchJson(url: string) {
  try {
    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching JSON:', error);
    return null;
  }
}

interface PSDViewGraphProps {
  scanId: string | undefined;
}

function PSDViewGraph({ scanId = '' }: PSDViewGraphProps) {
  const [loading, setLoading] = React.useState(false);
  const [dataArray, setDataArray] = useState<Data[]>([]);
  const [frequency, setFrequency] = useState<Data[]>([]);

  const highestValue = useRef(Number.NEGATIVE_INFINITY);
  const lowestValue = useRef(Number.POSITIVE_INFINITY);

  const [getScanTransmittersSetups] = useGetScanTransimtterSetupsLazyQuery();

  useEffect(() => {
    if (!dataArray.length) return;
    getScanTransmittersSetups({ variables: { id: scanId } }).then((res) => {
      const items = res?.data?.searchScans?.items;
      if (!items?.length) return;
      const x: any[] = [],
        y: any[] = [];
      items[0]?.transmitter_setups?.items.forEach((v, i) => {
        if (v?.frequency === undefined) return;
        x.push(v.frequency, v.frequency, null);
        y.push(lowestValue.current, highestValue.current, null);
      });
      setFrequency([
        {
          x,
          y,
          line: {
            color: 'rgba(255, 0, 0, 0.2)',
            width: 23
          },
          mode: 'lines',
          name: 'transmitter_setups',
          type: 'scatter'
        }
      ]);
    });
  }, [dataArray]);

  async function loadPsd(scanId: string | null = null) {
    if (!scanId || scanId === undefined || scanId === '') {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const api = new Api();
      const response = await api.electromagnetic.getScanPsd(
        { scanId: scanId },
        await getServicesRequestParams()
      );

      if (response.status !== 200) {
        alert('Error loading PSD data');
        setLoading(false);
        return;
      }
      const data = response.data;
      const urls = data.urls;

      for (let i = 0; i < urls.length; i++) {
        const currentColor = channelColors[i] ? channelColors[i] : channelColors[0];
        const url = urls[i];
        await fetchJson(url)
          .then((data) => {
            if (data?.frequencies?.[0]?.values?.length) {
              const x: number[] = [],
                y: number[] = [];

              data.frequencies[0].values.forEach((v: number, i: number) => {
                if (v > highestValue.current) highestValue.current = v;
                if (lowestValue.current > v) lowestValue.current = v;
                x.push(i);
                y.push(v);
              });

              setDataArray((prevDataArray) => [
                ...prevDataArray,
                {
                  x,
                  y,
                  mode: 'lines',
                  name: data.channel,
                  line: {
                    color: currentColor
                  }
                }
              ]);
            }
          })
          .catch((error) => {
            console.error('Error loading PSD data', error);
            setLoading(false);
          });
      }
    } catch (error) {
      console.error('Error loading PSD data', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    setDataArray([]);
    const fetchData = async () => {
      await loadPsd(scanId);
      setLoading(false);
    };
    fetchData();
  }, [scanId]);

  if (loading) {
    return (
      <Card
        component={Stack}
        flex={1}
        justifyContent="center"
        alignItems="center"
        direction="row"
        sx={{
          m: 1,
          p: 1
        }}>
        <CircularProgress />
      </Card>
    );
  }

  if (!dataArray || dataArray.length === 0) {
    return <div>No data</div>;
  }

  return (
    <>
      <Card
        component={Stack}
        flex={1}
        sx={{
          m: 1,
          p: 1
        }}>
        <Typography variant="h5">PSD View</Typography>
        <hr />
        <Plot
          data={[...dataArray, ...frequency]}
          layout={{
            hovermode: 'x',
            xaxis: {
              title: 'f [Hz]'
            },
            yaxis: {
              title: 'A [dB(amp) v^2]'
            },
            autosize: true,
            legend: {
              orientation: 'v',
              x: 1,
              xanchor: 'right',
              yanchor: 'bottom'
            }
          }}
          config={{
            responsive: true
          }}
          style={{
            width: '100%',
            height: '100%'
          }}
        />
      </Card>
    </>
  );
}

export default PSDViewGraph;
