import { Alert, Autocomplete, Button, Modal, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import ModalBox from '../../Templates/ModalBox';
import { TransmitterMethod } from '../../../Generated/ExoDBAPI';

const MODAL_WIDTH = 600;
const transmitterMethodOptions: TransmitterMethod[] = [];
Object.values(TransmitterMethod).forEach((method) => {
  if (!transmitterMethodOptions.includes(method)) {
    transmitterMethodOptions.push(method);
  }
});

function CreateTransmitterModal({
  polygon,
  reportSave,
  reportCancel,
  defaultName,
  defaultComment,
  defaultMethod
}: CreateTransmitterModalProps) {
  const [name, setName] = React.useState(defaultName ?? '');
  const [comment, setComment] = React.useState(defaultComment ?? '');
  const [chosenMethod, setChosenMethod] = React.useState<string>(defaultMethod ?? '');
  const [error, setError] = React.useState<string>();
  function validateInput() {
    if (name === '') {
      setError('Enter a name');
      return;
    }
    reportSave(name, comment, chosenMethod);
  }

  React.useEffect(() => {
    setError(undefined);
  }, [name]);

  return (
    <Modal open={true}>
      <ModalBox>
        <Stack gap={2} sx={{ width: MODAL_WIDTH }}>
          <Typography variant="h4">Planned Transmitter</Typography>
          {polygon !== undefined && <TextField label="Polygon" value={polygon} disabled={true} />}
          <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} />
          <Autocomplete
            options={transmitterMethodOptions}
            placeholder="Transmitter method"
            renderInput={(params) => (
              <TextField {...params} key={`option-${params.id}`} placeholder="Transmitter method" />
            )}
            value={chosenMethod}
            onChange={(e, newVal) => {
              setChosenMethod(newVal || ('' as TransmitterMethod));
            }}
            sx={{ maxWidth: MODAL_WIDTH }}
          />

          <TextField
            label="Comment"
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            multiline
          />
          {error !== undefined && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Stack direction="row">
            <Button variant="outlined" onClick={validateInput}>
              Save
            </Button>
            <Button variant="outlined" sx={{ marginLeft: 'auto' }} onClick={reportCancel}>
              Discard
            </Button>
          </Stack>
        </Stack>
      </ModalBox>
    </Modal>
  );
}

export interface CreateTransmitterModalProps {
  polygon?: string;
  reportSave: (name: string, comment: string, chosenMethod: string) => void;
  reportCancel: () => void;
  defaultName?: string;
  defaultComment?: string;
  defaultMethod?: string;
}

export default CreateTransmitterModal;
