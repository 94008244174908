import * as React from "react";
import { Dict, EnumDictionary } from "../../BasicTypes";
import { Stack } from "@mui/material";
import StageStatusFilter from "./StageStatusFilter";
import { RUN_STATUS_ORDER, ScanStages } from "./MonitorTypes";
import StageMonitorPresets from "./StageMonitorsPresets";

function MonitorStatusFilters({ reportFilterChanged }: MonitorStatusFiltersProps) {
  const [overrideCheckState, setOverrideCheckState] = React.useState<EnumDictionary<ScanStages, Dict<boolean>>>(
    Object.fromEntries(
      Object.values(ScanStages).map((stage) => [
        stage,
        Object.fromEntries(RUN_STATUS_ORDER.map((status) => [status, true])) as Dict<boolean>,
      ])
    ) as EnumDictionary<ScanStages, Dict<boolean>>
  );
  return (
    <Stack direction="row" gap={"1rem"}>
      {Object.values(ScanStages).map((stage) => (
        <StageStatusFilter
          stage={stage}
          reportStatusChange={(statuses) => reportFilterChanged(stage, statuses)}
          overrideCheckState={overrideCheckState[stage]}
        />
      ))}
      <StageMonitorPresets setOverridePresets={setOverrideCheckState} />
    </Stack>
  );
}

export interface MonitorStatusFiltersProps {
  reportFilterChanged: (stage: ScanStages, statuses: Dict<boolean>) => void;
}

export default MonitorStatusFilters;
