import React from "react";
import { AnalysisProductsSource, Api, SiteDetails, UserLayer, UserLayerShape } from "../../../Generated/ExoDBAPI";
import { getRequestParams } from "../../../Utils/azureAuth";

import UserContext from "../../Contexts/UserContext";
import {
  DrawnContext,
  getSourceNameForDrawnLayer,
  ShapeDrawningState,
  UserLayerDisplayMode,
  createHoverModeSet,
} from "./DrawnFileData";
import { MapFeaturesStatus } from "../DataTypes/MapFeatures";
import { parseInt } from "lodash";
import { Button } from "@mui/material";

const SUCCESS_STATUS = "Ready";

interface UploadShapeData {
  source?: AnalysisProductsSource;
  site: SiteDetails;
  layer: UserLayer;
}

function CommitDrawnLayerForm({
  site,
  source,
  drawnContext,
  mapFeatures,
  reportUploadFailure,
}: CommitDrawnLayerFormProps) {
  const [enabled, setEnabled] = React.useState<boolean>(false);
  const [lastBadEpsgsSite, setLastBadEpsgsSite] = React.useState<SiteDetails>();
  const [uploadStatus, setUploadStatus] = React.useState<{ message: string; color: string }>({
    message: "start",
    color: "black",
  });
  const [layerToUpload, setLayerToUpload] = React.useState<UploadShapeData | undefined>(undefined);

  const { user } = React.useContext(UserContext);
  const nameRef = React.useRef<HTMLInputElement>(null);
  const epsgSelectRef = React.useRef<HTMLSelectElement>(null);

  React.useEffect(() => {
    let clearMessage: string | undefined = undefined;
    if (drawnContext.currentState === ShapeDrawningState.Uninitialized) {
      clearMessage = "Clear intermediate layer after state reset";
    }

    if (clearMessage !== undefined) {
      drawnContext.setAllDrawnShapes([]);
    }
  }, [drawnContext.currentState]);

  React.useEffect(() => {
    if (drawnContext.currentState !== ShapeDrawningState.LayerUploading) {
      return;
    }

    if (user === undefined) {
      return;
    }

    if (layerToUpload === undefined) {
      return;
    }

    const realLayerToUpload = layerToUpload;
    async function uploadLayer() {
      const api = new Api();
      api.sites
        .postUserFeatureLayer(
          realLayerToUpload.site.id,
          {
            layer: realLayerToUpload.layer,
            source: getSourceNameForDrawnLayer(realLayerToUpload.source?.name),
          },
          await getRequestParams()
        )
        .then(() => setUploadStatus({ message: "Success", color: "green" }))
        .catch(() => {
          setUploadStatus({ message: "Failed", color: "red" });
          window.alert("Upload Failed! Ask Software team for help");
          reportUploadFailure(realLayerToUpload.site, realLayerToUpload.layer.shapes);
        })
        .finally(() => {
          drawnContext.setCurrentState((oldState) => {
            if (oldState === ShapeDrawningState.Uninitialized) {
              return oldState;
            }
            return ShapeDrawningState.Clean;
          });
        });
    }
    uploadLayer();
  }, [user, drawnContext.currentState, layerToUpload]);

  function getEpsgValues(mapFeatures: MapFeaturesStatus): string[] {
    if (!mapFeatures.doneLoading) {
      return [];
    }
    const epsgs: string[] = [];
    const allFeatures = [
      mapFeatures.features.pipes,
      mapFeatures.features.transmitters,
      mapFeatures.features.travelCourses,
      mapFeatures.features.polygons,
    ];
    for (let featureList of allFeatures) {
      featureList.forEach((feature) => {
        const originalEpsg = feature.header.originalEpsgCode.toString();
        if (!epsgs.includes(originalEpsg)) {
          epsgs.push(originalEpsg);
        }
      });
    }
    if (epsgs.length == 1) {
      // The site is being cleared, since we want to re-display the alert if the bad site is displayed again.
      if (lastBadEpsgsSite !== undefined) {
        // The `if` is needed to avoid repeated re-renders.
        setLastBadEpsgsSite(undefined);
      }
      return epsgs;
    }
    if (lastBadEpsgsSite?.id !== site?.id) {
      window.alert(`Expected exactly one coordinates systems, got EPSGs: ${epsgs}`);
      setLastBadEpsgsSite(site);
    }
    return [""].concat(epsgs);
  }

  function commitLayer() {
    if (nameRef.current === null) {
      throw Error("Name missing - how was this enabled?");
    }

    if (site === undefined) {
      throw Error("Site missing - how was this enabled?");
    }

    if (epsgSelectRef.current === null || epsgSelectRef.current.value === "") {
      throw Error("No EPSG selected");
    }

    const newLayer = {
      name: nameRef.current.value,
      shapes: drawnContext.allDrawnShapes,
      wantedEpsgCode: parseInt(epsgSelectRef.current.value),
    };

    drawnContext.setSavedLayers((layers) => {
      return layers.concat([newLayer]);
    });
    setLayerToUpload({ source: source, site: site, layer: newLayer });
    drawnContext.setAllDrawnShapes([]);
    nameRef.current.value = "";
    setUploadStatus({ message: "Uploading", color: "black" });
    drawnContext.setCurrentState(ShapeDrawningState.LayerUploading);
  }

  function getStatus(): string {
    if (site === undefined) {
      return "Site not set";
    }

    if (drawnContext.currentState !== ShapeDrawningState.Clean) {
      return `Bad State: not clean [${drawnContext.currentState.toString()}]`;
    }

    if (drawnContext.allDrawnShapes.length <= 0) {
      return "No shapes were drawn";
    }

    if (nameRef.current === null || nameRef.current.value.length === 0) {
      return "Missing layer name";
    }

    if (epsgSelectRef.current === null || epsgSelectRef.current.value === "") {
      return "No EPSG";
    }

    return SUCCESS_STATUS;
  }

  function updateEnabled() {
    const newEnabled = getStatus() === SUCCESS_STATUS;
    if (newEnabled !== enabled) {
      setEnabled(newEnabled);
    }
  }

  updateEnabled();
  return (
    <form>
      <span className="exofuserFormEntry" key="exofuserDrawnCommitLayerSiteName">
        <label htmlFor="exofuser.drawn.commit.site_name">Site Name</label>
        <input
          disabled={true}
          id="exofuser.drawn.commit.site_name"
          name="exofuser.drawn.commit.site_name"
          value={site?.name ?? ""}
        />
      </span>
      <span className="exofuserFormEntry" key="exofuserDrawnCommitLayerName">
        <label htmlFor="exofuser.drawn.layer.name">Layer Name</label>
        <input
          id="exofuser.drawn.commit.name"
          name="exofuser.drawn.commit.name"
          ref={nameRef}
          onChange={updateEnabled}
        />
      </span>
      <span className="exofuserFormEntry" key="exofuserDrawnCommitLayerEpsg">
        <label htmlFor="exofuser.drawn.commit.epsg">Choose Epsg</label>
        <select
          id="exofuser.drawn.commit.epsg"
          name="exofuser.drawn.commit.epsg"
          ref={epsgSelectRef}
          onChange={updateEnabled}
        >
          {getEpsgValues(mapFeatures).map((value) => {
            return (
              <option value={value} key={`exofuser.drawn.commit.epsg.${value}`}>
                {value === "" ? "" : `EPSG: ${value}`}
              </option>
            );
          })}
        </select>
      </span>
      <span className="exofuserFormEntry" key="exofuserDrawnCommitLayerStatus">
        <label htmlFor="exofuser.drawn.commit.status">Status</label>
        <input
          disabled={true}
          id="exofuser.drawn.commit.status"
          name="exofuser.drawn.commit.status"
          style={enabled ? { color: "black" } : { color: "red" }}
          value={getStatus()}
        />
      </span>
      <span className="formSection" key="submitButton">
        <div
          style={{ width: "fit-content" }}
          {...createHoverModeSet(enabled, UserLayerDisplayMode.Commit, drawnContext)}
        >
          <Button variant="contained" disabled={!enabled} onClick={commitLayer}>
            Commit Layer
          </Button>
        </div>
      </span>
      <span className="exofuserFormEntry" key="exofuserDrawnCommitLayerUploadStatus">
        <label htmlFor="exofuser.drawn.commit.upload_status">Upload Status</label>
        <input
          disabled={true}
          id="exofuser.drawn.commit.upload_status"
          name="exofuser.drawn.commit.upload_status"
          style={{ color: uploadStatus.color }}
          value={uploadStatus.message}
        />
      </span>
    </form>
  );
}

export interface CommitDrawnLayerFormProps {
  site: SiteDetails | undefined;
  source: AnalysisProductsSource | undefined;
  drawnContext: DrawnContext;
  mapFeatures: MapFeaturesStatus;
  reportUploadFailure: (plannedSite: SiteDetails, features: UserLayerShape[]) => void;
}

export default CommitDrawnLayerForm;
