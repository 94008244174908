import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  RadioGroup,
  Radio
} from '@mui/material';
import './ManHolesForm.css';
import { TargetObject } from '../../Types/MappingEntities';
import ManHolePipe from './ManHolePipe';
import { SiteDetails } from '../../Generated/ExoDBAPI';
import UploadMedia from '../ExoFuser/UploadFiles/layerUploaders/UploadMedia';
import LocalStorageService from '../../Utils/storage';
import {
  CART_HEIGHT,
  manholeCoverMaterials,
  manholeCoverShapes,
  ManholeOpenedStatus,
  POIEntities,
  TargetCompanyList,
  TargetOtherCompany,
  TargetUtilityTypes
} from '../../Constant/entity';
import {
  useCreatePoiMutation,
  useCreateManholeMutation,
  useCreatePipeMutation
} from '../../Generated/Graphql';

import { convertMeasurement } from '../../Utils/mathUtils';
interface PipeSvgProps {
  color?: string;
  onClick?: () => void;
  showPipe?: boolean;
}

const PipeSvg: React.FC<PipeSvgProps> = ({ onClick, showPipe, color = 'rgb(44, 169, 188)' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    version="1.1"
    onClick={onClick}>
    <g id="surface1">
      <path
        style={{ stroke: 'none', fillRule: 'nonzero', fill: color, fillOpacity: 1 }}
        d="M 66.667969 83.332031 L 50 83.332031 C 40.796875 83.332031 33.332031 75.871094 33.332031 66.667969 L 33.332031 45.832031 L 12.5 45.832031 L 12.5 29.167969 L 33.332031 29.167969 C 42.539062 29.167969 50 36.628906 50 45.832031 L 50 66.667969 L 66.667969 66.667969 L 66.667969 16.667969 L 83.332031 16.667969 L 83.332031 66.667969 C 83.332031 75.871094 75.871094 83.332031 66.667969 83.332031 Z M 66.667969 83.332031 "
      />
      <path
        style={{
          fill: 'none',
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          stroke: 'rgb(0%,0%,0%)',
          strokeOpacity: showPipe ? 1 : 0.1,
          strokeMiterlimit: 4
        }}
        d="M 16.000312 19.999688 L 12 19.999688 C 9.79125 19.999688 7.999687 18.209062 7.999687 16.000312 L 7.999687 10.999688 L 3 10.999688 L 3 7.000312 L 7.999687 7.000312 C 10.209375 7.000312 12 8.790938 12 10.999688 L 12 16.000312 L 16.000312 16.000312 L 16.000312 4.000312 L 19.999688 4.000312 L 19.999688 16.000312 C 19.999688 18.209062 18.209062 19.999688 16.000312 19.999688 Z M 3 12 L 3 6 M 15 4.000312 L 21 4.000312 "
        transform="matrix(4.166667,0,0,4.166667,0,0)"
      />
    </g>
  </svg>
);
export type PipeItem = {
  id?: number;
  picture: string;
  isPipe: string;
  no_cables: string;
  hive: string;
  description: string;
  media?: string;
  azimuth: number | null;
  invert_level: number | null;
  top_level: number | null;
  diameter: number | null;
  thickness: number | null;
  flowDirection?: string;
};

export type FormData = {
  scanName: string;
  manholeName: string;
  utilityType: string[];
  company: string;
  notes: string;
  depth: number;
  gps_height: number;
  overallNumber: number;
  otherCompanyName: string;
  pipes: PipeItem[];
  fileNames: string[];
  folderName: string;
  siteId: string;
  scanId: string;
  Measurement: string;
  manholeCoverShape: string;
  manholeCoverDiameter: number;
  manholeCoverMaterial?: string;
  pipeDiscoveryStatus: string;
};

type ManholeProps = {
  targetObject: TargetObject;
  scanId: string;
  country: string;
  scanName: string;
  siteName: string;
  site: SiteDetails;
  coordinates: [number, number] | null;
  updateTargetInfo: (targetInfo: FormData) => void;
};

export const emptyPipe: PipeItem = {
  picture: '',
  isPipe: '',
  no_cables: '0',
  hive: '1',
  description: '',
  azimuth: null,
  top_level: null,
  invert_level: null,
  diameter: null,
  thickness: null
};
export const COMPANIESKEY = 'COMPANIES';
export enum PipeExist {
  NO = 'no',
  YES = 'yes'
}

interface Measurement {
  [key: string]: string;
}

export const measurementUnits: Measurement = { ISRAEL: 'cm', USA: 'inch' };
const picture_width = 277;
const picture_width_max = 360;
const picture_width_tumbnail = 80;

const updateLocalData = (key: string, data: any) => {
  const storedData = JSON.parse(LocalStorageService.loadData(key, '{}' as string));
  LocalStorageService.saveData(key, JSON.stringify({ ...storedData, ...data }));
};

const manholeOpenedOptions: ManholeOpenedStatus[] = [];
Object.values(ManholeOpenedStatus).forEach((status) => {
  if (!manholeOpenedOptions.includes(status)) {
    manholeOpenedOptions.push(status);
  }
});
const TargetUtilityOptions: TargetUtilityTypes[] = [];
Object.values(TargetUtilityTypes).forEach((utility) => {
  if (!TargetUtilityOptions.includes(utility)) {
    TargetUtilityOptions.push(utility);
  }
});

const ManHoleForm = ({
  targetObject,
  scanId,
  scanName,
  siteName,
  site,
  country,
  coordinates,
  updateTargetInfo
}: ManholeProps) => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();
  const [selected, setSelected] = useState<string[]>([]);
  const overallNumber = watch('overallNumber', 1);
  const [selectedPipes, setSelectedPipes] = useState<PipeItem[]>([]);
  const [showPipe, setShowPipe] = useState<number | null>();
  const [filesLoaded, setFilesLoaded] = useState<string[]>([]);
  const [filesFailed, setFilesFailed] = useState<string[]>([]);
  const [folderName, setFolderName] = useState<string>('');
  const [showImage, setShowImage] = useState(false);
  const [companyList, setCompanyList] = useState<string[]>(
    country && country.toUpperCase()
      ? TargetCompanyList[country.toUpperCase()]
      : (TargetCompanyList['USA'] as string[])
  );
  const [automaticCartHeight, setAutomaticCartHeight] = useState<boolean>(false);
  const [usedMicrobase, setUsedMicrobase] = useState<boolean>(false);
  const [gpsHeightTyped, setGpsHeightTyped] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    scanName: scanName,
    manholeName: targetObject?.name || '',
    utilityType: [],
    company: '',
    notes: '',
    depth: 0,
    gps_height: 0,
    overallNumber: 1,
    otherCompanyName: '',
    pipes: [],
    fileNames: [],
    folderName: '',
    siteId: site.id,
    scanId: scanId,
    manholeCoverShape: '',
    pipeDiscoveryStatus: '',
    manholeCoverDiameter: 0,
    Measurement: measurementUnits[country.toUpperCase()] ?? measurementUnits['USA']
  });
  const [errMessage, setErrMessage] = useState<string>('');
  const [createPoiMutation] = useCreatePoiMutation();
  const [createManholeMutation] = useCreateManholeMutation();
  const [createPipeMutation] = useCreatePipeMutation();
  const keyLocal = `Scan-${scanId}`;
  const fieldsToConvert = [
    'azimuth',
    'invert_level',
    'top_level',
    'diameter',
    'thickness',
    'depth',
    'manholeCoverDiameter'
  ];
  const convertFieldsData = (data: FormData) => {
    const convertedData = { ...data };
    Object.keys(data).forEach((key) => {
      if (fieldsToConvert.includes(key)) {
        const value = data[key as keyof FormData];
        if (value !== undefined && value !== null && value !== 999) {
          convertedData[key as keyof FormData] = convertMeasurement(
            parseInt(value.toString()), // Convert the string to a number using parseInt
            measurementUnits.ISRAEL.toLowerCase() as 'cm'
          ) as never; // Add a type assertion to 'never' to fix the type error
        }
      }
    });
    return convertedData;
  };
  const convertFieldsPipes = (pipe: PipeItem) => {
    const convertedData = { ...pipe };
    Object.keys(pipe).forEach((key) => {
      if (fieldsToConvert.includes(key)) {
        const value = pipe[key as keyof PipeItem];
        if (value !== undefined && value !== null && value !== 999) {
          convertedData[key as keyof PipeItem] = convertMeasurement(
            parseInt(value!.toString()), // Convert the string to a number using parseInt
            measurementUnits.ISRAEL.toLowerCase() as 'cm'
          ) as never; // Add a type assertion to 'never' to fix the type error
        }
      }
    });
    return convertedData;
  };
  useEffect(() => {
    const savedCompanies: string[] = LocalStorageService.loadData(COMPANIESKEY, []);
    setCompanyList([...companyList, ...savedCompanies, ...TargetOtherCompany]);
  }, []);

  useEffect(() => {
    if (selectedPipes.length > overallNumber) {
      setSelectedPipes(selectedPipes.slice(0, overallNumber));
      setFormData({
        ...formData,
        overallNumber: overallNumber,
        pipes: formData.pipes.slice(0, overallNumber)
      });
    } else if (selectedPipes.length < overallNumber) {
      const newPipes = [...selectedPipes];
      while (newPipes.length < overallNumber) {
        newPipes.push(emptyPipe);
      }
      setSelectedPipes(newPipes);
      setFormData({ ...formData, overallNumber: overallNumber, pipes: newPipes });
    }
  }, [overallNumber, selectedPipes]);

  const updateMediaFiles = (folder: string, fileName: string, success: boolean) => {
    if (success) {
      setFilesLoaded([...filesLoaded, fileName]);
      setFormData((prev: FormData) => ({ ...prev, fileNames: [...prev.fileNames, fileName] }));
    } else {
      setFilesFailed([...filesFailed, fileName]);
    }
    setFolderName(folder);
    setFormData((prev: FormData) => ({ ...prev, folderName: folder }));
  };

  const checkValidate = () => {
    const errorMessages: string[] = [];

    if (formData.utilityType.length === 0) {
      setErrMessage('Utility type is required');
      return false;
    }
    if (
      formData.company.length === 0 ||
      (formData.company === 'Other' && !formData.otherCompanyName)
    ) {
      setErrMessage('Company name is required');
      return false;
    }
    if (formData.manholeCoverShape.length === 0) {
      setErrMessage('Manhole cover shape is required');
      return false;
    }
    if (formData.pipeDiscoveryStatus === 'Opened') {
      selectedPipes.forEach((pipe, index) => {
        if (
          (pipe.isPipe === PipeExist.YES &&
            (!pipe.picture ||
              !pipe.azimuth ||
              !pipe.top_level ||
              !pipe.invert_level ||
              !pipe.diameter ||
              !pipe.thickness)) ||
          pipe.isPipe === ''
        ) {
          errorMessages.push(`Pipe ${index + 1} is required`);
        }
      });
    }

    if (errorMessages.length > 0) {
      setErrMessage(errorMessages.join(', '));
      return false;
    }

    if (formData.gps_height <= 0) {
      setErrMessage('GPS monopod height is required');
      return false;
    }

    if (formData.depth <= 0 && formData.pipeDiscoveryStatus === 'Opened') {
      setErrMessage('Manhole depth is required');
      return false;
    }

    setErrMessage('');
    return true;
  };

  const addPOIEntity = async (data: FormData) => {
    const geoData = {
      geometry: { coordinates, type: 'MultiPoint' },
      properties: {},
      type: 'Feature'
    };
    const graphqlResp = await createPoiMutation({
      variables: {
        geoData: JSON.stringify(geoData),
        poiType: POIEntities.Manhole,
        comment: data.notes,
        sitePoisId: site.id,
        scanPoisId: scanId
      }
    });
    if (graphqlResp.errors) {
      window.alert(`Got ${graphqlResp.errors.length} while trying to create poi in graphql`);
      console.error('Got errors', graphqlResp.errors);
    } else {
      return graphqlResp.data?.createPOIEntity?.id;
    }
  };

  const addManhole = async (data: FormData, manholePoiId: string) => {
    const input = {
      ...data,
      depth: data.depth ? data.depth : 999,
      manholePoiId: manholePoiId!,
      gpsHeight: data.gps_height,
      manholeName: data.manholeName,
      owner: data.company,
      utilityTypes: data.utilityType,
      overallNumber: data.overallNumber
    };
    const graphqlResp = await createManholeMutation({
      variables: input
    });
    if (graphqlResp.errors) {
      window.alert(`Got ${graphqlResp.errors.length} while trying to create poi in graphql`);
      console.error('Got errors', graphqlResp.errors);
    } else {
      return graphqlResp.data?.createManholeItem?.id;
    }
  };
  const addPipes = async (manholeItemPipesId: string) => {
    selectedPipes.forEach(async (pipe) => {
      const input = {
        manholeItemPipesId: manholeItemPipesId!,
        picture: pipe.picture,
        isPipe: pipe.isPipe,
        noCables: pipe.no_cables,
        hive: pipe.hive,
        description: pipe.description,
        azimuth: pipe.azimuth!,
        topLevel: pipe.top_level!,
        invertLevel: pipe.invert_level!,
        diameter: pipe.diameter!,
        thickness: pipe.thickness!
      };
      const graphqlResp = await createPipeMutation({
        variables: input
      });
      if (graphqlResp.errors) {
        window.alert(`Got ${graphqlResp.errors.length} while trying to create poi in graphql`);
        console.error('Got errors', graphqlResp.errors);
      }
    });
  };
  const addNewManhole = async (data: FormData) => {
    const poiEntitiyId = await addPOIEntity(data);
    if (poiEntitiyId) {
      const manholeId = await addManhole(data, poiEntitiyId);
      if (manholeId && selectedPipes[0].isPipe === 'yes') {
        addPipes(manholeId);
      } else if (!manholeId) {
        window.alert('Failed to create manhole');
      }
    } else {
      window.alert('Failed to create poi entity');
    }
  };
  const onSubmit = (data: FormData) => {
    let gps_height = automaticCartHeight
      ? CART_HEIGHT
      : formData.Measurement === measurementUnits.USA
      ? convertMeasurement(data.gps_height, measurementUnits.ISRAEL.toLowerCase() as 'cm')
      : data.gps_height;
    gps_height = usedMicrobase && !automaticCartHeight ? gps_height + 20 : gps_height;
    data.gps_height = gps_height;

    if (!checkValidate()) return;
    let newData = data;
    let newPipes = [] as PipeItem[];
    if (selectedPipes[0].isPipe === 'yes') {
      newPipes = [...selectedPipes];
    }

    if (formData.Measurement === measurementUnits.USA) {
      newData = convertFieldsData(data);
      if (newPipes.length > 0) {
        newPipes = [
          ...selectedPipes.map((pipe) => {
            return convertFieldsPipes(pipe);
          })
        ];
      }

      setSelectedPipes([...newPipes]);
    }

    const target_info = {
      ...newData,
      pipes: newPipes,
      fileNames: filesLoaded,
      siteId: site.id,
      folderName: folderName,
      manholeCoverDiameter: newData.manholeCoverDiameter ? newData.manholeCoverDiameter : 999,
      Measurement: formData.Measurement
    };
    addNewManhole(newData);
    updateTargetInfo(target_info);
    if (data.company === 'Other') {
      const companies = LocalStorageService.loadData(COMPANIESKEY) || [];
      if (companies.includes(data.otherCompanyName)) return;
      companies.push(data.otherCompanyName);
      LocalStorageService.saveData(COMPANIESKEY, companies);
    }
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const data = [...selected, event.target.value];
      setSelected(data);
      setFormData({ ...formData, utilityType: data });
      updateLocalData(keyLocal, { utilityType: data });
    } else {
      const data = selected.filter((value) => value !== event.target.value);
      setSelected(data);
      setFormData({ ...formData, utilityType: data });
      updateLocalData(keyLocal, { utilityType: data });
    }
  };

  const handleRadioChange = (value: string, key?: string) => {
    if (!key) {
      setFormData({ ...formData, company: value });
      updateLocalData(keyLocal, { value });
    } else {
      setFormData({ ...formData, [key]: value });
      updateLocalData(keyLocal, { [key]: value });
    }
  };

  const isDisabled = (value: string): boolean => {
    return selected.length >= 3 && !selected.includes(value);
  };

  const updatePipe = (index: number, pipeItem: PipeItem) => {
    const newPipes: PipeItem[] = selectedPipes;
    if (
      newPipes[index].isPipe === pipeItem.isPipe &&
      newPipes[index].picture === pipeItem.picture
    ) {
      newPipes[index] = pipeItem;
      return;
    }
    newPipes[index] = pipeItem;
    setSelectedPipes([...newPipes]);
  };

  const addPipe = () => {
    const newPipes = [...selectedPipes];
    newPipes.push({ ...emptyPipe, isPipe: PipeExist.YES });
    setSelectedPipes(newPipes);
    setShowPipe(newPipes.length - 1);
    setValue('overallNumber', newPipes.length);
    setFormData({ ...formData, overallNumber: newPipes.length, pipes: newPipes });
  };

  const removePipe = (index: number) => {
    const newPipes = [...selectedPipes];
    newPipes.splice(index, 1);
    if (newPipes.length === 0) {
      newPipes.push(emptyPipe);
      setSelectedPipes(newPipes);
      setShowPipe(null);
      setFormData({ ...formData, overallNumber: 1, pipes: newPipes });
      return;
    }

    setSelectedPipes(newPipes);
    setShowPipe(newPipes.length - 1);
    setValue('overallNumber', newPipes.length);
    setFormData({ ...formData, overallNumber: newPipes.length, pipes: newPipes });
  };
  const calculateColor = (index: number): string => {
    if (selectedPipes[index] && selectedPipes[index].isPipe === PipeExist.NO) {
      return 'red';
    } else if (selectedPipes[index] && selectedPipes[index].isPipe === PipeExist.YES) {
      return 'green';
    } else {
      return 'white';
    }
  };
  const handleGpsHeightChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setGpsHeightTyped(true);
    const value = e.target.value;
    const parsedValue = parseFloat(value);
    setFormData({
      ...formData,
      gps_height: isNaN(parsedValue) ? 0 : parsedValue
    });
  };

  const handleAutomaticCartHeightChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAutomaticCartHeight(e.target.checked);
    e.target.checked ?? setUsedMicrobase(false);
    let addedCartHeight = formData.gps_height;
    e.target.checked ? (addedCartHeight = CART_HEIGHT) : (addedCartHeight = 0);
    setFormData({
      ...formData,
      gps_height: addedCartHeight
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'formContainer'}>
      <div className="control">
        <TextField
          label="Site Name"
          className={'control text-input-long'}
          value={siteName}
          variant="outlined"
          disabled
        />
      </div>

      <input
        type="hidden"
        {...register('scanName', { required: true, valueAsNumber: false })}
        value={scanName}
        onChange={(e) => {
          setFormData({ ...formData, scanName: e.target.value });
        }}
      />

      <input
        type="hidden"
        {...register('manholeName', { required: true, valueAsNumber: false })}
        value={targetObject?.name || ''}
        onChange={(e) => {
          setFormData({ ...formData, manholeName: e.target.value });
        }}
      />

      <div className="control">
        <label>Measurement unit</label>
        <div>
          <RadioGroup
            defaultValue={formData.Measurement}
            onChange={(e) => {
              setFormData({ ...formData, Measurement: e.target.value });
            }}>
            <FormControlLabel value={measurementUnits.ISRAEL} control={<Radio />} label="cm" />
            <FormControlLabel value={measurementUnits.USA} control={<Radio />} label="Inch" />
          </RadioGroup>
        </div>
      </div>
      <div className="control">
        <img
          src={`./Assets/Manhole/ground_level.png`}
          key={'ground_level'}
          alt={'ground_level'}
          title={'ground_level'}
          width={showImage ? picture_width : picture_width_tumbnail}
          height={
            showImage
              ? picture_width_max
              : (picture_width_tumbnail / picture_width) * picture_width_max
          }
          className="img_style"
          onClick={() => setShowImage(!showImage)}
        />
      </div>

      <div className="both-text">
        <div className="control">
          <TextField
            label={`GPS monopod height (${formData.Measurement}) *`}
            {...register('gps_height', { required: true, valueAsNumber: true })}
            error={formData.gps_height <= 0 && gpsHeightTyped}
            helperText={formData.gps_height <= 0 && 'GPS monopod height is required'}
            className="control"
            value={formData.gps_height > 0 ? formData.gps_height : ''}
            inputProps={{ inputMode: 'numeric' }}
            disabled={automaticCartHeight}
            onChange={handleGpsHeightChange}
          />
        </div>
        <div className="control">
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={automaticCartHeight}
                    checked={automaticCartHeight}
                    onChange={handleAutomaticCartHeightChange}
                  />
                }
                label={'Automatic Cart Height'}
              />
            </FormGroup>
          </FormControl>
        </div>
        {gpsHeightTyped && formData.gps_height > 0 && !automaticCartHeight && (
          <div className="control">
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={usedMicrobase}
                      checked={usedMicrobase}
                      onChange={(e) => {
                        setUsedMicrobase(e.target.checked);
                      }}
                    />
                  }
                  label={'Used microbase '}
                />
              </FormGroup>
            </FormControl>
          </div>
        )}
      </div>

      <div className="both">
        <FormControl required error={!!errors.utilityType}>
          <FormLabel>Utility type</FormLabel>
          <hr />
          <FormGroup>
            {TargetUtilityOptions.map((type: string) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...register('utilityType', { required: false })}
                    value={type}
                    checked={selected?.includes(type)}
                    onChange={handleCheckboxChange}
                    disabled={isDisabled(type)}
                  />
                }
                label={type}
                key={type}
              />
            ))}
          </FormGroup>
        </FormControl>
        <FormControl required error={!!errors.company}>
          <FormLabel>Company</FormLabel>
          <hr />
          <RadioGroup defaultValue={formData.company}>
            {companyList.map((company: string) => (
              <FormControlLabel
                control={
                  <Radio
                    {...register('company')}
                    value={company}
                    onChange={(e) => {
                      handleRadioChange(company);
                    }}
                  />
                }
                value={company}
                label={company}
                key={company}
              />
            ))}
          </RadioGroup>
          {formData['company'] === 'Other' && (
            <div className="control">
              <TextField
                {...register('otherCompanyName')}
                label="Company Name"
                variant="outlined"
                onChange={(e) => {
                  setFormData({ ...formData, otherCompanyName: e.target.value });
                }}
              />
            </div>
          )}
        </FormControl>
      </div>

      <div className="both">
        <FormControl required error={!!errors.company}>
          <FormLabel>Manhole cover shape</FormLabel>
          <hr />
          <RadioGroup defaultValue={formData.manholeCoverShape}>
            {manholeCoverShapes.map((shape: string) => (
              <FormControlLabel
                control={
                  <Radio
                    {...register('manholeCoverShape')}
                    value={shape}
                    onChange={(e) => {
                      handleRadioChange(shape, 'manholeCoverShape');
                    }}
                  />
                }
                value={shape}
                label={shape}
                key={shape}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Manhole cover material</FormLabel>
          <hr />
          <RadioGroup defaultValue={formData.manholeCoverMaterial}>
            {manholeCoverMaterials.map((material: string) => (
              <FormControlLabel
                control={
                  <Radio
                    {...register('manholeCoverMaterial')}
                    value={material}
                    onChange={(e) => {
                      handleRadioChange(material, 'manholeCoverMaterial');
                    }}
                  />
                }
                value={material}
                label={material}
                key={material}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      <div className="both-text">
        <div className="control">
          <TextField
            label="General Notes and Description"
            {...register('notes', { required: false, valueAsNumber: false })}
            error={!!errors.notes}
            helperText={errors.notes && 'Notes'}
            className="control"
            inputProps={{ style: { padding: '10px', width: '300px' } }}
            onChange={(e) => {
              setFormData({ ...formData, notes: e.target.value });
            }}
          />
          <div className="comments">
            List all potential dangers or peculiar occurrences such
            <br /> as the scent of fuel, presence of gas, or asbestos.
          </div>
        </div>

        <div className="control">
          <TextField
            label={`Manhole cover diameter/oblique (${formData.Measurement})`}
            className="control"
            inputProps={{ inputMode: 'numeric' }}
            {...register('manholeCoverDiameter')}
            onChange={(e) => {
              setFormData({
                ...formData,
                manholeCoverDiameter: parseFloat(e.target.value)
              });
            }}
          />
        </div>
      </div>

      <p className="text">Allowed file types: Image, Video, Audio</p>
      {filesLoaded.map((file) => (
        <p key={file}>{file} uploaded successfully</p>
      ))}

      {filesFailed.map((file) => (
        <p key={file}>{file} failed to upload</p>
      ))}
      <hr />
      <div className="control">
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '250px' }}>
          <UploadMedia
            plannedSite={site}
            layerName={`manhole_${watch('manholeName')}`}
            folderName="poi"
            callBack={updateMediaFiles}
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Array.from({ length: overallNumber }).map((_, i) => (
          <Fragment key={`pips-${i}`}>
            {selectedPipes[i]?.isPipe === PipeExist.NO ? (
              <img
                src="./Assets/Manhole/no_pipe.svg"
                key={i}
                alt="No Pipe"
                width={100}
                height={100}
                style={{ marginRight: '8px', border: showPipe === i ? '4px solid black' : 'none' }}
              />
            ) : selectedPipes[i]?.isPipe === PipeExist.YES && selectedPipes[i]?.picture ? (
              <>
                <img
                  src={`./Assets/Manhole/${selectedPipes[i].picture}.png`}
                  key={i}
                  alt={selectedPipes[i].picture}
                  title={selectedPipes[i].picture}
                  width={100}
                  height={100}
                  style={{
                    marginLeft: '1rem',
                    border: showPipe === i ? '4px solid black' : 'none'
                  }}
                  onClick={() => setShowPipe(i)}
                />
              </>
            ) : (
              formData.pipeDiscoveryStatus === ManholeOpenedStatus.Opened && (
                <PipeSvg
                  key={i}
                  color={calculateColor(i)}
                  onClick={() => setShowPipe(i)}
                  showPipe={showPipe === i}
                />
              )
            )}
          </Fragment>
        ))}
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap' }} className="control">
        <FormControl>
          <FormLabel>Manhole opened status</FormLabel>

          <RadioGroup defaultValue={formData.pipeDiscoveryStatus}>
            {manholeOpenedOptions.map((status: string) => (
              <FormControlLabel
                control={
                  <Radio
                    value={status}
                    onChange={(e) => {
                      if (e.target.value === ManholeOpenedStatus.Opened) {
                        setShowPipe(0);
                      } else {
                        setShowPipe(null);
                      }
                      handleRadioChange(status, 'pipeDiscoveryStatus');
                    }}
                  />
                }
                value={status}
                label={status}
                key={status}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      {formData.pipeDiscoveryStatus === ManholeOpenedStatus.Opened && (
        <div className="control">
          <TextField
            label={`Manhole depth (${formData.Measurement})`}
            {...register('depth', {
              required: formData.pipeDiscoveryStatus === ManholeOpenedStatus.Opened
            })}
            error={!!errors.depth}
            helperText={errors.depth && 'Manhole depth is required'}
            className="control"
            inputProps={{ inputMode: 'numeric' }}
            onChange={(e) => {
              if (Number(e.target.value) >= 0) {
                setFormData({
                  ...formData,
                  depth: parseFloat(e.target.value)
                });
              }
            }}
          />
          <div className="comments">* If can't measure, fill 999</div>
        </div>
      )}
      {showPipe !== undefined && showPipe !== null && (
        <>
          <hr />
          <ManHolePipe
            index={showPipe}
            isUtilitySewerDrain={
              formData.utilityType.includes(TargetUtilityTypes.Sewer) ||
              formData.utilityType.includes(TargetUtilityTypes.Drain)
            }
            updatePipe={updatePipe}
            addPipe={addPipe}
            measurement={formData.Measurement}
            removePipe={removePipe}
            pipe={selectedPipes[showPipe]}
            last={showPipe === (selectedPipes.length > 0 ? selectedPipes.length - 1 : 0)}
            country={country.toUpperCase()}
          />
        </>
      )}

      {errMessage.length > 0 && (
        <pre style={{ color: 'red', fontWeight: 'bolder' }}>{errMessage}</pre>
      )}
      <hr />

      <div className="submit">
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default ManHoleForm;
