import * as React from 'react';
import { GeoJSON, Tooltip } from 'react-leaflet';
import { getTravelCoursePathOptions } from '../../../Utils/mapUtils';
import {
  TooltipPaneName,
  flcTravelCoursePaneName,
  travelCoursePaneName
} from '../../../config/paneConfig';
import { transformLightnessToRange } from '../../../Utils/cssUtils';
import { AnalysisProductsTravelCourse } from '../DataTypes/MapEntities';
import { useMapPipeEntitiesStore } from '../store';

function TravelCourseMapEntities({ courses, colored }: TravelCourseMapEntitiesProps) {
  const { selectedPipeTravelCourse } = useMapPipeEntitiesStore();

  const displayCourses = selectedPipeTravelCourse.length ? selectedPipeTravelCourse : courses;
  function createCourse(course: AnalysisProductsTravelCourse) {
    const options = { ...getTravelCoursePathOptions(course, colored), interactive: true };
    const divStyle = {
      backgroundColor: transformLightnessToRange(options.color, 60, 80)
    };
    const scanName = `p${course.header.scan.polygon}_s${course.header.scan.snake}_part${course.header.scan.part}`;
    const messageLines = [
      `Sensor: ${course.header.scan.sensor}`,
      `Polygon: ${scanName}`,
      `Scan From: ${course.header.scan.timeiso}`
    ];
    return (
      <GeoJSON
        data={JSON.parse(course.header.geoJson)}
        pathOptions={options}
        key={`course${course.header.id}`}
        pane={
          colored &&
          (course.header.scan.sensor === 'FLC_370' ||
            course.header.scan.sensor === 'GRADIOMETRY_FLC_370')
            ? flcTravelCoursePaneName
            : travelCoursePaneName
        }>
        <Tooltip pane={TooltipPaneName} sticky={true}>
          <div style={divStyle}>
            {messageLines.map((messageLine) => (
              <>
                {messageLine}
                <br />
              </>
            ))}
          </div>
        </Tooltip>
      </GeoJSON>
    );
  }

  return <>{displayCourses.map((course) => createCourse(course))}</>;
}

export interface TravelCourseMapEntitiesProps {
  courses: AnalysisProductsTravelCourse[];
  colored: boolean;
}

export default TravelCourseMapEntities;
