import { Box, Collapse, Stack, Typography } from '@mui/material';
import * as React from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function MonitorCollapseSection({ header, variant = 'body1', children }: MonitorNodeSectionProps) {
  const [collapseIn, setCollapseIn] = React.useState(false);
  return (
    <Stack direction="column" spacing={collapseIn ? 1 : 0}>
      <Box sx={{ cursor: 'pointer' }} onClick={() => setCollapseIn((val) => !val)}>
        <Stack direction="row" spacing={1} alignItems="center">
          {collapseIn ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize="small" />}
          <Typography variant={variant as any}>{header}</Typography>
        </Stack>
      </Box>
      <Collapse in={collapseIn}>{children}</Collapse>
    </Stack>
  );
}

export interface MonitorNodeSectionProps {
  header: string;
  variant?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export default MonitorCollapseSection;
