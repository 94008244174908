import { useMemo, useState } from "react";
import { Scan, useSearchScansLocationLazyQuery } from "../../Generated/Graphql";
import { SiteInfo } from "../Common/ChooseSite";
import { Box, Button, IconButton, Typography } from "@mui/material";
import "./SETTING.css"
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Api as ServicesApi } from "../../Generated/ExoServicesAPI";
import { getServicesRequestParams } from "../../Utils/azureAuth";
import {ChangeScanSiteRequest} from "../../Generated/ExoServicesAPI";
import { reportAllPagesResults } from "../../Utils/graphqlUtils";
import ScanDateQuery from "./ScanDateQuery";
import SiteQuery from "./SiteQuery";

export default function SettingChangeScanSite() {
    const [selectedScan, setSelectedScan] = useState<Scan | null>(null);
    const [selectedSite, setSelectedSite] = useState<SiteInfo | null>(null);
    const [selectedScanSite, setSelectedScanSite] = useState<SiteInfo | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [, scansResults] = useSearchScansLocationLazyQuery();
    const [siteScans, setSiteScans] = useState<Scan[] | undefined>([]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const loadScans = useMemo(() => async function(siteId: string) {
        let startScan = 0;
        reportAllPagesResults(
            scansResults,
            (res) => {
                const scans = (res?.data?.searchScans?.items ?? []).filter(item => item !== null) as Scan[];
                if(!scans || scans.length === 0) {
                    return;
                }
                setSiteScans((sScans) => {
                    return [...(startScan === 0 ? []: sScans || []), ...scans];
                });
                startScan += scans.length;
            },
            (res) => res.data.searchScans?.nextToken,
            { siteId: siteId }
        );
    }, []);

    const handleMoveScan = async () => {
        if (!selectedScan || !selectedScan.id || !selectedSite || !selectedSite.id || !selectedScanSite || !selectedScanSite.id) {
            return;
        }
        setSelectedSite(null);
        const api = new ServicesApi();
        try {
            const data: ChangeScanSiteRequest = {
                oldSiteId: selectedScanSite.id,
                newSiteId: selectedSite.id
            }
            await api.fix.changeScanSite(
              selectedScan.id , data, await getServicesRequestParams()
            );

            setErrorMessage("Scan moved successfully");
        } catch (e) {
            setErrorMessage(`Got an error while trying to move scan to another site, ${JSON.stringify(e)}`);
        } finally {
            setSelectedScan(null);
            setSelectedScanSite(null);
        }
    }

    const getMessageStyle = () => {
        if (errorMessage.toLowerCase().includes("success")) {
            return { color: "green", fontWeight: "bold" };
        } else if (errorMessage) {
            return { color: "red", fontWeight: "bold" };
        }
        return {};
    };

    return (
        <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom>
                    Move Scan From One Site to Another
                </Typography>
                <IconButton onClick={toggleExpand}>
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </Box>
            {isExpanded && (
            <div className="one_line">
            <ScanDateQuery setSelectedScan={setSelectedScan} selectedScan={selectedScan} setSelectedScanSite={setSelectedScanSite} loadScans={loadScans} siteScans={siteScans} title="Source Scan" format={'^p\\d+_s\\d+_part\\d+_.+.'} />
            {selectedScanSite && selectedScan && (
                <>
                <SiteQuery setSelectedSite={setSelectedSite} selectedSite={selectedSite} title="Target Site" excludeSite={selectedScanSite} />
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', '& button': { m: 1, ml: 2 } }}>
                <Button
                        variant="contained"
                        size="large"
                        value="MoveScan"
                        className="selectButton"
                        disabled={!selectedSite}
                onClick={handleMoveScan}>Move Scan</Button>
                </Box>
                </>
                )}
            </div>)}
            {errorMessage && (
                <Typography sx={getMessageStyle()}>
                    {errorMessage}
                </Typography>
            )}
        </Box>
    );

}
