import Button from "@mui/material/Button";
import * as React from "react";
import { getFileName } from "../../../Utils/fileUtils";
import { getScanMatchingFeatures } from "./FindMatchingFeatures";
import tokml from "@maphubs/tokml";
import { Stack } from "@mui/system";
import { AnalysisProductsPipe, AnalysisProductsTravelCourse } from "../DataTypes/MapEntities";

const DOWNLOAD_TEXT_PREFIX = "data:text/plain;charset=utf-8,";

function DownloadPipeAndTravelCourseGeoJson({ pipe, travelCourses }: DownloadPipeAndTravelCourseGeoJsonProps) {
  const [travelCoursesDownloadInfo, setTravelCoursesDownloadInfo] = React.useState<TravelCourseDownloadInfo[]>([]);

  const pipeGeoJsonLinkRef = React.useRef<HTMLAnchorElement>(null);
  const pipeKMLLinkRef = React.useRef<HTMLAnchorElement>(null);
  const travelCourseLinkRef = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    setTravelCoursesDownloadInfo(getTravelCourseContent());
  }, [pipe, travelCourses]);

  function getTravelCourseContent() {
    if (!pipe) {
      return [];
    }
    const matchingCourses = getScanMatchingFeatures(pipe.header.scan, travelCourses);

    if (matchingCourses.length == 0) {
      return [];
    }

    return matchingCourses.map((t) => ({
      geoJson: DOWNLOAD_TEXT_PREFIX + t.header.geoJson,
      kml: DOWNLOAD_TEXT_PREFIX + tokml(JSON.parse(t.header.geoJson)),
      fileName: getFileName(t.header.s3Ref),
    }));
  }

  function downloadFiles(
    pipeLinkRef: React.RefObject<HTMLAnchorElement>,
    travelCourseLinkRef: React.RefObject<HTMLAnchorElement>,
    contentAttrGetter: (info: TravelCourseDownloadInfo) => string,
    fileSuffix: string
  ) {
    const pipeLink = pipeLinkRef.current;
    if (pipeLink !== null) {
      pipeLink.click();
    }

    const travelCourseDownloadLink = travelCourseLinkRef.current;
    if (travelCourseDownloadLink !== null) {
      for (const downloadInfo of travelCoursesDownloadInfo) {
        travelCourseDownloadLink.href = contentAttrGetter(downloadInfo);
        travelCourseDownloadLink.download = `${downloadInfo.fileName}.${fileSuffix}`;
        travelCourseDownloadLink.click();
      }
    }
  }

  return (
    <>
      <Stack spacing={2}>
        <Button
          variant="outlined"
          value="DownloadEntity"
          disabled={pipe === undefined}
          onClick={() => {
            downloadFiles(pipeGeoJsonLinkRef, travelCourseLinkRef, (info) => info.geoJson, "json");
          }}
        >
          Download GeoJsons
        </Button>
        <Button
          variant="outlined"
          value="DownloadEntity"
          disabled={pipe === undefined}
          onClick={() => {
            downloadFiles(pipeKMLLinkRef, travelCourseLinkRef, (info) => info.kml, "kml");
          }}
        >
          Download KMLs
        </Button>
      </Stack>
      <a
        href={DOWNLOAD_TEXT_PREFIX + pipe?.header.geoJson}
        download={getFileName(pipe ? pipe.header.s3Ref : "no_pipe") + ".json"}
        style={{ display: "hidden" }}
        ref={pipeGeoJsonLinkRef}
      />
      <a
        href={DOWNLOAD_TEXT_PREFIX + tokml(JSON.parse(pipe ? pipe.header.geoJson : "{}"))}
        download={getFileName(pipe ? pipe.header.s3Ref : "no_pipe") + ".kml"}
        style={{ display: "hidden" }}
        ref={pipeKMLLinkRef}
      />
      <a style={{ display: "hidden" }} ref={travelCourseLinkRef} />
    </>
  );
}

export interface DownloadPipeAndTravelCourseGeoJsonProps {
  pipe?: AnalysisProductsPipe;
  travelCourses: AnalysisProductsTravelCourse[];
}

export interface TravelCourseDownloadInfo {
  geoJson: string;
  kml: string;
  fileName: string;
}

export default DownloadPipeAndTravelCourseGeoJson;
