import { LatLngLiteral } from 'leaflet';
import { UnitMeasuresProperties } from '../../../Utils/const';
import * as turf from '@turf/turf';

export const getFormattedDistance = (
  startPoint: L.LatLngLiteral,
  endPoint: L.LatLngLiteral,
  map: L.Map | null,
  unitMeasures: UnitMeasuresProperties
) => {
  if (endPoint && startPoint && map) {
    const point1 = turf.point([startPoint.lng, startPoint.lat]);
    const point2 = turf.point([endPoint.lng, endPoint.lat]);
    const distanceInMeters = turf.distance(point1, point2, 'meters');
    if (UnitMeasuresProperties.Feet === unitMeasures) {
      const distanceInFeet = distanceInMeters * 3.28084 * 1000; // 1 meter = 3.28084 feet
      const totalInches = distanceInFeet * 12;
      const feet = Math.floor(distanceInFeet);
      const inches = Math.round(totalInches % 12);
      return `${feet}' ${inches}"`;
    } else if (UnitMeasuresProperties.Meters === unitMeasures) {
      const distance = distanceInMeters * 1000;
      return `${distance.toFixed(2)} m`;
    }
  }
};

export const getMidPoint = (
  startPoint: L.LatLngLiteral,
  endPoint: L.LatLngLiteral
): L.LatLngTuple => {
  const lat = (startPoint.lat + endPoint.lat) as number;
  const lng = (startPoint.lng + endPoint.lng) as number;
  return [lat / 2, lng / 2] as L.LatLngTuple;
};

export function getAngleRotate(startPoint: L.LatLngLiteral, endPoint: L.LatLngLiteral) {
  const latDiff = endPoint.lat - startPoint.lat;
  const lngDiff = endPoint.lng - startPoint.lng;
  const angleRadians = Math.atan2(latDiff, lngDiff);
  const angleDegrees = angleRadians * (180 / Math.PI); // Convert radians to degrees
  return angleDegrees + 360;
}

export enum SelectedToolsMode {
  NONE,
  SETTINGS,
  PDF,
  MEASUREMENT,
  STRAIGHT_LINE,
  DASH_LINE,
  GAS_LINE,
  DEPTH_LINE,
  ELECTRIC_LINE,
  MESSAGE,
  LINE_WIDTH,
  STICKERS,
  STICKER_NORTH,
  STICKER_P,
  UNIFY
}

export type ToolObject = {
  type: SelectedToolsMode;
  points: LatLngLiteral[];
  text: string;
  properties?: any;
  id: string;
};

const selectedModeMap: Record<string, SelectedToolsMode> = {
  NONE: SelectedToolsMode.NONE,
  SETTINGS: SelectedToolsMode.SETTINGS,
  PDF: SelectedToolsMode.PDF,
  MEASUREMENT: SelectedToolsMode.MEASUREMENT,
  STRAIGHT_LINE: SelectedToolsMode.STRAIGHT_LINE,
  DASH_LINE: SelectedToolsMode.DASH_LINE,
  GAS_LINE: SelectedToolsMode.GAS_LINE,
  DEPTH_LINE: SelectedToolsMode.DEPTH_LINE,
  ELECTRIC_LINE: SelectedToolsMode.ELECTRIC_LINE,
  MESSAGE: SelectedToolsMode.MESSAGE,
  LINE_WIDTH: SelectedToolsMode.LINE_WIDTH,
  STICKERS: SelectedToolsMode.STICKERS,
  STICKER_NORTH: SelectedToolsMode.STICKER_NORTH,
  STICKER_P: SelectedToolsMode.STICKER_P
};

export function convertToSelectedToolsMode(
  value: string | undefined | null
): SelectedToolsMode | undefined {
  return value ? selectedModeMap[value] : SelectedToolsMode.NONE;
}
