import { Dict, entityType } from '../../../BasicTypes';
import { UNDEFINED_STR_VALUE } from '../../../Utils/filterUtils';
import { getMinValue } from '../../../Utils/mathUtils';
import {
  AnalysisProductsPipe,
  AnalysisProductsPolygon,
  AnalysisProductsTransmitter,
  ScanDetails
} from '../DataTypes/MapEntities';
import { MapFeatures } from '../DataTypes/MapFeatures';

export const DEFAULT_MIN_TRANSMITTERS_FREQUENCY_DELTA = 20.1; // Not really needed since we work with integers
export const DEFAULT_MIN_SAME_DATE_VALUE = 1000 * 60 * 60 * 12; // 12 Hours

function isCloseFrequency(
  pipe: AnalysisProductsPipe,
  transmitter: AnalysisProductsTransmitter,
  min_delta: number = DEFAULT_MIN_TRANSMITTERS_FREQUENCY_DELTA
): boolean {
  const frequency = pipe.frequency;
  if (frequency === undefined) {
    return false;
  }
  return Math.abs(frequency - transmitter.frequency) < min_delta;
}

function isSameLocation(firstEntity: entityType, secondEntity: entityType): boolean {
  const firstScan = firstEntity.header.scan;
  const secondScan = secondEntity.header.scan;
  return firstScan.polygon === secondScan.polygon && firstScan.snake === secondScan.snake;
}

function isSameDate(
  firstEntity: entityType,
  secondEntity: entityType,
  minDeltaTimestamp: number = DEFAULT_MIN_SAME_DATE_VALUE
): boolean {
  const firstTimestamp = firstEntity.header.scan.timestamp;
  const secondTimestamp = secondEntity.header.scan.timestamp;
  if (firstTimestamp === undefined || secondTimestamp === undefined) {
    console.log('Error: no dates', firstEntity, secondEntity);
    return false;
  }

  return Math.abs(firstTimestamp - secondTimestamp) < minDeltaTimestamp;
}

function compareScanDetails(scan1: ScanDetails, scan2: ScanDetails) {
  return (
    scan1.id === scan2.id &&
    scan1.timestamp === scan2.timestamp &&
    scan1.name === scan2.name &&
    scan1.part === scan2.part &&
    scan1.polygon === scan2.polygon &&
    scan1.snake === scan2.snake &&
    scan1.sensor === scan2.sensor
  );
}

export function getScanMatchingFeatures<T extends entityType>(
  scan: ScanDetails,
  featuresOfType: T[]
): T[] {
  const features = featuresOfType.filter((entity: T) =>
    compareScanDetails(entity.header.scan, scan)
  );
  if (features.length !== 0) {
    return features;
  }
  return featuresOfType.filter((entity: T) => entity.header.scan.id === scan.id);
}

export function getMatchingTransmitters(
  selectedPipe: AnalysisProductsPipe,
  transmitters: AnalysisProductsTransmitter[]
): AnalysisProductsTransmitter[] {
  if (selectedPipe.frequency === undefined) {
    return [];
  }
  return transmitters.filter(
    (transmitter: AnalysisProductsTransmitter) =>
      isCloseFrequency(selectedPipe, transmitter) &&
      isSameDate(selectedPipe, transmitter) &&
      isSameLocation(selectedPipe, transmitter)
  );
}

export function getScanTransmitters(
  selectedPipe: AnalysisProductsPipe,
  transmitters: AnalysisProductsTransmitter[]
): AnalysisProductsTransmitter[] {
  if (selectedPipe.frequency === undefined) {
    return [];
  }
  return transmitters.filter(
    (transmitter: AnalysisProductsTransmitter) =>
      isSameDate(selectedPipe, transmitter) && isSameLocation(selectedPipe, transmitter)
  );
}

export function getMatchingPipesByTransmitter(
  selectedTransmitter: AnalysisProductsTransmitter,
  pipes: AnalysisProductsPipe[]
): AnalysisProductsPipe[] {
  return pipes.filter((pipe: AnalysisProductsPipe) => {
    return (
      isCloseFrequency(pipe, selectedTransmitter) &&
      isSameDate(pipe, selectedTransmitter) &&
      isSameLocation(pipe, selectedTransmitter)
    );
  });
}

export function findMatchingFeaturesToSelected(
  selectedPipe: AnalysisProductsPipe,
  features: MapFeatures
): MapFeatures {
  return {
    pipes: [selectedPipe],
    polygons: [],
    transmitters: getMatchingTransmitters(selectedPipe, features.transmitters),
    travelCourses: getScanMatchingFeatures(selectedPipe.header.scan, features.travelCourses),
    targets: []
  };
}

export function findMatchingPipesToSelectedTransmitter(
  selectedTransmitter: AnalysisProductsTransmitter,
  features: MapFeatures
): MapFeatures {
  return {
    pipes: getMatchingPipesByTransmitter(selectedTransmitter, features.pipes),
    polygons: [],
    transmitters: [selectedTransmitter],
    travelCourses: [],
    targets: []
  };
}

export function findMatchingFeaturesToSelectedAllScanTransmitters(
  selectedPipe: AnalysisProductsPipe,
  features: MapFeatures
): MapFeatures {
  return {
    pipes: [selectedPipe],
    polygons: [],
    transmitters: getScanTransmitters(selectedPipe, features.transmitters),
    travelCourses: getScanMatchingFeatures(selectedPipe.header.scan, features.travelCourses),
    targets: []
  };
}

export function entityToStringKey(
  entity: AnalysisProductsTransmitter | AnalysisProductsPipe | AnalysisProductsPolygon
) {
  const keyFields = [entity.header.scan.polygon, entity.header.scan.snake, entity.header.scan.part];
  if (keyFields.some((k) => k === undefined)) {
    return 'undefined';
  }
  return keyFields.join('#');
}
export const getTransmittersMethod = (
  entity: AnalysisProductsPipe,
  transmitterMapping: Dict<AnalysisProductsTransmitter[]>
) => {
  const transmitters = transmitterMapping[entityToStringKey(entity)];
  const maxDelta = DEFAULT_MIN_TRANSMITTERS_FREQUENCY_DELTA;
  const maxTimestampDelta = DEFAULT_MIN_SAME_DATE_VALUE;
  if (entity.frequency === undefined) {
    return UNDEFINED_STR_VALUE;
  }
  if (!transmitters) {
    return;
  }
  const pipeFreq: number = entity.frequency;
  const comapareToPipeFreq = (freq: number) => Math.abs(freq - pipeFreq);
  const minDiffTransmitter = getMinValue(
    transmitters.filter(
      (t: AnalysisProductsTransmitter) =>
        t.header.scan.timestamp !== undefined &&
        entity.header.scan.timestamp !== undefined &&
        Math.abs(t.header.scan.timestamp - entity.header.scan.timestamp) <= maxTimestampDelta
    ),
    (t: AnalysisProductsTransmitter) => comapareToPipeFreq(t.frequency)
  );
  if (
    minDiffTransmitter === undefined ||
    comapareToPipeFreq(minDiffTransmitter.frequency) > maxDelta
  ) {
    return UNDEFINED_STR_VALUE;
  }

  return minDiffTransmitter.method ?? UNDEFINED_STR_VALUE;
};
