import { create } from 'zustand';
import { AnalysisProductsTravelCourse, ScanDetails } from '../../DataTypes/MapEntities';
import { LatLngExpression } from 'leaflet';
import { dataModel } from './UnifyData';

let unifyAreaIdCounter = 1;

export type unifyAreaType = {
  polygon: [number, number][];
  travelCourses: AnalysisProductsTravelCourse[];
  scans: ScanDetails[];
  model: dataModel[];
  id: number;
};

export interface PolygonType {
  points: LatLngExpression[];
}

type UnifyStore = {
  selectedScans: ScanDetails[];
  setSelectedScans: (scans: ScanDetails[]) => void;
  selectedUnifyArea: unifyAreaType[];
  setSelectedUnifyArea: (unifyArea: unifyAreaType[]) => void;
  updateUnifyAreaScans: (areaId: number, polygon: number, scans_names: string[]) => void;
  updateSelectedUnifyArea: (areaId: number, item: string) => void;
  polygons: PolygonType[];
  setPolygons: (polygons: PolygonType[]) => void;
  clearAll: () => void;
  showUnify: boolean;
  setShowUnify: (showUnify: boolean) => void;
  getNewId: () => number;
  resetId: () => void;
  id: number;
};

export const useUnifyStore = create<UnifyStore>((set, get) => ({
  selectedScans: [],
  setSelectedScans: (scans) => set({ selectedScans: scans }),
  selectedUnifyArea: [] as unifyAreaType[],
  setSelectedUnifyArea: (unifyArea: unifyAreaType[]) =>
    set((state) => ({ ...state, selectedUnifyArea: unifyArea, id: state.id + 1 })),

  updateUnifyAreaScans: (areaId: number, polygon: number, scans_names: string[]) =>
    set((state) => {
      const updatedAreas = state.selectedUnifyArea.map((area) => {
        if (area.id === areaId) {
          const model = area.model.find((model) => model.polygon === polygon);
          if (model) {
            return {
              ...area,
              model: area.model.map((model) => {
                if (model.polygon === polygon) {
                  return {
                    ...model,
                    scans_names,
                    scans: [
                      ...state.selectedScans.filter((scan) => scans_names.includes(scan.name))
                    ]
                  };
                }
                return model;
              })
            };
          }
          return area;
        }
        return area;
      });
      return {
        ...state,
        selectedUnifyArea: [...updatedAreas]
      };
    }),

  updateSelectedUnifyArea: (areaId: number, item: string) =>
    set((state) => {
      const updatedAreas = state.selectedUnifyArea.map((area) => {
        if (area.id === areaId) {
          const newModel = area.model.filter((model) => model.item !== item);
          return {
            ...area,
            model: newModel
          };
        }
        return area;
      });
      const newAreas = updatedAreas.filter((value) => value.model.length > 0);
      if (newAreas.length === 0) {
        state.resetId();
      }
      return {
        ...state,
        selectedUnifyArea: [...newAreas]
      };
    }),
  polygons: [],
  setPolygons: (polygons) => set({ polygons: polygons }),
  clearAll: () =>
    set((state) => {
      unifyAreaIdCounter = 1;
      return {
        ...state,
        selectedScans: [],
        selectedUnifyArea: [],
        polygons: [],
        showUnify: false,
        id: 1
      };
    }),
  showUnify: false,
  setShowUnify: (showUnify) => set({ showUnify: showUnify }),
  getNewId: () => {
    const { selectedUnifyArea } = get();
    if (selectedUnifyArea.length === 1 && selectedUnifyArea[0].scans.length === 0) {
      unifyAreaIdCounter = 1;
      return unifyAreaIdCounter;
    } else {
      return unifyAreaIdCounter++;
    }
  },
  resetId: () => (unifyAreaIdCounter = 1),
  id: 1
}));
