import { Button, Stack } from "@mui/material";
import { SiteDetails } from "../../../../Generated/ExoDBAPI";
import FileInput, { FileData, FileInputRef } from "../../../Inputs/FileInput";
import { useEffect, useRef, useState } from "react";

function UploadMediaFile({
  plannedSite,
  accept,
  percentages,
  uploadCallback,
  layerName,
  progress
}: UploadFileProps) {
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const fileInputRef = useRef<FileInputRef>(null);

  useEffect(() => {
    const filesData = fileInputRef.current?.getFilesData();
    if (!filesData || filesData.length === 0) return;
    fileInputRef.current?.updateProgress(filesData[0].id, progress)
  }, [progress])

  async function submit() {
    if (plannedSite === undefined) {
      window.alert("Pick a site");
      return;
    }
    if (layerName.length <= 3) {
      window.alert("Enter a layer name with more than 4 characters");
      return;
    }
    if (!/\w+/.test(layerName)) {
      window.alert("Layer name can only contain english letters, digits and underscores");
      return;
    }
    const fileInput = fileInputRef.current;
    if (fileInput === null) {
      window.alert("Internal error. This should be reported");
      console.log("Against all odds the file input ref was null");
      return;
    }

    const filesData = fileInput.getFilesData();
    if (filesData.length === 0) {
      window.alert("You need to pick a file to upload");
      return;
    }
    try {
      setUploadDisabled(true);
      await uploadCallback(plannedSite, layerName, filesData[0], fileInput);
      fileInput.changeFileStatus(filesData[0].id, true);
      fileInput.clearFiles();
    } catch (e: any) {
      if (e instanceof TypeError) {
        window.alert("Upload probably didn't fail. Check the layers in a few minutes.");
      }
      fileInput.changeFileStatus(filesData[0].id, false);
    } finally {
      setUploadDisabled(false);
    }
  }

  return (
    <Stack>
      <FileInput
        multipleFiles={false}
        loadedFileXButtonAtStart={true}
        accept={accept}
        ref={fileInputRef}
        percentages={percentages}
      />
      <Button variant="outlined" sx={{ marginTop: 1 }} onClick={submit} disabled={uploadDisabled}>
        Load file
      </Button>
    </Stack>
  );
}

export interface UploadFileProps {
  plannedSite?: SiteDetails;
  accept: string;
  percentages: boolean;
  uploadCallback: (
    site: SiteDetails,
    layerName: string,
    fileData: FileData,
    fileInput: FileInputRef
  ) => Promise<void>;
  layerName: string;
  progress: number;
}

export default UploadMediaFile;
