import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 18,
    fontStyle: 'bold',
    fontSize: 10,
    marginLeft: 4,
  },
  title: {
    width: '12%',
    textAlign: 'left',
    fontSize: 10,
    paddingLeft: 8,
  },
  name: {
    width: '33%',
    borderColor: '#000',
    borderWidth: 1,
    fontSize: 9,
    textAlign: 'left',
    paddingLeft: 4,
    paddingTop: 2,
    backgroundColor: '#acafb5',
  },
  desc: {
    fontStyle: 'bold',
    fontSize: 10,
    width: '45%',
    color: 'blue',
},
space: {
    width: '5%',
},
summery : {
    height: 50
}

});

const SummeryPDF = () => (
    <>
      <View style={styles.row}>
        <Text style={styles.desc}>PROXIMITY CLEARANCE DETERMINATION</Text>

        <Text style={styles.space}>{' '}</Text>
        <Text style={styles.title}>Decision</Text>
        <Text style={styles.name}>{' '}</Text>

      </View>

      <View style={styles.row}>
        <Text style={styles.title}>Name:</Text>
        <Text style={styles.name}>{' '}</Text>
        <Text style={styles.space}>{' '}</Text>
        <Text style={styles.title}>Signature:</Text>
        <Text style={styles.name}>{' '}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.title}>Date:</Text>
        <Text style={styles.name}>{' '}</Text>
        <Text style={styles.space}>{' '}</Text>
        <Text style={styles.title}>Comments:</Text>
        <Text style={styles.name}>{' '}</Text>
      </View>
    </>
);

export default SummeryPDF;
