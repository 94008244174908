import React from "react";
import {
  Autocomplete,
  AutocompletePropsSizeOverrides,
  AutocompleteRenderInputParams,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { OverridableStringUnion } from "@mui/types";

const DEFAULT_WIDTH = 500;
const DEFAULT_SIZE = "small";

/**
 * Set default values for AutoComplete of mui.
 * By default, a TextField is used to render the input of the user.
 *
 * @param id The id for the auto complete field. Also used for creating unique keys for the different elements
 * @param options The options to choose from
 * @param setSelectedOption (matching selectedOption) Set the selected option.
 *    Usually the right element of a useState return value.
 * @param selectedOption (matching setSelectedOption) The currently selected option.
 *    Usually the left element of a useState return value.
 * @param placeholder The value to use as label for the TextField (if renderInput is not overridden)
 * @param alwaysChoose If true - always choose an option if at least a single option is valid
 *    This basically means that if options is not empty and the selected option is not valid, the first option will be
 *    taken.
 * @param sx
 * @param size
 * @param renderInput
 * @param getOptionLabel
 * @param onChange
 *    See AutoCompletePropsWithDefault
 * @param autoCompleteProps Values for most of the internal AutoComplete options
 *  options must be given explicitly, and if you want to change value you are should use AutoComplete directly
 * @param spanClassName The className for the span holding the AutoComplete element
 * @returns An element to perform auto complete
 */
function AutoCompleteField<T>({
  options,
  setSelectedOption,
  selectedOption,
  placeholder = "",
  alwaysChoose = false,
  sx = { width: DEFAULT_WIDTH },
  size = DEFAULT_SIZE,
  renderInput,
  getOptionLabel = (option: any) => option?.label ?? option?.name ?? (typeof option === "string" ? option : ""),
  onChange,
  autoCompleteProps = {},
  textFieldProps = {},
  spanClassName = "exofuserFormEntry",
}: AutoCompleteFieldProps<T>) {
  if (renderInput === undefined) {
    renderInput = (params: AutocompleteRenderInputParams) => (
      <TextField {...params} label={placeholder} key={`option-${params.id}`} {...textFieldProps} />
    );
  }

  if (onChange === undefined) {
    onChange = (_e: any, value: any) => (setSelectedOption ?? ((_value: any) => undefined))(value);
  }

  function chooseLegalValue() {
    let currentSelectedOption = selectedOption;
    if (selectedOption !== undefined && !options.includes(selectedOption)) {
      currentSelectedOption = undefined;
    }

    if (alwaysChoose && currentSelectedOption === undefined) {
      currentSelectedOption = options.length === 0 ? undefined : options[0];
    }
    return currentSelectedOption;
  }

  React.useEffect(() => {
    if (setSelectedOption !== undefined) {
      setSelectedOption(chooseLegalValue());
    }
  }, [options, selectedOption]);

  // Mui doesn't like undefined value (unless value is always undefined), and the value must be in options
  // If no option is available, we add a single empty option to avoid this issue
  const effectiveOptions = React.useMemo<T[] | string[]>(() => (options.length > 0 ? options : [""]), [options]);

  return (
    <span className={spanClassName}>
      <Autocomplete
        value={chooseLegalValue() ?? (alwaysChoose ? effectiveOptions[0] : "")}
        options={effectiveOptions}
        sx={sx}
        size={size}
        renderInput={renderInput}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        {...autoCompleteProps}
      />
    </span>
  );
}

interface AutoCompletePropsWithDefault<T> {
  /**
   * The size of the component.
   * @default 'medium'
   */
  size?: OverridableStringUnion<"small" | "medium", AutocompletePropsSizeOverrides>;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx?: SxProps<Theme>;

  /**
   * Render the input.
   *
   * @param {object} params
   * @returns {ReactNode}
   */
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;

  /**
   * Used to determine the string value for a given option.
   * It's used to fill the input (and the list box options if `renderOption` is not provided).
   *
   * If used in free solo mode, it must accept both the type of the options and a string.
   *
   * @param {T} option
   * @returns {string}
   * @default (option) => option.label ?? option
   */
  getOptionLabel?: (option: T) => string;

  /**
   * Callback fired when the value changes.
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * @param {T|T[]} value The new value of the component.
   * @param {string} reason One of "createOption", "selectOption", "removeOption", "blur" or "clear".
   * @param {string} [details]
   */
  onChange?: (event: React.SyntheticEvent, value: any) => void;
}

export interface AutoCompleteFieldProps<T> extends AutoCompletePropsWithDefault<T> {
  options: T[];
  setSelectedOption?: (option?: T) => void;
  alwaysChoose?: boolean;
  selectedOption?: T;
  placeholder?: string;
  autoCompleteProps?: any;
  textFieldProps?: TextFieldProps;
  spanClassName?: string;
}

export default AutoCompleteField;
