import * as React from 'react';
import { PlannedPolygonLayer } from '../../../Utils/layerUtils';
import { PlannedObjectType } from '../Planning/ManagePolygonsForm';
import {
  useGetPlannedPolygonTransmittersLazyQuery,
  useGetPlanningPolygonsLazyQuery
} from '../../../Generated/Graphql';
import { reportAllPagesResults } from '../../../Utils/graphqlUtils';

function PlanningLayerStorage({
  graphqlSiteId,
  planningLayers,
  reportPlanningLayers,
  reloadTrigger,
  setPlannedEntityId
}: PlanningLayerStorageProps) {
  const [_getPolygons, polygonsResults] = useGetPlanningPolygonsLazyQuery({
    variables: { siteId: graphqlSiteId ?? '' }
  });
  const [_getTransmitters, transmittersResults] = useGetPlannedPolygonTransmittersLazyQuery({
    variables: { polygonId: graphqlSiteId ?? '' }
  });

  function getPolygonTransmitters(polygonId: string) {
    const transmitters: PlannedPolygonLayer[] = [];
    reportAllPagesResults(
      transmittersResults,
      (res) => {
        const items = res.data.getPlannedPolygon?.planned_transmitters?.items;
        if (!items) {
          return;
        }
        for (const item of items) {
          if (item === null) {
            return;
          }
          const geojsonData = JSON.parse(item.geo_data);
          transmitters.push({
            id: item.id,
            name: item.name,
            metadata: {
              name: item.name,
              comment: item.comment ?? '',
              type: PlannedObjectType.TRANSMITTER,
              method: item.method ?? ''
            },
            color: item.color ?? 'red',
            overrideColor: item.override_color ?? undefined,
            data: [{ lng: geojsonData['coordinates'][0], lat: geojsonData['coordinates'][1] }],
            visible: true,
            selected: false,
            subLayers: []
          });
        }
      },
      (res) => res.data.getPlannedPolygon?.planned_transmitters?.nextToken,
      { polygonId: polygonId }
    );
    return transmitters;
  }

  React.useEffect(() => {
    // Load Data
    async function fetchData() {
      if (graphqlSiteId === undefined) {
        return;
      }
      reportPlanningLayers([]);
      const polygons: PlannedPolygonLayer[] = [];
      await reportAllPagesResults(
        polygonsResults,
        (res) => {
          const items = res.data.getSite?.planned_entities?.planned_polygons?.items;
          const plannedEntityId = res.data.getSite?.planned_entities?.id;
          setPlannedEntityId(plannedEntityId ?? '');
          if (!items) {
            return;
          }
          for (const polygon of items) {
            if (polygon === null) {
              return;
            }
            const transmitters: PlannedPolygonLayer[] = getPolygonTransmitters(polygon.id);
            const geojsonData = JSON.parse(polygon.geo_data);
            const coordinates =
              geojsonData['coordinates'] === undefined
                ? geojsonData['geometry']['coordinates']
                : geojsonData['coordinates'];
            polygons.push({
              id: polygon.id,
              name: polygon.name,
              metadata: {
                name: polygon.name,
                comment: polygon.comment ?? '',
                type: PlannedObjectType.TRANSMITTER,
                sensorTypes: (polygon.sensor_models ?? []).filter((s) => s !== null) as string[],
                platforms: (polygon.mount_method ?? []).filter((p) => p !== null) as string[],
                isRescan: polygon.is_rescan ?? false
              },
              color: polygon.color ?? 'red',
              overrideColor: polygon.override_color ?? undefined,
              data: (coordinates[0] as [number, number][]).map((c) => ({
                lat: c[1],
                lng: c[0]
              })),
              visible: true,
              selected: false,
              subLayers: transmitters
            });
          }
        },
        (res) => res.data.getSite?.planned_entities?.planned_polygons?.nextToken
      );
      if (polygons.length !== 0) {
        reportPlanningLayers(polygons);
      } else {
        const storedObjects = window.localStorage.getItem(
          `CustomGeoJsonLayersStorage--${graphqlSiteId}--layers`
        );
        if (storedObjects !== null) {
          reportPlanningLayers(JSON.parse(storedObjects)['layers']);
        }
      }
    }
    fetchData();
  }, [graphqlSiteId, reloadTrigger]);

  React.useEffect(() => {
    // Save Data
    if (graphqlSiteId === undefined) {
      return;
    }
    window.localStorage.setItem(
      `CustomGeoJsonLayersStorage--${graphqlSiteId}--layers`,
      JSON.stringify({ layers: planningLayers })
    );
  }, [planningLayers]);

  return <></>;
}

export interface PlanningLayerStorageProps {
  graphqlSiteId?: string;
  planningLayers: PlannedPolygonLayer[];
  reportPlanningLayers: (layers: PlannedPolygonLayer[]) => void;
  setPlannedEntityId: (id: string) => void;
  planningEntityId?: string;
  reloadTrigger: boolean;
}

export interface GeoJsonLayerStorageData {
  id: string;
  color: string;
  overrideColor: boolean;
  visible: boolean;
  subLayers?: GeoJsonLayerStorageData[];
}

export default PlanningLayerStorage;
