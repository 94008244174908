import { LatLngExpression, PathOptions } from 'leaflet';
import { Polyline } from 'react-leaflet';
export interface AzimuthLineProps {
  positions: LatLngExpression[];
  pathOptions?: PathOptions;
  azimuth?: number;
}
function AzimuthLine({ positions, pathOptions, azimuth }: AzimuthLineProps) {
  return (
    <>
      <Polyline positions={positions} pathOptions={pathOptions} />
    </>
  );
}
export default AzimuthLine;
