import * as React from "react";

function CreateRTKForm({ handelCreate, handelCancel }: CreateRTKFormProps) {
  const [publish, setPublish] = React.useState(false);
  const [sendGGA, setsendGGA] = React.useState(true);
  const nameRef = React.useRef<HTMLInputElement>(null);
  const usernameRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);
  const mountPointRef = React.useRef<HTMLInputElement>(null);
  const hostnameRef = React.useRef<HTMLInputElement>(null);
  const portRef = React.useRef<HTMLInputElement>(null);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();

    const nameInput = nameRef.current;
    const usernameInput = usernameRef.current;
    const passwordInput = passwordRef.current;
    const mountPointInput = mountPointRef.current;
    const hostnameInput = hostnameRef.current;
    const portInput = portRef.current;

    if (
      nameInput === null ||
      usernameInput === null ||
      passwordInput === null ||
      mountPointInput === null ||
      hostnameInput === null ||
      portInput === null
    ) {
      throw Error("One of the refs is null");
    }

    handelCreate(
      nameInput.value,
      usernameInput.value,
      passwordInput.value,
      mountPointInput.value,
      hostnameInput.value,
      +portInput.value,
      sendGGA,
      publish
    );
  }

  function publishValueChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setPublish(e.target.checked);
  }

  return (
    <div className="rtkCreateFormContainer">
      <header className="flexHeader">
        <h2 className="pageHeader">Create RTK user</h2>
        <span className="siteFormEntry floatRight">
          <span className="deleteContactButton" onClick={handelCancel}>
            ×
          </span>
        </span>
      </header>

      <form onSubmit={onSubmit} autoComplete="off">
        <span className="formSectionNoMargin">
          <span className="rtkFormEntry">
            Name
            <input type="text" className="rtkInputText" name="name" id="name" required={true} ref={nameRef} />
          </span>
          <span className="rtkFormEntry">
            Username
            <input type="text" className="rtkInputText" name="name" id="name" required={true} ref={usernameRef} />
          </span>
          <span className="rtkFormEntry">
            Password
            <input type="text" className="rtkInputText" name="name" id="name" required={true} ref={passwordRef} />
          </span>
          <span className="rtkFormEntry">
            Mount Point
            <input type="text" className="rtkInputText" name="name" id="name" required={true} ref={mountPointRef} />
          </span>
          <span className="rtkFormEntry">
            Hostname
            <input
              type="text"
              className="rtkInputText"
              name="Hostname"
              id="Hostname"
              required={true}
              ref={hostnameRef}
            />
          </span>
          <span className="rtkFormEntry">
            Port
            <input
              type="text"
              className="rtkInputText"
              name="Port"
              id="Port"
              required={true}
              ref={portRef}
              pattern="^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$"
            />
          </span>
          <span className="rtkFormEntry">
            <span>
              <input
                type="checkbox"
                id="sendGGA"
                name="sendGGA"
                onChange={(e) => setsendGGA(e.target.checked)}
                defaultChecked={true}
              />{" "}
              Sends gga
            </span>
          </span>
          <span className="rtkFormEntry">
            <span>
              <input type="checkbox" id="publish" name="publish" onChange={publishValueChanged} /> Publish this user to
              all sites from this state
            </span>
          </span>
          <span className="formSection" key="submitButton">
            <span className="siteFormEntry">
              <input type="submit" value="Create" className="formButton" />
            </span>
          </span>
        </span>
      </form>
    </div>
  );
}

export interface CreateRTKFormProps {
  handelCreate: (
    name: string,
    username: string,
    password: string,
    mountPoint: string,
    hostname: string,
    port: number,
    sendGGA: boolean,
    publish: boolean
  ) => void;
  handelCancel: () => void;
}

export default CreateRTKForm;
