import React from "react";
import { getTransmitterClaimedFrequency, MapFeaturesStatus } from "../DataTypes/MapFeatures";
import { Stack, TextField } from "@mui/material";

import DownloadButton from "../../Templates/DownloadButton";

import "../../../styles/table.css";
import { Dict } from "../../../BasicTypes";
import { START_MAX_FREQUENCY_DELTA } from "../../../config/mapConfig";

const SUCCESS_STATUS = "Ready";

interface TransmittersSplit {
  missing: string[];
  bad: string[];
  good: string[];
}

function LogTransmitterView({ mapFeatureStatus, setMaxAllowedFrequency }: LogTransmitterViewProps) {
  const [frequencyDeltaStr, setFrequencyDeltaStr] = React.useState<string | undefined>(
    START_MAX_FREQUENCY_DELTA.toString()
  );

  const errorStatus = React.useMemo<string>(() => {
    if (frequencyDeltaStr === undefined) {
      return "No Frequency delta was given";
    }
    const value = parseInt(frequencyDeltaStr);
    if (Number.isNaN(value)) {
      return "Frequency delta must be an integer";
    }

    if (value <= 0) {
      return "Frequency delta must be positive";
    }
    return SUCCESS_STATUS;
  }, [frequencyDeltaStr]);

  React.useEffect(() => {
    if (errorStatus !== SUCCESS_STATUS || frequencyDeltaStr === undefined) {
      setMaxAllowedFrequency(undefined);
    } else {
      setMaxAllowedFrequency(parseInt(frequencyDeltaStr));
    }
  }, [errorStatus, frequencyDeltaStr]);

  const frequencyDeltas = React.useMemo<Dict<number | undefined> | undefined>(() => {
    return Object.fromEntries(
      mapFeatureStatus.allFeatures.transmitters.map((transmitter) => {
        const claimedFrequency = getTransmitterClaimedFrequency(transmitter);
        if (transmitter.header.scan.s3Ref === undefined) {
          return [undefined, undefined];
        }

        const scanUri = `s3://${transmitter.header.scan.s3Ref}`;
        if (claimedFrequency === undefined) {
          return [scanUri, undefined];
        }
        return [scanUri, Math.abs(transmitter.frequency - claimedFrequency)];
      })
    );
  }, [mapFeatureStatus, frequencyDeltaStr]);

  const transmittersSplit = React.useMemo<TransmittersSplit | undefined>(() => {
    const allowedDelta = parseInt(frequencyDeltaStr ?? "");
    if (Number.isNaN(allowedDelta) || frequencyDeltas === undefined) {
      return undefined;
    }

    const transmittersSplit: TransmittersSplit = { missing: [], bad: [], good: [] };
    Object.entries(frequencyDeltas).forEach(([scan, delta]) => {
      if (delta === undefined) {
        transmittersSplit.missing.push(scan);
      } else if (delta > allowedDelta) {
        transmittersSplit.bad.push(scan);
      } else {
        transmittersSplit.good.push(scan);
      }
    });
    return transmittersSplit;
  }, [frequencyDeltas, frequencyDeltaStr]);

  return (
    <Stack spacing={2} sx={{ width: 200 }}>
      <TextField
        error={errorStatus !== SUCCESS_STATUS}
        type="number"
        InputProps={{
          inputProps: {
            min: 1,
          },
        }}
        helperText={errorStatus}
        label="Allowed Delta (hz)"
        value={frequencyDeltaStr}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFrequencyDeltaStr(event.target.value);
        }}
      ></TextField>
      <TextField
        disabled={true}
        label={[
          `${transmittersSplit?.bad?.length}/${mapFeatureStatus.allFeatures.transmitters.length}`,
          `(${transmittersSplit?.missing.length})`,
        ].join(" ")}
        helperText={"bad/all (missing)"}
      ></TextField>
      <DownloadButton
        buttonName="Download Bad"
        data={errorStatus !== SUCCESS_STATUS ? undefined : JSON.stringify(transmittersSplit?.bad, undefined, 2)}
        fileName={"bad_transmitters.txt"}
      ></DownloadButton>
      <DownloadButton
        buttonName="Download All"
        data={errorStatus !== SUCCESS_STATUS ? undefined : JSON.stringify(transmittersSplit, undefined, 2)}
        fileName={"transmitters.txt"}
      ></DownloadButton>
    </Stack>
  );
}

export interface LogTransmitterViewProps {
  mapFeatureStatus: MapFeaturesStatus;
  setMaxAllowedFrequency: (arg: number | undefined) => void;
}

export default LogTransmitterView;
