import { ParsedTargetObect} from "../Types/MappingEntities";

export const targetOwnerListMap  = {'BELL SYSTEM': {color: '#eda12f', abrv: 'BEL'},
 'conEdison':{color:"#f71111", abrv: 'SCE'}, 'Partner': {color: '#f28388', abrv: 'PAR'},
 'Cellcom': {color: '#b652de', abrv: 'CEL'},
 'HOT': {color: '#a31c52', abrv: 'HOT'},
'Bezeq': {color: '#481ebd', abrv: 'BZK'},
 'IEC': {color: '#f71111', abrv: 'IEC'},
 'Mekorot':{color: '#2ae2e8', abrv: 'MEK'}};

export const targetUtilityTypesMap = {'Electricity':{color: '#f71111', abrv: 'EL'},
  'Lighting': {color: '#1c0ac2', abrv: 'LT'}, 'Traffic Control': {color: '#087529',abrv: 'TR'},
  'Communication': {color: '#fa3cea', abrv: 'COM'}, 'Fiber Optic':{color: '#eda12f', abrv: 'FO'},
  'Water': {color: '#1d89ad', abrv: 'W'},
  'Irrigation': {color: '#74d0f7', abrv: 'IRR'}, 'Gas': {color: '#74d0f7', abrv: 'GAS'}, 'Fuel': {color: '#7a0ee6', abrv: 'FUEL'},
  'Sewer': {color: '#7a5e1b', abrv: 'SW'},'Drain': {color: '#4f81d6', abrv: 'DR'},'Unknown':{color: '#c5c6c7', abrv: 'UNK'}, 'Other':{color: '#c5c6c7', abrv: 'OTH'}}


  export function getRelevantOwnersAndUtilities(pois:ParsedTargetObect[]){
    const selectedOwners: { [key: string]: { color: string; abrv: string } | {} } = {};
    const selectedUtilities: { [key: string]: { color: string; abrv: string } | {} } = {};
    pois.forEach((poi) => {
      if (poi.target_info?.company) {
        selectedOwners[poi.target_info.company] =
          targetOwnerListMap[poi.target_info.company as keyof typeof targetOwnerListMap];
      } else if (poi.target_info?.utilityType) {
        poi.target_info?.utilityType.forEach((type: any) => {
          selectedUtilities[type] = targetUtilityTypesMap[type as keyof typeof targetUtilityTypesMap];
        });
      } else{
        selectedUtilities['Unknown'] = targetUtilityTypesMap['Unknown'];
      }
    });
    return {selectedOwners, selectedUtilities}
  }
