import React from "react";
import L from "leaflet";
import { GeoShape, UserLayerAttributes, UserLayerShape } from "../../../Generated/ExoDBAPI";
import { DrawnContext, ShapeDrawningState, getGeoShapeType } from "./DrawnFileData";
import PropertiesModal from "./PropertiesModal";
import { getMarkingOptions } from "./DrawnCommon";

const SUCCESS_STATUS = "Ready";

function getLatsLons(points: L.LatLng[]): [number[], number[]] {
  const lats: number[] = [];
  const lons: number[] = [];
  points.forEach((point) => {
    lats.push(point.lat);
    lons.push(point.lng);
  });
  return [lats, lons];
}

function DrawnControlForm({ drawnContext }: DrawnControlFormProps) {
  const [enabled, setEnabled] = React.useState<boolean>(false);
  const [showShapePropertiesModal, setShowShapePropertiesModal] = React.useState(false);
  const typeSelectRef = React.useRef<HTMLSelectElement>(null);
  const nameRef = React.useRef<HTMLInputElement>(null);

  function getPoints(leafletFeatureLayer: L.Layer): L.LatLng[] {
    const geoType = getGeoShapeType(leafletFeatureLayer);

    if (geoType === GeoShape.Polyline) {
      return (leafletFeatureLayer as L.Polyline).getLatLngs() as L.LatLng[];
    }

    if (geoType === GeoShape.Polygon) {
      const multiPoints = (leafletFeatureLayer as L.Polygon).getLatLngs() as L.LatLng[][];
      if (multiPoints.length != 1) {
        console.log("Bad polygon (multi-polygon)", leafletFeatureLayer);
        throw Error("Multi polygon is not supported");
      }
      return multiPoints[0];
    }

    console.log("Bad layer", leafletFeatureLayer);
    throw Error("Could not extract points from shape");
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (
      drawnContext.drawingLayer !== null &&
      drawnContext.drawingLayer.getLayers().length > 0 &&
      drawnContext.currentState == ShapeDrawningState.ShapeDrawn
    ) {
      setShowShapePropertiesModal(true);
    }
  }

  function onConfirmShapes(attributes?: UserLayerAttributes, name?: string) {
    try {
      if (
        drawnContext.currentState != ShapeDrawningState.ShapeDrawn ||
        drawnContext.drawingLayer === null ||
        drawnContext.drawingLayer.getLayers().length == 0 ||
        typeSelectRef.current === null
      ) {
        console.log(
          "Could not save drawn shape. The current state is",
          drawnContext.currentState,
          "the drawing layer is",
          drawnContext.drawingLayer
        );
        return;
      }
      const markingType = typeSelectRef.current.value;
      const layersCount = drawnContext.drawingLayer.getLayers().length;
      const newShapes: UserLayerShape[] = drawnContext.drawingLayer.getLayers().map((leafletFeatureLayer, index) => {
        const geoShape = getGeoShapeType(leafletFeatureLayer);
        const points = getPoints(leafletFeatureLayer);
        name = name ?? nameRef.current?.value;
        if (name === undefined || name.length === 0) {
          name = undefined;
        }

        if (name !== undefined && layersCount > 1) {
          name = `${name} (${index + 1})`;
        }

        const [lats, lons] = getLatsLons(points);
        if (attributes !== undefined) {
          Object.keys(attributes).forEach((key) => {
            if ((attributes as any)[key] === undefined || (attributes as any)[key] === null) {
              delete (attributes as any)[key];
            }
          });
        }

        return {
          lats: lats,
          lons: lons,
          shapeType: geoShape,
          markingType: markingType,
          timestamp: Math.floor(new Date().getTime() / 1000),
          name: name,
          layerAttributes: attributes,
        };
      });
      drawnContext.setAllDrawnShapes((shapes) => shapes.concat(newShapes));
      drawnContext.setCurrentState(ShapeDrawningState.ShapeSubmitted);
    } finally {
      setShowShapePropertiesModal(false);
    }
  }

  function getStatus(): string {
    if (drawnContext.currentState !== ShapeDrawningState.ShapeDrawn) {
      return `Bad State: not drawn [${drawnContext.currentState.toString()}]`;
    }

    const features = drawnContext.drawingLayer?.getLayers();
    if (features === undefined || features.length <= 0) {
      return "Error [no shape data]: clear shapes and retry";
    }

    if (getMarkingOptions(drawnContext.currentShapeType).length <= 0) {
      return "Error [missing shape type]: clear shape and retry";
    }

    if (typeSelectRef.current === null) {
      return "Error [no select]: ask for software support";
    }

    return SUCCESS_STATUS;
  }

  function updateEnabled() {
    const newEnabled = getStatus() === SUCCESS_STATUS;
    if (newEnabled !== enabled) {
      setEnabled(newEnabled);
    }
  }

  updateEnabled();
  return (
    <form onSubmit={submit}>
      <span className="exofuserFormEntry" key="exofuserDrawnShapeType">
        <label htmlFor="exofuser.drawn.current.type">Choose Type</label>
        <select
          id="exofuser.drawn.current.type"
          name="exofuser.drawn.current.type"
          ref={typeSelectRef}
          onChange={updateEnabled}
        >
          {getMarkingOptions(drawnContext.currentShapeType).map((name) => {
            return (
              <option value={name} key={`exofuser.drawn.current.type.${name}`}>
                {name}
              </option>
            );
          })}
        </select>
      </span>

      <span className="exofuserFormEntry" key="exofuserDrawnShapeName">
        <label htmlFor="exofuser.drawn.current.name">Shape Name (Optional)</label>
        <input id="exofuser.drawn.current.name" name="exofuser.drawn.current.name" ref={nameRef} />
      </span>

      <span className="exofuserFormEntry" key="exofuserDrawnShapeStatus">
        <label htmlFor="exofuser.drawn.commit.status">Status</label>
        <input
          disabled={true}
          id="exofuser.drawn.current.status"
          name="exofuser.drawn.current.status"
          style={enabled ? { color: "black" } : { color: "red" }}
          value={getStatus()}
        />
      </span>

      <span className="formSection" key="submitButton">
        <input type="submit" value="Save Shape" className="formButton" disabled={!enabled} />
      </span>
      <span className="formSection" key="forceEnableStateButton">
        <input
          type="button"
          value="Force Enable Drawing"
          className="formButton"
          disabled={drawnContext.currentState !== ShapeDrawningState.Uninitialized}
          onClick={() => {
            drawnContext.setCurrentState(ShapeDrawningState.Clean);
          }}
        />
      </span>
      <span className="formSection" key="delete shapes">
        <input
          type="button"
          value={`Delete ${drawnContext.allDrawnShapes.length} Drawn Shapes`}
          className="formButton"
          disabled={drawnContext.allDrawnShapes.length === 0}
          onClick={() => drawnContext.setAllDrawnShapes([])}
        />
      </span>
      {showShapePropertiesModal && (
        <PropertiesModal
          defaultQL={typeSelectRef.current?.value ?? ""}
          QLOptions={getMarkingOptions(drawnContext.currentShapeType)}
          onCancel={() => setShowShapePropertiesModal(false)}
          onConfirm={onConfirmShapes}
          defaultName={nameRef.current?.value ?? ""}
        />
      )}
    </form>
  );
}

export interface DrawnControlFormProps {
  drawnContext: DrawnContext;
}

export default DrawnControlForm;
