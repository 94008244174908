import * as React from "react";
import { Api, RTKUser, StateDetails } from "../../../Generated/ExoDBAPI";
import { getRequestParams } from "../../../Utils/azureAuth";
import Modal from "../../Templates/Modal";
import AddRTKCard from "./AddRTKCard";
import ConfirmRTKOverride from "./ConfirmRTKOverride";
import CreateRTKForm from "./CreateRTKForm";
import RTKCard from "./RTKCard";

function EditRTK({ siteId, siteRTKs, states, defaultStateId }: EditRTKProps) {
  const [creatingRTK, setCreatingRTK] = React.useState(false);
  const [showAreYouSure, setshowAreYouSure] = React.useState(false);
  const [rtks, setRTks] = React.useState<RTKEntity[]>(
    siteRTKs.map((r, i) => {
      return {
        rtk: r,
        publish: false,
        index: i,
      };
    })
  );
  const [idIndex, setIdIndex] = React.useState(siteRTKs.length);
  const [importStateId, setImportStateId] = React.useState(defaultStateId);

  const updateUsersRef = React.useRef<HTMLInputElement>(null);
  const importRef = React.useRef<HTMLInputElement>(null);

  function createRTKModal() {
    if (creatingRTK) {
      return (
        <Modal>
          <CreateRTKForm handelCancel={() => setCreatingRTK(false)} handelCreate={createNewRTK} />
        </Modal>
      );
    }
  }

  function createAreYouSureModal() {
    if (showAreYouSure) {
      return (
        <Modal>
          <ConfirmRTKOverride
            onConfirm={() => {
              setshowAreYouSure(false);
              updateRTK();
            }}
            onDeny={() => setshowAreYouSure(false)}
          />
        </Modal>
      );
    }
  }

  function createNewRTK(
    name: string,
    username: string,
    password: string,
    mountPoint: string,
    hostname: string,
    port: number,
    sendGGA: boolean,
    publish: boolean
  ) {
    const tempRTKs = [...rtks];
    tempRTKs.push({
      rtk: {
        name,
        username,
        password,
        mountPoint,
        hostname,
        sendGGA,
        port,
      },
      publish,
      index: idIndex + 1,
    });
    setRTks(tempRTKs);
    setIdIndex(idIndex + 1);
    setCreatingRTK(false);
  }

  function handelDelete(index: number) {
    const tempRTKs = [...rtks];
    const deleteIndex = tempRTKs.findIndex((r) => r.index === index);
    tempRTKs.splice(deleteIndex, 1);
    setRTks(tempRTKs);
  }

  async function handelSubmitUpdate(e: React.FormEvent) {
    e.preventDefault();
    setshowAreYouSure(true);
  }

  async function updateRTK() {
    const submitButton = updateUsersRef.current;
    if (submitButton === null) {
      return;
    }
    submitButton.disabled = true;
    submitButton.classList.add("buttonClicked");

    const usersToAdd = rtks.filter((r) => !r.publish).map((r) => r.rtk);
    const api = new Api();

    try {
      await api.sites.putRtkUsers(siteId, { rtkUsers: usersToAdd }, await getRequestParams());
    } catch (e) {
      let errorMessage = "Unknown Error while updating rtk users";
      if (e instanceof Error) {
        errorMessage = `Failed to update rtk users. ${e.message}`;
      }
      window.alert(errorMessage);
      submitButton.disabled = false;
      submitButton.classList.remove("buttonClicked");
      return;
    }

    const usersToPublish = rtks.filter((r) => r.publish).map((r) => r.rtk);

    try {
      await api.rtkUsers.publishRtkUsers(defaultStateId, { rtkUsers: usersToPublish }, await getRequestParams());
    } catch (e) {
      let errorMessage = "Unknown Error while publishing rtk users";
      if (e instanceof Error) {
        errorMessage = `Failed to publish rtk users. ${e.message}`;
      }

      window.alert(errorMessage);
    } finally {
      submitButton.disabled = false;
      submitButton.classList.remove("buttonClicked");
    }
  }

  async function importRtkUser(e: React.FormEvent) {
    e.preventDefault();
    const submitButton = importRef.current;
    if (submitButton === null) {
      return;
    }
    submitButton.disabled = true;
    submitButton.classList.add("buttonClicked");

    try {
      const api = new Api();
      const users = (await api.rtkUsers.getRtkUsers({ stateId: importStateId }, await getRequestParams())).data
        .rtkUsers;

      const tempUsers = [...rtks];
      const newUsers = users
        .filter((r) => {
          return !tempUsers.some((s) => r.id === s.rtk.id);
        })
        .map((r, i) => {
          return {
            rtk: r,
            publish: false,
            index: i + idIndex,
          };
        });
      console.log(newUsers);
      tempUsers.push(...newUsers);
      setIdIndex(idIndex + newUsers.length);
      setRTks(tempUsers);
    } catch (e) {
      let errorMessage = "Unknown Error while importing rtk users";
      if (e instanceof Error) {
        errorMessage = `Failed import rtk users. ${e.message}`;
      }

      window.alert(errorMessage);
    } finally {
      submitButton.disabled = false;
      submitButton.classList.remove("buttonClicked");
    }
  }

  return (
    <div>
      <h2 className="pageHeader">Site RTKs</h2>
      <form onSubmit={handelSubmitUpdate}>
        <div className="rtkContainer">
          {rtks.map((rtk) => (
            <RTKCard
              name={rtk.rtk.name}
              username={rtk.rtk.username}
              password={rtk.rtk.password}
              mountPoint={rtk.rtk.mountPoint}
              hostname={rtk.rtk.hostname}
              port={rtk.rtk.port}
              index={rtk.index}
              sendGGA={rtk.rtk.sendGGA}
              publish={rtk.publish}
              handelDelete={handelDelete}
            />
          ))}
          <AddRTKCard
            onClick={() => {
              console.log(creatingRTK);
              setCreatingRTK(true);
            }}
          />
        </div>
        <span className="formSection">
          <span className="siteFormEntry">
            <input type="submit" value="Update RTK users" className="formButton" ref={updateUsersRef} />
          </span>
        </span>
      </form>
      <form onSubmit={importRtkUser}>
        <hr />
        <span className="formSection">
          Import RTK users from state
          <select
            id="stateId"
            name="stateId"
            defaultValue={defaultStateId}
            onChange={(e) => setImportStateId(e.target.value)}
          >
            {states.map((s) => {
              return (
                <option value={s.id}>
                  {s.countryName === s.stateName ? s.countryName : `${s.countryName}, ${s.stateName}`}
                </option>
              );
            })}
          </select>
        </span>
        <span className="formSection">
          <input type="submit" value="Import RTK users" className="formButton" ref={importRef} />
        </span>
      </form>
      {createRTKModal()}
      {createAreYouSureModal()}
    </div>
  );
}

export interface RTKEntity {
  rtk: RTKUser;
  publish: boolean;
  index: number;
}

export interface EditRTKProps {
  siteId: string;
  siteRTKs: RTKUser[];
  states: StateDetails[];
  defaultStateId: string;
}

export default EditRTK;
