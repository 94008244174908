import { Collapse } from "@mui/material";
import * as React from "react";
import ImageModal from "./ImageModal";

const COLLAPSE_TIMEOUT = 1000;

function AggregatorCard({ header, wrapperClass = "siteStatusWrapper", children }: SiteStatusProps) {
  const [collapsed, setCollapsed] = React.useState(false);

  function createCollapseButton() {
    if (collapsed) {
      return <img src="doubleArrowsDown.png" onClick={() => setCollapsed(false)} className="collapseImage" />;
    }
    return <img src="doubleArrowsUp.png" onClick={() => setCollapsed(true)} className="collapseImage" />;
  }

  return (
    <div className={wrapperClass}>
      <span className="siteHeader">
        {header}
        <span className="siteButtonWrapper">{createCollapseButton()}</span>
      </span>
      <Collapse in={!collapsed} timeout={COLLAPSE_TIMEOUT}>
        {children}
      </Collapse>
    </div>
  );
}

export interface SiteStatusProps {
  header: React.ReactNode;
  children: React.ReactNode;
  wrapperClass?: string;
}

export default AggregatorCard;
