import Button from "@mui/material/Button";
import * as React from "react";

const DOWNLOAD_TEXT_PREFIX = "data:text/plain;charset=utf-8,";

function downloadFile(fileName: string | undefined, url: string) {
  const fakeLink = document.createElement("a");
  fakeLink.style.display = "none";
  fakeLink.href = url;
  fakeLink.download = fileName ?? "download.txt";
  document.body.appendChild(fakeLink);
  try {
    fakeLink.click();
  } finally {
    document.body.removeChild(fakeLink);
  }
}

function DownloadButton({ buttonName, data, fileName, getDataCallback, disabled = false }: DownloadButtonProps) {
  return (
    <>
      <Button
        variant="outlined"
        disabled={disabled || (data === undefined && fileName === undefined && getDataCallback === undefined)}
        onClick={() => {
          let url: string | undefined = undefined;
          if (data !== undefined) {
            url = DOWNLOAD_TEXT_PREFIX + data;
          } else if (getDataCallback !== undefined) {
            url = DOWNLOAD_TEXT_PREFIX + getDataCallback();
          }

          if (url === undefined) {
            return;
          }
          downloadFile(fileName, url);
        }}
      >
        {buttonName}
      </Button>
    </>
  );
}

export interface DownloadButtonProps {
  buttonName: string;
  data?: string;
  fileName?: string;
  disabled?: boolean;
  getDataCallback?: () => string;
}

export default DownloadButton;
