import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import LoadingWheel from "../LoadingWheel";
import UserContext from "../Contexts/UserContext";
import RequestLogIn from "../RequestLogIn";
import InfoForm, { FormField } from "../Templates/InfoForm";
import TabsBar from "../TabsBar";
import S3Form from "../DetailedSiteDataForms/S3Form";
import OneDriveForm from "../DetailedSiteDataForms/OneDriveForm";
import { Api, ClientDetails, SiteDetails, StateDetails } from "../../Generated/ExoDBAPI";
import { getBearerToken } from "../../Utils/azureAuth";
import SitePolygonSection from "../DetailedSiteDataForms/SitePolygonSection";
import SiteScans from "../DetailedSiteDataForms/SiteScans";

const detailedViewTabs = ["General", "S3", "OneDrive", "Scans"];

/**
 * The detailed view for a site
 * @param {*} loadedStates loadedStates States loaded previously while on the site to save having to wait for them to load
 * @returns
 */
function DetailedSiteView({ loadedStates, loadedClients }: DetailedSiteViewProps) {
  const [site, setSite] = useState<SiteDetails>();
  const [location, setLocation] = useState<Location>();
  const [states, setStates] = useState(loadedStates);
  const [clients, setClients] = useState(loadedClients);
  const [activeTab, setActiveTab] = useState(detailedViewTabs[0]);
  const [sharepointUrl, setSharepointUrl] = useState<string>();

  const { user } = useContext(UserContext);

  let { siteId } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (user !== undefined && siteId !== undefined) {
        const api = new Api();
        try {
          api.sites
            .getSiteById(siteId, {
              baseUrl: process.env.REACT_APP_BACKEND_HOST,
              headers: { authorization: await getBearerToken() },
            })
            .then((res) => {
              setSite(res.data);
            });
        } catch (e) {
          let errorMessage = "Unknown Error when fetching site";
          if (e instanceof Error) {
            errorMessage = `Failed to load site. ${e.message}`;
          }
          window.alert(errorMessage);
          throw e;
        }
        api.states
          .getStates({
            baseUrl: process.env.REACT_APP_BACKEND_HOST,
            headers: { authorization: await getBearerToken() },
          })
          .then((res) => {
            setStates(res.data.states);
          });

        api.clients
          .getClients({
            baseUrl: process.env.REACT_APP_BACKEND_HOST,
            headers: { authorization: await getBearerToken() },
          })
          .then((res) => {
            setClients(res.data.clients);
          });

        api.sites
          .getSharepointUrl(siteId, {
            baseUrl: process.env.REACT_APP_BACKEND_HOST,
            headers: { authorization: await getBearerToken() },
          })
          .then((res) => {
            setSharepointUrl(res.data.sharepointURL);
          });
      }
    }
    fetchData();
  }, [siteId, user]);

  useEffect(() => {
    if (states.length !== 0 && site !== undefined) {
      const state = states.filter((s) => s.id === site.stateId)[0];
      setLocation({
        stateName: state.stateName,
        countryName: state.countryName,
      });
    }
  }, [states, site]);

  /**
   *
   * @returns Returns the location in {countryName}, {stateName} format
   */
  function getLocation() {
    if (location === undefined) {
      return "";
    }
    if (location.countryName !== location.stateName) {
      return `${location.countryName}, ${location.stateName}`;
    } else {
      return location.countryName;
    }
  }

  function getClientName(clientId: string) {
    if (clients && clients.length > 0) {
      const client = clients.filter((c) => c.id === clientId)[0];
      return client.name;
    }

    return "";
  }

  /**
   * Helper function for creating the site detailed view. if the site isn't loaded yet it returns null
   * @returns the detailed view DOM
   */
  function createDetailedView() {
    if (user === undefined) {
      return <RequestLogIn />;
    }
    if (site === undefined) {
      return <LoadingWheel />;
    }

    function createContactSection() {
      if (site === undefined || siteId === undefined) {
        return;
      }
      if (site.contacts.length === 0) {
        return;
      }

      return (
        <>
          <hr />
          <h2 className="pageHeader">Contacts</h2>
          <div className="detailedSiteView">
            {site.contacts.map((c) => {
              return (
                <span className="contactInfo">
                  <span className="contactName">{c.name}</span>, <span className="contactJob">{c.jobTitle}</span>
                  <br />
                  <span className="contactPhone">{c.phone}</span>
                </span>
              );
            })}
            <hr />
            <SitePolygonSection siteId={siteId} />
          </div>
        </>
      );
    }

    function getForm() {
      if (site === undefined) {
        return;
      }
      if (activeTab === "General") {
        const formFields: FormField[] = [
          { name: "Location", value: getLocation() },
          { name: "Created on", value: site.creationDate },
          { name: "Site type", value: site.siteType },
          { name: "Client name", value: getClientName(site.clientId) },
        ];
        return (
          <>
            <InfoForm formFields={formFields} />

            {createContactSection()}
          </>
        );
      }
      if (activeTab === "S3" && siteId !== undefined) {
        const formFields = [
          { name: "Polygon directory", value: site.polygonsDir },
          {
            name: "Geolocated pictures directory",
            value: site.geoLocatedPictureDir,
          },
          {
            name: "Geolocated pictures directory",
            value: site.geoLocatedFilesDir,
          },
          { name: "Pictures directory", value: site.picturesDir },
          { name: "Soil type directory", value: site.soilTypeDir },
        ];

        return <S3Form formFields={formFields} siteId={siteId} />;
      }
      if (activeTab === "OneDrive" && siteId !== undefined && sharepointUrl !== undefined) {
        return <OneDriveForm siteId={siteId} sharepointUrl={sharepointUrl} />;
      }
      if (activeTab === "Scans") {
        return <SiteScans s3Prefix={site.s3Prefix} />;
      }
    }

    return (
      <div className="pageWrapper">
        <h2 className="pageHeader">{site.name}</h2>
        <TabsBar
          tabs={detailedViewTabs}
          activeTab={activeTab}
          onTabClick={(tabName: string) => setActiveTab(tabName)}
        />
        <div className="tabsInfoContainer">{getForm()}</div>
      </div>
    );
  }
  return <>{createDetailedView()}</>;
}

export interface DetailedSiteViewProps {
  loadedStates: StateDetails[];
  loadedClients: ClientDetails[];
}

export interface Location {
  countryName: string;
  stateName: string;
}

export default DetailedSiteView;
