import React from "react";
import { Autocomplete, Stack, TextField } from "@mui/material";
import {
  useGetCustomersNamesLazyQuery,
  useGetLocationsLazyQuery,
  useGetAllSitesInfoForChoosingLazyQuery,
} from "../../Generated/Graphql";
import { CustomerInfo, LocationInfo, parseLocations, parseSites, parseCustomers } from "../../Utils/parseCommonQueries";

export interface SiteInfo {
  id: string;
  name: string;
  s3Ref: string;
  customerId: string;
  locationId: string;
}

export const ALL_STATES: LocationInfo = { id: "", name: "All" };
export const ALL_CUSTOMERS: CustomerInfo = { id: "", name: "All" };

function ChooseSite({ setSelectedSites, multiple = false }: ChooseSiteProps) {
  const [allSitesOptions, setAllSitesOptions] = React.useState<SiteInfo[]>([]);
  const [localSelectedSites, setLocalSelectedSites] = React.useState<SiteInfo[]>([]);

  const [allStatesOptions, setAllStatesOptions] = React.useState<LocationInfo[]>([ALL_STATES]);
  const [selectedLocation, setSelectedLocation] = React.useState<LocationInfo>(ALL_STATES);
  const [allCustomersOptions, setAllCustomersOptions] = React.useState<CustomerInfo[]>([ALL_CUSTOMERS]);
  const [selectedCustomer, setSelectedCustomer] = React.useState<CustomerInfo>(ALL_CUSTOMERS);

  const [, getAllSitesQuery] = useGetAllSitesInfoForChoosingLazyQuery();
  const [, getAllLocationsQuery] = useGetLocationsLazyQuery();
  const [, getAllCustomersQuery] = useGetCustomersNamesLazyQuery();

  React.useEffect(() => {
    async function load() {
      const sitesPromise = parseSites(getAllSitesQuery);
      const locationsPromise = parseLocations(getAllLocationsQuery);
      const customersPromise = parseCustomers(getAllCustomersQuery);

      const sites = (await sitesPromise).sort((a, b) => a.name.localeCompare(b.name));
      const locations = (await locationsPromise).sort((a, b) => a.name.localeCompare(b.name));
      const customers = (await customersPromise).sort((a, b) => a.name.localeCompare(b.name));

      setAllSitesOptions(
        sites.map((site) => {
          const locationMatches = locations.filter((location) => location.id === site.locationId);
          const customerMatches = customers.filter((customer) => customer.id === site.customerId);
          return {
            id: site.id,
            name: site.name,
            s3Ref: site.siteS3Ref,
            locationId: locationMatches.length === 1 ? locationMatches[0].id : "unknown",
            customerId: customerMatches.length === 1 ? customerMatches[0].id : "unknown",
          };
        })
      );
      setAllStatesOptions([ALL_STATES, ...locations]);
      setAllCustomersOptions([ALL_CUSTOMERS, ...customers]);
    }
    load();
  }, [getAllSitesQuery, getAllLocationsQuery, getAllCustomersQuery]);

  const siteOptions = React.useMemo(() => {
    return allSitesOptions.filter((site) => {
      if (selectedLocation.id !== "" && site.locationId !== selectedLocation.id) {
        return false;
      }

      if (selectedCustomer.id !== "" && site.customerId !== selectedCustomer.id) {
        return false;
      }

      return true;
    });
  }, [allSitesOptions, selectedLocation, selectedCustomer]);

  return (
    <Stack direction="row" spacing={2}>
      <Autocomplete
        options={allStatesOptions}
        getOptionLabel={(o) => o?.name}
        renderInput={(params) => <TextField {...params} label="State" />}
        sx={{ width: 200 }}
        value={selectedLocation}
        onChange={(_e, state) => setSelectedLocation(state ?? ALL_STATES)}
      />

      <Autocomplete
        options={allCustomersOptions}
        getOptionLabel={(o) => o?.name}
        renderInput={(params) => <TextField {...params} label="Customer" />}
        sx={{ width: 150 }}
        value={selectedCustomer}
        onChange={(_e, customer) => setSelectedCustomer(customer ?? ALL_CUSTOMERS)}
      />

      <Autocomplete
        options={siteOptions}
        getOptionLabel={(o) => o?.name ?? "undefined"}
        renderInput={(params) => <TextField {...params} label="Site" error={localSelectedSites.length === 0} />}
        sx={{ width: 500 }}
        multiple={multiple}
        onChange={(_e, sitesValue) => {
          const sites = sitesValue === null ? [] : multiple ? (sitesValue as SiteInfo[]) : [sitesValue as SiteInfo];
          setSelectedSites(sites);
          setLocalSelectedSites(sites);
        }}
      />
    </Stack>
  );
}

export interface ChooseSiteProps {
  setSelectedSites: (site: SiteInfo[]) => void;
  multiple?: boolean;
}

export default ChooseSite;
