import React from "react";

function LoadingWheel({ dim, borderWidth }: LoadingWheelProps) {
  if (dim === undefined) {
    return <div className="loadingWheel"></div>;
  }
  return <div className="loadingWheel" style={{ height: dim, width: dim, borderWidth }}></div>;
}

interface LoadingWheelProps {
  dim?: number;
  borderWidth?: number;
}

export default LoadingWheel;
