import * as React from "react";
import { Box } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalBox = React.forwardRef(function ModalBox(props: any, ref) {
  return <Box ref={ref} component="span" {...{ ...props, ...{ sx: style } }} />;
});

export default ModalBox;
