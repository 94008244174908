import { Dict } from '../BasicTypes';

const maxZoom = 25;
const maxTilesZoom = 22;
const maxOpenStreetMapZoom = 19;

export const DefaultExtraTiffLayerConfig = {
  maxNativeZoom: maxTilesZoom,
  authorizationRequired: true
};

export const START_MAX_FREQUENCY_DELTA = 100;

export function getMapConfig(): GetMapConfigResponse {
  return {
    maxZoom: maxZoom,
    tilesLayers: [
      {
        urlFormat: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attributions: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        maxNativeZoom: maxOpenStreetMapZoom, // Maximum zoom supported
        authorizationRequired: false,
        opacity: 1
      },
      {
        urlFormat: process.env.REACT_APP_BACKEND_HOST + '/tiles/{z}/{x}/{y}/png',
        maxNativeZoom: maxTilesZoom, // Maximal zoom we support tiles for,
        authorizationRequired: true,
        opacity: 1
      }
    ]
  };
}

const LIME_GREEN_MAG_ARROW = {
  default: 'rgb(152,230,0)',
  Lowest: 'rgb(152,230,0)',
  Low: 'rgb(152,230,0)',
  Medium: 'rgb(152,230,0)',
  High: 'rgb(152,230,0)',
  Highest: 'rgb(152,230,0)'
};
const GREEN_MAGNETIC = {
  default: 'rgb(43,145,20)',
  Lowest: 'rgb(43,145,20)',
  Low: 'rgb(43,145,20)',
  Medium: 'rgb(43,145,20)',
  High: 'rgb(43,145,20)',
  Highest: 'rgb(43,145,20)'
};
const PEACH_METAL_DETECTOR = {
  default: 'rgb(250,185,137)',
  Lowest: 'rgb(250,185,137)',
  Low: 'rgb(250,185,137)',
  Medium: 'rgb(250,185,137)',
  High: 'rgb(250,185,137)',
  Highest: 'rgb(250,185,137)'
};
const STRONG_RED_GPR = {
  default: 'rgb(255,0,0)',
  Lowest: 'rgb(255,0,0)',
  Low: 'rgb(255,0,0)',
  Medium: 'rgb(255,0,0)',
  High: 'rgb(255,0,0)',
  Highest: 'rgb(255,0,0)'
};
const DARK_BLUE_LEMI = {
  default: 'rgb(0,71,255)',
  Lowest: 'rgb(0,71,255)',
  Low: 'rgb(0,71,255)',
  Medium: 'rgb(0,71,255)',
  High: 'rgb(0,71,255)',
  Highest: 'rgb(0,71,255)'
};
const LIGHT_BLUE_MINI_LEMON_DRONE = {
  default: 'rgb(0,180,255)',
  Lowest: 'rgb(0,180,255)',
  Low: 'rgb(0,180,255)',
  Medium: 'rgb(0,180,255)',
  High: 'rgb(0,180,255)',
  Highest: 'rgb(0,180,255)'
};
const BLUE_MINI_LEMON = {
  default: 'rgb(0,135,255)',
  Lowest: 'rgb(0,135,255)',
  Low: 'rgb(0,135,255)',
  Medium: 'rgb(0,135,255)',
  High: 'rgb(0,135,255)',
  Highest: 'rgb(0,135,255)'
};
const PURPLE_GV3_GPR = {
  default: '#AC33FF',
  Lowest: '#AC33FF',
  Low: '#AC33FF',
  Medium: '#AC33FF',
  High: '#AC33FF',
  Highest: '#AC33FF'
};
const PURPLE_TEMP_IDS_OPERA = {
  default: '#6F00BD ',
  Lowest: '#6F00BD ',
  Low: '#6F00BD ',
  Medium: '#6F00BD ',
  High: '#6F00BD ',
  Highest: '#6F00BD '
};
const PURPLE_TEMP_GSSI_UTILITYSCAN_DF = {
  default: '#686973',
  Lowest: '#686973',
  Low: '#686973',
  Medium: '#686973',
  High: '#686973',
  Highest: '#686973'
};
const PURPLE_TEMP_GSSI_UTILITYSCAN = {
  default: '#B55CD1',
  Lowest: '#B55CD1',
  Low: '#B55CD1',
  Medium: '#B55CD1',
  High: '#B55CD1',
  Highest: '#B55CD1'
};
const PURPLE_TEMP_IMPULSE_RADAR = {
  default: '#B57060',
  Lowest: '#B57060',
  Low: '#B57060',
  Medium: '#B57060',
  High: '#B57060',
  Highest: '#B57060'
};
const PURPLE_TEMP_SCREENING_EAGLE = {
  default: '#996517',
  Lowest: '#996517',
  Low: '#996517',
  Medium: '#996517',
  High: '#996517',
  Highest: '#996517'
};
const PURPLE_TEMP_KONTUR_8 = {
  default: '#17998C',
  Lowest: '#17998C',
  Low: '#17998C',
  Medium: '#17998C',
  High: '#17998C',
  Highest: '#17998C'
};
const PURPLE_TEMP_QUANTUM_IMAGER = {
  default: '#FC037F',
  Lowest: '#FC037F',
  Low: '#FC037F',
  Medium: '#FC037F',
  High: '#FC037F',
  Highest: '#FC037F'
};

export const SENSONRS_AND_CONFIDENCE_COLORS = {
  lemi: {
    default: 'rgb(0,71,255)',
    Highest: '#0047AB',
    High: '#0000FF',
    Medium: '#6495ED',
    Low: '#89CFF0',
    Lowest: '#F0FFFF'
  },
  ids: {
    default: '#9654e3',
    Highest: '#5D3FD3',
    High: '#7F00FF',
    Medium: '#CF9FFF',
    Low: '#CBC3E3',
    Lowest: '#E6E6FA'
  },
  md: {
    default: ' #f7b945',
    Highest: '#EC5800',
    High: '#CC7722',
    Medium: '#FFA500',
    Low: '#FAC898',
    Lowest: '#FFE5B4'
  },
  flc: {
    default: '#1c8c14',
    Highest: '#008000',
    High: '#4F7942',
    Medium: '#5F8575',
    Low: '#50C878',
    Lowest: '#AFE1AF'
  },
  cobra: {
    default: 'rgb(255,0,0)',
    Highest: '#B22222',
    High: '#DC143C',
    Meduim: '#FF0000',
    Low: '#CD5C5C',
    Lowest: '#F08080'
  }
};

export function getFeaturesGraphicConfig(): FeaturesGraphicConfigResponse {
  return {
    sensorColorbyConfidenceMatrix: {
      LEMI_150: SENSONRS_AND_CONFIDENCE_COLORS.lemi,
      GRADIOMETRY_LEMI: SENSONRS_AND_CONFIDENCE_COLORS.lemi,
      MINILEMON_GROUND: SENSONRS_AND_CONFIDENCE_COLORS.lemi,
      GRADIOMETRY_MINILEMON: SENSONRS_AND_CONFIDENCE_COLORS.lemi,
      MINILEMON_DRONE: SENSONRS_AND_CONFIDENCE_COLORS.lemi,
      GRADIOMETRY_FLC_370: SENSONRS_AND_CONFIDENCE_COLORS.flc,
      GRADIOMETRY_MAG_03: SENSONRS_AND_CONFIDENCE_COLORS.flc,
      FLC_370: SENSONRS_AND_CONFIDENCE_COLORS.flc,
      MAGARROW: LIME_GREEN_MAG_ARROW,
      MAG_03: GREEN_MAGNETIC,
      TEMP_IDS_OPERA_DUO: SENSONRS_AND_CONFIDENCE_COLORS.ids,
      IDS_STREAM_DP: SENSONRS_AND_CONFIDENCE_COLORS.ids,
      GV3_TRIVUE_GPR: SENSONRS_AND_CONFIDENCE_COLORS.ids,
      COBRA_GPR: SENSONRS_AND_CONFIDENCE_COLORS.cobra,
      NEL_MD: SENSONRS_AND_CONFIDENCE_COLORS.md
    },
    // transmitter:{
    //   Clamp:''
    // },
    defaultColor: {
      // Purple
      default: 'rgb(119, 28, 184)',
      Lowest: 'rgb(119, 28, 184)',
      Low: 'rgb(119, 28, 184)',
      Medium: 'rgb(119, 28, 184)',
      High: 'rgb(119, 28, 184)',
      Highest: 'rgb(119, 28, 184)'
    },
    travelCourseColor: {
      // Black
      default: 'rgb(0, 0, 0)',
      Lowest: 'rgb(0, 0, 0)',
      Low: 'rgb(0, 0, 0)',
      Medium: 'rgb(0, 0, 0)',
      High: 'rgb(0, 0, 0)',
      Highest: 'rgb(0, 0, 0)'
    },
    transmitterMethod: {
      clamp: '#ff69ba',
      induction: '#20b2aa',
      injection: '#ff6347',
      unknown: '#00fa9a'
    },
    transmitterPolygonColor: [
      'rgb(255,204,255)', // Pink/Purple
      'rgb(178,102,255)', // Strong Purple
      'rgb(153,76,0)', // Brown
      'rgb(102,255,255)', // Cyan
      'rgb(128,128,0)' // Olive
    ],
    defaultMarkerColor: 'gold',
    transmitterMarkerBySensorMapping: {},

    radius: 1,
    defaultMarker: '',
    pipeWeight: 6,
    filterPolygonDisplayParams: {
      color: 'purple',
      fillOpacity: 0.05
    },
    selectedEntityColor: '#FFFF00'
  };
}

export interface FeaturesGraphicConfigResponse {
  sensorColorbyConfidenceMatrix: Dict<Dict<string>>;
  defaultColor: Dict<string>;
  travelCourseColor: Dict<string>;
  transmitterMethod: Dict<string>;
  transmitterPolygonColor: string[];
  transmitterMarkerBySensorMapping: Dict<string>;
  radius: number;
  defaultMarkerColor: string;
  defaultMarker: string;
  pipeWeight: number;
  filterPolygonDisplayParams: FilterPolygonDisplayParams;
  selectedEntityColor: string;
}

export interface TilesLayerConfig {
  urlFormat: string;
  attributions?: string;
  minNativeZoom?: number;
  maxNativeZoom?: number;
  minZoom?: number;
  authorizationRequired: boolean;
  opacity: number;
  esriLayer?: boolean;
}

export interface GetMapConfigResponse {
  maxZoom: number;
  tilesLayers: TilesLayerConfig[];
}

export interface FilterPolygonDisplayParams {
  color: string;
  fillOpacity: number;
}
