import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { Modal, Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";

const TABLE_CELL_PROPS = { sx: { borderLeft: "1px solid rgba(224, 224, 224, 1)" } };

function MissingEntitiesForm({ presentFeaturesIds, s3FeaturesIds, loading, triggerRefresh }: MissingEntitiesFormProps) {
  const [presentMissingFeaturesIds, setPresentMissingFeaturesIds] = React.useState<string[]>([]);
  const [s3MissingFeaturesIds, setS3MissingFeaturesIds] = React.useState<string[]>([]);
  const [showLocallyMissing, setShowLocallyMissing] = React.useState(true);
  const [showS3Missing, setShowS3Missing] = React.useState(true);
  const [showGPS, setShowGPS] = React.useState(false);

  React.useEffect(() => {
    if (!loading) {
      setPresentMissingFeaturesIds(s3FeaturesIds.filter((id) => !presentFeaturesIds.includes(id)));
      setS3MissingFeaturesIds(presentFeaturesIds.filter((id) => !s3FeaturesIds.includes(id)));
    }
  }, [JSON.stringify(presentFeaturesIds), s3FeaturesIds, showGPS, loading]);

  function gpsPredicate(id: string) {
    return !id.includes("__gps__") || showGPS;
  }

  function createTable() {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ backgroundColor: "rgb(242,242,245)" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "rgb(230,230,250)" }}>
              <TableCell>Type</TableCell>
              <TableCell>S3 Path</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showLocallyMissing &&
              presentMissingFeaturesIds.filter(gpsPredicate).map((id) => (
                <TableRow key={id}>
                  <TableCell {...TABLE_CELL_PROPS}>Missing Locally</TableCell>
                  <TableCell {...TABLE_CELL_PROPS}>{id}</TableCell>
                </TableRow>
              ))}
            {showS3Missing &&
              s3MissingFeaturesIds.filter(gpsPredicate).map((id) => (
                <TableRow key={id}>
                  <TableCell {...TABLE_CELL_PROPS}>Not in S3</TableCell>
                  <TableCell {...TABLE_CELL_PROPS}>{id}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <Stack direction="row">
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Missing Features
        </Typography>
        <IconButton onClick={() => triggerRefresh()} disabled={loading}>
          <RefreshIcon />
        </IconButton>
      </Stack>
      {presentMissingFeaturesIds.length === 0 && s3MissingFeaturesIds.length === 0 && (
        <Typography>All the features are present</Typography>
      )}
      {(presentMissingFeaturesIds.length !== 0 || s3MissingFeaturesIds.length !== 0) && (
        <FormGroup>
          <Stack direction="row" spacing={1}>
            <FormControlLabel control={<Checkbox onChange={(e) => setShowGPS(e.target.checked)} />} label="Show GPS" />
            <FormControlLabel
              control={<Checkbox onChange={(e) => setShowLocallyMissing(e.target.checked)} defaultChecked={true} />}
              label="Show Locally Missing"
            />
            <FormControlLabel
              control={<Checkbox onChange={(e) => setShowS3Missing(e.target.checked)} defaultChecked={true} />}
              label="Show S3 Missing"
            />
          </Stack>
        </FormGroup>
      )}
      <Stack spacing={2}>
        <>
          {(presentMissingFeaturesIds.length > 0 || s3MissingFeaturesIds.length > 0) && (
            <>
              <Stack direction="row" spacing={1}>
                <Typography>Missing Locally: {presentMissingFeaturesIds.filter(gpsPredicate).length}</Typography>
                <Typography>Missing From S3: {s3MissingFeaturesIds.filter(gpsPredicate).length}</Typography>
              </Stack>
              {createTable()}
            </>
          )}
        </>
      </Stack>
    </>
  );
}

export interface MissingEntitiesFormProps {
  presentFeaturesIds: string[];
  s3FeaturesIds: string[];
  loading: boolean;
  triggerRefresh: () => void;
}

export default MissingEntitiesForm;
