import { Dict } from "../BasicTypes";
import { Api, FileType } from "../Generated/ExoDBAPI";
import { FileUploader } from "./fileUploaderFactory";
import partsUploader from "./partsUploader";

class PreSignedUrlMultipartFileUploader implements FileUploader {
  fileData: File;
  fileName: string;
  fileType: FileType;
  siteId: string;
  backendClient: Api<null>;
  requestParams: Dict<string>;
  maxPartSize: number;
  retries: number;
  timeBetweenTries: number;
  date?: string;
  constructor(
    fileData: File,
    fileName: string,
    fileType: FileType,
    siteId: string,
    backendClient: Api<null>,
    requestParams: Dict<string>,
    date?: string,
    maxPartSize: number = 100_000_000,
    retries: number = 10,
    timeBetweenTries: number = 5000
  ) {
    this.fileData = fileData;
    this.fileName = fileName;
    this.fileType = fileType;
    this.siteId = siteId;
    this.backendClient = backendClient;
    this.requestParams = requestParams;
    this.maxPartSize = maxPartSize;
    this.retries = retries;
    this.timeBetweenTries = timeBetweenTries;
    this.date = date;
  }

  async uploadFile(onProgress = (e: number) => {}) {
    const fileParts = this.splitFile();
    const { urls, uploadId } = await this.getPresginedUrls(fileParts.length);

    const filePartsUploader = new partsUploader(fileParts, urls, uploadId, this.retries, this.timeBetweenTries);
    const res = await filePartsUploader.uploadFile(onProgress);
    const resp = await this.backendClient.sites.endMultipartFileUpload(
      this.siteId,
      {
        fileName: this.fileName,
        fileType: this.fileType,
        uploadId: uploadId,
        parts: res,
        date: this.date,
      },
      this.requestParams
    );

    return resp.data;
  }

  splitFile() {
    let startPointer = 0;
    let endPointer = this.fileData.size;
    let chunks = [];
    while (startPointer < endPointer) {
      const newStartPointer = startPointer + this.maxPartSize;
      chunks.push(this.fileData.slice(startPointer, newStartPointer));
      startPointer = newStartPointer;
    }
    return chunks;
  }

  async getPresginedUrls(partAmount: number) {
    return (
      await this.backendClient.sites.startMultipartFileUpload(
        this.siteId,
        {
          fileName: this.fileName,
          fileType: this.fileType,
          date: this.date,
          parts: partAmount,
        },
        this.requestParams
      )
    ).data;
  }
}

export default PreSignedUrlMultipartFileUploader;
