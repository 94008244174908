import React from "react";
import axios from "axios";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { StatusCodes as HttpStatusCode } from "http-status-codes";

import { getRequestParamsOnlyHeaders } from "../../../Utils/azureAuth";
import LoadingWheel from "../../LoadingWheel";

function ExportDataForm({ graphqlSiteId, analysisIds, mappingIds }: ExportDataFormProps) {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const downloadLinkRef = React.useRef<HTMLAnchorElement>(null);

  async function onClick() {
    if (graphqlSiteId === undefined || downloadLinkRef.current === null) {
      alert("You somehow try to export data without a site");
      return;
    }
    setLoading(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_SERVICES_BACKEND_HOST}/export/view/site/${graphqlSiteId}`,
        method: "POST",
        ...(await getRequestParamsOnlyHeaders()),
        data: JSON.stringify({
          analysisIds: analysisIds,
          mappingIds: mappingIds,
        }),
      });

      if (response.status === HttpStatusCode.NO_CONTENT && response.headers["location"] !== undefined) {
        // Big data - Url location added
        downloadLinkRef.current.href = response.headers["location"];
      } else if (response.status === HttpStatusCode.OK && parseInt(response.headers["content-length"]) > 0) {
        // Regular data - Given in response
        downloadLinkRef.current.href = "data:text/plain;base64," + response.data;
      } else {
        window.alert("No data was returned");
        return;
      }

      const match = response.headers["content-disposition"].match(/filename="(?<filename>[^"]+)"/);
      downloadLinkRef.current.download = match?.groups?.filename ?? "unknown_site.exported.zip";
      downloadLinkRef.current.click();
    } finally {
      setLoading(false);
    }
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h5">Download Site Features</Typography>
      <Button disabled={graphqlSiteId === undefined || isLoading} onClick={onClick}>
        Download Site
      </Button>
      {isLoading ? <LoadingWheel /> : <></>}
      <a href="" download={"placeholder.zip"} style={{ display: "hidden" }} ref={downloadLinkRef} />
    </Stack>
  );
}

export interface ExportDataFormProps {
  graphqlSiteId?: string;
  analysisIds: string[];
  mappingIds: string[];
}

export default ExportDataForm;
