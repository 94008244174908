import * as React from "react";
import { ArcGISResourceType, ResourceInfo } from "../../ArcGISResources/LoadResourcesForm";
import EsriFeatureServer from "./EsriFeatureServer";
import { EsriLayerGroup } from "./EsriLayer";
import EsriService from "../ArcGISFeatures/EsriMapServer";

function EsriFeatures({ arcGISResources }: EsriFeaturesProps) {
  return (
    <>
      {arcGISResources.map((resource) => {
        if (resource.type === ArcGISResourceType.MapServer) {
          return <EsriService url={resource.url} namePrefix={resource.name} key={`arcgis-ms-${resource.name}`} />;
        }
        if (resource.type === ArcGISResourceType.FeatureLayer) {
          return <EsriLayerGroup name={resource.name} url={resource.url} key={`arcgis-f-${resource.name}`} />;
        }
        if (resource.type === ArcGISResourceType.FeatureServer) {
          return <EsriFeatureServer url={resource.url} namePrefix={resource.name} key={`arcgis-fs-${resource.name}`} />;
        }
      })}{" "}
    </>
  );
}

export interface EsriFeaturesProps {
  arcGISResources: ResourceInfo[];
}

export default EsriFeatures;
