import * as React from 'react';
import { Circle, Tooltip } from 'react-leaflet';
import { getFeaturesGraphicConfig, START_MAX_FREQUENCY_DELTA } from '../../../config/mapConfig';
import { TooltipPaneName } from '../../../config/paneConfig';
import { getPointCoordinates } from '../../../Utils/geoJsonUtils';
import { getTransmitterClaimedFrequency } from '../DataTypes/MapFeatures';
import { AnalysisProductsTransmitter } from '../DataTypes/MapEntities';
import { DisplayGroup } from '../DataTypes/DisplayModes';
import { useMapPipeEntitiesStore } from '../store';

function TransmitterMapEntities({
  transmitters,
  maxAllowedFrequency,
  setSelectedTransmitter,
  setChosenDisplayGroup,
  chosenDisplayGroup,
  setPreviousDisplayGroup
}: TransmitterMapEntitiesProps) {
  const { selectedPipeScanTransmitters, showPipeTransmitters, selectedPipeTravelCourse } =
    useMapPipeEntitiesStore();

  const displayTransmitters = selectedPipeScanTransmitters.length
    ? selectedPipeScanTransmitters
    : transmitters;

  function getOptionalStringVal<T>(val?: T) {
    if (val === undefined) {
      return 'Unknown';
    }
    return val;
  }

  function generateTransmitterInfoLines(
    transmitter: AnalysisProductsTransmitter,
    claimedFrequency?: number
  ): string[] {
    const scan = transmitter.header.scan;
    const frequencyString = claimedFrequency === undefined ? 'unknown' : `${claimedFrequency}hz`;
    return [
      `Frequency: ${transmitter.frequency}hz`,
      `Claimed Freq: ${frequencyString}`,
      `Sensor: ${scan.sensor}`,
      `Found By: ${transmitter.header.user}`,
      `Found At: p${scan.polygon}s${scan.snake}_part${scan.part}`,
      `Scan From: ${scan.date}`,
      `Method: ${getOptionalStringVal(transmitter.method)}`,
      `Source: ${getOptionalStringVal(transmitter.source)}`,
      `Snr: ${getOptionalStringVal(transmitter.snr ? transmitter.snr.toFixed(3) : undefined)}`,
      transmitter.asset_id ? `Transmitter asset: ${transmitter.asset_id}` : ''
    ];
  }
  function getTransmitterDisplayInfo(
    transmitter: AnalysisProductsTransmitter,
    claimedFrequency?: number
  ): [number, string] {
    const featureGraphicConfig = getFeaturesGraphicConfig();
    let color = featureGraphicConfig.defaultMarkerColor;
    if (
      claimedFrequency !== undefined &&
      Math.abs(transmitter.frequency - claimedFrequency) >
        (maxAllowedFrequency ?? START_MAX_FREQUENCY_DELTA)
    ) {
      color = 'black';
    } else if (transmitter.header.scan.polygon !== undefined) {
      color =
        featureGraphicConfig.transmitterPolygonColor[
          transmitter.header.scan.polygon % featureGraphicConfig.transmitterPolygonColor.length
        ];
    }

    return [featureGraphicConfig.radius, color];
  }

  function createTransmitterHalo(transmitter: AnalysisProductsTransmitter) {
    const claimedFrequency = getTransmitterClaimedFrequency(transmitter);
    const [radius, color] = getTransmitterDisplayInfo(transmitter, claimedFrequency);
    const geoJson = JSON.parse(transmitter.header.geoJson);
    const center = getPointCoordinates(geoJson);

    return (
      <>
        <Circle
          radius={radius * 3}
          center={[center[1], center[0]]}
          pathOptions={{ color: color, fillColor: color, opacity: 0.05 }}
          key={`transmitter-halo${transmitter.header.id}`}
        />
      </>
    );
  }
  function createTransmitter(transmitter: AnalysisProductsTransmitter) {
    const claimedFrequency = getTransmitterClaimedFrequency(transmitter);
    const [radius, color] = getTransmitterDisplayInfo(transmitter, claimedFrequency);
    const messageLines = generateTransmitterInfoLines(transmitter, claimedFrequency);
    const geoJson = JSON.parse(transmitter.header.geoJson);
    const center = getPointCoordinates(geoJson);

    return (
      <>
        <Circle
          eventHandlers={{
            click() {
              setSelectedTransmitter(transmitter);
              setPreviousDisplayGroup(chosenDisplayGroup);
              setChosenDisplayGroup(DisplayGroup.SELECTED_TRANSMITTER_SCAN_PIPES);
            }
          }}
          radius={radius}
          center={[center[1], center[0]]}
          pathOptions={{ color: color, fillColor: color }}
          key={`transmitter${transmitter.header.id}`}>
          <Tooltip pane={TooltipPaneName} key={`tool-${transmitter.header.id}`}>
            {messageLines.map((messageLine) => (
              <>
                <div style={{ backgroundColor: 'white' }}>{messageLine}</div>
              </>
            ))}
          </Tooltip>
        </Circle>
      </>
    );
  }
  return (
    <>
      {displayTransmitters.map((t) => createTransmitterHalo(t))}
      {displayTransmitters.map((t) => createTransmitter(t))}
    </>
  );
}

export interface TransmitterMapEntitiesProps {
  transmitters: AnalysisProductsTransmitter[];
  maxAllowedFrequency?: number;
  setSelectedTransmitter: (transmitter: AnalysisProductsTransmitter) => void;
  setChosenDisplayGroup: (displayGroup: DisplayGroup) => void;
  setPreviousDisplayGroup: (displayGroup: DisplayGroup) => void;
  chosenDisplayGroup: DisplayGroup;
}

export default TransmitterMapEntities;
