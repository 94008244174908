import { useEffect, useMemo, useState } from "react";
import { AnalysisProductsTravelCourse } from "../../DataTypes/MapEntities"
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import SelectedValues from "./SelectedValues";
import { Button } from "@mui/material";
import './PolygonDrawer.css';

interface UnifyDataProps {
    areaId: number;
    item: string;
    polygon: string;
    scansIds: string[];
    travelFiltered: AnalysisProductsTravelCourse[];
    updateData: (areaId: number, polygon: number, scans: string[]) => void;
    removeData: (areaId: number, item: string) => void;
}

const getUniquePolygons = (travel: AnalysisProductsTravelCourse[]) => {
    const uniquePolygons = new Set(travel.map(head => head.header.scan.polygon));
    return Array.from(uniquePolygons);
};

const UnifyDataUnit = ({item, polygon, scansIds, travelFiltered, areaId, updateData, removeData }: UnifyDataProps) => {
    const [selectionPolygon, setSelectionPolygon] = useState<string | undefined>(polygon);
    const [selectionSnake, setSelectionSnake] = useState<string>('1');
    const [selectedIds, setSelectedIds] = useState<string[]>(scansIds || []);
    const scans = useMemo(() => travelFiltered.map(travel => travel.header.scan.snake === Number(selectionSnake) && travel.header.scan.polygon === Number(selectionPolygon) ? travel.header.scan.name : null).filter(scan => scan !== null)
    , [travelFiltered, selectionPolygon, selectionSnake]);
    const uniquePolygons = useMemo(() => getUniquePolygons(travelFiltered.filter(value => selectedIds.includes(value.header.scan.name))), [travelFiltered]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectionPolygon(event.target.value);
    };
    const handleChangeSnake = (event: SelectChangeEvent<string>) => {
        setSelectionSnake(event.target.value);
    };

    useEffect(() => {
        if (!selectionPolygon || !selectionSnake) return;
        updateData(areaId, Number(selectionPolygon), selectedIds);
    }, [selectedIds])


  return (<>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 8, }}>
                    <span className="blue-circle">{areaId}</span>
                    <FormControl sx={{ width: 100 }}>
                        <InputLabel id="polygon-select-label">Polygon</InputLabel>
                        <Select
                            labelId="polygon-select-label"
                            disabled={true}
                            value={selectionPolygon}
                            onChange={handleChange}
                            label="Select Polygon"
                            style={{ width: '100px' }}
                        >
                            {uniquePolygons.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: 100 }}>
                        <InputLabel id="snake-select-label">Snake</InputLabel>
                        <Select
                            labelId="snake-select-label"
                            value={selectionSnake}
                            onChange={handleChangeSnake}
                            label="Select Snake"
                            disabled={true}
                            style={{ width: '100px' }}
                        >
                            {['1'].map((option) => (
                                <MenuItem key={option} value={option} defaultValue={'1'}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <>
                    {selectionPolygon !== undefined && selectionSnake && scans.length > 0 && <SelectedValues<string>
                            optionsMapping={scans.filter(scan => scan !== null && selectedIds.includes(scan)) as string[]}
                            filterName={'Scans list'}
                            setSelection={setSelectedIds}
                            strategy={true}
                            recalculateTrigger={false}
                            resendOutputTrigger={true}
                            enabled={true}
                        />}
                    </>
                    <Button sx={{height: '24px'}} variant="contained" color="secondary" onClick={() => removeData(areaId, item)}>Clear</Button>
                </Box>
                <hr />
        </>
  )
}

export default UnifyDataUnit
