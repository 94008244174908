import { AnalysisProductsPipe, DepthPoint } from '../../DataTypes/MapEntities';
import { SiteWideDepthData, getGeoidValueNumber } from '../../DepthSupport/depthData';
import { getDepth } from '../../DepthSupport/depthUtils';
import { DEPTH_KEY, GROUND_KEY } from './constants';

export function generatePipeTooltip({
  content,
  backgroundColor,
  textColor
}: {
  content: string;
  backgroundColor: string;
  textColor: string;
}) {
  return `
    <div style="background-color:${backgroundColor};padding:12px;color:${textColor};border-radius:8px; font-size:14px">
     ${content}
    </div>`;
}

export function generatePipeInfoLines(pipe: AnalysisProductsPipe) {
  const scan = pipe.header.scan;

  let averageDepth: string | number = 'Unknown';
  const depths = pipe.depths;

  if (depths !== undefined && depths !== null && depths.length > 0) {
    averageDepth = (depths.reduce((a, b) => a + b, 0) / depths.length).toFixed(1);
  }

  const generateLine = (title: string, content?: string) => `
       <div style="display:flex;gap:4px;">
        <strong>${title}</strong>
        <span>${content}</span>
       </div>`;

  return `<div>
            ${generateLine('Name', pipe.header.name)}
            ${generateLine('Sensor', scan.sensor)}
            ${generateLine('Confidence', pipe.confidence)}
            ${generateLine('Avg. Depth', averageDepth)}
            ${generateLine('Found By', pipe.header.user)}
            ${generateLine('Found At', `p${scan.polygon}s${scan.snake}_part${scan.part}`)}
            ${generateLine('Scan From', scan.date)}
            ${generateLine('Auto Confidence', pipe.autoConfidence)}
            ${generateLine(DEPTH_KEY, '<not crossing>')}
            ${generateLine(GROUND_KEY, '<not crossing>')}
            ${pipe.frequency ? `${generateLine('Frequency', `${pipe.frequency}Hz`)}` : ''}
            ${pipe.header.comment ? `${generateLine('Comment', `${pipe.header.comment}`)}` : ''}
            ${
              pipe.pipesQAInfo?.comment
                ? `${generateLine('PipesQA comment', pipe.pipesQAInfo?.comment.text)}`
                : ''
            }
            ${
              pipe.pipesQAInfo?.status
                ? `${generateLine('PipesQA status', pipe.pipesQAInfo?.status)}`
                : ''
            }
          </div>
       `;
}

export function generateDepthInfoLines(
  pipe: AnalysisProductsPipe,
  point: DepthPoint,
  siteDepthInfo: SiteWideDepthData
) {
  let groundKeyLine;
  const scan = pipe.header.scan;

  let averageDepth: string | number = 'Unknown';
  const depths = pipe.depths;

  if (depths !== undefined && depths !== null && depths.length > 0) {
    averageDepth = (depths.reduce((a, b) => a + b, 0) / depths.length).toFixed(1);
  }

  const generateLine = (title: string, content?: string) => `
       <div style="display:flex;gap:4px;">
        <strong>${title}</strong>
        <span>${content}</span>
       </div>`;

  if (point.ellipsoidHeight === undefined) {
    groundKeyLine = generateLine(GROUND_KEY, '<not available>');
  } else {
    const ellipsoidHeight = point.ellipsoidHeight + point.depth;
    let height = ellipsoidHeight;
    let heightType = 'ellipsoid';
    if (getGeoidValueNumber(siteDepthInfo.geoidValue) !== undefined) {
      height = ellipsoidHeight - (siteDepthInfo.geoidValue as number);
      heightType = 'absolute';
    }
    groundKeyLine = generateLine(GROUND_KEY, `${getDepth(height)} (${heightType})`);
  }

  return `<div>
            ${generateLine('Name', pipe.header.name)}
            ${generateLine('Sensor', scan.sensor)}
            ${generateLine('Confidence', pipe.confidence)}
            ${generateLine('Avg. Depth', averageDepth)}
            ${generateLine('Found By', pipe.header.user)}
            ${generateLine('Found At', `p${scan.polygon}s${scan.snake}_part${scan.part}`)}
            ${generateLine('Scan From', scan.date)}
            ${generateLine('Auto Confidence', pipe.autoConfidence)}
            ${generateLine(DEPTH_KEY, getDepth(point.depth))}
            ${groundKeyLine}
            ${pipe.frequency ? `${generateLine('Frequency', `${pipe.frequency}Hz`)}` : ''}
            ${pipe.header.comment ? `${generateLine('Comment', `${pipe.header.comment}`)}` : ''}
            ${
              pipe.pipesQAInfo?.comment
                ? `${generateLine('PipesQA comment', pipe.pipesQAInfo?.comment.text)}`
                : ''
            }
            ${
              pipe.pipesQAInfo?.status
                ? `${generateLine('PipesQA status', pipe.pipesQAInfo?.status)}`
                : ''
            }
          </div>
       `;
}
