import { Scan } from "../../Generated/Graphql";


export const regex = /p(\d+)_s(\d+)_part(\d+)/;

export const comparator = (a: Scan, b: Scan): number => {
    const aPolygonIndex = a.polygon_index ?? 0;
    const bPolygonIndex = b.polygon_index ?? 0;
    const aSnakeIndex = a.snake_index ?? 0;
    const bSnakeIndex = b.snake_index ?? 0;
    const aPartIndex = a.part_index ?? 0;
    const bPartIndex = b.part_index ?? 0;

    if (aPolygonIndex !== bPolygonIndex) return aPolygonIndex - bPolygonIndex;
    if (aSnakeIndex !== bSnakeIndex) return aSnakeIndex - bSnakeIndex;
    return aPartIndex - bPartIndex;
};
