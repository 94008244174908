import { useMemo } from 'react';
import { ParsedTargetObect } from '../../../../../Types/MappingEntities';
import { Stack } from '@mui/material';
import { Typography, Table } from '../../../../../shared';
import { ColumnDef } from '@tanstack/react-table';
import { FilterBar } from '../Filter';
import { usePoiStore } from '../../store';
import { useMapRef } from '../../../store/mapRef';

export const PoiTable = () => {
  const { setSelectedPoi, selectedPoi, filteredPois } = usePoiStore();
  const { mapRef } = useMapRef();

  const columns: ColumnDef<ParsedTargetObect, any>[] = [
    {
      accessorKey: 'name',
      header: (p) => (
        <Typography fontWeight={600} textAlign="left">
          Name
        </Typography>
      )
    }
  ];

  const newFilteredPois = useMemo(() => {
    return filteredPois.map((p) => {
      if (p.target_info?.entity) {
        p.name = `${p.target_info.entity}-${p.name}`;
      } else {
        p.name = `manhole-${p.name}`;
      }
      return p;
    });
  }, [filteredPois]);

  const handleSelectRow = (p: ParsedTargetObect) => {
    const { lat, lon } = p.metadata.scan.gps_position;
    mapRef?.current?.flyTo([lat, lon], 24);
    setSelectedPoi(p);
  };

  return (
    <Stack flex={2} minHeight={0} height={300} maxHeight={300}>
      <FilterBar />
      <Table
        columns={columns}
        data={newFilteredPois}
        onRowClick={handleSelectRow}
        selectedRow={selectedPoi}
      />
    </Stack>
  );
};
