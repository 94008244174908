import { LeafletEventHandlerFnMap } from "leaflet";
import * as React from "react";
import { useMapEvents } from "react-leaflet";

function MapEventsHandler({ events }: MapEventsHandlerProps) {
  useMapEvents(events);
  return null;
}

export interface MapEventsHandlerProps {
  events: LeafletEventHandlerFnMap;
}

export default MapEventsHandler;
