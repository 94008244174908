import * as React from "react";
import ScanStatus, { ScanStatusProps } from "./ScanStatus";
import { ScanStatusProvider } from "./ScansStatusContext";

function ScanStatusContainer({ header, color, children }: ScanStatusProps) {
  return (
    <ScanStatusProvider>
      <ScanStatus header={header} color={color}>
        {children}
      </ScanStatus>
    </ScanStatusProvider>
  );
}

export default ScanStatusContainer;
