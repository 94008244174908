import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import TableHeader from './TabelHeader';
import TableHeaderCrew from './TabelHeaderCrew';
import TableRow , { LocationAddress } from './TableRow';
import TableRowCrew , { CrewCondition } from './TableRowCrew';
import TableRowDraw , { DrawCondition, FooterDraw } from './TableRowDraw';
import SummeryPDF from './SummeryTable';
import TableHeaderDraw from './TabelHeaderDraw';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff'
  },
  section: {
    margin: 2,
    padding: 2,
    flexGrow: 1,
    border:2
  },
  sectionSummery: {
    margin: 2,
    padding: 2,
    flexGrow: 1,
    border:2,
    maxHeight: 56
  },
  section1: { color: 'blue', textAlign: 'left', margin: 2, border:2, padding: 2,
  flexGrow: 1, maxHeight: 20, fontWeight: 'bold' },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 2,
    border:2,
    margin: 2, padding: 2
},
  drawContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 2,
    border:2,
    margin: 2, padding: 2,
    height: 400
},
logo: {
    width: 74,
    height: 20,
    marginLeft: 'auto',
    marginRight: 'auto'
},
map: {
    width: 748,
    height: 330,
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: 'auto'
},
title: {
    fontStyle: 'bold',
    fontSize: 10,
    color: 'blue',
}
});


interface PropDocument {
    pdfImage: string;
    location_address: LocationAddress[];
    crew_condition: CrewCondition[];
    draw_condition: DrawCondition[];
}
const PdfGenerator = ({pdfImage, location_address, crew_condition, draw_condition}: PropDocument) => (
  <Document producer="Exodigo.Ai"
    title="PG&E Crossbore Inspection Program- Proximity Clearance Form"
    author='Exodigo'
    subject='Exofuser'
    >
    {location_address.map((item: LocationAddress, index: number) => (
        <Page key={index} size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section1}>
              <Text style={styles.title}>PG&E Crossbore Inspection Program- Proximity Clearance Form</Text>
          </View>
          <View style={styles.tableContainer}>
              {/* <Text style={styles.title}>LOCATION</Text> */}
              <TableHeader />
              <TableRow item={item} />
          </View>
          <View style={styles.tableContainer}>
              {/* <Text style={styles.title}>CREW & CONDITIONS</Text> */}
              <TableHeaderCrew />
              <TableRowCrew items={crew_condition} />
          </View>
          <View style={styles.drawContainer}>
              {/* <Text style={styles.title}>PROXIMITY DRAWING</Text> */}
              <TableHeaderDraw />
              <TableRowDraw items={draw_condition} />
              <FooterDraw />
              <View style={styles.map}>
                <Image style={{ width: 'auto', height: 'auto' }} src={{ uri: pdfImage, method: "GET", headers: { 'Access-Control-Allow-Origin': 'http://localhost:8080',
                  'Access-Control-Allow-Credentials': true,"Cache-Control": "no-cache" }, body: "" }} />
              </View>
          </View>
          <View style={styles.sectionSummery}>
              <SummeryPDF />
          </View>
        </Page>
    ))}

  </Document>
);

export default PdfGenerator;
