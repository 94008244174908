import * as React from "react";

function Modal({ children }: ModalProps) {
  return (
    <div className="modal">
      <div className="modalContent">{children}</div>
    </div>
  );
}

export interface ModalProps {
  children: React.ReactNode;
}

export default Modal;
