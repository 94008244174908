import React from 'react';
import 'leaflet-draw';
import { CircleMarker, Polyline, Tooltip, useMap } from 'react-leaflet';
import './MeasurementDrawLine.css';
import { UnitMeasuresProperties } from '../../../Utils/const';
import { getFormattedDistance } from './MeasurementUtil';

type MapUnitSelectorProps = {
  unitMeasures: UnitMeasuresProperties;
};

const newLineLength = 0.0002; // for zoom 20

const MeasurementDrawLine = ({ unitMeasures }: MapUnitSelectorProps) => {
  const [startPoint, setStartPoint] = React.useState<L.LatLngLiteral>();
  const [endPoint, setEndPoint] = React.useState<L.LatLngLiteral>();
  const [fixEndPoint, setFixEndPoint] = React.useState<L.LatLngTuple>();
  const [newLineEndPoint, setNewLineEndPoint] = React.useState<L.LatLngTuple>();
  const [formattedDistance, setFormattedDistance] = React.useState<string>();
  const map = useMap();

  const showFormattedDistance = () => {
    if (endPoint && startPoint && map) {
      setFormattedDistance(getFormattedDistance(startPoint, endPoint, map, unitMeasures));
    }
  };

  React.useEffect(() => {
    map?.once('click', handleStartDraw);
  }, []);

  React.useEffect(() => {
    if (endPoint && startPoint && map) {
      const angle = Math.atan2(endPoint.lat - startPoint.lat, endPoint.lng - startPoint.lng);
      const perpendicularAngle = angle + Math.PI / 2;
      const currentZoomLevel = map.getZoom();
      let adjustedNewLineLength = newLineLength;
      if (currentZoomLevel > 20) {
        adjustedNewLineLength /= Math.pow(2, currentZoomLevel - 20);
      } else if (currentZoomLevel < 20) {
        adjustedNewLineLength *= Math.pow(2, 20 - currentZoomLevel);
      }
      setNewLineEndPoint([
        midPoint[0] + Math.abs(adjustedNewLineLength * Math.sin(perpendicularAngle)),
        midPoint[1] + Math.abs(adjustedNewLineLength * Math.cos(perpendicularAngle))
      ]);

      showFormattedDistance();
    }
  }, [endPoint]);

  if (!map) {
    return null;
  }

  const handleStartDraw = (e: L.LeafletMouseEvent) => {
    if (e?.latlng) {
      setStartPoint(e.latlng);
      setEndPoint(e.latlng);
      map.once('click', handleEndDraw);
      map.on('mousemove', handleMouseMove);
    }
  };

  const handleEndDraw = (e: L.LeafletMouseEvent) => {
    setEndPoint(e.latlng);

    map.off('mousemove', handleMouseMove);
    map.off('click', handleEndDraw);

    if (newLineEndPoint) setFixEndPoint(newLineEndPoint);
  };

  const handleMouseMove = (e: L.LeafletMouseEvent) => {
    setEndPoint(e.latlng);
  };

  if (!startPoint || !endPoint) {
    return null;
  }
  const midPoint: L.LatLngTuple = [
    (startPoint.lat + endPoint.lat) / 2,
    (startPoint.lng + endPoint.lng) / 2
  ];
  if (!midPoint) {
    return null;
  }

  return (
    <div>
      {newLineEndPoint && <Polyline positions={[midPoint, newLineEndPoint]} color="red" />}
      {startPoint && (
        <CircleMarker
          center={startPoint}
          radius={3}
          pathOptions={{ color: 'red', fillColor: 'red', fillOpacity: 0.8 }}
        />
      )}
      {endPoint && (
        <CircleMarker
          center={endPoint}
          radius={3}
          pathOptions={{ color: 'red', fillColor: 'red', fillOpacity: 0.8 }}
        />
      )}

      <Polyline positions={[startPoint, endPoint]} color="red" dashArray="2 6">
        <>
          <Tooltip
            position={fixEndPoint || newLineEndPoint}
            className="custom-tooltip"
            opacity={1}
            permanent={true}
            interactive={true}
            sticky={false}>
            <div className="custom-tooltip">
              <div className="custom-tooltip-content">Length: {formattedDistance} </div>
            </div>
          </Tooltip>
        </>
      </Polyline>
    </div>
  );
};

export default MeasurementDrawLine;
