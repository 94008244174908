import * as React from "react";
import FileInput, { FileInputRef } from "../Inputs/FileInput";

function PolygonFormSection({ polyFileRef }: PolygonFormSectionProps) {
  return (
    <>
      <div className="formSection" key="polygonFileEntry">
        <FileInput ref={polyFileRef} multipleFiles={true} accept=".kmz, .kml" percentages={false} />
      </div>
    </>
  );
}

export interface PolygonFormSectionProps {
  polyFileRef: React.Ref<FileInputRef>;
}

export default PolygonFormSection;
