import { Autocomplete, Button, Divider, FormControlLabel, Stack, TextField } from '@mui/material';
import { Typography } from '../../../../../shared';
import { usePoiStore } from '../../store';
import { FilterCategories, Filters } from '../../types';
import { Slider, Checkbox } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

type DrawerContentProps = {
  onClose: () => void;
};

export const DrawerContent = ({ onClose }: DrawerContentProps) => {
  const { filterOptions, filterValues, applyFilters, resetFilters } = usePoiStore();
  const [state, setState] = useState(filterValues);

  const {
    depth: depthOptions,
    media: hasMediaOption,
    owner: ownersOptions,
    utilities: hasUtilitiesOption
  } = filterOptions;

  useEffect(() => {
    setState(filterValues);
  }, [filterValues]);

  const onChangeOwner = (owner: Filters[FilterCategories.owner]) => {
    setState((prevState) => ({
      ...prevState,
      owner
    }));
  };

  const onChangeMedia = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { id } = event.target;
    setState((prevState) => ({
      ...prevState,
      [id]: checked
    }));
  };

  const onChangeDepth = (depth: number | number[]) => {
    setState((prevState) => ({
      ...prevState,
      depth: depth as number[]
    }));
  };

  const renderCheckbox = () => {
    return (
      <Stack direction="row" spacing={2}>
        <FormControlLabel
          control={
            <Checkbox
              id={FilterCategories.media}
              onChange={onChangeMedia}
              checked={state[FilterCategories.media]}
              disabled={!hasMediaOption}
            />
          }
          label="Media"
          sx={{ margin: 0 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              id={FilterCategories.utilities}
              onChange={onChangeMedia}
              checked={state[FilterCategories.utilities]}
              disabled={!hasUtilitiesOption}
            />
          }
          label="Utilities"
          sx={{ margin: 0 }}
        />
      </Stack>
    );
  };

  const renderUtilitesCondition = () => {
    return (
      <Stack direction="row" spacing={2}>
        <FormControlLabel control={<Checkbox />} label="Utilities" />
      </Stack>
    );
  };

  const renderDepth = () => {
    return (
      <Stack>
        <Typography fontSize={14}>Depth Range</Typography>
        <Stack alignItems="center" justifyContent="center" direction="row" spacing={2}>
          <Typography fontWeight={400} fontSize={14}>
            {state[FilterCategories.depth]?.[0]}
          </Typography>
          <Slider
            maxWidth="100%"
            component={Stack}
            aria-labelledby="depth-range-slider"
            value={state[FilterCategories.depth]}
            onChange={(_, newVal) => onChangeDepth(newVal)}
            max={depthOptions?.[1]}
            min={depthOptions?.[0]}
          />
          <Typography fontSize={14}>{state[FilterCategories.depth]?.[1]}</Typography>
        </Stack>
      </Stack>
    );
  };

  const renderOwner = () => {
    if (!ownersOptions?.length) return;
    return (
      <Autocomplete
        id="owner"
        size="small"
        limitTags={2}
        renderInput={(params) => {
          return <TextField {...params} label="Owner" placeholder="Owner" />;
        }}
        onChange={(_, newVal) => onChangeOwner(newVal)}
        getOptionLabel={(opt) => opt.label}
        multiple
        options={ownersOptions}
        value={state[FilterCategories.owner]}
      />
    );
  };

  return (
    <Stack direction="column" spacing={2} height="100%">
      <Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Filter</Typography>
          <Typography
            onClick={() => {
              resetFilters();
            }}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline'
            }}>
            reset
          </Typography>
        </Stack>
        <Divider
          sx={{
            my: 1
          }}
        />
      </Stack>

      <Stack
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
        paddingY={2}
        flex={1}>
        <Stack direction="column" spacing={3}>
          {renderOwner()}
          {renderCheckbox()}
          {renderDepth()}
        </Stack>
      </Stack>

      <Stack>
        <Button
          variant="contained"
          onClick={() => {
            applyFilters(state);
            onClose();
          }}>
          Apply
        </Button>
      </Stack>
    </Stack>
  );
};
