class LocalStorageService {
  public static saveData(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public static loadData(key: string, def: unknown = null): any {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : def;
  }
}

export default LocalStorageService;
