import { Button } from "@mui/material";
import * as React from "react";
import { AnalysisProductsSite, Api } from "../../../Generated/ExoDBAPI";
import { getRequestParams } from "../../../Utils/azureAuth";
import { AnalysisProductsPipe } from "../DataTypes/MapEntities";
import UserContext from "../../Contexts/UserContext";

function DownloadPipeProtobuf({ selectedPipe, analysisSite }: DownloadPipeProtobufProps) {
  const { user } = React.useContext(UserContext);
  const [protobufDownloadUrl, setProtobufDownloadUrl] = React.useState<string>();
  const linkRef = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    async function fetchData() {
      const s3RefFull = selectedPipe?.header.s3Ref;
      if (user === undefined || s3RefFull === undefined || analysisSite?.s3Source === undefined) {
        return;
      }
      /*
      the format of s3RefFull is  {s3Source}/{s3Prefix}/{feature_id} so if we want to extract the feature id we need
      the sub string starting from analysisSite.s3Source.length + analysisSite.s3Prefix.length + 2 (+2 for the slashes) to the
      */
      if (!s3RefFull.startsWith(`${analysisSite.s3Source}/${analysisSite.s3Prefix}/`)) {
        throw new Error(
          `The s3 path ${s3RefFull} does not start with ${analysisSite.s3Source}/${analysisSite.s3Prefix}/ ({s3Source}/{s3Prefix}/))`
        );
      }
      const s3Ref = s3RefFull.slice(analysisSite.s3Source.length + analysisSite.s3Prefix.length + 2);
      setProtobufDownloadUrl(undefined);

      const api = new Api();
      const resp = await api.analysis.getEntityProtobufUrl(
        analysisSite.s3Prefix,
        encodeURIComponent(s3Ref),
        { source: analysisSite.s3Source },
        await getRequestParams()
      );
      setProtobufDownloadUrl(resp.data.url);
    }
    fetchData();
  }, [user, selectedPipe?.header.id]);
  return (
    <>
      <Button
        variant="outlined"
        value="DownloadEntity"
        className="selectButton"
        disabled={protobufDownloadUrl === undefined}
        onClick={() => {
          const downloadLink = linkRef.current;
          if (downloadLink !== null) {
            downloadLink.click();
          }
        }}
      >
        Download Protobuf
      </Button>
      <a href={protobufDownloadUrl} download style={{ display: "hidden" }} ref={linkRef} />
    </>
  );
}

export interface DownloadPipeProtobufProps {
  selectedPipe?: AnalysisProductsPipe;
  analysisSite?: AnalysisProductsSite;
}

export default DownloadPipeProtobuf;
