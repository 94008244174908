import { Card, CircularProgress, Stack } from '@mui/material';

export const MediaSpinner = () => {
  return (
    <Card
      component={Stack}
      elevation={3}
      sx={{
        width: 80,
        height: 80
      }}
      direction="row"
      justifyContent="center"
      alignItems="center">
      <CircularProgress size={16} color="primary" />
    </Card>
  );
};
