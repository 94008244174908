import { LatLng, LatLngExpression } from 'leaflet';
import { Dict } from '../BasicTypes';
import {
  AnalysisProductsPipe,
  AnalysisProductsPolygon,
  AnalysisProductsTravelCourse
} from '../Components/ExoFuser/DataTypes/MapEntities';
import { DislpayPipeModeValues } from '../Components/ExoFuser/MapControls/DisplayPipeMode/type';
import { getFeaturesGraphicConfig } from '../config/mapConfig';
import { Confidence, TransmitterMethod } from '../Generated/ExoDBAPI';
import transformTranslate from '@turf/transform-translate';
import { point } from '@turf/turf';
function getColorBySensorAndConfidence(
  sensor: string,
  confidence: Confidence | undefined,
  isSelected: boolean = false,
  displayMode?: DislpayPipeModeValues,
  transmitterMethod?: TransmitterMethod
): string {
  const config = getFeaturesGraphicConfig();

  if (isSelected) {
    return config.selectedEntityColor;
  }
  if (transmitterMethod && displayMode === DislpayPipeModeValues.Transmitter) {
    return getTransmittersMethodColor(transmitterMethod);
  }
  let confidenceColor: Dict<string> = config.defaultColor;
  if (config.sensorColorbyConfidenceMatrix[sensor] !== undefined) {
    confidenceColor = config.sensorColorbyConfidenceMatrix[sensor];
  }

  if (
    confidence !== undefined &&
    confidenceColor[confidence] !== undefined &&
    displayMode === DislpayPipeModeValues.SensorAndConfidence
  ) {
    return confidenceColor[confidence];
  }

  if (confidenceColor['default'] !== undefined) {
    return confidenceColor['default'];
  }

  if (confidence !== undefined && config.defaultColor[confidence] !== undefined) {
    return config.defaultColor[confidence];
  }

  return config.defaultColor['default'];
}

export function getAnalysisShapeColor(
  entity: AnalysisProductsPipe | AnalysisProductsPolygon,
  isSelected: boolean = false,
  displayPipesMode?: DislpayPipeModeValues,
  transmitterMethod?: TransmitterMethod
) {
  return getColorBySensorAndConfidence(
    entity.header.scan.sensor,
    entity.confidence,
    isSelected,
    displayPipesMode,
    transmitterMethod
  );
}

export function getTravelCourseColor(course: AnalysisProductsTravelCourse, colored?: boolean) {
  if (colored === undefined || !colored) {
    return getFeaturesGraphicConfig().travelCourseColor['default'];
  }
  return getColorBySensorAndConfidence(course.header.scan.sensor, undefined);
}

export function getTransmittersMethodColor(transmitterMethod: TransmitterMethod) {
  const config = getFeaturesGraphicConfig();
  return config.transmitterMethod[transmitterMethod.toLowerCase()]
    ? config.transmitterMethod[transmitterMethod.toLowerCase()]
    : config.transmitterMethod['unknown'];
}

export function getTravelCoursePathOptions(
  course: AnalysisProductsTravelCourse,
  colored?: boolean
) {
  if (colored === undefined || !colored) {
    return { color: getFeaturesGraphicConfig().travelCourseColor['default'] };
  }
  const options: TravelCoursePathOptions = {
    color: getColorBySensorAndConfidence(course.header.scan.sensor, undefined)
  };
  if (
    course.header.scan.sensor === 'FLC_370' ||
    course.header.scan.sensor === 'GRADIOMETRY_FLC_370'
  ) {
    options['weight'] = 7;
  }
  return options;
}
export function getNewCoordinatesByAngle(
  lat: number,
  lng: number,
  angle: number,
  distance: number = 1
): [number, number] {
  // Create a point from the original coordinates
  const from = point([lng, lat]);

  // Calculate the new point using transformTranslate
  const options = { units: 'meters' }; // You can change the units to 'miles', 'meters', etc.
  const newPoint = transformTranslate(from, distance, angle, options);

  // Extract the new coordinates
  const [newLng, newLat] = newPoint.geometry.coordinates;
  return [newLat, newLng];
}
export const calculateCentroid = (latlngs: LatLng[]): LatLngExpression => {
  let latSum = 0;
  let lngSum = 0;
  const len = latlngs.length;
  latlngs.forEach((latlng) => {
    latSum += latlng.lat;
    lngSum += latlng.lng;
  });
  return [latSum / len, lngSum / len];
};
interface TravelCoursePathOptions {
  color: string;
  weight?: number;
}
