import React from 'react';
import { RadioGroup, Radio, FormControl, FormControlLabel } from '@mui/material';

export enum ForceNoMissingPipes {
  IGNORE = 'ignore',
  ABORT = 'abort'
}

function AllowMissingPipesForm({ setForceNoMissingPipes }: AllowMissingPipesFormProps) {
  function onRadioButtonChange(value: ForceNoMissingPipes) {
    if (!Object.values(ForceNoMissingPipes).includes(value)) {
      window.alert(`Received illegal pipe check value: ${value}\nPlease inform Software team`);
      return;
    }

    setForceNoMissingPipes(value);
  }

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="force-no-missing-pipes"
        defaultValue={ForceNoMissingPipes.IGNORE}
        name="radio-buttons-group"
        onChange={(e, value) => onRadioButtonChange(value as ForceNoMissingPipes)}>
        <FormControlLabel value={ForceNoMissingPipes.IGNORE} control={<Radio />} label="Ignore" />
        <FormControlLabel
          value={ForceNoMissingPipes.ABORT}
          control={<Radio />}
          label="Abort site"
        />
      </RadioGroup>
    </FormControl>
  );
}

export interface AllowMissingPipesFormProps {
  setForceNoMissingPipes: (s: ForceNoMissingPipes) => void;
}

export default AllowMissingPipesForm;
