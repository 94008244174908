import React from "react";
import { Api } from "../../Generated/ExoDBAPI";
import { getRequestParamsOnlyHeaders } from "../../Utils/azureAuth";

function NewSharepointDateFolder({ siteId }: NewSharepointDateFolderProps) {
  const dateRef = React.useRef<HTMLInputElement>(null);
  const submitButtonRef = React.useRef<HTMLInputElement>(null);

  async function onSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    const submitButton = submitButtonRef.current;
    if (submitButton === null || dateRef.current === null) {
      console.log("Submit button ref or Date ref are null when submitting form");
      return;
    }
    submitButton.className = "buttonClicked";
    submitButton.disabled = true;

    const dateValue = dateRef.current.value;
    if (dateValue === "") {
      window.alert("Enter a date");
      submitButton.className = "formButton";
      submitButton.disabled = false;
    }
    const api = new Api({ baseUrl: process.env.REACT_APP_BACKEND_HOST });
    api.sites
      .addSharepointDateDir(
        siteId,
        {
          date: dateValue,
        },
        await getRequestParamsOnlyHeaders()
      )
      .then(() => {
        window.alert("Folder added");
      })
      .catch((resp) => {
        const errorMsg = resp.response.data;
        window.alert(errorMsg);
      })
      .finally(() => {
        submitButton.className = "formButton";
        submitButton.disabled = false;
      });
  }

  return (
    <>
      <h3>Create a new date folder</h3>
      <form onSubmit={onSubmit}>
        <span className="siteFormEntry">
          <label>Pick a date</label>
          <input type="date" name="folderDate" ref={dateRef} />
        </span>
        <span className="formSectionIsolated">
          <input type="submit" className="formButton" value="Create folder" ref={submitButtonRef} />
        </span>
      </form>
    </>
  );
}

export interface NewSharepointDateFolderProps {
  siteId: string;
}

export default NewSharepointDateFolder;
