import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
  BigInt: any;
  Double: any;
};

export type AlgoUtilityMetrics = {
  __typename?: 'AlgoUtilityMetrics';
  algoUtilityMetricsAnalyzed_entityId?: Maybe<Scalars['ID']>;
  analyzed_entity?: Maybe<AnalyzedEntity>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  metrics?: Maybe<Scalars['AWSJSON']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type AnalysisStep = {
  __typename?: 'AnalysisStep';
  access_time?: Maybe<Scalars['AWSTimestamp']>;
  access_time_iso?: Maybe<Scalars['AWSDateTime']>;
  analysisStepMonitor_stepsId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
  error_message?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_new_found_entity?: Maybe<Scalars['Boolean']>;
  is_scan_opened?: Maybe<Scalars['Boolean']>;
  monitor_steps?: Maybe<MonitorSteps>;
  status: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type AnalyzedEntity = {
  __typename?: 'AnalyzedEntity';
  algo_utility_metrics?: Maybe<AlgoUtilityMetrics>;
  analysis_confidence?: Maybe<Scalars['Float']>;
  analyzedEntityAlgo_utility_metricsId?: Maybe<Scalars['ID']>;
  analyzedEntityPipesqa_viewId?: Maybe<Scalars['ID']>;
  app_name?: Maybe<Scalars['String']>;
  app_version?: Maybe<Scalars['String']>;
  auto_confidence?: Maybe<Scalars['Float']>;
  classification: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  creation_source?: Maybe<Scalars['String']>;
  creation_time_iso?: Maybe<Scalars['AWSDateTime']>;
  creation_timestamp?: Maybe<Scalars['AWSTimestamp']>;
  data_source?: Maybe<Scalars['String']>;
  depth_points?: Maybe<ModelEntityDepthPointsConnection>;
  detailed_geo_data?: Maybe<Scalars['AWSJSON']>;
  frequency?: Maybe<Scalars['Float']>;
  geo_data: Scalars['AWSJSON'];
  id: Scalars['ID'];
  is_valid?: Maybe<Scalars['Boolean']>;
  magnetization?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  pipesqa_view?: Maybe<PipesqaPipe>;
  reanalysis_requests?: Maybe<ModelReanalysisRequestConnection>;
  s3_analysis_ref?: Maybe<Scalars['String']>;
  s3_epls_ref?: Maybe<Scalars['String']>;
  s3_ref?: Maybe<Scalars['String']>;
  scan?: Maybe<Scan>;
  scanAnalyzed_entitiesId?: Maybe<Scalars['ID']>;
  sensor_model?: Maybe<Scalars['String']>;
  site: Site;
  siteAnalyzed_entitiesId?: Maybe<Scalars['ID']>;
  snr?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['AWSDateTime'];
  user?: Maybe<Scalars['String']>;
};


export type AnalyzedEntityDepth_PointsArgs = {
  filter?: InputMaybe<ModelEntityDepthPointsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type AnalyzedEntityReanalysis_RequestsArgs = {
  filter?: InputMaybe<ModelReanalysisRequestFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type Comment = {
  __typename?: 'Comment';
  changed_by?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  pipesqaPipeCommentsId?: Maybe<Scalars['ID']>;
  pipesqa_pipe?: Maybe<PipesqaPipe>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['AWSDateTime']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type CreateAlgoUtilityMetricsInput = {
  algoUtilityMetricsAnalyzed_entityId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  metrics?: InputMaybe<Scalars['AWSJSON']>;
};

export type CreateAnalysisStepInput = {
  access_time?: InputMaybe<Scalars['AWSTimestamp']>;
  access_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  analysisStepMonitor_stepsId?: InputMaybe<Scalars['ID']>;
  error_message?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  is_new_found_entity?: InputMaybe<Scalars['Boolean']>;
  is_scan_opened?: InputMaybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type CreateAnalyzedEntityInput = {
  analysis_confidence?: InputMaybe<Scalars['Float']>;
  analyzedEntityAlgo_utility_metricsId?: InputMaybe<Scalars['ID']>;
  analyzedEntityPipesqa_viewId?: InputMaybe<Scalars['ID']>;
  app_name?: InputMaybe<Scalars['String']>;
  app_version?: InputMaybe<Scalars['String']>;
  auto_confidence?: InputMaybe<Scalars['Float']>;
  classification: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  creation_source?: InputMaybe<Scalars['String']>;
  creation_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  creation_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  data_source?: InputMaybe<Scalars['String']>;
  detailed_geo_data?: InputMaybe<Scalars['AWSJSON']>;
  frequency?: InputMaybe<Scalars['Float']>;
  geo_data: Scalars['AWSJSON'];
  id?: InputMaybe<Scalars['ID']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  magnetization?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  s3_analysis_ref?: InputMaybe<Scalars['String']>;
  s3_epls_ref?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanAnalyzed_entitiesId?: InputMaybe<Scalars['ID']>;
  sensor_model?: InputMaybe<Scalars['String']>;
  siteAnalyzed_entitiesId?: InputMaybe<Scalars['ID']>;
  snr?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<Scalars['String']>;
};

export type CreateCommentInput = {
  changed_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  pipesqaPipeCommentsId?: InputMaybe<Scalars['ID']>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateCustomerInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreateEntityDepthPointsInput = {
  algorithm?: InputMaybe<Scalars['String']>;
  analyzedEntityDepth_pointsId?: InputMaybe<Scalars['ID']>;
  depthsFromGround: Array<InputMaybe<Scalars['Float']>>;
  ellipsoidHeights?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  ellipsoidHeightsMask?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  hadError?: InputMaybe<Scalars['Boolean']>;
  hasEllipsoidHeight?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  lats: Array<InputMaybe<Scalars['Float']>>;
  lons: Array<InputMaybe<Scalars['Float']>>;
  mappingEntityDepth_pointsId?: InputMaybe<Scalars['ID']>;
  originalCrs: Scalars['String'];
  siteSite_pipe_depthsId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateGeoidValueInput = {
  id?: InputMaybe<Scalars['ID']>;
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
  siteGeoid_valuesId?: InputMaybe<Scalars['ID']>;
  value: Scalars['Float'];
};

export type CreateLocationInput = {
  country: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type CreateManholeItemInput = {
  depth?: InputMaybe<Scalars['Float']>;
  gpsHeight?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  manholeCoverDiameter?: InputMaybe<Scalars['Float']>;
  manholeCoverMaterial?: InputMaybe<Scalars['String']>;
  manholeCoverShape?: InputMaybe<Scalars['String']>;
  manholeItemPoiId: Scalars['ID'];
  manholeName?: InputMaybe<Scalars['String']>;
  otherOwnerName?: InputMaybe<Scalars['String']>;
  overallNumber?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['String']>;
  pipeDiscoveryStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  utilityTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreateMappingEntityInput = {
  app_name?: InputMaybe<Scalars['String']>;
  app_version?: InputMaybe<Scalars['String']>;
  classification: Scalars['String'];
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  creation_source?: InputMaybe<Scalars['String']>;
  creation_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  creation_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  data_source?: InputMaybe<Scalars['String']>;
  depth?: InputMaybe<Scalars['Float']>;
  geo_data: Scalars['AWSJSON'];
  id?: InputMaybe<Scalars['ID']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  object_type?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  siteMapping_entitiesId?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['String']>;
};

export type CreateMonitorStepsInput = {
  id?: InputMaybe<Scalars['ID']>;
  monitorStepsAnalysis_stepId: Scalars['ID'];
  monitorStepsPreloader_stepId: Scalars['ID'];
  monitorStepsScanId: Scalars['ID'];
  monitorStepsScan_stepId: Scalars['ID'];
  monitorStepsUpload_stepId: Scalars['ID'];
  monitorStepsUtility_finder_stepId: Scalars['ID'];
};

export type CreatePoiEntityInput = {
  comment?: InputMaybe<Scalars['String']>;
  geo_data: Scalars['AWSJSON'];
  id?: InputMaybe<Scalars['ID']>;
  pOIEntityDataId?: InputMaybe<Scalars['ID']>;
  poiType?: InputMaybe<Scalars['String']>;
  scanPoisId?: InputMaybe<Scalars['ID']>;
  sitePoisId?: InputMaybe<Scalars['ID']>;
};

export type CreatePipeItemInput = {
  azimuth?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  diameter?: InputMaybe<Scalars['Float']>;
  flowDirection?: InputMaybe<Scalars['String']>;
  hive?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  invertLevel?: InputMaybe<Scalars['Float']>;
  manholeItemPipesId: Scalars['ID'];
  media?: InputMaybe<Scalars['String']>;
  noCables?: InputMaybe<Scalars['String']>;
  secondaryOwner?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  thickness?: InputMaybe<Scalars['Float']>;
  topLevel?: InputMaybe<Scalars['Float']>;
};

export type CreatePipelineRunInfoInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  epls_s3_ref: Scalars['String'];
  error_message?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
  utilityFinderStepPipelinesId?: InputMaybe<Scalars['ID']>;
};

export type CreatePipesqaPipeInput = {
  crossings_statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  pipesqaPipeAnalyzed_entityId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  update_timestamp?: InputMaybe<Scalars['String']>;
};

export type CreatePlannedEntitiesInput = {
  id?: InputMaybe<Scalars['ID']>;
  plannedEntitiesSiteId: Scalars['ID'];
  s3_ref?: InputMaybe<Scalars['String']>;
};

export type CreatePlannedPolygonInput = {
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  geo_data: Scalars['AWSJSON'];
  id?: InputMaybe<Scalars['ID']>;
  is_rescan?: InputMaybe<Scalars['Boolean']>;
  mount_method?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  override_color?: InputMaybe<Scalars['Boolean']>;
  plannedEntitiesPlanned_polygonsId?: InputMaybe<Scalars['ID']>;
  sensor_models?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreatePlannedTransmitterInput = {
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  geo_data: Scalars['AWSJSON'];
  id?: InputMaybe<Scalars['ID']>;
  method?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  override_color?: InputMaybe<Scalars['Boolean']>;
  plannedPolygonPlanned_transmittersId?: InputMaybe<Scalars['ID']>;
};

export type CreatePreloadRunInfoInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  epls_s3_ref?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  preloaded_scan_s3_refs?: InputMaybe<Array<Scalars['String']>>;
  preloaderStepPreload_runsId?: InputMaybe<Scalars['ID']>;
  products?: InputMaybe<Array<Scalars['String']>>;
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
  target_name: Scalars['String'];
  target_type: Scalars['String'];
};

export type CreatePreloaderStepInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  error_message?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  preloaderStepMonitor_stepsId?: InputMaybe<Scalars['ID']>;
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
};

export type CreateReanalysisRequestCommentInput = {
  comment?: InputMaybe<Scalars['String']>;
  commenter?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['AWSTimestamp']>;
  date_iso?: InputMaybe<Scalars['AWSDateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  reanalysisRequestCommentsId?: InputMaybe<Scalars['ID']>;
};

export type CreateReanalysisRequestInput = {
  analyzedEntityReanalysis_requestsId?: InputMaybe<Scalars['ID']>;
  assignee?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['AWSTimestamp']>;
  due_date_iso?: InputMaybe<Scalars['AWSDateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  opened_by?: InputMaybe<Scalars['String']>;
  siteReanalysis_requestsId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  transmitterSetupReanalysis_requestsId?: InputMaybe<Scalars['ID']>;
  travelCourseReanalysis_requestsId?: InputMaybe<Scalars['ID']>;
};

export type CreateScanAccessInput = {
  end_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  frequency?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  scanScan_accessesId?: InputMaybe<Scalars['ID']>;
  start_timestamp: Scalars['AWSTimestamp'];
  user: Scalars['String'];
};

export type CreateScanByHashedParametersInput = {
  hash: Scalars['ID'];
  s3_ref: Scalars['String'];
  scan_id: Scalars['ID'];
};

export type CreateScanDepthPointsInput = {
  ellipsoidHeights: Array<InputMaybe<Scalars['Float']>>;
  headings?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  heightMode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lats: Array<InputMaybe<Scalars['Float']>>;
  lons: Array<InputMaybe<Scalars['Float']>>;
  scanScan_depthsId?: InputMaybe<Scalars['ID']>;
  siteSite_depthsId?: InputMaybe<Scalars['ID']>;
};

export type CreateScanInput = {
  bounding_polygon_s3_ref?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  downsampled_gps?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']>>>>>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  end_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  frequency?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  is_unified?: InputMaybe<Scalars['Boolean']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  machine_id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['AWSJSON']>;
  mount_method?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  original_sensor_types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  part_index?: InputMaybe<Scalars['Int']>;
  polygon_index?: InputMaybe<Scalars['Int']>;
  s3_preloaded_refs?: InputMaybe<Array<Scalars['String']>>;
  s3_raw_ref?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanMonitoring_stepsId?: InputMaybe<Scalars['ID']>;
  scanSource_scansId?: InputMaybe<Scalars['ID']>;
  scan_type: Scalars['String'];
  sensorScansId?: InputMaybe<Scalars['ID']>;
  sensor_type: Scalars['String'];
  siteScansId?: InputMaybe<Scalars['ID']>;
  snake_index?: InputMaybe<Scalars['Int']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  start_timestamp: Scalars['AWSTimestamp'];
  status: Scalars['String'];
  target_info?: InputMaybe<Scalars['AWSJSON']>;
};

export type CreateScanStepInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  error_message?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  scanStepMonitor_stepsId?: InputMaybe<Scalars['ID']>;
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
};

export type CreateSensorInput = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
  sensor_type: Scalars['String'];
  serial?: InputMaybe<Scalars['String']>;
};

export type CreateSiteInput = {
  customerSitesId?: InputMaybe<Scalars['ID']>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id?: InputMaybe<Scalars['ID']>;
  locationSitesId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  profiles_override?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  projected_crs?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  sitePlanned_entitiesId?: InputMaybe<Scalars['ID']>;
  site_type?: InputMaybe<Scalars['String']>;
};

export type CreateTargetEntityInput = {
  app_name?: InputMaybe<Scalars['String']>;
  app_version?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  creation_source?: InputMaybe<Scalars['String']>;
  creation_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  creation_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  data_source?: InputMaybe<Scalars['String']>;
  display_params?: InputMaybe<Scalars['AWSJSON']>;
  geo_data: Scalars['AWSJSON'];
  id?: InputMaybe<Scalars['ID']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanTarget_entitiesId?: InputMaybe<Scalars['ID']>;
  sensor_model?: InputMaybe<Scalars['String']>;
  siteTarget_entitiesId?: InputMaybe<Scalars['ID']>;
  target_type: Scalars['String'];
  user?: InputMaybe<Scalars['String']>;
};

export type CreateTasksScansConnectionInput = {
  id?: InputMaybe<Scalars['ID']>;
  scanId: Scalars['ID'];
  unifyTaskId: Scalars['ID'];
};

export type CreateTransmitterInput = {
  asset_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  serial: Scalars['String'];
};

export type CreateTransmitterSetupInput = {
  frequency: Scalars['Float'];
  geo_data: Scalars['AWSJSON'];
  id?: InputMaybe<Scalars['ID']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  method: Scalars['String'];
  s3_ref?: InputMaybe<Scalars['String']>;
  scanTransmitter_setupsId?: InputMaybe<Scalars['ID']>;
  siteTransmitter_setupsId?: InputMaybe<Scalars['ID']>;
  snr?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transmitterSetupTransmitterId: Scalars['ID'];
};

export type CreateTravelCourseInput = {
  geo_data: Scalars['AWSJSON'];
  gps_fixed_ratio?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanTravel_coursesId?: InputMaybe<Scalars['ID']>;
  siteTravel_coursesId?: InputMaybe<Scalars['ID']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type CreateUnifyTaskInput = {
  frequencies?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  geo_data: Scalars['AWSJSON'];
  id?: InputMaybe<Scalars['ID']>;
  siteUnify_tasksId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
};

export type CreateUploadStepInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  error_message?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
  uploadStepMonitor_stepsId?: InputMaybe<Scalars['ID']>;
  upload_speed?: InputMaybe<Scalars['Float']>;
  uploader?: InputMaybe<Scalars['String']>;
};

export type CreateUtilityFinderStepInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  error_message?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
  utilityFinderStepMonitor_stepsId?: InputMaybe<Scalars['ID']>;
};

export type CreateV2TargetEntityInput = {
  app_name?: InputMaybe<Scalars['String']>;
  app_version?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  creation_source?: InputMaybe<Scalars['String']>;
  creation_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  creation_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  data_source?: InputMaybe<Scalars['String']>;
  display_params?: InputMaybe<Scalars['AWSJSON']>;
  geo_data: Scalars['AWSJSON'];
  id?: InputMaybe<Scalars['ID']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanV2_target_entitiesId?: InputMaybe<Scalars['ID']>;
  sensor_model?: InputMaybe<Scalars['String']>;
  siteV2_target_entitiesId?: InputMaybe<Scalars['ID']>;
  target_type: Scalars['String'];
  user?: InputMaybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  sites?: Maybe<ModelSiteConnection>;
  updatedAt: Scalars['AWSDateTime'];
};


export type CustomerSitesArgs = {
  filter?: InputMaybe<ModelSiteFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type DeleteAlgoUtilityMetricsInput = {
  id: Scalars['ID'];
};

export type DeleteAnalysisStepInput = {
  id: Scalars['ID'];
};

export type DeleteAnalyzedEntityInput = {
  id: Scalars['ID'];
};

export type DeleteCommentInput = {
  id: Scalars['ID'];
};

export type DeleteCustomerInput = {
  id: Scalars['ID'];
};

export type DeleteEntityDepthPointsInput = {
  id: Scalars['ID'];
};

export type DeleteGeoidValueInput = {
  id: Scalars['ID'];
};

export type DeleteLocationInput = {
  id: Scalars['ID'];
};

export type DeleteManholeItemInput = {
  id: Scalars['ID'];
};

export type DeleteMappingEntityInput = {
  id: Scalars['ID'];
};

export type DeleteMonitorStepsInput = {
  id: Scalars['ID'];
};

export type DeletePoiEntityInput = {
  id: Scalars['ID'];
};

export type DeletePipeItemInput = {
  id: Scalars['ID'];
};

export type DeletePipelineRunInfoInput = {
  id: Scalars['ID'];
};

export type DeletePipesqaPipeInput = {
  id: Scalars['ID'];
};

export type DeletePlannedEntitiesInput = {
  id: Scalars['ID'];
};

export type DeletePlannedPolygonInput = {
  id: Scalars['ID'];
};

export type DeletePlannedTransmitterInput = {
  id: Scalars['ID'];
};

export type DeletePreloadRunInfoInput = {
  id: Scalars['ID'];
};

export type DeletePreloaderStepInput = {
  id: Scalars['ID'];
};

export type DeleteReanalysisRequestCommentInput = {
  id: Scalars['ID'];
};

export type DeleteReanalysisRequestInput = {
  id: Scalars['ID'];
};

export type DeleteScanAccessInput = {
  id: Scalars['ID'];
};

export type DeleteScanByHashedParametersInput = {
  hash: Scalars['ID'];
};

export type DeleteScanDepthPointsInput = {
  id: Scalars['ID'];
};

export type DeleteScanInput = {
  id: Scalars['ID'];
};

export type DeleteScanStepInput = {
  id: Scalars['ID'];
};

export type DeleteSensorInput = {
  id: Scalars['ID'];
};

export type DeleteSiteInput = {
  id: Scalars['ID'];
};

export type DeleteTargetEntityInput = {
  id: Scalars['ID'];
};

export type DeleteTasksScansConnectionInput = {
  id: Scalars['ID'];
};

export type DeleteTransmitterInput = {
  id: Scalars['ID'];
};

export type DeleteTransmitterSetupInput = {
  id: Scalars['ID'];
};

export type DeleteTravelCourseInput = {
  id: Scalars['ID'];
};

export type DeleteUnifyTaskInput = {
  id: Scalars['ID'];
};

export type DeleteUploadStepInput = {
  id: Scalars['ID'];
};

export type DeleteUtilityFinderStepInput = {
  id: Scalars['ID'];
};

export type DeleteV2TargetEntityInput = {
  id: Scalars['ID'];
};

export type EntityDepthPoints = {
  __typename?: 'EntityDepthPoints';
  algorithm?: Maybe<Scalars['String']>;
  analyzedEntityDepth_pointsId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
  depthsFromGround: Array<Maybe<Scalars['Float']>>;
  ellipsoidHeights?: Maybe<Array<Maybe<Scalars['Float']>>>;
  ellipsoidHeightsMask?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  entity?: Maybe<AnalyzedEntity>;
  hadError?: Maybe<Scalars['Boolean']>;
  hasEllipsoidHeight?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lats: Array<Maybe<Scalars['Float']>>;
  lons: Array<Maybe<Scalars['Float']>>;
  mappingEntityDepth_pointsId?: Maybe<Scalars['ID']>;
  mapping_entity?: Maybe<MappingEntity>;
  originalCrs: Scalars['String'];
  site?: Maybe<Site>;
  siteSite_pipe_depthsId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type GeoidValue = {
  __typename?: 'GeoidValue';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  site: Site;
  siteGeoid_valuesId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['AWSDateTime'];
  value: Scalars['Float'];
};

export type Location = {
  __typename?: 'Location';
  country: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  region?: Maybe<Scalars['String']>;
  sites?: Maybe<ModelSiteConnection>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
};


export type LocationSitesArgs = {
  filter?: InputMaybe<ModelSiteFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type ManholeItem = {
  __typename?: 'ManholeItem';
  createdAt: Scalars['AWSDateTime'];
  depth?: Maybe<Scalars['Float']>;
  gpsHeight?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  manholeCoverDiameter?: Maybe<Scalars['Float']>;
  manholeCoverMaterial?: Maybe<Scalars['String']>;
  manholeCoverShape?: Maybe<Scalars['String']>;
  manholeItemPoiId: Scalars['ID'];
  manholeName?: Maybe<Scalars['String']>;
  otherOwnerName?: Maybe<Scalars['String']>;
  overallNumber?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  pipeDiscoveryStatus?: Maybe<Scalars['String']>;
  pipes?: Maybe<ModelPipeItemConnection>;
  poi: PoiEntity;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
  utilityTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ManholeItemPipesArgs = {
  filter?: InputMaybe<ModelPipeItemFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type MappingEntity = {
  __typename?: 'MappingEntity';
  app_name?: Maybe<Scalars['String']>;
  app_version?: Maybe<Scalars['String']>;
  classification: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  creation_source?: Maybe<Scalars['String']>;
  creation_time_iso?: Maybe<Scalars['AWSDateTime']>;
  creation_timestamp?: Maybe<Scalars['AWSTimestamp']>;
  data_source?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Float']>;
  depth_points?: Maybe<ModelEntityDepthPointsConnection>;
  geo_data: Scalars['AWSJSON'];
  id: Scalars['ID'];
  is_valid?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  object_type?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['String']>;
  s3_ref?: Maybe<Scalars['String']>;
  site: Site;
  siteMapping_entitiesId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['AWSDateTime'];
  user?: Maybe<Scalars['String']>;
};


export type MappingEntityDepth_PointsArgs = {
  filter?: InputMaybe<ModelEntityDepthPointsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type ModelAlgoUtilityMetricsConditionInput = {
  algoUtilityMetricsAnalyzed_entityId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAlgoUtilityMetricsConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  metrics?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelAlgoUtilityMetricsConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAlgoUtilityMetricsConditionInput>>>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelAlgoUtilityMetricsConnection = {
  __typename?: 'ModelAlgoUtilityMetricsConnection';
  items: Array<Maybe<AlgoUtilityMetrics>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelAlgoUtilityMetricsFilterInput = {
  algoUtilityMetricsAnalyzed_entityId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAlgoUtilityMetricsFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  metrics?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelAlgoUtilityMetricsFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAlgoUtilityMetricsFilterInput>>>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelAnalysisStepConditionInput = {
  access_time?: InputMaybe<ModelIntInput>;
  access_time_iso?: InputMaybe<ModelStringInput>;
  analysisStepMonitor_stepsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAnalysisStepConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  is_new_found_entity?: InputMaybe<ModelBooleanInput>;
  is_scan_opened?: InputMaybe<ModelBooleanInput>;
  not?: InputMaybe<ModelAnalysisStepConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAnalysisStepConditionInput>>>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelAnalysisStepConnection = {
  __typename?: 'ModelAnalysisStepConnection';
  items: Array<Maybe<AnalysisStep>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelAnalysisStepFilterInput = {
  access_time?: InputMaybe<ModelIntInput>;
  access_time_iso?: InputMaybe<ModelStringInput>;
  analysisStepMonitor_stepsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAnalysisStepFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  is_new_found_entity?: InputMaybe<ModelBooleanInput>;
  is_scan_opened?: InputMaybe<ModelBooleanInput>;
  not?: InputMaybe<ModelAnalysisStepFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAnalysisStepFilterInput>>>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelAnalyzedEntityConditionInput = {
  analysis_confidence?: InputMaybe<ModelFloatInput>;
  analyzedEntityAlgo_utility_metricsId?: InputMaybe<ModelIdInput>;
  analyzedEntityPipesqa_viewId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityConditionInput>>>;
  app_name?: InputMaybe<ModelStringInput>;
  app_version?: InputMaybe<ModelStringInput>;
  auto_confidence?: InputMaybe<ModelFloatInput>;
  classification?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  creation_source?: InputMaybe<ModelStringInput>;
  creation_time_iso?: InputMaybe<ModelStringInput>;
  creation_timestamp?: InputMaybe<ModelIntInput>;
  data_source?: InputMaybe<ModelStringInput>;
  detailed_geo_data?: InputMaybe<ModelStringInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  magnetization?: InputMaybe<ModelFloatInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityConditionInput>>>;
  s3_analysis_ref?: InputMaybe<ModelStringInput>;
  s3_epls_ref?: InputMaybe<ModelStringInput>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  sensor_model?: InputMaybe<ModelStringInput>;
  siteAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  snr?: InputMaybe<ModelFloatInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelAnalyzedEntityConnection = {
  __typename?: 'ModelAnalyzedEntityConnection';
  items: Array<Maybe<AnalyzedEntity>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelAnalyzedEntityFilterInput = {
  analysis_confidence?: InputMaybe<ModelFloatInput>;
  analyzedEntityAlgo_utility_metricsId?: InputMaybe<ModelIdInput>;
  analyzedEntityPipesqa_viewId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityFilterInput>>>;
  app_name?: InputMaybe<ModelStringInput>;
  app_version?: InputMaybe<ModelStringInput>;
  auto_confidence?: InputMaybe<ModelFloatInput>;
  classification?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  creation_source?: InputMaybe<ModelStringInput>;
  creation_time_iso?: InputMaybe<ModelStringInput>;
  creation_timestamp?: InputMaybe<ModelIntInput>;
  data_source?: InputMaybe<ModelStringInput>;
  detailed_geo_data?: InputMaybe<ModelStringInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  magnetization?: InputMaybe<ModelFloatInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityFilterInput>>>;
  s3_analysis_ref?: InputMaybe<ModelStringInput>;
  s3_epls_ref?: InputMaybe<ModelStringInput>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  sensor_model?: InputMaybe<ModelStringInput>;
  siteAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  snr?: InputMaybe<ModelFloatInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export enum ModelAttributeTypes {
  Null = '_null',
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet'
}

export type ModelBooleanInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type ModelCommentConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelCommentConditionInput>>>;
  changed_by?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelCommentConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCommentConditionInput>>>;
  pipesqaPipeCommentsId?: InputMaybe<ModelIdInput>;
  text?: InputMaybe<ModelStringInput>;
  timestamp?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelCommentConnection = {
  __typename?: 'ModelCommentConnection';
  items: Array<Maybe<Comment>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCommentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelCommentFilterInput>>>;
  changed_by?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelCommentFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCommentFilterInput>>>;
  pipesqaPipeCommentsId?: InputMaybe<ModelIdInput>;
  text?: InputMaybe<ModelStringInput>;
  timestamp?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelCustomerConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelCustomerConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelCustomerConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCustomerConditionInput>>>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelCustomerConnection = {
  __typename?: 'ModelCustomerConnection';
  items: Array<Maybe<Customer>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCustomerFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelCustomerFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelCustomerFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCustomerFilterInput>>>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelEntityDepthPointsConditionInput = {
  algorithm?: InputMaybe<ModelStringInput>;
  analyzedEntityDepth_pointsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelEntityDepthPointsConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  depthsFromGround?: InputMaybe<ModelFloatInput>;
  ellipsoidHeights?: InputMaybe<ModelFloatInput>;
  ellipsoidHeightsMask?: InputMaybe<ModelBooleanInput>;
  hadError?: InputMaybe<ModelBooleanInput>;
  hasEllipsoidHeight?: InputMaybe<ModelBooleanInput>;
  lats?: InputMaybe<ModelFloatInput>;
  lons?: InputMaybe<ModelFloatInput>;
  mappingEntityDepth_pointsId?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelEntityDepthPointsConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelEntityDepthPointsConditionInput>>>;
  originalCrs?: InputMaybe<ModelStringInput>;
  siteSite_pipe_depthsId?: InputMaybe<ModelIdInput>;
  type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelEntityDepthPointsConnection = {
  __typename?: 'ModelEntityDepthPointsConnection';
  items: Array<Maybe<EntityDepthPoints>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelEntityDepthPointsFilterInput = {
  algorithm?: InputMaybe<ModelStringInput>;
  analyzedEntityDepth_pointsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelEntityDepthPointsFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  depthsFromGround?: InputMaybe<ModelFloatInput>;
  ellipsoidHeights?: InputMaybe<ModelFloatInput>;
  ellipsoidHeightsMask?: InputMaybe<ModelBooleanInput>;
  hadError?: InputMaybe<ModelBooleanInput>;
  hasEllipsoidHeight?: InputMaybe<ModelBooleanInput>;
  id?: InputMaybe<ModelIdInput>;
  lats?: InputMaybe<ModelFloatInput>;
  lons?: InputMaybe<ModelFloatInput>;
  mappingEntityDepth_pointsId?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelEntityDepthPointsFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelEntityDepthPointsFilterInput>>>;
  originalCrs?: InputMaybe<ModelStringInput>;
  siteSite_pipe_depthsId?: InputMaybe<ModelIdInput>;
  type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelFloatInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq?: InputMaybe<Scalars['Float']>;
  ge?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  le?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
};

export type ModelGeoidValueConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelGeoidValueConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  lat?: InputMaybe<ModelFloatInput>;
  lon?: InputMaybe<ModelFloatInput>;
  not?: InputMaybe<ModelGeoidValueConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelGeoidValueConditionInput>>>;
  siteGeoid_valuesId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  value?: InputMaybe<ModelFloatInput>;
};

export type ModelGeoidValueConnection = {
  __typename?: 'ModelGeoidValueConnection';
  items: Array<Maybe<GeoidValue>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelGeoidValueFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelGeoidValueFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  lat?: InputMaybe<ModelFloatInput>;
  lon?: InputMaybe<ModelFloatInput>;
  not?: InputMaybe<ModelGeoidValueFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelGeoidValueFilterInput>>>;
  siteGeoid_valuesId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  value?: InputMaybe<ModelFloatInput>;
};

export type ModelIdInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelIntInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export type ModelLocationConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelLocationConditionInput>>>;
  country?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelLocationConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelLocationConditionInput>>>;
  region?: InputMaybe<ModelStringInput>;
  state?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelLocationConnection = {
  __typename?: 'ModelLocationConnection';
  items: Array<Maybe<Location>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelLocationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelLocationFilterInput>>>;
  country?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelLocationFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelLocationFilterInput>>>;
  region?: InputMaybe<ModelStringInput>;
  state?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelManholeItemConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelManholeItemConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  depth?: InputMaybe<ModelFloatInput>;
  gpsHeight?: InputMaybe<ModelFloatInput>;
  manholeCoverDiameter?: InputMaybe<ModelFloatInput>;
  manholeCoverMaterial?: InputMaybe<ModelStringInput>;
  manholeCoverShape?: InputMaybe<ModelStringInput>;
  manholeItemPoiId?: InputMaybe<ModelIdInput>;
  manholeName?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelManholeItemConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelManholeItemConditionInput>>>;
  otherOwnerName?: InputMaybe<ModelStringInput>;
  overallNumber?: InputMaybe<ModelIntInput>;
  owner?: InputMaybe<ModelStringInput>;
  pipeDiscoveryStatus?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  utilityTypes?: InputMaybe<ModelStringInput>;
};

export type ModelManholeItemConnection = {
  __typename?: 'ModelManholeItemConnection';
  items: Array<Maybe<ManholeItem>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelManholeItemFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelManholeItemFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  depth?: InputMaybe<ModelFloatInput>;
  gpsHeight?: InputMaybe<ModelFloatInput>;
  id?: InputMaybe<ModelIdInput>;
  manholeCoverDiameter?: InputMaybe<ModelFloatInput>;
  manholeCoverMaterial?: InputMaybe<ModelStringInput>;
  manholeCoverShape?: InputMaybe<ModelStringInput>;
  manholeItemPoiId?: InputMaybe<ModelIdInput>;
  manholeName?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelManholeItemFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelManholeItemFilterInput>>>;
  otherOwnerName?: InputMaybe<ModelStringInput>;
  overallNumber?: InputMaybe<ModelIntInput>;
  owner?: InputMaybe<ModelStringInput>;
  pipeDiscoveryStatus?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  utilityTypes?: InputMaybe<ModelStringInput>;
};

export type ModelMappingEntityConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelMappingEntityConditionInput>>>;
  app_name?: InputMaybe<ModelStringInput>;
  app_version?: InputMaybe<ModelStringInput>;
  classification?: InputMaybe<ModelStringInput>;
  color?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  creation_source?: InputMaybe<ModelStringInput>;
  creation_time_iso?: InputMaybe<ModelStringInput>;
  creation_timestamp?: InputMaybe<ModelIntInput>;
  data_source?: InputMaybe<ModelStringInput>;
  depth?: InputMaybe<ModelFloatInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelMappingEntityConditionInput>;
  object_type?: InputMaybe<ModelStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelMappingEntityConditionInput>>>;
  properties?: InputMaybe<ModelStringInput>;
  s3_ref?: InputMaybe<ModelStringInput>;
  siteMapping_entitiesId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelMappingEntityConnection = {
  __typename?: 'ModelMappingEntityConnection';
  items: Array<Maybe<MappingEntity>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelMappingEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelMappingEntityFilterInput>>>;
  app_name?: InputMaybe<ModelStringInput>;
  app_version?: InputMaybe<ModelStringInput>;
  classification?: InputMaybe<ModelStringInput>;
  color?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  creation_source?: InputMaybe<ModelStringInput>;
  creation_time_iso?: InputMaybe<ModelStringInput>;
  creation_timestamp?: InputMaybe<ModelIntInput>;
  data_source?: InputMaybe<ModelStringInput>;
  depth?: InputMaybe<ModelFloatInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelMappingEntityFilterInput>;
  object_type?: InputMaybe<ModelStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelMappingEntityFilterInput>>>;
  properties?: InputMaybe<ModelStringInput>;
  s3_ref?: InputMaybe<ModelStringInput>;
  siteMapping_entitiesId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelMonitorStepsConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelMonitorStepsConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  monitorStepsAnalysis_stepId?: InputMaybe<ModelIdInput>;
  monitorStepsPreloader_stepId?: InputMaybe<ModelIdInput>;
  monitorStepsScanId?: InputMaybe<ModelIdInput>;
  monitorStepsScan_stepId?: InputMaybe<ModelIdInput>;
  monitorStepsUpload_stepId?: InputMaybe<ModelIdInput>;
  monitorStepsUtility_finder_stepId?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelMonitorStepsConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelMonitorStepsConditionInput>>>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelMonitorStepsConnection = {
  __typename?: 'ModelMonitorStepsConnection';
  items: Array<Maybe<MonitorSteps>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelMonitorStepsFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelMonitorStepsFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  monitorStepsAnalysis_stepId?: InputMaybe<ModelIdInput>;
  monitorStepsPreloader_stepId?: InputMaybe<ModelIdInput>;
  monitorStepsScanId?: InputMaybe<ModelIdInput>;
  monitorStepsScan_stepId?: InputMaybe<ModelIdInput>;
  monitorStepsUpload_stepId?: InputMaybe<ModelIdInput>;
  monitorStepsUtility_finder_stepId?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelMonitorStepsFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelMonitorStepsFilterInput>>>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPoiEntityConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPoiEntityConditionInput>>>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPoiEntityConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPoiEntityConditionInput>>>;
  pOIEntityDataId?: InputMaybe<ModelIdInput>;
  poiType?: InputMaybe<ModelStringInput>;
  scanPoisId?: InputMaybe<ModelIdInput>;
  sitePoisId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPoiEntityConnection = {
  __typename?: 'ModelPOIEntityConnection';
  items: Array<Maybe<PoiEntity>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPoiEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPoiEntityFilterInput>>>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelPoiEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPoiEntityFilterInput>>>;
  pOIEntityDataId?: InputMaybe<ModelIdInput>;
  poiType?: InputMaybe<ModelStringInput>;
  scanPoisId?: InputMaybe<ModelIdInput>;
  sitePoisId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPipeItemConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPipeItemConditionInput>>>;
  azimuth?: InputMaybe<ModelFloatInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  description?: InputMaybe<ModelStringInput>;
  diameter?: InputMaybe<ModelFloatInput>;
  flowDirection?: InputMaybe<ModelStringInput>;
  hive?: InputMaybe<ModelStringInput>;
  invertLevel?: InputMaybe<ModelFloatInput>;
  manholeItemPipesId?: InputMaybe<ModelIdInput>;
  media?: InputMaybe<ModelStringInput>;
  noCables?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPipeItemConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPipeItemConditionInput>>>;
  secondaryOwner?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  thickness?: InputMaybe<ModelFloatInput>;
  topLevel?: InputMaybe<ModelFloatInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPipeItemConnection = {
  __typename?: 'ModelPipeItemConnection';
  items: Array<Maybe<PipeItem>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPipeItemFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPipeItemFilterInput>>>;
  azimuth?: InputMaybe<ModelFloatInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  description?: InputMaybe<ModelStringInput>;
  diameter?: InputMaybe<ModelFloatInput>;
  flowDirection?: InputMaybe<ModelStringInput>;
  hive?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  invertLevel?: InputMaybe<ModelFloatInput>;
  manholeItemPipesId?: InputMaybe<ModelIdInput>;
  media?: InputMaybe<ModelStringInput>;
  noCables?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPipeItemFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPipeItemFilterInput>>>;
  secondaryOwner?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  thickness?: InputMaybe<ModelFloatInput>;
  topLevel?: InputMaybe<ModelFloatInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPipelineRunInfoConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPipelineRunInfoConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  epls_s3_ref?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPipelineRunInfoConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPipelineRunInfoConditionInput>>>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  utilityFinderStepPipelinesId?: InputMaybe<ModelIdInput>;
};

export type ModelPipelineRunInfoConnection = {
  __typename?: 'ModelPipelineRunInfoConnection';
  items: Array<Maybe<PipelineRunInfo>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPipelineRunInfoFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPipelineRunInfoFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  epls_s3_ref?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPipelineRunInfoFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPipelineRunInfoFilterInput>>>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  utilityFinderStepPipelinesId?: InputMaybe<ModelIdInput>;
};

export type ModelPipesqaPipeConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPipesqaPipeConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  crossings_statuses?: InputMaybe<ModelStringInput>;
  flagged?: InputMaybe<ModelBooleanInput>;
  not?: InputMaybe<ModelPipesqaPipeConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPipesqaPipeConditionInput>>>;
  pipesqaPipeAnalyzed_entityId?: InputMaybe<ModelIdInput>;
  status?: InputMaybe<ModelStringInput>;
  update_timestamp?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPipesqaPipeConnection = {
  __typename?: 'ModelPipesqaPipeConnection';
  items: Array<Maybe<PipesqaPipe>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPipesqaPipeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPipesqaPipeFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  crossings_statuses?: InputMaybe<ModelStringInput>;
  flagged?: InputMaybe<ModelBooleanInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelPipesqaPipeFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPipesqaPipeFilterInput>>>;
  pipesqaPipeAnalyzed_entityId?: InputMaybe<ModelIdInput>;
  status?: InputMaybe<ModelStringInput>;
  update_timestamp?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPlannedEntitiesConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPlannedEntitiesConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPlannedEntitiesConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPlannedEntitiesConditionInput>>>;
  plannedEntitiesSiteId?: InputMaybe<ModelIdInput>;
  s3_ref?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPlannedEntitiesConnection = {
  __typename?: 'ModelPlannedEntitiesConnection';
  items: Array<Maybe<PlannedEntities>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPlannedEntitiesFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPlannedEntitiesFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelPlannedEntitiesFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPlannedEntitiesFilterInput>>>;
  plannedEntitiesSiteId?: InputMaybe<ModelIdInput>;
  s3_ref?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPlannedPolygonConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPlannedPolygonConditionInput>>>;
  color?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  is_rescan?: InputMaybe<ModelBooleanInput>;
  mount_method?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPlannedPolygonConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPlannedPolygonConditionInput>>>;
  override_color?: InputMaybe<ModelBooleanInput>;
  plannedEntitiesPlanned_polygonsId?: InputMaybe<ModelIdInput>;
  sensor_models?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPlannedPolygonConnection = {
  __typename?: 'ModelPlannedPolygonConnection';
  items: Array<Maybe<PlannedPolygon>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPlannedPolygonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPlannedPolygonFilterInput>>>;
  color?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  is_rescan?: InputMaybe<ModelBooleanInput>;
  mount_method?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPlannedPolygonFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPlannedPolygonFilterInput>>>;
  override_color?: InputMaybe<ModelBooleanInput>;
  plannedEntitiesPlanned_polygonsId?: InputMaybe<ModelIdInput>;
  sensor_models?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPlannedTransmitterConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPlannedTransmitterConditionInput>>>;
  color?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  method?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPlannedTransmitterConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPlannedTransmitterConditionInput>>>;
  override_color?: InputMaybe<ModelBooleanInput>;
  plannedPolygonPlanned_transmittersId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPlannedTransmitterConnection = {
  __typename?: 'ModelPlannedTransmitterConnection';
  items: Array<Maybe<PlannedTransmitter>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPlannedTransmitterFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPlannedTransmitterFilterInput>>>;
  color?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  method?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPlannedTransmitterFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPlannedTransmitterFilterInput>>>;
  override_color?: InputMaybe<ModelBooleanInput>;
  plannedPolygonPlanned_transmittersId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPreloadRunInfoConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPreloadRunInfoConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  epls_s3_ref?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPreloadRunInfoConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPreloadRunInfoConditionInput>>>;
  preloaded_scan_s3_refs?: InputMaybe<ModelStringInput>;
  preloaderStepPreload_runsId?: InputMaybe<ModelIdInput>;
  products?: InputMaybe<ModelStringInput>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  target_name?: InputMaybe<ModelStringInput>;
  target_type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPreloadRunInfoConnection = {
  __typename?: 'ModelPreloadRunInfoConnection';
  items: Array<Maybe<PreloadRunInfo>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPreloadRunInfoFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPreloadRunInfoFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  epls_s3_ref?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelPreloadRunInfoFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPreloadRunInfoFilterInput>>>;
  preloaded_scan_s3_refs?: InputMaybe<ModelStringInput>;
  preloaderStepPreload_runsId?: InputMaybe<ModelIdInput>;
  products?: InputMaybe<ModelStringInput>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  target_name?: InputMaybe<ModelStringInput>;
  target_type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPreloaderStepConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPreloaderStepConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelPreloaderStepConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPreloaderStepConditionInput>>>;
  preloaderStepMonitor_stepsId?: InputMaybe<ModelIdInput>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelPreloaderStepConnection = {
  __typename?: 'ModelPreloaderStepConnection';
  items: Array<Maybe<PreloaderStep>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPreloaderStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPreloaderStepFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelPreloaderStepFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPreloaderStepFilterInput>>>;
  preloaderStepMonitor_stepsId?: InputMaybe<ModelIdInput>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelReanalysisRequestCommentConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelReanalysisRequestCommentConditionInput>>>;
  comment?: InputMaybe<ModelStringInput>;
  commenter?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  date?: InputMaybe<ModelIntInput>;
  date_iso?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelReanalysisRequestCommentConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelReanalysisRequestCommentConditionInput>>>;
  reanalysisRequestCommentsId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelReanalysisRequestCommentConnection = {
  __typename?: 'ModelReanalysisRequestCommentConnection';
  items: Array<Maybe<ReanalysisRequestComment>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelReanalysisRequestCommentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelReanalysisRequestCommentFilterInput>>>;
  comment?: InputMaybe<ModelStringInput>;
  commenter?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  date?: InputMaybe<ModelIntInput>;
  date_iso?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelReanalysisRequestCommentFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelReanalysisRequestCommentFilterInput>>>;
  reanalysisRequestCommentsId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelReanalysisRequestConditionInput = {
  analyzedEntityReanalysis_requestsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelReanalysisRequestConditionInput>>>;
  assignee?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  due_date?: InputMaybe<ModelIntInput>;
  due_date_iso?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelReanalysisRequestConditionInput>;
  opened_by?: InputMaybe<ModelStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelReanalysisRequestConditionInput>>>;
  siteReanalysis_requestsId?: InputMaybe<ModelIdInput>;
  status?: InputMaybe<ModelStringInput>;
  transmitterSetupReanalysis_requestsId?: InputMaybe<ModelIdInput>;
  travelCourseReanalysis_requestsId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelReanalysisRequestConnection = {
  __typename?: 'ModelReanalysisRequestConnection';
  items: Array<Maybe<ReanalysisRequest>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelReanalysisRequestFilterInput = {
  analyzedEntityReanalysis_requestsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelReanalysisRequestFilterInput>>>;
  assignee?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  due_date?: InputMaybe<ModelIntInput>;
  due_date_iso?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelReanalysisRequestFilterInput>;
  opened_by?: InputMaybe<ModelStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelReanalysisRequestFilterInput>>>;
  siteReanalysis_requestsId?: InputMaybe<ModelIdInput>;
  status?: InputMaybe<ModelStringInput>;
  transmitterSetupReanalysis_requestsId?: InputMaybe<ModelIdInput>;
  travelCourseReanalysis_requestsId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanAccessConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanAccessConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_timestamp?: InputMaybe<ModelIntInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  not?: InputMaybe<ModelScanAccessConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanAccessConditionInput>>>;
  scanScan_accessesId?: InputMaybe<ModelIdInput>;
  start_timestamp?: InputMaybe<ModelIntInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelScanAccessConnection = {
  __typename?: 'ModelScanAccessConnection';
  items: Array<Maybe<ScanAccess>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelScanAccessFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanAccessFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_timestamp?: InputMaybe<ModelIntInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelScanAccessFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanAccessFilterInput>>>;
  scanScan_accessesId?: InputMaybe<ModelIdInput>;
  start_timestamp?: InputMaybe<ModelIntInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelScanByHashedParametersConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanByHashedParametersConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelScanByHashedParametersConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanByHashedParametersConditionInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scan_id?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanByHashedParametersConnection = {
  __typename?: 'ModelScanByHashedParametersConnection';
  items: Array<Maybe<ScanByHashedParameters>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelScanByHashedParametersFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanByHashedParametersFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  hash?: InputMaybe<ModelIdInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelScanByHashedParametersFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanByHashedParametersFilterInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scan_id?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanConditionInput>>>;
  bounding_polygon_s3_ref?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  downsampled_gps?: InputMaybe<ModelFloatInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  end_timestamp?: InputMaybe<ModelIntInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  is_unified?: InputMaybe<ModelBooleanInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  machine_id?: InputMaybe<ModelStringInput>;
  metadata?: InputMaybe<ModelStringInput>;
  mount_method?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelScanConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanConditionInput>>>;
  original_sensor_types?: InputMaybe<ModelStringInput>;
  part_index?: InputMaybe<ModelIntInput>;
  polygon_index?: InputMaybe<ModelIntInput>;
  s3_preloaded_refs?: InputMaybe<ModelStringInput>;
  s3_raw_ref?: InputMaybe<ModelStringInput>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanMonitoring_stepsId?: InputMaybe<ModelIdInput>;
  scanSource_scansId?: InputMaybe<ModelIdInput>;
  scan_type?: InputMaybe<ModelStringInput>;
  sensorScansId?: InputMaybe<ModelIdInput>;
  sensor_type?: InputMaybe<ModelStringInput>;
  siteScansId?: InputMaybe<ModelIdInput>;
  snake_index?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  start_timestamp?: InputMaybe<ModelIntInput>;
  status?: InputMaybe<ModelStringInput>;
  target_info?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanConnection = {
  __typename?: 'ModelScanConnection';
  items: Array<Maybe<Scan>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelScanDepthPointsConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanDepthPointsConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  ellipsoidHeights?: InputMaybe<ModelFloatInput>;
  headings?: InputMaybe<ModelFloatInput>;
  heightMode?: InputMaybe<ModelStringInput>;
  lats?: InputMaybe<ModelFloatInput>;
  lons?: InputMaybe<ModelFloatInput>;
  not?: InputMaybe<ModelScanDepthPointsConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanDepthPointsConditionInput>>>;
  scanScan_depthsId?: InputMaybe<ModelIdInput>;
  siteSite_depthsId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanDepthPointsConnection = {
  __typename?: 'ModelScanDepthPointsConnection';
  items: Array<Maybe<ScanDepthPoints>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelScanDepthPointsFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanDepthPointsFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  ellipsoidHeights?: InputMaybe<ModelFloatInput>;
  headings?: InputMaybe<ModelFloatInput>;
  heightMode?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  lats?: InputMaybe<ModelFloatInput>;
  lons?: InputMaybe<ModelFloatInput>;
  not?: InputMaybe<ModelScanDepthPointsFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanDepthPointsFilterInput>>>;
  scanScan_depthsId?: InputMaybe<ModelIdInput>;
  siteSite_depthsId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanFilterInput>>>;
  bounding_polygon_s3_ref?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  downsampled_gps?: InputMaybe<ModelFloatInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  end_timestamp?: InputMaybe<ModelIntInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  id?: InputMaybe<ModelIdInput>;
  is_unified?: InputMaybe<ModelBooleanInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  machine_id?: InputMaybe<ModelStringInput>;
  metadata?: InputMaybe<ModelStringInput>;
  mount_method?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelScanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanFilterInput>>>;
  original_sensor_types?: InputMaybe<ModelStringInput>;
  part_index?: InputMaybe<ModelIntInput>;
  polygon_index?: InputMaybe<ModelIntInput>;
  s3_preloaded_refs?: InputMaybe<ModelStringInput>;
  s3_raw_ref?: InputMaybe<ModelStringInput>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanMonitoring_stepsId?: InputMaybe<ModelIdInput>;
  scanSource_scansId?: InputMaybe<ModelIdInput>;
  scan_type?: InputMaybe<ModelStringInput>;
  sensorScansId?: InputMaybe<ModelIdInput>;
  sensor_type?: InputMaybe<ModelStringInput>;
  siteScansId?: InputMaybe<ModelIdInput>;
  snake_index?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  start_timestamp?: InputMaybe<ModelIntInput>;
  status?: InputMaybe<ModelStringInput>;
  target_info?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanStepConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanStepConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelScanStepConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanStepConditionInput>>>;
  scanStepMonitor_stepsId?: InputMaybe<ModelIdInput>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanStepConnection = {
  __typename?: 'ModelScanStepConnection';
  items: Array<Maybe<ScanStep>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelScanStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanStepFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelScanStepFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanStepFilterInput>>>;
  scanStepMonitor_stepsId?: InputMaybe<ModelIdInput>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelSensorConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSensorConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  model?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelSensorConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSensorConditionInput>>>;
  sensor_type?: InputMaybe<ModelStringInput>;
  serial?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelSensorConnection = {
  __typename?: 'ModelSensorConnection';
  items: Array<Maybe<Sensor>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelSensorFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSensorFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  model?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelSensorFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSensorFilterInput>>>;
  sensor_type?: InputMaybe<ModelStringInput>;
  serial?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelSiteConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSiteConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  customerSitesId?: InputMaybe<ModelIdInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  locationSitesId?: InputMaybe<ModelIdInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelSiteConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSiteConditionInput>>>;
  profiles_override?: InputMaybe<ModelStringInput>;
  projected_crs?: InputMaybe<ModelStringInput>;
  s3_ref?: InputMaybe<ModelStringInput>;
  sitePlanned_entitiesId?: InputMaybe<ModelIdInput>;
  site_type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelSiteConnection = {
  __typename?: 'ModelSiteConnection';
  items: Array<Maybe<Site>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelSiteFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSiteFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  customerSitesId?: InputMaybe<ModelIdInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  locationSitesId?: InputMaybe<ModelIdInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelSiteFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSiteFilterInput>>>;
  profiles_override?: InputMaybe<ModelStringInput>;
  projected_crs?: InputMaybe<ModelStringInput>;
  s3_ref?: InputMaybe<ModelStringInput>;
  sitePlanned_entitiesId?: InputMaybe<ModelIdInput>;
  site_type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelSizeInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelSubscriptionAlgoUtilityMetricsFilterInput = {
  algoUtilityMetricsAnalyzed_entityId?: InputMaybe<ModelSubscriptionIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionAlgoUtilityMetricsFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  metrics?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionAlgoUtilityMetricsFilterInput>>>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionAnalysisStepFilterInput = {
  access_time?: InputMaybe<ModelSubscriptionIntInput>;
  access_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  analysisStepMonitor_stepsId?: InputMaybe<ModelSubscriptionIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionAnalysisStepFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  error_message?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  is_new_found_entity?: InputMaybe<ModelSubscriptionBooleanInput>;
  is_scan_opened?: InputMaybe<ModelSubscriptionBooleanInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionAnalysisStepFilterInput>>>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionAnalyzedEntityFilterInput = {
  analysis_confidence?: InputMaybe<ModelSubscriptionFloatInput>;
  analyzedEntityAlgo_utility_metricsId?: InputMaybe<ModelSubscriptionIdInput>;
  analyzedEntityDepth_pointsId?: InputMaybe<ModelSubscriptionIdInput>;
  analyzedEntityPipesqa_viewId?: InputMaybe<ModelSubscriptionIdInput>;
  analyzedEntityReanalysis_requestsId?: InputMaybe<ModelSubscriptionIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>>>;
  app_name?: InputMaybe<ModelSubscriptionStringInput>;
  app_version?: InputMaybe<ModelSubscriptionStringInput>;
  auto_confidence?: InputMaybe<ModelSubscriptionFloatInput>;
  classification?: InputMaybe<ModelSubscriptionStringInput>;
  comment?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  creation_source?: InputMaybe<ModelSubscriptionStringInput>;
  creation_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  creation_timestamp?: InputMaybe<ModelSubscriptionIntInput>;
  data_source?: InputMaybe<ModelSubscriptionStringInput>;
  detailed_geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  frequency?: InputMaybe<ModelSubscriptionFloatInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  is_valid?: InputMaybe<ModelSubscriptionBooleanInput>;
  magnetization?: InputMaybe<ModelSubscriptionFloatInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>>>;
  s3_analysis_ref?: InputMaybe<ModelSubscriptionStringInput>;
  s3_epls_ref?: InputMaybe<ModelSubscriptionStringInput>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  sensor_model?: InputMaybe<ModelSubscriptionStringInput>;
  snr?: InputMaybe<ModelSubscriptionFloatInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  user?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionBooleanInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type ModelSubscriptionCommentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCommentFilterInput>>>;
  changed_by?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCommentFilterInput>>>;
  text?: InputMaybe<ModelSubscriptionStringInput>;
  timestamp?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionCustomerFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCustomerFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  customerSitesId?: InputMaybe<ModelSubscriptionIdInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCustomerFilterInput>>>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionEntityDepthPointsFilterInput = {
  algorithm?: InputMaybe<ModelSubscriptionStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionEntityDepthPointsFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  depthsFromGround?: InputMaybe<ModelSubscriptionFloatInput>;
  ellipsoidHeights?: InputMaybe<ModelSubscriptionFloatInput>;
  ellipsoidHeightsMask?: InputMaybe<ModelSubscriptionBooleanInput>;
  hadError?: InputMaybe<ModelSubscriptionBooleanInput>;
  hasEllipsoidHeight?: InputMaybe<ModelSubscriptionBooleanInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  lats?: InputMaybe<ModelSubscriptionFloatInput>;
  lons?: InputMaybe<ModelSubscriptionFloatInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionEntityDepthPointsFilterInput>>>;
  originalCrs?: InputMaybe<ModelSubscriptionStringInput>;
  type?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionFloatInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq?: InputMaybe<Scalars['Float']>;
  ge?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  le?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type ModelSubscriptionGeoidValueFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionGeoidValueFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  lat?: InputMaybe<ModelSubscriptionFloatInput>;
  lon?: InputMaybe<ModelSubscriptionFloatInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionGeoidValueFilterInput>>>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  value?: InputMaybe<ModelSubscriptionFloatInput>;
};

export type ModelSubscriptionIdInput = {
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ModelSubscriptionIntInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ModelSubscriptionLocationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionLocationFilterInput>>>;
  country?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  locationSitesId?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionLocationFilterInput>>>;
  region?: InputMaybe<ModelSubscriptionStringInput>;
  state?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionManholeItemFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionManholeItemFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  depth?: InputMaybe<ModelSubscriptionFloatInput>;
  gpsHeight?: InputMaybe<ModelSubscriptionFloatInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  manholeCoverDiameter?: InputMaybe<ModelSubscriptionFloatInput>;
  manholeCoverMaterial?: InputMaybe<ModelSubscriptionStringInput>;
  manholeCoverShape?: InputMaybe<ModelSubscriptionStringInput>;
  manholeItemPipesId?: InputMaybe<ModelSubscriptionIdInput>;
  manholeItemPoiId?: InputMaybe<ModelSubscriptionIdInput>;
  manholeName?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionManholeItemFilterInput>>>;
  otherOwnerName?: InputMaybe<ModelSubscriptionStringInput>;
  overallNumber?: InputMaybe<ModelSubscriptionIntInput>;
  owner?: InputMaybe<ModelSubscriptionStringInput>;
  pipeDiscoveryStatus?: InputMaybe<ModelSubscriptionStringInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  utilityTypes?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionMappingEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionMappingEntityFilterInput>>>;
  app_name?: InputMaybe<ModelSubscriptionStringInput>;
  app_version?: InputMaybe<ModelSubscriptionStringInput>;
  classification?: InputMaybe<ModelSubscriptionStringInput>;
  color?: InputMaybe<ModelSubscriptionStringInput>;
  comment?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  creation_source?: InputMaybe<ModelSubscriptionStringInput>;
  creation_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  creation_timestamp?: InputMaybe<ModelSubscriptionIntInput>;
  data_source?: InputMaybe<ModelSubscriptionStringInput>;
  depth?: InputMaybe<ModelSubscriptionFloatInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  is_valid?: InputMaybe<ModelSubscriptionBooleanInput>;
  mappingEntityDepth_pointsId?: InputMaybe<ModelSubscriptionIdInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  object_type?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionMappingEntityFilterInput>>>;
  properties?: InputMaybe<ModelSubscriptionStringInput>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  user?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionMonitorStepsFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionMonitorStepsFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  monitorStepsAnalysis_stepId?: InputMaybe<ModelSubscriptionIdInput>;
  monitorStepsPreloader_stepId?: InputMaybe<ModelSubscriptionIdInput>;
  monitorStepsScanId?: InputMaybe<ModelSubscriptionIdInput>;
  monitorStepsScan_stepId?: InputMaybe<ModelSubscriptionIdInput>;
  monitorStepsUpload_stepId?: InputMaybe<ModelSubscriptionIdInput>;
  monitorStepsUtility_finder_stepId?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionMonitorStepsFilterInput>>>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionPoiEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPoiEntityFilterInput>>>;
  comment?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPoiEntityFilterInput>>>;
  pOIEntityDataId?: InputMaybe<ModelSubscriptionIdInput>;
  poiType?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionPipeItemFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPipeItemFilterInput>>>;
  azimuth?: InputMaybe<ModelSubscriptionFloatInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  description?: InputMaybe<ModelSubscriptionStringInput>;
  diameter?: InputMaybe<ModelSubscriptionFloatInput>;
  flowDirection?: InputMaybe<ModelSubscriptionStringInput>;
  hive?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  invertLevel?: InputMaybe<ModelSubscriptionFloatInput>;
  media?: InputMaybe<ModelSubscriptionStringInput>;
  noCables?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPipeItemFilterInput>>>;
  secondaryOwner?: InputMaybe<ModelSubscriptionStringInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  thickness?: InputMaybe<ModelSubscriptionFloatInput>;
  topLevel?: InputMaybe<ModelSubscriptionFloatInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionPipelineRunInfoFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPipelineRunInfoFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  end_time?: InputMaybe<ModelSubscriptionIntInput>;
  end_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  epls_s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  error_message?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPipelineRunInfoFilterInput>>>;
  start_time?: InputMaybe<ModelSubscriptionIntInput>;
  start_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionPipesqaPipeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPipesqaPipeFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  crossings_statuses?: InputMaybe<ModelSubscriptionStringInput>;
  flagged?: InputMaybe<ModelSubscriptionBooleanInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPipesqaPipeFilterInput>>>;
  pipesqaPipeAnalyzed_entityId?: InputMaybe<ModelSubscriptionIdInput>;
  pipesqaPipeCommentsId?: InputMaybe<ModelSubscriptionIdInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  update_timestamp?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionPlannedEntitiesFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPlannedEntitiesFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPlannedEntitiesFilterInput>>>;
  plannedEntitiesPlanned_polygonsId?: InputMaybe<ModelSubscriptionIdInput>;
  plannedEntitiesSiteId?: InputMaybe<ModelSubscriptionIdInput>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionPlannedPolygonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPlannedPolygonFilterInput>>>;
  color?: InputMaybe<ModelSubscriptionStringInput>;
  comment?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  is_rescan?: InputMaybe<ModelSubscriptionBooleanInput>;
  mount_method?: InputMaybe<ModelSubscriptionStringInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPlannedPolygonFilterInput>>>;
  override_color?: InputMaybe<ModelSubscriptionBooleanInput>;
  plannedPolygonPlanned_transmittersId?: InputMaybe<ModelSubscriptionIdInput>;
  sensor_models?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionPlannedTransmitterFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPlannedTransmitterFilterInput>>>;
  color?: InputMaybe<ModelSubscriptionStringInput>;
  comment?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  method?: InputMaybe<ModelSubscriptionStringInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPlannedTransmitterFilterInput>>>;
  override_color?: InputMaybe<ModelSubscriptionBooleanInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionPreloadRunInfoFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPreloadRunInfoFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  end_time?: InputMaybe<ModelSubscriptionIntInput>;
  end_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  epls_s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  error_message?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPreloadRunInfoFilterInput>>>;
  preloaded_scan_s3_refs?: InputMaybe<ModelSubscriptionStringInput>;
  products?: InputMaybe<ModelSubscriptionStringInput>;
  start_time?: InputMaybe<ModelSubscriptionIntInput>;
  start_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  target_name?: InputMaybe<ModelSubscriptionStringInput>;
  target_type?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionPreloaderStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPreloaderStepFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  end_time?: InputMaybe<ModelSubscriptionIntInput>;
  end_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  error_message?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPreloaderStepFilterInput>>>;
  preloaderStepMonitor_stepsId?: InputMaybe<ModelSubscriptionIdInput>;
  preloaderStepPreload_runsId?: InputMaybe<ModelSubscriptionIdInput>;
  start_time?: InputMaybe<ModelSubscriptionIntInput>;
  start_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionReanalysisRequestCommentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionReanalysisRequestCommentFilterInput>>>;
  comment?: InputMaybe<ModelSubscriptionStringInput>;
  commenter?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  date?: InputMaybe<ModelSubscriptionIntInput>;
  date_iso?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionReanalysisRequestCommentFilterInput>>>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionReanalysisRequestFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionReanalysisRequestFilterInput>>>;
  assignee?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  due_date?: InputMaybe<ModelSubscriptionIntInput>;
  due_date_iso?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  opened_by?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionReanalysisRequestFilterInput>>>;
  reanalysisRequestCommentsId?: InputMaybe<ModelSubscriptionIdInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionScanAccessFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanAccessFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  end_timestamp?: InputMaybe<ModelSubscriptionIntInput>;
  frequency?: InputMaybe<ModelSubscriptionFloatInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanAccessFilterInput>>>;
  start_timestamp?: InputMaybe<ModelSubscriptionIntInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  user?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionScanByHashedParametersFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanByHashedParametersFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  hash?: InputMaybe<ModelSubscriptionIdInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanByHashedParametersFilterInput>>>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  scan_id?: InputMaybe<ModelSubscriptionIdInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionScanDepthPointsFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanDepthPointsFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  ellipsoidHeights?: InputMaybe<ModelSubscriptionFloatInput>;
  headings?: InputMaybe<ModelSubscriptionFloatInput>;
  heightMode?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  lats?: InputMaybe<ModelSubscriptionFloatInput>;
  lons?: InputMaybe<ModelSubscriptionFloatInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanDepthPointsFilterInput>>>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanFilterInput>>>;
  bounding_polygon_s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  comment?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  downsampled_gps?: InputMaybe<ModelSubscriptionFloatInput>;
  end_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  end_timestamp?: InputMaybe<ModelSubscriptionIntInput>;
  frequency?: InputMaybe<ModelSubscriptionFloatInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  is_unified?: InputMaybe<ModelSubscriptionBooleanInput>;
  is_valid?: InputMaybe<ModelSubscriptionBooleanInput>;
  machine_id?: InputMaybe<ModelSubscriptionStringInput>;
  metadata?: InputMaybe<ModelSubscriptionStringInput>;
  mount_method?: InputMaybe<ModelSubscriptionStringInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanFilterInput>>>;
  original_sensor_types?: InputMaybe<ModelSubscriptionStringInput>;
  part_index?: InputMaybe<ModelSubscriptionIntInput>;
  polygon_index?: InputMaybe<ModelSubscriptionIntInput>;
  s3_preloaded_refs?: InputMaybe<ModelSubscriptionStringInput>;
  s3_raw_ref?: InputMaybe<ModelSubscriptionStringInput>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  scanAnalyzed_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  scanMonitoring_stepsId?: InputMaybe<ModelSubscriptionIdInput>;
  scanPoisId?: InputMaybe<ModelSubscriptionIdInput>;
  scanScan_accessesId?: InputMaybe<ModelSubscriptionIdInput>;
  scanScan_depthsId?: InputMaybe<ModelSubscriptionIdInput>;
  scanSource_scansId?: InputMaybe<ModelSubscriptionIdInput>;
  scanTarget_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  scanTransmitter_setupsId?: InputMaybe<ModelSubscriptionIdInput>;
  scanTravel_coursesId?: InputMaybe<ModelSubscriptionIdInput>;
  scanV2_target_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  scan_type?: InputMaybe<ModelSubscriptionStringInput>;
  sensor_type?: InputMaybe<ModelSubscriptionStringInput>;
  snake_index?: InputMaybe<ModelSubscriptionIntInput>;
  start_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  start_timestamp?: InputMaybe<ModelSubscriptionIntInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  target_info?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionScanStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanStepFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  end_time?: InputMaybe<ModelSubscriptionIntInput>;
  end_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  error_message?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanStepFilterInput>>>;
  scanStepMonitor_stepsId?: InputMaybe<ModelSubscriptionIdInput>;
  start_time?: InputMaybe<ModelSubscriptionIntInput>;
  start_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionSensorFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSensorFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  model?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSensorFilterInput>>>;
  sensorScansId?: InputMaybe<ModelSubscriptionIdInput>;
  sensor_type?: InputMaybe<ModelSubscriptionStringInput>;
  serial?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionSiteFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSiteFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSiteFilterInput>>>;
  profiles_override?: InputMaybe<ModelSubscriptionStringInput>;
  projected_crs?: InputMaybe<ModelSubscriptionStringInput>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  siteAnalyzed_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  siteGeoid_valuesId?: InputMaybe<ModelSubscriptionIdInput>;
  siteMapping_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  sitePlanned_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  sitePoisId?: InputMaybe<ModelSubscriptionIdInput>;
  siteReanalysis_requestsId?: InputMaybe<ModelSubscriptionIdInput>;
  siteScansId?: InputMaybe<ModelSubscriptionIdInput>;
  siteSite_depthsId?: InputMaybe<ModelSubscriptionIdInput>;
  siteSite_pipe_depthsId?: InputMaybe<ModelSubscriptionIdInput>;
  siteTarget_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  siteTransmitter_setupsId?: InputMaybe<ModelSubscriptionIdInput>;
  siteTravel_coursesId?: InputMaybe<ModelSubscriptionIdInput>;
  siteUnify_tasksId?: InputMaybe<ModelSubscriptionIdInput>;
  siteV2_target_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  site_type?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionStringInput = {
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ModelSubscriptionTargetEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionTargetEntityFilterInput>>>;
  app_name?: InputMaybe<ModelSubscriptionStringInput>;
  app_version?: InputMaybe<ModelSubscriptionStringInput>;
  classification?: InputMaybe<ModelSubscriptionStringInput>;
  comment?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  creation_source?: InputMaybe<ModelSubscriptionStringInput>;
  creation_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  creation_timestamp?: InputMaybe<ModelSubscriptionIntInput>;
  data_source?: InputMaybe<ModelSubscriptionStringInput>;
  display_params?: InputMaybe<ModelSubscriptionStringInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  is_valid?: InputMaybe<ModelSubscriptionBooleanInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionTargetEntityFilterInput>>>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  sensor_model?: InputMaybe<ModelSubscriptionStringInput>;
  target_type?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  user?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionTasksScansConnectionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionTasksScansConnectionFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionTasksScansConnectionFilterInput>>>;
  scanId?: InputMaybe<ModelSubscriptionIdInput>;
  unifyTaskId?: InputMaybe<ModelSubscriptionIdInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionTransmitterFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionTransmitterFilterInput>>>;
  asset_id?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionTransmitterFilterInput>>>;
  serial?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionTransmitterSetupFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionTransmitterSetupFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  frequency?: InputMaybe<ModelSubscriptionFloatInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  is_valid?: InputMaybe<ModelSubscriptionBooleanInput>;
  method?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionTransmitterSetupFilterInput>>>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  snr?: InputMaybe<ModelSubscriptionFloatInput>;
  source?: InputMaybe<ModelSubscriptionStringInput>;
  tags?: InputMaybe<ModelSubscriptionStringInput>;
  transmitterSetupReanalysis_requestsId?: InputMaybe<ModelSubscriptionIdInput>;
  transmitterSetupTransmitterId?: InputMaybe<ModelSubscriptionIdInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionTravelCourseFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionTravelCourseFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  gps_fixed_ratio?: InputMaybe<ModelSubscriptionFloatInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  is_valid?: InputMaybe<ModelSubscriptionBooleanInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionTravelCourseFilterInput>>>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  tag?: InputMaybe<ModelSubscriptionStringInput>;
  travelCourseReanalysis_requestsId?: InputMaybe<ModelSubscriptionIdInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionUnifyTaskFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionUnifyTaskFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  frequencies?: InputMaybe<ModelSubscriptionFloatInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionUnifyTaskFilterInput>>>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  user_email?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionUploadStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionUploadStepFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  end_time?: InputMaybe<ModelSubscriptionIntInput>;
  end_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  error_message?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionUploadStepFilterInput>>>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  start_time?: InputMaybe<ModelSubscriptionIntInput>;
  start_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  uploadStepMonitor_stepsId?: InputMaybe<ModelSubscriptionIdInput>;
  upload_speed?: InputMaybe<ModelSubscriptionFloatInput>;
  uploader?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionUtilityFinderStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionUtilityFinderStepFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  end_time?: InputMaybe<ModelSubscriptionIntInput>;
  end_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  error_message?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionUtilityFinderStepFilterInput>>>;
  start_time?: InputMaybe<ModelSubscriptionIntInput>;
  start_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  utilityFinderStepMonitor_stepsId?: InputMaybe<ModelSubscriptionIdInput>;
  utilityFinderStepPipelinesId?: InputMaybe<ModelSubscriptionIdInput>;
};

export type ModelSubscriptionV2TargetEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionV2TargetEntityFilterInput>>>;
  app_name?: InputMaybe<ModelSubscriptionStringInput>;
  app_version?: InputMaybe<ModelSubscriptionStringInput>;
  classification?: InputMaybe<ModelSubscriptionStringInput>;
  comment?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  creation_source?: InputMaybe<ModelSubscriptionStringInput>;
  creation_time_iso?: InputMaybe<ModelSubscriptionStringInput>;
  creation_timestamp?: InputMaybe<ModelSubscriptionIntInput>;
  data_source?: InputMaybe<ModelSubscriptionStringInput>;
  display_params?: InputMaybe<ModelSubscriptionStringInput>;
  geo_data?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  is_valid?: InputMaybe<ModelSubscriptionBooleanInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionV2TargetEntityFilterInput>>>;
  s3_ref?: InputMaybe<ModelSubscriptionStringInput>;
  sensor_model?: InputMaybe<ModelSubscriptionStringInput>;
  target_type?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  user?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelTargetEntityConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTargetEntityConditionInput>>>;
  app_name?: InputMaybe<ModelStringInput>;
  app_version?: InputMaybe<ModelStringInput>;
  classification?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  creation_source?: InputMaybe<ModelStringInput>;
  creation_time_iso?: InputMaybe<ModelStringInput>;
  creation_timestamp?: InputMaybe<ModelIntInput>;
  data_source?: InputMaybe<ModelStringInput>;
  display_params?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelTargetEntityConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTargetEntityConditionInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanTarget_entitiesId?: InputMaybe<ModelIdInput>;
  sensor_model?: InputMaybe<ModelStringInput>;
  siteTarget_entitiesId?: InputMaybe<ModelIdInput>;
  target_type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelTargetEntityConnection = {
  __typename?: 'ModelTargetEntityConnection';
  items: Array<Maybe<TargetEntity>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTargetEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTargetEntityFilterInput>>>;
  app_name?: InputMaybe<ModelStringInput>;
  app_version?: InputMaybe<ModelStringInput>;
  classification?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  creation_source?: InputMaybe<ModelStringInput>;
  creation_time_iso?: InputMaybe<ModelStringInput>;
  creation_timestamp?: InputMaybe<ModelIntInput>;
  data_source?: InputMaybe<ModelStringInput>;
  display_params?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelTargetEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTargetEntityFilterInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanTarget_entitiesId?: InputMaybe<ModelIdInput>;
  sensor_model?: InputMaybe<ModelStringInput>;
  siteTarget_entitiesId?: InputMaybe<ModelIdInput>;
  target_type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelTasksScansConnectionConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTasksScansConnectionConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelTasksScansConnectionConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTasksScansConnectionConditionInput>>>;
  scanId?: InputMaybe<ModelIdInput>;
  unifyTaskId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelTasksScansConnectionConnection = {
  __typename?: 'ModelTasksScansConnectionConnection';
  items: Array<Maybe<TasksScansConnection>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTasksScansConnectionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTasksScansConnectionFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelTasksScansConnectionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTasksScansConnectionFilterInput>>>;
  scanId?: InputMaybe<ModelIdInput>;
  unifyTaskId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelTransmitterConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTransmitterConditionInput>>>;
  asset_id?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelTransmitterConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTransmitterConditionInput>>>;
  serial?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelTransmitterConnection = {
  __typename?: 'ModelTransmitterConnection';
  items: Array<Maybe<Transmitter>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTransmitterFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTransmitterFilterInput>>>;
  asset_id?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelTransmitterFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTransmitterFilterInput>>>;
  serial?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelTransmitterSetupConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTransmitterSetupConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  method?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelTransmitterSetupConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTransmitterSetupConditionInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanTransmitter_setupsId?: InputMaybe<ModelIdInput>;
  siteTransmitter_setupsId?: InputMaybe<ModelIdInput>;
  snr?: InputMaybe<ModelFloatInput>;
  source?: InputMaybe<ModelStringInput>;
  tags?: InputMaybe<ModelStringInput>;
  transmitterSetupTransmitterId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelTransmitterSetupConnection = {
  __typename?: 'ModelTransmitterSetupConnection';
  items: Array<Maybe<TransmitterSetup>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTransmitterSetupFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTransmitterSetupFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  method?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelTransmitterSetupFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTransmitterSetupFilterInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanTransmitter_setupsId?: InputMaybe<ModelIdInput>;
  siteTransmitter_setupsId?: InputMaybe<ModelIdInput>;
  snr?: InputMaybe<ModelFloatInput>;
  source?: InputMaybe<ModelStringInput>;
  tags?: InputMaybe<ModelStringInput>;
  transmitterSetupTransmitterId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelTravelCourseConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTravelCourseConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  gps_fixed_ratio?: InputMaybe<ModelFloatInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  not?: InputMaybe<ModelTravelCourseConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTravelCourseConditionInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanTravel_coursesId?: InputMaybe<ModelIdInput>;
  siteTravel_coursesId?: InputMaybe<ModelIdInput>;
  tag?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelTravelCourseConnection = {
  __typename?: 'ModelTravelCourseConnection';
  items: Array<Maybe<TravelCourse>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTravelCourseFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTravelCourseFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  gps_fixed_ratio?: InputMaybe<ModelFloatInput>;
  id?: InputMaybe<ModelIdInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  not?: InputMaybe<ModelTravelCourseFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTravelCourseFilterInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanTravel_coursesId?: InputMaybe<ModelIdInput>;
  siteTravel_coursesId?: InputMaybe<ModelIdInput>;
  tag?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelUnifyTaskConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUnifyTaskConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  frequencies?: InputMaybe<ModelFloatInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelUnifyTaskConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUnifyTaskConditionInput>>>;
  siteUnify_tasksId?: InputMaybe<ModelIdInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user_email?: InputMaybe<ModelStringInput>;
};

export type ModelUnifyTaskConnection = {
  __typename?: 'ModelUnifyTaskConnection';
  items: Array<Maybe<UnifyTask>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUnifyTaskFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUnifyTaskFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  frequencies?: InputMaybe<ModelFloatInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelUnifyTaskFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUnifyTaskFilterInput>>>;
  siteUnify_tasksId?: InputMaybe<ModelIdInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user_email?: InputMaybe<ModelStringInput>;
};

export type ModelUploadStepConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUploadStepConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelUploadStepConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUploadStepConditionInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  uploadStepMonitor_stepsId?: InputMaybe<ModelIdInput>;
  upload_speed?: InputMaybe<ModelFloatInput>;
  uploader?: InputMaybe<ModelStringInput>;
};

export type ModelUploadStepConnection = {
  __typename?: 'ModelUploadStepConnection';
  items: Array<Maybe<UploadStep>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUploadStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUploadStepFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelUploadStepFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUploadStepFilterInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  uploadStepMonitor_stepsId?: InputMaybe<ModelIdInput>;
  upload_speed?: InputMaybe<ModelFloatInput>;
  uploader?: InputMaybe<ModelStringInput>;
};

export type ModelUtilityFinderStepConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUtilityFinderStepConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelUtilityFinderStepConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUtilityFinderStepConditionInput>>>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  utilityFinderStepMonitor_stepsId?: InputMaybe<ModelIdInput>;
};

export type ModelUtilityFinderStepConnection = {
  __typename?: 'ModelUtilityFinderStepConnection';
  items: Array<Maybe<UtilityFinderStep>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUtilityFinderStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUtilityFinderStepFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  end_time?: InputMaybe<ModelIntInput>;
  end_time_iso?: InputMaybe<ModelStringInput>;
  error_message?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelUtilityFinderStepFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUtilityFinderStepFilterInput>>>;
  start_time?: InputMaybe<ModelIntInput>;
  start_time_iso?: InputMaybe<ModelStringInput>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  utilityFinderStepMonitor_stepsId?: InputMaybe<ModelIdInput>;
};

export type ModelV2TargetEntityConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelV2TargetEntityConditionInput>>>;
  app_name?: InputMaybe<ModelStringInput>;
  app_version?: InputMaybe<ModelStringInput>;
  classification?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  creation_source?: InputMaybe<ModelStringInput>;
  creation_time_iso?: InputMaybe<ModelStringInput>;
  creation_timestamp?: InputMaybe<ModelIntInput>;
  data_source?: InputMaybe<ModelStringInput>;
  display_params?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelV2TargetEntityConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelV2TargetEntityConditionInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanV2_target_entitiesId?: InputMaybe<ModelIdInput>;
  sensor_model?: InputMaybe<ModelStringInput>;
  siteV2_target_entitiesId?: InputMaybe<ModelIdInput>;
  target_type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelV2TargetEntityConnection = {
  __typename?: 'ModelV2TargetEntityConnection';
  items: Array<Maybe<V2TargetEntity>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelV2TargetEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelV2TargetEntityFilterInput>>>;
  app_name?: InputMaybe<ModelStringInput>;
  app_version?: InputMaybe<ModelStringInput>;
  classification?: InputMaybe<ModelStringInput>;
  comment?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  creation_source?: InputMaybe<ModelStringInput>;
  creation_time_iso?: InputMaybe<ModelStringInput>;
  creation_timestamp?: InputMaybe<ModelIntInput>;
  data_source?: InputMaybe<ModelStringInput>;
  display_params?: InputMaybe<ModelStringInput>;
  geo_data?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  is_valid?: InputMaybe<ModelBooleanInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelV2TargetEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelV2TargetEntityFilterInput>>>;
  s3_ref?: InputMaybe<ModelStringInput>;
  scanV2_target_entitiesId?: InputMaybe<ModelIdInput>;
  sensor_model?: InputMaybe<ModelStringInput>;
  siteV2_target_entitiesId?: InputMaybe<ModelIdInput>;
  target_type?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type MonitorSteps = {
  __typename?: 'MonitorSteps';
  analysis_step: AnalysisStep;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  monitorStepsAnalysis_stepId: Scalars['ID'];
  monitorStepsPreloader_stepId: Scalars['ID'];
  monitorStepsScanId: Scalars['ID'];
  monitorStepsScan_stepId: Scalars['ID'];
  monitorStepsUpload_stepId: Scalars['ID'];
  monitorStepsUtility_finder_stepId: Scalars['ID'];
  preloader_step: PreloaderStep;
  scan: Scan;
  scan_step: ScanStep;
  updatedAt: Scalars['AWSDateTime'];
  upload_step: UploadStep;
  utility_finder_step: UtilityFinderStep;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAlgoUtilityMetrics?: Maybe<AlgoUtilityMetrics>;
  createAnalysisStep?: Maybe<AnalysisStep>;
  createAnalyzedEntity?: Maybe<AnalyzedEntity>;
  createComment?: Maybe<Comment>;
  createCustomer?: Maybe<Customer>;
  createEntityDepthPoints?: Maybe<EntityDepthPoints>;
  createGeoidValue?: Maybe<GeoidValue>;
  createLocation?: Maybe<Location>;
  createManholeItem?: Maybe<ManholeItem>;
  createMappingEntity?: Maybe<MappingEntity>;
  createMonitorSteps?: Maybe<MonitorSteps>;
  createPOIEntity?: Maybe<PoiEntity>;
  createPipeItem?: Maybe<PipeItem>;
  createPipelineRunInfo?: Maybe<PipelineRunInfo>;
  createPipesqaPipe?: Maybe<PipesqaPipe>;
  createPlannedEntities?: Maybe<PlannedEntities>;
  createPlannedPolygon?: Maybe<PlannedPolygon>;
  createPlannedTransmitter?: Maybe<PlannedTransmitter>;
  createPreloadRunInfo?: Maybe<PreloadRunInfo>;
  createPreloaderStep?: Maybe<PreloaderStep>;
  createReanalysisRequest?: Maybe<ReanalysisRequest>;
  createReanalysisRequestComment?: Maybe<ReanalysisRequestComment>;
  createScan?: Maybe<Scan>;
  createScanAccess?: Maybe<ScanAccess>;
  createScanByHashedParameters?: Maybe<ScanByHashedParameters>;
  createScanDepthPoints?: Maybe<ScanDepthPoints>;
  createScanStep?: Maybe<ScanStep>;
  createSensor?: Maybe<Sensor>;
  createSite?: Maybe<Site>;
  createTargetEntity?: Maybe<TargetEntity>;
  createTasksScansConnection?: Maybe<TasksScansConnection>;
  createTransmitter?: Maybe<Transmitter>;
  createTransmitterSetup?: Maybe<TransmitterSetup>;
  createTravelCourse?: Maybe<TravelCourse>;
  createUnifyTask?: Maybe<UnifyTask>;
  createUploadStep?: Maybe<UploadStep>;
  createUtilityFinderStep?: Maybe<UtilityFinderStep>;
  createV2TargetEntity?: Maybe<V2TargetEntity>;
  deleteAlgoUtilityMetrics?: Maybe<AlgoUtilityMetrics>;
  deleteAnalysisStep?: Maybe<AnalysisStep>;
  deleteAnalyzedEntity?: Maybe<AnalyzedEntity>;
  deleteComment?: Maybe<Comment>;
  deleteCustomer?: Maybe<Customer>;
  deleteEntityDepthPoints?: Maybe<EntityDepthPoints>;
  deleteGeoidValue?: Maybe<GeoidValue>;
  deleteLocation?: Maybe<Location>;
  deleteManholeItem?: Maybe<ManholeItem>;
  deleteMappingEntity?: Maybe<MappingEntity>;
  deleteMonitorSteps?: Maybe<MonitorSteps>;
  deletePOIEntity?: Maybe<PoiEntity>;
  deletePipeItem?: Maybe<PipeItem>;
  deletePipelineRunInfo?: Maybe<PipelineRunInfo>;
  deletePipesqaPipe?: Maybe<PipesqaPipe>;
  deletePlannedEntities?: Maybe<PlannedEntities>;
  deletePlannedPolygon?: Maybe<PlannedPolygon>;
  deletePlannedTransmitter?: Maybe<PlannedTransmitter>;
  deletePreloadRunInfo?: Maybe<PreloadRunInfo>;
  deletePreloaderStep?: Maybe<PreloaderStep>;
  deleteReanalysisRequest?: Maybe<ReanalysisRequest>;
  deleteReanalysisRequestComment?: Maybe<ReanalysisRequestComment>;
  deleteScan?: Maybe<Scan>;
  deleteScanAccess?: Maybe<ScanAccess>;
  deleteScanByHashedParameters?: Maybe<ScanByHashedParameters>;
  deleteScanDepthPoints?: Maybe<ScanDepthPoints>;
  deleteScanStep?: Maybe<ScanStep>;
  deleteSensor?: Maybe<Sensor>;
  deleteSite?: Maybe<Site>;
  deleteTargetEntity?: Maybe<TargetEntity>;
  deleteTasksScansConnection?: Maybe<TasksScansConnection>;
  deleteTransmitter?: Maybe<Transmitter>;
  deleteTransmitterSetup?: Maybe<TransmitterSetup>;
  deleteTravelCourse?: Maybe<TravelCourse>;
  deleteUnifyTask?: Maybe<UnifyTask>;
  deleteUploadStep?: Maybe<UploadStep>;
  deleteUtilityFinderStep?: Maybe<UtilityFinderStep>;
  deleteV2TargetEntity?: Maybe<V2TargetEntity>;
  updateAlgoUtilityMetrics?: Maybe<AlgoUtilityMetrics>;
  updateAnalysisStep?: Maybe<AnalysisStep>;
  updateAnalyzedEntity?: Maybe<AnalyzedEntity>;
  updateComment?: Maybe<Comment>;
  updateCustomer?: Maybe<Customer>;
  updateEntityDepthPoints?: Maybe<EntityDepthPoints>;
  updateGeoidValue?: Maybe<GeoidValue>;
  updateLocation?: Maybe<Location>;
  updateManholeItem?: Maybe<ManholeItem>;
  updateMappingEntity?: Maybe<MappingEntity>;
  updateMonitorSteps?: Maybe<MonitorSteps>;
  updatePOIEntity?: Maybe<PoiEntity>;
  updatePipeItem?: Maybe<PipeItem>;
  updatePipelineRunInfo?: Maybe<PipelineRunInfo>;
  updatePipesqaPipe?: Maybe<PipesqaPipe>;
  updatePlannedEntities?: Maybe<PlannedEntities>;
  updatePlannedPolygon?: Maybe<PlannedPolygon>;
  updatePlannedTransmitter?: Maybe<PlannedTransmitter>;
  updatePreloadRunInfo?: Maybe<PreloadRunInfo>;
  updatePreloaderStep?: Maybe<PreloaderStep>;
  updateReanalysisRequest?: Maybe<ReanalysisRequest>;
  updateReanalysisRequestComment?: Maybe<ReanalysisRequestComment>;
  updateScan?: Maybe<Scan>;
  updateScanAccess?: Maybe<ScanAccess>;
  updateScanByHashedParameters?: Maybe<ScanByHashedParameters>;
  updateScanDepthPoints?: Maybe<ScanDepthPoints>;
  updateScanStep?: Maybe<ScanStep>;
  updateSensor?: Maybe<Sensor>;
  updateSite?: Maybe<Site>;
  updateTargetEntity?: Maybe<TargetEntity>;
  updateTasksScansConnection?: Maybe<TasksScansConnection>;
  updateTransmitter?: Maybe<Transmitter>;
  updateTransmitterSetup?: Maybe<TransmitterSetup>;
  updateTravelCourse?: Maybe<TravelCourse>;
  updateUnifyTask?: Maybe<UnifyTask>;
  updateUploadStep?: Maybe<UploadStep>;
  updateUtilityFinderStep?: Maybe<UtilityFinderStep>;
  updateV2TargetEntity?: Maybe<V2TargetEntity>;
};


export type MutationCreateAlgoUtilityMetricsArgs = {
  condition?: InputMaybe<ModelAlgoUtilityMetricsConditionInput>;
  input: CreateAlgoUtilityMetricsInput;
};


export type MutationCreateAnalysisStepArgs = {
  condition?: InputMaybe<ModelAnalysisStepConditionInput>;
  input: CreateAnalysisStepInput;
};


export type MutationCreateAnalyzedEntityArgs = {
  condition?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  input: CreateAnalyzedEntityInput;
};


export type MutationCreateCommentArgs = {
  condition?: InputMaybe<ModelCommentConditionInput>;
  input: CreateCommentInput;
};


export type MutationCreateCustomerArgs = {
  condition?: InputMaybe<ModelCustomerConditionInput>;
  input: CreateCustomerInput;
};


export type MutationCreateEntityDepthPointsArgs = {
  condition?: InputMaybe<ModelEntityDepthPointsConditionInput>;
  input: CreateEntityDepthPointsInput;
};


export type MutationCreateGeoidValueArgs = {
  condition?: InputMaybe<ModelGeoidValueConditionInput>;
  input: CreateGeoidValueInput;
};


export type MutationCreateLocationArgs = {
  condition?: InputMaybe<ModelLocationConditionInput>;
  input: CreateLocationInput;
};


export type MutationCreateManholeItemArgs = {
  condition?: InputMaybe<ModelManholeItemConditionInput>;
  input: CreateManholeItemInput;
};


export type MutationCreateMappingEntityArgs = {
  condition?: InputMaybe<ModelMappingEntityConditionInput>;
  input: CreateMappingEntityInput;
};


export type MutationCreateMonitorStepsArgs = {
  condition?: InputMaybe<ModelMonitorStepsConditionInput>;
  input: CreateMonitorStepsInput;
};


export type MutationCreatePoiEntityArgs = {
  condition?: InputMaybe<ModelPoiEntityConditionInput>;
  input: CreatePoiEntityInput;
};


export type MutationCreatePipeItemArgs = {
  condition?: InputMaybe<ModelPipeItemConditionInput>;
  input: CreatePipeItemInput;
};


export type MutationCreatePipelineRunInfoArgs = {
  condition?: InputMaybe<ModelPipelineRunInfoConditionInput>;
  input: CreatePipelineRunInfoInput;
};


export type MutationCreatePipesqaPipeArgs = {
  condition?: InputMaybe<ModelPipesqaPipeConditionInput>;
  input: CreatePipesqaPipeInput;
};


export type MutationCreatePlannedEntitiesArgs = {
  condition?: InputMaybe<ModelPlannedEntitiesConditionInput>;
  input: CreatePlannedEntitiesInput;
};


export type MutationCreatePlannedPolygonArgs = {
  condition?: InputMaybe<ModelPlannedPolygonConditionInput>;
  input: CreatePlannedPolygonInput;
};


export type MutationCreatePlannedTransmitterArgs = {
  condition?: InputMaybe<ModelPlannedTransmitterConditionInput>;
  input: CreatePlannedTransmitterInput;
};


export type MutationCreatePreloadRunInfoArgs = {
  condition?: InputMaybe<ModelPreloadRunInfoConditionInput>;
  input: CreatePreloadRunInfoInput;
};


export type MutationCreatePreloaderStepArgs = {
  condition?: InputMaybe<ModelPreloaderStepConditionInput>;
  input: CreatePreloaderStepInput;
};


export type MutationCreateReanalysisRequestArgs = {
  condition?: InputMaybe<ModelReanalysisRequestConditionInput>;
  input: CreateReanalysisRequestInput;
};


export type MutationCreateReanalysisRequestCommentArgs = {
  condition?: InputMaybe<ModelReanalysisRequestCommentConditionInput>;
  input: CreateReanalysisRequestCommentInput;
};


export type MutationCreateScanArgs = {
  condition?: InputMaybe<ModelScanConditionInput>;
  input: CreateScanInput;
};


export type MutationCreateScanAccessArgs = {
  condition?: InputMaybe<ModelScanAccessConditionInput>;
  input: CreateScanAccessInput;
};


export type MutationCreateScanByHashedParametersArgs = {
  condition?: InputMaybe<ModelScanByHashedParametersConditionInput>;
  input: CreateScanByHashedParametersInput;
};


export type MutationCreateScanDepthPointsArgs = {
  condition?: InputMaybe<ModelScanDepthPointsConditionInput>;
  input: CreateScanDepthPointsInput;
};


export type MutationCreateScanStepArgs = {
  condition?: InputMaybe<ModelScanStepConditionInput>;
  input: CreateScanStepInput;
};


export type MutationCreateSensorArgs = {
  condition?: InputMaybe<ModelSensorConditionInput>;
  input: CreateSensorInput;
};


export type MutationCreateSiteArgs = {
  condition?: InputMaybe<ModelSiteConditionInput>;
  input: CreateSiteInput;
};


export type MutationCreateTargetEntityArgs = {
  condition?: InputMaybe<ModelTargetEntityConditionInput>;
  input: CreateTargetEntityInput;
};


export type MutationCreateTasksScansConnectionArgs = {
  condition?: InputMaybe<ModelTasksScansConnectionConditionInput>;
  input: CreateTasksScansConnectionInput;
};


export type MutationCreateTransmitterArgs = {
  condition?: InputMaybe<ModelTransmitterConditionInput>;
  input: CreateTransmitterInput;
};


export type MutationCreateTransmitterSetupArgs = {
  condition?: InputMaybe<ModelTransmitterSetupConditionInput>;
  input: CreateTransmitterSetupInput;
};


export type MutationCreateTravelCourseArgs = {
  condition?: InputMaybe<ModelTravelCourseConditionInput>;
  input: CreateTravelCourseInput;
};


export type MutationCreateUnifyTaskArgs = {
  condition?: InputMaybe<ModelUnifyTaskConditionInput>;
  input: CreateUnifyTaskInput;
};


export type MutationCreateUploadStepArgs = {
  condition?: InputMaybe<ModelUploadStepConditionInput>;
  input: CreateUploadStepInput;
};


export type MutationCreateUtilityFinderStepArgs = {
  condition?: InputMaybe<ModelUtilityFinderStepConditionInput>;
  input: CreateUtilityFinderStepInput;
};


export type MutationCreateV2TargetEntityArgs = {
  condition?: InputMaybe<ModelV2TargetEntityConditionInput>;
  input: CreateV2TargetEntityInput;
};


export type MutationDeleteAlgoUtilityMetricsArgs = {
  condition?: InputMaybe<ModelAlgoUtilityMetricsConditionInput>;
  input: DeleteAlgoUtilityMetricsInput;
};


export type MutationDeleteAnalysisStepArgs = {
  condition?: InputMaybe<ModelAnalysisStepConditionInput>;
  input: DeleteAnalysisStepInput;
};


export type MutationDeleteAnalyzedEntityArgs = {
  condition?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  input: DeleteAnalyzedEntityInput;
};


export type MutationDeleteCommentArgs = {
  condition?: InputMaybe<ModelCommentConditionInput>;
  input: DeleteCommentInput;
};


export type MutationDeleteCustomerArgs = {
  condition?: InputMaybe<ModelCustomerConditionInput>;
  input: DeleteCustomerInput;
};


export type MutationDeleteEntityDepthPointsArgs = {
  condition?: InputMaybe<ModelEntityDepthPointsConditionInput>;
  input: DeleteEntityDepthPointsInput;
};


export type MutationDeleteGeoidValueArgs = {
  condition?: InputMaybe<ModelGeoidValueConditionInput>;
  input: DeleteGeoidValueInput;
};


export type MutationDeleteLocationArgs = {
  condition?: InputMaybe<ModelLocationConditionInput>;
  input: DeleteLocationInput;
};


export type MutationDeleteManholeItemArgs = {
  condition?: InputMaybe<ModelManholeItemConditionInput>;
  input: DeleteManholeItemInput;
};


export type MutationDeleteMappingEntityArgs = {
  condition?: InputMaybe<ModelMappingEntityConditionInput>;
  input: DeleteMappingEntityInput;
};


export type MutationDeleteMonitorStepsArgs = {
  condition?: InputMaybe<ModelMonitorStepsConditionInput>;
  input: DeleteMonitorStepsInput;
};


export type MutationDeletePoiEntityArgs = {
  condition?: InputMaybe<ModelPoiEntityConditionInput>;
  input: DeletePoiEntityInput;
};


export type MutationDeletePipeItemArgs = {
  condition?: InputMaybe<ModelPipeItemConditionInput>;
  input: DeletePipeItemInput;
};


export type MutationDeletePipelineRunInfoArgs = {
  condition?: InputMaybe<ModelPipelineRunInfoConditionInput>;
  input: DeletePipelineRunInfoInput;
};


export type MutationDeletePipesqaPipeArgs = {
  condition?: InputMaybe<ModelPipesqaPipeConditionInput>;
  input: DeletePipesqaPipeInput;
};


export type MutationDeletePlannedEntitiesArgs = {
  condition?: InputMaybe<ModelPlannedEntitiesConditionInput>;
  input: DeletePlannedEntitiesInput;
};


export type MutationDeletePlannedPolygonArgs = {
  condition?: InputMaybe<ModelPlannedPolygonConditionInput>;
  input: DeletePlannedPolygonInput;
};


export type MutationDeletePlannedTransmitterArgs = {
  condition?: InputMaybe<ModelPlannedTransmitterConditionInput>;
  input: DeletePlannedTransmitterInput;
};


export type MutationDeletePreloadRunInfoArgs = {
  condition?: InputMaybe<ModelPreloadRunInfoConditionInput>;
  input: DeletePreloadRunInfoInput;
};


export type MutationDeletePreloaderStepArgs = {
  condition?: InputMaybe<ModelPreloaderStepConditionInput>;
  input: DeletePreloaderStepInput;
};


export type MutationDeleteReanalysisRequestArgs = {
  condition?: InputMaybe<ModelReanalysisRequestConditionInput>;
  input: DeleteReanalysisRequestInput;
};


export type MutationDeleteReanalysisRequestCommentArgs = {
  condition?: InputMaybe<ModelReanalysisRequestCommentConditionInput>;
  input: DeleteReanalysisRequestCommentInput;
};


export type MutationDeleteScanArgs = {
  condition?: InputMaybe<ModelScanConditionInput>;
  input: DeleteScanInput;
};


export type MutationDeleteScanAccessArgs = {
  condition?: InputMaybe<ModelScanAccessConditionInput>;
  input: DeleteScanAccessInput;
};


export type MutationDeleteScanByHashedParametersArgs = {
  condition?: InputMaybe<ModelScanByHashedParametersConditionInput>;
  input: DeleteScanByHashedParametersInput;
};


export type MutationDeleteScanDepthPointsArgs = {
  condition?: InputMaybe<ModelScanDepthPointsConditionInput>;
  input: DeleteScanDepthPointsInput;
};


export type MutationDeleteScanStepArgs = {
  condition?: InputMaybe<ModelScanStepConditionInput>;
  input: DeleteScanStepInput;
};


export type MutationDeleteSensorArgs = {
  condition?: InputMaybe<ModelSensorConditionInput>;
  input: DeleteSensorInput;
};


export type MutationDeleteSiteArgs = {
  condition?: InputMaybe<ModelSiteConditionInput>;
  input: DeleteSiteInput;
};


export type MutationDeleteTargetEntityArgs = {
  condition?: InputMaybe<ModelTargetEntityConditionInput>;
  input: DeleteTargetEntityInput;
};


export type MutationDeleteTasksScansConnectionArgs = {
  condition?: InputMaybe<ModelTasksScansConnectionConditionInput>;
  input: DeleteTasksScansConnectionInput;
};


export type MutationDeleteTransmitterArgs = {
  condition?: InputMaybe<ModelTransmitterConditionInput>;
  input: DeleteTransmitterInput;
};


export type MutationDeleteTransmitterSetupArgs = {
  condition?: InputMaybe<ModelTransmitterSetupConditionInput>;
  input: DeleteTransmitterSetupInput;
};


export type MutationDeleteTravelCourseArgs = {
  condition?: InputMaybe<ModelTravelCourseConditionInput>;
  input: DeleteTravelCourseInput;
};


export type MutationDeleteUnifyTaskArgs = {
  condition?: InputMaybe<ModelUnifyTaskConditionInput>;
  input: DeleteUnifyTaskInput;
};


export type MutationDeleteUploadStepArgs = {
  condition?: InputMaybe<ModelUploadStepConditionInput>;
  input: DeleteUploadStepInput;
};


export type MutationDeleteUtilityFinderStepArgs = {
  condition?: InputMaybe<ModelUtilityFinderStepConditionInput>;
  input: DeleteUtilityFinderStepInput;
};


export type MutationDeleteV2TargetEntityArgs = {
  condition?: InputMaybe<ModelV2TargetEntityConditionInput>;
  input: DeleteV2TargetEntityInput;
};


export type MutationUpdateAlgoUtilityMetricsArgs = {
  condition?: InputMaybe<ModelAlgoUtilityMetricsConditionInput>;
  input: UpdateAlgoUtilityMetricsInput;
};


export type MutationUpdateAnalysisStepArgs = {
  condition?: InputMaybe<ModelAnalysisStepConditionInput>;
  input: UpdateAnalysisStepInput;
};


export type MutationUpdateAnalyzedEntityArgs = {
  condition?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  input: UpdateAnalyzedEntityInput;
};


export type MutationUpdateCommentArgs = {
  condition?: InputMaybe<ModelCommentConditionInput>;
  input: UpdateCommentInput;
};


export type MutationUpdateCustomerArgs = {
  condition?: InputMaybe<ModelCustomerConditionInput>;
  input: UpdateCustomerInput;
};


export type MutationUpdateEntityDepthPointsArgs = {
  condition?: InputMaybe<ModelEntityDepthPointsConditionInput>;
  input: UpdateEntityDepthPointsInput;
};


export type MutationUpdateGeoidValueArgs = {
  condition?: InputMaybe<ModelGeoidValueConditionInput>;
  input: UpdateGeoidValueInput;
};


export type MutationUpdateLocationArgs = {
  condition?: InputMaybe<ModelLocationConditionInput>;
  input: UpdateLocationInput;
};


export type MutationUpdateManholeItemArgs = {
  condition?: InputMaybe<ModelManholeItemConditionInput>;
  input: UpdateManholeItemInput;
};


export type MutationUpdateMappingEntityArgs = {
  condition?: InputMaybe<ModelMappingEntityConditionInput>;
  input: UpdateMappingEntityInput;
};


export type MutationUpdateMonitorStepsArgs = {
  condition?: InputMaybe<ModelMonitorStepsConditionInput>;
  input: UpdateMonitorStepsInput;
};


export type MutationUpdatePoiEntityArgs = {
  condition?: InputMaybe<ModelPoiEntityConditionInput>;
  input: UpdatePoiEntityInput;
};


export type MutationUpdatePipeItemArgs = {
  condition?: InputMaybe<ModelPipeItemConditionInput>;
  input: UpdatePipeItemInput;
};


export type MutationUpdatePipelineRunInfoArgs = {
  condition?: InputMaybe<ModelPipelineRunInfoConditionInput>;
  input: UpdatePipelineRunInfoInput;
};


export type MutationUpdatePipesqaPipeArgs = {
  condition?: InputMaybe<ModelPipesqaPipeConditionInput>;
  input: UpdatePipesqaPipeInput;
};


export type MutationUpdatePlannedEntitiesArgs = {
  condition?: InputMaybe<ModelPlannedEntitiesConditionInput>;
  input: UpdatePlannedEntitiesInput;
};


export type MutationUpdatePlannedPolygonArgs = {
  condition?: InputMaybe<ModelPlannedPolygonConditionInput>;
  input: UpdatePlannedPolygonInput;
};


export type MutationUpdatePlannedTransmitterArgs = {
  condition?: InputMaybe<ModelPlannedTransmitterConditionInput>;
  input: UpdatePlannedTransmitterInput;
};


export type MutationUpdatePreloadRunInfoArgs = {
  condition?: InputMaybe<ModelPreloadRunInfoConditionInput>;
  input: UpdatePreloadRunInfoInput;
};


export type MutationUpdatePreloaderStepArgs = {
  condition?: InputMaybe<ModelPreloaderStepConditionInput>;
  input: UpdatePreloaderStepInput;
};


export type MutationUpdateReanalysisRequestArgs = {
  condition?: InputMaybe<ModelReanalysisRequestConditionInput>;
  input: UpdateReanalysisRequestInput;
};


export type MutationUpdateReanalysisRequestCommentArgs = {
  condition?: InputMaybe<ModelReanalysisRequestCommentConditionInput>;
  input: UpdateReanalysisRequestCommentInput;
};


export type MutationUpdateScanArgs = {
  condition?: InputMaybe<ModelScanConditionInput>;
  input: UpdateScanInput;
};


export type MutationUpdateScanAccessArgs = {
  condition?: InputMaybe<ModelScanAccessConditionInput>;
  input: UpdateScanAccessInput;
};


export type MutationUpdateScanByHashedParametersArgs = {
  condition?: InputMaybe<ModelScanByHashedParametersConditionInput>;
  input: UpdateScanByHashedParametersInput;
};


export type MutationUpdateScanDepthPointsArgs = {
  condition?: InputMaybe<ModelScanDepthPointsConditionInput>;
  input: UpdateScanDepthPointsInput;
};


export type MutationUpdateScanStepArgs = {
  condition?: InputMaybe<ModelScanStepConditionInput>;
  input: UpdateScanStepInput;
};


export type MutationUpdateSensorArgs = {
  condition?: InputMaybe<ModelSensorConditionInput>;
  input: UpdateSensorInput;
};


export type MutationUpdateSiteArgs = {
  condition?: InputMaybe<ModelSiteConditionInput>;
  input: UpdateSiteInput;
};


export type MutationUpdateTargetEntityArgs = {
  condition?: InputMaybe<ModelTargetEntityConditionInput>;
  input: UpdateTargetEntityInput;
};


export type MutationUpdateTasksScansConnectionArgs = {
  condition?: InputMaybe<ModelTasksScansConnectionConditionInput>;
  input: UpdateTasksScansConnectionInput;
};


export type MutationUpdateTransmitterArgs = {
  condition?: InputMaybe<ModelTransmitterConditionInput>;
  input: UpdateTransmitterInput;
};


export type MutationUpdateTransmitterSetupArgs = {
  condition?: InputMaybe<ModelTransmitterSetupConditionInput>;
  input: UpdateTransmitterSetupInput;
};


export type MutationUpdateTravelCourseArgs = {
  condition?: InputMaybe<ModelTravelCourseConditionInput>;
  input: UpdateTravelCourseInput;
};


export type MutationUpdateUnifyTaskArgs = {
  condition?: InputMaybe<ModelUnifyTaskConditionInput>;
  input: UpdateUnifyTaskInput;
};


export type MutationUpdateUploadStepArgs = {
  condition?: InputMaybe<ModelUploadStepConditionInput>;
  input: UpdateUploadStepInput;
};


export type MutationUpdateUtilityFinderStepArgs = {
  condition?: InputMaybe<ModelUtilityFinderStepConditionInput>;
  input: UpdateUtilityFinderStepInput;
};


export type MutationUpdateV2TargetEntityArgs = {
  condition?: InputMaybe<ModelV2TargetEntityConditionInput>;
  input: UpdateV2TargetEntityInput;
};

export type PoiEntity = {
  __typename?: 'POIEntity';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  data?: Maybe<ManholeItem>;
  geo_data: Scalars['AWSJSON'];
  id: Scalars['ID'];
  pOIEntityDataId?: Maybe<Scalars['ID']>;
  poiType?: Maybe<Scalars['String']>;
  scan?: Maybe<Scan>;
  scanPoisId?: Maybe<Scalars['ID']>;
  site: Site;
  sitePoisId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type PipeItem = {
  __typename?: 'PipeItem';
  azimuth?: Maybe<Scalars['Float']>;
  createdAt: Scalars['AWSDateTime'];
  description?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['Float']>;
  flowDirection?: Maybe<Scalars['String']>;
  hive?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invertLevel?: Maybe<Scalars['Float']>;
  manhole: ManholeItem;
  manholeItemPipesId: Scalars['ID'];
  media?: Maybe<Scalars['String']>;
  noCables?: Maybe<Scalars['String']>;
  secondaryOwner?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['Float']>;
  topLevel?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type PipelineRunInfo = {
  __typename?: 'PipelineRunInfo';
  createdAt: Scalars['AWSDateTime'];
  end_time?: Maybe<Scalars['AWSTimestamp']>;
  end_time_iso?: Maybe<Scalars['AWSDateTime']>;
  epls_s3_ref: Scalars['String'];
  error_message?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  start_time?: Maybe<Scalars['AWSTimestamp']>;
  start_time_iso?: Maybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
  step?: Maybe<UtilityFinderStep>;
  updatedAt: Scalars['AWSDateTime'];
  utilityFinderStepPipelinesId?: Maybe<Scalars['ID']>;
};

export type PipesqaPipe = {
  __typename?: 'PipesqaPipe';
  analyzed_entity?: Maybe<AnalyzedEntity>;
  comments?: Maybe<ModelCommentConnection>;
  createdAt: Scalars['AWSDateTime'];
  crossings_statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  flagged?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  pipesqaPipeAnalyzed_entityId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
};


export type PipesqaPipeCommentsArgs = {
  filter?: InputMaybe<ModelCommentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type PlannedEntities = {
  __typename?: 'PlannedEntities';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  plannedEntitiesSiteId: Scalars['ID'];
  planned_polygons?: Maybe<ModelPlannedPolygonConnection>;
  s3_ref?: Maybe<Scalars['String']>;
  site: Site;
  updatedAt: Scalars['AWSDateTime'];
};


export type PlannedEntitiesPlanned_PolygonsArgs = {
  filter?: InputMaybe<ModelPlannedPolygonFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type PlannedPolygon = {
  __typename?: 'PlannedPolygon';
  color?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  geo_data: Scalars['AWSJSON'];
  id: Scalars['ID'];
  is_rescan?: Maybe<Scalars['Boolean']>;
  mount_method?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  override_color?: Maybe<Scalars['Boolean']>;
  plannedEntitiesPlanned_polygonsId?: Maybe<Scalars['ID']>;
  planned_transmitters?: Maybe<ModelPlannedTransmitterConnection>;
  sensor_models?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt: Scalars['AWSDateTime'];
};


export type PlannedPolygonPlanned_TransmittersArgs = {
  filter?: InputMaybe<ModelPlannedTransmitterFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type PlannedTransmitter = {
  __typename?: 'PlannedTransmitter';
  color?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  geo_data: Scalars['AWSJSON'];
  id: Scalars['ID'];
  method?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  override_color?: Maybe<Scalars['Boolean']>;
  plannedPolygonPlanned_transmittersId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type PreloadRunInfo = {
  __typename?: 'PreloadRunInfo';
  createdAt: Scalars['AWSDateTime'];
  end_time?: Maybe<Scalars['AWSTimestamp']>;
  end_time_iso?: Maybe<Scalars['AWSDateTime']>;
  epls_s3_ref?: Maybe<Scalars['String']>;
  error_message?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  preloaded_scan_s3_refs?: Maybe<Array<Scalars['String']>>;
  preloaderStepPreload_runsId?: Maybe<Scalars['ID']>;
  products?: Maybe<Array<Scalars['String']>>;
  start_time?: Maybe<Scalars['AWSTimestamp']>;
  start_time_iso?: Maybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
  step?: Maybe<PreloaderStep>;
  target_name: Scalars['String'];
  target_type: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type PreloaderStep = {
  __typename?: 'PreloaderStep';
  createdAt: Scalars['AWSDateTime'];
  end_time?: Maybe<Scalars['AWSTimestamp']>;
  end_time_iso?: Maybe<Scalars['AWSDateTime']>;
  error_message?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  monitor_steps?: Maybe<MonitorSteps>;
  preload_runs?: Maybe<ModelPreloadRunInfoConnection>;
  preloaderStepMonitor_stepsId?: Maybe<Scalars['ID']>;
  start_time?: Maybe<Scalars['AWSTimestamp']>;
  start_time_iso?: Maybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};


export type PreloaderStepPreload_RunsArgs = {
  filter?: InputMaybe<ModelPreloadRunInfoFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type Query = {
  __typename?: 'Query';
  getAlgoUtilityMetrics?: Maybe<AlgoUtilityMetrics>;
  getAnalysisStep?: Maybe<AnalysisStep>;
  getAnalyzedEntity?: Maybe<AnalyzedEntity>;
  getComment?: Maybe<Comment>;
  getCustomer?: Maybe<Customer>;
  getEntityDepthPoints?: Maybe<EntityDepthPoints>;
  getGeoidValue?: Maybe<GeoidValue>;
  getLocation?: Maybe<Location>;
  getManholeItem?: Maybe<ManholeItem>;
  getMappingEntity?: Maybe<MappingEntity>;
  getMonitorSteps?: Maybe<MonitorSteps>;
  getPOIEntity?: Maybe<PoiEntity>;
  getPipeItem?: Maybe<PipeItem>;
  getPipelineRunInfo?: Maybe<PipelineRunInfo>;
  getPipesqaPipe?: Maybe<PipesqaPipe>;
  getPlannedEntities?: Maybe<PlannedEntities>;
  getPlannedPolygon?: Maybe<PlannedPolygon>;
  getPlannedTransmitter?: Maybe<PlannedTransmitter>;
  getPreloadRunInfo?: Maybe<PreloadRunInfo>;
  getPreloaderStep?: Maybe<PreloaderStep>;
  getReanalysisRequest?: Maybe<ReanalysisRequest>;
  getReanalysisRequestComment?: Maybe<ReanalysisRequestComment>;
  getScan?: Maybe<Scan>;
  getScanAccess?: Maybe<ScanAccess>;
  getScanByHashedParameters?: Maybe<ScanByHashedParameters>;
  getScanDepthPoints?: Maybe<ScanDepthPoints>;
  getScanStep?: Maybe<ScanStep>;
  getSensor?: Maybe<Sensor>;
  getSite?: Maybe<Site>;
  getTargetEntity?: Maybe<TargetEntity>;
  getTasksScansConnection?: Maybe<TasksScansConnection>;
  getTransmitter?: Maybe<Transmitter>;
  getTransmitterSetup?: Maybe<TransmitterSetup>;
  getTravelCourse?: Maybe<TravelCourse>;
  getUnifyTask?: Maybe<UnifyTask>;
  getUploadStep?: Maybe<UploadStep>;
  getUtilityFinderStep?: Maybe<UtilityFinderStep>;
  getV2TargetEntity?: Maybe<V2TargetEntity>;
  listAlgoUtilityMetrics?: Maybe<ModelAlgoUtilityMetricsConnection>;
  listAnalysisSteps?: Maybe<ModelAnalysisStepConnection>;
  listAnalyzedEntities?: Maybe<ModelAnalyzedEntityConnection>;
  listComments?: Maybe<ModelCommentConnection>;
  listCustomers?: Maybe<ModelCustomerConnection>;
  listEntityDepthPoints?: Maybe<ModelEntityDepthPointsConnection>;
  listGeoidValues?: Maybe<ModelGeoidValueConnection>;
  listLocations?: Maybe<ModelLocationConnection>;
  listManholeItems?: Maybe<ModelManholeItemConnection>;
  listMappingEntities?: Maybe<ModelMappingEntityConnection>;
  listMonitorSteps?: Maybe<ModelMonitorStepsConnection>;
  listPOIEntities?: Maybe<ModelPoiEntityConnection>;
  listPipeItems?: Maybe<ModelPipeItemConnection>;
  listPipelineRunInfos?: Maybe<ModelPipelineRunInfoConnection>;
  listPipesqaPipes?: Maybe<ModelPipesqaPipeConnection>;
  listPlannedEntities?: Maybe<ModelPlannedEntitiesConnection>;
  listPlannedPolygons?: Maybe<ModelPlannedPolygonConnection>;
  listPlannedTransmitters?: Maybe<ModelPlannedTransmitterConnection>;
  listPreloadRunInfos?: Maybe<ModelPreloadRunInfoConnection>;
  listPreloaderSteps?: Maybe<ModelPreloaderStepConnection>;
  listReanalysisRequestComments?: Maybe<ModelReanalysisRequestCommentConnection>;
  listReanalysisRequests?: Maybe<ModelReanalysisRequestConnection>;
  listScanAccesses?: Maybe<ModelScanAccessConnection>;
  listScanByHashedParameters?: Maybe<ModelScanByHashedParametersConnection>;
  listScanDepthPoints?: Maybe<ModelScanDepthPointsConnection>;
  listScanSteps?: Maybe<ModelScanStepConnection>;
  listScans?: Maybe<ModelScanConnection>;
  listSensors?: Maybe<ModelSensorConnection>;
  listSites?: Maybe<ModelSiteConnection>;
  listTargetEntities?: Maybe<ModelTargetEntityConnection>;
  listTasksScansConnections?: Maybe<ModelTasksScansConnectionConnection>;
  listTransmitterSetups?: Maybe<ModelTransmitterSetupConnection>;
  listTransmitters?: Maybe<ModelTransmitterConnection>;
  listTravelCourses?: Maybe<ModelTravelCourseConnection>;
  listUnifyTasks?: Maybe<ModelUnifyTaskConnection>;
  listUploadSteps?: Maybe<ModelUploadStepConnection>;
  listUtilityFinderSteps?: Maybe<ModelUtilityFinderStepConnection>;
  listV2TargetEntities?: Maybe<ModelV2TargetEntityConnection>;
  searchAlgoUtilityMetrics?: Maybe<SearchableAlgoUtilityMetricsConnection>;
  searchAnalysisSteps?: Maybe<SearchableAnalysisStepConnection>;
  searchAnalyzedEntities?: Maybe<SearchableAnalyzedEntityConnection>;
  searchComments?: Maybe<SearchableCommentConnection>;
  searchCustomers?: Maybe<SearchableCustomerConnection>;
  searchEntityDepthPoints?: Maybe<SearchableEntityDepthPointsConnection>;
  searchGeoidValues?: Maybe<SearchableGeoidValueConnection>;
  searchLocations?: Maybe<SearchableLocationConnection>;
  searchManholeItems?: Maybe<SearchableManholeItemConnection>;
  searchMappingEntities?: Maybe<SearchableMappingEntityConnection>;
  searchMonitorSteps?: Maybe<SearchableMonitorStepsConnection>;
  searchPOIEntities?: Maybe<SearchablePoiEntityConnection>;
  searchPipeItems?: Maybe<SearchablePipeItemConnection>;
  searchPipelineRunInfos?: Maybe<SearchablePipelineRunInfoConnection>;
  searchPipesqaPipes?: Maybe<SearchablePipesqaPipeConnection>;
  searchPlannedEntities?: Maybe<SearchablePlannedEntitiesConnection>;
  searchPlannedPolygons?: Maybe<SearchablePlannedPolygonConnection>;
  searchPlannedTransmitters?: Maybe<SearchablePlannedTransmitterConnection>;
  searchPreloadRunInfos?: Maybe<SearchablePreloadRunInfoConnection>;
  searchPreloaderSteps?: Maybe<SearchablePreloaderStepConnection>;
  searchReanalysisRequestComments?: Maybe<SearchableReanalysisRequestCommentConnection>;
  searchReanalysisRequests?: Maybe<SearchableReanalysisRequestConnection>;
  searchScanAccesses?: Maybe<SearchableScanAccessConnection>;
  searchScanByHashedParameters?: Maybe<SearchableScanByHashedParametersConnection>;
  searchScanDepthPoints?: Maybe<SearchableScanDepthPointsConnection>;
  searchScanSteps?: Maybe<SearchableScanStepConnection>;
  searchScans?: Maybe<SearchableScanConnection>;
  searchSensors?: Maybe<SearchableSensorConnection>;
  searchSites?: Maybe<SearchableSiteConnection>;
  searchTargetEntities?: Maybe<SearchableTargetEntityConnection>;
  searchTransmitterSetups?: Maybe<SearchableTransmitterSetupConnection>;
  searchTransmitters?: Maybe<SearchableTransmitterConnection>;
  searchTravelCourses?: Maybe<SearchableTravelCourseConnection>;
  searchUnifyTasks?: Maybe<SearchableUnifyTaskConnection>;
  searchUploadSteps?: Maybe<SearchableUploadStepConnection>;
  searchUtilityFinderSteps?: Maybe<SearchableUtilityFinderStepConnection>;
  searchV2TargetEntities?: Maybe<SearchableV2TargetEntityConnection>;
  tasksScansConnectionsByScanId?: Maybe<ModelTasksScansConnectionConnection>;
  tasksScansConnectionsByUnifyTaskId?: Maybe<ModelTasksScansConnectionConnection>;
};


export type QueryGetAlgoUtilityMetricsArgs = {
  id: Scalars['ID'];
};


export type QueryGetAnalysisStepArgs = {
  id: Scalars['ID'];
};


export type QueryGetAnalyzedEntityArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommentArgs = {
  id: Scalars['ID'];
};


export type QueryGetCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryGetEntityDepthPointsArgs = {
  id: Scalars['ID'];
};


export type QueryGetGeoidValueArgs = {
  id: Scalars['ID'];
};


export type QueryGetLocationArgs = {
  id: Scalars['ID'];
};


export type QueryGetManholeItemArgs = {
  id: Scalars['ID'];
};


export type QueryGetMappingEntityArgs = {
  id: Scalars['ID'];
};


export type QueryGetMonitorStepsArgs = {
  id: Scalars['ID'];
};


export type QueryGetPoiEntityArgs = {
  id: Scalars['ID'];
};


export type QueryGetPipeItemArgs = {
  id: Scalars['ID'];
};


export type QueryGetPipelineRunInfoArgs = {
  id: Scalars['ID'];
};


export type QueryGetPipesqaPipeArgs = {
  id: Scalars['ID'];
};


export type QueryGetPlannedEntitiesArgs = {
  id: Scalars['ID'];
};


export type QueryGetPlannedPolygonArgs = {
  id: Scalars['ID'];
};


export type QueryGetPlannedTransmitterArgs = {
  id: Scalars['ID'];
};


export type QueryGetPreloadRunInfoArgs = {
  id: Scalars['ID'];
};


export type QueryGetPreloaderStepArgs = {
  id: Scalars['ID'];
};


export type QueryGetReanalysisRequestArgs = {
  id: Scalars['ID'];
};


export type QueryGetReanalysisRequestCommentArgs = {
  id: Scalars['ID'];
};


export type QueryGetScanArgs = {
  id: Scalars['ID'];
};


export type QueryGetScanAccessArgs = {
  id: Scalars['ID'];
};


export type QueryGetScanByHashedParametersArgs = {
  hash: Scalars['ID'];
};


export type QueryGetScanDepthPointsArgs = {
  id: Scalars['ID'];
};


export type QueryGetScanStepArgs = {
  id: Scalars['ID'];
};


export type QueryGetSensorArgs = {
  id: Scalars['ID'];
};


export type QueryGetSiteArgs = {
  id: Scalars['ID'];
};


export type QueryGetTargetEntityArgs = {
  id: Scalars['ID'];
};


export type QueryGetTasksScansConnectionArgs = {
  id: Scalars['ID'];
};


export type QueryGetTransmitterArgs = {
  id: Scalars['ID'];
};


export type QueryGetTransmitterSetupArgs = {
  id: Scalars['ID'];
};


export type QueryGetTravelCourseArgs = {
  id: Scalars['ID'];
};


export type QueryGetUnifyTaskArgs = {
  id: Scalars['ID'];
};


export type QueryGetUploadStepArgs = {
  id: Scalars['ID'];
};


export type QueryGetUtilityFinderStepArgs = {
  id: Scalars['ID'];
};


export type QueryGetV2TargetEntityArgs = {
  id: Scalars['ID'];
};


export type QueryListAlgoUtilityMetricsArgs = {
  filter?: InputMaybe<ModelAlgoUtilityMetricsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListAnalysisStepsArgs = {
  filter?: InputMaybe<ModelAnalysisStepFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListAnalyzedEntitiesArgs = {
  filter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListCommentsArgs = {
  filter?: InputMaybe<ModelCommentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListCustomersArgs = {
  filter?: InputMaybe<ModelCustomerFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListEntityDepthPointsArgs = {
  filter?: InputMaybe<ModelEntityDepthPointsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListGeoidValuesArgs = {
  filter?: InputMaybe<ModelGeoidValueFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListLocationsArgs = {
  filter?: InputMaybe<ModelLocationFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListManholeItemsArgs = {
  filter?: InputMaybe<ModelManholeItemFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListMappingEntitiesArgs = {
  filter?: InputMaybe<ModelMappingEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListMonitorStepsArgs = {
  filter?: InputMaybe<ModelMonitorStepsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListPoiEntitiesArgs = {
  filter?: InputMaybe<ModelPoiEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListPipeItemsArgs = {
  filter?: InputMaybe<ModelPipeItemFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListPipelineRunInfosArgs = {
  filter?: InputMaybe<ModelPipelineRunInfoFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListPipesqaPipesArgs = {
  filter?: InputMaybe<ModelPipesqaPipeFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListPlannedEntitiesArgs = {
  filter?: InputMaybe<ModelPlannedEntitiesFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListPlannedPolygonsArgs = {
  filter?: InputMaybe<ModelPlannedPolygonFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListPlannedTransmittersArgs = {
  filter?: InputMaybe<ModelPlannedTransmitterFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListPreloadRunInfosArgs = {
  filter?: InputMaybe<ModelPreloadRunInfoFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListPreloaderStepsArgs = {
  filter?: InputMaybe<ModelPreloaderStepFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListReanalysisRequestCommentsArgs = {
  filter?: InputMaybe<ModelReanalysisRequestCommentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListReanalysisRequestsArgs = {
  filter?: InputMaybe<ModelReanalysisRequestFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListScanAccessesArgs = {
  filter?: InputMaybe<ModelScanAccessFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListScanByHashedParametersArgs = {
  filter?: InputMaybe<ModelScanByHashedParametersFilterInput>;
  hash?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryListScanDepthPointsArgs = {
  filter?: InputMaybe<ModelScanDepthPointsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListScanStepsArgs = {
  filter?: InputMaybe<ModelScanStepFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListScansArgs = {
  filter?: InputMaybe<ModelScanFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListSensorsArgs = {
  filter?: InputMaybe<ModelSensorFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListSitesArgs = {
  filter?: InputMaybe<ModelSiteFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListTargetEntitiesArgs = {
  filter?: InputMaybe<ModelTargetEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListTasksScansConnectionsArgs = {
  filter?: InputMaybe<ModelTasksScansConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListTransmitterSetupsArgs = {
  filter?: InputMaybe<ModelTransmitterSetupFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListTransmittersArgs = {
  filter?: InputMaybe<ModelTransmitterFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListTravelCoursesArgs = {
  filter?: InputMaybe<ModelTravelCourseFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListUnifyTasksArgs = {
  filter?: InputMaybe<ModelUnifyTaskFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListUploadStepsArgs = {
  filter?: InputMaybe<ModelUploadStepFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListUtilityFinderStepsArgs = {
  filter?: InputMaybe<ModelUtilityFinderStepFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListV2TargetEntitiesArgs = {
  filter?: InputMaybe<ModelV2TargetEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QuerySearchAlgoUtilityMetricsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableAlgoUtilityMetricsAggregationInput>>>;
  filter?: InputMaybe<SearchableAlgoUtilityMetricsFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableAlgoUtilityMetricsSortInput>>>;
};


export type QuerySearchAnalysisStepsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableAnalysisStepAggregationInput>>>;
  filter?: InputMaybe<SearchableAnalysisStepFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableAnalysisStepSortInput>>>;
};


export type QuerySearchAnalyzedEntitiesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntityAggregationInput>>>;
  filter?: InputMaybe<SearchableAnalyzedEntityFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntitySortInput>>>;
};


export type QuerySearchCommentsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableCommentAggregationInput>>>;
  filter?: InputMaybe<SearchableCommentFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableCommentSortInput>>>;
};


export type QuerySearchCustomersArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableCustomerAggregationInput>>>;
  filter?: InputMaybe<SearchableCustomerFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableCustomerSortInput>>>;
};


export type QuerySearchEntityDepthPointsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableEntityDepthPointsAggregationInput>>>;
  filter?: InputMaybe<SearchableEntityDepthPointsFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableEntityDepthPointsSortInput>>>;
};


export type QuerySearchGeoidValuesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableGeoidValueAggregationInput>>>;
  filter?: InputMaybe<SearchableGeoidValueFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableGeoidValueSortInput>>>;
};


export type QuerySearchLocationsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableLocationAggregationInput>>>;
  filter?: InputMaybe<SearchableLocationFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableLocationSortInput>>>;
};


export type QuerySearchManholeItemsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableManholeItemAggregationInput>>>;
  filter?: InputMaybe<SearchableManholeItemFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableManholeItemSortInput>>>;
};


export type QuerySearchMappingEntitiesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableMappingEntityAggregationInput>>>;
  filter?: InputMaybe<SearchableMappingEntityFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableMappingEntitySortInput>>>;
};


export type QuerySearchMonitorStepsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableMonitorStepsAggregationInput>>>;
  filter?: InputMaybe<SearchableMonitorStepsFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableMonitorStepsSortInput>>>;
};


export type QuerySearchPoiEntitiesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchablePoiEntityAggregationInput>>>;
  filter?: InputMaybe<SearchablePoiEntityFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchablePoiEntitySortInput>>>;
};


export type QuerySearchPipeItemsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchablePipeItemAggregationInput>>>;
  filter?: InputMaybe<SearchablePipeItemFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchablePipeItemSortInput>>>;
};


export type QuerySearchPipelineRunInfosArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchablePipelineRunInfoAggregationInput>>>;
  filter?: InputMaybe<SearchablePipelineRunInfoFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchablePipelineRunInfoSortInput>>>;
};


export type QuerySearchPipesqaPipesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchablePipesqaPipeAggregationInput>>>;
  filter?: InputMaybe<SearchablePipesqaPipeFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchablePipesqaPipeSortInput>>>;
};


export type QuerySearchPlannedEntitiesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchablePlannedEntitiesAggregationInput>>>;
  filter?: InputMaybe<SearchablePlannedEntitiesFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchablePlannedEntitiesSortInput>>>;
};


export type QuerySearchPlannedPolygonsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchablePlannedPolygonAggregationInput>>>;
  filter?: InputMaybe<SearchablePlannedPolygonFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchablePlannedPolygonSortInput>>>;
};


export type QuerySearchPlannedTransmittersArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchablePlannedTransmitterAggregationInput>>>;
  filter?: InputMaybe<SearchablePlannedTransmitterFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchablePlannedTransmitterSortInput>>>;
};


export type QuerySearchPreloadRunInfosArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchablePreloadRunInfoAggregationInput>>>;
  filter?: InputMaybe<SearchablePreloadRunInfoFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchablePreloadRunInfoSortInput>>>;
};


export type QuerySearchPreloaderStepsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchablePreloaderStepAggregationInput>>>;
  filter?: InputMaybe<SearchablePreloaderStepFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchablePreloaderStepSortInput>>>;
};


export type QuerySearchReanalysisRequestCommentsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableReanalysisRequestCommentAggregationInput>>>;
  filter?: InputMaybe<SearchableReanalysisRequestCommentFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableReanalysisRequestCommentSortInput>>>;
};


export type QuerySearchReanalysisRequestsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableReanalysisRequestAggregationInput>>>;
  filter?: InputMaybe<SearchableReanalysisRequestFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableReanalysisRequestSortInput>>>;
};


export type QuerySearchScanAccessesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableScanAccessAggregationInput>>>;
  filter?: InputMaybe<SearchableScanAccessFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableScanAccessSortInput>>>;
};


export type QuerySearchScanByHashedParametersArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableScanByHashedParametersAggregationInput>>>;
  filter?: InputMaybe<SearchableScanByHashedParametersFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableScanByHashedParametersSortInput>>>;
};


export type QuerySearchScanDepthPointsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableScanDepthPointsAggregationInput>>>;
  filter?: InputMaybe<SearchableScanDepthPointsFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableScanDepthPointsSortInput>>>;
};


export type QuerySearchScanStepsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableScanStepAggregationInput>>>;
  filter?: InputMaybe<SearchableScanStepFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableScanStepSortInput>>>;
};


export type QuerySearchScansArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableScanAggregationInput>>>;
  filter?: InputMaybe<SearchableScanFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableScanSortInput>>>;
};


export type QuerySearchSensorsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableSensorAggregationInput>>>;
  filter?: InputMaybe<SearchableSensorFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableSensorSortInput>>>;
};


export type QuerySearchSitesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableSiteAggregationInput>>>;
  filter?: InputMaybe<SearchableSiteFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableSiteSortInput>>>;
};


export type QuerySearchTargetEntitiesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableTargetEntityAggregationInput>>>;
  filter?: InputMaybe<SearchableTargetEntityFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableTargetEntitySortInput>>>;
};


export type QuerySearchTransmitterSetupsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableTransmitterSetupAggregationInput>>>;
  filter?: InputMaybe<SearchableTransmitterSetupFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableTransmitterSetupSortInput>>>;
};


export type QuerySearchTransmittersArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableTransmitterAggregationInput>>>;
  filter?: InputMaybe<SearchableTransmitterFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableTransmitterSortInput>>>;
};


export type QuerySearchTravelCoursesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableTravelCourseAggregationInput>>>;
  filter?: InputMaybe<SearchableTravelCourseFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableTravelCourseSortInput>>>;
};


export type QuerySearchUnifyTasksArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableUnifyTaskAggregationInput>>>;
  filter?: InputMaybe<SearchableUnifyTaskFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableUnifyTaskSortInput>>>;
};


export type QuerySearchUploadStepsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableUploadStepAggregationInput>>>;
  filter?: InputMaybe<SearchableUploadStepFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableUploadStepSortInput>>>;
};


export type QuerySearchUtilityFinderStepsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableUtilityFinderStepAggregationInput>>>;
  filter?: InputMaybe<SearchableUtilityFinderStepFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableUtilityFinderStepSortInput>>>;
};


export type QuerySearchV2TargetEntitiesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableV2TargetEntityAggregationInput>>>;
  filter?: InputMaybe<SearchableV2TargetEntityFilterInput>;
  from?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableV2TargetEntitySortInput>>>;
};


export type QueryTasksScansConnectionsByScanIdArgs = {
  filter?: InputMaybe<ModelTasksScansConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  scanId: Scalars['ID'];
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryTasksScansConnectionsByUnifyTaskIdArgs = {
  filter?: InputMaybe<ModelTasksScansConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  unifyTaskId: Scalars['ID'];
};

export type ReanalysisRequest = {
  __typename?: 'ReanalysisRequest';
  analyzedEntityReanalysis_requestsId?: Maybe<Scalars['ID']>;
  assignee?: Maybe<Scalars['String']>;
  comments?: Maybe<ModelReanalysisRequestCommentConnection>;
  createdAt: Scalars['AWSDateTime'];
  due_date?: Maybe<Scalars['AWSTimestamp']>;
  due_date_iso?: Maybe<Scalars['AWSDateTime']>;
  entity?: Maybe<AnalyzedEntity>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  opened_by?: Maybe<Scalars['String']>;
  site: Site;
  siteReanalysis_requestsId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  transmitter?: Maybe<TransmitterSetup>;
  transmitterSetupReanalysis_requestsId?: Maybe<Scalars['ID']>;
  travelCourseReanalysis_requestsId?: Maybe<Scalars['ID']>;
  travel_course?: Maybe<TravelCourse>;
  updatedAt: Scalars['AWSDateTime'];
};


export type ReanalysisRequestCommentsArgs = {
  filter?: InputMaybe<ModelReanalysisRequestCommentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type ReanalysisRequestComment = {
  __typename?: 'ReanalysisRequestComment';
  comment?: Maybe<Scalars['String']>;
  commenter?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  date?: Maybe<Scalars['AWSTimestamp']>;
  date_iso?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  reanalysisRequestCommentsId?: Maybe<Scalars['ID']>;
  request?: Maybe<ReanalysisRequest>;
  updatedAt: Scalars['AWSDateTime'];
};

export type Scan = {
  __typename?: 'Scan';
  analyzed_entities?: Maybe<ModelAnalyzedEntityConnection>;
  bounding_polygon_s3_ref?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  downsampled_gps?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
  end_time_iso?: Maybe<Scalars['AWSDateTime']>;
  end_timestamp?: Maybe<Scalars['AWSTimestamp']>;
  frequency?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  is_unified?: Maybe<Scalars['Boolean']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  machine_id?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['AWSJSON']>;
  monitoring_steps?: Maybe<MonitorSteps>;
  mount_method?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_sensor_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  part_index?: Maybe<Scalars['Int']>;
  pois?: Maybe<ModelPoiEntityConnection>;
  polygon_index?: Maybe<Scalars['Int']>;
  s3_preloaded_refs?: Maybe<Array<Scalars['String']>>;
  s3_raw_ref?: Maybe<Scalars['String']>;
  s3_ref?: Maybe<Scalars['String']>;
  scanMonitoring_stepsId?: Maybe<Scalars['ID']>;
  scanSource_scansId?: Maybe<Scalars['ID']>;
  scan_accesses?: Maybe<ModelScanAccessConnection>;
  scan_depths?: Maybe<ModelScanDepthPointsConnection>;
  scan_type: Scalars['String'];
  sensor: Sensor;
  sensorScansId?: Maybe<Scalars['ID']>;
  sensor_type: Scalars['String'];
  site: Site;
  siteScansId?: Maybe<Scalars['ID']>;
  snake_index?: Maybe<Scalars['Int']>;
  source_scans?: Maybe<ModelScanConnection>;
  start_time_iso?: Maybe<Scalars['AWSDateTime']>;
  start_timestamp: Scalars['AWSTimestamp'];
  status: Scalars['String'];
  target_entities?: Maybe<ModelTargetEntityConnection>;
  target_info?: Maybe<Scalars['AWSJSON']>;
  transmitter_setups?: Maybe<ModelTransmitterSetupConnection>;
  travel_courses?: Maybe<ModelTravelCourseConnection>;
  unifiedTasksConnection?: Maybe<ModelTasksScansConnectionConnection>;
  updatedAt: Scalars['AWSDateTime'];
  v2_target_entities?: Maybe<ModelV2TargetEntityConnection>;
};


export type ScanAnalyzed_EntitiesArgs = {
  filter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanPoisArgs = {
  filter?: InputMaybe<ModelPoiEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanScan_AccessesArgs = {
  filter?: InputMaybe<ModelScanAccessFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanScan_DepthsArgs = {
  filter?: InputMaybe<ModelScanDepthPointsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanSource_ScansArgs = {
  filter?: InputMaybe<ModelScanFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanTarget_EntitiesArgs = {
  filter?: InputMaybe<ModelTargetEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanTransmitter_SetupsArgs = {
  filter?: InputMaybe<ModelTransmitterSetupFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanTravel_CoursesArgs = {
  filter?: InputMaybe<ModelTravelCourseFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanUnifiedTasksConnectionArgs = {
  filter?: InputMaybe<ModelTasksScansConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanV2_Target_EntitiesArgs = {
  filter?: InputMaybe<ModelV2TargetEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type ScanAccess = {
  __typename?: 'ScanAccess';
  createdAt: Scalars['AWSDateTime'];
  end_timestamp?: Maybe<Scalars['AWSTimestamp']>;
  frequency?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  scan: Scan;
  scanScan_accessesId?: Maybe<Scalars['ID']>;
  start_timestamp: Scalars['AWSTimestamp'];
  updatedAt: Scalars['AWSDateTime'];
  user: Scalars['String'];
};

export type ScanByHashedParameters = {
  __typename?: 'ScanByHashedParameters';
  createdAt: Scalars['AWSDateTime'];
  hash: Scalars['ID'];
  s3_ref: Scalars['String'];
  scan_id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};

export type ScanDepthPoints = {
  __typename?: 'ScanDepthPoints';
  createdAt: Scalars['AWSDateTime'];
  ellipsoidHeights: Array<Maybe<Scalars['Float']>>;
  headings?: Maybe<Array<Maybe<Scalars['Float']>>>;
  heightMode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lats: Array<Maybe<Scalars['Float']>>;
  lons: Array<Maybe<Scalars['Float']>>;
  scan: Scan;
  scanScan_depthsId?: Maybe<Scalars['ID']>;
  site: Site;
  siteSite_depthsId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type ScanStep = {
  __typename?: 'ScanStep';
  createdAt: Scalars['AWSDateTime'];
  end_time?: Maybe<Scalars['AWSTimestamp']>;
  end_time_iso?: Maybe<Scalars['AWSDateTime']>;
  error_message?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  monitor_steps?: Maybe<MonitorSteps>;
  scanStepMonitor_stepsId?: Maybe<Scalars['ID']>;
  start_time?: Maybe<Scalars['AWSTimestamp']>;
  start_time_iso?: Maybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type SearchableAggregateBucketResult = {
  __typename?: 'SearchableAggregateBucketResult';
  buckets?: Maybe<Array<Maybe<SearchableAggregateBucketResultItem>>>;
};

export type SearchableAggregateBucketResultItem = {
  __typename?: 'SearchableAggregateBucketResultItem';
  doc_count: Scalars['Int'];
  key: Scalars['String'];
};

export type SearchableAggregateGenericResult = SearchableAggregateBucketResult | SearchableAggregateScalarResult;

export type SearchableAggregateResult = {
  __typename?: 'SearchableAggregateResult';
  name: Scalars['String'];
  result?: Maybe<SearchableAggregateGenericResult>;
};

export type SearchableAggregateScalarResult = {
  __typename?: 'SearchableAggregateScalarResult';
  value: Scalars['Float'];
};

export enum SearchableAggregateType {
  Avg = 'avg',
  Cardinality = 'cardinality',
  Max = 'max',
  Min = 'min',
  Sum = 'sum',
  Terms = 'terms'
}

export enum SearchableAlgoUtilityMetricsAggregateField {
  AlgoUtilityMetricsAnalyzedEntityId = 'algoUtilityMetricsAnalyzed_entityId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Metrics = 'metrics',
  UpdatedAt = 'updatedAt'
}

export type SearchableAlgoUtilityMetricsAggregationInput = {
  field: SearchableAlgoUtilityMetricsAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableAlgoUtilityMetricsConnection = {
  __typename?: 'SearchableAlgoUtilityMetricsConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<AlgoUtilityMetrics>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableAlgoUtilityMetricsFilterInput = {
  algoUtilityMetricsAnalyzed_entityId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableAlgoUtilityMetricsFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  metrics?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableAlgoUtilityMetricsFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableAlgoUtilityMetricsFilterInput>>>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableAlgoUtilityMetricsSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableAlgoUtilityMetricsSortableFields>;
};

export enum SearchableAlgoUtilityMetricsSortableFields {
  AlgoUtilityMetricsAnalyzedEntityId = 'algoUtilityMetricsAnalyzed_entityId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Metrics = 'metrics',
  UpdatedAt = 'updatedAt'
}

export enum SearchableAnalysisStepAggregateField {
  AccessTime = 'access_time',
  AccessTimeIso = 'access_time_iso',
  AnalysisStepMonitorStepsId = 'analysisStepMonitor_stepsId',
  CreatedAt = 'createdAt',
  ErrorMessage = 'error_message',
  Id = 'id',
  IsNewFoundEntity = 'is_new_found_entity',
  IsScanOpened = 'is_scan_opened',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type SearchableAnalysisStepAggregationInput = {
  field: SearchableAnalysisStepAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableAnalysisStepConnection = {
  __typename?: 'SearchableAnalysisStepConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<AnalysisStep>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableAnalysisStepFilterInput = {
  access_time?: InputMaybe<SearchableIntFilterInput>;
  access_time_iso?: InputMaybe<SearchableStringFilterInput>;
  analysisStepMonitor_stepsId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableAnalysisStepFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  error_message?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  is_new_found_entity?: InputMaybe<SearchableBooleanFilterInput>;
  is_scan_opened?: InputMaybe<SearchableBooleanFilterInput>;
  not?: InputMaybe<SearchableAnalysisStepFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableAnalysisStepFilterInput>>>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableAnalysisStepSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableAnalysisStepSortableFields>;
};

export enum SearchableAnalysisStepSortableFields {
  AccessTime = 'access_time',
  AccessTimeIso = 'access_time_iso',
  AnalysisStepMonitorStepsId = 'analysisStepMonitor_stepsId',
  CreatedAt = 'createdAt',
  ErrorMessage = 'error_message',
  Id = 'id',
  IsNewFoundEntity = 'is_new_found_entity',
  IsScanOpened = 'is_scan_opened',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export enum SearchableAnalyzedEntityAggregateField {
  AnalysisConfidence = 'analysis_confidence',
  AnalyzedEntityAlgoUtilityMetricsId = 'analyzedEntityAlgo_utility_metricsId',
  AnalyzedEntityPipesqaViewId = 'analyzedEntityPipesqa_viewId',
  AppName = 'app_name',
  AppVersion = 'app_version',
  AutoConfidence = 'auto_confidence',
  Classification = 'classification',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  CreationSource = 'creation_source',
  CreationTimeIso = 'creation_time_iso',
  CreationTimestamp = 'creation_timestamp',
  DataSource = 'data_source',
  DetailedGeoData = 'detailed_geo_data',
  Frequency = 'frequency',
  GeoData = 'geo_data',
  Id = 'id',
  IsValid = 'is_valid',
  Magnetization = 'magnetization',
  Name = 'name',
  S3AnalysisRef = 's3_analysis_ref',
  S3EplsRef = 's3_epls_ref',
  S3Ref = 's3_ref',
  ScanAnalyzedEntitiesId = 'scanAnalyzed_entitiesId',
  SensorModel = 'sensor_model',
  SiteAnalyzedEntitiesId = 'siteAnalyzed_entitiesId',
  Snr = 'snr',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export type SearchableAnalyzedEntityAggregationInput = {
  field: SearchableAnalyzedEntityAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableAnalyzedEntityConnection = {
  __typename?: 'SearchableAnalyzedEntityConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<AnalyzedEntity>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableAnalyzedEntityFilterInput = {
  analysis_confidence?: InputMaybe<SearchableFloatFilterInput>;
  analyzedEntityAlgo_utility_metricsId?: InputMaybe<SearchableIdFilterInput>;
  analyzedEntityPipesqa_viewId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntityFilterInput>>>;
  app_name?: InputMaybe<SearchableStringFilterInput>;
  app_version?: InputMaybe<SearchableStringFilterInput>;
  auto_confidence?: InputMaybe<SearchableFloatFilterInput>;
  classification?: InputMaybe<SearchableStringFilterInput>;
  comment?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  creation_source?: InputMaybe<SearchableStringFilterInput>;
  creation_time_iso?: InputMaybe<SearchableStringFilterInput>;
  creation_timestamp?: InputMaybe<SearchableIntFilterInput>;
  data_source?: InputMaybe<SearchableStringFilterInput>;
  detailed_geo_data?: InputMaybe<SearchableStringFilterInput>;
  frequency?: InputMaybe<SearchableFloatFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  is_valid?: InputMaybe<SearchableBooleanFilterInput>;
  magnetization?: InputMaybe<SearchableFloatFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableAnalyzedEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntityFilterInput>>>;
  s3_analysis_ref?: InputMaybe<SearchableStringFilterInput>;
  s3_epls_ref?: InputMaybe<SearchableStringFilterInput>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  scanAnalyzed_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  sensor_model?: InputMaybe<SearchableStringFilterInput>;
  siteAnalyzed_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  snr?: InputMaybe<SearchableFloatFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  user?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableAnalyzedEntitySortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableAnalyzedEntitySortableFields>;
};

export enum SearchableAnalyzedEntitySortableFields {
  AnalysisConfidence = 'analysis_confidence',
  AnalyzedEntityAlgoUtilityMetricsId = 'analyzedEntityAlgo_utility_metricsId',
  AnalyzedEntityPipesqaViewId = 'analyzedEntityPipesqa_viewId',
  AppName = 'app_name',
  AppVersion = 'app_version',
  AutoConfidence = 'auto_confidence',
  Classification = 'classification',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  CreationSource = 'creation_source',
  CreationTimeIso = 'creation_time_iso',
  CreationTimestamp = 'creation_timestamp',
  DataSource = 'data_source',
  DetailedGeoData = 'detailed_geo_data',
  Frequency = 'frequency',
  GeoData = 'geo_data',
  Id = 'id',
  IsValid = 'is_valid',
  Magnetization = 'magnetization',
  Name = 'name',
  S3AnalysisRef = 's3_analysis_ref',
  S3EplsRef = 's3_epls_ref',
  S3Ref = 's3_ref',
  ScanAnalyzedEntitiesId = 'scanAnalyzed_entitiesId',
  SensorModel = 'sensor_model',
  SiteAnalyzedEntitiesId = 'siteAnalyzed_entitiesId',
  Snr = 'snr',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export type SearchableBooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export enum SearchableCommentAggregateField {
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  Id = 'id',
  PipesqaPipeCommentsId = 'pipesqaPipeCommentsId',
  Text = 'text',
  Timestamp = 'timestamp',
  UpdatedAt = 'updatedAt'
}

export type SearchableCommentAggregationInput = {
  field: SearchableCommentAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableCommentConnection = {
  __typename?: 'SearchableCommentConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Comment>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableCommentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableCommentFilterInput>>>;
  changed_by?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableCommentFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableCommentFilterInput>>>;
  pipesqaPipeCommentsId?: InputMaybe<SearchableIdFilterInput>;
  text?: InputMaybe<SearchableStringFilterInput>;
  timestamp?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableCommentSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableCommentSortableFields>;
};

export enum SearchableCommentSortableFields {
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  Id = 'id',
  PipesqaPipeCommentsId = 'pipesqaPipeCommentsId',
  Text = 'text',
  Timestamp = 'timestamp',
  UpdatedAt = 'updatedAt'
}

export enum SearchableCustomerAggregateField {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type SearchableCustomerAggregationInput = {
  field: SearchableCustomerAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableCustomerConnection = {
  __typename?: 'SearchableCustomerConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Customer>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableCustomerFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableCustomerFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableCustomerFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableCustomerFilterInput>>>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableCustomerSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableCustomerSortableFields>;
};

export enum SearchableCustomerSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export enum SearchableEntityDepthPointsAggregateField {
  Algorithm = 'algorithm',
  AnalyzedEntityDepthPointsId = 'analyzedEntityDepth_pointsId',
  CreatedAt = 'createdAt',
  DepthsFromGround = 'depthsFromGround',
  EllipsoidHeights = 'ellipsoidHeights',
  EllipsoidHeightsMask = 'ellipsoidHeightsMask',
  HadError = 'hadError',
  HasEllipsoidHeight = 'hasEllipsoidHeight',
  Id = 'id',
  Lats = 'lats',
  Lons = 'lons',
  MappingEntityDepthPointsId = 'mappingEntityDepth_pointsId',
  OriginalCrs = 'originalCrs',
  SiteSitePipeDepthsId = 'siteSite_pipe_depthsId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type SearchableEntityDepthPointsAggregationInput = {
  field: SearchableEntityDepthPointsAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableEntityDepthPointsConnection = {
  __typename?: 'SearchableEntityDepthPointsConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<EntityDepthPoints>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableEntityDepthPointsFilterInput = {
  algorithm?: InputMaybe<SearchableStringFilterInput>;
  analyzedEntityDepth_pointsId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableEntityDepthPointsFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  depthsFromGround?: InputMaybe<SearchableFloatFilterInput>;
  ellipsoidHeights?: InputMaybe<SearchableFloatFilterInput>;
  ellipsoidHeightsMask?: InputMaybe<SearchableBooleanFilterInput>;
  hadError?: InputMaybe<SearchableBooleanFilterInput>;
  hasEllipsoidHeight?: InputMaybe<SearchableBooleanFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  lats?: InputMaybe<SearchableFloatFilterInput>;
  lons?: InputMaybe<SearchableFloatFilterInput>;
  mappingEntityDepth_pointsId?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableEntityDepthPointsFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableEntityDepthPointsFilterInput>>>;
  originalCrs?: InputMaybe<SearchableStringFilterInput>;
  siteSite_pipe_depthsId?: InputMaybe<SearchableIdFilterInput>;
  type?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableEntityDepthPointsSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableEntityDepthPointsSortableFields>;
};

export enum SearchableEntityDepthPointsSortableFields {
  Algorithm = 'algorithm',
  AnalyzedEntityDepthPointsId = 'analyzedEntityDepth_pointsId',
  CreatedAt = 'createdAt',
  DepthsFromGround = 'depthsFromGround',
  EllipsoidHeights = 'ellipsoidHeights',
  EllipsoidHeightsMask = 'ellipsoidHeightsMask',
  HadError = 'hadError',
  HasEllipsoidHeight = 'hasEllipsoidHeight',
  Id = 'id',
  Lats = 'lats',
  Lons = 'lons',
  MappingEntityDepthPointsId = 'mappingEntityDepth_pointsId',
  OriginalCrs = 'originalCrs',
  SiteSitePipeDepthsId = 'siteSite_pipe_depthsId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type SearchableFloatFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export enum SearchableGeoidValueAggregateField {
  CreatedAt = 'createdAt',
  Id = 'id',
  Lat = 'lat',
  Lon = 'lon',
  SiteGeoidValuesId = 'siteGeoid_valuesId',
  UpdatedAt = 'updatedAt',
  Value = 'value'
}

export type SearchableGeoidValueAggregationInput = {
  field: SearchableGeoidValueAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableGeoidValueConnection = {
  __typename?: 'SearchableGeoidValueConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<GeoidValue>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableGeoidValueFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableGeoidValueFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  lat?: InputMaybe<SearchableFloatFilterInput>;
  lon?: InputMaybe<SearchableFloatFilterInput>;
  not?: InputMaybe<SearchableGeoidValueFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableGeoidValueFilterInput>>>;
  siteGeoid_valuesId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  value?: InputMaybe<SearchableFloatFilterInput>;
};

export type SearchableGeoidValueSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableGeoidValueSortableFields>;
};

export enum SearchableGeoidValueSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Lat = 'lat',
  Lon = 'lon',
  SiteGeoidValuesId = 'siteGeoid_valuesId',
  UpdatedAt = 'updatedAt',
  Value = 'value'
}

export type SearchableIdFilterInput = {
  eq?: InputMaybe<Scalars['ID']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  match?: InputMaybe<Scalars['ID']>;
  matchPhrase?: InputMaybe<Scalars['ID']>;
  matchPhrasePrefix?: InputMaybe<Scalars['ID']>;
  multiMatch?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  regexp?: InputMaybe<Scalars['ID']>;
  wildcard?: InputMaybe<Scalars['ID']>;
};

export type SearchableIntFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum SearchableLocationAggregateField {
  Country = 'country',
  CreatedAt = 'createdAt',
  Id = 'id',
  Region = 'region',
  State = 'state',
  UpdatedAt = 'updatedAt'
}

export type SearchableLocationAggregationInput = {
  field: SearchableLocationAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableLocationConnection = {
  __typename?: 'SearchableLocationConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Location>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableLocationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableLocationFilterInput>>>;
  country?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableLocationFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableLocationFilterInput>>>;
  region?: InputMaybe<SearchableStringFilterInput>;
  state?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableLocationSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableLocationSortableFields>;
};

export enum SearchableLocationSortableFields {
  Country = 'country',
  CreatedAt = 'createdAt',
  Id = 'id',
  Region = 'region',
  State = 'state',
  UpdatedAt = 'updatedAt'
}

export enum SearchableManholeItemAggregateField {
  CreatedAt = 'createdAt',
  Depth = 'depth',
  GpsHeight = 'gpsHeight',
  Id = 'id',
  ManholeCoverDiameter = 'manholeCoverDiameter',
  ManholeCoverMaterial = 'manholeCoverMaterial',
  ManholeCoverShape = 'manholeCoverShape',
  ManholeItemPoiId = 'manholeItemPoiId',
  ManholeName = 'manholeName',
  OtherOwnerName = 'otherOwnerName',
  OverallNumber = 'overallNumber',
  Owner = 'owner',
  PipeDiscoveryStatus = 'pipeDiscoveryStatus',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UtilityTypes = 'utilityTypes'
}

export type SearchableManholeItemAggregationInput = {
  field: SearchableManholeItemAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableManholeItemConnection = {
  __typename?: 'SearchableManholeItemConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<ManholeItem>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableManholeItemFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableManholeItemFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  depth?: InputMaybe<SearchableFloatFilterInput>;
  gpsHeight?: InputMaybe<SearchableFloatFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  manholeCoverDiameter?: InputMaybe<SearchableFloatFilterInput>;
  manholeCoverMaterial?: InputMaybe<SearchableStringFilterInput>;
  manholeCoverShape?: InputMaybe<SearchableStringFilterInput>;
  manholeItemPoiId?: InputMaybe<SearchableIdFilterInput>;
  manholeName?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableManholeItemFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableManholeItemFilterInput>>>;
  otherOwnerName?: InputMaybe<SearchableStringFilterInput>;
  overallNumber?: InputMaybe<SearchableIntFilterInput>;
  owner?: InputMaybe<SearchableStringFilterInput>;
  pipeDiscoveryStatus?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  utilityTypes?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableManholeItemSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableManholeItemSortableFields>;
};

export enum SearchableManholeItemSortableFields {
  CreatedAt = 'createdAt',
  Depth = 'depth',
  GpsHeight = 'gpsHeight',
  Id = 'id',
  ManholeCoverDiameter = 'manholeCoverDiameter',
  ManholeCoverMaterial = 'manholeCoverMaterial',
  ManholeCoverShape = 'manholeCoverShape',
  ManholeItemPoiId = 'manholeItemPoiId',
  ManholeName = 'manholeName',
  OtherOwnerName = 'otherOwnerName',
  OverallNumber = 'overallNumber',
  Owner = 'owner',
  PipeDiscoveryStatus = 'pipeDiscoveryStatus',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UtilityTypes = 'utilityTypes'
}

export enum SearchableMappingEntityAggregateField {
  AppName = 'app_name',
  AppVersion = 'app_version',
  Classification = 'classification',
  Color = 'color',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  CreationSource = 'creation_source',
  CreationTimeIso = 'creation_time_iso',
  CreationTimestamp = 'creation_timestamp',
  DataSource = 'data_source',
  Depth = 'depth',
  GeoData = 'geo_data',
  Id = 'id',
  IsValid = 'is_valid',
  Name = 'name',
  ObjectType = 'object_type',
  Properties = 'properties',
  S3Ref = 's3_ref',
  SiteMappingEntitiesId = 'siteMapping_entitiesId',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export type SearchableMappingEntityAggregationInput = {
  field: SearchableMappingEntityAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableMappingEntityConnection = {
  __typename?: 'SearchableMappingEntityConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<MappingEntity>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableMappingEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableMappingEntityFilterInput>>>;
  app_name?: InputMaybe<SearchableStringFilterInput>;
  app_version?: InputMaybe<SearchableStringFilterInput>;
  classification?: InputMaybe<SearchableStringFilterInput>;
  color?: InputMaybe<SearchableStringFilterInput>;
  comment?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  creation_source?: InputMaybe<SearchableStringFilterInput>;
  creation_time_iso?: InputMaybe<SearchableStringFilterInput>;
  creation_timestamp?: InputMaybe<SearchableIntFilterInput>;
  data_source?: InputMaybe<SearchableStringFilterInput>;
  depth?: InputMaybe<SearchableFloatFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  is_valid?: InputMaybe<SearchableBooleanFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableMappingEntityFilterInput>;
  object_type?: InputMaybe<SearchableStringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableMappingEntityFilterInput>>>;
  properties?: InputMaybe<SearchableStringFilterInput>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  siteMapping_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  user?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableMappingEntitySortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableMappingEntitySortableFields>;
};

export enum SearchableMappingEntitySortableFields {
  AppName = 'app_name',
  AppVersion = 'app_version',
  Classification = 'classification',
  Color = 'color',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  CreationSource = 'creation_source',
  CreationTimeIso = 'creation_time_iso',
  CreationTimestamp = 'creation_timestamp',
  DataSource = 'data_source',
  Depth = 'depth',
  GeoData = 'geo_data',
  Id = 'id',
  IsValid = 'is_valid',
  Name = 'name',
  ObjectType = 'object_type',
  Properties = 'properties',
  S3Ref = 's3_ref',
  SiteMappingEntitiesId = 'siteMapping_entitiesId',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export enum SearchableMonitorStepsAggregateField {
  CreatedAt = 'createdAt',
  Id = 'id',
  MonitorStepsAnalysisStepId = 'monitorStepsAnalysis_stepId',
  MonitorStepsPreloaderStepId = 'monitorStepsPreloader_stepId',
  MonitorStepsScanId = 'monitorStepsScanId',
  MonitorStepsScanStepId = 'monitorStepsScan_stepId',
  MonitorStepsUploadStepId = 'monitorStepsUpload_stepId',
  MonitorStepsUtilityFinderStepId = 'monitorStepsUtility_finder_stepId',
  UpdatedAt = 'updatedAt'
}

export type SearchableMonitorStepsAggregationInput = {
  field: SearchableMonitorStepsAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableMonitorStepsConnection = {
  __typename?: 'SearchableMonitorStepsConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<MonitorSteps>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableMonitorStepsFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableMonitorStepsFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  monitorStepsAnalysis_stepId?: InputMaybe<SearchableIdFilterInput>;
  monitorStepsPreloader_stepId?: InputMaybe<SearchableIdFilterInput>;
  monitorStepsScanId?: InputMaybe<SearchableIdFilterInput>;
  monitorStepsScan_stepId?: InputMaybe<SearchableIdFilterInput>;
  monitorStepsUpload_stepId?: InputMaybe<SearchableIdFilterInput>;
  monitorStepsUtility_finder_stepId?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableMonitorStepsFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableMonitorStepsFilterInput>>>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableMonitorStepsSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableMonitorStepsSortableFields>;
};

export enum SearchableMonitorStepsSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  MonitorStepsAnalysisStepId = 'monitorStepsAnalysis_stepId',
  MonitorStepsPreloaderStepId = 'monitorStepsPreloader_stepId',
  MonitorStepsScanId = 'monitorStepsScanId',
  MonitorStepsScanStepId = 'monitorStepsScan_stepId',
  MonitorStepsUploadStepId = 'monitorStepsUpload_stepId',
  MonitorStepsUtilityFinderStepId = 'monitorStepsUtility_finder_stepId',
  UpdatedAt = 'updatedAt'
}

export enum SearchablePoiEntityAggregateField {
  Comment = 'comment',
  CreatedAt = 'createdAt',
  GeoData = 'geo_data',
  Id = 'id',
  POiEntityDataId = 'pOIEntityDataId',
  PoiType = 'poiType',
  ScanPoisId = 'scanPoisId',
  SitePoisId = 'sitePoisId',
  UpdatedAt = 'updatedAt'
}

export type SearchablePoiEntityAggregationInput = {
  field: SearchablePoiEntityAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchablePoiEntityConnection = {
  __typename?: 'SearchablePOIEntityConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<PoiEntity>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchablePoiEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchablePoiEntityFilterInput>>>;
  comment?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchablePoiEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchablePoiEntityFilterInput>>>;
  pOIEntityDataId?: InputMaybe<SearchableIdFilterInput>;
  poiType?: InputMaybe<SearchableStringFilterInput>;
  scanPoisId?: InputMaybe<SearchableIdFilterInput>;
  sitePoisId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchablePoiEntitySortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchablePoiEntitySortableFields>;
};

export enum SearchablePoiEntitySortableFields {
  Comment = 'comment',
  CreatedAt = 'createdAt',
  GeoData = 'geo_data',
  Id = 'id',
  POiEntityDataId = 'pOIEntityDataId',
  PoiType = 'poiType',
  ScanPoisId = 'scanPoisId',
  SitePoisId = 'sitePoisId',
  UpdatedAt = 'updatedAt'
}

export enum SearchablePipeItemAggregateField {
  Azimuth = 'azimuth',
  CreatedAt = 'createdAt',
  Description = 'description',
  Diameter = 'diameter',
  FlowDirection = 'flowDirection',
  Hive = 'hive',
  Id = 'id',
  InvertLevel = 'invertLevel',
  ManholeItemPipesId = 'manholeItemPipesId',
  Media = 'media',
  NoCables = 'noCables',
  SecondaryOwner = 'secondaryOwner',
  Status = 'status',
  Thickness = 'thickness',
  TopLevel = 'topLevel',
  UpdatedAt = 'updatedAt'
}

export type SearchablePipeItemAggregationInput = {
  field: SearchablePipeItemAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchablePipeItemConnection = {
  __typename?: 'SearchablePipeItemConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<PipeItem>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchablePipeItemFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchablePipeItemFilterInput>>>;
  azimuth?: InputMaybe<SearchableFloatFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  description?: InputMaybe<SearchableStringFilterInput>;
  diameter?: InputMaybe<SearchableFloatFilterInput>;
  flowDirection?: InputMaybe<SearchableStringFilterInput>;
  hive?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  invertLevel?: InputMaybe<SearchableFloatFilterInput>;
  manholeItemPipesId?: InputMaybe<SearchableIdFilterInput>;
  media?: InputMaybe<SearchableStringFilterInput>;
  noCables?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchablePipeItemFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchablePipeItemFilterInput>>>;
  secondaryOwner?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  thickness?: InputMaybe<SearchableFloatFilterInput>;
  topLevel?: InputMaybe<SearchableFloatFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchablePipeItemSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchablePipeItemSortableFields>;
};

export enum SearchablePipeItemSortableFields {
  Azimuth = 'azimuth',
  CreatedAt = 'createdAt',
  Description = 'description',
  Diameter = 'diameter',
  FlowDirection = 'flowDirection',
  Hive = 'hive',
  Id = 'id',
  InvertLevel = 'invertLevel',
  ManholeItemPipesId = 'manholeItemPipesId',
  Media = 'media',
  NoCables = 'noCables',
  SecondaryOwner = 'secondaryOwner',
  Status = 'status',
  Thickness = 'thickness',
  TopLevel = 'topLevel',
  UpdatedAt = 'updatedAt'
}

export enum SearchablePipelineRunInfoAggregateField {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  EplsS3Ref = 'epls_s3_ref',
  ErrorMessage = 'error_message',
  Id = 'id',
  Name = 'name',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UtilityFinderStepPipelinesId = 'utilityFinderStepPipelinesId'
}

export type SearchablePipelineRunInfoAggregationInput = {
  field: SearchablePipelineRunInfoAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchablePipelineRunInfoConnection = {
  __typename?: 'SearchablePipelineRunInfoConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<PipelineRunInfo>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchablePipelineRunInfoFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchablePipelineRunInfoFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  end_time?: InputMaybe<SearchableIntFilterInput>;
  end_time_iso?: InputMaybe<SearchableStringFilterInput>;
  epls_s3_ref?: InputMaybe<SearchableStringFilterInput>;
  error_message?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchablePipelineRunInfoFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchablePipelineRunInfoFilterInput>>>;
  start_time?: InputMaybe<SearchableIntFilterInput>;
  start_time_iso?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  utilityFinderStepPipelinesId?: InputMaybe<SearchableIdFilterInput>;
};

export type SearchablePipelineRunInfoSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchablePipelineRunInfoSortableFields>;
};

export enum SearchablePipelineRunInfoSortableFields {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  EplsS3Ref = 'epls_s3_ref',
  ErrorMessage = 'error_message',
  Id = 'id',
  Name = 'name',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UtilityFinderStepPipelinesId = 'utilityFinderStepPipelinesId'
}

export enum SearchablePipesqaPipeAggregateField {
  CreatedAt = 'createdAt',
  CrossingsStatuses = 'crossings_statuses',
  Flagged = 'flagged',
  Id = 'id',
  PipesqaPipeAnalyzedEntityId = 'pipesqaPipeAnalyzed_entityId',
  Status = 'status',
  UpdateTimestamp = 'update_timestamp',
  UpdatedAt = 'updatedAt'
}

export type SearchablePipesqaPipeAggregationInput = {
  field: SearchablePipesqaPipeAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchablePipesqaPipeConnection = {
  __typename?: 'SearchablePipesqaPipeConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<PipesqaPipe>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchablePipesqaPipeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchablePipesqaPipeFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  crossings_statuses?: InputMaybe<SearchableStringFilterInput>;
  flagged?: InputMaybe<SearchableBooleanFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchablePipesqaPipeFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchablePipesqaPipeFilterInput>>>;
  pipesqaPipeAnalyzed_entityId?: InputMaybe<SearchableIdFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  update_timestamp?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchablePipesqaPipeSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchablePipesqaPipeSortableFields>;
};

export enum SearchablePipesqaPipeSortableFields {
  CreatedAt = 'createdAt',
  CrossingsStatuses = 'crossings_statuses',
  Flagged = 'flagged',
  Id = 'id',
  PipesqaPipeAnalyzedEntityId = 'pipesqaPipeAnalyzed_entityId',
  Status = 'status',
  UpdateTimestamp = 'update_timestamp',
  UpdatedAt = 'updatedAt'
}

export enum SearchablePlannedEntitiesAggregateField {
  CreatedAt = 'createdAt',
  Id = 'id',
  PlannedEntitiesSiteId = 'plannedEntitiesSiteId',
  S3Ref = 's3_ref',
  UpdatedAt = 'updatedAt'
}

export type SearchablePlannedEntitiesAggregationInput = {
  field: SearchablePlannedEntitiesAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchablePlannedEntitiesConnection = {
  __typename?: 'SearchablePlannedEntitiesConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<PlannedEntities>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchablePlannedEntitiesFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchablePlannedEntitiesFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchablePlannedEntitiesFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchablePlannedEntitiesFilterInput>>>;
  plannedEntitiesSiteId?: InputMaybe<SearchableIdFilterInput>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchablePlannedEntitiesSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchablePlannedEntitiesSortableFields>;
};

export enum SearchablePlannedEntitiesSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  PlannedEntitiesSiteId = 'plannedEntitiesSiteId',
  S3Ref = 's3_ref',
  UpdatedAt = 'updatedAt'
}

export enum SearchablePlannedPolygonAggregateField {
  Color = 'color',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  GeoData = 'geo_data',
  Id = 'id',
  IsRescan = 'is_rescan',
  MountMethod = 'mount_method',
  Name = 'name',
  OverrideColor = 'override_color',
  PlannedEntitiesPlannedPolygonsId = 'plannedEntitiesPlanned_polygonsId',
  SensorModels = 'sensor_models',
  UpdatedAt = 'updatedAt'
}

export type SearchablePlannedPolygonAggregationInput = {
  field: SearchablePlannedPolygonAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchablePlannedPolygonConnection = {
  __typename?: 'SearchablePlannedPolygonConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<PlannedPolygon>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchablePlannedPolygonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchablePlannedPolygonFilterInput>>>;
  color?: InputMaybe<SearchableStringFilterInput>;
  comment?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  is_rescan?: InputMaybe<SearchableBooleanFilterInput>;
  mount_method?: InputMaybe<SearchableStringFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchablePlannedPolygonFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchablePlannedPolygonFilterInput>>>;
  override_color?: InputMaybe<SearchableBooleanFilterInput>;
  plannedEntitiesPlanned_polygonsId?: InputMaybe<SearchableIdFilterInput>;
  sensor_models?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchablePlannedPolygonSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchablePlannedPolygonSortableFields>;
};

export enum SearchablePlannedPolygonSortableFields {
  Color = 'color',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  GeoData = 'geo_data',
  Id = 'id',
  IsRescan = 'is_rescan',
  MountMethod = 'mount_method',
  Name = 'name',
  OverrideColor = 'override_color',
  PlannedEntitiesPlannedPolygonsId = 'plannedEntitiesPlanned_polygonsId',
  SensorModels = 'sensor_models',
  UpdatedAt = 'updatedAt'
}

export enum SearchablePlannedTransmitterAggregateField {
  Color = 'color',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  GeoData = 'geo_data',
  Id = 'id',
  Method = 'method',
  Name = 'name',
  OverrideColor = 'override_color',
  PlannedPolygonPlannedTransmittersId = 'plannedPolygonPlanned_transmittersId',
  UpdatedAt = 'updatedAt'
}

export type SearchablePlannedTransmitterAggregationInput = {
  field: SearchablePlannedTransmitterAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchablePlannedTransmitterConnection = {
  __typename?: 'SearchablePlannedTransmitterConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<PlannedTransmitter>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchablePlannedTransmitterFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchablePlannedTransmitterFilterInput>>>;
  color?: InputMaybe<SearchableStringFilterInput>;
  comment?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  method?: InputMaybe<SearchableStringFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchablePlannedTransmitterFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchablePlannedTransmitterFilterInput>>>;
  override_color?: InputMaybe<SearchableBooleanFilterInput>;
  plannedPolygonPlanned_transmittersId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchablePlannedTransmitterSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchablePlannedTransmitterSortableFields>;
};

export enum SearchablePlannedTransmitterSortableFields {
  Color = 'color',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  GeoData = 'geo_data',
  Id = 'id',
  Method = 'method',
  Name = 'name',
  OverrideColor = 'override_color',
  PlannedPolygonPlannedTransmittersId = 'plannedPolygonPlanned_transmittersId',
  UpdatedAt = 'updatedAt'
}

export enum SearchablePreloadRunInfoAggregateField {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  EplsS3Ref = 'epls_s3_ref',
  ErrorMessage = 'error_message',
  Id = 'id',
  PreloadedScanS3Refs = 'preloaded_scan_s3_refs',
  PreloaderStepPreloadRunsId = 'preloaderStepPreload_runsId',
  Products = 'products',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  TargetName = 'target_name',
  TargetType = 'target_type',
  UpdatedAt = 'updatedAt'
}

export type SearchablePreloadRunInfoAggregationInput = {
  field: SearchablePreloadRunInfoAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchablePreloadRunInfoConnection = {
  __typename?: 'SearchablePreloadRunInfoConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<PreloadRunInfo>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchablePreloadRunInfoFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchablePreloadRunInfoFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  end_time?: InputMaybe<SearchableIntFilterInput>;
  end_time_iso?: InputMaybe<SearchableStringFilterInput>;
  epls_s3_ref?: InputMaybe<SearchableStringFilterInput>;
  error_message?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchablePreloadRunInfoFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchablePreloadRunInfoFilterInput>>>;
  preloaded_scan_s3_refs?: InputMaybe<SearchableStringFilterInput>;
  preloaderStepPreload_runsId?: InputMaybe<SearchableIdFilterInput>;
  products?: InputMaybe<SearchableStringFilterInput>;
  start_time?: InputMaybe<SearchableIntFilterInput>;
  start_time_iso?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  target_name?: InputMaybe<SearchableStringFilterInput>;
  target_type?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchablePreloadRunInfoSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchablePreloadRunInfoSortableFields>;
};

export enum SearchablePreloadRunInfoSortableFields {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  EplsS3Ref = 'epls_s3_ref',
  ErrorMessage = 'error_message',
  Id = 'id',
  PreloadedScanS3Refs = 'preloaded_scan_s3_refs',
  PreloaderStepPreloadRunsId = 'preloaderStepPreload_runsId',
  Products = 'products',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  TargetName = 'target_name',
  TargetType = 'target_type',
  UpdatedAt = 'updatedAt'
}

export enum SearchablePreloaderStepAggregateField {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  ErrorMessage = 'error_message',
  Id = 'id',
  PreloaderStepMonitorStepsId = 'preloaderStepMonitor_stepsId',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type SearchablePreloaderStepAggregationInput = {
  field: SearchablePreloaderStepAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchablePreloaderStepConnection = {
  __typename?: 'SearchablePreloaderStepConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<PreloaderStep>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchablePreloaderStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchablePreloaderStepFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  end_time?: InputMaybe<SearchableIntFilterInput>;
  end_time_iso?: InputMaybe<SearchableStringFilterInput>;
  error_message?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchablePreloaderStepFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchablePreloaderStepFilterInput>>>;
  preloaderStepMonitor_stepsId?: InputMaybe<SearchableIdFilterInput>;
  start_time?: InputMaybe<SearchableIntFilterInput>;
  start_time_iso?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchablePreloaderStepSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchablePreloaderStepSortableFields>;
};

export enum SearchablePreloaderStepSortableFields {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  ErrorMessage = 'error_message',
  Id = 'id',
  PreloaderStepMonitorStepsId = 'preloaderStepMonitor_stepsId',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export enum SearchableReanalysisRequestAggregateField {
  AnalyzedEntityReanalysisRequestsId = 'analyzedEntityReanalysis_requestsId',
  Assignee = 'assignee',
  CreatedAt = 'createdAt',
  DueDate = 'due_date',
  DueDateIso = 'due_date_iso',
  Id = 'id',
  Name = 'name',
  OpenedBy = 'opened_by',
  SiteReanalysisRequestsId = 'siteReanalysis_requestsId',
  Status = 'status',
  TransmitterSetupReanalysisRequestsId = 'transmitterSetupReanalysis_requestsId',
  TravelCourseReanalysisRequestsId = 'travelCourseReanalysis_requestsId',
  UpdatedAt = 'updatedAt'
}

export type SearchableReanalysisRequestAggregationInput = {
  field: SearchableReanalysisRequestAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export enum SearchableReanalysisRequestCommentAggregateField {
  Comment = 'comment',
  Commenter = 'commenter',
  CreatedAt = 'createdAt',
  Date = 'date',
  DateIso = 'date_iso',
  Id = 'id',
  ReanalysisRequestCommentsId = 'reanalysisRequestCommentsId',
  UpdatedAt = 'updatedAt'
}

export type SearchableReanalysisRequestCommentAggregationInput = {
  field: SearchableReanalysisRequestCommentAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableReanalysisRequestCommentConnection = {
  __typename?: 'SearchableReanalysisRequestCommentConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<ReanalysisRequestComment>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableReanalysisRequestCommentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableReanalysisRequestCommentFilterInput>>>;
  comment?: InputMaybe<SearchableStringFilterInput>;
  commenter?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  date?: InputMaybe<SearchableIntFilterInput>;
  date_iso?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableReanalysisRequestCommentFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableReanalysisRequestCommentFilterInput>>>;
  reanalysisRequestCommentsId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableReanalysisRequestCommentSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableReanalysisRequestCommentSortableFields>;
};

export enum SearchableReanalysisRequestCommentSortableFields {
  Comment = 'comment',
  Commenter = 'commenter',
  CreatedAt = 'createdAt',
  Date = 'date',
  DateIso = 'date_iso',
  Id = 'id',
  ReanalysisRequestCommentsId = 'reanalysisRequestCommentsId',
  UpdatedAt = 'updatedAt'
}

export type SearchableReanalysisRequestConnection = {
  __typename?: 'SearchableReanalysisRequestConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<ReanalysisRequest>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableReanalysisRequestFilterInput = {
  analyzedEntityReanalysis_requestsId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableReanalysisRequestFilterInput>>>;
  assignee?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  due_date?: InputMaybe<SearchableIntFilterInput>;
  due_date_iso?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableReanalysisRequestFilterInput>;
  opened_by?: InputMaybe<SearchableStringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableReanalysisRequestFilterInput>>>;
  siteReanalysis_requestsId?: InputMaybe<SearchableIdFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  transmitterSetupReanalysis_requestsId?: InputMaybe<SearchableIdFilterInput>;
  travelCourseReanalysis_requestsId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableReanalysisRequestSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableReanalysisRequestSortableFields>;
};

export enum SearchableReanalysisRequestSortableFields {
  AnalyzedEntityReanalysisRequestsId = 'analyzedEntityReanalysis_requestsId',
  Assignee = 'assignee',
  CreatedAt = 'createdAt',
  DueDate = 'due_date',
  DueDateIso = 'due_date_iso',
  Id = 'id',
  Name = 'name',
  OpenedBy = 'opened_by',
  SiteReanalysisRequestsId = 'siteReanalysis_requestsId',
  Status = 'status',
  TransmitterSetupReanalysisRequestsId = 'transmitterSetupReanalysis_requestsId',
  TravelCourseReanalysisRequestsId = 'travelCourseReanalysis_requestsId',
  UpdatedAt = 'updatedAt'
}

export enum SearchableScanAccessAggregateField {
  CreatedAt = 'createdAt',
  EndTimestamp = 'end_timestamp',
  Frequency = 'frequency',
  Id = 'id',
  ScanScanAccessesId = 'scanScan_accessesId',
  StartTimestamp = 'start_timestamp',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export type SearchableScanAccessAggregationInput = {
  field: SearchableScanAccessAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableScanAccessConnection = {
  __typename?: 'SearchableScanAccessConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<ScanAccess>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableScanAccessFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableScanAccessFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  end_timestamp?: InputMaybe<SearchableIntFilterInput>;
  frequency?: InputMaybe<SearchableFloatFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableScanAccessFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableScanAccessFilterInput>>>;
  scanScan_accessesId?: InputMaybe<SearchableIdFilterInput>;
  start_timestamp?: InputMaybe<SearchableIntFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  user?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableScanAccessSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableScanAccessSortableFields>;
};

export enum SearchableScanAccessSortableFields {
  CreatedAt = 'createdAt',
  EndTimestamp = 'end_timestamp',
  Frequency = 'frequency',
  Id = 'id',
  ScanScanAccessesId = 'scanScan_accessesId',
  StartTimestamp = 'start_timestamp',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export enum SearchableScanAggregateField {
  BoundingPolygonS3Ref = 'bounding_polygon_s3_ref',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  DownsampledGps = 'downsampled_gps',
  EndTimeIso = 'end_time_iso',
  EndTimestamp = 'end_timestamp',
  Frequency = 'frequency',
  Id = 'id',
  IsUnified = 'is_unified',
  IsValid = 'is_valid',
  MachineId = 'machine_id',
  Metadata = 'metadata',
  MountMethod = 'mount_method',
  Name = 'name',
  OriginalSensorTypes = 'original_sensor_types',
  PartIndex = 'part_index',
  PolygonIndex = 'polygon_index',
  S3PreloadedRefs = 's3_preloaded_refs',
  S3RawRef = 's3_raw_ref',
  S3Ref = 's3_ref',
  ScanMonitoringStepsId = 'scanMonitoring_stepsId',
  ScanSourceScansId = 'scanSource_scansId',
  ScanType = 'scan_type',
  SensorScansId = 'sensorScansId',
  SensorType = 'sensor_type',
  SiteScansId = 'siteScansId',
  SnakeIndex = 'snake_index',
  StartTimeIso = 'start_time_iso',
  StartTimestamp = 'start_timestamp',
  Status = 'status',
  TargetInfo = 'target_info',
  UpdatedAt = 'updatedAt'
}

export type SearchableScanAggregationInput = {
  field: SearchableScanAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export enum SearchableScanByHashedParametersAggregateField {
  CreatedAt = 'createdAt',
  Hash = 'hash',
  S3Ref = 's3_ref',
  ScanId = 'scan_id',
  UpdatedAt = 'updatedAt'
}

export type SearchableScanByHashedParametersAggregationInput = {
  field: SearchableScanByHashedParametersAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableScanByHashedParametersConnection = {
  __typename?: 'SearchableScanByHashedParametersConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<ScanByHashedParameters>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableScanByHashedParametersFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableScanByHashedParametersFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  hash?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableScanByHashedParametersFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableScanByHashedParametersFilterInput>>>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  scan_id?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableScanByHashedParametersSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableScanByHashedParametersSortableFields>;
};

export enum SearchableScanByHashedParametersSortableFields {
  CreatedAt = 'createdAt',
  Hash = 'hash',
  S3Ref = 's3_ref',
  ScanId = 'scan_id',
  UpdatedAt = 'updatedAt'
}

export type SearchableScanConnection = {
  __typename?: 'SearchableScanConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Scan>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum SearchableScanDepthPointsAggregateField {
  CreatedAt = 'createdAt',
  EllipsoidHeights = 'ellipsoidHeights',
  Headings = 'headings',
  HeightMode = 'heightMode',
  Id = 'id',
  Lats = 'lats',
  Lons = 'lons',
  ScanScanDepthsId = 'scanScan_depthsId',
  SiteSiteDepthsId = 'siteSite_depthsId',
  UpdatedAt = 'updatedAt'
}

export type SearchableScanDepthPointsAggregationInput = {
  field: SearchableScanDepthPointsAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableScanDepthPointsConnection = {
  __typename?: 'SearchableScanDepthPointsConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<ScanDepthPoints>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableScanDepthPointsFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableScanDepthPointsFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  ellipsoidHeights?: InputMaybe<SearchableFloatFilterInput>;
  headings?: InputMaybe<SearchableFloatFilterInput>;
  heightMode?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  lats?: InputMaybe<SearchableFloatFilterInput>;
  lons?: InputMaybe<SearchableFloatFilterInput>;
  not?: InputMaybe<SearchableScanDepthPointsFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableScanDepthPointsFilterInput>>>;
  scanScan_depthsId?: InputMaybe<SearchableIdFilterInput>;
  siteSite_depthsId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableScanDepthPointsSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableScanDepthPointsSortableFields>;
};

export enum SearchableScanDepthPointsSortableFields {
  CreatedAt = 'createdAt',
  EllipsoidHeights = 'ellipsoidHeights',
  Headings = 'headings',
  HeightMode = 'heightMode',
  Id = 'id',
  Lats = 'lats',
  Lons = 'lons',
  ScanScanDepthsId = 'scanScan_depthsId',
  SiteSiteDepthsId = 'siteSite_depthsId',
  UpdatedAt = 'updatedAt'
}

export type SearchableScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableScanFilterInput>>>;
  bounding_polygon_s3_ref?: InputMaybe<SearchableStringFilterInput>;
  comment?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  downsampled_gps?: InputMaybe<SearchableFloatFilterInput>;
  end_time_iso?: InputMaybe<SearchableStringFilterInput>;
  end_timestamp?: InputMaybe<SearchableIntFilterInput>;
  frequency?: InputMaybe<SearchableFloatFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  is_unified?: InputMaybe<SearchableBooleanFilterInput>;
  is_valid?: InputMaybe<SearchableBooleanFilterInput>;
  machine_id?: InputMaybe<SearchableStringFilterInput>;
  metadata?: InputMaybe<SearchableStringFilterInput>;
  mount_method?: InputMaybe<SearchableStringFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableScanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableScanFilterInput>>>;
  original_sensor_types?: InputMaybe<SearchableStringFilterInput>;
  part_index?: InputMaybe<SearchableIntFilterInput>;
  polygon_index?: InputMaybe<SearchableIntFilterInput>;
  s3_preloaded_refs?: InputMaybe<SearchableStringFilterInput>;
  s3_raw_ref?: InputMaybe<SearchableStringFilterInput>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  scanMonitoring_stepsId?: InputMaybe<SearchableIdFilterInput>;
  scanSource_scansId?: InputMaybe<SearchableIdFilterInput>;
  scan_type?: InputMaybe<SearchableStringFilterInput>;
  sensorScansId?: InputMaybe<SearchableIdFilterInput>;
  sensor_type?: InputMaybe<SearchableStringFilterInput>;
  siteScansId?: InputMaybe<SearchableIdFilterInput>;
  snake_index?: InputMaybe<SearchableIntFilterInput>;
  start_time_iso?: InputMaybe<SearchableStringFilterInput>;
  start_timestamp?: InputMaybe<SearchableIntFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  target_info?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableScanSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableScanSortableFields>;
};

export enum SearchableScanSortableFields {
  BoundingPolygonS3Ref = 'bounding_polygon_s3_ref',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  DownsampledGps = 'downsampled_gps',
  EndTimeIso = 'end_time_iso',
  EndTimestamp = 'end_timestamp',
  Frequency = 'frequency',
  Id = 'id',
  IsUnified = 'is_unified',
  IsValid = 'is_valid',
  MachineId = 'machine_id',
  Metadata = 'metadata',
  MountMethod = 'mount_method',
  Name = 'name',
  OriginalSensorTypes = 'original_sensor_types',
  PartIndex = 'part_index',
  PolygonIndex = 'polygon_index',
  S3PreloadedRefs = 's3_preloaded_refs',
  S3RawRef = 's3_raw_ref',
  S3Ref = 's3_ref',
  ScanMonitoringStepsId = 'scanMonitoring_stepsId',
  ScanSourceScansId = 'scanSource_scansId',
  ScanType = 'scan_type',
  SensorScansId = 'sensorScansId',
  SensorType = 'sensor_type',
  SiteScansId = 'siteScansId',
  SnakeIndex = 'snake_index',
  StartTimeIso = 'start_time_iso',
  StartTimestamp = 'start_timestamp',
  Status = 'status',
  TargetInfo = 'target_info',
  UpdatedAt = 'updatedAt'
}

export enum SearchableScanStepAggregateField {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  ErrorMessage = 'error_message',
  Id = 'id',
  ScanStepMonitorStepsId = 'scanStepMonitor_stepsId',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type SearchableScanStepAggregationInput = {
  field: SearchableScanStepAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableScanStepConnection = {
  __typename?: 'SearchableScanStepConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<ScanStep>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableScanStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableScanStepFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  end_time?: InputMaybe<SearchableIntFilterInput>;
  end_time_iso?: InputMaybe<SearchableStringFilterInput>;
  error_message?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableScanStepFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableScanStepFilterInput>>>;
  scanStepMonitor_stepsId?: InputMaybe<SearchableIdFilterInput>;
  start_time?: InputMaybe<SearchableIntFilterInput>;
  start_time_iso?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableScanStepSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableScanStepSortableFields>;
};

export enum SearchableScanStepSortableFields {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  ErrorMessage = 'error_message',
  Id = 'id',
  ScanStepMonitorStepsId = 'scanStepMonitor_stepsId',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export enum SearchableSensorAggregateField {
  CreatedAt = 'createdAt',
  Id = 'id',
  Model = 'model',
  SensorType = 'sensor_type',
  Serial = 'serial',
  UpdatedAt = 'updatedAt'
}

export type SearchableSensorAggregationInput = {
  field: SearchableSensorAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableSensorConnection = {
  __typename?: 'SearchableSensorConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Sensor>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableSensorFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableSensorFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  model?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableSensorFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableSensorFilterInput>>>;
  sensor_type?: InputMaybe<SearchableStringFilterInput>;
  serial?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableSensorSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableSensorSortableFields>;
};

export enum SearchableSensorSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Model = 'model',
  SensorType = 'sensor_type',
  Serial = 'serial',
  UpdatedAt = 'updatedAt'
}

export enum SearchableSiteAggregateField {
  CreatedAt = 'createdAt',
  CustomerSitesId = 'customerSitesId',
  GeoData = 'geo_data',
  Id = 'id',
  LocationSitesId = 'locationSitesId',
  Name = 'name',
  ProfilesOverride = 'profiles_override',
  ProjectedCrs = 'projected_crs',
  S3Ref = 's3_ref',
  SitePlannedEntitiesId = 'sitePlanned_entitiesId',
  SiteType = 'site_type',
  UpdatedAt = 'updatedAt'
}

export type SearchableSiteAggregationInput = {
  field: SearchableSiteAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableSiteConnection = {
  __typename?: 'SearchableSiteConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Site>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableSiteFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableSiteFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  customerSitesId?: InputMaybe<SearchableIdFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  locationSitesId?: InputMaybe<SearchableIdFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableSiteFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableSiteFilterInput>>>;
  profiles_override?: InputMaybe<SearchableStringFilterInput>;
  projected_crs?: InputMaybe<SearchableStringFilterInput>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  sitePlanned_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  site_type?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableSiteSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableSiteSortableFields>;
};

export enum SearchableSiteSortableFields {
  CreatedAt = 'createdAt',
  CustomerSitesId = 'customerSitesId',
  GeoData = 'geo_data',
  Id = 'id',
  LocationSitesId = 'locationSitesId',
  Name = 'name',
  ProfilesOverride = 'profiles_override',
  ProjectedCrs = 'projected_crs',
  S3Ref = 's3_ref',
  SitePlannedEntitiesId = 'sitePlanned_entitiesId',
  SiteType = 'site_type',
  UpdatedAt = 'updatedAt'
}

export enum SearchableSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type SearchableStringFilterInput = {
  eq?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  match?: InputMaybe<Scalars['String']>;
  matchPhrase?: InputMaybe<Scalars['String']>;
  matchPhrasePrefix?: InputMaybe<Scalars['String']>;
  multiMatch?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regexp?: InputMaybe<Scalars['String']>;
  wildcard?: InputMaybe<Scalars['String']>;
};

export enum SearchableTargetEntityAggregateField {
  AppName = 'app_name',
  AppVersion = 'app_version',
  Classification = 'classification',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  CreationSource = 'creation_source',
  CreationTimeIso = 'creation_time_iso',
  CreationTimestamp = 'creation_timestamp',
  DataSource = 'data_source',
  DisplayParams = 'display_params',
  GeoData = 'geo_data',
  Id = 'id',
  IsValid = 'is_valid',
  Name = 'name',
  S3Ref = 's3_ref',
  ScanTargetEntitiesId = 'scanTarget_entitiesId',
  SensorModel = 'sensor_model',
  SiteTargetEntitiesId = 'siteTarget_entitiesId',
  TargetType = 'target_type',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export type SearchableTargetEntityAggregationInput = {
  field: SearchableTargetEntityAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableTargetEntityConnection = {
  __typename?: 'SearchableTargetEntityConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<TargetEntity>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableTargetEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableTargetEntityFilterInput>>>;
  app_name?: InputMaybe<SearchableStringFilterInput>;
  app_version?: InputMaybe<SearchableStringFilterInput>;
  classification?: InputMaybe<SearchableStringFilterInput>;
  comment?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  creation_source?: InputMaybe<SearchableStringFilterInput>;
  creation_time_iso?: InputMaybe<SearchableStringFilterInput>;
  creation_timestamp?: InputMaybe<SearchableIntFilterInput>;
  data_source?: InputMaybe<SearchableStringFilterInput>;
  display_params?: InputMaybe<SearchableStringFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  is_valid?: InputMaybe<SearchableBooleanFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableTargetEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableTargetEntityFilterInput>>>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  scanTarget_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  sensor_model?: InputMaybe<SearchableStringFilterInput>;
  siteTarget_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  target_type?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  user?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableTargetEntitySortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableTargetEntitySortableFields>;
};

export enum SearchableTargetEntitySortableFields {
  AppName = 'app_name',
  AppVersion = 'app_version',
  Classification = 'classification',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  CreationSource = 'creation_source',
  CreationTimeIso = 'creation_time_iso',
  CreationTimestamp = 'creation_timestamp',
  DataSource = 'data_source',
  DisplayParams = 'display_params',
  GeoData = 'geo_data',
  Id = 'id',
  IsValid = 'is_valid',
  Name = 'name',
  S3Ref = 's3_ref',
  ScanTargetEntitiesId = 'scanTarget_entitiesId',
  SensorModel = 'sensor_model',
  SiteTargetEntitiesId = 'siteTarget_entitiesId',
  TargetType = 'target_type',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export enum SearchableTransmitterAggregateField {
  AssetId = 'asset_id',
  CreatedAt = 'createdAt',
  Id = 'id',
  Serial = 'serial',
  UpdatedAt = 'updatedAt'
}

export type SearchableTransmitterAggregationInput = {
  field: SearchableTransmitterAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableTransmitterConnection = {
  __typename?: 'SearchableTransmitterConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Transmitter>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableTransmitterFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableTransmitterFilterInput>>>;
  asset_id?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableTransmitterFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableTransmitterFilterInput>>>;
  serial?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export enum SearchableTransmitterSetupAggregateField {
  CreatedAt = 'createdAt',
  Frequency = 'frequency',
  GeoData = 'geo_data',
  Id = 'id',
  IsValid = 'is_valid',
  Method = 'method',
  S3Ref = 's3_ref',
  ScanTransmitterSetupsId = 'scanTransmitter_setupsId',
  SiteTransmitterSetupsId = 'siteTransmitter_setupsId',
  Snr = 'snr',
  Source = 'source',
  Tags = 'tags',
  TransmitterSetupTransmitterId = 'transmitterSetupTransmitterId',
  UpdatedAt = 'updatedAt'
}

export type SearchableTransmitterSetupAggregationInput = {
  field: SearchableTransmitterSetupAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableTransmitterSetupConnection = {
  __typename?: 'SearchableTransmitterSetupConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<TransmitterSetup>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableTransmitterSetupFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableTransmitterSetupFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  frequency?: InputMaybe<SearchableFloatFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  is_valid?: InputMaybe<SearchableBooleanFilterInput>;
  method?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableTransmitterSetupFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableTransmitterSetupFilterInput>>>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  scanTransmitter_setupsId?: InputMaybe<SearchableIdFilterInput>;
  siteTransmitter_setupsId?: InputMaybe<SearchableIdFilterInput>;
  snr?: InputMaybe<SearchableFloatFilterInput>;
  source?: InputMaybe<SearchableStringFilterInput>;
  tags?: InputMaybe<SearchableStringFilterInput>;
  transmitterSetupTransmitterId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableTransmitterSetupSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableTransmitterSetupSortableFields>;
};

export enum SearchableTransmitterSetupSortableFields {
  CreatedAt = 'createdAt',
  Frequency = 'frequency',
  GeoData = 'geo_data',
  Id = 'id',
  IsValid = 'is_valid',
  Method = 'method',
  S3Ref = 's3_ref',
  ScanTransmitterSetupsId = 'scanTransmitter_setupsId',
  SiteTransmitterSetupsId = 'siteTransmitter_setupsId',
  Snr = 'snr',
  Source = 'source',
  Tags = 'tags',
  TransmitterSetupTransmitterId = 'transmitterSetupTransmitterId',
  UpdatedAt = 'updatedAt'
}

export type SearchableTransmitterSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableTransmitterSortableFields>;
};

export enum SearchableTransmitterSortableFields {
  AssetId = 'asset_id',
  CreatedAt = 'createdAt',
  Id = 'id',
  Serial = 'serial',
  UpdatedAt = 'updatedAt'
}

export enum SearchableTravelCourseAggregateField {
  CreatedAt = 'createdAt',
  GeoData = 'geo_data',
  GpsFixedRatio = 'gps_fixed_ratio',
  Id = 'id',
  IsValid = 'is_valid',
  S3Ref = 's3_ref',
  ScanTravelCoursesId = 'scanTravel_coursesId',
  SiteTravelCoursesId = 'siteTravel_coursesId',
  Tag = 'tag',
  UpdatedAt = 'updatedAt'
}

export type SearchableTravelCourseAggregationInput = {
  field: SearchableTravelCourseAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableTravelCourseConnection = {
  __typename?: 'SearchableTravelCourseConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<TravelCourse>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableTravelCourseFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableTravelCourseFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  gps_fixed_ratio?: InputMaybe<SearchableFloatFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  is_valid?: InputMaybe<SearchableBooleanFilterInput>;
  not?: InputMaybe<SearchableTravelCourseFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableTravelCourseFilterInput>>>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  scanTravel_coursesId?: InputMaybe<SearchableIdFilterInput>;
  siteTravel_coursesId?: InputMaybe<SearchableIdFilterInput>;
  tag?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableTravelCourseSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableTravelCourseSortableFields>;
};

export enum SearchableTravelCourseSortableFields {
  CreatedAt = 'createdAt',
  GeoData = 'geo_data',
  GpsFixedRatio = 'gps_fixed_ratio',
  Id = 'id',
  IsValid = 'is_valid',
  S3Ref = 's3_ref',
  ScanTravelCoursesId = 'scanTravel_coursesId',
  SiteTravelCoursesId = 'siteTravel_coursesId',
  Tag = 'tag',
  UpdatedAt = 'updatedAt'
}

export enum SearchableUnifyTaskAggregateField {
  CreatedAt = 'createdAt',
  Frequencies = 'frequencies',
  GeoData = 'geo_data',
  Id = 'id',
  SiteUnifyTasksId = 'siteUnify_tasksId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserEmail = 'user_email'
}

export type SearchableUnifyTaskAggregationInput = {
  field: SearchableUnifyTaskAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableUnifyTaskConnection = {
  __typename?: 'SearchableUnifyTaskConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<UnifyTask>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableUnifyTaskFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableUnifyTaskFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  frequencies?: InputMaybe<SearchableFloatFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableUnifyTaskFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableUnifyTaskFilterInput>>>;
  siteUnify_tasksId?: InputMaybe<SearchableIdFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  user_email?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableUnifyTaskSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableUnifyTaskSortableFields>;
};

export enum SearchableUnifyTaskSortableFields {
  CreatedAt = 'createdAt',
  Frequencies = 'frequencies',
  GeoData = 'geo_data',
  Id = 'id',
  SiteUnifyTasksId = 'siteUnify_tasksId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserEmail = 'user_email'
}

export enum SearchableUploadStepAggregateField {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  ErrorMessage = 'error_message',
  Id = 'id',
  S3Ref = 's3_ref',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UploadStepMonitorStepsId = 'uploadStepMonitor_stepsId',
  UploadSpeed = 'upload_speed',
  Uploader = 'uploader'
}

export type SearchableUploadStepAggregationInput = {
  field: SearchableUploadStepAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableUploadStepConnection = {
  __typename?: 'SearchableUploadStepConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<UploadStep>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableUploadStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableUploadStepFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  end_time?: InputMaybe<SearchableIntFilterInput>;
  end_time_iso?: InputMaybe<SearchableStringFilterInput>;
  error_message?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableUploadStepFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableUploadStepFilterInput>>>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  start_time?: InputMaybe<SearchableIntFilterInput>;
  start_time_iso?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  uploadStepMonitor_stepsId?: InputMaybe<SearchableIdFilterInput>;
  upload_speed?: InputMaybe<SearchableFloatFilterInput>;
  uploader?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableUploadStepSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableUploadStepSortableFields>;
};

export enum SearchableUploadStepSortableFields {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  ErrorMessage = 'error_message',
  Id = 'id',
  S3Ref = 's3_ref',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UploadStepMonitorStepsId = 'uploadStepMonitor_stepsId',
  UploadSpeed = 'upload_speed',
  Uploader = 'uploader'
}

export enum SearchableUtilityFinderStepAggregateField {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  ErrorMessage = 'error_message',
  Id = 'id',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UtilityFinderStepMonitorStepsId = 'utilityFinderStepMonitor_stepsId'
}

export type SearchableUtilityFinderStepAggregationInput = {
  field: SearchableUtilityFinderStepAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableUtilityFinderStepConnection = {
  __typename?: 'SearchableUtilityFinderStepConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<UtilityFinderStep>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableUtilityFinderStepFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableUtilityFinderStepFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  end_time?: InputMaybe<SearchableIntFilterInput>;
  end_time_iso?: InputMaybe<SearchableStringFilterInput>;
  error_message?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableUtilityFinderStepFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableUtilityFinderStepFilterInput>>>;
  start_time?: InputMaybe<SearchableIntFilterInput>;
  start_time_iso?: InputMaybe<SearchableStringFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  utilityFinderStepMonitor_stepsId?: InputMaybe<SearchableIdFilterInput>;
};

export type SearchableUtilityFinderStepSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableUtilityFinderStepSortableFields>;
};

export enum SearchableUtilityFinderStepSortableFields {
  CreatedAt = 'createdAt',
  EndTime = 'end_time',
  EndTimeIso = 'end_time_iso',
  ErrorMessage = 'error_message',
  Id = 'id',
  StartTime = 'start_time',
  StartTimeIso = 'start_time_iso',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UtilityFinderStepMonitorStepsId = 'utilityFinderStepMonitor_stepsId'
}

export enum SearchableV2TargetEntityAggregateField {
  AppName = 'app_name',
  AppVersion = 'app_version',
  Classification = 'classification',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  CreationSource = 'creation_source',
  CreationTimeIso = 'creation_time_iso',
  CreationTimestamp = 'creation_timestamp',
  DataSource = 'data_source',
  DisplayParams = 'display_params',
  GeoData = 'geo_data',
  Id = 'id',
  IsValid = 'is_valid',
  Name = 'name',
  S3Ref = 's3_ref',
  ScanV2TargetEntitiesId = 'scanV2_target_entitiesId',
  SensorModel = 'sensor_model',
  SiteV2TargetEntitiesId = 'siteV2_target_entitiesId',
  TargetType = 'target_type',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export type SearchableV2TargetEntityAggregationInput = {
  field: SearchableV2TargetEntityAggregateField;
  name: Scalars['String'];
  type: SearchableAggregateType;
};

export type SearchableV2TargetEntityConnection = {
  __typename?: 'SearchableV2TargetEntityConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<V2TargetEntity>>;
  nextToken?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchableV2TargetEntityFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableV2TargetEntityFilterInput>>>;
  app_name?: InputMaybe<SearchableStringFilterInput>;
  app_version?: InputMaybe<SearchableStringFilterInput>;
  classification?: InputMaybe<SearchableStringFilterInput>;
  comment?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  creation_source?: InputMaybe<SearchableStringFilterInput>;
  creation_time_iso?: InputMaybe<SearchableStringFilterInput>;
  creation_timestamp?: InputMaybe<SearchableIntFilterInput>;
  data_source?: InputMaybe<SearchableStringFilterInput>;
  display_params?: InputMaybe<SearchableStringFilterInput>;
  geo_data?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  is_valid?: InputMaybe<SearchableBooleanFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableV2TargetEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableV2TargetEntityFilterInput>>>;
  s3_ref?: InputMaybe<SearchableStringFilterInput>;
  scanV2_target_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  sensor_model?: InputMaybe<SearchableStringFilterInput>;
  siteV2_target_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  target_type?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  user?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableV2TargetEntitySortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableV2TargetEntitySortableFields>;
};

export enum SearchableV2TargetEntitySortableFields {
  AppName = 'app_name',
  AppVersion = 'app_version',
  Classification = 'classification',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  CreationSource = 'creation_source',
  CreationTimeIso = 'creation_time_iso',
  CreationTimestamp = 'creation_timestamp',
  DataSource = 'data_source',
  DisplayParams = 'display_params',
  GeoData = 'geo_data',
  Id = 'id',
  IsValid = 'is_valid',
  Name = 'name',
  S3Ref = 's3_ref',
  ScanV2TargetEntitiesId = 'scanV2_target_entitiesId',
  SensorModel = 'sensor_model',
  SiteV2TargetEntitiesId = 'siteV2_target_entitiesId',
  TargetType = 'target_type',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export type Sensor = {
  __typename?: 'Sensor';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  model: Scalars['String'];
  scans?: Maybe<ModelScanConnection>;
  sensor_type: Scalars['String'];
  serial?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
};


export type SensorScansArgs = {
  filter?: InputMaybe<ModelScanFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type Site = {
  __typename?: 'Site';
  analyzed_entities?: Maybe<ModelAnalyzedEntityConnection>;
  createdAt: Scalars['AWSDateTime'];
  customer?: Maybe<Customer>;
  customerSitesId?: Maybe<Scalars['ID']>;
  geo_data?: Maybe<Scalars['AWSJSON']>;
  geoid_values?: Maybe<ModelGeoidValueConnection>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  locationSitesId?: Maybe<Scalars['ID']>;
  mapping_entities?: Maybe<ModelMappingEntityConnection>;
  name: Scalars['String'];
  planned_entities?: Maybe<PlannedEntities>;
  pois?: Maybe<ModelPoiEntityConnection>;
  profiles_override?: Maybe<Array<Maybe<Scalars['String']>>>;
  projected_crs?: Maybe<Scalars['String']>;
  reanalysis_requests?: Maybe<ModelReanalysisRequestConnection>;
  s3_ref?: Maybe<Scalars['String']>;
  scans?: Maybe<ModelScanConnection>;
  sitePlanned_entitiesId?: Maybe<Scalars['ID']>;
  site_depths?: Maybe<ModelScanDepthPointsConnection>;
  site_pipe_depths?: Maybe<ModelEntityDepthPointsConnection>;
  site_type?: Maybe<Scalars['String']>;
  target_entities?: Maybe<ModelTargetEntityConnection>;
  transmitter_setups?: Maybe<ModelTransmitterSetupConnection>;
  travel_courses?: Maybe<ModelTravelCourseConnection>;
  unify_tasks?: Maybe<ModelUnifyTaskConnection>;
  updatedAt: Scalars['AWSDateTime'];
  v2_target_entities?: Maybe<ModelV2TargetEntityConnection>;
};


export type SiteAnalyzed_EntitiesArgs = {
  filter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteGeoid_ValuesArgs = {
  filter?: InputMaybe<ModelGeoidValueFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteMapping_EntitiesArgs = {
  filter?: InputMaybe<ModelMappingEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SitePoisArgs = {
  filter?: InputMaybe<ModelPoiEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteReanalysis_RequestsArgs = {
  filter?: InputMaybe<ModelReanalysisRequestFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteScansArgs = {
  filter?: InputMaybe<ModelScanFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteSite_DepthsArgs = {
  filter?: InputMaybe<ModelScanDepthPointsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteSite_Pipe_DepthsArgs = {
  filter?: InputMaybe<ModelEntityDepthPointsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteTarget_EntitiesArgs = {
  filter?: InputMaybe<ModelTargetEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteTransmitter_SetupsArgs = {
  filter?: InputMaybe<ModelTransmitterSetupFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteTravel_CoursesArgs = {
  filter?: InputMaybe<ModelTravelCourseFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteUnify_TasksArgs = {
  filter?: InputMaybe<ModelUnifyTaskFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteV2_Target_EntitiesArgs = {
  filter?: InputMaybe<ModelV2TargetEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onCreateAlgoUtilityMetrics?: Maybe<AlgoUtilityMetrics>;
  onCreateAnalysisStep?: Maybe<AnalysisStep>;
  onCreateAnalyzedEntity?: Maybe<AnalyzedEntity>;
  onCreateComment?: Maybe<Comment>;
  onCreateCustomer?: Maybe<Customer>;
  onCreateEntityDepthPoints?: Maybe<EntityDepthPoints>;
  onCreateGeoidValue?: Maybe<GeoidValue>;
  onCreateLocation?: Maybe<Location>;
  onCreateManholeItem?: Maybe<ManholeItem>;
  onCreateMappingEntity?: Maybe<MappingEntity>;
  onCreateMonitorSteps?: Maybe<MonitorSteps>;
  onCreatePOIEntity?: Maybe<PoiEntity>;
  onCreatePipeItem?: Maybe<PipeItem>;
  onCreatePipelineRunInfo?: Maybe<PipelineRunInfo>;
  onCreatePipesqaPipe?: Maybe<PipesqaPipe>;
  onCreatePlannedEntities?: Maybe<PlannedEntities>;
  onCreatePlannedPolygon?: Maybe<PlannedPolygon>;
  onCreatePlannedTransmitter?: Maybe<PlannedTransmitter>;
  onCreatePreloadRunInfo?: Maybe<PreloadRunInfo>;
  onCreatePreloaderStep?: Maybe<PreloaderStep>;
  onCreateReanalysisRequest?: Maybe<ReanalysisRequest>;
  onCreateReanalysisRequestComment?: Maybe<ReanalysisRequestComment>;
  onCreateScan?: Maybe<Scan>;
  onCreateScanAccess?: Maybe<ScanAccess>;
  onCreateScanByHashedParameters?: Maybe<ScanByHashedParameters>;
  onCreateScanDepthPoints?: Maybe<ScanDepthPoints>;
  onCreateScanStep?: Maybe<ScanStep>;
  onCreateSensor?: Maybe<Sensor>;
  onCreateSite?: Maybe<Site>;
  onCreateTargetEntity?: Maybe<TargetEntity>;
  onCreateTasksScansConnection?: Maybe<TasksScansConnection>;
  onCreateTransmitter?: Maybe<Transmitter>;
  onCreateTransmitterSetup?: Maybe<TransmitterSetup>;
  onCreateTravelCourse?: Maybe<TravelCourse>;
  onCreateUnifyTask?: Maybe<UnifyTask>;
  onCreateUploadStep?: Maybe<UploadStep>;
  onCreateUtilityFinderStep?: Maybe<UtilityFinderStep>;
  onCreateV2TargetEntity?: Maybe<V2TargetEntity>;
  onDeleteAlgoUtilityMetrics?: Maybe<AlgoUtilityMetrics>;
  onDeleteAnalysisStep?: Maybe<AnalysisStep>;
  onDeleteAnalyzedEntity?: Maybe<AnalyzedEntity>;
  onDeleteComment?: Maybe<Comment>;
  onDeleteCustomer?: Maybe<Customer>;
  onDeleteEntityDepthPoints?: Maybe<EntityDepthPoints>;
  onDeleteGeoidValue?: Maybe<GeoidValue>;
  onDeleteLocation?: Maybe<Location>;
  onDeleteManholeItem?: Maybe<ManholeItem>;
  onDeleteMappingEntity?: Maybe<MappingEntity>;
  onDeleteMonitorSteps?: Maybe<MonitorSteps>;
  onDeletePOIEntity?: Maybe<PoiEntity>;
  onDeletePipeItem?: Maybe<PipeItem>;
  onDeletePipelineRunInfo?: Maybe<PipelineRunInfo>;
  onDeletePipesqaPipe?: Maybe<PipesqaPipe>;
  onDeletePlannedEntities?: Maybe<PlannedEntities>;
  onDeletePlannedPolygon?: Maybe<PlannedPolygon>;
  onDeletePlannedTransmitter?: Maybe<PlannedTransmitter>;
  onDeletePreloadRunInfo?: Maybe<PreloadRunInfo>;
  onDeletePreloaderStep?: Maybe<PreloaderStep>;
  onDeleteReanalysisRequest?: Maybe<ReanalysisRequest>;
  onDeleteReanalysisRequestComment?: Maybe<ReanalysisRequestComment>;
  onDeleteScan?: Maybe<Scan>;
  onDeleteScanAccess?: Maybe<ScanAccess>;
  onDeleteScanByHashedParameters?: Maybe<ScanByHashedParameters>;
  onDeleteScanDepthPoints?: Maybe<ScanDepthPoints>;
  onDeleteScanStep?: Maybe<ScanStep>;
  onDeleteSensor?: Maybe<Sensor>;
  onDeleteSite?: Maybe<Site>;
  onDeleteTargetEntity?: Maybe<TargetEntity>;
  onDeleteTasksScansConnection?: Maybe<TasksScansConnection>;
  onDeleteTransmitter?: Maybe<Transmitter>;
  onDeleteTransmitterSetup?: Maybe<TransmitterSetup>;
  onDeleteTravelCourse?: Maybe<TravelCourse>;
  onDeleteUnifyTask?: Maybe<UnifyTask>;
  onDeleteUploadStep?: Maybe<UploadStep>;
  onDeleteUtilityFinderStep?: Maybe<UtilityFinderStep>;
  onDeleteV2TargetEntity?: Maybe<V2TargetEntity>;
  onUpdateAlgoUtilityMetrics?: Maybe<AlgoUtilityMetrics>;
  onUpdateAnalysisStep?: Maybe<AnalysisStep>;
  onUpdateAnalyzedEntity?: Maybe<AnalyzedEntity>;
  onUpdateComment?: Maybe<Comment>;
  onUpdateCustomer?: Maybe<Customer>;
  onUpdateEntityDepthPoints?: Maybe<EntityDepthPoints>;
  onUpdateGeoidValue?: Maybe<GeoidValue>;
  onUpdateLocation?: Maybe<Location>;
  onUpdateManholeItem?: Maybe<ManholeItem>;
  onUpdateMappingEntity?: Maybe<MappingEntity>;
  onUpdateMonitorSteps?: Maybe<MonitorSteps>;
  onUpdatePOIEntity?: Maybe<PoiEntity>;
  onUpdatePipeItem?: Maybe<PipeItem>;
  onUpdatePipelineRunInfo?: Maybe<PipelineRunInfo>;
  onUpdatePipesqaPipe?: Maybe<PipesqaPipe>;
  onUpdatePlannedEntities?: Maybe<PlannedEntities>;
  onUpdatePlannedPolygon?: Maybe<PlannedPolygon>;
  onUpdatePlannedTransmitter?: Maybe<PlannedTransmitter>;
  onUpdatePreloadRunInfo?: Maybe<PreloadRunInfo>;
  onUpdatePreloaderStep?: Maybe<PreloaderStep>;
  onUpdateReanalysisRequest?: Maybe<ReanalysisRequest>;
  onUpdateReanalysisRequestComment?: Maybe<ReanalysisRequestComment>;
  onUpdateScan?: Maybe<Scan>;
  onUpdateScanAccess?: Maybe<ScanAccess>;
  onUpdateScanByHashedParameters?: Maybe<ScanByHashedParameters>;
  onUpdateScanDepthPoints?: Maybe<ScanDepthPoints>;
  onUpdateScanStep?: Maybe<ScanStep>;
  onUpdateSensor?: Maybe<Sensor>;
  onUpdateSite?: Maybe<Site>;
  onUpdateTargetEntity?: Maybe<TargetEntity>;
  onUpdateTasksScansConnection?: Maybe<TasksScansConnection>;
  onUpdateTransmitter?: Maybe<Transmitter>;
  onUpdateTransmitterSetup?: Maybe<TransmitterSetup>;
  onUpdateTravelCourse?: Maybe<TravelCourse>;
  onUpdateUnifyTask?: Maybe<UnifyTask>;
  onUpdateUploadStep?: Maybe<UploadStep>;
  onUpdateUtilityFinderStep?: Maybe<UtilityFinderStep>;
  onUpdateV2TargetEntity?: Maybe<V2TargetEntity>;
};


export type SubscriptionOnCreateAlgoUtilityMetricsArgs = {
  filter?: InputMaybe<ModelSubscriptionAlgoUtilityMetricsFilterInput>;
};


export type SubscriptionOnCreateAnalysisStepArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalysisStepFilterInput>;
};


export type SubscriptionOnCreateAnalyzedEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>;
};


export type SubscriptionOnCreateCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionCommentFilterInput>;
};


export type SubscriptionOnCreateCustomerArgs = {
  filter?: InputMaybe<ModelSubscriptionCustomerFilterInput>;
};


export type SubscriptionOnCreateEntityDepthPointsArgs = {
  filter?: InputMaybe<ModelSubscriptionEntityDepthPointsFilterInput>;
};


export type SubscriptionOnCreateGeoidValueArgs = {
  filter?: InputMaybe<ModelSubscriptionGeoidValueFilterInput>;
};


export type SubscriptionOnCreateLocationArgs = {
  filter?: InputMaybe<ModelSubscriptionLocationFilterInput>;
};


export type SubscriptionOnCreateManholeItemArgs = {
  filter?: InputMaybe<ModelSubscriptionManholeItemFilterInput>;
};


export type SubscriptionOnCreateMappingEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionMappingEntityFilterInput>;
};


export type SubscriptionOnCreateMonitorStepsArgs = {
  filter?: InputMaybe<ModelSubscriptionMonitorStepsFilterInput>;
};


export type SubscriptionOnCreatePoiEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionPoiEntityFilterInput>;
};


export type SubscriptionOnCreatePipeItemArgs = {
  filter?: InputMaybe<ModelSubscriptionPipeItemFilterInput>;
};


export type SubscriptionOnCreatePipelineRunInfoArgs = {
  filter?: InputMaybe<ModelSubscriptionPipelineRunInfoFilterInput>;
};


export type SubscriptionOnCreatePipesqaPipeArgs = {
  filter?: InputMaybe<ModelSubscriptionPipesqaPipeFilterInput>;
};


export type SubscriptionOnCreatePlannedEntitiesArgs = {
  filter?: InputMaybe<ModelSubscriptionPlannedEntitiesFilterInput>;
};


export type SubscriptionOnCreatePlannedPolygonArgs = {
  filter?: InputMaybe<ModelSubscriptionPlannedPolygonFilterInput>;
};


export type SubscriptionOnCreatePlannedTransmitterArgs = {
  filter?: InputMaybe<ModelSubscriptionPlannedTransmitterFilterInput>;
};


export type SubscriptionOnCreatePreloadRunInfoArgs = {
  filter?: InputMaybe<ModelSubscriptionPreloadRunInfoFilterInput>;
};


export type SubscriptionOnCreatePreloaderStepArgs = {
  filter?: InputMaybe<ModelSubscriptionPreloaderStepFilterInput>;
};


export type SubscriptionOnCreateReanalysisRequestArgs = {
  filter?: InputMaybe<ModelSubscriptionReanalysisRequestFilterInput>;
};


export type SubscriptionOnCreateReanalysisRequestCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionReanalysisRequestCommentFilterInput>;
};


export type SubscriptionOnCreateScanArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFilterInput>;
};


export type SubscriptionOnCreateScanAccessArgs = {
  filter?: InputMaybe<ModelSubscriptionScanAccessFilterInput>;
};


export type SubscriptionOnCreateScanByHashedParametersArgs = {
  filter?: InputMaybe<ModelSubscriptionScanByHashedParametersFilterInput>;
};


export type SubscriptionOnCreateScanDepthPointsArgs = {
  filter?: InputMaybe<ModelSubscriptionScanDepthPointsFilterInput>;
};


export type SubscriptionOnCreateScanStepArgs = {
  filter?: InputMaybe<ModelSubscriptionScanStepFilterInput>;
};


export type SubscriptionOnCreateSensorArgs = {
  filter?: InputMaybe<ModelSubscriptionSensorFilterInput>;
};


export type SubscriptionOnCreateSiteArgs = {
  filter?: InputMaybe<ModelSubscriptionSiteFilterInput>;
};


export type SubscriptionOnCreateTargetEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionTargetEntityFilterInput>;
};


export type SubscriptionOnCreateTasksScansConnectionArgs = {
  filter?: InputMaybe<ModelSubscriptionTasksScansConnectionFilterInput>;
};


export type SubscriptionOnCreateTransmitterArgs = {
  filter?: InputMaybe<ModelSubscriptionTransmitterFilterInput>;
};


export type SubscriptionOnCreateTransmitterSetupArgs = {
  filter?: InputMaybe<ModelSubscriptionTransmitterSetupFilterInput>;
};


export type SubscriptionOnCreateTravelCourseArgs = {
  filter?: InputMaybe<ModelSubscriptionTravelCourseFilterInput>;
};


export type SubscriptionOnCreateUnifyTaskArgs = {
  filter?: InputMaybe<ModelSubscriptionUnifyTaskFilterInput>;
};


export type SubscriptionOnCreateUploadStepArgs = {
  filter?: InputMaybe<ModelSubscriptionUploadStepFilterInput>;
};


export type SubscriptionOnCreateUtilityFinderStepArgs = {
  filter?: InputMaybe<ModelSubscriptionUtilityFinderStepFilterInput>;
};


export type SubscriptionOnCreateV2TargetEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionV2TargetEntityFilterInput>;
};


export type SubscriptionOnDeleteAlgoUtilityMetricsArgs = {
  filter?: InputMaybe<ModelSubscriptionAlgoUtilityMetricsFilterInput>;
};


export type SubscriptionOnDeleteAnalysisStepArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalysisStepFilterInput>;
};


export type SubscriptionOnDeleteAnalyzedEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>;
};


export type SubscriptionOnDeleteCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionCommentFilterInput>;
};


export type SubscriptionOnDeleteCustomerArgs = {
  filter?: InputMaybe<ModelSubscriptionCustomerFilterInput>;
};


export type SubscriptionOnDeleteEntityDepthPointsArgs = {
  filter?: InputMaybe<ModelSubscriptionEntityDepthPointsFilterInput>;
};


export type SubscriptionOnDeleteGeoidValueArgs = {
  filter?: InputMaybe<ModelSubscriptionGeoidValueFilterInput>;
};


export type SubscriptionOnDeleteLocationArgs = {
  filter?: InputMaybe<ModelSubscriptionLocationFilterInput>;
};


export type SubscriptionOnDeleteManholeItemArgs = {
  filter?: InputMaybe<ModelSubscriptionManholeItemFilterInput>;
};


export type SubscriptionOnDeleteMappingEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionMappingEntityFilterInput>;
};


export type SubscriptionOnDeleteMonitorStepsArgs = {
  filter?: InputMaybe<ModelSubscriptionMonitorStepsFilterInput>;
};


export type SubscriptionOnDeletePoiEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionPoiEntityFilterInput>;
};


export type SubscriptionOnDeletePipeItemArgs = {
  filter?: InputMaybe<ModelSubscriptionPipeItemFilterInput>;
};


export type SubscriptionOnDeletePipelineRunInfoArgs = {
  filter?: InputMaybe<ModelSubscriptionPipelineRunInfoFilterInput>;
};


export type SubscriptionOnDeletePipesqaPipeArgs = {
  filter?: InputMaybe<ModelSubscriptionPipesqaPipeFilterInput>;
};


export type SubscriptionOnDeletePlannedEntitiesArgs = {
  filter?: InputMaybe<ModelSubscriptionPlannedEntitiesFilterInput>;
};


export type SubscriptionOnDeletePlannedPolygonArgs = {
  filter?: InputMaybe<ModelSubscriptionPlannedPolygonFilterInput>;
};


export type SubscriptionOnDeletePlannedTransmitterArgs = {
  filter?: InputMaybe<ModelSubscriptionPlannedTransmitterFilterInput>;
};


export type SubscriptionOnDeletePreloadRunInfoArgs = {
  filter?: InputMaybe<ModelSubscriptionPreloadRunInfoFilterInput>;
};


export type SubscriptionOnDeletePreloaderStepArgs = {
  filter?: InputMaybe<ModelSubscriptionPreloaderStepFilterInput>;
};


export type SubscriptionOnDeleteReanalysisRequestArgs = {
  filter?: InputMaybe<ModelSubscriptionReanalysisRequestFilterInput>;
};


export type SubscriptionOnDeleteReanalysisRequestCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionReanalysisRequestCommentFilterInput>;
};


export type SubscriptionOnDeleteScanArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFilterInput>;
};


export type SubscriptionOnDeleteScanAccessArgs = {
  filter?: InputMaybe<ModelSubscriptionScanAccessFilterInput>;
};


export type SubscriptionOnDeleteScanByHashedParametersArgs = {
  filter?: InputMaybe<ModelSubscriptionScanByHashedParametersFilterInput>;
};


export type SubscriptionOnDeleteScanDepthPointsArgs = {
  filter?: InputMaybe<ModelSubscriptionScanDepthPointsFilterInput>;
};


export type SubscriptionOnDeleteScanStepArgs = {
  filter?: InputMaybe<ModelSubscriptionScanStepFilterInput>;
};


export type SubscriptionOnDeleteSensorArgs = {
  filter?: InputMaybe<ModelSubscriptionSensorFilterInput>;
};


export type SubscriptionOnDeleteSiteArgs = {
  filter?: InputMaybe<ModelSubscriptionSiteFilterInput>;
};


export type SubscriptionOnDeleteTargetEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionTargetEntityFilterInput>;
};


export type SubscriptionOnDeleteTasksScansConnectionArgs = {
  filter?: InputMaybe<ModelSubscriptionTasksScansConnectionFilterInput>;
};


export type SubscriptionOnDeleteTransmitterArgs = {
  filter?: InputMaybe<ModelSubscriptionTransmitterFilterInput>;
};


export type SubscriptionOnDeleteTransmitterSetupArgs = {
  filter?: InputMaybe<ModelSubscriptionTransmitterSetupFilterInput>;
};


export type SubscriptionOnDeleteTravelCourseArgs = {
  filter?: InputMaybe<ModelSubscriptionTravelCourseFilterInput>;
};


export type SubscriptionOnDeleteUnifyTaskArgs = {
  filter?: InputMaybe<ModelSubscriptionUnifyTaskFilterInput>;
};


export type SubscriptionOnDeleteUploadStepArgs = {
  filter?: InputMaybe<ModelSubscriptionUploadStepFilterInput>;
};


export type SubscriptionOnDeleteUtilityFinderStepArgs = {
  filter?: InputMaybe<ModelSubscriptionUtilityFinderStepFilterInput>;
};


export type SubscriptionOnDeleteV2TargetEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionV2TargetEntityFilterInput>;
};


export type SubscriptionOnUpdateAlgoUtilityMetricsArgs = {
  filter?: InputMaybe<ModelSubscriptionAlgoUtilityMetricsFilterInput>;
};


export type SubscriptionOnUpdateAnalysisStepArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalysisStepFilterInput>;
};


export type SubscriptionOnUpdateAnalyzedEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>;
};


export type SubscriptionOnUpdateCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionCommentFilterInput>;
};


export type SubscriptionOnUpdateCustomerArgs = {
  filter?: InputMaybe<ModelSubscriptionCustomerFilterInput>;
};


export type SubscriptionOnUpdateEntityDepthPointsArgs = {
  filter?: InputMaybe<ModelSubscriptionEntityDepthPointsFilterInput>;
};


export type SubscriptionOnUpdateGeoidValueArgs = {
  filter?: InputMaybe<ModelSubscriptionGeoidValueFilterInput>;
};


export type SubscriptionOnUpdateLocationArgs = {
  filter?: InputMaybe<ModelSubscriptionLocationFilterInput>;
};


export type SubscriptionOnUpdateManholeItemArgs = {
  filter?: InputMaybe<ModelSubscriptionManholeItemFilterInput>;
};


export type SubscriptionOnUpdateMappingEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionMappingEntityFilterInput>;
};


export type SubscriptionOnUpdateMonitorStepsArgs = {
  filter?: InputMaybe<ModelSubscriptionMonitorStepsFilterInput>;
};


export type SubscriptionOnUpdatePoiEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionPoiEntityFilterInput>;
};


export type SubscriptionOnUpdatePipeItemArgs = {
  filter?: InputMaybe<ModelSubscriptionPipeItemFilterInput>;
};


export type SubscriptionOnUpdatePipelineRunInfoArgs = {
  filter?: InputMaybe<ModelSubscriptionPipelineRunInfoFilterInput>;
};


export type SubscriptionOnUpdatePipesqaPipeArgs = {
  filter?: InputMaybe<ModelSubscriptionPipesqaPipeFilterInput>;
};


export type SubscriptionOnUpdatePlannedEntitiesArgs = {
  filter?: InputMaybe<ModelSubscriptionPlannedEntitiesFilterInput>;
};


export type SubscriptionOnUpdatePlannedPolygonArgs = {
  filter?: InputMaybe<ModelSubscriptionPlannedPolygonFilterInput>;
};


export type SubscriptionOnUpdatePlannedTransmitterArgs = {
  filter?: InputMaybe<ModelSubscriptionPlannedTransmitterFilterInput>;
};


export type SubscriptionOnUpdatePreloadRunInfoArgs = {
  filter?: InputMaybe<ModelSubscriptionPreloadRunInfoFilterInput>;
};


export type SubscriptionOnUpdatePreloaderStepArgs = {
  filter?: InputMaybe<ModelSubscriptionPreloaderStepFilterInput>;
};


export type SubscriptionOnUpdateReanalysisRequestArgs = {
  filter?: InputMaybe<ModelSubscriptionReanalysisRequestFilterInput>;
};


export type SubscriptionOnUpdateReanalysisRequestCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionReanalysisRequestCommentFilterInput>;
};


export type SubscriptionOnUpdateScanArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFilterInput>;
};


export type SubscriptionOnUpdateScanAccessArgs = {
  filter?: InputMaybe<ModelSubscriptionScanAccessFilterInput>;
};


export type SubscriptionOnUpdateScanByHashedParametersArgs = {
  filter?: InputMaybe<ModelSubscriptionScanByHashedParametersFilterInput>;
};


export type SubscriptionOnUpdateScanDepthPointsArgs = {
  filter?: InputMaybe<ModelSubscriptionScanDepthPointsFilterInput>;
};


export type SubscriptionOnUpdateScanStepArgs = {
  filter?: InputMaybe<ModelSubscriptionScanStepFilterInput>;
};


export type SubscriptionOnUpdateSensorArgs = {
  filter?: InputMaybe<ModelSubscriptionSensorFilterInput>;
};


export type SubscriptionOnUpdateSiteArgs = {
  filter?: InputMaybe<ModelSubscriptionSiteFilterInput>;
};


export type SubscriptionOnUpdateTargetEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionTargetEntityFilterInput>;
};


export type SubscriptionOnUpdateTasksScansConnectionArgs = {
  filter?: InputMaybe<ModelSubscriptionTasksScansConnectionFilterInput>;
};


export type SubscriptionOnUpdateTransmitterArgs = {
  filter?: InputMaybe<ModelSubscriptionTransmitterFilterInput>;
};


export type SubscriptionOnUpdateTransmitterSetupArgs = {
  filter?: InputMaybe<ModelSubscriptionTransmitterSetupFilterInput>;
};


export type SubscriptionOnUpdateTravelCourseArgs = {
  filter?: InputMaybe<ModelSubscriptionTravelCourseFilterInput>;
};


export type SubscriptionOnUpdateUnifyTaskArgs = {
  filter?: InputMaybe<ModelSubscriptionUnifyTaskFilterInput>;
};


export type SubscriptionOnUpdateUploadStepArgs = {
  filter?: InputMaybe<ModelSubscriptionUploadStepFilterInput>;
};


export type SubscriptionOnUpdateUtilityFinderStepArgs = {
  filter?: InputMaybe<ModelSubscriptionUtilityFinderStepFilterInput>;
};


export type SubscriptionOnUpdateV2TargetEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionV2TargetEntityFilterInput>;
};

export type TargetEntity = {
  __typename?: 'TargetEntity';
  app_name?: Maybe<Scalars['String']>;
  app_version?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  creation_source?: Maybe<Scalars['String']>;
  creation_time_iso?: Maybe<Scalars['AWSDateTime']>;
  creation_timestamp?: Maybe<Scalars['AWSTimestamp']>;
  data_source?: Maybe<Scalars['String']>;
  display_params?: Maybe<Scalars['AWSJSON']>;
  geo_data: Scalars['AWSJSON'];
  id: Scalars['ID'];
  is_valid?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  s3_ref?: Maybe<Scalars['String']>;
  scan?: Maybe<Scan>;
  scanTarget_entitiesId?: Maybe<Scalars['ID']>;
  sensor_model?: Maybe<Scalars['String']>;
  site: Site;
  siteTarget_entitiesId?: Maybe<Scalars['ID']>;
  target_type: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  user?: Maybe<Scalars['String']>;
};

export type TasksScansConnection = {
  __typename?: 'TasksScansConnection';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  scan: Scan;
  scanId: Scalars['ID'];
  unifyTask: UnifyTask;
  unifyTaskId: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};

export type Transmitter = {
  __typename?: 'Transmitter';
  asset_id?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  serial: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type TransmitterSetup = {
  __typename?: 'TransmitterSetup';
  createdAt: Scalars['AWSDateTime'];
  frequency: Scalars['Float'];
  geo_data: Scalars['AWSJSON'];
  id: Scalars['ID'];
  is_valid?: Maybe<Scalars['Boolean']>;
  method: Scalars['String'];
  reanalysis_requests?: Maybe<ModelReanalysisRequestConnection>;
  s3_ref?: Maybe<Scalars['String']>;
  scan?: Maybe<Scan>;
  scanTransmitter_setupsId?: Maybe<Scalars['ID']>;
  siteTransmitter_setupsId?: Maybe<Scalars['ID']>;
  snr?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  transmitter: Transmitter;
  transmitterSetupTransmitterId: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};


export type TransmitterSetupReanalysis_RequestsArgs = {
  filter?: InputMaybe<ModelReanalysisRequestFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type TravelCourse = {
  __typename?: 'TravelCourse';
  createdAt: Scalars['AWSDateTime'];
  geo_data: Scalars['AWSJSON'];
  gps_fixed_ratio?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  is_valid?: Maybe<Scalars['Boolean']>;
  reanalysis_requests?: Maybe<ModelReanalysisRequestConnection>;
  s3_ref?: Maybe<Scalars['String']>;
  scan?: Maybe<Scan>;
  scanTravel_coursesId?: Maybe<Scalars['ID']>;
  siteTravel_coursesId?: Maybe<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
};


export type TravelCourseReanalysis_RequestsArgs = {
  filter?: InputMaybe<ModelReanalysisRequestFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type UnifyTask = {
  __typename?: 'UnifyTask';
  createdAt: Scalars['AWSDateTime'];
  frequencies?: Maybe<Array<Maybe<Scalars['Float']>>>;
  geo_data: Scalars['AWSJSON'];
  id: Scalars['ID'];
  scansConnection?: Maybe<ModelTasksScansConnectionConnection>;
  site: Site;
  siteUnify_tasksId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
  user_email?: Maybe<Scalars['String']>;
};


export type UnifyTaskScansConnectionArgs = {
  filter?: InputMaybe<ModelTasksScansConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type UpdateAlgoUtilityMetricsInput = {
  algoUtilityMetricsAnalyzed_entityId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  metrics?: InputMaybe<Scalars['AWSJSON']>;
};

export type UpdateAnalysisStepInput = {
  access_time?: InputMaybe<Scalars['AWSTimestamp']>;
  access_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  analysisStepMonitor_stepsId?: InputMaybe<Scalars['ID']>;
  error_message?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  is_new_found_entity?: InputMaybe<Scalars['Boolean']>;
  is_scan_opened?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UpdateAnalyzedEntityInput = {
  analysis_confidence?: InputMaybe<Scalars['Float']>;
  analyzedEntityAlgo_utility_metricsId?: InputMaybe<Scalars['ID']>;
  analyzedEntityPipesqa_viewId?: InputMaybe<Scalars['ID']>;
  app_name?: InputMaybe<Scalars['String']>;
  app_version?: InputMaybe<Scalars['String']>;
  auto_confidence?: InputMaybe<Scalars['Float']>;
  classification?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  creation_source?: InputMaybe<Scalars['String']>;
  creation_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  creation_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  data_source?: InputMaybe<Scalars['String']>;
  detailed_geo_data?: InputMaybe<Scalars['AWSJSON']>;
  frequency?: InputMaybe<Scalars['Float']>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  is_valid?: InputMaybe<Scalars['Boolean']>;
  magnetization?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  s3_analysis_ref?: InputMaybe<Scalars['String']>;
  s3_epls_ref?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanAnalyzed_entitiesId?: InputMaybe<Scalars['ID']>;
  sensor_model?: InputMaybe<Scalars['String']>;
  siteAnalyzed_entitiesId?: InputMaybe<Scalars['ID']>;
  snr?: InputMaybe<Scalars['Float']>;
  user?: InputMaybe<Scalars['String']>;
};

export type UpdateCommentInput = {
  changed_by?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  pipesqaPipeCommentsId?: InputMaybe<Scalars['ID']>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateCustomerInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateEntityDepthPointsInput = {
  algorithm?: InputMaybe<Scalars['String']>;
  analyzedEntityDepth_pointsId?: InputMaybe<Scalars['ID']>;
  depthsFromGround?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  ellipsoidHeights?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  ellipsoidHeightsMask?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  hadError?: InputMaybe<Scalars['Boolean']>;
  hasEllipsoidHeight?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lats?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lons?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  mappingEntityDepth_pointsId?: InputMaybe<Scalars['ID']>;
  originalCrs?: InputMaybe<Scalars['String']>;
  siteSite_pipe_depthsId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateGeoidValueInput = {
  id: Scalars['ID'];
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
  siteGeoid_valuesId?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateLocationInput = {
  country?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  region?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type UpdateManholeItemInput = {
  depth?: InputMaybe<Scalars['Float']>;
  gpsHeight?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  manholeCoverDiameter?: InputMaybe<Scalars['Float']>;
  manholeCoverMaterial?: InputMaybe<Scalars['String']>;
  manholeCoverShape?: InputMaybe<Scalars['String']>;
  manholeItemPoiId?: InputMaybe<Scalars['ID']>;
  manholeName?: InputMaybe<Scalars['String']>;
  otherOwnerName?: InputMaybe<Scalars['String']>;
  overallNumber?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['String']>;
  pipeDiscoveryStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  utilityTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateMappingEntityInput = {
  app_name?: InputMaybe<Scalars['String']>;
  app_version?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  creation_source?: InputMaybe<Scalars['String']>;
  creation_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  creation_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  data_source?: InputMaybe<Scalars['String']>;
  depth?: InputMaybe<Scalars['Float']>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  is_valid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  object_type?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  siteMapping_entitiesId?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['String']>;
};

export type UpdateMonitorStepsInput = {
  id: Scalars['ID'];
  monitorStepsAnalysis_stepId?: InputMaybe<Scalars['ID']>;
  monitorStepsPreloader_stepId?: InputMaybe<Scalars['ID']>;
  monitorStepsScanId?: InputMaybe<Scalars['ID']>;
  monitorStepsScan_stepId?: InputMaybe<Scalars['ID']>;
  monitorStepsUpload_stepId?: InputMaybe<Scalars['ID']>;
  monitorStepsUtility_finder_stepId?: InputMaybe<Scalars['ID']>;
};

export type UpdatePoiEntityInput = {
  comment?: InputMaybe<Scalars['String']>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  pOIEntityDataId?: InputMaybe<Scalars['ID']>;
  poiType?: InputMaybe<Scalars['String']>;
  scanPoisId?: InputMaybe<Scalars['ID']>;
  sitePoisId?: InputMaybe<Scalars['ID']>;
};

export type UpdatePipeItemInput = {
  azimuth?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  diameter?: InputMaybe<Scalars['Float']>;
  flowDirection?: InputMaybe<Scalars['String']>;
  hive?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  invertLevel?: InputMaybe<Scalars['Float']>;
  manholeItemPipesId?: InputMaybe<Scalars['ID']>;
  media?: InputMaybe<Scalars['String']>;
  noCables?: InputMaybe<Scalars['String']>;
  secondaryOwner?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  thickness?: InputMaybe<Scalars['Float']>;
  topLevel?: InputMaybe<Scalars['Float']>;
};

export type UpdatePipelineRunInfoInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  epls_s3_ref?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status?: InputMaybe<Scalars['String']>;
  utilityFinderStepPipelinesId?: InputMaybe<Scalars['ID']>;
};

export type UpdatePipesqaPipeInput = {
  crossings_statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  pipesqaPipeAnalyzed_entityId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  update_timestamp?: InputMaybe<Scalars['String']>;
};

export type UpdatePlannedEntitiesInput = {
  id: Scalars['ID'];
  plannedEntitiesSiteId?: InputMaybe<Scalars['ID']>;
  s3_ref?: InputMaybe<Scalars['String']>;
};

export type UpdatePlannedPolygonInput = {
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  is_rescan?: InputMaybe<Scalars['Boolean']>;
  mount_method?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  override_color?: InputMaybe<Scalars['Boolean']>;
  plannedEntitiesPlanned_polygonsId?: InputMaybe<Scalars['ID']>;
  sensor_models?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdatePlannedTransmitterInput = {
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  method?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  override_color?: InputMaybe<Scalars['Boolean']>;
  plannedPolygonPlanned_transmittersId?: InputMaybe<Scalars['ID']>;
};

export type UpdatePreloadRunInfoInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  epls_s3_ref?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  preloaded_scan_s3_refs?: InputMaybe<Array<Scalars['String']>>;
  preloaderStepPreload_runsId?: InputMaybe<Scalars['ID']>;
  products?: InputMaybe<Array<Scalars['String']>>;
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status?: InputMaybe<Scalars['String']>;
  target_name?: InputMaybe<Scalars['String']>;
  target_type?: InputMaybe<Scalars['String']>;
};

export type UpdatePreloaderStepInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  error_message?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  preloaderStepMonitor_stepsId?: InputMaybe<Scalars['ID']>;
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UpdateReanalysisRequestCommentInput = {
  comment?: InputMaybe<Scalars['String']>;
  commenter?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['AWSTimestamp']>;
  date_iso?: InputMaybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  reanalysisRequestCommentsId?: InputMaybe<Scalars['ID']>;
};

export type UpdateReanalysisRequestInput = {
  analyzedEntityReanalysis_requestsId?: InputMaybe<Scalars['ID']>;
  assignee?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['AWSTimestamp']>;
  due_date_iso?: InputMaybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  opened_by?: InputMaybe<Scalars['String']>;
  siteReanalysis_requestsId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  transmitterSetupReanalysis_requestsId?: InputMaybe<Scalars['ID']>;
  travelCourseReanalysis_requestsId?: InputMaybe<Scalars['ID']>;
};

export type UpdateScanAccessInput = {
  end_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  frequency?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  scanScan_accessesId?: InputMaybe<Scalars['ID']>;
  start_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  user?: InputMaybe<Scalars['String']>;
};

export type UpdateScanByHashedParametersInput = {
  hash: Scalars['ID'];
  s3_ref?: InputMaybe<Scalars['String']>;
  scan_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateScanDepthPointsInput = {
  ellipsoidHeights?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  headings?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  heightMode?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lats?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lons?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  scanScan_depthsId?: InputMaybe<Scalars['ID']>;
  siteSite_depthsId?: InputMaybe<Scalars['ID']>;
};

export type UpdateScanInput = {
  bounding_polygon_s3_ref?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  downsampled_gps?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']>>>>>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  end_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  frequency?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  is_unified?: InputMaybe<Scalars['Boolean']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  machine_id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['AWSJSON']>;
  mount_method?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  original_sensor_types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  part_index?: InputMaybe<Scalars['Int']>;
  polygon_index?: InputMaybe<Scalars['Int']>;
  s3_preloaded_refs?: InputMaybe<Array<Scalars['String']>>;
  s3_raw_ref?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanMonitoring_stepsId?: InputMaybe<Scalars['ID']>;
  scanSource_scansId?: InputMaybe<Scalars['ID']>;
  scan_type?: InputMaybe<Scalars['String']>;
  sensorScansId?: InputMaybe<Scalars['ID']>;
  sensor_type?: InputMaybe<Scalars['String']>;
  siteScansId?: InputMaybe<Scalars['ID']>;
  snake_index?: InputMaybe<Scalars['Int']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  start_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  status?: InputMaybe<Scalars['String']>;
  target_info?: InputMaybe<Scalars['AWSJSON']>;
};

export type UpdateScanStepInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  error_message?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  scanStepMonitor_stepsId?: InputMaybe<Scalars['ID']>;
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UpdateSensorInput = {
  id: Scalars['ID'];
  model?: InputMaybe<Scalars['String']>;
  sensor_type?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['String']>;
};

export type UpdateSiteInput = {
  customerSitesId?: InputMaybe<Scalars['ID']>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  locationSitesId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  profiles_override?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  projected_crs?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  sitePlanned_entitiesId?: InputMaybe<Scalars['ID']>;
  site_type?: InputMaybe<Scalars['String']>;
};

export type UpdateTargetEntityInput = {
  app_name?: InputMaybe<Scalars['String']>;
  app_version?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  creation_source?: InputMaybe<Scalars['String']>;
  creation_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  creation_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  data_source?: InputMaybe<Scalars['String']>;
  display_params?: InputMaybe<Scalars['AWSJSON']>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  is_valid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanTarget_entitiesId?: InputMaybe<Scalars['ID']>;
  sensor_model?: InputMaybe<Scalars['String']>;
  siteTarget_entitiesId?: InputMaybe<Scalars['ID']>;
  target_type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export type UpdateTasksScansConnectionInput = {
  id: Scalars['ID'];
  scanId?: InputMaybe<Scalars['ID']>;
  unifyTaskId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTransmitterInput = {
  asset_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  serial?: InputMaybe<Scalars['String']>;
};

export type UpdateTransmitterSetupInput = {
  frequency?: InputMaybe<Scalars['Float']>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  is_valid?: InputMaybe<Scalars['Boolean']>;
  method?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanTransmitter_setupsId?: InputMaybe<Scalars['ID']>;
  siteTransmitter_setupsId?: InputMaybe<Scalars['ID']>;
  snr?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transmitterSetupTransmitterId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTravelCourseInput = {
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  gps_fixed_ratio?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  is_valid?: InputMaybe<Scalars['Boolean']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanTravel_coursesId?: InputMaybe<Scalars['ID']>;
  siteTravel_coursesId?: InputMaybe<Scalars['ID']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type UpdateUnifyTaskInput = {
  frequencies?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  siteUnify_tasksId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
};

export type UpdateUploadStepInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  error_message?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  s3_ref?: InputMaybe<Scalars['String']>;
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status?: InputMaybe<Scalars['String']>;
  uploadStepMonitor_stepsId?: InputMaybe<Scalars['ID']>;
  upload_speed?: InputMaybe<Scalars['Float']>;
  uploader?: InputMaybe<Scalars['String']>;
};

export type UpdateUtilityFinderStepInput = {
  end_time?: InputMaybe<Scalars['AWSTimestamp']>;
  end_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  error_message?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  start_time?: InputMaybe<Scalars['AWSTimestamp']>;
  start_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  status?: InputMaybe<Scalars['String']>;
  utilityFinderStepMonitor_stepsId?: InputMaybe<Scalars['ID']>;
};

export type UpdateV2TargetEntityInput = {
  app_name?: InputMaybe<Scalars['String']>;
  app_version?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  creation_source?: InputMaybe<Scalars['String']>;
  creation_time_iso?: InputMaybe<Scalars['AWSDateTime']>;
  creation_timestamp?: InputMaybe<Scalars['AWSTimestamp']>;
  data_source?: InputMaybe<Scalars['String']>;
  display_params?: InputMaybe<Scalars['AWSJSON']>;
  geo_data?: InputMaybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  is_valid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  s3_ref?: InputMaybe<Scalars['String']>;
  scanV2_target_entitiesId?: InputMaybe<Scalars['ID']>;
  sensor_model?: InputMaybe<Scalars['String']>;
  siteV2_target_entitiesId?: InputMaybe<Scalars['ID']>;
  target_type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export type UploadStep = {
  __typename?: 'UploadStep';
  createdAt: Scalars['AWSDateTime'];
  end_time?: Maybe<Scalars['AWSTimestamp']>;
  end_time_iso?: Maybe<Scalars['AWSDateTime']>;
  error_message?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  monitor_steps?: Maybe<MonitorSteps>;
  s3_ref?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['AWSTimestamp']>;
  start_time_iso?: Maybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  uploadStepMonitor_stepsId?: Maybe<Scalars['ID']>;
  upload_speed?: Maybe<Scalars['Float']>;
  uploader?: Maybe<Scalars['String']>;
};

export type UtilityFinderStep = {
  __typename?: 'UtilityFinderStep';
  createdAt: Scalars['AWSDateTime'];
  end_time?: Maybe<Scalars['AWSTimestamp']>;
  end_time_iso?: Maybe<Scalars['AWSDateTime']>;
  error_message?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  monitor_steps?: Maybe<MonitorSteps>;
  pipelines?: Maybe<ModelPipelineRunInfoConnection>;
  start_time?: Maybe<Scalars['AWSTimestamp']>;
  start_time_iso?: Maybe<Scalars['AWSDateTime']>;
  status: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  utilityFinderStepMonitor_stepsId?: Maybe<Scalars['ID']>;
};


export type UtilityFinderStepPipelinesArgs = {
  filter?: InputMaybe<ModelPipelineRunInfoFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type V2TargetEntity = {
  __typename?: 'V2TargetEntity';
  app_name?: Maybe<Scalars['String']>;
  app_version?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  creation_source?: Maybe<Scalars['String']>;
  creation_time_iso?: Maybe<Scalars['AWSDateTime']>;
  creation_timestamp?: Maybe<Scalars['AWSTimestamp']>;
  data_source?: Maybe<Scalars['String']>;
  display_params?: Maybe<Scalars['AWSJSON']>;
  geo_data: Scalars['AWSJSON'];
  id: Scalars['ID'];
  is_valid?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  s3_ref?: Maybe<Scalars['String']>;
  scan?: Maybe<Scan>;
  scanV2_target_entitiesId?: Maybe<Scalars['ID']>;
  sensor_model?: Maybe<Scalars['String']>;
  site: Site;
  siteV2_target_entitiesId?: Maybe<Scalars['ID']>;
  target_type: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  user?: Maybe<Scalars['String']>;
};

export type CreateCustomerMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer?: { __typename?: 'Customer', id: string } | null };

export type CreateLocationMutationVariables = Exact<{
  state?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation?: { __typename?: 'Location', id: string } | null };

export type CreateMappingEntityMutationVariables = Exact<{
  id: Scalars['ID'];
  color: Scalars['String'];
  depth?: InputMaybe<Scalars['Float']>;
  geoData: Scalars['AWSJSON'];
  object_type: Scalars['String'];
  properties?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['ID']>;
  classification: Scalars['String'];
}>;


export type CreateMappingEntityMutation = { __typename?: 'Mutation', createMappingEntity?: { __typename?: 'MappingEntity', id: string } | null };

export type DeleteMappingEntityMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMappingEntityMutation = { __typename?: 'Mutation', deleteMappingEntity?: { __typename?: 'MappingEntity', id: string } | null };

export type UpdateMappingEntityMutationVariables = Exact<{
  id: Scalars['ID'];
  properties?: InputMaybe<Scalars['String']>;
  geoData: Scalars['AWSJSON'];
}>;


export type UpdateMappingEntityMutation = { __typename?: 'Mutation', updateMappingEntity?: { __typename?: 'MappingEntity', id: string } | null };

export type CreatePlannedEntityMutationVariables = Exact<{
  plannedEntitiesSiteId: Scalars['ID'];
}>;


export type CreatePlannedEntityMutation = { __typename?: 'Mutation', createPlannedEntities?: { __typename?: 'PlannedEntities', id: string } | null };

export type CreatePlannedPolygonMutationVariables = Exact<{
  color?: InputMaybe<Scalars['String']>;
  plannedEntitiesPlanned_polygonsId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  geoData: Scalars['AWSJSON'];
  name: Scalars['String'];
  overrideColor?: InputMaybe<Scalars['Boolean']>;
  mountMethod?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sensorModels?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  isRescan?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreatePlannedPolygonMutation = { __typename?: 'Mutation', createPlannedPolygon?: { __typename?: 'PlannedPolygon', id: string } | null };

export type CreatePlannedTransmitterMutationVariables = Exact<{
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  geoData: Scalars['AWSJSON'];
  name: Scalars['String'];
  method?: InputMaybe<Scalars['String']>;
  overrideColor?: InputMaybe<Scalars['Boolean']>;
  plannedPolygonPlanned_transmittersId: Scalars['ID'];
}>;


export type CreatePlannedTransmitterMutation = { __typename?: 'Mutation', createPlannedTransmitter?: { __typename?: 'PlannedTransmitter', id: string } | null };

export type UpdateSiteWithPlannedEntityMutationVariables = Exact<{
  id: Scalars['ID'];
  plannedEntitiesId: Scalars['ID'];
}>;


export type UpdateSiteWithPlannedEntityMutation = { __typename?: 'Mutation', updateSite?: { __typename?: 'Site', id: string } | null };

export type UpdatePlannedPolygonMutationVariables = Exact<{
  id: Scalars['ID'];
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  geoData?: InputMaybe<Scalars['AWSJSON']>;
  name?: InputMaybe<Scalars['String']>;
  overrideColor?: InputMaybe<Scalars['Boolean']>;
  sensorModels?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  mountMethod?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  isRescan?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdatePlannedPolygonMutation = { __typename?: 'Mutation', updatePlannedPolygon?: { __typename?: 'PlannedPolygon', id: string } | null };

export type UpdatePlannedTransmitterMutationVariables = Exact<{
  id: Scalars['ID'];
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  geoData?: InputMaybe<Scalars['AWSJSON']>;
  name?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  overrideColor?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdatePlannedTransmitterMutation = { __typename?: 'Mutation', updatePlannedTransmitter?: { __typename?: 'PlannedTransmitter', id: string } | null };

export type DeletePlannedPolygonMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePlannedPolygonMutation = { __typename?: 'Mutation', deletePlannedPolygon?: { __typename?: 'PlannedPolygon', id: string } | null };

export type DeletePlannedTransmitterMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePlannedTransmitterMutation = { __typename?: 'Mutation', deletePlannedTransmitter?: { __typename?: 'PlannedTransmitter', id: string } | null };

export type CreatePoiMutationVariables = Exact<{
  sitePoisId: Scalars['ID'];
  geoData: Scalars['AWSJSON'];
  poiType: Scalars['String'];
  comment: Scalars['String'];
  scanPoisId?: InputMaybe<Scalars['ID']>;
}>;


export type CreatePoiMutation = { __typename?: 'Mutation', createPOIEntity?: { __typename?: 'POIEntity', id: string, poiType?: string | null } | null };

export type CreateManholeMutationVariables = Exact<{
  depth: Scalars['Float'];
  gpsHeight: Scalars['Float'];
  manholePoiId: Scalars['ID'];
  manholeName: Scalars['String'];
  owner: Scalars['String'];
  utilityTypes: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  overallNumber?: InputMaybe<Scalars['Int']>;
  manholeCoverShape: Scalars['String'];
}>;


export type CreateManholeMutation = { __typename?: 'Mutation', createManholeItem?: { __typename?: 'ManholeItem', id: string } | null };

export type CreatePipeMutationVariables = Exact<{
  azimuth: Scalars['Float'];
  description: Scalars['String'];
  diameter: Scalars['Float'];
  hive: Scalars['String'];
  invertLevel: Scalars['Float'];
  manholeItemPipesId: Scalars['ID'];
  noCables: Scalars['String'];
  thickness: Scalars['Float'];
  topLevel: Scalars['Float'];
}>;


export type CreatePipeMutation = { __typename?: 'Mutation', createPipeItem?: { __typename?: 'PipeItem', id: string } | null };

export type UpdateScanEntityMutationVariables = Exact<{
  id: Scalars['ID'];
  target_info: Scalars['AWSJSON'];
}>;


export type UpdateScanEntityMutation = { __typename?: 'Mutation', updateScan?: { __typename?: 'Scan', id: string, metadata?: any | null, siteScansId?: string | null, target_info?: any | null } | null };

export type CreateSiteMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  customerSitesId: Scalars['ID'];
  locationSitesId: Scalars['ID'];
  s3Ref: Scalars['String'];
  geoData: Scalars['AWSJSON'];
}>;


export type CreateSiteMutation = { __typename?: 'Mutation', createSite?: { __typename?: 'Site', id: string } | null };

export type CreateUnifyTaskMutationVariables = Exact<{
  geoData: Scalars['AWSJSON'];
  user_email: Scalars['String'];
  siteId?: InputMaybe<Scalars['ID']>;
}>;


export type CreateUnifyTaskMutation = { __typename?: 'Mutation', createUnifyTask?: { __typename?: 'UnifyTask', id: string } | null };

export type CreateTasksScansConnectionMutationVariables = Exact<{
  scanId: Scalars['ID'];
  unifyTaskId: Scalars['ID'];
}>;


export type CreateTasksScansConnectionMutation = { __typename?: 'Mutation', createTasksScansConnection?: { __typename?: 'TasksScansConnection', id: string } | null };

export type CreateMultipleTasksScansConnectionsMutationVariables = Exact<{
  unifyTaskId: Scalars['ID'];
  scanId1: Scalars['ID'];
  scanId2: Scalars['ID'];
  scanId3: Scalars['ID'];
  scanId4: Scalars['ID'];
  scanId5: Scalars['ID'];
}>;


export type CreateMultipleTasksScansConnectionsMutation = { __typename?: 'Mutation', createTasksScansConnection1?: { __typename?: 'TasksScansConnection', id: string } | null, createTasksScansConnection2?: { __typename?: 'TasksScansConnection', id: string } | null, createTasksScansConnection3?: { __typename?: 'TasksScansConnection', id: string } | null, createTasksScansConnection4?: { __typename?: 'TasksScansConnection', id: string } | null, createTasksScansConnection5?: { __typename?: 'TasksScansConnection', id: string } | null };

export type DeleteUnifyTaskMutationVariables = Exact<{
  unifyTaskId: Scalars['ID'];
}>;


export type DeleteUnifyTaskMutation = { __typename?: 'Mutation', deleteUnifyTask?: { __typename?: 'UnifyTask', id: string } | null };

export type GetAllAnalyzedEntitiesIdsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetAllAnalyzedEntitiesIdsQuery = { __typename?: 'Query', listAnalyzedEntities?: { __typename?: 'ModelAnalyzedEntityConnection', nextToken?: string | null, items: Array<{ __typename?: 'AnalyzedEntity', id: string } | null> } | null };

export type GetAnalyzedEntitiesBySiteIdQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['ID']>;
  dataSource1?: InputMaybe<Scalars['String']>;
  dataSource2?: InputMaybe<Scalars['String']>;
  dataSource3?: InputMaybe<Scalars['String']>;
  depthAlgorithm?: InputMaybe<Scalars['String']>;
  depthType?: InputMaybe<Scalars['String']>;
}>;


export type GetAnalyzedEntitiesBySiteIdQuery = { __typename?: 'Query', searchAnalyzedEntities?: { __typename?: 'SearchableAnalyzedEntityConnection', nextToken?: string | null, items: Array<{ __typename?: 'AnalyzedEntity', analysis_confidence?: number | null, auto_confidence?: number | null, classification: string, creation_timestamp?: any | null, frequency?: number | null, creation_source?: string | null, geo_data: any, id: string, magnetization?: number | null, s3_epls_ref?: string | null, s3_ref?: string | null, snr?: number | null, sensor_model?: string | null, updatedAt: any, user?: string | null, name?: string | null, comment?: string | null, app_name?: string | null, app_version?: string | null, is_valid?: boolean | null, scan?: { __typename?: 'Scan', id: string, start_timestamp: any, part_index?: number | null, polygon_index?: number | null, snake_index?: number | null, name?: string | null, s3_ref?: string | null, mount_method?: string | null, is_valid?: boolean | null, scan_type: string, sensor: { __typename?: 'Sensor', model: string } } | null, depth_points?: { __typename?: 'ModelEntityDepthPointsConnection', nextToken?: string | null, items: Array<{ __typename?: 'EntityDepthPoints', id: string, lats: Array<number | null>, lons: Array<number | null>, hasEllipsoidHeight?: boolean | null, ellipsoidHeights?: Array<number | null> | null, depthsFromGround: Array<number | null>, originalCrs: string } | null> } | null, algo_utility_metrics?: { __typename?: 'AlgoUtilityMetrics', metrics?: any | null } | null, pipesqa_view?: { __typename?: 'PipesqaPipe', flagged?: boolean | null, status?: string | null, comments?: { __typename?: 'ModelCommentConnection', items: Array<{ __typename?: 'Comment', text?: string | null, timestamp?: any | null } | null> } | null } | null } | null> } | null };

export type GetCustomersNamesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetCustomersNamesQuery = { __typename?: 'Query', searchCustomers?: { __typename?: 'SearchableCustomerConnection', nextToken?: string | null, items: Array<{ __typename?: 'Customer', id: string, name: string } | null> } | null };

export type GetCustomersAmountWithNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetCustomersAmountWithNameQuery = { __typename?: 'Query', searchCustomers?: { __typename?: 'SearchableCustomerConnection', total?: number | null } | null };

export type GetSearchEntityDepthPointsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  pipeId?: InputMaybe<Scalars['ID']>;
}>;


export type GetSearchEntityDepthPointsQuery = { __typename?: 'Query', searchEntityDepthPoints?: { __typename?: 'SearchableEntityDepthPointsConnection', nextToken?: string | null, items: Array<{ __typename?: 'EntityDepthPoints', id: string, siteSite_pipe_depthsId?: string | null, lats: Array<number | null>, lons: Array<number | null>, depthsFromGround: Array<number | null>, ellipsoidHeights?: Array<number | null> | null, ellipsoidHeightsMask?: Array<boolean | null> | null, hasEllipsoidHeight?: boolean | null, analyzedEntityDepth_pointsId?: string | null, algorithm?: string | null, entity?: { __typename?: 'AnalyzedEntity', id: string } | null } | null> } | null };

export type GetLocationsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetLocationsQuery = { __typename?: 'Query', searchLocations?: { __typename?: 'SearchableLocationConnection', nextToken?: string | null, items: Array<{ __typename?: 'Location', country: string, id: string, region?: string | null, state?: string | null } | null> } | null };

export type GetLocationsAmountWithFilterQueryVariables = Exact<{
  state: Scalars['String'];
  country: Scalars['String'];
}>;


export type GetLocationsAmountWithFilterQuery = { __typename?: 'Query', searchLocations?: { __typename?: 'SearchableLocationConnection', total?: number | null } | null };

export type SearchMappingEntitiesBySiteQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['ID']>;
}>;


export type SearchMappingEntitiesBySiteQuery = { __typename?: 'Query', searchMappingEntities?: { __typename?: 'SearchableMappingEntityConnection', nextToken?: string | null, items: Array<{ __typename?: 'MappingEntity', geo_data: any, color?: string | null, depth?: number | null, object_type?: string | null, id: string, properties?: string | null, createdAt: any } | null> } | null };

export type GetListMappingEntitiesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['ID']>;
}>;


export type GetListMappingEntitiesQuery = { __typename?: 'Query', listMappingEntities?: { __typename?: 'ModelMappingEntityConnection', nextToken?: string | null, items: Array<{ __typename?: 'MappingEntity', id: string, geo_data: any, classification: string, createdAt: any, object_type?: string | null, color?: string | null, depth?: number | null, properties?: string | null } | null> } | null };

export type GetPlanningPolygonsQueryVariables = Exact<{
  siteId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetPlanningPolygonsQuery = { __typename?: 'Query', getSite?: { __typename?: 'Site', id: string, planned_entities?: { __typename?: 'PlannedEntities', id: string, planned_polygons?: { __typename?: 'ModelPlannedPolygonConnection', nextToken?: string | null, items: Array<{ __typename?: 'PlannedPolygon', color?: string | null, comment?: string | null, createdAt: any, geo_data: any, name: string, override_color?: boolean | null, sensor_models?: Array<string | null> | null, mount_method?: Array<string | null> | null, id: string, is_rescan?: boolean | null } | null> } | null } | null } | null };

export type GetPlannedPolygonTransmittersQueryVariables = Exact<{
  polygonId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetPlannedPolygonTransmittersQuery = { __typename?: 'Query', getPlannedPolygon?: { __typename?: 'PlannedPolygon', id: string, planned_transmitters?: { __typename?: 'ModelPlannedTransmitterConnection', nextToken?: string | null, items: Array<{ __typename?: 'PlannedTransmitter', color?: string | null, comment?: string | null, geo_data: any, id: string, name: string, override_color?: boolean | null, method?: string | null } | null> } | null } | null };

export type ScanFieldsFragment = { __typename?: 'Scan', id: string, mount_method?: string | null, start_timestamp: any, status: string, name?: string | null, polygon_index?: number | null, part_index?: number | null, snake_index?: number | null, s3_ref?: string | null, end_timestamp?: any | null, sensor: { __typename?: 'Sensor', model: string }, site: { __typename?: 'Site', name: string }, travel_courses?: { __typename?: 'ModelTravelCourseConnection', items: Array<{ __typename?: 'TravelCourse', gps_fixed_ratio?: number | null } | null> } | null };

export type GetScansAfterTimestampQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  timestamp: Scalars['Int'];
}>;


export type GetScansAfterTimestampQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', nextToken?: string | null, items: Array<{ __typename?: 'Scan', id: string, mount_method?: string | null, start_timestamp: any, status: string, name?: string | null, polygon_index?: number | null, part_index?: number | null, snake_index?: number | null, s3_ref?: string | null, end_timestamp?: any | null, sensor: { __typename?: 'Sensor', model: string }, site: { __typename?: 'Site', name: string }, travel_courses?: { __typename?: 'ModelTravelCourseConnection', items: Array<{ __typename?: 'TravelCourse', gps_fixed_ratio?: number | null } | null> } | null } | null> } | null };

export type GetScanTransimtterSetupsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetScanTransimtterSetupsQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', nextToken?: string | null, total?: number | null, items: Array<{ __typename?: 'Scan', transmitter_setups?: { __typename?: 'ModelTransmitterSetupConnection', items: Array<{ __typename?: 'TransmitterSetup', frequency: number } | null> } | null } | null> } | null };

export type GetSiteScansQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId: Scalars['ID'];
}>;


export type GetSiteScansQuery = { __typename?: 'Query', getSite?: { __typename?: 'Site', id: string, scans?: { __typename?: 'ModelScanConnection', nextToken?: string | null, items: Array<{ __typename?: 'Scan', id: string, mount_method?: string | null, start_timestamp: any, status: string, name?: string | null, polygon_index?: number | null, part_index?: number | null, snake_index?: number | null, s3_ref?: string | null, end_timestamp?: any | null, sensor: { __typename?: 'Sensor', model: string }, site: { __typename?: 'Site', name: string }, travel_courses?: { __typename?: 'ModelTravelCourseConnection', items: Array<{ __typename?: 'TravelCourse', gps_fixed_ratio?: number | null } | null> } | null } | null> } | null } | null };

export type GetSiteScansDetailsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId: Scalars['ID'];
}>;


export type GetSiteScansDetailsQuery = { __typename?: 'Query', getSite?: { __typename?: 'Site', id: string, scans?: { __typename?: 'ModelScanConnection', nextToken?: string | null, items: Array<{ __typename?: 'Scan', id: string, name?: string | null, start_timestamp: any, polygon_index?: number | null, part_index?: number | null, snake_index?: number | null, bounding_polygon_s3_ref?: string | null, mount_method?: string | null, sensor: { __typename?: 'Sensor', model: string } } | null> } | null } | null };

export type SearchScansPsdQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId: Scalars['ID'];
  is_valid?: InputMaybe<Scalars['Boolean']>;
}>;


export type SearchScansPsdQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', nextToken?: string | null, total?: number | null, items: Array<{ __typename?: 'Scan', id: string, name?: string | null, start_timestamp: any, polygon_index?: number | null, part_index?: number | null, snake_index?: number | null, bounding_polygon_s3_ref?: string | null, sensor: { __typename?: 'Sensor', model: string } } | null> } | null };

export type SearchScansLocationValidQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId: Scalars['ID'];
  is_valid?: InputMaybe<Scalars['Boolean']>;
}>;


export type SearchScansLocationValidQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', nextToken?: string | null, total?: number | null, items: Array<{ __typename?: 'Scan', id: string, name?: string | null, start_timestamp: any, polygon_index?: number | null, part_index?: number | null, snake_index?: number | null, bounding_polygon_s3_ref?: string | null, sensor: { __typename?: 'Sensor', model: string } } | null> } | null };

export type SearchScansLocationQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId: Scalars['ID'];
}>;


export type SearchScansLocationQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', nextToken?: string | null, total?: number | null, items: Array<{ __typename?: 'Scan', id: string, name?: string | null, start_timestamp: any, polygon_index?: number | null, part_index?: number | null, snake_index?: number | null, bounding_polygon_s3_ref?: string | null, sensor: { __typename?: 'Sensor', model: string } } | null> } | null };

export type SearchScansMonitorInfoQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  startTimeStamp: Scalars['Int'];
  endTimeStamp: Scalars['Int'];
  siteId?: InputMaybe<Scalars['ID']>;
}>;


export type SearchScansMonitorInfoQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', nextToken?: string | null, items: Array<{ __typename?: 'Scan', id: string, name?: string | null, is_valid?: boolean | null, scan_type: string, machine_id?: string | null, sensor: { __typename?: 'Sensor', model: string }, monitoring_steps?: { __typename?: 'MonitorSteps', scan_step: { __typename?: 'ScanStep', end_time?: any | null, error_message?: string | null, start_time?: any | null, status: string }, upload_step: { __typename?: 'UploadStep', start_time?: any | null, end_time?: any | null, error_message?: string | null, status: string, s3_ref?: string | null, uploader?: string | null, upload_speed?: number | null, updatedAt: any }, preloader_step: { __typename?: 'PreloaderStep', start_time?: any | null, end_time?: any | null, error_message?: string | null, status: string, id: string }, utility_finder_step: { __typename?: 'UtilityFinderStep', id: string, start_time?: any | null, status: string, error_message?: string | null, end_time?: any | null }, analysis_step: { __typename?: 'AnalysisStep', access_time?: any | null, error_message?: string | null, status: string } } | null } | null> } | null };

export type GetPreloadRunsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  stepId: Scalars['ID'];
}>;


export type GetPreloadRunsQuery = { __typename?: 'Query', getPreloaderStep?: { __typename?: 'PreloaderStep', id: string, preload_runs?: { __typename?: 'ModelPreloadRunInfoConnection', nextToken?: string | null, items: Array<{ __typename?: 'PreloadRunInfo', id: string, error_message?: string | null, preloaded_scan_s3_refs?: Array<string> | null, end_time?: any | null, start_time?: any | null, status: string, target_name: string, target_type: string, products?: Array<string> | null, updatedAt: any } | null> } | null } | null };

export type GetPipelinesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  stepId: Scalars['ID'];
}>;


export type GetPipelinesQuery = { __typename?: 'Query', getUtilityFinderStep?: { __typename?: 'UtilityFinderStep', id: string, pipelines?: { __typename?: 'ModelPipelineRunInfoConnection', nextToken?: string | null, items: Array<{ __typename?: 'PipelineRunInfo', epls_s3_ref: string, error_message?: string | null, id: string, name: string, start_time?: any | null, end_time?: any | null, status: string, updatedAt: any } | null> } | null } | null };

export type GetSitesWithScansInDateRangeQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  startTimeStamp: Scalars['Int'];
  endTimeStamp: Scalars['Int'];
}>;


export type GetSitesWithScansInDateRangeQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', nextToken?: string | null, items: Array<{ __typename?: 'Scan', site: { __typename?: 'Site', locationSitesId?: string | null, name: string, id: string }, sensor: { __typename?: 'Sensor', model: string, id: string } } | null> } | null };

export type GetTargetScanQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['ID']>;
}>;


export type GetTargetScanQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', total?: number | null, nextToken?: string | null, items: Array<{ __typename?: 'Scan', id: string, name?: string | null, scan_type: string, metadata?: any | null, target_info?: any | null, site: { __typename?: 'Site', id: string, location?: { __typename?: 'Location', country: string } | null } } | null> } | null };

export type GetTargetScanManHoleQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  scanId?: InputMaybe<Scalars['ID']>;
}>;


export type GetTargetScanManHoleQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', total?: number | null, nextToken?: string | null, items: Array<{ __typename?: 'Scan', id: string, name?: string | null, scan_type: string, metadata?: any | null, target_info?: any | null, site: { __typename?: 'Site', id: string, name: string, location?: { __typename?: 'Location', country: string } | null } } | null> } | null };

export type GetDailySummaryInfoQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  startTimeStamp: Scalars['Int'];
  endTimeStamp: Scalars['Int'];
  siteId?: InputMaybe<Scalars['ID']>;
}>;


export type GetDailySummaryInfoQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', nextToken?: string | null, items: Array<{ __typename?: 'Scan', is_valid?: boolean | null, scan_type: string, name?: string | null, start_timestamp: any, end_timestamp?: any | null, s3_ref?: string | null, polygon_index?: number | null, part_index?: number | null, snake_index?: number | null, comment?: string | null, frequency?: number | null, sensor: { __typename?: 'Sensor', model: string }, transmitter_setups?: { __typename?: 'ModelTransmitterSetupConnection', items: Array<{ __typename?: 'TransmitterSetup', frequency: number } | null> } | null } | null> } | null };

export type GetSiteScansRefsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId: Scalars['ID'];
}>;


export type GetSiteScansRefsQuery = { __typename?: 'Query', getSite?: { __typename?: 'Site', id: string, scans?: { __typename?: 'ModelScanConnection', nextToken?: string | null, items: Array<{ __typename?: 'Scan', id: string, s3_ref?: string | null } | null> } | null } | null };

export type GetSensorModelsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetSensorModelsQuery = { __typename?: 'Query', listSensors?: { __typename?: 'ModelSensorConnection', nextToken?: string | null, items: Array<{ __typename?: 'Sensor', id: string, model: string } | null> } | null };

export type GetSiteTargetEntitiesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['ID']>;
}>;


export type GetSiteTargetEntitiesQuery = { __typename?: 'Query', searchTargetEntities?: { __typename?: 'SearchableTargetEntityConnection', nextToken?: string | null, items: Array<{ __typename?: 'TargetEntity', app_name?: string | null, app_version?: string | null, comment?: string | null, creation_timestamp?: any | null, display_params?: any | null, geo_data: any, is_valid?: boolean | null, name?: string | null, s3_ref?: string | null, sensor_model?: string | null, target_type: string, user?: string | null, scan?: { __typename?: 'Scan', mount_method?: string | null, name?: string | null, part_index?: number | null, polygon_index?: number | null, start_timestamp: any, snake_index?: number | null, s3_ref?: string | null, is_valid?: boolean | null, scan_type: string, sensor: { __typename?: 'Sensor', model: string } } | null } | null> } | null };

export type GetSiteTransmittersQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['ID']>;
}>;


export type GetSiteTransmittersQuery = { __typename?: 'Query', searchTransmitterSetups?: { __typename?: 'SearchableTransmitterSetupConnection', nextToken?: string | null, items: Array<{ __typename?: 'TransmitterSetup', siteTransmitter_setupsId?: string | null, id: string, geo_data: any, frequency: number, method: string, source?: string | null, s3_ref?: string | null, snr?: number | null, is_valid?: boolean | null, scan?: { __typename?: 'Scan', mount_method?: string | null, name?: string | null, part_index?: number | null, polygon_index?: number | null, start_timestamp: any, snake_index?: number | null, s3_ref?: string | null, is_valid?: boolean | null, scan_type: string, sensor: { __typename?: 'Sensor', model: string } } | null, transmitter: { __typename?: 'Transmitter', asset_id?: string | null } } | null> } | null };

export type GetSiteTravelCoursesQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['ID']>;
}>;


export type GetSiteTravelCoursesQuery = { __typename?: 'Query', searchTravelCourses?: { __typename?: 'SearchableTravelCourseConnection', nextToken?: string | null, items: Array<{ __typename?: 'TravelCourse', geo_data: any, s3_ref?: string | null, id: string, tag?: string | null, is_valid?: boolean | null, gps_fixed_ratio?: number | null, scan?: { __typename?: 'Scan', id: string, mount_method?: string | null, name?: string | null, part_index?: number | null, polygon_index?: number | null, snake_index?: number | null, start_timestamp: any, start_time_iso?: any | null, s3_ref?: string | null, is_valid?: boolean | null, scan_type: string, sensor: { __typename?: 'Sensor', model: string } } | null } | null> } | null };

export type SearchUnifyTasksByUserQueryVariables = Exact<{
  user_email: Scalars['String'];
  siteId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type SearchUnifyTasksByUserQuery = { __typename?: 'Query', searchUnifyTasks?: { __typename?: 'SearchableUnifyTaskConnection', nextToken?: string | null, items: Array<{ __typename?: 'UnifyTask', createdAt: any, id: string, status?: string | null, user_email?: string | null, updatedAt: any } | null> } | null };

export type SearchUnifyTasksQueryVariables = Exact<{
  siteId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type SearchUnifyTasksQuery = { __typename?: 'Query', searchUnifyTasks?: { __typename?: 'SearchableUnifyTaskConnection', nextToken?: string | null, items: Array<{ __typename?: 'UnifyTask', createdAt: any, id: string, status?: string | null, user_email?: string | null, updatedAt: any } | null> } | null };

export type GetSitesByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetSitesByNameQuery = { __typename?: 'Query', searchSites?: { __typename?: 'SearchableSiteConnection', items: Array<{ __typename?: 'Site', id: string } | null> } | null };

export type GetAllSitesS3RefsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetAllSitesS3RefsQuery = { __typename?: 'Query', listSites?: { __typename?: 'ModelSiteConnection', nextToken?: string | null, items: Array<{ __typename?: 'Site', s3_ref?: string | null, id: string } | null> } | null };

export type GetAllSitesMonitorFilterInfoQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetAllSitesMonitorFilterInfoQuery = { __typename?: 'Query', searchSites?: { __typename?: 'SearchableSiteConnection', nextToken?: string | null, items: Array<{ __typename?: 'Site', id: string, name: string, locationSitesId?: string | null } | null> } | null };

export type GetSitesAmountWithS3RefQueryVariables = Exact<{
  s3Ref: Scalars['String'];
}>;


export type GetSitesAmountWithS3RefQuery = { __typename?: 'Query', searchSites?: { __typename?: 'SearchableSiteConnection', total?: number | null } | null };

export type GetAllSitesInfoForChoosingQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetAllSitesInfoForChoosingQuery = { __typename?: 'Query', searchSites?: { __typename?: 'SearchableSiteConnection', nextToken?: string | null, items: Array<{ __typename?: 'Site', id: string, name: string, s3_ref?: string | null, customerSitesId?: string | null, locationSitesId?: string | null } | null> } | null };

export const ScanFieldsFragmentDoc = gql`
    fragment ScanFields on Scan {
  id
  sensor {
    model
  }
  mount_method
  start_timestamp
  status
  name
  polygon_index
  part_index
  snake_index
  s3_ref
  end_timestamp
  site {
    name
  }
  travel_courses(filter: {tag: {eq: "raw"}}) {
    items {
      gps_fixed_ratio
    }
  }
}
    `;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($name: String!) {
  createCustomer(input: {name: $name}) {
    id
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($state: String, $country: String!, $region: String) {
  createLocation(input: {country: $country, region: $region, state: $state}) {
    id
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      state: // value for 'state'
 *      country: // value for 'country'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const CreateMappingEntityDocument = gql`
    mutation CreateMappingEntity($id: ID!, $color: String!, $depth: Float, $geoData: AWSJSON!, $object_type: String!, $properties: String, $siteId: ID, $classification: String!) {
  createMappingEntity(
    input: {id: $id, color: $color, depth: $depth, geo_data: $geoData, object_type: $object_type, properties: $properties, siteMapping_entitiesId: $siteId, classification: $classification}
  ) {
    id
  }
}
    `;
export type CreateMappingEntityMutationFn = Apollo.MutationFunction<CreateMappingEntityMutation, CreateMappingEntityMutationVariables>;

/**
 * __useCreateMappingEntityMutation__
 *
 * To run a mutation, you first call `useCreateMappingEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMappingEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMappingEntityMutation, { data, loading, error }] = useCreateMappingEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      color: // value for 'color'
 *      depth: // value for 'depth'
 *      geoData: // value for 'geoData'
 *      object_type: // value for 'object_type'
 *      properties: // value for 'properties'
 *      siteId: // value for 'siteId'
 *      classification: // value for 'classification'
 *   },
 * });
 */
export function useCreateMappingEntityMutation(baseOptions?: Apollo.MutationHookOptions<CreateMappingEntityMutation, CreateMappingEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMappingEntityMutation, CreateMappingEntityMutationVariables>(CreateMappingEntityDocument, options);
      }
export type CreateMappingEntityMutationHookResult = ReturnType<typeof useCreateMappingEntityMutation>;
export type CreateMappingEntityMutationResult = Apollo.MutationResult<CreateMappingEntityMutation>;
export type CreateMappingEntityMutationOptions = Apollo.BaseMutationOptions<CreateMappingEntityMutation, CreateMappingEntityMutationVariables>;
export const DeleteMappingEntityDocument = gql`
    mutation deleteMappingEntity($id: ID!) {
  deleteMappingEntity(input: {id: $id}) {
    id
  }
}
    `;
export type DeleteMappingEntityMutationFn = Apollo.MutationFunction<DeleteMappingEntityMutation, DeleteMappingEntityMutationVariables>;

/**
 * __useDeleteMappingEntityMutation__
 *
 * To run a mutation, you first call `useDeleteMappingEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMappingEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMappingEntityMutation, { data, loading, error }] = useDeleteMappingEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMappingEntityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMappingEntityMutation, DeleteMappingEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMappingEntityMutation, DeleteMappingEntityMutationVariables>(DeleteMappingEntityDocument, options);
      }
export type DeleteMappingEntityMutationHookResult = ReturnType<typeof useDeleteMappingEntityMutation>;
export type DeleteMappingEntityMutationResult = Apollo.MutationResult<DeleteMappingEntityMutation>;
export type DeleteMappingEntityMutationOptions = Apollo.BaseMutationOptions<DeleteMappingEntityMutation, DeleteMappingEntityMutationVariables>;
export const UpdateMappingEntityDocument = gql`
    mutation updateMappingEntity($id: ID!, $properties: String, $geoData: AWSJSON!) {
  updateMappingEntity(
    input: {id: $id, properties: $properties, geo_data: $geoData}
  ) {
    id
  }
}
    `;
export type UpdateMappingEntityMutationFn = Apollo.MutationFunction<UpdateMappingEntityMutation, UpdateMappingEntityMutationVariables>;

/**
 * __useUpdateMappingEntityMutation__
 *
 * To run a mutation, you first call `useUpdateMappingEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMappingEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMappingEntityMutation, { data, loading, error }] = useUpdateMappingEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      properties: // value for 'properties'
 *      geoData: // value for 'geoData'
 *   },
 * });
 */
export function useUpdateMappingEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMappingEntityMutation, UpdateMappingEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMappingEntityMutation, UpdateMappingEntityMutationVariables>(UpdateMappingEntityDocument, options);
      }
export type UpdateMappingEntityMutationHookResult = ReturnType<typeof useUpdateMappingEntityMutation>;
export type UpdateMappingEntityMutationResult = Apollo.MutationResult<UpdateMappingEntityMutation>;
export type UpdateMappingEntityMutationOptions = Apollo.BaseMutationOptions<UpdateMappingEntityMutation, UpdateMappingEntityMutationVariables>;
export const CreatePlannedEntityDocument = gql`
    mutation createPlannedEntity($plannedEntitiesSiteId: ID!) {
  createPlannedEntities(input: {plannedEntitiesSiteId: $plannedEntitiesSiteId}) {
    id
  }
}
    `;
export type CreatePlannedEntityMutationFn = Apollo.MutationFunction<CreatePlannedEntityMutation, CreatePlannedEntityMutationVariables>;

/**
 * __useCreatePlannedEntityMutation__
 *
 * To run a mutation, you first call `useCreatePlannedEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlannedEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlannedEntityMutation, { data, loading, error }] = useCreatePlannedEntityMutation({
 *   variables: {
 *      plannedEntitiesSiteId: // value for 'plannedEntitiesSiteId'
 *   },
 * });
 */
export function useCreatePlannedEntityMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlannedEntityMutation, CreatePlannedEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlannedEntityMutation, CreatePlannedEntityMutationVariables>(CreatePlannedEntityDocument, options);
      }
export type CreatePlannedEntityMutationHookResult = ReturnType<typeof useCreatePlannedEntityMutation>;
export type CreatePlannedEntityMutationResult = Apollo.MutationResult<CreatePlannedEntityMutation>;
export type CreatePlannedEntityMutationOptions = Apollo.BaseMutationOptions<CreatePlannedEntityMutation, CreatePlannedEntityMutationVariables>;
export const CreatePlannedPolygonDocument = gql`
    mutation createPlannedPolygon($color: String, $plannedEntitiesPlanned_polygonsId: ID!, $comment: String, $geoData: AWSJSON!, $name: String!, $overrideColor: Boolean, $mountMethod: [String], $sensorModels: [String], $isRescan: Boolean) {
  createPlannedPolygon(
    input: {color: $color, plannedEntitiesPlanned_polygonsId: $plannedEntitiesPlanned_polygonsId, comment: $comment, geo_data: $geoData, name: $name, override_color: $overrideColor, sensor_models: $sensorModels, mount_method: $mountMethod, is_rescan: $isRescan}
  ) {
    id
  }
}
    `;
export type CreatePlannedPolygonMutationFn = Apollo.MutationFunction<CreatePlannedPolygonMutation, CreatePlannedPolygonMutationVariables>;

/**
 * __useCreatePlannedPolygonMutation__
 *
 * To run a mutation, you first call `useCreatePlannedPolygonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlannedPolygonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlannedPolygonMutation, { data, loading, error }] = useCreatePlannedPolygonMutation({
 *   variables: {
 *      color: // value for 'color'
 *      plannedEntitiesPlanned_polygonsId: // value for 'plannedEntitiesPlanned_polygonsId'
 *      comment: // value for 'comment'
 *      geoData: // value for 'geoData'
 *      name: // value for 'name'
 *      overrideColor: // value for 'overrideColor'
 *      mountMethod: // value for 'mountMethod'
 *      sensorModels: // value for 'sensorModels'
 *      isRescan: // value for 'isRescan'
 *   },
 * });
 */
export function useCreatePlannedPolygonMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlannedPolygonMutation, CreatePlannedPolygonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlannedPolygonMutation, CreatePlannedPolygonMutationVariables>(CreatePlannedPolygonDocument, options);
      }
export type CreatePlannedPolygonMutationHookResult = ReturnType<typeof useCreatePlannedPolygonMutation>;
export type CreatePlannedPolygonMutationResult = Apollo.MutationResult<CreatePlannedPolygonMutation>;
export type CreatePlannedPolygonMutationOptions = Apollo.BaseMutationOptions<CreatePlannedPolygonMutation, CreatePlannedPolygonMutationVariables>;
export const CreatePlannedTransmitterDocument = gql`
    mutation createPlannedTransmitter($color: String, $comment: String, $geoData: AWSJSON!, $name: String!, $method: String, $overrideColor: Boolean, $plannedPolygonPlanned_transmittersId: ID!) {
  createPlannedTransmitter(
    input: {color: $color, comment: $comment, geo_data: $geoData, name: $name, method: $method, override_color: $overrideColor, plannedPolygonPlanned_transmittersId: $plannedPolygonPlanned_transmittersId}
  ) {
    id
  }
}
    `;
export type CreatePlannedTransmitterMutationFn = Apollo.MutationFunction<CreatePlannedTransmitterMutation, CreatePlannedTransmitterMutationVariables>;

/**
 * __useCreatePlannedTransmitterMutation__
 *
 * To run a mutation, you first call `useCreatePlannedTransmitterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlannedTransmitterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlannedTransmitterMutation, { data, loading, error }] = useCreatePlannedTransmitterMutation({
 *   variables: {
 *      color: // value for 'color'
 *      comment: // value for 'comment'
 *      geoData: // value for 'geoData'
 *      name: // value for 'name'
 *      method: // value for 'method'
 *      overrideColor: // value for 'overrideColor'
 *      plannedPolygonPlanned_transmittersId: // value for 'plannedPolygonPlanned_transmittersId'
 *   },
 * });
 */
export function useCreatePlannedTransmitterMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlannedTransmitterMutation, CreatePlannedTransmitterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlannedTransmitterMutation, CreatePlannedTransmitterMutationVariables>(CreatePlannedTransmitterDocument, options);
      }
export type CreatePlannedTransmitterMutationHookResult = ReturnType<typeof useCreatePlannedTransmitterMutation>;
export type CreatePlannedTransmitterMutationResult = Apollo.MutationResult<CreatePlannedTransmitterMutation>;
export type CreatePlannedTransmitterMutationOptions = Apollo.BaseMutationOptions<CreatePlannedTransmitterMutation, CreatePlannedTransmitterMutationVariables>;
export const UpdateSiteWithPlannedEntityDocument = gql`
    mutation updateSiteWithPlannedEntity($id: ID!, $plannedEntitiesId: ID!) {
  updateSite(input: {id: $id, sitePlanned_entitiesId: $plannedEntitiesId}) {
    id
  }
}
    `;
export type UpdateSiteWithPlannedEntityMutationFn = Apollo.MutationFunction<UpdateSiteWithPlannedEntityMutation, UpdateSiteWithPlannedEntityMutationVariables>;

/**
 * __useUpdateSiteWithPlannedEntityMutation__
 *
 * To run a mutation, you first call `useUpdateSiteWithPlannedEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteWithPlannedEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteWithPlannedEntityMutation, { data, loading, error }] = useUpdateSiteWithPlannedEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      plannedEntitiesId: // value for 'plannedEntitiesId'
 *   },
 * });
 */
export function useUpdateSiteWithPlannedEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteWithPlannedEntityMutation, UpdateSiteWithPlannedEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteWithPlannedEntityMutation, UpdateSiteWithPlannedEntityMutationVariables>(UpdateSiteWithPlannedEntityDocument, options);
      }
export type UpdateSiteWithPlannedEntityMutationHookResult = ReturnType<typeof useUpdateSiteWithPlannedEntityMutation>;
export type UpdateSiteWithPlannedEntityMutationResult = Apollo.MutationResult<UpdateSiteWithPlannedEntityMutation>;
export type UpdateSiteWithPlannedEntityMutationOptions = Apollo.BaseMutationOptions<UpdateSiteWithPlannedEntityMutation, UpdateSiteWithPlannedEntityMutationVariables>;
export const UpdatePlannedPolygonDocument = gql`
    mutation updatePlannedPolygon($id: ID!, $color: String, $comment: String, $geoData: AWSJSON, $name: String, $overrideColor: Boolean, $sensorModels: [String], $mountMethod: [String], $isRescan: Boolean) {
  updatePlannedPolygon(
    input: {id: $id, color: $color, comment: $comment, geo_data: $geoData, name: $name, override_color: $overrideColor, sensor_models: $sensorModels, mount_method: $mountMethod, is_rescan: $isRescan}
  ) {
    id
  }
}
    `;
export type UpdatePlannedPolygonMutationFn = Apollo.MutationFunction<UpdatePlannedPolygonMutation, UpdatePlannedPolygonMutationVariables>;

/**
 * __useUpdatePlannedPolygonMutation__
 *
 * To run a mutation, you first call `useUpdatePlannedPolygonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlannedPolygonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlannedPolygonMutation, { data, loading, error }] = useUpdatePlannedPolygonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      color: // value for 'color'
 *      comment: // value for 'comment'
 *      geoData: // value for 'geoData'
 *      name: // value for 'name'
 *      overrideColor: // value for 'overrideColor'
 *      sensorModels: // value for 'sensorModels'
 *      mountMethod: // value for 'mountMethod'
 *      isRescan: // value for 'isRescan'
 *   },
 * });
 */
export function useUpdatePlannedPolygonMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlannedPolygonMutation, UpdatePlannedPolygonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlannedPolygonMutation, UpdatePlannedPolygonMutationVariables>(UpdatePlannedPolygonDocument, options);
      }
export type UpdatePlannedPolygonMutationHookResult = ReturnType<typeof useUpdatePlannedPolygonMutation>;
export type UpdatePlannedPolygonMutationResult = Apollo.MutationResult<UpdatePlannedPolygonMutation>;
export type UpdatePlannedPolygonMutationOptions = Apollo.BaseMutationOptions<UpdatePlannedPolygonMutation, UpdatePlannedPolygonMutationVariables>;
export const UpdatePlannedTransmitterDocument = gql`
    mutation updatePlannedTransmitter($id: ID!, $color: String, $comment: String, $geoData: AWSJSON, $name: String, $method: String, $overrideColor: Boolean) {
  updatePlannedTransmitter(
    input: {id: $id, color: $color, comment: $comment, geo_data: $geoData, method: $method, name: $name, override_color: $overrideColor}
  ) {
    id
  }
}
    `;
export type UpdatePlannedTransmitterMutationFn = Apollo.MutationFunction<UpdatePlannedTransmitterMutation, UpdatePlannedTransmitterMutationVariables>;

/**
 * __useUpdatePlannedTransmitterMutation__
 *
 * To run a mutation, you first call `useUpdatePlannedTransmitterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlannedTransmitterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlannedTransmitterMutation, { data, loading, error }] = useUpdatePlannedTransmitterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      color: // value for 'color'
 *      comment: // value for 'comment'
 *      geoData: // value for 'geoData'
 *      name: // value for 'name'
 *      method: // value for 'method'
 *      overrideColor: // value for 'overrideColor'
 *   },
 * });
 */
export function useUpdatePlannedTransmitterMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlannedTransmitterMutation, UpdatePlannedTransmitterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlannedTransmitterMutation, UpdatePlannedTransmitterMutationVariables>(UpdatePlannedTransmitterDocument, options);
      }
export type UpdatePlannedTransmitterMutationHookResult = ReturnType<typeof useUpdatePlannedTransmitterMutation>;
export type UpdatePlannedTransmitterMutationResult = Apollo.MutationResult<UpdatePlannedTransmitterMutation>;
export type UpdatePlannedTransmitterMutationOptions = Apollo.BaseMutationOptions<UpdatePlannedTransmitterMutation, UpdatePlannedTransmitterMutationVariables>;
export const DeletePlannedPolygonDocument = gql`
    mutation deletePlannedPolygon($id: ID!) {
  deletePlannedPolygon(input: {id: $id}) {
    id
  }
}
    `;
export type DeletePlannedPolygonMutationFn = Apollo.MutationFunction<DeletePlannedPolygonMutation, DeletePlannedPolygonMutationVariables>;

/**
 * __useDeletePlannedPolygonMutation__
 *
 * To run a mutation, you first call `useDeletePlannedPolygonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlannedPolygonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlannedPolygonMutation, { data, loading, error }] = useDeletePlannedPolygonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlannedPolygonMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlannedPolygonMutation, DeletePlannedPolygonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlannedPolygonMutation, DeletePlannedPolygonMutationVariables>(DeletePlannedPolygonDocument, options);
      }
export type DeletePlannedPolygonMutationHookResult = ReturnType<typeof useDeletePlannedPolygonMutation>;
export type DeletePlannedPolygonMutationResult = Apollo.MutationResult<DeletePlannedPolygonMutation>;
export type DeletePlannedPolygonMutationOptions = Apollo.BaseMutationOptions<DeletePlannedPolygonMutation, DeletePlannedPolygonMutationVariables>;
export const DeletePlannedTransmitterDocument = gql`
    mutation deletePlannedTransmitter($id: ID!) {
  deletePlannedTransmitter(input: {id: $id}) {
    id
  }
}
    `;
export type DeletePlannedTransmitterMutationFn = Apollo.MutationFunction<DeletePlannedTransmitterMutation, DeletePlannedTransmitterMutationVariables>;

/**
 * __useDeletePlannedTransmitterMutation__
 *
 * To run a mutation, you first call `useDeletePlannedTransmitterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlannedTransmitterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlannedTransmitterMutation, { data, loading, error }] = useDeletePlannedTransmitterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlannedTransmitterMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlannedTransmitterMutation, DeletePlannedTransmitterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlannedTransmitterMutation, DeletePlannedTransmitterMutationVariables>(DeletePlannedTransmitterDocument, options);
      }
export type DeletePlannedTransmitterMutationHookResult = ReturnType<typeof useDeletePlannedTransmitterMutation>;
export type DeletePlannedTransmitterMutationResult = Apollo.MutationResult<DeletePlannedTransmitterMutation>;
export type DeletePlannedTransmitterMutationOptions = Apollo.BaseMutationOptions<DeletePlannedTransmitterMutation, DeletePlannedTransmitterMutationVariables>;
export const CreatePoiDocument = gql`
    mutation createPOI($sitePoisId: ID!, $geoData: AWSJSON!, $poiType: String!, $comment: String!, $scanPoisId: ID) {
  createPOIEntity(
    input: {geo_data: $geoData, poiType: $poiType, comment: $comment, sitePoisId: $sitePoisId, scanPoisId: $scanPoisId}
  ) {
    id
    poiType
  }
}
    `;
export type CreatePoiMutationFn = Apollo.MutationFunction<CreatePoiMutation, CreatePoiMutationVariables>;

/**
 * __useCreatePoiMutation__
 *
 * To run a mutation, you first call `useCreatePoiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePoiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPoiMutation, { data, loading, error }] = useCreatePoiMutation({
 *   variables: {
 *      sitePoisId: // value for 'sitePoisId'
 *      geoData: // value for 'geoData'
 *      poiType: // value for 'poiType'
 *      comment: // value for 'comment'
 *      scanPoisId: // value for 'scanPoisId'
 *   },
 * });
 */
export function useCreatePoiMutation(baseOptions?: Apollo.MutationHookOptions<CreatePoiMutation, CreatePoiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePoiMutation, CreatePoiMutationVariables>(CreatePoiDocument, options);
      }
export type CreatePoiMutationHookResult = ReturnType<typeof useCreatePoiMutation>;
export type CreatePoiMutationResult = Apollo.MutationResult<CreatePoiMutation>;
export type CreatePoiMutationOptions = Apollo.BaseMutationOptions<CreatePoiMutation, CreatePoiMutationVariables>;
export const CreateManholeDocument = gql`
    mutation createManhole($depth: Float!, $gpsHeight: Float!, $manholePoiId: ID!, $manholeName: String!, $owner: String!, $utilityTypes: [String]!, $overallNumber: Int, $manholeCoverShape: String!) {
  createManholeItem(
    input: {depth: $depth, gpsHeight: $gpsHeight, manholeItemPoiId: $manholePoiId, manholeName: $manholeName, owner: $owner, utilityTypes: $utilityTypes, overallNumber: $overallNumber, manholeCoverShape: $manholeCoverShape}
  ) {
    id
  }
}
    `;
export type CreateManholeMutationFn = Apollo.MutationFunction<CreateManholeMutation, CreateManholeMutationVariables>;

/**
 * __useCreateManholeMutation__
 *
 * To run a mutation, you first call `useCreateManholeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManholeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManholeMutation, { data, loading, error }] = useCreateManholeMutation({
 *   variables: {
 *      depth: // value for 'depth'
 *      gpsHeight: // value for 'gpsHeight'
 *      manholePoiId: // value for 'manholePoiId'
 *      manholeName: // value for 'manholeName'
 *      owner: // value for 'owner'
 *      utilityTypes: // value for 'utilityTypes'
 *      overallNumber: // value for 'overallNumber'
 *      manholeCoverShape: // value for 'manholeCoverShape'
 *   },
 * });
 */
export function useCreateManholeMutation(baseOptions?: Apollo.MutationHookOptions<CreateManholeMutation, CreateManholeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManholeMutation, CreateManholeMutationVariables>(CreateManholeDocument, options);
      }
export type CreateManholeMutationHookResult = ReturnType<typeof useCreateManholeMutation>;
export type CreateManholeMutationResult = Apollo.MutationResult<CreateManholeMutation>;
export type CreateManholeMutationOptions = Apollo.BaseMutationOptions<CreateManholeMutation, CreateManholeMutationVariables>;
export const CreatePipeDocument = gql`
    mutation createPipe($azimuth: Float!, $description: String!, $diameter: Float!, $hive: String!, $invertLevel: Float!, $manholeItemPipesId: ID!, $noCables: String!, $thickness: Float!, $topLevel: Float!) {
  createPipeItem(
    input: {azimuth: $azimuth, description: $description, diameter: $diameter, hive: $hive, invertLevel: $invertLevel, manholeItemPipesId: $manholeItemPipesId, noCables: $noCables, thickness: $thickness, topLevel: $topLevel}
  ) {
    id
  }
}
    `;
export type CreatePipeMutationFn = Apollo.MutationFunction<CreatePipeMutation, CreatePipeMutationVariables>;

/**
 * __useCreatePipeMutation__
 *
 * To run a mutation, you first call `useCreatePipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPipeMutation, { data, loading, error }] = useCreatePipeMutation({
 *   variables: {
 *      azimuth: // value for 'azimuth'
 *      description: // value for 'description'
 *      diameter: // value for 'diameter'
 *      hive: // value for 'hive'
 *      invertLevel: // value for 'invertLevel'
 *      manholeItemPipesId: // value for 'manholeItemPipesId'
 *      noCables: // value for 'noCables'
 *      thickness: // value for 'thickness'
 *      topLevel: // value for 'topLevel'
 *   },
 * });
 */
export function useCreatePipeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePipeMutation, CreatePipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePipeMutation, CreatePipeMutationVariables>(CreatePipeDocument, options);
      }
export type CreatePipeMutationHookResult = ReturnType<typeof useCreatePipeMutation>;
export type CreatePipeMutationResult = Apollo.MutationResult<CreatePipeMutation>;
export type CreatePipeMutationOptions = Apollo.BaseMutationOptions<CreatePipeMutation, CreatePipeMutationVariables>;
export const UpdateScanEntityDocument = gql`
    mutation updateScanEntity($id: ID!, $target_info: AWSJSON!) {
  updateScan(input: {id: $id, target_info: $target_info}) {
    id
    metadata
    siteScansId
    target_info
  }
}
    `;
export type UpdateScanEntityMutationFn = Apollo.MutationFunction<UpdateScanEntityMutation, UpdateScanEntityMutationVariables>;

/**
 * __useUpdateScanEntityMutation__
 *
 * To run a mutation, you first call `useUpdateScanEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScanEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScanEntityMutation, { data, loading, error }] = useUpdateScanEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      target_info: // value for 'target_info'
 *   },
 * });
 */
export function useUpdateScanEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScanEntityMutation, UpdateScanEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScanEntityMutation, UpdateScanEntityMutationVariables>(UpdateScanEntityDocument, options);
      }
export type UpdateScanEntityMutationHookResult = ReturnType<typeof useUpdateScanEntityMutation>;
export type UpdateScanEntityMutationResult = Apollo.MutationResult<UpdateScanEntityMutation>;
export type UpdateScanEntityMutationOptions = Apollo.BaseMutationOptions<UpdateScanEntityMutation, UpdateScanEntityMutationVariables>;
export const CreateSiteDocument = gql`
    mutation CreateSite($id: ID!, $name: String!, $customerSitesId: ID!, $locationSitesId: ID!, $s3Ref: String!, $geoData: AWSJSON!) {
  createSite(
    input: {customerSitesId: $customerSitesId, locationSitesId: $locationSitesId, geo_data: $geoData, name: $name, s3_ref: $s3Ref, id: $id}
  ) {
    id
  }
}
    `;
export type CreateSiteMutationFn = Apollo.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      customerSitesId: // value for 'customerSitesId'
 *      locationSitesId: // value for 'locationSitesId'
 *      s3Ref: // value for 's3Ref'
 *      geoData: // value for 'geoData'
 *   },
 * });
 */
export function useCreateSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
      }
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const CreateUnifyTaskDocument = gql`
    mutation CreateUnifyTask($geoData: AWSJSON!, $user_email: String!, $siteId: ID) {
  createUnifyTask(
    input: {geo_data: $geoData, user_email: $user_email, siteUnify_tasksId: $siteId}
  ) {
    id
  }
}
    `;
export type CreateUnifyTaskMutationFn = Apollo.MutationFunction<CreateUnifyTaskMutation, CreateUnifyTaskMutationVariables>;

/**
 * __useCreateUnifyTaskMutation__
 *
 * To run a mutation, you first call `useCreateUnifyTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnifyTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnifyTaskMutation, { data, loading, error }] = useCreateUnifyTaskMutation({
 *   variables: {
 *      geoData: // value for 'geoData'
 *      user_email: // value for 'user_email'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useCreateUnifyTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnifyTaskMutation, CreateUnifyTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUnifyTaskMutation, CreateUnifyTaskMutationVariables>(CreateUnifyTaskDocument, options);
      }
export type CreateUnifyTaskMutationHookResult = ReturnType<typeof useCreateUnifyTaskMutation>;
export type CreateUnifyTaskMutationResult = Apollo.MutationResult<CreateUnifyTaskMutation>;
export type CreateUnifyTaskMutationOptions = Apollo.BaseMutationOptions<CreateUnifyTaskMutation, CreateUnifyTaskMutationVariables>;
export const CreateTasksScansConnectionDocument = gql`
    mutation CreateTasksScansConnection($scanId: ID!, $unifyTaskId: ID!) {
  createTasksScansConnection(input: {scanId: $scanId, unifyTaskId: $unifyTaskId}) {
    id
  }
}
    `;
export type CreateTasksScansConnectionMutationFn = Apollo.MutationFunction<CreateTasksScansConnectionMutation, CreateTasksScansConnectionMutationVariables>;

/**
 * __useCreateTasksScansConnectionMutation__
 *
 * To run a mutation, you first call `useCreateTasksScansConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTasksScansConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTasksScansConnectionMutation, { data, loading, error }] = useCreateTasksScansConnectionMutation({
 *   variables: {
 *      scanId: // value for 'scanId'
 *      unifyTaskId: // value for 'unifyTaskId'
 *   },
 * });
 */
export function useCreateTasksScansConnectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTasksScansConnectionMutation, CreateTasksScansConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTasksScansConnectionMutation, CreateTasksScansConnectionMutationVariables>(CreateTasksScansConnectionDocument, options);
      }
export type CreateTasksScansConnectionMutationHookResult = ReturnType<typeof useCreateTasksScansConnectionMutation>;
export type CreateTasksScansConnectionMutationResult = Apollo.MutationResult<CreateTasksScansConnectionMutation>;
export type CreateTasksScansConnectionMutationOptions = Apollo.BaseMutationOptions<CreateTasksScansConnectionMutation, CreateTasksScansConnectionMutationVariables>;
export const CreateMultipleTasksScansConnectionsDocument = gql`
    mutation CreateMultipleTasksScansConnections($unifyTaskId: ID!, $scanId1: ID!, $scanId2: ID!, $scanId3: ID!, $scanId4: ID!, $scanId5: ID!) {
  createTasksScansConnection1: createTasksScansConnection(
    input: {scanId: $scanId1, unifyTaskId: $unifyTaskId}
  ) {
    id
  }
  createTasksScansConnection2: createTasksScansConnection(
    input: {scanId: $scanId2, unifyTaskId: $unifyTaskId}
  ) {
    id
  }
  createTasksScansConnection3: createTasksScansConnection(
    input: {scanId: $scanId3, unifyTaskId: $unifyTaskId}
  ) {
    id
  }
  createTasksScansConnection4: createTasksScansConnection(
    input: {scanId: $scanId4, unifyTaskId: $unifyTaskId}
  ) {
    id
  }
  createTasksScansConnection5: createTasksScansConnection(
    input: {scanId: $scanId5, unifyTaskId: $unifyTaskId}
  ) {
    id
  }
}
    `;
export type CreateMultipleTasksScansConnectionsMutationFn = Apollo.MutationFunction<CreateMultipleTasksScansConnectionsMutation, CreateMultipleTasksScansConnectionsMutationVariables>;

/**
 * __useCreateMultipleTasksScansConnectionsMutation__
 *
 * To run a mutation, you first call `useCreateMultipleTasksScansConnectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipleTasksScansConnectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultipleTasksScansConnectionsMutation, { data, loading, error }] = useCreateMultipleTasksScansConnectionsMutation({
 *   variables: {
 *      unifyTaskId: // value for 'unifyTaskId'
 *      scanId1: // value for 'scanId1'
 *      scanId2: // value for 'scanId2'
 *      scanId3: // value for 'scanId3'
 *      scanId4: // value for 'scanId4'
 *      scanId5: // value for 'scanId5'
 *   },
 * });
 */
export function useCreateMultipleTasksScansConnectionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateMultipleTasksScansConnectionsMutation, CreateMultipleTasksScansConnectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMultipleTasksScansConnectionsMutation, CreateMultipleTasksScansConnectionsMutationVariables>(CreateMultipleTasksScansConnectionsDocument, options);
      }
export type CreateMultipleTasksScansConnectionsMutationHookResult = ReturnType<typeof useCreateMultipleTasksScansConnectionsMutation>;
export type CreateMultipleTasksScansConnectionsMutationResult = Apollo.MutationResult<CreateMultipleTasksScansConnectionsMutation>;
export type CreateMultipleTasksScansConnectionsMutationOptions = Apollo.BaseMutationOptions<CreateMultipleTasksScansConnectionsMutation, CreateMultipleTasksScansConnectionsMutationVariables>;
export const DeleteUnifyTaskDocument = gql`
    mutation DeleteUnifyTask($unifyTaskId: ID!) {
  deleteUnifyTask(input: {id: $unifyTaskId}) {
    id
  }
}
    `;
export type DeleteUnifyTaskMutationFn = Apollo.MutationFunction<DeleteUnifyTaskMutation, DeleteUnifyTaskMutationVariables>;

/**
 * __useDeleteUnifyTaskMutation__
 *
 * To run a mutation, you first call `useDeleteUnifyTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnifyTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnifyTaskMutation, { data, loading, error }] = useDeleteUnifyTaskMutation({
 *   variables: {
 *      unifyTaskId: // value for 'unifyTaskId'
 *   },
 * });
 */
export function useDeleteUnifyTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnifyTaskMutation, DeleteUnifyTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnifyTaskMutation, DeleteUnifyTaskMutationVariables>(DeleteUnifyTaskDocument, options);
      }
export type DeleteUnifyTaskMutationHookResult = ReturnType<typeof useDeleteUnifyTaskMutation>;
export type DeleteUnifyTaskMutationResult = Apollo.MutationResult<DeleteUnifyTaskMutation>;
export type DeleteUnifyTaskMutationOptions = Apollo.BaseMutationOptions<DeleteUnifyTaskMutation, DeleteUnifyTaskMutationVariables>;
export const GetAllAnalyzedEntitiesIdsDocument = gql`
    query GetAllAnalyzedEntitiesIds($nextToken: String) {
  listAnalyzedEntities(nextToken: $nextToken) {
    nextToken
    items {
      id
    }
  }
}
    `;

/**
 * __useGetAllAnalyzedEntitiesIdsQuery__
 *
 * To run a query within a React component, call `useGetAllAnalyzedEntitiesIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAnalyzedEntitiesIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAnalyzedEntitiesIdsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetAllAnalyzedEntitiesIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAnalyzedEntitiesIdsQuery, GetAllAnalyzedEntitiesIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAnalyzedEntitiesIdsQuery, GetAllAnalyzedEntitiesIdsQueryVariables>(GetAllAnalyzedEntitiesIdsDocument, options);
      }
export function useGetAllAnalyzedEntitiesIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAnalyzedEntitiesIdsQuery, GetAllAnalyzedEntitiesIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAnalyzedEntitiesIdsQuery, GetAllAnalyzedEntitiesIdsQueryVariables>(GetAllAnalyzedEntitiesIdsDocument, options);
        }
export type GetAllAnalyzedEntitiesIdsQueryHookResult = ReturnType<typeof useGetAllAnalyzedEntitiesIdsQuery>;
export type GetAllAnalyzedEntitiesIdsLazyQueryHookResult = ReturnType<typeof useGetAllAnalyzedEntitiesIdsLazyQuery>;
export type GetAllAnalyzedEntitiesIdsQueryResult = Apollo.QueryResult<GetAllAnalyzedEntitiesIdsQuery, GetAllAnalyzedEntitiesIdsQueryVariables>;
export const GetAnalyzedEntitiesBySiteIdDocument = gql`
    query GetAnalyzedEntitiesBySiteId($nextToken: String, $siteId: ID, $dataSource1: String, $dataSource2: String, $dataSource3: String, $depthAlgorithm: String, $depthType: String) {
  searchAnalyzedEntities(
    nextToken: $nextToken
    filter: {siteAnalyzed_entitiesId: {eq: $siteId}, or: [{data_source: {eq: $dataSource1}}, {data_source: {eq: $dataSource2}}, {data_source: {eq: $dataSource3}}]}
    limit: 1000
  ) {
    nextToken
    items {
      analysis_confidence
      auto_confidence
      classification
      creation_timestamp
      frequency
      creation_source
      geo_data
      id
      magnetization
      s3_epls_ref
      s3_ref
      snr
      sensor_model
      updatedAt
      user
      name
      comment
      app_name
      app_version
      is_valid
      scan {
        sensor {
          model
        }
        id
        start_timestamp
        part_index
        polygon_index
        snake_index
        name
        s3_ref
        mount_method
        is_valid
        scan_type
      }
      depth_points(filter: {algorithm: {eq: $depthAlgorithm}, type: {eq: $depthType}}) {
        nextToken
        items {
          id
          lats
          lons
          hasEllipsoidHeight
          ellipsoidHeights
          depthsFromGround
          originalCrs
        }
      }
      algo_utility_metrics {
        metrics
      }
      pipesqa_view {
        comments {
          items {
            text
            timestamp
          }
        }
        flagged
        status
      }
    }
  }
}
    `;

/**
 * __useGetAnalyzedEntitiesBySiteIdQuery__
 *
 * To run a query within a React component, call `useGetAnalyzedEntitiesBySiteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyzedEntitiesBySiteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyzedEntitiesBySiteIdQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *      dataSource1: // value for 'dataSource1'
 *      dataSource2: // value for 'dataSource2'
 *      dataSource3: // value for 'dataSource3'
 *      depthAlgorithm: // value for 'depthAlgorithm'
 *      depthType: // value for 'depthType'
 *   },
 * });
 */
export function useGetAnalyzedEntitiesBySiteIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAnalyzedEntitiesBySiteIdQuery, GetAnalyzedEntitiesBySiteIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalyzedEntitiesBySiteIdQuery, GetAnalyzedEntitiesBySiteIdQueryVariables>(GetAnalyzedEntitiesBySiteIdDocument, options);
      }
export function useGetAnalyzedEntitiesBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyzedEntitiesBySiteIdQuery, GetAnalyzedEntitiesBySiteIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalyzedEntitiesBySiteIdQuery, GetAnalyzedEntitiesBySiteIdQueryVariables>(GetAnalyzedEntitiesBySiteIdDocument, options);
        }
export type GetAnalyzedEntitiesBySiteIdQueryHookResult = ReturnType<typeof useGetAnalyzedEntitiesBySiteIdQuery>;
export type GetAnalyzedEntitiesBySiteIdLazyQueryHookResult = ReturnType<typeof useGetAnalyzedEntitiesBySiteIdLazyQuery>;
export type GetAnalyzedEntitiesBySiteIdQueryResult = Apollo.QueryResult<GetAnalyzedEntitiesBySiteIdQuery, GetAnalyzedEntitiesBySiteIdQueryVariables>;
export const GetCustomersNamesDocument = gql`
    query getCustomersNames($nextToken: String) {
  searchCustomers(nextToken: $nextToken, limit: 1000) {
    items {
      id
      name
    }
    nextToken
  }
}
    `;

/**
 * __useGetCustomersNamesQuery__
 *
 * To run a query within a React component, call `useGetCustomersNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersNamesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetCustomersNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersNamesQuery, GetCustomersNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersNamesQuery, GetCustomersNamesQueryVariables>(GetCustomersNamesDocument, options);
      }
export function useGetCustomersNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersNamesQuery, GetCustomersNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersNamesQuery, GetCustomersNamesQueryVariables>(GetCustomersNamesDocument, options);
        }
export type GetCustomersNamesQueryHookResult = ReturnType<typeof useGetCustomersNamesQuery>;
export type GetCustomersNamesLazyQueryHookResult = ReturnType<typeof useGetCustomersNamesLazyQuery>;
export type GetCustomersNamesQueryResult = Apollo.QueryResult<GetCustomersNamesQuery, GetCustomersNamesQueryVariables>;
export const GetCustomersAmountWithNameDocument = gql`
    query getCustomersAmountWithName($name: String!) {
  searchCustomers(filter: {name: {eq: $name}}) {
    total
  }
}
    `;

/**
 * __useGetCustomersAmountWithNameQuery__
 *
 * To run a query within a React component, call `useGetCustomersAmountWithNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersAmountWithNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersAmountWithNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetCustomersAmountWithNameQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersAmountWithNameQuery, GetCustomersAmountWithNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersAmountWithNameQuery, GetCustomersAmountWithNameQueryVariables>(GetCustomersAmountWithNameDocument, options);
      }
export function useGetCustomersAmountWithNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersAmountWithNameQuery, GetCustomersAmountWithNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersAmountWithNameQuery, GetCustomersAmountWithNameQueryVariables>(GetCustomersAmountWithNameDocument, options);
        }
export type GetCustomersAmountWithNameQueryHookResult = ReturnType<typeof useGetCustomersAmountWithNameQuery>;
export type GetCustomersAmountWithNameLazyQueryHookResult = ReturnType<typeof useGetCustomersAmountWithNameLazyQuery>;
export type GetCustomersAmountWithNameQueryResult = Apollo.QueryResult<GetCustomersAmountWithNameQuery, GetCustomersAmountWithNameQueryVariables>;
export const GetSearchEntityDepthPointsDocument = gql`
    query getSearchEntityDepthPoints($nextToken: String, $pipeId: ID) {
  searchEntityDepthPoints(
    filter: {analyzedEntityDepth_pointsId: {eq: $pipeId}}
    limit: 1000
    nextToken: $nextToken
  ) {
    items {
      id
      siteSite_pipe_depthsId
      lats
      lons
      depthsFromGround
      ellipsoidHeights
      ellipsoidHeightsMask
      hasEllipsoidHeight
      analyzedEntityDepth_pointsId
      algorithm
      entity {
        id
      }
    }
    nextToken
  }
}
    `;

/**
 * __useGetSearchEntityDepthPointsQuery__
 *
 * To run a query within a React component, call `useGetSearchEntityDepthPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchEntityDepthPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchEntityDepthPointsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      pipeId: // value for 'pipeId'
 *   },
 * });
 */
export function useGetSearchEntityDepthPointsQuery(baseOptions?: Apollo.QueryHookOptions<GetSearchEntityDepthPointsQuery, GetSearchEntityDepthPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchEntityDepthPointsQuery, GetSearchEntityDepthPointsQueryVariables>(GetSearchEntityDepthPointsDocument, options);
      }
export function useGetSearchEntityDepthPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchEntityDepthPointsQuery, GetSearchEntityDepthPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchEntityDepthPointsQuery, GetSearchEntityDepthPointsQueryVariables>(GetSearchEntityDepthPointsDocument, options);
        }
export type GetSearchEntityDepthPointsQueryHookResult = ReturnType<typeof useGetSearchEntityDepthPointsQuery>;
export type GetSearchEntityDepthPointsLazyQueryHookResult = ReturnType<typeof useGetSearchEntityDepthPointsLazyQuery>;
export type GetSearchEntityDepthPointsQueryResult = Apollo.QueryResult<GetSearchEntityDepthPointsQuery, GetSearchEntityDepthPointsQueryVariables>;
export const GetLocationsDocument = gql`
    query getLocations($nextToken: String) {
  searchLocations(nextToken: $nextToken, limit: 1000) {
    nextToken
    items {
      country
      id
      region
      state
    }
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetLocationsAmountWithFilterDocument = gql`
    query getLocationsAmountWithFilter($state: String!, $country: String!) {
  searchLocations(filter: {state: {eq: $state}, country: {eq: $country}}) {
    total
  }
}
    `;

/**
 * __useGetLocationsAmountWithFilterQuery__
 *
 * To run a query within a React component, call `useGetLocationsAmountWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsAmountWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsAmountWithFilterQuery({
 *   variables: {
 *      state: // value for 'state'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetLocationsAmountWithFilterQuery(baseOptions: Apollo.QueryHookOptions<GetLocationsAmountWithFilterQuery, GetLocationsAmountWithFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsAmountWithFilterQuery, GetLocationsAmountWithFilterQueryVariables>(GetLocationsAmountWithFilterDocument, options);
      }
export function useGetLocationsAmountWithFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsAmountWithFilterQuery, GetLocationsAmountWithFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsAmountWithFilterQuery, GetLocationsAmountWithFilterQueryVariables>(GetLocationsAmountWithFilterDocument, options);
        }
export type GetLocationsAmountWithFilterQueryHookResult = ReturnType<typeof useGetLocationsAmountWithFilterQuery>;
export type GetLocationsAmountWithFilterLazyQueryHookResult = ReturnType<typeof useGetLocationsAmountWithFilterLazyQuery>;
export type GetLocationsAmountWithFilterQueryResult = Apollo.QueryResult<GetLocationsAmountWithFilterQuery, GetLocationsAmountWithFilterQueryVariables>;
export const SearchMappingEntitiesBySiteDocument = gql`
    query SearchMappingEntitiesBySite($nextToken: String, $siteId: ID) {
  searchMappingEntities(
    filter: {classification: {eq: "ExoTools"}, siteMapping_entitiesId: {eq: $siteId}}
    nextToken: $nextToken
    limit: 1000
  ) {
    items {
      geo_data
      color
      depth
      object_type
      id
      properties
      createdAt
    }
    nextToken
  }
}
    `;

/**
 * __useSearchMappingEntitiesBySiteQuery__
 *
 * To run a query within a React component, call `useSearchMappingEntitiesBySiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMappingEntitiesBySiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMappingEntitiesBySiteQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSearchMappingEntitiesBySiteQuery(baseOptions?: Apollo.QueryHookOptions<SearchMappingEntitiesBySiteQuery, SearchMappingEntitiesBySiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchMappingEntitiesBySiteQuery, SearchMappingEntitiesBySiteQueryVariables>(SearchMappingEntitiesBySiteDocument, options);
      }
export function useSearchMappingEntitiesBySiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchMappingEntitiesBySiteQuery, SearchMappingEntitiesBySiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchMappingEntitiesBySiteQuery, SearchMappingEntitiesBySiteQueryVariables>(SearchMappingEntitiesBySiteDocument, options);
        }
export type SearchMappingEntitiesBySiteQueryHookResult = ReturnType<typeof useSearchMappingEntitiesBySiteQuery>;
export type SearchMappingEntitiesBySiteLazyQueryHookResult = ReturnType<typeof useSearchMappingEntitiesBySiteLazyQuery>;
export type SearchMappingEntitiesBySiteQueryResult = Apollo.QueryResult<SearchMappingEntitiesBySiteQuery, SearchMappingEntitiesBySiteQueryVariables>;
export const GetListMappingEntitiesDocument = gql`
    query GetListMappingEntities($nextToken: String, $siteId: ID) {
  listMappingEntities(
    filter: {classification: {eq: "ExoTools"}, siteMapping_entitiesId: {eq: $siteId}}
    nextToken: $nextToken
    limit: 1000
  ) {
    items {
      id
      geo_data
      classification
      createdAt
      object_type
      color
      depth
      properties
    }
    nextToken
  }
}
    `;

/**
 * __useGetListMappingEntitiesQuery__
 *
 * To run a query within a React component, call `useGetListMappingEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListMappingEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListMappingEntitiesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetListMappingEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetListMappingEntitiesQuery, GetListMappingEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListMappingEntitiesQuery, GetListMappingEntitiesQueryVariables>(GetListMappingEntitiesDocument, options);
      }
export function useGetListMappingEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListMappingEntitiesQuery, GetListMappingEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListMappingEntitiesQuery, GetListMappingEntitiesQueryVariables>(GetListMappingEntitiesDocument, options);
        }
export type GetListMappingEntitiesQueryHookResult = ReturnType<typeof useGetListMappingEntitiesQuery>;
export type GetListMappingEntitiesLazyQueryHookResult = ReturnType<typeof useGetListMappingEntitiesLazyQuery>;
export type GetListMappingEntitiesQueryResult = Apollo.QueryResult<GetListMappingEntitiesQuery, GetListMappingEntitiesQueryVariables>;
export const GetPlanningPolygonsDocument = gql`
    query GetPlanningPolygons($siteId: ID!, $nextToken: String) {
  getSite(id: $siteId) {
    id
    planned_entities {
      id
      planned_polygons(nextToken: $nextToken) {
        nextToken
        items {
          color
          comment
          createdAt
          geo_data
          name
          override_color
          sensor_models
          mount_method
          id
          is_rescan
        }
      }
    }
  }
}
    `;

/**
 * __useGetPlanningPolygonsQuery__
 *
 * To run a query within a React component, call `useGetPlanningPolygonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanningPolygonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanningPolygonsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetPlanningPolygonsQuery(baseOptions: Apollo.QueryHookOptions<GetPlanningPolygonsQuery, GetPlanningPolygonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanningPolygonsQuery, GetPlanningPolygonsQueryVariables>(GetPlanningPolygonsDocument, options);
      }
export function useGetPlanningPolygonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanningPolygonsQuery, GetPlanningPolygonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanningPolygonsQuery, GetPlanningPolygonsQueryVariables>(GetPlanningPolygonsDocument, options);
        }
export type GetPlanningPolygonsQueryHookResult = ReturnType<typeof useGetPlanningPolygonsQuery>;
export type GetPlanningPolygonsLazyQueryHookResult = ReturnType<typeof useGetPlanningPolygonsLazyQuery>;
export type GetPlanningPolygonsQueryResult = Apollo.QueryResult<GetPlanningPolygonsQuery, GetPlanningPolygonsQueryVariables>;
export const GetPlannedPolygonTransmittersDocument = gql`
    query getPlannedPolygonTransmitters($polygonId: ID!, $nextToken: String) {
  getPlannedPolygon(id: $polygonId) {
    id
    planned_transmitters(nextToken: $nextToken) {
      items {
        color
        comment
        geo_data
        id
        name
        override_color
        method
      }
      nextToken
    }
  }
}
    `;

/**
 * __useGetPlannedPolygonTransmittersQuery__
 *
 * To run a query within a React component, call `useGetPlannedPolygonTransmittersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlannedPolygonTransmittersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlannedPolygonTransmittersQuery({
 *   variables: {
 *      polygonId: // value for 'polygonId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetPlannedPolygonTransmittersQuery(baseOptions: Apollo.QueryHookOptions<GetPlannedPolygonTransmittersQuery, GetPlannedPolygonTransmittersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlannedPolygonTransmittersQuery, GetPlannedPolygonTransmittersQueryVariables>(GetPlannedPolygonTransmittersDocument, options);
      }
export function useGetPlannedPolygonTransmittersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlannedPolygonTransmittersQuery, GetPlannedPolygonTransmittersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlannedPolygonTransmittersQuery, GetPlannedPolygonTransmittersQueryVariables>(GetPlannedPolygonTransmittersDocument, options);
        }
export type GetPlannedPolygonTransmittersQueryHookResult = ReturnType<typeof useGetPlannedPolygonTransmittersQuery>;
export type GetPlannedPolygonTransmittersLazyQueryHookResult = ReturnType<typeof useGetPlannedPolygonTransmittersLazyQuery>;
export type GetPlannedPolygonTransmittersQueryResult = Apollo.QueryResult<GetPlannedPolygonTransmittersQuery, GetPlannedPolygonTransmittersQueryVariables>;
export const GetScansAfterTimestampDocument = gql`
    query GetScansAfterTimestamp($nextToken: String, $timestamp: Int!) {
  searchScans(
    filter: {start_timestamp: {gt: $timestamp}}
    nextToken: $nextToken
    limit: 1000
  ) {
    items {
      ...ScanFields
    }
    nextToken
  }
}
    ${ScanFieldsFragmentDoc}`;

/**
 * __useGetScansAfterTimestampQuery__
 *
 * To run a query within a React component, call `useGetScansAfterTimestampQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScansAfterTimestampQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScansAfterTimestampQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useGetScansAfterTimestampQuery(baseOptions: Apollo.QueryHookOptions<GetScansAfterTimestampQuery, GetScansAfterTimestampQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScansAfterTimestampQuery, GetScansAfterTimestampQueryVariables>(GetScansAfterTimestampDocument, options);
      }
export function useGetScansAfterTimestampLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScansAfterTimestampQuery, GetScansAfterTimestampQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScansAfterTimestampQuery, GetScansAfterTimestampQueryVariables>(GetScansAfterTimestampDocument, options);
        }
export type GetScansAfterTimestampQueryHookResult = ReturnType<typeof useGetScansAfterTimestampQuery>;
export type GetScansAfterTimestampLazyQueryHookResult = ReturnType<typeof useGetScansAfterTimestampLazyQuery>;
export type GetScansAfterTimestampQueryResult = Apollo.QueryResult<GetScansAfterTimestampQuery, GetScansAfterTimestampQueryVariables>;
export const GetScanTransimtterSetupsDocument = gql`
    query GetScanTransimtterSetups($id: ID!) {
  searchScans(filter: {id: {eq: $id}}) {
    nextToken
    total
    items {
      transmitter_setups {
        items {
          frequency
        }
      }
    }
  }
}
    `;

/**
 * __useGetScanTransimtterSetupsQuery__
 *
 * To run a query within a React component, call `useGetScanTransimtterSetupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanTransimtterSetupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanTransimtterSetupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScanTransimtterSetupsQuery(baseOptions: Apollo.QueryHookOptions<GetScanTransimtterSetupsQuery, GetScanTransimtterSetupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScanTransimtterSetupsQuery, GetScanTransimtterSetupsQueryVariables>(GetScanTransimtterSetupsDocument, options);
      }
export function useGetScanTransimtterSetupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScanTransimtterSetupsQuery, GetScanTransimtterSetupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScanTransimtterSetupsQuery, GetScanTransimtterSetupsQueryVariables>(GetScanTransimtterSetupsDocument, options);
        }
export type GetScanTransimtterSetupsQueryHookResult = ReturnType<typeof useGetScanTransimtterSetupsQuery>;
export type GetScanTransimtterSetupsLazyQueryHookResult = ReturnType<typeof useGetScanTransimtterSetupsLazyQuery>;
export type GetScanTransimtterSetupsQueryResult = Apollo.QueryResult<GetScanTransimtterSetupsQuery, GetScanTransimtterSetupsQueryVariables>;
export const GetSiteScansDocument = gql`
    query getSiteScans($nextToken: String, $siteId: ID!) {
  getSite(id: $siteId) {
    id
    scans(nextToken: $nextToken, limit: 1000) {
      items {
        ...ScanFields
      }
      nextToken
    }
  }
}
    ${ScanFieldsFragmentDoc}`;

/**
 * __useGetSiteScansQuery__
 *
 * To run a query within a React component, call `useGetSiteScansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteScansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteScansQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetSiteScansQuery(baseOptions: Apollo.QueryHookOptions<GetSiteScansQuery, GetSiteScansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteScansQuery, GetSiteScansQueryVariables>(GetSiteScansDocument, options);
      }
export function useGetSiteScansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteScansQuery, GetSiteScansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteScansQuery, GetSiteScansQueryVariables>(GetSiteScansDocument, options);
        }
export type GetSiteScansQueryHookResult = ReturnType<typeof useGetSiteScansQuery>;
export type GetSiteScansLazyQueryHookResult = ReturnType<typeof useGetSiteScansLazyQuery>;
export type GetSiteScansQueryResult = Apollo.QueryResult<GetSiteScansQuery, GetSiteScansQueryVariables>;
export const GetSiteScansDetailsDocument = gql`
    query getSiteScansDetails($nextToken: String, $siteId: ID!) {
  getSite(id: $siteId) {
    id
    scans(nextToken: $nextToken, limit: 1000) {
      items {
        id
        name
        start_timestamp
        polygon_index
        part_index
        snake_index
        bounding_polygon_s3_ref
        mount_method
        sensor {
          model
        }
      }
      nextToken
    }
  }
}
    `;

/**
 * __useGetSiteScansDetailsQuery__
 *
 * To run a query within a React component, call `useGetSiteScansDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteScansDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteScansDetailsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetSiteScansDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetSiteScansDetailsQuery, GetSiteScansDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteScansDetailsQuery, GetSiteScansDetailsQueryVariables>(GetSiteScansDetailsDocument, options);
      }
export function useGetSiteScansDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteScansDetailsQuery, GetSiteScansDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteScansDetailsQuery, GetSiteScansDetailsQueryVariables>(GetSiteScansDetailsDocument, options);
        }
export type GetSiteScansDetailsQueryHookResult = ReturnType<typeof useGetSiteScansDetailsQuery>;
export type GetSiteScansDetailsLazyQueryHookResult = ReturnType<typeof useGetSiteScansDetailsLazyQuery>;
export type GetSiteScansDetailsQueryResult = Apollo.QueryResult<GetSiteScansDetailsQuery, GetSiteScansDetailsQueryVariables>;
export const SearchScansPsdDocument = gql`
    query searchScansPSD($nextToken: String, $siteId: ID!, $is_valid: Boolean) {
  searchScans(
    filter: {siteScansId: {eq: $siteId}, is_valid: {eq: $is_valid}, sensor_type: {eq: "ELECTROMAGNETIC"}, scan_type: {match: "^(TRANSMITTER|SURVEY)$"}}
    nextToken: $nextToken
    limit: 1000
  ) {
    nextToken
    total
    items {
      id
      name
      start_timestamp
      polygon_index
      part_index
      snake_index
      bounding_polygon_s3_ref
      sensor {
        model
      }
    }
  }
}
    `;

/**
 * __useSearchScansPsdQuery__
 *
 * To run a query within a React component, call `useSearchScansPsdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchScansPsdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchScansPsdQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *      is_valid: // value for 'is_valid'
 *   },
 * });
 */
export function useSearchScansPsdQuery(baseOptions: Apollo.QueryHookOptions<SearchScansPsdQuery, SearchScansPsdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchScansPsdQuery, SearchScansPsdQueryVariables>(SearchScansPsdDocument, options);
      }
export function useSearchScansPsdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchScansPsdQuery, SearchScansPsdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchScansPsdQuery, SearchScansPsdQueryVariables>(SearchScansPsdDocument, options);
        }
export type SearchScansPsdQueryHookResult = ReturnType<typeof useSearchScansPsdQuery>;
export type SearchScansPsdLazyQueryHookResult = ReturnType<typeof useSearchScansPsdLazyQuery>;
export type SearchScansPsdQueryResult = Apollo.QueryResult<SearchScansPsdQuery, SearchScansPsdQueryVariables>;
export const SearchScansLocationValidDocument = gql`
    query searchScansLocationValid($nextToken: String, $siteId: ID!, $is_valid: Boolean) {
  searchScans(
    filter: {siteScansId: {eq: $siteId}, is_valid: {eq: $is_valid}}
    nextToken: $nextToken
    limit: 500
  ) {
    nextToken
    total
    items {
      id
      name
      start_timestamp
      polygon_index
      part_index
      snake_index
      bounding_polygon_s3_ref
      sensor {
        model
      }
    }
  }
}
    `;

/**
 * __useSearchScansLocationValidQuery__
 *
 * To run a query within a React component, call `useSearchScansLocationValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchScansLocationValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchScansLocationValidQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *      is_valid: // value for 'is_valid'
 *   },
 * });
 */
export function useSearchScansLocationValidQuery(baseOptions: Apollo.QueryHookOptions<SearchScansLocationValidQuery, SearchScansLocationValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchScansLocationValidQuery, SearchScansLocationValidQueryVariables>(SearchScansLocationValidDocument, options);
      }
export function useSearchScansLocationValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchScansLocationValidQuery, SearchScansLocationValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchScansLocationValidQuery, SearchScansLocationValidQueryVariables>(SearchScansLocationValidDocument, options);
        }
export type SearchScansLocationValidQueryHookResult = ReturnType<typeof useSearchScansLocationValidQuery>;
export type SearchScansLocationValidLazyQueryHookResult = ReturnType<typeof useSearchScansLocationValidLazyQuery>;
export type SearchScansLocationValidQueryResult = Apollo.QueryResult<SearchScansLocationValidQuery, SearchScansLocationValidQueryVariables>;
export const SearchScansLocationDocument = gql`
    query searchScansLocation($nextToken: String, $siteId: ID!) {
  searchScans(
    filter: {siteScansId: {eq: $siteId}}
    nextToken: $nextToken
    limit: 500
  ) {
    nextToken
    total
    items {
      id
      name
      start_timestamp
      polygon_index
      part_index
      snake_index
      bounding_polygon_s3_ref
      sensor {
        model
      }
    }
  }
}
    `;

/**
 * __useSearchScansLocationQuery__
 *
 * To run a query within a React component, call `useSearchScansLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchScansLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchScansLocationQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSearchScansLocationQuery(baseOptions: Apollo.QueryHookOptions<SearchScansLocationQuery, SearchScansLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchScansLocationQuery, SearchScansLocationQueryVariables>(SearchScansLocationDocument, options);
      }
export function useSearchScansLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchScansLocationQuery, SearchScansLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchScansLocationQuery, SearchScansLocationQueryVariables>(SearchScansLocationDocument, options);
        }
export type SearchScansLocationQueryHookResult = ReturnType<typeof useSearchScansLocationQuery>;
export type SearchScansLocationLazyQueryHookResult = ReturnType<typeof useSearchScansLocationLazyQuery>;
export type SearchScansLocationQueryResult = Apollo.QueryResult<SearchScansLocationQuery, SearchScansLocationQueryVariables>;
export const SearchScansMonitorInfoDocument = gql`
    query searchScansMonitorInfo($nextToken: String, $startTimeStamp: Int!, $endTimeStamp: Int!, $siteId: ID) {
  searchScans(
    filter: {start_timestamp: {gte: $startTimeStamp, lt: $endTimeStamp}, siteScansId: {eq: $siteId}}
    nextToken: $nextToken
  ) {
    nextToken
    items {
      id
      name
      is_valid
      scan_type
      machine_id
      sensor {
        model
      }
      monitoring_steps {
        scan_step {
          end_time
          error_message
          start_time
          status
        }
        upload_step {
          start_time
          end_time
          error_message
          status
          s3_ref
          uploader
          upload_speed
          updatedAt
        }
        preloader_step {
          start_time
          end_time
          error_message
          status
          id
        }
        utility_finder_step {
          id
          start_time
          status
          error_message
          end_time
        }
        analysis_step {
          access_time
          error_message
          status
        }
      }
    }
  }
}
    `;

/**
 * __useSearchScansMonitorInfoQuery__
 *
 * To run a query within a React component, call `useSearchScansMonitorInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchScansMonitorInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchScansMonitorInfoQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      startTimeStamp: // value for 'startTimeStamp'
 *      endTimeStamp: // value for 'endTimeStamp'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSearchScansMonitorInfoQuery(baseOptions: Apollo.QueryHookOptions<SearchScansMonitorInfoQuery, SearchScansMonitorInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchScansMonitorInfoQuery, SearchScansMonitorInfoQueryVariables>(SearchScansMonitorInfoDocument, options);
      }
export function useSearchScansMonitorInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchScansMonitorInfoQuery, SearchScansMonitorInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchScansMonitorInfoQuery, SearchScansMonitorInfoQueryVariables>(SearchScansMonitorInfoDocument, options);
        }
export type SearchScansMonitorInfoQueryHookResult = ReturnType<typeof useSearchScansMonitorInfoQuery>;
export type SearchScansMonitorInfoLazyQueryHookResult = ReturnType<typeof useSearchScansMonitorInfoLazyQuery>;
export type SearchScansMonitorInfoQueryResult = Apollo.QueryResult<SearchScansMonitorInfoQuery, SearchScansMonitorInfoQueryVariables>;
export const GetPreloadRunsDocument = gql`
    query getPreloadRuns($nextToken: String, $stepId: ID!) {
  getPreloaderStep(id: $stepId) {
    id
    preload_runs(nextToken: $nextToken, limit: 100) {
      nextToken
      items {
        id
        error_message
        preloaded_scan_s3_refs
        end_time
        start_time
        status
        target_name
        target_type
        products
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetPreloadRunsQuery__
 *
 * To run a query within a React component, call `useGetPreloadRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreloadRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreloadRunsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      stepId: // value for 'stepId'
 *   },
 * });
 */
export function useGetPreloadRunsQuery(baseOptions: Apollo.QueryHookOptions<GetPreloadRunsQuery, GetPreloadRunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreloadRunsQuery, GetPreloadRunsQueryVariables>(GetPreloadRunsDocument, options);
      }
export function useGetPreloadRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreloadRunsQuery, GetPreloadRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreloadRunsQuery, GetPreloadRunsQueryVariables>(GetPreloadRunsDocument, options);
        }
export type GetPreloadRunsQueryHookResult = ReturnType<typeof useGetPreloadRunsQuery>;
export type GetPreloadRunsLazyQueryHookResult = ReturnType<typeof useGetPreloadRunsLazyQuery>;
export type GetPreloadRunsQueryResult = Apollo.QueryResult<GetPreloadRunsQuery, GetPreloadRunsQueryVariables>;
export const GetPipelinesDocument = gql`
    query getPipelines($nextToken: String, $stepId: ID!) {
  getUtilityFinderStep(id: $stepId) {
    id
    pipelines(nextToken: $nextToken) {
      nextToken
      items {
        epls_s3_ref
        error_message
        id
        name
        start_time
        end_time
        status
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetPipelinesQuery__
 *
 * To run a query within a React component, call `useGetPipelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelinesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      stepId: // value for 'stepId'
 *   },
 * });
 */
export function useGetPipelinesQuery(baseOptions: Apollo.QueryHookOptions<GetPipelinesQuery, GetPipelinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelinesQuery, GetPipelinesQueryVariables>(GetPipelinesDocument, options);
      }
export function useGetPipelinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelinesQuery, GetPipelinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelinesQuery, GetPipelinesQueryVariables>(GetPipelinesDocument, options);
        }
export type GetPipelinesQueryHookResult = ReturnType<typeof useGetPipelinesQuery>;
export type GetPipelinesLazyQueryHookResult = ReturnType<typeof useGetPipelinesLazyQuery>;
export type GetPipelinesQueryResult = Apollo.QueryResult<GetPipelinesQuery, GetPipelinesQueryVariables>;
export const GetSitesWithScansInDateRangeDocument = gql`
    query getSitesWithScansInDateRange($nextToken: String, $startTimeStamp: Int!, $endTimeStamp: Int!) {
  searchScans(
    filter: {start_timestamp: {gte: $startTimeStamp, lte: $endTimeStamp}}
    nextToken: $nextToken
  ) {
    items {
      site {
        locationSitesId
        name
        id
      }
      sensor {
        model
        id
      }
    }
    nextToken
  }
}
    `;

/**
 * __useGetSitesWithScansInDateRangeQuery__
 *
 * To run a query within a React component, call `useGetSitesWithScansInDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesWithScansInDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesWithScansInDateRangeQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      startTimeStamp: // value for 'startTimeStamp'
 *      endTimeStamp: // value for 'endTimeStamp'
 *   },
 * });
 */
export function useGetSitesWithScansInDateRangeQuery(baseOptions: Apollo.QueryHookOptions<GetSitesWithScansInDateRangeQuery, GetSitesWithScansInDateRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSitesWithScansInDateRangeQuery, GetSitesWithScansInDateRangeQueryVariables>(GetSitesWithScansInDateRangeDocument, options);
      }
export function useGetSitesWithScansInDateRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSitesWithScansInDateRangeQuery, GetSitesWithScansInDateRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSitesWithScansInDateRangeQuery, GetSitesWithScansInDateRangeQueryVariables>(GetSitesWithScansInDateRangeDocument, options);
        }
export type GetSitesWithScansInDateRangeQueryHookResult = ReturnType<typeof useGetSitesWithScansInDateRangeQuery>;
export type GetSitesWithScansInDateRangeLazyQueryHookResult = ReturnType<typeof useGetSitesWithScansInDateRangeLazyQuery>;
export type GetSitesWithScansInDateRangeQueryResult = Apollo.QueryResult<GetSitesWithScansInDateRangeQuery, GetSitesWithScansInDateRangeQueryVariables>;
export const GetTargetScanDocument = gql`
    query getTargetScan($nextToken: String, $siteId: ID) {
  searchScans(
    filter: {scan_type: {eq: "TARGET"}, siteScansId: {eq: $siteId}}
    limit: 1000
    nextToken: $nextToken
  ) {
    total
    items {
      id
      name
      scan_type
      metadata
      target_info
      site {
        id
        location {
          country
        }
      }
    }
    nextToken
  }
}
    `;

/**
 * __useGetTargetScanQuery__
 *
 * To run a query within a React component, call `useGetTargetScanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetScanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetScanQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetTargetScanQuery(baseOptions?: Apollo.QueryHookOptions<GetTargetScanQuery, GetTargetScanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetScanQuery, GetTargetScanQueryVariables>(GetTargetScanDocument, options);
      }
export function useGetTargetScanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetScanQuery, GetTargetScanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetScanQuery, GetTargetScanQueryVariables>(GetTargetScanDocument, options);
        }
export type GetTargetScanQueryHookResult = ReturnType<typeof useGetTargetScanQuery>;
export type GetTargetScanLazyQueryHookResult = ReturnType<typeof useGetTargetScanLazyQuery>;
export type GetTargetScanQueryResult = Apollo.QueryResult<GetTargetScanQuery, GetTargetScanQueryVariables>;
export const GetTargetScanManHoleDocument = gql`
    query getTargetScanManHole($nextToken: String, $scanId: ID) {
  searchScans(
    filter: {scan_type: {eq: "TARGET"}, id: {eq: $scanId}}
    limit: 1000
    nextToken: $nextToken
  ) {
    total
    items {
      id
      name
      scan_type
      metadata
      target_info
      site {
        id
        name
        location {
          country
        }
      }
    }
    nextToken
  }
}
    `;

/**
 * __useGetTargetScanManHoleQuery__
 *
 * To run a query within a React component, call `useGetTargetScanManHoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetScanManHoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetScanManHoleQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      scanId: // value for 'scanId'
 *   },
 * });
 */
export function useGetTargetScanManHoleQuery(baseOptions?: Apollo.QueryHookOptions<GetTargetScanManHoleQuery, GetTargetScanManHoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetScanManHoleQuery, GetTargetScanManHoleQueryVariables>(GetTargetScanManHoleDocument, options);
      }
export function useGetTargetScanManHoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetScanManHoleQuery, GetTargetScanManHoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetScanManHoleQuery, GetTargetScanManHoleQueryVariables>(GetTargetScanManHoleDocument, options);
        }
export type GetTargetScanManHoleQueryHookResult = ReturnType<typeof useGetTargetScanManHoleQuery>;
export type GetTargetScanManHoleLazyQueryHookResult = ReturnType<typeof useGetTargetScanManHoleLazyQuery>;
export type GetTargetScanManHoleQueryResult = Apollo.QueryResult<GetTargetScanManHoleQuery, GetTargetScanManHoleQueryVariables>;
export const GetDailySummaryInfoDocument = gql`
    query getDailySummaryInfo($nextToken: String, $startTimeStamp: Int!, $endTimeStamp: Int!, $siteId: ID) {
  searchScans(
    filter: {start_timestamp: {gte: $startTimeStamp, lte: $endTimeStamp}, siteScansId: {eq: $siteId}}
    nextToken: $nextToken
  ) {
    items {
      is_valid
      sensor {
        model
      }
      scan_type
      name
      start_timestamp
      end_timestamp
      s3_ref
      polygon_index
      part_index
      snake_index
      comment
      frequency
      transmitter_setups {
        items {
          frequency
        }
      }
    }
    nextToken
  }
}
    `;

/**
 * __useGetDailySummaryInfoQuery__
 *
 * To run a query within a React component, call `useGetDailySummaryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailySummaryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailySummaryInfoQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      startTimeStamp: // value for 'startTimeStamp'
 *      endTimeStamp: // value for 'endTimeStamp'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetDailySummaryInfoQuery(baseOptions: Apollo.QueryHookOptions<GetDailySummaryInfoQuery, GetDailySummaryInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDailySummaryInfoQuery, GetDailySummaryInfoQueryVariables>(GetDailySummaryInfoDocument, options);
      }
export function useGetDailySummaryInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailySummaryInfoQuery, GetDailySummaryInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDailySummaryInfoQuery, GetDailySummaryInfoQueryVariables>(GetDailySummaryInfoDocument, options);
        }
export type GetDailySummaryInfoQueryHookResult = ReturnType<typeof useGetDailySummaryInfoQuery>;
export type GetDailySummaryInfoLazyQueryHookResult = ReturnType<typeof useGetDailySummaryInfoLazyQuery>;
export type GetDailySummaryInfoQueryResult = Apollo.QueryResult<GetDailySummaryInfoQuery, GetDailySummaryInfoQueryVariables>;
export const GetSiteScansRefsDocument = gql`
    query getSiteScansRefs($nextToken: String, $siteId: ID!) {
  getSite(id: $siteId) {
    id
    scans(nextToken: $nextToken, limit: 1000) {
      items {
        id
        s3_ref
      }
      nextToken
    }
  }
}
    `;

/**
 * __useGetSiteScansRefsQuery__
 *
 * To run a query within a React component, call `useGetSiteScansRefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteScansRefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteScansRefsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetSiteScansRefsQuery(baseOptions: Apollo.QueryHookOptions<GetSiteScansRefsQuery, GetSiteScansRefsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteScansRefsQuery, GetSiteScansRefsQueryVariables>(GetSiteScansRefsDocument, options);
      }
export function useGetSiteScansRefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteScansRefsQuery, GetSiteScansRefsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteScansRefsQuery, GetSiteScansRefsQueryVariables>(GetSiteScansRefsDocument, options);
        }
export type GetSiteScansRefsQueryHookResult = ReturnType<typeof useGetSiteScansRefsQuery>;
export type GetSiteScansRefsLazyQueryHookResult = ReturnType<typeof useGetSiteScansRefsLazyQuery>;
export type GetSiteScansRefsQueryResult = Apollo.QueryResult<GetSiteScansRefsQuery, GetSiteScansRefsQueryVariables>;
export const GetSensorModelsDocument = gql`
    query GetSensorModels($nextToken: String) {
  listSensors(nextToken: $nextToken, limit: 1000) {
    items {
      id
      model
    }
    nextToken
  }
}
    `;

/**
 * __useGetSensorModelsQuery__
 *
 * To run a query within a React component, call `useGetSensorModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorModelsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetSensorModelsQuery(baseOptions?: Apollo.QueryHookOptions<GetSensorModelsQuery, GetSensorModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSensorModelsQuery, GetSensorModelsQueryVariables>(GetSensorModelsDocument, options);
      }
export function useGetSensorModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensorModelsQuery, GetSensorModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSensorModelsQuery, GetSensorModelsQueryVariables>(GetSensorModelsDocument, options);
        }
export type GetSensorModelsQueryHookResult = ReturnType<typeof useGetSensorModelsQuery>;
export type GetSensorModelsLazyQueryHookResult = ReturnType<typeof useGetSensorModelsLazyQuery>;
export type GetSensorModelsQueryResult = Apollo.QueryResult<GetSensorModelsQuery, GetSensorModelsQueryVariables>;
export const GetSiteTargetEntitiesDocument = gql`
    query getSiteTargetEntities($nextToken: String, $siteId: ID) {
  searchTargetEntities(
    filter: {siteTarget_entitiesId: {eq: $siteId}}
    limit: 1000
    nextToken: $nextToken
  ) {
    items {
      app_name
      app_version
      comment
      creation_timestamp
      display_params
      geo_data
      is_valid
      name
      s3_ref
      sensor_model
      target_type
      user
      scan {
        mount_method
        sensor {
          model
        }
        name
        part_index
        polygon_index
        start_timestamp
        snake_index
        s3_ref
        is_valid
        scan_type
      }
    }
    nextToken
  }
}
    `;

/**
 * __useGetSiteTargetEntitiesQuery__
 *
 * To run a query within a React component, call `useGetSiteTargetEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteTargetEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteTargetEntitiesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetSiteTargetEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetSiteTargetEntitiesQuery, GetSiteTargetEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteTargetEntitiesQuery, GetSiteTargetEntitiesQueryVariables>(GetSiteTargetEntitiesDocument, options);
      }
export function useGetSiteTargetEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteTargetEntitiesQuery, GetSiteTargetEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteTargetEntitiesQuery, GetSiteTargetEntitiesQueryVariables>(GetSiteTargetEntitiesDocument, options);
        }
export type GetSiteTargetEntitiesQueryHookResult = ReturnType<typeof useGetSiteTargetEntitiesQuery>;
export type GetSiteTargetEntitiesLazyQueryHookResult = ReturnType<typeof useGetSiteTargetEntitiesLazyQuery>;
export type GetSiteTargetEntitiesQueryResult = Apollo.QueryResult<GetSiteTargetEntitiesQuery, GetSiteTargetEntitiesQueryVariables>;
export const GetSiteTransmittersDocument = gql`
    query getSiteTransmitters($nextToken: String, $siteId: ID) {
  searchTransmitterSetups(
    nextToken: $nextToken
    filter: {siteTransmitter_setupsId: {eq: $siteId}}
    limit: 1000
  ) {
    items {
      siteTransmitter_setupsId
      id
      geo_data
      frequency
      method
      source
      s3_ref
      snr
      is_valid
      scan {
        mount_method
        sensor {
          model
        }
        name
        part_index
        polygon_index
        start_timestamp
        snake_index
        s3_ref
        is_valid
        scan_type
      }
      transmitter {
        asset_id
      }
    }
    nextToken
  }
}
    `;

/**
 * __useGetSiteTransmittersQuery__
 *
 * To run a query within a React component, call `useGetSiteTransmittersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteTransmittersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteTransmittersQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetSiteTransmittersQuery(baseOptions?: Apollo.QueryHookOptions<GetSiteTransmittersQuery, GetSiteTransmittersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteTransmittersQuery, GetSiteTransmittersQueryVariables>(GetSiteTransmittersDocument, options);
      }
export function useGetSiteTransmittersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteTransmittersQuery, GetSiteTransmittersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteTransmittersQuery, GetSiteTransmittersQueryVariables>(GetSiteTransmittersDocument, options);
        }
export type GetSiteTransmittersQueryHookResult = ReturnType<typeof useGetSiteTransmittersQuery>;
export type GetSiteTransmittersLazyQueryHookResult = ReturnType<typeof useGetSiteTransmittersLazyQuery>;
export type GetSiteTransmittersQueryResult = Apollo.QueryResult<GetSiteTransmittersQuery, GetSiteTransmittersQueryVariables>;
export const GetSiteTravelCoursesDocument = gql`
    query getSiteTravelCourses($nextToken: String, $siteId: ID) {
  searchTravelCourses(
    filter: {siteTravel_coursesId: {eq: $siteId}}
    limit: 250
    nextToken: $nextToken
  ) {
    nextToken
    items {
      geo_data
      s3_ref
      id
      tag
      is_valid
      gps_fixed_ratio
      scan {
        id
        mount_method
        name
        part_index
        polygon_index
        snake_index
        start_timestamp
        start_time_iso
        sensor {
          model
        }
        s3_ref
        is_valid
        scan_type
      }
    }
  }
}
    `;

/**
 * __useGetSiteTravelCoursesQuery__
 *
 * To run a query within a React component, call `useGetSiteTravelCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteTravelCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteTravelCoursesQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetSiteTravelCoursesQuery(baseOptions?: Apollo.QueryHookOptions<GetSiteTravelCoursesQuery, GetSiteTravelCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteTravelCoursesQuery, GetSiteTravelCoursesQueryVariables>(GetSiteTravelCoursesDocument, options);
      }
export function useGetSiteTravelCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteTravelCoursesQuery, GetSiteTravelCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteTravelCoursesQuery, GetSiteTravelCoursesQueryVariables>(GetSiteTravelCoursesDocument, options);
        }
export type GetSiteTravelCoursesQueryHookResult = ReturnType<typeof useGetSiteTravelCoursesQuery>;
export type GetSiteTravelCoursesLazyQueryHookResult = ReturnType<typeof useGetSiteTravelCoursesLazyQuery>;
export type GetSiteTravelCoursesQueryResult = Apollo.QueryResult<GetSiteTravelCoursesQuery, GetSiteTravelCoursesQueryVariables>;
export const SearchUnifyTasksByUserDocument = gql`
    query SearchUnifyTasksByUser($user_email: String!, $siteId: ID!, $nextToken: String) {
  searchUnifyTasks(
    filter: {user_email: {eq: $user_email}, siteUnify_tasksId: {eq: $siteId}}
    sort: {field: updatedAt, direction: desc}
    limit: 50
    nextToken: $nextToken
  ) {
    nextToken
    items {
      createdAt
      id
      status
      user_email
      updatedAt
    }
  }
}
    `;

/**
 * __useSearchUnifyTasksByUserQuery__
 *
 * To run a query within a React component, call `useSearchUnifyTasksByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUnifyTasksByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUnifyTasksByUserQuery({
 *   variables: {
 *      user_email: // value for 'user_email'
 *      siteId: // value for 'siteId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useSearchUnifyTasksByUserQuery(baseOptions: Apollo.QueryHookOptions<SearchUnifyTasksByUserQuery, SearchUnifyTasksByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUnifyTasksByUserQuery, SearchUnifyTasksByUserQueryVariables>(SearchUnifyTasksByUserDocument, options);
      }
export function useSearchUnifyTasksByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUnifyTasksByUserQuery, SearchUnifyTasksByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUnifyTasksByUserQuery, SearchUnifyTasksByUserQueryVariables>(SearchUnifyTasksByUserDocument, options);
        }
export type SearchUnifyTasksByUserQueryHookResult = ReturnType<typeof useSearchUnifyTasksByUserQuery>;
export type SearchUnifyTasksByUserLazyQueryHookResult = ReturnType<typeof useSearchUnifyTasksByUserLazyQuery>;
export type SearchUnifyTasksByUserQueryResult = Apollo.QueryResult<SearchUnifyTasksByUserQuery, SearchUnifyTasksByUserQueryVariables>;
export const SearchUnifyTasksDocument = gql`
    query SearchUnifyTasks($siteId: ID!, $nextToken: String) {
  searchUnifyTasks(
    filter: {siteUnify_tasksId: {eq: $siteId}}
    sort: {field: updatedAt, direction: desc}
    limit: 50
    nextToken: $nextToken
  ) {
    nextToken
    items {
      createdAt
      id
      status
      user_email
      updatedAt
    }
  }
}
    `;

/**
 * __useSearchUnifyTasksQuery__
 *
 * To run a query within a React component, call `useSearchUnifyTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUnifyTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUnifyTasksQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useSearchUnifyTasksQuery(baseOptions: Apollo.QueryHookOptions<SearchUnifyTasksQuery, SearchUnifyTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUnifyTasksQuery, SearchUnifyTasksQueryVariables>(SearchUnifyTasksDocument, options);
      }
export function useSearchUnifyTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUnifyTasksQuery, SearchUnifyTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUnifyTasksQuery, SearchUnifyTasksQueryVariables>(SearchUnifyTasksDocument, options);
        }
export type SearchUnifyTasksQueryHookResult = ReturnType<typeof useSearchUnifyTasksQuery>;
export type SearchUnifyTasksLazyQueryHookResult = ReturnType<typeof useSearchUnifyTasksLazyQuery>;
export type SearchUnifyTasksQueryResult = Apollo.QueryResult<SearchUnifyTasksQuery, SearchUnifyTasksQueryVariables>;
export const GetSitesByNameDocument = gql`
    query GetSitesByName($name: String!) {
  searchSites(filter: {name: {eq: $name}}) {
    items {
      id
    }
  }
}
    `;

/**
 * __useGetSitesByNameQuery__
 *
 * To run a query within a React component, call `useGetSitesByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetSitesByNameQuery(baseOptions: Apollo.QueryHookOptions<GetSitesByNameQuery, GetSitesByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSitesByNameQuery, GetSitesByNameQueryVariables>(GetSitesByNameDocument, options);
      }
export function useGetSitesByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSitesByNameQuery, GetSitesByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSitesByNameQuery, GetSitesByNameQueryVariables>(GetSitesByNameDocument, options);
        }
export type GetSitesByNameQueryHookResult = ReturnType<typeof useGetSitesByNameQuery>;
export type GetSitesByNameLazyQueryHookResult = ReturnType<typeof useGetSitesByNameLazyQuery>;
export type GetSitesByNameQueryResult = Apollo.QueryResult<GetSitesByNameQuery, GetSitesByNameQueryVariables>;
export const GetAllSitesS3RefsDocument = gql`
    query GetAllSitesS3Refs($nextToken: String) {
  listSites(nextToken: $nextToken, limit: 1000) {
    items {
      s3_ref
      id
    }
    nextToken
  }
}
    `;

/**
 * __useGetAllSitesS3RefsQuery__
 *
 * To run a query within a React component, call `useGetAllSitesS3RefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSitesS3RefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSitesS3RefsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetAllSitesS3RefsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSitesS3RefsQuery, GetAllSitesS3RefsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSitesS3RefsQuery, GetAllSitesS3RefsQueryVariables>(GetAllSitesS3RefsDocument, options);
      }
export function useGetAllSitesS3RefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSitesS3RefsQuery, GetAllSitesS3RefsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSitesS3RefsQuery, GetAllSitesS3RefsQueryVariables>(GetAllSitesS3RefsDocument, options);
        }
export type GetAllSitesS3RefsQueryHookResult = ReturnType<typeof useGetAllSitesS3RefsQuery>;
export type GetAllSitesS3RefsLazyQueryHookResult = ReturnType<typeof useGetAllSitesS3RefsLazyQuery>;
export type GetAllSitesS3RefsQueryResult = Apollo.QueryResult<GetAllSitesS3RefsQuery, GetAllSitesS3RefsQueryVariables>;
export const GetAllSitesMonitorFilterInfoDocument = gql`
    query GetAllSitesMonitorFilterInfo($nextToken: String) {
  searchSites(nextToken: $nextToken, limit: 1000) {
    items {
      id
      name
      locationSitesId
    }
    nextToken
  }
}
    `;

/**
 * __useGetAllSitesMonitorFilterInfoQuery__
 *
 * To run a query within a React component, call `useGetAllSitesMonitorFilterInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSitesMonitorFilterInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSitesMonitorFilterInfoQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetAllSitesMonitorFilterInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSitesMonitorFilterInfoQuery, GetAllSitesMonitorFilterInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSitesMonitorFilterInfoQuery, GetAllSitesMonitorFilterInfoQueryVariables>(GetAllSitesMonitorFilterInfoDocument, options);
      }
export function useGetAllSitesMonitorFilterInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSitesMonitorFilterInfoQuery, GetAllSitesMonitorFilterInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSitesMonitorFilterInfoQuery, GetAllSitesMonitorFilterInfoQueryVariables>(GetAllSitesMonitorFilterInfoDocument, options);
        }
export type GetAllSitesMonitorFilterInfoQueryHookResult = ReturnType<typeof useGetAllSitesMonitorFilterInfoQuery>;
export type GetAllSitesMonitorFilterInfoLazyQueryHookResult = ReturnType<typeof useGetAllSitesMonitorFilterInfoLazyQuery>;
export type GetAllSitesMonitorFilterInfoQueryResult = Apollo.QueryResult<GetAllSitesMonitorFilterInfoQuery, GetAllSitesMonitorFilterInfoQueryVariables>;
export const GetSitesAmountWithS3RefDocument = gql`
    query getSitesAmountWithS3Ref($s3Ref: String!) {
  searchSites(filter: {s3_ref: {eq: $s3Ref}}) {
    total
  }
}
    `;

/**
 * __useGetSitesAmountWithS3RefQuery__
 *
 * To run a query within a React component, call `useGetSitesAmountWithS3RefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesAmountWithS3RefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesAmountWithS3RefQuery({
 *   variables: {
 *      s3Ref: // value for 's3Ref'
 *   },
 * });
 */
export function useGetSitesAmountWithS3RefQuery(baseOptions: Apollo.QueryHookOptions<GetSitesAmountWithS3RefQuery, GetSitesAmountWithS3RefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSitesAmountWithS3RefQuery, GetSitesAmountWithS3RefQueryVariables>(GetSitesAmountWithS3RefDocument, options);
      }
export function useGetSitesAmountWithS3RefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSitesAmountWithS3RefQuery, GetSitesAmountWithS3RefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSitesAmountWithS3RefQuery, GetSitesAmountWithS3RefQueryVariables>(GetSitesAmountWithS3RefDocument, options);
        }
export type GetSitesAmountWithS3RefQueryHookResult = ReturnType<typeof useGetSitesAmountWithS3RefQuery>;
export type GetSitesAmountWithS3RefLazyQueryHookResult = ReturnType<typeof useGetSitesAmountWithS3RefLazyQuery>;
export type GetSitesAmountWithS3RefQueryResult = Apollo.QueryResult<GetSitesAmountWithS3RefQuery, GetSitesAmountWithS3RefQueryVariables>;
export const GetAllSitesInfoForChoosingDocument = gql`
    query GetAllSitesInfoForChoosing($nextToken: String) {
  searchSites(nextToken: $nextToken, limit: 1000) {
    items {
      id
      name
      s3_ref
      customerSitesId
      locationSitesId
    }
    nextToken
  }
}
    `;

/**
 * __useGetAllSitesInfoForChoosingQuery__
 *
 * To run a query within a React component, call `useGetAllSitesInfoForChoosingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSitesInfoForChoosingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSitesInfoForChoosingQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetAllSitesInfoForChoosingQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSitesInfoForChoosingQuery, GetAllSitesInfoForChoosingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSitesInfoForChoosingQuery, GetAllSitesInfoForChoosingQueryVariables>(GetAllSitesInfoForChoosingDocument, options);
      }
export function useGetAllSitesInfoForChoosingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSitesInfoForChoosingQuery, GetAllSitesInfoForChoosingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSitesInfoForChoosingQuery, GetAllSitesInfoForChoosingQueryVariables>(GetAllSitesInfoForChoosingDocument, options);
        }
export type GetAllSitesInfoForChoosingQueryHookResult = ReturnType<typeof useGetAllSitesInfoForChoosingQuery>;
export type GetAllSitesInfoForChoosingLazyQueryHookResult = ReturnType<typeof useGetAllSitesInfoForChoosingLazyQuery>;
export type GetAllSitesInfoForChoosingQueryResult = Apollo.QueryResult<GetAllSitesInfoForChoosingQuery, GetAllSitesInfoForChoosingQueryVariables>;