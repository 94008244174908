import { Card, Stack, Skeleton, CircularProgress } from '@mui/material';
import { Tooltip } from '../../../../../shared';

export const ImageShow = ({ src }: { src: string }) => {
  return (
    <Tooltip
      title={
        <Stack>
          <img src={src} height={500} width={500} />
        </Stack>
      }>
      <Card
        elevation={3}
        sx={{
          width: 80,
          height: 80
        }}>
        <img width="100%" height="100%" src={src} />
      </Card>
    </Tooltip>
  );
};
