import { useState } from 'react';
import ScanQuery from './ScanQuery';
import PSDViewGraph from './PSDViewGraph';
import { Scan } from '../../Generated/Graphql';
import { Stack } from '@mui/material';

function PSDView() {
  const [selectedScan, setSelectedScan] = useState<Scan | null>(null);

  return (
    <Stack flex={1} direction="column" height={'100%'}>
      <ScanQuery setSelectedScan={setSelectedScan} selectedScan={selectedScan} isValidDefault={true} />
      {selectedScan && selectedScan.id && (
        <PSDViewGraph key={selectedScan.id} scanId={selectedScan.id} />
      )}
    </Stack>
  );
}

export default PSDView;
