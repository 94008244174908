import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

interface Props {
        openDialog: boolean;
        handleDisagreeClose: () => void;
        handleAgreeClose: () => void;
    }

const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
          children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
      ) {
        return <Slide direction="up" ref={ref} {...props} />;
      });


export function DialogConfirmation({openDialog, handleDisagreeClose, handleAgreeClose}: Props) {

    return (
        <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleDisagreeClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Exovision edit already exists"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          All changes and additions will be deleted
          <br />
          are you sure to replace?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDisagreeClose}>Cancel</Button>
        <Button onClick={handleAgreeClose}>Replace</Button>
      </DialogActions>
    </Dialog>
    )
}
