import { Dict } from "../BasicTypes";

export function groupBy<T>(arr: T[], getKey: (entry: T) => string) {
  const res: Dict<T[]> = {};
  for (const item of arr) {
    const key = getKey(item);
    if (!Object.keys(res).includes(key)) {
      res[key] = [];
    }
    res[key].push(item);
  }

  return res;
}
