import * as React from "react";
import { Button, FormControl, Stack, TextField } from "@mui/material";
import { Modal } from "@mui/material";
import ModalBox from "../../Templates/ModalBox";
import "./bugReport.css";
import AutoCompleteField from "../../Templates/AutoCompleteField";

const DESCRIPTION_ROWS = 4;

function ReportBugModal({
  imageDataUrl,
  plannedSite,
  source,
  analysisSite,
  description,
  setDescription,
  urgency,
  setUrgency,
  reportBug,
  cancel,
  showErrors,
}: ReportBugModalProps) {
  return (
    <Modal open={true}>
      <ModalBox>
        <FormControl>
          <div className="bugReportModal">
            <Stack>
              <h1>Bug Report</h1>
              <h2>Screenshot</h2>
              <img src={imageDataUrl} className="screenshotPreview" />
              <hr />
              <h2>General Info</h2>
              <Stack spacing="1rem" direction="row">
                <TextField label="Source" value={source} disabled />
                <TextField label="Planned Site" value={plannedSite} disabled />
                <TextField label="Analysis Site" value={analysisSite} disabled />
              </Stack>
              <hr />
              <h2>Bug Description</h2>
              <Stack spacing="1rem">
                <TextField
                  placeholder="Describe the bug"
                  label="Description"
                  rows={DESCRIPTION_ROWS}
                  multiline
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  error={showErrors && description.length <= MIN_DESCRIPTION_CHARACTERS}
                />
                <AutoCompleteField<Urgency | "">
                  sx={{ width: "40%" }}
                  placeholder="Urgency"
                  options={[Urgency.IMMEDIATE, Urgency.NEXT_RELEASE, Urgency.LOW]}
                  selectedOption={urgency}
                  setSelectedOption={setUrgency}
                  textFieldProps={{ error: showErrors && (urgency === undefined || urgency === "") }}
                />
              </Stack>
              <hr />
              <Stack direction="row">
                <Button variant="outlined" onClick={reportBug}>
                  Report Bug
                </Button>
                <Button variant="outlined" sx={{ marginLeft: "auto" }} onClick={cancel}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </div>
        </FormControl>
      </ModalBox>
    </Modal>
  );
}

export const MIN_DESCRIPTION_CHARACTERS = 5;

export enum Urgency {
  IMMEDIATE = "Immediate",
  NEXT_RELEASE = "Next Release",
  LOW = "Low",
}

export interface ReportBugModalProps {
  imageDataUrl: string;
  plannedSite?: string;
  source?: string;
  analysisSite?: string;
  description: string;
  setDescription: (desc: string) => void;
  urgency?: "" | Urgency;
  setUrgency: (u?: "" | Urgency) => void;
  reportBug: () => void;
  cancel: () => void;
  showErrors: boolean;
}

export default ReportBugModal;
