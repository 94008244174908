import React from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import PageCard from "../Templates/PageCard";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/scanDashboard.css";
import { GetScansAfterTimestampQuery, useGetScansAfterTimestampLazyQuery } from "../../Generated/Graphql";
import { Dict } from "../../BasicTypes";
import AggregatedScanCard from "../ScansDashboard/AggregatedScanCard";
import ScanCard from "../ScansDashboard/ScanCard";
import { ApolloQueryResult } from "@apollo/client";
import { parseScanResults } from "../ScansDashboard/GraphqlParser";

export enum GroupBy {
  Status = "Status",
  Site = "Site",
  Sensor = "Sensor",
}

// const SITE_SCAN_COLOR = "rgb(245, 245, 245)"; // Light Grey
// const SENSOR_SCAN_COLOR = "rgb(255, 255, 255)"; // White
// const SITE_UPLOADED_COLOR = "rgb(236, 245, 233)"; // Light Green
// const SENSOR_UPLOADED_COLOR = "rgb(234, 242, 230)"; // Darker Light Green

const MIN_DATE_OFFSET = 8;

function getAttrGetter(groupBy: GroupBy) {
  if (groupBy === GroupBy.Status) {
    return (s: ScanData) => s.status;
  }
  if (groupBy === GroupBy.Site) {
    return (s: ScanData) => s.siteName;
  }
  if (groupBy === GroupBy.Sensor) {
    return (s: ScanData) => s.sensor;
  }
  throw new Error(`Unknown grouping selected ${groupBy}`);
}

const SCAN_STATUS_ORDER: Dict<number> = {
  STARTED: -1,
  UPLOADING: 0,
  UPLOADED: 1,
  ACCESSED: 2,
  ANALYZED: 3,
};

const MIN_SCAN_STATUS_TO_SITE_COLOR: Dict<string> = {
  0: "rgb(245, 245, 245)", // Light Grey,
  1: "rgb(236, 245, 233)", // Light Green
  2: "rgb(224, 226, 248)", // light blue-violet
  3: "rgb(250, 246, 210)", // light gold
};

const MIN_SCAN_STATUS_TO_SCAN_COLOR: Dict<string> = {
  0: "rgb(255, 255, 255)", // white
  1: "rgb(234, 242, 230)", // Light Green
  2: "rgb(224, 226, 248)", // light blue-violet
  3: "rgb(249, 244, 199)", // light gold
};

function ScansDashboard() {
  const today = new Date();

  const [minDate, setMinDate] = React.useState<Date>(new Date(new Date().setDate(today.getDate() - 1))); // This abomination is how you get the date for yesterday
  const [textFilter, setTextFilter] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [scans, setScans] = React.useState<ScanData[]>([]);
  const [displayScans, setDisplayScans] = React.useState<ScanData[]>([]);
  const [showWarning, setShowWarning] = React.useState(true);

  const [, scansResults] = useGetScansAfterTimestampLazyQuery({
    variables: { timestamp: ~~(minDate.setHours(0, 0, 0) / 1000) },
  });

  React.useEffect(() => {
    async function filterData() {
      setDisplayScans(
        scans.filter(
          (s) =>
            s.name.toLowerCase().includes(textFilter.toLowerCase()) ||
            s.siteName.toLowerCase().includes(textFilter.toLowerCase()) ||
            s.sensor.toLowerCase().includes(textFilter.toLowerCase()) ||
            s.status.toLowerCase().includes(textFilter.toLowerCase())
        )
      );
    }
    filterData();
  }, [scans, textFilter]);

  async function getScans() {
    setTextFilter("");
    setScans([]);
    setLoading(true);

    try {
      let nextToken = null;
      const scans: ScanData[] = [];
      do {
        const res: ApolloQueryResult<GetScansAfterTimestampQuery> = await scansResults.fetchMore({
          variables: { nextToken },
        });
        if (res.error) {
          window.alert(`Got error: ${res.error.message}`);
          console.log(res.error);
          throw res.error;
        }
        if (res.errors) {
          window.alert(`Got ${res.errors.length}. The first error is: ${res.errors[0].message}`);
          console.log(res.errors);
          throw res.errors;
        }

        scans.push(...parseScanResults(res, (res) => res.data.searchScans?.items));

        nextToken = res.data.searchScans?.nextToken;
      } while (nextToken);
      setScans(scans);
    } finally {
      setLoading(false);
    }
  }

  function groupByField(attrGetter: (s: ScanData) => string, scans: ScanData[]) {
    const groups: Dict<ScanData[]> = {};
    for (const scan of scans) {
      const key = attrGetter(scan);
      if (groups[key] === undefined) {
        groups[key] = [];
      }
      groups[key].push(scan);
    }

    return groups;
  }

  function createResults(attrGetter: (s: ScanData) => string, scans: ScanData[]) {
    const groups = groupByField(attrGetter, scans);
    return Object.keys(groups).map((group) => {
      const minAccessIndex = Math.min(...groups[group].map((s) => SCAN_STATUS_ORDER[s.status]));
      const color = MIN_SCAN_STATUS_TO_SCAN_COLOR[minAccessIndex];
      return (
        <AggregatedScanCard
          key={group}
          cardProps={{ sx: { backgroundColor: color } }}
          header={
            <Typography variant="h5" sx={{ marginbottom: 1 }}>
              {group}
            </Typography>
          }
        >
          {groups[group]
            .sort((a, b) => SCAN_STATUS_ORDER[a.status] - SCAN_STATUS_ORDER[b.status])
            .map((scan) => (
              <ScanCard scan={scan} key={scan.id}></ScanCard>
            ))}
        </AggregatedScanCard>
      );
    });
  }

  function createSiteAggregatedResults() {
    const groups = groupByField(getAttrGetter(GroupBy.Site), displayScans);
    return Object.keys(groups).map((g) => {
      const minAccessIndex = Math.min(...groups[g].map((s) => SCAN_STATUS_ORDER[s.status]));
      const color = MIN_SCAN_STATUS_TO_SITE_COLOR[minAccessIndex];
      return (
        <AggregatedScanCard
          cardProps={{ sx: { backgroundColor: color } }}
          key={g}
          header={
            <Typography variant="h5" sx={{ marginbottom: 1 }}>
              {g}
            </Typography>
          }
        >
          <>{createResults(getAttrGetter(GroupBy.Sensor), groups[g])}</>
        </AggregatedScanCard>
      );
    });
  }

  return (
    <>
      <PageCard>
        <Collapse in={showWarning}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            This dashboard only show scans that are currently being uploaded or are already in S3.
          </Alert>
        </Collapse>
        <Typography variant="h4" sx={{ marginbottom: 1 }}>
          Recent Scans
        </Typography>
        <FormControl sx={{ pt: 2 }}>
          <Stack spacing={2}>
            <Stack direction={"row"} spacing={1}>
              <DatePicker
                label="Scans range start"
                renderInput={(params) => <TextField {...params} />}
                value={minDate}
                onChange={(newValue: any) => {
                  newValue ? setMinDate(newValue.$d) : setMinDate(today);
                }}
                maxDate={new Date()}
                minDate={new Date(new Date().setDate(today.getDate() - MIN_DATE_OFFSET))} // Limits the min date scans are fetched. Could be removed if pagination is implemented
              />

              <Button variant="outlined" disabled={loading} onClick={getScans}>
                Get scans
              </Button>
            </Stack>
            <Stack direction={"row"} spacing={1}>
              <TextField label="Free search" value={textFilter} onChange={(e) => setTextFilter(e.target.value)} />
            </Stack>
          </Stack>
        </FormControl>
      </PageCard>
      {loading && <CircularProgress sx={{ margin: "auto" }} />}

      {createSiteAggregatedResults()}
    </>
  );
}

export interface ScanData {
  id: string;
  name: string;
  startTimestamp: number;
  endTimestamp?: number;
  sensor: string;
  status: string;
  siteName: string;
  fixedModeRatio?: number;
  mount_method?: string;
}

export default ScansDashboard;
