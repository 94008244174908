import * as React from "react";
import TabsBar from "../TabsBar";

class TabForm extends React.Component<TabFormProps, TabFormState> {
  constructor(props: TabFormProps) {
    super(props);
    this.state = {
      activeTab: this.props.tabs[0].tabName,
      movementEnabled: true,
    };
  }

  setActiveTab(newTabName: string, force?: boolean) {
    if ((force !== undefined && force) || this.state.movementEnabled) {
      this.setState({ activeTab: newTabName, movementEnabled: false });
    }
  }

  enableExit(tab: string) {
    if (this.state.activeTab === tab) {
      this.setState({ activeTab: this.state.activeTab, movementEnabled: true });
    }
  }

  getTabDivStyle(tab: string, activeTab: string) {
    if (tab !== activeTab) {
      return { display: "none" };
    }
    return {};
  }

  render() {
    return (
      <div className="tabFormWrapper">
        <TabsBar
          tabs={this.props.tabs.map((t) => {
            return t.tabName;
          })}
          activeTab={this.state.activeTab}
          onTabClick={(tabName: string) => this.setState({ activeTab: tabName, movementEnabled: false })}
        />
        <div className="tabsInfoContainer">
          {this.props.tabs.map((t) => {
            if (!t.shouldReload) {
              return (
                <div
                  className={this.props.tabClass}
                  key={t.tabName}
                  style={this.getTabDivStyle(t.tabName, this.state.activeTab)}
                >
                  {t.tabElement}
                </div>
              );
            }
            if (t.tabName === this.state.activeTab) {
              return (
                <div className={this.props.tabClass} key={t.tabName}>
                  {t.tabElement}
                </div>
              );
            }
            return <div key={t.tabName}></div>;
          })}
        </div>
      </div>
    );
  }
}

export interface TabFormState {
  activeTab: string;
  // We want to enable auto switching, but we don't want change a tab the user picked without them knowing
  movementEnabled: boolean;
}

export interface TabFormProps {
  tabs: { tabName: string; tabElement: React.ReactNode; shouldReload: boolean }[];
  tabClass?: string;
}

export default TabForm;
