import React from "react";
import NewSharepointDateFolder from "./NewSharepointDateFolderForm";
import RedirectToSharepoint from "./RedirectToSharepoint";

function OneDriveForm({ siteId, sharepointUrl }: OneDriveFormProps) {
  return (
    <>
      <NewSharepointDateFolder siteId={siteId} />
      <hr />
      <RedirectToSharepoint sharepointLink={sharepointUrl} />
    </>
  );
}

export interface OneDriveFormProps {
  siteId: string;
  sharepointUrl: string;
}

export default OneDriveForm;
