import { Box, Stack } from "@mui/material";
import * as React from "react";
import { PreloadRunInfo } from "./MonitorTypes";
import MonitorCollapseSection from "./MonitorCollapseSection";

function PreloadRun({ runInfo, useCollapse }: PreloadRunInfoProps) {
  function createRunInfo(runInfo: PreloadRunInfo) {
    return (
      <Stack>
        <Box>Start Time: {runInfo.startTime === undefined ? "N/A" : new Date(runInfo.startTime).toLocaleString()}</Box>
        <Box>End Time: {runInfo.endTime === undefined ? "N/A" : new Date(runInfo.endTime).toLocaleString()}</Box>
        <Box>Status: {runInfo.status}</Box>
        <Box>S3 refs: {runInfo.preloadedS3Refs?.join("\n") ?? "N/A"}</Box>
        {runInfo.products ? <Box>Products: {runInfo.products.join(", ")}</Box> : <></>}
        {runInfo.errorMessage && runInfo.status === "FAILED" ? <Box>Error message: {runInfo.errorMessage}</Box> : <></>}
      </Stack>
    );
  }
  return (
    <>
      {useCollapse && (
        <Stack>
          <Box>
            <MonitorCollapseSection header={runInfo.targetName} key={runInfo.targetName}>
              {createRunInfo(runInfo)}
            </MonitorCollapseSection>
          </Box>
        </Stack>
      )}
      {!useCollapse && <Stack>{createRunInfo(runInfo)}</Stack>}
    </>
  );
}

export interface PreloadRunInfoProps {
  runInfo: PreloadRunInfo;
  useCollapse: boolean;
}

export default PreloadRun;
