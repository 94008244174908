import axios from "axios";
import { runWithRetries } from "../Utils/promiseWithRetries";

class partsUploader {
  fileParts: Blob[];
  urls: string[];
  uploadId: string;
  retries: number;
  timeBetweenTries: number;
  constructor(parts: Blob[], urls: string[], uploadId: string, retries = 10, timeBetweenTries = 5000) {
    this.fileParts = parts;
    this.urls = urls;
    this.uploadId = uploadId;
    this.retries = retries;
    this.timeBetweenTries = timeBetweenTries;
  }

  async uploadFile(onProgress = (e: number) => {}) {
    const parts = [];

    const totalSize = this.fileParts.reduce((s, f) => f.size + s, 0);
    const regularChunkSize = this.fileParts[0].size;
    const lastChunkSize = this.fileParts[this.fileParts.length - 1].size;

    for (let i = 0; i < this.fileParts.length; i++) {
      const filePart = this.fileParts[i];
      const url = this.urls[i];

      const putPromise = axios.put(url, filePart, {
        onUploadProgress: (e) => {
          let basePercent = 0;
          let percentMultiplier = 1;
          if (i !== this.fileParts.length - 1) {
            basePercent = i * (regularChunkSize / totalSize) * 100;
            percentMultiplier = regularChunkSize / totalSize;
          } else {
            basePercent = i * (regularChunkSize / totalSize) * 100;
            percentMultiplier = lastChunkSize / totalSize;
          }
          onProgress(Math.round((percentMultiplier * (e.loaded * 100)) / e.total + basePercent));
        },
      });

      const resp = await runWithRetries(putPromise, {
        maxRetries: this.retries,
        waitBeforeRetryMilliseconds: this.timeBetweenTries,
        failureError: new Error("Upload failed after max retry attempts"),
      });
      parts.push({ ETag: resp.headers["etag"], PartNumber: i + 1 });
    }
    return parts;
  }
}

export default partsUploader;
