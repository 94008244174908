import { create } from 'zustand';
import { ParsedTargetObect } from '../../../../Types/MappingEntities';
import {
  getDepthRange,
  getHasMedia,
  getOwners,
  isMatchDepth,
  isMatchOwner,
  getHasUtilities,
  isMatchUtlities,
  isMatchMedia
} from '../utils';
import { FilterCategories, Filters } from '../types';

type PoiStore = {
  pois: Array<ParsedTargetObect>;
  initFilterValues: Filters;
  filterOptions: Filters;
  filterValues: Filters;
  filteredPois: Array<ParsedTargetObect>;
  selectedPoi: ParsedTargetObect | undefined;

  setPois: (pois: Array<ParsedTargetObect>) => void;
  setFilteredPois: (pois: Array<ParsedTargetObect>) => void;
  setSelectedPoi: (data: ParsedTargetObect | undefined) => void;
  applyFilters: (filters: Filters) => void;
  resetFilters: () => void;
};

const initFilters: Filters = {
  depth: undefined,
  media: false,
  owner: [],
  utilities: false
};

export const usePoiStore = create<PoiStore>((set) => ({
  pois: [],
  filteredPois: [],
  initFilterValues: initFilters,
  filterOptions: initFilters,
  filterValues: initFilters,
  selectedPoi: undefined,
  resetFilters: () =>
    set((state) => ({ filteredPois: state.pois, filterValues: state.initFilterValues })),
  applyFilters: (filterValues) =>
    set((state) => {
      const newFilteredPois = state.pois.filter((p) => {
        let isMedia, isDepth, isUtlitites, isOwner;
        const { media, depth, owner, utilities } = filterValues;

        if (owner?.length) {
          isOwner = isMatchOwner(p, owner);
        } else {
          isOwner = true;
        }

        if (media) {
          isMedia = isMatchMedia(p);
        } else {
          isMedia = !isMatchMedia(p);
        }

        if (utilities) {
          isUtlitites = isMatchUtlities(p);
        } else {
          isUtlitites = !isMatchUtlities(p);
        }

        if (
          JSON.stringify(depth) === JSON.stringify(state.initFilterValues[FilterCategories.depth])
        ) {
          isDepth = true;
        } else {
          isDepth = isMatchDepth(p, depth);
        }

        return isMedia && isUtlitites && isDepth && isOwner;
      });

      return { filterValues, filteredPois: newFilteredPois, selectedPoi: undefined };
    }),
  setPois: (pois) =>
    set((state) => {
      const { hasMedia } = getHasMedia(pois);
      const hasUtitlites = getHasUtilities(pois);

      const commonValues = {
        [FilterCategories.depth]: getDepthRange(pois),
        [FilterCategories.media]: hasMedia,
        [FilterCategories.utilities]: hasUtitlites
      };

      const options = {
        ...commonValues,
        [FilterCategories.owner]: getOwners(pois)
      };

      return {
        pois,
        filterOptions: {
          ...state.filterOptions,
          ...options
        },
        filterValues: {
          ...state.filterValues,
          ...commonValues
        },
        initFilterValues: {
          ...state.initFilterValues,
          ...commonValues
        }
      };
    }),
  setFilteredPois: (filteredPois) => set((state) => ({ filteredPois })),
  setSelectedPoi: (selectedPoi) => set((state) => ({ selectedPoi }))
}));
