import * as React from "react";
import L, { ControlPosition, LeafletMouseEvent } from "leaflet";
import { Polygon, Popup, useMap } from "react-leaflet";
import Button from "@mui/material/Button/Button";
import FormControl from "@mui/material/FormControl/FormControl";
import { SelectChangeEvent } from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { SubLayer, exovision_edit, typeObject } from "../../../../Constant/exoVision";
import { GeoJsonLayer } from "../../../../Utils/layerUtils";
import LayerContext from "../../../Contexts/LayerContext";
import UserContext from "../../../Contexts/UserContext";

export enum SelectedVisionToolsMode {
  NONE,
  SETTINGS,
  DRAW,
  MEASURE,
}

interface DrawProps {
  setSelectedTool: (tool: SelectedVisionToolsMode) => void;
  onGeoJsonLayerClicked: (layerId: string, ctrlClick: boolean, subLayer: any) => void;
  plannedPolygons: GeoJsonLayer[];
  setPlannedPolygons: (polygons: GeoJsonLayer[] | ((polygons: GeoJsonLayer[]) => GeoJsonLayer[])) => void;
}

function DrawTypeControl({ setSelectedTool, onGeoJsonLayerClicked, plannedPolygons }: DrawProps) {
  const [active, setActive] = React.useState(false);
  const [drawing, setDrawing] = React.useState(true);
  const [showPopup, setShowPopup] = React.useState(true);
  const [areaPolygon, setAreaPolygon] = React.useState<L.LatLng[]>([]);
  const [mouseOverPoint, setMouseOverPoint] = React.useState<L.LatLng>();
  const mapClickRef = React.useRef(mapClick);
  const mapMouseOverRef = React.useRef(mapMouseOver);
  const [type, setType] = React.useState(Object.keys(typeObject)[0] ?? "");
  const [polygonColor, setPolygonColor] = React.useState(typeObject[0]?.color ?? "#0000FF");
  const { setGlobalChangeLayers } = React.useContext(LayerContext);
  const { user } = React.useContext(UserContext);
  const map = useMap();

  function mapClick(e: LeafletMouseEvent) {
    setAreaPolygon((currentPolygon) => [...currentPolygon, e.latlng]);
  }

  function mapMouseOver(e: LeafletMouseEvent) {
    setMouseOverPoint(e.latlng);
  }

  React.useEffect(() => {
    document.addEventListener("keydown", onEscPressed);
    return () => {
      document.removeEventListener("keydown", onEscPressed);
    };
  }, []);

  React.useEffect(() => {
    if (drawing) {
      map.addEventListener("click", mapClickRef.current);
      map.addEventListener("mousemove", mapMouseOverRef.current);
    } else {
      map.removeEventListener("click", mapClickRef.current);
      map.removeEventListener("mousemove", mapMouseOverRef.current);
    }
  }, [drawing, map]);

  React.useEffect(() => {
    if (!active) {
      setAreaPolygon([]);
    }
  }, [active]);

  function onEscPressed(e: KeyboardEvent) {
    if (e.key === "Escape") {
      setShowPopup(true);
      setMouseOverPoint(undefined);
      setGlobalChangeLayers(true);

      setDrawing((prevState) => {
        if (prevState) {
          return !prevState;
        }
        setActive(false);
        return prevState;
      });
    }
  }

  function onControlClick() {
    if (!active) {
      setActive(true);
      setDrawing(true);
    } else if (!drawing) {
      setActive(false);
    } else {
      setAreaPolygon((currentPolygon) => {
        if (currentPolygon.length === 0) {
          return currentPolygon;
        }
        const tempPolygon = [...currentPolygon];
        tempPolygon.pop();
        return tempPolygon;
      });
    }
  }

  function getPolygonPoints() {
    return mouseOverPoint ? [...areaPolygon, mouseOverPoint] : areaPolygon;
  }

  const handleChange = (event: SelectChangeEvent<"">) => {
    setType(event.target.value);
    const selectedType = typeObject[event.target.value];
    setPolygonColor(selectedType.color);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const coordinates: [number, number][] = areaPolygon.map((point) => [point.lng, point.lat]);
    const today = new Date();
    const id = `${exovision_edit} ${today.getTime()}`;
    const subLayer: SubLayer = {
      id: id,
      name: id,
      overrideColor: false,
      selected: false,
      subLayers: [],
      visible: true,
      color: polygonColor,
      data: {
        type: "Feature",
        geometry: {
          coordinates: [coordinates],
          type: "Polygon",
        },
        properties: {
          type: type,
          confidence: 1.0,
          create_by: user?.account?.name || "user",
        },
      },
      metadata: {
        type: type,
      },
    };

    onGeoJsonLayerClicked(id, false, subLayer);
    setSelectedTool(SelectedVisionToolsMode.NONE);
    setShowPopup(false);
  };

  return (
    <>
      <Polygon positions={getPolygonPoints()} pathOptions={{ color: "white", dashArray: "8, 8", fillColor: "purple" }}>
        {showPopup && !drawing && areaPolygon.length > 0 && (
          <Popup position={areaPolygon[0]} closeButton={false} autoClose={false}>
            <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
              <FormControl>
                <RadioGroup aria-labelledby="type-label" name="type" value={type} onChange={handleChange}>
                  {Object.keys(typeObject).map((optionKey: string) => (
                    <FormControlLabel
                      key={optionKey}
                      value={optionKey}
                      control={<Radio style={{ color: typeObject[optionKey].color }} />}
                      label={typeObject[optionKey].label}
                      style={{ backgroundColor: typeObject[optionKey].backgroundColor }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Button type="submit" variant="contained" color="primary" style={{ marginTop: "20px" }}>
                Submit
              </Button>
            </form>
          </Popup>
        )}
      </Polygon>
    </>
  );
}

export interface MeasureControlProps {
  position: ControlPosition;
}

export default DrawTypeControl;
