import React, { useEffect, useState, Fragment } from 'react';
import {
  EntityDepthPoints,
  useGetSearchEntityDepthPointsLazyQuery
} from '../../../Generated/Graphql';
import { AnalysisProductsPipe } from '../DataTypes/MapEntities';
import { Polyline, Rectangle, Tooltip } from 'react-leaflet';
import L, { LatLngBoundsExpression } from 'leaflet';
import './DepthDisplay.css';
import { selectedDepthsPaneName } from '../../../config/paneConfig';
import { useMapPipeEntitiesStore } from '../store';

interface DepthDisplayProps {
  siteId: string | undefined;
}

export interface FilterParams {
  siteId: string;
}

export default function DepthDisplay({ siteId }: DepthDisplayProps) {
  const [itemDepthPointsQuery] = useGetSearchEntityDepthPointsLazyQuery();
  const [positions, setPositions] = useState<[number, number][]>([]);
  const [depths, setDepts] = useState<[number][]>([]);
  const [currentPipeId, setCurrentPipeId] = useState<string | undefined>(undefined);
  const { selectedPipe } = useMapPipeEntitiesStore();

  const filterItemsByDepthValidationAlgorithm = (items: EntityDepthPoints[]) => {
    let filteredItems = items.filter(
      (item) => item !== null && item?.algorithm && item.algorithm === 'pipesqa_manual'
    );
    if (filteredItems.length === 0) {
      filteredItems = items.filter(
        (item) => item !== null && item?.algorithm && item.algorithm === 'final'
      );
    }
    return filteredItems;
  };
  useEffect(() => {
    let nextToken: string | null = null;
    async function fetchData() {
      if (!siteId || !selectedPipe) {
        setPositions([]);
        setDepts([]);
        return;
      }
      const pipeId = selectedPipe.header.id;
      if (currentPipeId !== pipeId) {
        setPositions([]);
        setDepts([]);
        setCurrentPipeId(pipeId);
      }
      const res = await itemDepthPointsQuery({ variables: { pipeId, nextToken } });
      const data = res?.data?.searchEntityDepthPoints;
      if (!data || !data.items || data.items.length === 0) {
        setPositions([]);
        return;
      }
      const items = Array.isArray(data.items)
        ? (data.items as EntityDepthPoints[])
        : ([data.items] as EntityDepthPoints[]);
      const filteredItems = filterItemsByDepthValidationAlgorithm(items);
      setPositions([]);
      const item = filteredItems[0];
      for (let i = 0; i < item.lats.length; i++) {
        if (
          item.lats[i] !== null &&
          item.lons &&
          item.lons[i] !== null &&
          item.lons[i] !== undefined
        ) {
          setPositions((prev) => [...prev, [item.lats[i] as number, item.lons[i] as number]]);
          setDepts((prev) => [...prev, [item.depthsFromGround[i] as number]]);
        }
      }
    }
    fetchData();
  }, [itemDepthPointsQuery, siteId, selectedPipe, currentPipeId]);

  return (
    <>
      {positions.map((position: [number, number], index: number) => {
        const widthBase = 0.000001;
        const widthLine = 4 * widthBase;
        const endPosition: [number, number, number] = [
          position[0] + widthLine,
          position[1] + widthLine,
          0
        ];
        const stepPosition: [number, number, number] = [
          position[0] + widthLine,
          position[1] + 2 * widthLine,
          0
        ];
        const bounds: LatLngBoundsExpression = [
          [endPosition[0] + widthBase, endPosition[1] + widthBase],
          [stepPosition[0] + widthBase / 4, stepPosition[1]]
        ];
        const midPoint: [number, number] = [
          (bounds[0][0] + bounds[1][0]) / 2,
          bounds[0][1] + widthBase / 2
        ];

        return (
          <Fragment key={`line-depth-${index}`}>
            <Rectangle
              bounds={bounds}
              color="white"
              fillOpacity={0.8}
              pane={selectedDepthsPaneName}>
              <Tooltip permanent direction="right" position={midPoint}>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    maxHeight: '12px',
                    overflow: 'hidden',
                    background: 'transparent'
                  }}>
                  {depths[index][0].toFixed(3)}
                </span>
              </Tooltip>
            </Rectangle>

            <Polyline
              positions={[L.latLng(position), L.latLng(endPosition), L.latLng(stepPosition)]}
              color="white"
              dashArray="5,10"
              weight={3}
              pane={selectedDepthsPaneName}
            />
          </Fragment>
        );
      })}
    </>
  );
}
