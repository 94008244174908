import * as React from "react";
import { Stack } from "@mui/material";
import { PreloadRunInfo } from "./MonitorTypes";
import PreloadRun from "./PreloadRun";

function PreloadStepSection({ preloadRuns }: PreloadStepSectionProps) {
  if (preloadRuns.length === 1 && preloadRuns[0].target === "SCAN") {
    return <PreloadRun useCollapse={false} runInfo={preloadRuns[0]} />;
  }
  if (preloadRuns.length === 0) {
    return <>No data yet</>;
  }
  return (
    <Stack gap="1rem">
      {preloadRuns.map((run) => (
        <PreloadRun useCollapse={true} runInfo={run} />
      ))}
    </Stack>
  );
}

export interface PreloadStepSectionProps {
  preloadRuns: PreloadRunInfo[];
}

export default PreloadStepSection;
