import * as React from 'react';
import { LatLngExpression, PathOptions } from 'leaflet';
import EditableShape from '../EditableMapEntites/EditableShape';
import EditableCircleGroup from '../EditableMapEntites/EditableCircleGroup';
import { getFeaturesGraphicConfig } from '../../../config/mapConfig';
import { PlannedPolygonLayer } from '../../../Utils/layerUtils';

function PlanningEntities({
  plannedPolygons,
  setPlannedPolygonPositions,
  beingDrawnPlannedPolygon,
  setDrawnPolygonPositions,
  transmitterActions
}: PlanningEntitiesProps) {
  const areTransmitterEditable = React.useMemo<boolean>(() => {
    const selectedPolygonCount = plannedPolygons.filter((p) => p.selected).length;
    return selectedPolygonCount === 1;
  }, [plannedPolygons]);
  return (
    <>
      {plannedPolygons.map(
        (p, i) =>
          p.visible && (
            <EditableShape
              positions={p.data ?? []}
              editable={p.selected}
              drawable={false}
              setPositions={setPlannedPolygonPositions[i]}
              pathOptions={{ color: p.color }}
              key={`plannedPolygon-${p.id}`}
              polygonName={p.name}
            />
          )
      )}
      {plannedPolygons.map(
        (p) =>
          p.visible && (
            <EditableCircleGroup
              centers={p.subLayers?.map((t) => t.data?.at(0) ?? { lat: 0, lng: 0 }) ?? []}
              pathOptionsOverrides={p.subLayers?.map((t) => {
                const override: PathOptions = {};
                if (t.overrideColor) {
                  override.color = t.color;
                }
                if (t.selected) {
                  override.weight = 5;
                } else {
                  override.weight = 3;
                }
                return override;
              })}
              editable={p.selected && areTransmitterEditable}
              defaultRadius={getFeaturesGraphicConfig().radius}
              addCircle={transmitterActions.addTransmitter}
              removeCircle={transmitterActions.removeTransmitter}
              changeCircle={transmitterActions.changeTransmitter}
              pathOptions={{ color: p.color }}
            />
          )
      )}
      {beingDrawnPlannedPolygon !== undefined && (
        <EditableShape
          positions={beingDrawnPlannedPolygon}
          editable={true}
          drawable={true}
          setPositions={setDrawnPolygonPositions}
          pathOptions={{ color: 'red' }}
          key="drawnPlannedPolygon"
        />
      )}
    </>
  );
}

export interface TransmitterActions {
  addTransmitter: (coords: LatLngExpression) => void;
  removeTransmitter: (index: number) => void;
  changeTransmitter: (index: number, coords: LatLngExpression) => void;
}

export interface PlanningEntitiesProps {
  plannedPolygons: PlannedPolygonLayer[];
  setPlannedPolygonPositions: ((
    updateFunc: (positions: LatLngExpression[]) => LatLngExpression[]
  ) => void)[];
  beingDrawnPlannedPolygon?: LatLngExpression[];
  setDrawnPolygonPositions: (setFunc: (oldVla: LatLngExpression[]) => LatLngExpression[]) => void;
  transmitterActions: TransmitterActions;
}

export default PlanningEntities;
