import * as React from "react";
import Modal from "../Templates/Modal";

function ImageModal({ url, onExit }: ImageModalProps) {
  return (
    <Modal>
      <div className="imageModalWrapper">
        <span className="imageModalHeader">
          <span className="closeImageButton" onClick={onExit}>
            ×
          </span>
        </span>
        <img src={url} className="siteImage" />
      </div>
    </Modal>
  );
}

export interface ImageModalProps {
  url: string;
  onExit: () => void;
}

export default ImageModal;
