import { entityType } from "../../../BasicTypes";
import {
  AnalysisProductsPipe,
  AnalysisProductsPolygon,
  AnalysisProductsTarget,
  AnalysisProductsTransmitter,
  AnalysisProductsTravelCourse,
  ScanDetails,
} from "./MapEntities";

export function createEmptyMapFeatures(): MapFeatures {
  return {
    pipes: [],
    transmitters: [],
    travelCourses: [],
    polygons: [],
    targets: [],
  };
}

export function getFeatureCount(mapFeatures: MapFeatures): number {
  return Object.values(mapFeatures)
    .map((v) => v.length)
    .reduce((partialSum, v) => {
      return partialSum + v;
    }, 0);
}

export function getFeaturesIds(mapFeatures: MapFeatures): Set<string> {
  return new Set(
    Object.values(mapFeatures)
      .map((v) => v.map((e: any) => e.header.id))
      .flat(1)
  );
}

export function filterFeatures(mapFeatures: MapFeatures, predicate: (entity: entityType) => boolean): MapFeatures {
  const result = createEmptyMapFeatures() as unknown as { [key: string]: entityType[] };
  Object.entries(mapFeatures).forEach(([entityType, entitiesOfType]) => {
    result[entityType] = entitiesOfType.filter(predicate);
  });
  return result as unknown as MapFeatures;
}

export function changeFeatures(mapFeatures: MapFeatures, mapFunction: (entity: entityType) => entityType): MapFeatures {
  const result = createEmptyMapFeatures() as unknown as { [key: string]: entityType[] };
  Object.entries(mapFeatures).forEach(([entityType, entitiesOfType]) => {
    result[entityType] = entitiesOfType.map(mapFunction);
  });
  return result as unknown as MapFeatures;
}

export function concatFeatures(base: MapFeatures, extension: MapFeatures): MapFeatures {
  const result = createEmptyMapFeatures() as unknown as { [key: string]: entityType[] };
  for (const features of [base, extension]) {
    for (const [entityType, entitiesOfType] of Object.entries(features)) {
      result[entityType] = result[entityType].concat(entitiesOfType);
    }
  }
  return result as unknown as MapFeatures;
}

function isEntityValid(entity: entityType): boolean {
  return entity.header.isValid === undefined || entity.header.isValid;
}

function entityWithChangedValid(entity: entityType, isValid: boolean): entityType {
  // The object is json serializable since it was given via json
  const copy = JSON.parse(JSON.stringify(entity));
  copy.header.isValid = isValid;
  return copy;
}

const FREQUENY_REGEX = new RegExp("freq_(?<frequency>\\d+)");
export function getTransmitterClaimedFrequency(transmitter: AnalysisProductsTransmitter): number | undefined {
  const claimedFrequencyGroup = FREQUENY_REGEX.exec(transmitter.header.s3Ref)?.groups;
  if (claimedFrequencyGroup === undefined || claimedFrequencyGroup["frequency"] == undefined) {
    return undefined;
  }

  return parseInt(claimedFrequencyGroup["frequency"]);
}

export class MapFeaturesStatus {
  allFeatures: MapFeatures;
  scans: ScanDetails[];
  currentAnalysisSiteName: string;
  doneLoading: boolean;
  failedFeatures: Set<string>;

  features: MapFeatures;
  invalidFeatures: MapFeatures;

  constructor({ features, currentAnalysisSiteName, doneLoading, failedFeatures, scans }: BuildMapFeaturesStatusProps) {
    this.allFeatures = features;
    this.currentAnalysisSiteName = currentAnalysisSiteName;
    this.doneLoading = doneLoading;
    this.failedFeatures = failedFeatures;
    this.scans = scans;

    this.features = filterFeatures(this.allFeatures, isEntityValid);
    this.invalidFeatures = filterFeatures(this.allFeatures, (entity) => !isEntityValid(entity));
  }

  merge(otherMapFeature: MapFeaturesStatus): MapFeaturesStatus {
    if (otherMapFeature.currentAnalysisSiteName !== this.currentAnalysisSiteName) {
      return this;
    }

    const newSucceed = getFeaturesIds(otherMapFeature.allFeatures);
    const stillFailed = new Set(Array.from(this.failedFeatures).filter((entityId) => !newSucceed.has(entityId)));
    const failedFeatures = new Set([...stillFailed, ...otherMapFeature.failedFeatures]);
    const newScans = [...this.scans];
    const scanIds = newScans.map((s) => s.id);
    otherMapFeature.scans.filter((s) => !scanIds.includes(s.id)).forEach((s) => newScans.push(s));

    return new MapFeaturesStatus({
      features: concatFeatures(this.allFeatures, otherMapFeature.allFeatures),
      currentAnalysisSiteName: this.currentAnalysisSiteName,
      doneLoading: otherMapFeature.doneLoading,
      failedFeatures: failedFeatures,
      scans: newScans,
    });
  }

  setDoneLoading(loadingValue: boolean): MapFeaturesStatus {
    return new MapFeaturesStatus({
      features: this.allFeatures,
      currentAnalysisSiteName: this.currentAnalysisSiteName,
      doneLoading: loadingValue,
      failedFeatures: this.failedFeatures,
      scans: this.scans,
    });
  }

  extendErrors(featuresIds: string[]): MapFeaturesStatus {
    return new MapFeaturesStatus({
      features: this.allFeatures,
      currentAnalysisSiteName: this.currentAnalysisSiteName,
      doneLoading: this.doneLoading,
      failedFeatures: new Set([...this.failedFeatures, ...featuresIds]),
      scans: this.scans,
    });
  }

  removeFailedFeatures(deletionIds: string[]): MapFeaturesStatus {
    return new MapFeaturesStatus({
      features: filterFeatures(this.allFeatures, (entity) => !deletionIds.includes(entity.header.id)),
      currentAnalysisSiteName: this.currentAnalysisSiteName,
      doneLoading: this.doneLoading,
      failedFeatures: new Set<string>(),
      scans: this.scans,
    });
  }

  setEntityValid(featureId: string, isValid: boolean): MapFeaturesStatus {
    return new MapFeaturesStatus({
      features: changeFeatures(this.allFeatures, (entity) =>
        featureId !== entity.header.id ? entity : entityWithChangedValid(entity, isValid)
      ),
      currentAnalysisSiteName: this.currentAnalysisSiteName,
      doneLoading: this.doneLoading,
      failedFeatures: this.failedFeatures,
      scans: this.scans,
    });
  }

  setScans(scans: ScanDetails[]) {
    return new MapFeaturesStatus({
      features: this.features,
      currentAnalysisSiteName: this.currentAnalysisSiteName,
      doneLoading: this.doneLoading,
      failedFeatures: this.failedFeatures,
      scans: scans,
    });
  }
}

export interface MapFeatures {
  pipes: AnalysisProductsPipe[];
  transmitters: AnalysisProductsTransmitter[];
  travelCourses: AnalysisProductsTravelCourse[];
  polygons: AnalysisProductsPolygon[];
  targets: AnalysisProductsTarget[];
}

export interface BuildMapFeaturesStatusProps {
  features: MapFeatures;
  scans: ScanDetails[];
  currentAnalysisSiteName: string;
  doneLoading: boolean;
  failedFeatures: Set<string>;
}
