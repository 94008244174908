import { Api, SiteDetails, UserLayer } from "../../../Generated/ExoDBAPI";
import { getRequestParams } from "../../../Utils/azureAuth";

import { FeatureQueryConfig } from "../ExoFuserConfig";

import { runWithRetries } from "../../../Utils/promiseWithRetries";
import { splitTasks } from "../../../Utils/splitTasks";

async function getDrawnLayersPromises({
  plannedSite,
  ids,
  source,
  reporters,
  queryConfig,
}: getLayersDownloadPromisesParameters): Promise<(() => Promise<void>)[]> {
  async function queryDrawnLayers(layersIds: string[], plannedSite: SiteDetails, sourceName?: string) {
    let result = undefined;
    try {
      const api = new Api();

      const queryLayersCoroutine = api.sites.postGetLayersData(
        plannedSite.id,
        { layersIds: layersIds, source: sourceName },
        await getRequestParams()
      );
      result = await runWithRetries(queryLayersCoroutine, queryConfig.retryConfig);
    } catch (error) {
      console.log(error);
      reporters.reportNewErrors(layersIds);
    }

    if (result === undefined) {
      reporters.reportNewErrors(layersIds);
      return;
    }

    reporters.reportNewLayers(result.data.layers);
  }

  const promises = [];
  for (let i = 0; i < ids.length; i += queryConfig.featuresPerQuery) {
    const chunk = ids.slice(i, i + queryConfig.featuresPerQuery);
    promises.push(async () => {
      await queryDrawnLayers(chunk, plannedSite, source);
    });
  }
  return promises;
}

export async function reportDrawnLayers({ ids, plannedSite, source, reporters, queryConfig }: reportDrawnLayersProps) {
  reporters.setDoneLoading(plannedSite.id, false);
  try {
    await splitTasks(
      await getDrawnLayersPromises({
        ids: ids,
        plannedSite: plannedSite,
        source: source,
        reporters: reporters,
        queryConfig: queryConfig,
      }),
      queryConfig.maxConcurrency
    );
  } finally {
    reporters.setDoneLoading(plannedSite.id, true);
  }
}

export interface LayerReporters {
  setExpectedLayersCount: (count: number) => void;
  reportNewLayers: (layers: UserLayer[]) => void;
  reportNewErrors: (layersIds: string[]) => void;
  setDoneLoading: (plannedSiteId: string, state: boolean) => void;
}

export interface getLayersDownloadPromisesParameters {
  ids: string[];
  plannedSite: SiteDetails;
  source?: string;
  reporters: LayerReporters;
  queryConfig: FeatureQueryConfig;
}

export interface reportDrawnLayersProps {
  ids: string[];
  plannedSite: SiteDetails;
  source?: string;
  reporters: LayerReporters;
  queryConfig: FeatureQueryConfig;
}
