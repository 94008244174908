import { TypographyProps, Typography as MUITypography } from '@mui/material';
import { forwardRef } from 'react';

export const Typography = forwardRef((props: TypographyProps, ref: any) => {
  return (
    <MUITypography {...props} ref={ref}>
      {props.children}
    </MUITypography>
  );
});
