import _React from "react";
import { FeatureLayer } from "react-esri-leaflet";
import { LayerGroup, LayersControl } from "react-leaflet";
import { PopupPaneName } from "../../../../config/paneConfig";

function EsriLayer({ url, id }: EsriLayerProps) {
  return (
    <FeatureLayer
      url={url}
      key={id}
      onEachFeature={(feature, layer) => {
        const popupLines = Object.keys(feature.properties)
          .map((key) => `${key}: ${feature.properties[key]}`)
          .join("<br/>");
        layer.bindPopup(popupLines, { pane: PopupPaneName });
      }}
    />
  );
}

export function EsriLayerGroup({ name, url }: EsriLayerGroupProps) {
  return (
    <LayersControl.Overlay name={name}>
      <LayerGroup>
        <EsriLayer url={url} id={`esri-layer`} />
      </LayerGroup>
    </LayersControl.Overlay>
  );
}

export interface EsriLayerProps {
  url: string;
  id?: string;
}

export interface EsriLayerGroupProps {
  name: string;
  url: string;
}

export default EsriLayer;
