import { Box, Button, Divider, List, ListItem, ListItemText, Paper, Slider, Typography } from "@mui/material";
import * as React from "react";
import { DragDropContext, Droppable, DropResult, Draggable } from "react-beautiful-dnd";
import { CustomTileLayer } from "./LoadTifTileLayers";

function ManageLoadedTiffs({ layers, setLoadedLayers }: ManageLoadedTiffsProps) {
  const [dragDisabled, setDragDisabled] = React.useState(false);
  function onDragEnd(e: DropResult) {
    if (e.destination === undefined || e.destination === null || e.source === undefined || e.destination === null) {
      return;
    }

    const tempArr = [...layers];
    const [removed] = tempArr.splice(layers.length - e.source.index - 1, 1);
    tempArr.splice(layers.length - e.destination.index - 1, 0, removed);

    setLoadedLayers(tempArr);
  }

  function changeOpacity(newOpacity: number, name: string) {
    const tempArr = [...layers];
    const layerIndex = tempArr.findIndex((l) => l.name === name);
    tempArr[layerIndex] = { ...tempArr[layerIndex], opacity: newOpacity };
    setLoadedLayers(tempArr);
  }

  return (
    <>
      <Typography variant="subtitle1">Manage Layers</Typography>
      <Paper sx={{ marginBottom: 1, paddingBottom: 2 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="layerList">
            {(provided) => (
              <List ref={provided.innerRef} {...provided.droppableProps}>
                {[...layers].reverse().map((l, i) => (
                  <Draggable draggableId={l.path} index={i} key={l.name} isDragDisabled={dragDisabled}>
                    {(draggableProvided) => (
                      <>
                        <ListItem
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          <ListItemText
                            primary={l.name}
                            secondary={
                              <>
                                <Typography variant="subtitle2">Opacity</Typography>
                                <div
                                  onMouseOver={() => {
                                    setDragDisabled(true);
                                  }}
                                  onMouseOut={() => setDragDisabled(false)}
                                >
                                  <Slider
                                    size="small"
                                    value={l.opacity * 100}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                    onChange={(e, v) => changeOpacity((v as number) / 100, l.name)}
                                  />
                                </div>
                              </>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>
    </>
  );
}

export interface ManageLoadedTiffsProps {
  layers: CustomTileLayer[];
  setLoadedLayers: (layers: CustomTileLayer[]) => void;
}

export default ManageLoadedTiffs;
