import * as React from "react";
import { Api, SiteDetails } from "../../../../Generated/ExoDBAPI";
import GenericPreSignedUrlFileUploader, { UploadPart } from "../../../../MultipartUpload/genericPresingedURLUploader";
import { getRequestParams } from "../../../../Utils/azureAuth";
import { FileData, FileInputRef } from "../../../Inputs/FileInput";
import UploadFile from "./UploadFile";

function UploadTiff({ plannedSite }: UploadTiffProps) {
  async function uploadTiffCallback(site: SiteDetails, layerName: string, fileData: FileData, fileInput: FileInputRef) {
    const getUrlCallback = async () => {
      const api = new Api();
      return (
        await api.analysis.getTiffUploadPresignedUrl(
          { plannedSite: site.name, layerName: layerName, fileSize: fileData.data.size.toString() },
          await getRequestParams()
        )
      ).data;
    };
    const endMultipartUploadCallback = async (uploadId: string, parts: UploadPart[]) => {
      const api = new Api();
      await api.analysis.endTiffMultipartUpload(
        { plannedSite: site.name, layerName: layerName },
        { uploadId: uploadId, parts: parts },
        await getRequestParams()
      );
    };
    const uploader = new GenericPreSignedUrlFileUploader(fileData.data, getUrlCallback, endMultipartUploadCallback);

    await uploader.uploadFile((e: number) => fileInput.updateProgress(fileData.id, e));
  }

  return (
    <UploadFile plannedSite={plannedSite} accept=".tiff,.tif" percentages={true} uploadCallback={uploadTiffCallback} />
  );
}

export interface UploadTiffProps {
  plannedSite?: SiteDetails;
}

export default UploadTiff;
