import { Divider, Stack, Grid } from '@mui/material';

import { usePoiStore } from '../store/poiStore';

import { InfoIcon } from '../../../SVG/Info';
import { getRelevantOwnersAndUtilities } from '../../../../Constant/ownerAndUtilityMapping';
import { Legend, Tooltip, Typography } from '../../../../shared';
import { Media, MetaData, Pipes, PoiTable, TabSection } from '../components';
import { getOwner } from '../utils';

export function PoiTab() {
  const { selectedPoi, pois } = usePoiStore();
  const { selectedOwners }: { [key: string]: { color: string; abrv: string } | {} } =
    getRelevantOwnersAndUtilities(pois);
  const { selectedUtilities }: { [key: string]: { color: string; abrv: string } | {} } =
    getRelevantOwnersAndUtilities(pois);

  const manhole = selectedPoi?.target_info;
  const measurement = pois[0]?.site?.location?.country === 'USA' ? 'inch' : 'cm';
  const renderLegend = (map: Record<string, { color: string }>) => {
    return Object.entries(map).map(([key, value]) => {
      return (
        <Grid container spacing={2}>
          <Grid container item xs="auto" direction="column">
            <Grid key={key} item xs="auto">
              <Stack direction="column">
                <Legend color={value?.color || '#000000'} label={key} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const renderOwners = renderLegend(selectedOwners as Record<string, { color: string }>);
  const renderUtilities = renderLegend(selectedUtilities as Record<string, { color: string }>);

  const legandAndInfo =
    renderOwners.length > 0 || renderUtilities.length > 0 ? (
      <Grid container spacing={2} p={1}>
        {renderOwners.length > 0 ? (
          <Grid item xs={renderUtilities.length > 0 ? 6 : 12} p={1}>
            <Typography variant="h6">Owners</Typography>
            {renderOwners}
          </Grid>
        ) : (
          <></>
        )}
        {renderUtilities.length > 0 ? (
          <Grid item xs={renderOwners.length > 0 ? 6 : 12} p={1}>
            <Typography variant="h6">Utilities</Typography>
            {renderUtilities}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    ) : (
      <></>
    );

  const renderInfo = (
    <Tooltip title={legandAndInfo}>
      <InfoIcon fontSize="small" />
    </Tooltip>
  );

  return (
    <Stack direction="row" spacing={2} overflow="hidden">
      <PoiTable />
      <TabSection title="Data" sx={{ flex: 1 }} dataInfo={renderInfo}>
        <MetaData
          data={{
            ...manhole,
            measurement,
            utilities: manhole?.utilityType,
            owner: getOwner(selectedPoi),
            name: selectedPoi?.name
          }}
        />
      </TabSection>

      <Divider flexItem orientation="vertical" component="span" />
      <TabSection title="Pipes" sx={{ flex: 1 }}>
        <Pipes data={manhole?.pipes || []} measurement={measurement} />
      </TabSection>
      <Divider flexItem orientation="vertical" component="span" />
      <TabSection title="Media" sx={{ flex: 1 }}>
        <Media
          fileNames={manhole?.fileNames}
          siteId={manhole?.siteId}
          folderName={manhole?.folderName}
        />
      </TabSection>
    </Stack>
  );
}
