import { Dict } from "../../BasicTypes";

export interface BaseMonitorInfo {
  status: string;
  startTime?: number;
  endTime?: number;
  errorMessage?: string;
}
export interface PreloadRunInfo extends BaseMonitorInfo {
  target: string;
  targetName: string;
  preloadedS3Refs?: string[];
  id: string;
  products?: string[];
  updatedAt: string;
}

export interface PipelineRunInfo extends BaseMonitorInfo {
  startTime?: number;
  endTime?: number;
  name: string;
  eplsS3Ref: string;
  errorMessage?: string;
  status: string;
  updatedAt: string;
}

export interface ScanStep extends BaseMonitorInfo {}

export interface UploadStep extends BaseMonitorInfo {
  s3Ref?: string;
  uploader?: string;
  uploadSpeed?: number;
  updatedAt: number;
}

export interface PreloadStep extends BaseMonitorInfo {
  runs: PreloadRunInfo[];
}

export interface UtilityFinderStep extends BaseMonitorInfo {
  pipelines: PipelineRunInfo[];
}

export interface AnalysisStep {
  accessTime?: number;
  status: string;
  errorMessage?: string;
}

export interface MonitoringInfo {
  scanStep: ScanStep;
  uploadStep: UploadStep;
  preloadStep: PreloadStep;
  utilityFinderStep: UtilityFinderStep;
  errorMessage?: string;
  analysisStep: AnalysisStep;
}

export interface ScanMonitorInfo {
  id: string;
  name: string;
  sensor: string;
  scanType: string;
  valid?: boolean;
  machineId: string | undefined;
  monitoringInfo: MonitoringInfo;
}

export const RUN_STATUS_ORDER = ["PENDING", "SCHEDULED", "RUNNING", "DONE", "FAILED", "SKIPPED"];
export const RUN_STATUS_TO_DISPLAY_NAME: Dict<string> = {
  SCHEDULED: "Scheduled",
  RUNNING: "Running",
  DONE: "Done",
  FAILED: "Failed",
  PENDING: "Pending",
  SKIPPED: "Skipped",
};

export interface SensorInfo {
  model: string;
  id: string;
}

export interface SiteInfo {
  name: string;
  id: string;
  locationId: string;
}

export interface LocationInfo {
  state: string;
  id: string;
}

export enum ScanStages {
  SCAN = "Scan",
  UPLOAD = "Upload",
  PRELOAD = "Preload",
  UTILITY_FINDER = "Utility Finder",
  ANALYSIS = "Analysis",
}
