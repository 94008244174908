import React from "react";

import { AnalysisProductsSite, Api, ImageData } from "../../../Generated/ExoDBAPI";
import { getRequestParams } from "../../../Utils/azureAuth";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import UserContext from "../../Contexts/UserContext";
import Modal from "../../Templates/Modal";
import { TextField } from "@mui/material";
import { AnalysisProductsPipe } from "../DataTypes/MapEntities";

function CreationContextImage({ analysisSite, pipe }: CreationContextImageProps) {
  const { user } = React.useContext(UserContext);
  const [imagesData, setImagesData] = React.useState<ImageData[]>([]);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [selectedImageData, setSetlectedImageData] = React.useState<ImageData>();

  const closingDivRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    async function fetchData() {
      if (user === undefined || analysisSite?.s3Prefix === undefined || pipe?.header.id === undefined) {
        return;
      }

      setImagesData([]);
      setSetlectedImageData(undefined);
      const api = new Api();
      const result = await api.analysis.getPipeCreationImage(
        analysisSite.s3Prefix,
        encodeURIComponent(pipe?.header.s3Ref),
        { source: analysisSite?.s3Source },
        await getRequestParams()
      );
      setImagesData(result.data.images);
    }

    fetchData();
  }, [user, analysisSite, pipe]);

  function createImages(className: string) {
    return imagesData.map((image) => (
      <img
        src={image.url}
        className={className}
        alt="picture name"
        onClick={() => {
          setShowModal(!showModal);
          setSetlectedImageData(image);
        }}
      />
    ));
  }

  React.useEffect(() => {
    if (closingDivRef.current !== null && showModal) {
      closingDivRef.current.focus();
    }
  }, [showModal, closingDivRef.current]);

  function hanleKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Escape") {
      setShowModal(false);
    }
  }

  function createModal() {
    if (selectedImageData === undefined || !showModal) {
      return <></>;
    }

    return (
      <Modal>
        <div
          tabIndex={-1}
          ref={closingDivRef}
          onBlur={() => setShowModal(false)}
          onDoubleClick={() => setShowModal(false)}
          onKeyDown={hanleKeyPress}
        >
          <TextField
            sx={{ background: "red", width: "100%" }}
            inputProps={{ style: { fontSize: 36, fontWeight: "bold" } }}
            value={"Click outside the image, press Escape or Double Click anywhere to exit"}
            variant="filled"
            disabled={true}
            onClick={() => setShowModal(false)}
          />
          <TextField
            sx={{ width: "100%", backgroundColor: "white" }}
            inputProps={{ style: { fontSize: 20, fontWeight: "bold" } }}
            value={selectedImageData.name}
            variant="filled"
            disabled={true}
            onClick={() => setShowModal(false)}
          />
          <TransformWrapper>
            <TransformComponent>
              <img src={selectedImageData.url} className="contextImage" />
            </TransformComponent>
          </TransformWrapper>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <form>{imagesData.length !== 0 && createImages("contextImagePreview")}</form>
      {createModal()}
    </>
  );
}

export interface CreationContextImageProps {
  analysisSite?: AnalysisProductsSite;
  pipe?: AnalysisProductsPipe;
}

export default CreationContextImage;
