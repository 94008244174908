import * as React from 'react';
import { Dict, EnumDictionary } from '../../BasicTypes';
import { RUN_STATUS_ORDER, ScanStages } from './MonitorTypes';
import { Button, FormControl, Stack, Typography } from '@mui/material';

function getAllTrueStageStatus() {
  return Object.fromEntries(RUN_STATUS_ORDER.map((status) => [status, true])) as Dict<boolean>;
}

function getAllFalseStageStatus() {
  return Object.fromEntries(RUN_STATUS_ORDER.map((status) => [status, false])) as Dict<boolean>;
}

function getAllTruePreset() {
  return Object.fromEntries(
    Object.values(ScanStages).map((stage) => [
      stage,
      Object.fromEntries(RUN_STATUS_ORDER.map((status) => [status, true])) as Dict<boolean>
    ])
  ) as EnumDictionary<ScanStages, Dict<boolean>>;
}

function getAllFalsePreset() {
  return Object.fromEntries(
    Object.values(ScanStages).map((stage) => [
      stage,
      Object.fromEntries(RUN_STATUS_ORDER.map((status) => [status, false])) as Dict<boolean>
    ])
  ) as EnumDictionary<ScanStages, Dict<boolean>>;
}

function getSuspiciousPreloaderPreset() {
  const preset = getAllTruePreset();
  preset[ScanStages.PRELOAD] = { ...getAllTrueStageStatus(), DONE: false, SKIPPED: false };
  preset[ScanStages.SCAN] = { ...getAllFalseStageStatus(), DONE: true };
  return preset;
}

function getNotUploadedPresets() {
  const preset = getAllTruePreset();
  preset[ScanStages.UPLOAD] = { ...getAllTrueStageStatus(), DONE: false };
  preset[ScanStages.PRELOAD] = { ...getAllFalseStageStatus(), PENDING: true };
  return preset;
}

function getFailedUtilityFinder() {
  const preset = getAllTruePreset();
  preset[ScanStages.UTILITY_FINDER] = { ...getAllFalseStageStatus(), FAILED: true };
  return preset;
}

function getAnalyzedScans() {
  const preset = getAllTruePreset();
  preset[ScanStages.ANALYSIS] = { ...getAllFalseStageStatus(), RUNNING: true, DONE: true };
  return preset;
}

const PRESETS: PresetInfo[] = [
  {
    name: 'Check All',
    preset: getAllTruePreset()
  },
  {
    name: 'Uncheck All',
    preset: getAllFalsePreset()
  },
  {
    name: 'Not Uploaded',
    preset: getNotUploadedPresets()
  },
  {
    // ඞ
    name: 'Suspicious Preload Run',
    preset: getSuspiciousPreloaderPreset()
  },
  {
    name: 'Failed Utility Finder',
    preset: getFailedUtilityFinder()
  },
  {
    name: 'Analyzed Scans',
    preset: getAnalyzedScans()
  }
];

function StageMonitorPresets({ setOverridePresets }: StageMonitorPresets) {
  return (
    <FormControl>
      <Typography variant="body1" fontWeight="bold">
        Presets
      </Typography>

      <Stack
        sx={{
          marginLeft: 1
        }}>
        {PRESETS.map((preset) => (
          <Button
            onClick={() => setOverridePresets({ ...preset.preset })}
            style={{ justifyContent: 'flex-start', whiteSpace: 'nowrap' }}>
            {preset.name}
          </Button>
        ))}
      </Stack>
    </FormControl>
  );
}

export interface StageMonitorPresets {
  setOverridePresets: (statuses: EnumDictionary<ScanStages, Dict<boolean>>) => void;
}

export interface PresetInfo {
  name: string;
  preset: EnumDictionary<ScanStages, Dict<boolean>>;
}

export default StageMonitorPresets;
