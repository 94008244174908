import React from 'react';
import { AnalysisProductsSite } from '../../../Generated/ExoDBAPI';
import { DisplayGroup } from '../DataTypes/DisplayModes';

import DisplayPipeData from './DisplayPipeData';
import CreationContextImage from './CreationContextImage';
import InvalidatePipe from './InvalidatePipe';
import DownloadPipeProtobuf from './DownloadPipeProtobuf';
import CreateDrawnShape from './CreateDrawnShape';
import DownloadPipeAndTravelCourseGeoJson from './DownloadPipeAndTravelCourse';
import GoToPipesQASelectedpipe from './GoToPipesQASelectedpipe';
import {
  getMatchingTransmitters,
  getScanMatchingFeatures,
  getScanTransmitters
} from './FindMatchingFeatures';
import { DrawnContext } from '../Drawns/DrawnFileData';
import {
  AnalysisProductsPipe,
  AnalysisProductsTransmitter,
  AnalysisProductsTravelCourse
} from '../DataTypes/MapEntities';
import GroupOfPipesToPipesQA from './GroupOfPipesToPipesQA';
import { MapFeatures } from '../DataTypes/MapFeatures';
import { useMapPipeEntitiesStore } from '../store';
import { Button } from '@mui/material';

function SelectedPipeTab({
  analysisSite,
  setEntityValid,
  setDisplayedFeatures,
  travelCourses,
  drawnContext,
  chosenDisplayGroup,
  pipesQaGroup,
  transmitters
}: SelectedPipeTabProps) {
  const {
    selectedPipe,
    showPipes,
    setShowPipes,
    setSelectedPipeScanTransmitters,
    setSelectedPipeTravelCourse,
    setShowPipeTransmitters,
    showPipeTransmitters
  } = useMapPipeEntitiesStore();

  const pipePreloaderTravelCourse = React.useMemo<AnalysisProductsTravelCourse | undefined>(() => {
    if (selectedPipe === undefined) {
      return undefined;
    }
    return getScanMatchingFeatures(selectedPipe.header.scan, travelCourses).find(
      (tr) => tr.gpsFixedModeRatio !== undefined
    );
  }, [selectedPipe, travelCourses]);

  const toggleTransimtters = () => {
    if (!selectedPipe) return;
    setSelectedPipeScanTransmitters(
      showPipeTransmitters ? [] : getScanTransmitters(selectedPipe, transmitters)
    );
    setSelectedPipeTravelCourse(
      showPipeTransmitters ? [] : getScanMatchingFeatures(selectedPipe.header.scan, travelCourses)
    );

    setShowPipes(!showPipes);
    setShowPipeTransmitters(!showPipeTransmitters);
  };

  const togglePieps = () => {
    if (selectedPipe) {
      setSelectedPipeScanTransmitters(
        !showPipes ? [] : getMatchingTransmitters(selectedPipe, transmitters)
      );
      setSelectedPipeTravelCourse(
        !showPipes ? [] : getScanMatchingFeatures(selectedPipe.header.scan, travelCourses)
      );
    }
    setShowPipes(!showPipes);
  };

  return (
    <span className="tabsContainer">
      <span className="tabsBody">
        <h3>Pipe Data</h3>
        <DisplayPipeData pipe={selectedPipe} preloaderTravelCourse={pipePreloaderTravelCourse} />
      </span>
      <span className="tabsBody">
        <h3>Pipe Actions</h3>
        <div className="selectActionsSection">
          <span className="selectAction">
            <Button onClick={togglePieps} variant={showPipes ? 'contained' : 'outlined'}>
              Toggle Pipe VIew
            </Button>
          </span>
          <span className="selectAction">
            <Button
              onClick={toggleTransimtters}
              variant={showPipeTransmitters ? 'contained' : 'outlined'}>
              Toggle Transmitters VIew
            </Button>
          </span>
          <span className="selectAction">
            <InvalidatePipe
              analysisSite={analysisSite}
              pipe={selectedPipe}
              setEntityValid={setEntityValid}
              setDisplayedFeatures={setDisplayedFeatures}
            />
          </span>
          <span className="selectAction">
            <DownloadPipeProtobuf selectedPipe={selectedPipe} analysisSite={analysisSite} />
          </span>
          <span className="selectAction">
            <DownloadPipeAndTravelCourseGeoJson pipe={selectedPipe} travelCourses={travelCourses} />
          </span>
          <span className="selectAction">
            <CreateDrawnShape drawnContext={drawnContext} pipe={selectedPipe} />
          </span>
        </div>
      </span>
      <span className="tabsBody">
        <h3>Pipes QA</h3>
        <span className="selectAction">
          <GoToPipesQASelectedpipe pipe={selectedPipe} />
        </span>
        <span className="selectAction">
          <GroupOfPipesToPipesQA pipeGroup={pipesQaGroup} />
        </span>
      </span>
      <span className="tabsBody">
        <h3>Pipe Creation Context</h3>
        <CreationContextImage analysisSite={analysisSite} pipe={selectedPipe} />
      </span>
    </span>
  );
}

export interface SelectedPipeTabProps {
  analysisSite?: AnalysisProductsSite;
  selectedPipe?: AnalysisProductsPipe;
  chosenDisplayGroup?: DisplayGroup;
  setChosenDisplayGroup?: (displayGroup: DisplayGroup) => void;
  setEntityValid: (
    site: AnalysisProductsSite,
    featureId: string,
    s3Ref: string,
    isValid: boolean
  ) => void;
  setDisplayedFeatures: (f: (m: MapFeatures) => MapFeatures) => void;
  travelCourses: AnalysisProductsTravelCourse[];
  transmitters: AnalysisProductsTransmitter[];
  drawnContext: DrawnContext;
  pipesQaGroup: AnalysisProductsPipe[];
}

export default SelectedPipeTab;
