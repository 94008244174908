import { LatLngExpression, PathOptions } from "leaflet";
import * as React from "react";
import { LeafletEventHandlerFnMap } from "leaflet";
import { Circle, useMap } from "react-leaflet";

function EditableCircle({ center, setCenter, editable, pathOptions, radius, eventHandlers }: EditableCircleProps) {
  const map = useMap();
  const [innerCenter, setInnerCenter] = React.useState(center);
  const moveCircleRef = React.useRef<(e: any) => void>(moveCircle);
  const mouseUpRef = React.useRef<() => void>(mouseUp);
  const lastMoveTimestamp = React.useRef<{ value: number }>({ value: -1 });

  React.useEffect(() => {
    lastMoveTimestamp.current.value = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - lastMoveTimestamp.current.value > 50) {
        setCenter(innerCenter);
      }
    }, 100);
  }, [innerCenter]);

  function moveCircle(e: any) {
    setInnerCenter(e.latlng);
  }

  function mouseUp() {
    /*
     When the mouse is unpressed make the map draggable again, map is not draggable while moving the circle.
     This event can't be tied to the circle because the circle can lag behind the cursor and the mouse up event might not register
    */
    map.off("mousemove", moveCircleRef.current);
    map.dragging.enable();
  }

  React.useEffect(() => {
    map.addEventListener("mouseup", mouseUpRef.current);
    return () => {
      map.removeEventListener("mouseup", mouseUpRef.current);
    };
  }, []);

  return (
    <Circle
      center={innerCenter}
      radius={radius}
      pathOptions={pathOptions}
      eventHandlers={{
        mousedown() {
          if (editable) {
            map.dragging.disable();
            map.on("mousemove", moveCircleRef.current);
          }
        },
        ...eventHandlers,
      }}
    />
  );
}

export interface EditableCircleProps {
  center: LatLngExpression;
  setCenter: (position: LatLngExpression) => void;
  radius: number;
  editable: boolean;
  pathOptions: PathOptions;
  eventHandlers?: LeafletEventHandlerFnMap;
}

export default EditableCircle;
