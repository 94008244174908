import React from "react";
import { Api } from "../../Generated/ExoDBAPI";
import { useCreateLocationMutation, useGetLocationsAmountWithFilterLazyQuery } from "../../Generated/Graphql";
import { getRequestParams, getRequestParamsOnlyHeaders } from "../../Utils/azureAuth";
import axios from "axios";

/**
 * A form for adding a new state
 * @returns
 */
function AddStateForm() {
  const [createLocation] = useCreateLocationMutation();
  const [locationAmountQuery] = useGetLocationsAmountWithFilterLazyQuery();
  const countryInput = React.useRef<HTMLInputElement>(null);
  const stateInput = React.useRef<HTMLInputElement>(null);
  const submitRef = React.useRef<HTMLInputElement>(null);

  /**
   * Send a request to the backend to create new state
   * @param {*} e inSubmit event
   */
  async function addState(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const submitButton = submitRef.current;
    if (submitButton === null || countryInput.current === null || stateInput.current === null) {
      return;
    }
    submitButton.className = "buttonClicked";
    submitButton.disabled = true;

    try {
      const state = stateInput.current.value;
      const country = countryInput.current.value;
      try {
        const prodBUrl = process.env.REACT_APP_PROD_B_GRAPHQL_HOST;
        if (prodBUrl !== undefined) {
          await axios.post(
            prodBUrl,
            {
              operationName: "CreateLocation",
              query:
                "mutation CreateLocation($state: String, $country: String!, $region: String) {\n  createLocation(input: {country: $country, region: $region, state: $state}) {\n    id\n    __typename\n  }\n}",
              variables: {
                state: state,
                country: country,
              },
            },
            await getRequestParamsOnlyHeaders()
          );
        }
      } catch (e) {
        console.log("failed to create state in prodb");
        console.log(e);
        window.alert("Failed to create state in new DB. Please notify software team.");
      }
      const res = await locationAmountQuery({ variables: { state: state, country: country } });
      const locationAmount = res.data?.searchLocations?.total;
      if (locationAmount !== 0) {
        const errorMsg = `There are ${locationAmount} customers with this name in the DB`;
        window.alert(errorMsg);
        throw new Error(errorMsg);
      }

      const api = new Api();
      await api.states
        .addState(
          {
            countryName: country,
            stateName: state,
          },
          await getRequestParams()
        )
        .then(async () => {
          const resp = await createLocation({ variables: { state: state, country: country } });
          if (resp.errors) {
            console.log(resp.errors);
            throw resp.errors[0];
          }
          if (stateInput.current !== null && countryInput.current !== null) {
            stateInput.current.value = "";
            countryInput.current.value = "";
          }
        })
        .catch((resp) => {
          const errorMsg = resp.response.data;
          window.alert(errorMsg);
        });
    } finally {
      submitButton.className = "formButton";
      submitButton.disabled = false;
    }
  }

  return (
    <form autoComplete="off" onSubmit={(e) => addState(e)} id="addStateForm">
      <h3 className="pageHeader">Create state</h3>
      <div className="formSection">
        <span>
          <span className="siteFormEntry">
            <label htmlFor="countryName">Country name:</label>
            <input
              type="text"
              name="countryName"
              id="countryName"
              required={true}
              pattern="(?=.{2,50}$)(^([a-zA-Z]+)([ ])?)(([a-zA-Z]+[ ])*)([a-zA-Z]+)"
              ref={countryInput}
            />
          </span>
          <span className="siteFormEntry">
            <label htmlFor="stateName">State name:</label>
            <input
              type="text"
              name="stateName"
              id="stateName"
              required={true}
              pattern="(?=.{2,50}$)(^([a-zA-Z]+)([ ])?)(([a-zA-Z]+[ ])*)([a-zA-Z]+)"
              ref={stateInput}
            />
          </span>
        </span>
        <div className="formSectionIsolated">
          <input type="submit" className="formButton" value="Add State" ref={submitRef} />
        </div>
      </div>
    </form>
  );
}

export default AddStateForm;
