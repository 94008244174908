import * as React from "react";
import { AnalysisLayer, Api, SiteDetails } from "../../../Generated/ExoDBAPI";
import { GeoJsonLayer } from "../../../Utils/layerUtils";
import { getLayerObjFromId } from "../UploadFiles/ManageGeoJsonLayers";
import { getColorFromTypeObject } from "../../../Constant/exoVision";

function CustomGeoJsonLayersStorageManager({
  site,
  geojsonLayers,
  setGeoJsonLayers,
}: CustomGeoJsonLayersStorageManagerProps) {
  React.useEffect(() => {
    async function fetchData() {
      if (site === undefined) {
        return;
      }
      const storedLayers = JSON.parse(
        window.localStorage.getItem(`CustomGeoJsonLayersStorage--${site.name}--layers`) ?? "[]"
      );
      const api = new Api();
      const layers = [];
      for (const storedLayer of storedLayers) {
        let layer = await getLayerObjFromId(storedLayer.id, storedLayer.name, api);
        layers.push(mergeStoredLayerAndGeoJsonLayer(storedLayer, layer));
      }
      setGeoJsonLayers(layers);
    }
    fetchData();
  }, [site]);

  React.useEffect(() => {
    if (site === undefined) {
      return;
    }
    const storageLayers = convertGeoJsonLayersToStorageLayers(geojsonLayers);
    window.localStorage.setItem(`CustomGeoJsonLayersStorage--${site.name}--layers`, JSON.stringify(storageLayers));
  }, [geojsonLayers]);

  function mergeStoredLayerAndGeoJsonLayer(
    storedLayer: GeoJsonLayerStorageData,
    geojsonLayer: AnalysisLayer
  ): GeoJsonLayer {
    const layer = {
      ...geojsonLayer,
      data: geojsonLayer.data ? geojsonLayer.data : undefined,
      metadata: geojsonLayer.metadata ? geojsonLayer.metadata : undefined,
      color: storedLayer.color,
      overrideColor: storedLayer.overrideColor,
      visible: storedLayer.visible,
      selected: false,
    };
    const subLayers = [];
    const analysisSubLayers = geojsonLayer.subLayers ?? [];
    for (const subLayer of analysisSubLayers) {
      const storedSubLayer = (storedLayer.subLayers ?? []).find((l) => l.id === subLayer.id);
      if (storedSubLayer === undefined) {
        console.log("Missing Id", subLayer.id);
        throw new Error("Desync between stored layer and remote layer.");
      }
      subLayers.push(mergeStoredLayerAndGeoJsonLayer(storedSubLayer, subLayer));
    }

    return { ...layer, subLayers: subLayers };
  }

  function convertGeoJsonLayersToStorageLayers(layers: GeoJsonLayer[]): GeoJsonLayerStorageData[] {
    const storageLayers = [];
    for (const layer of layers) {
      const storageLayer = {
        id: layer.id,
        name: layer.name,
        color: getColorFromTypeObject(layer?.data?.properties?.type) ?? layer.color,
        overrideColor: layer.overrideColor ?? false,
        visible: layer.visible,
      };

      const subLayers = convertGeoJsonLayersToStorageLayers(layer.subLayers ?? []);
      storageLayers.push({ ...storageLayer, subLayers: subLayers });
    }
    return storageLayers;
  }

  return <></>;
}

export interface CustomGeoJsonLayersStorageManagerProps {
  site?: SiteDetails;
  geojsonLayers: GeoJsonLayer[];
  setGeoJsonLayers: (layers: GeoJsonLayer[]) => void;
}

export interface GeoJsonLayerStorageData {
  id: string;
  name: string;
  color: string;
  overrideColor: boolean;
  visible: boolean;
  subLayers?: GeoJsonLayerStorageData[];
}

export default CustomGeoJsonLayersStorageManager;
