import React, { ChangeEvent, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { PipeExist, PipeItem, emptyPipe } from './ManHolesForm';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Controller, useForm } from 'react-hook-form';
import './ManHolePipe.css';
import { FlowDirection } from '../../Constant/entity';

const pictures = ['PVC', 'VCP', 'concrete', 'plastic', 'reinforced', 'metal', 'unknown'];
const No_Cables = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '>15',
  '>20',
  '>40',
  'Other'
];
const Hive = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  'Other'
];

type ManholePipeProps = {
  index: number;
  updatePipe: (index: number, pipeItem: PipeItem) => void;
  addPipe: () => void;
  removePipe: (index: number) => void;
  pipe: PipeItem;
  last: boolean;
  country: string;
  isUtilitySewerDrain: boolean;
  measurement: string;
};

function ManHolePipe(prop: ManholePipeProps) {
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors, isValid }
  } = useForm<PipeItem>();

  const formValues = watch();
  useEffect(() => {
    if (Object.keys(formValues).length > 0 && formValues.isPipe === PipeExist.YES) {
      prop.updatePipe(prop.index, { ...formValues });
    }
    if (formValues.isPipe === PipeExist.NO) {
      prop.updatePipe(prop.index, { ...emptyPipe, isPipe: PipeExist.NO });
    }
  }, [formValues]);

  useEffect(() => {
    Object.keys(prop.pipe).forEach((key) => {
      setValue(key as keyof PipeItem, prop.pipe[key as keyof PipeItem]);
    });
  }, [prop.index]);

  const handlePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('picture', event.target.value);
  };

  const handleNoPipeChange = (event: SelectChangeEvent) => {
    setValue('no_cables', event.target.value as string);
  };

  const handleHiveChange = (event: SelectChangeEvent) => {
    setValue('hive', event.target.value as string);
    // prop.updatePipe(prop.index, {...formValues, hive: event.target.value as string});
  };

  const handleRadioChange = (value: string, key: keyof PipeItem) => {
    setValue(key, value);
  };
  const handleNumberChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof PipeItem
  ) => {
    const value = Number(event.target.value);

    const numericValue = isNaN(value) ? null : value;
    setValue(fieldName, numericValue);
  };

  return (
    <>
      <div className="control">
        <label>Is there a pipe?</label>
        <div>
          <Controller
            name="isPipe"
            control={control}
            defaultValue={watch('isPipe')}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value={PipeExist.YES} control={<Radio />} label="Yes" />
                <FormControlLabel value={PipeExist.NO} control={<Radio />} label="No" />
              </RadioGroup>
            )}
          />
        </div>
      </div>
      {watch('isPipe') === PipeExist.YES && (
        <>
          <div className="imageContainer control">
            <label style={{ display: 'block' }}>Please select pipe type</label>
            <div>
              <RadioGroup
                row
                aria-label="picture"
                className="verticalRadioGroup"
                name="picture"
                defaultValue={watch('picture')}>
                {pictures.map((picture) => (
                  <div key={`div-${picture}`} className="control pipe-img">
                    <label key={picture}>
                      <Radio
                        checked={watch('picture') === picture}
                        onChange={handlePictureChange}
                        value={picture}
                      />
                      <img
                        src={`./Assets/Manhole/${picture}.png`}
                        alt={picture}
                        className="pipe-img"
                      />
                      <div className="pipe-picture">{picture}</div>
                    </label>
                  </div>
                ))}
              </RadioGroup>
            </div>
          </div>
          <div className="both-items">
            <FormControl className="form-control">
              <InputLabel id="hive-select-label" className="input_background">
                Hive: How much Exits in this group?
              </InputLabel>
              <Select
                value={watch('hive')}
                labelId="hive-select-label"
                id="hive-select"
                onChange={handleHiveChange}>
                {Hive.map((item, index) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="form-control">
              <InputLabel id="pipes-select-label" className="input_background">
                Number of cables
              </InputLabel>
              <Select
                value={watch('no_cables')}
                labelId="pipes-select-label"
                id="pipes-select"
                onChange={handleNoPipeChange}>
                {No_Cables.map((cable_item, index) => (
                  <MenuItem key={cable_item} value={cable_item}>
                    {cable_item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="form-maximum">
              <TextField
                label="Description"
                {...register('description', { required: false, valueAsNumber: false })}
                multiline
                rows={3}
                value={watch('description')}
                variant="outlined"
              />
            </FormControl>
          </div>
          <div className="comments">* If can't measure, fill 999</div>
          <FormControl className="form-maximum">
            <div className="both-items">
              <TextField
                label="Azimuth *"
                {...register('azimuth', { required: true, valueAsNumber: true })}
                error={!!errors.azimuth}
                className="control"
                inputProps={{ inputMode: 'numeric' }}
                value={watch('azimuth') || ''}
                onChange={(e) => handleNumberChange(e, 'azimuth')}
              />
              <TextField
                label={`Invert Level (${prop.measurement}) *`}
                {...register('invert_level', { required: true, valueAsNumber: true })}
                error={!!errors.invert_level}
                className="control"
                inputProps={{ inputMode: 'numeric' }}
                value={watch('invert_level') || ''}
                onChange={(e) => handleNumberChange(e, 'invert_level')}
              />
              <TextField
                label={`Top Level (${prop.measurement}) *`}
                {...register('top_level', { required: true, valueAsNumber: true })}
                error={!!errors.top_level}
                className="control"
                inputProps={{ inputMode: 'numeric' }}
                value={watch('top_level') || ''}
                onChange={(e) => handleNumberChange(e, 'top_level')}
              />
              <TextField
                label={`Diameter (${prop.measurement}) *`}
                {...register('diameter', { required: true, valueAsNumber: true })}
                error={!!errors.diameter}
                className="control"
                inputProps={{ inputMode: 'numeric' }}
                value={watch('diameter') || ''}
                onChange={(e) => handleNumberChange(e, 'diameter')}
              />
              <TextField
                label={`Thickness (${prop.measurement})`}
                {...register('thickness', { required: false, valueAsNumber: true })}
                error={!!errors.thickness}
                className="control"
                inputProps={{ inputMode: 'numeric' }}
                value={watch('thickness') || ''}
                required
                onChange={(e) => handleNumberChange(e, 'thickness')}
              />
            </div>
          </FormControl>
          {prop.isUtilitySewerDrain && (
            <div className="control">
              <FormControl>
                <FormLabel>Manhole cover material</FormLabel>
                <RadioGroup>
                  {FlowDirection.map((direction: string) => (
                    <FormControlLabel
                      control={
                        <Radio
                          value={direction}
                          onChange={(e) => {
                            handleRadioChange(direction, 'flowDirection');
                          }}
                        />
                      }
                      value={direction}
                      label={direction}
                      key={direction}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          )}

          <div className="bottoms">
            <Button
              variant="contained"
              color="primary"
              onClick={() => prop.removePipe(prop.index)}
              startIcon={<DeleteIcon />}
              style={{ marginBottom: '1rem', backgroundColor: 'red', color: 'white' }}>
              Remove Pipe
            </Button>
            {prop.last && isValid && watch('picture') && (
              <Button
                variant="contained"
                color="primary"
                onClick={prop.addPipe}
                startIcon={<AddCircleOutlineIcon />}>
                Add another Pipe
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
}
export default ManHolePipe;
